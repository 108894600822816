<div mat-dialog-content class="dialog-container">
  <!-- <div class="modal-background" (click)="dialogRef.close()"></div> -->
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>{{ (isEdit) ? translate.TITLE : translate.TITLE | uppercase }}</h1>
      <span class="close" (click)="dialogRef.close(null)">
        <img src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>

    <form [formGroup]="competitionForm" name="competitionForm" autocomplete="off" novalidate>
      <div fxLayout="row" class="mt-16 mb-16">
        <description-section title="{{ translate.CATEGORY.TITLE }}" text="">
          <mat-icon class="ic-info mr-8" svgIcon="ic-cat-info"></mat-icon>
        </description-section>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8">
          <mat-select placeholder="{{ translate.CATEGORY.TITLE }}" formControlName="competition" [errorStateMatcher]="matchers.competition" required  (selectionChange)="competitionSelect($event)">
            <mat-option *ngFor="let c of competitions" [value]="c.id">
              {{ c.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="competitionForm.controls.competition.hasError('required')">
            {{ translate.VALIDATIONS.CATEGORY_REQUIRED }}
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="50" fxFlex.xs="100" class="ml-8" *ngIf="enableSubDivision">
          <mat-select placeholder="{{ championship.sub_division_group_name ? championship.sub_division_group_name : translate.SUB_DIVISION }}" formControlName="subDivision" [errorStateMatcher]="matchers.subDivision" required>
            <mat-option *ngFor="let subDivision of subDivisions" [value]="subDivision.id">
              {{ subDivision.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="competitionForm.controls.subDivision.hasError('required')">
            {{ translate.VALIDATIONS.SUB_DIVISION_REQUIRED }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" class="mt-32 mb-16">
        <description-section title="{{ translate.ATHLETE.TITLE }}" text="{{ translate.ATHLETE.DESCRIPTION }}">
          <mat-icon class="ic-info mr-8" svgIcon="ic-cat-info"></mat-icon>
        </description-section>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" class="mt-16">
        <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8">
          <input matInput placeholder="{{ translate.ATHLETE.EMAIL }}" formControlName="email" [errorStateMatcher]="matchers.email" (blur)="searchUser($event)">
          <mat-error *ngIf="competitionForm.controls.email.hasError('email')">
            {{ translate.VALIDATIONS.EMAIL_INVALID }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayoutAlign="start start" class="user" *ngIf="user !== null">
        <div fxLayout="row" fxLayoutAlign="start center" class="small-font">
          <img src="{{ (user.avatar !== null) ? user.avatar : null | placeholderImage:'user' }}" width="80" class="avatar corner-radius"
          />
          {{ user.name }}
        </div>
        <span class="user-exist mt-16">Usuário encontrado em nossa base de dados.</span>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" class="mt-16" *ngIf="user === null">
        <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8">
          <input matInput placeholder="{{ translate.ATHLETE.FIRSTNAME }}" formControlName="firstname" [errorStateMatcher]="matchers.firstname" uppercase required>
          <mat-error *ngIf="competitionForm.controls.firstname.hasError('required')">
            {{ translate.VALIDATIONS.FIRSTNAME_REQUIRED }}
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8">
          <input matInput placeholder="{{ translate.ATHLETE.LASTNAME }}" formControlName="lastname" [errorStateMatcher]="matchers.lastname"
            uppercase required>
          <mat-error *ngIf="competitionForm.controls.lastname.hasError('required')">
            {{ translate.VALIDATIONS.LASTNAME_REQUIRED }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" class="mt-16">
        <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8">
          <input matInput placeholder="{{ translate.ATHLETE.LEADERBOARD_NAME }}" formControlName="leaderboardName" (blur)="leaderboardNameBlur($event)" (focus)="leaderboardNameFocus($event)" [errorStateMatcher]="matchers.leaderboardName" uppercase required>
          <mat-error *ngIf="competitionForm.controls.leaderboardName.hasError('required')">
            {{ translate.VALIDATIONS.LEADERBOARDNAME_REQUIRED }}
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8">
          <input matInput placeholder="{{ translate.ATHLETE.BOX }}" formControlName="box" [matAutocomplete]="autoBox" (ngModelChange)="searchBox($event)" uppercase>
          <mat-autocomplete #autoBox="matAutocomplete" [displayWith]="displayFnBox">
            <mat-option *ngFor="let box of boxes" [value]="box">
              {{ box.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      
      <div fxLayout="row" fxLayoutAlign="start start" class="mt-16">
        <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8">
          <mat-select placeholder="{{ translate.ATHLETE.TSHIRT }}" formControlName="tShirt">
            <mat-option *ngFor="let size of tshirtSize" [value]="size">
              {{ size }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </form>

    <div class="mt-16 mb-8 text-red" *ngIf="requestErrors !== null">
      {{ requestErrors }}
    </div>

    <div class="mb-0 mt-8" fxLayoutAlign="end center">
      <btn type="grayscale" (click)="dialogRef.close(null)" class="mr-16">{{ translate.CANCEL | uppercase }}</btn>
      <btn [btnLoading]="true" [loading]="isLoading" [buttonDisabled]="competitionForm.invalid" (click)="createCompetitor()">{{ translate.SAVE | uppercase }}</btn>
    </div>
  </div>
</div>