import { TableXComponent } from './../table-x.component';
import { TableXColumnComponent } from './../table-x-column/table-x-column.component';
import { Component, OnInit, Input, Inject, forwardRef, Output, EventEmitter } from '@angular/core';
import { TableConfig } from '../table-x.component';

@Component({
  selector: '[row]',
  templateUrl: './table-x-row.component.html',
  styleUrls: ['./table-x-row.component.scss']
})
export class TableXRowComponent implements OnInit {

  @Input() row: any;
  @Input() columns: TableXColumnComponent[];
  @Input() selected = false;
  @Input() config: TableConfig;
  @Input() index: number;

  @Output() rowEvent = new EventEmitter();

  constructor(
    @Inject(forwardRef(() => TableXComponent)) public dataTable: TableXComponent
  ) { }

  ngOnInit() {
    
  }

  checkboxAction(event)
  {
    this.selected = event.checked;
    this.rowEvent.emit({
      type: 'checkbox',
      row: this.row,
      index: this.index,
      checked: event.checked
    });
  }

  trackByColumn(index, item) {
    return index;
  }

  
}
