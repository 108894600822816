export const locale = {
  lang: 'pt-BR',
  data: {
    'HEAT_HEATCOMPETITORS': {
      'TABS': {
        'SINGLE': 'Individual',
        'TEAM': 'Time',
      },
      'HEATS_ITEMS' : {
        'HEATS': 'Baterias',
        'SUBMIT' : 'Salvar baterias'
      },
      'FILTER_COMPETITORS': {
        'COMPETITORS': 'Competidores',
        'FIELDS': {
          'WODS': 'Wods',
          'COMPETITIONS': 'Categorias',
          'SEARCH': 'Procure por nome',
        },
        'VALIDATIONS': {
          'WODS_REQUIRED': 'Wods é obrigatório',
          'COMPETITIONS_REQUIRED': 'Categorias é obrigatório',
        },
        'EMPTY' : 'Não existem categorias cadastradas.',
        'TOASTR': {
          'WODS': {
            'TITLE': 'WODS',
            'DESCRIPTION': 'Não existe nenhum wod cadastrado para a categoria selecionada.',
          },
        },
      },
      'COMPETITOR_DATAGRID': {
        'BUSY_LANES': 'Lanes ocupadas',
        'OF': 'de',
      },
      'HEATS_GENERATOR': {
        'FIELDS': {
          'NUMBER_HEATS': 'Número de baterias',
          'NUMBER_LANES': 'Competidores por baterias',
        },
        'VALIDATIONS': {
          'NUMBER_HEATS_REQUIRED': 'Número de baterias é obrigatório',
          'NUMBER_LANES_REQUIRED': 'Competidores por baterias é obrigatório',
        },
        'SUBMIT': 'Gerar baterias',
      },
      'HEATS_SCHEDULE': {
        'SCHEDULE': 'Horários',
        'TITLE_CONTENT': 'Programar baterias',
        'FIELDS': {
          'DUE_DATE': 'Data de início',
          'START_TIME': 'Hora de início',
          'DURATION': 'Duração (min)',
          'TRANSITION': 'Transição (min)',
        },
        'VALIDATIONS': {
          'DUE_DATE_REQUIRED': 'Data de início é obrigatório',
          'START_TIME_REQUIRED': 'Hora de início é obrigatório',
          'DURATION_REQUIRED': 'Duração é obrigatório',
          'TRANSITION_REQUIRED': 'Transição é obrigatório',
        },
        'SUBMIT_GENERATE_SCHEDULE': 'Gerar',
        'SUBMIT_SCHEDULE': 'Salvar',
        'TABLE' : {
          'HEAT': 'Bateria',
          'DESCRIPTION': 'Descrição',
          'DATE': 'Data',
          'HOUR': 'Hora'
        },
        'EMPTY' : 'Não existem baterias cadastradas para programar.',
        'TOASTR': {
          'GENERATE': {
            'TITLE': 'Falha ao programar baterias!',
            'DESCRIPTION': 'Nenhuma bateria foi selecionada.',
          },
          'UPDATE': {
            'TITLE': 'Baterias alteradas com sucesso!',
            'DESCRIPTION': 'Baterias alteradas.',
          },
        },
      },
      'TITLE': 'Baterias',
      'TABLE': {
        'ID' : 'ID',
        'NAME': 'Nome',
        'LANE': 'Lane',
        'COMPETITOR': 'Competidor',
        'RANK': 'Rank',
        'COMPETITION': 'Categoria',
        'WOD': 'WOD'
      },
      'EMPTY' : 'Não existem categorias cadastradas.',
    }
  }
};
