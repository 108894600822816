<div class="page-layout blank pb-64" id="scrollbar">
  <div class="p-32">
    <div class="content">
      <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
          <div class="p-32">
            <description-section
              title="{{'LEADERBOARD.TITLE' | translate}}"
              text="{{'LEADERBOARD.DESCRIPTION' | translate}}">
              <mat-icon class="ic-info mr-8" svgIcon="ic-info"></mat-icon>
            </description-section>
          </div>
          
          <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="publish-ranking pl-32 pr-32 pt-12 pb-12" *ngIf="!isResultPublised">
            <div>
               {{'LEADERBOARD.PUBLISH_RANKING' | translate}}<br>
               <a href="https://{{ this.event.slug }}.{{ domain }}/leaderboard" target="_blank" class="see-leaderboard">{{'LEADERBOARD.SEE_RANKING' | translate}}</a>
            </div>
          </div>

          <div class="mt-16">
            <tab-bar
              #tabbar
              [padding]="true"
              (tabEvent)="tabAction($event)"
              [items]="tabsFilter"
              [tabActive]="0">
            </tab-bar>
          </div>

          <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="pl-32 pr-32 pt-16 pb-12 mb-16 search line-bottom">
            <div fxFlex="80" fxFlex.xs="100" fxLayout="row wrap" fxLayoutAlign="start start">
              <mat-form-field fxFlex="50" fxFlex.xs="100" floatLabel="never" class="search">
                <mat-icon matPrefix class="icon-search">search</mat-icon>
                <input
                  matInput
                  placeholder="{{ 'LEADERBOARD.SEARCH' | translate}}"
                  [(ngModel)]="rankingFilter.competitor.fullnameSort">
              </mat-form-field>

              <mat-form-field *ngIf="competition?.enable_sub_division" fxFlex="40" fxFlex.xs="100" ngClass="ml-8 sub-division" style="margin-top: 0.05em" ngClass.xs="ml-0 sub-division">
                <mat-select placeholder="{{ championship.sub_division_group_name ? (championship.sub_division_group_name | titlecase) : ('LEADERBOARD.FORM.SUB_DIVISION' | translate) }}" [value]="subDivisionId" (selectionChange)="subDivisionSelect($event)">
                  <mat-option [value]="0">{{ 'LEADERBOARD.FORM.ALL' | translate | uppercase }}</mat-option>
                  <mat-option *ngFor="let subDivision of subDivisions" [value]="subDivision.id">
                    {{ subDivision.name | uppercase }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            
            <btn 
              [matMenuTriggerFor]="optionsButton"
              [btnLoading]="true"
              [loading]='isLoadingExport'
              type="grayscale"
              iconAlign="left"
              icon="common/ic-settings.svg"
            > 
              {{ 'LEADERBOARD.OPTIONS' | translate }}
            </btn>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" class="p-32 emtpy-content" *ngIf="isEmptyResult">
            <img src="./assets/icons/placeholder/ic-empty.svg" />
            <span class="mt-8">{{ 'LEADERBOARD.EMPTY' | translate }}</span>
          </div>

          <!-- Table -->
          <table-x #tableList [items]="results | filterBy: rankingFilter" [config]="tableConfig" [isLoading]="isLoading" *ngIf="!isEmptyResult" (sortEvent)="sortAction($event)">
            <column property="rank" header="{{ 'LEADERBOARD.TABLE_HEADER.RANKING' | translate }}" [sortable]="true" align="center" width="40px">
              <ng-template #dataTableCell let-item="item">
                <div *ngIf="item?.competitor?.disqualified == 0">
                  <div fxLayout="column" class="small-font" *ngIf="!subDivisionId">
                    <span>{{ item.competitor?.rank }}</span>
                    <span>({{ item.total }})</span>
                  </div>
                  <div fxLayout="column" class="small-font" *ngIf="subDivisionId">
                    <span>{{ item?.competitor?.sub_division_rank }}</span>
                    <span>({{ item?.sub_division_total }})</span>
                  </div>
                </div>
              </ng-template>
            </column>

            <column property="name" header="{{ 'LEADERBOARD.TABLE_HEADER.NAME' | translate }}" [sortable]="true" align="left">
              <ng-template #dataTableCell let-item="item">
                <a fxLayout="row" fxLayoutAlign="start center" class="small-font">
                  <img src="{{ (item.competitor.avatar) ? item.competitor.avatar : null | placeholderImage:'user' }}" width="80" class="avatar corner-radius" /> 
                  <span fxLayout="column">
                      {{ item.competitor.fullname }}
                      <span class='box'>{{ (item.competitor?.team?.box) ? item.competitor?.team?.box.name : ''}}</span>
                  </span>
                  <label-x class="ml-8" *ngIf="item.competitor.disqualified">{{ 'LEADERBOARD.DISQUALIFIED.TYPE_WD' | translate }}</label-x>
                  <label-x class="ml-8" *ngIf="item.competitor.is_cut">{{ 'LEADERBOARD.DISQUALIFIED.TYPE_CUT' | translate }}</label-x>
                </a>
              </ng-template>
            </column>

            <column *ngFor="let wod of wods, let i = index;" [property]="wod.id" [header]="wod.name" [sortable]="true" align="center" classes="border-left" width="150px">
              <ng-template #dataTableCell let-item="item">
                <div fxLayout="row" fxLayoutAlign="space-around start" class="row-result" *ngIf="getResultObj(wod.id, item.competitor.results) as itemResult">
                  <div fxLayout="column" class="pl-4 pr-4">
                    <div fxFlexAlign="space-between center">
                      <span class="result" fxFlex="100">{{ formatResult(itemResult, wod, false, 'result') }}</span>
                      <mat-icon style="font-size: 15px; cursor: pointer; color: #94a5b2" *ngIf="itemResult.status === 2" matTooltip="Recusado" matTooltipPosition="above">info</mat-icon>
                    </div>
                    <!-- <span class="result" fxFlex="100">{{ getResultPerWod(wod.id, item.competitor.results) }}</span> -->
                    <span
                      class="tiebreak"
                      fxFlex="100">
                       {{ formatResult(itemResult, wod, true, 'result') }}
                      <!-- *ngIf="item.competitor.results[i] && item.competitor.results[i].event.tiebreak_type_id !== null"> -->
                      <!-- {{ (item.competitor.results[i]) ? formatResult(item.competitor.results[i], true, 'result') : '-' }} -->
                    </span>
                  </div>
                  
                  <div fxLayout="column" class="pl-4 pr-4">
                    <span class="result">{{ formatResult(itemResult, wod, false, subDivisionId ? 'sub_division_rank' : 'rank') }}</span>
                    <span class="tiebreak">{{ formatResult(itemResult, wod, true, 'rank') }}</span>
                  </div>
                </div>                
              </ng-template>
            </column>
          </table-x>
      </div>
    </div>
  </div>
</div>

<!-- MAT-MENU OPTIONS disqualified -->
<mat-menu #optionsButton="matMenu" class="bottom arrow-right" [overlapTrigger]="false" xPosition="before">
  <ng-template matMenuContent>
    <div class="submenu" fxLayout="column">
      <button mat-menu-item (click)="reorderAthletesTie()" *ngIf="rankingReorder.length > 0" class="line-bottom">
        <span>
          <mat-icon class="mr-0">swap_vert</mat-icon>
          {{ 'LEADERBOARD.REORDER' | translate}}
        </span>
      </button>
      <button mat-menu-item (click)="exportRanking()" class="line-bottom">
        <span>
          <img class="mx-4" src="./assets/icons/content/ic-export.svg" alt="{{ 'LEADERBOARD.REORDER' | translate}}" />
          {{ 'LEADERBOARD.EXPORT' | translate}}
        </span>
      </button>
      <button mat-menu-item (click)="exportAllRankings()" class="line-bottom">
        <span>
          <img class="mx-4" src="./assets/icons/content/ic-export.svg" alt="{{ 'LEADERBOARD.REORDER' | translate}}" />
          {{ 'LEADERBOARD.EXPORT_ALL' | translate}}
        </span>
      </button>
    </div>
  </ng-template>
</mat-menu>

<btn-fab [loading]="isLoadingPublish" [btnLoading]="true" materialIcon="publish" (click)="publishRanking()" *ngIf="!isResultPublised"></btn-fab>
<scroll-to-top 
    scrollId="scrollbar" [animate]="true" [speed]="50" [acceleration]="2" [scrollDistance]="300">
</scroll-to-top>