export const locale = {
  lang: 'en-US',
  data: {
    'WODS': {
      'TITLE': 'Wods',
      'COPY': 'Copiar WOD\'s',
      'CONTENT_EMPTY': 'Nenhum WOD cadastrado',
      'ADD': 'Adicionar',
      'ADD_NEW': 'Adicionar novo WOD',
      'TABLE': {
        'NAME': 'Nome',
        'RESULT': 'Resultado',
        'TIEBREAK': 'Tiebreak'
      },
      'TOASTR': {
        'CREATE': {
          'TITLE': 'WOD criado com sucesso!',
          'DESCRIPTION': '',
        },
        'EDIT': {
          'TITLE': 'WOD editado com sucesso!',
          'DESCRIPTION': '',
        },
        'DELETE': {
          'TITLE': 'WOD deletado com sucesso!',
          'DESCRIPTION': '',
          'ERROR_DELETE_TITLE': 'Erro ao deletar o WOD!',
          'ERROR_DELETE_DESCRIPTION': 'Já existe resultado postado nesse WOD.',
          'ERROR_DELETE_DESCRIPTION_HEAT': 'Já existem baterias cadastradas nesse WOD.'
        },
        'COPY': {
          'TITLE': 'WOD copiados com sucesso!',
          'DESCRIPTION': '',
        }
      },
      'DIALOG': {
        'TITLE': 'Excluir Wod',
        'DESCRIPTION': 'Tem certeza que deseja excluir o wod <strong>{{ wod_name }}</strong>?<br><br>Após esta ação não poderá ser revertido.',
        'EXCLUIR': 'Excluir',
        'CANCEL': 'Cancelar',
      },
      'DELETE': 'Deletar',
      'DATE_FORMAT': 'dd/MM/yyyy',
      'DATETIME_FORMAT': 'dd/MM/yyyy HH:mm:ss',
    },
  }
};
