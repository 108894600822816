import {Team} from './../team';

export class ResultUserDashboard {
  id?: number = null;
  competitor_id: number;
  rank: number;
  published_rank: number;
  global_rank: number;
  published_global_rank: number;
  event_id: number;
  wod_id: number;
  competition_id: number;
  championship_id: number;
  disqualified: boolean;
  published: boolean;
  video?: string = null;
  status?: number;
  has_cap = false;
  result = '';
  tiebreakresult = '';
  points?: number;
  published_points?: number;
  name?: string = null;
  avatar?: string = null;
  competitor?: {
    id: number
    team_id: number
    team: Team
  };
  error?: string;

  deserialize(input: any) {
    (<any>Object).assign(this, input);

    const team = (input.competitor.team) ? new Team().deserialize(input.competitor.team) : null;
    this.competitor.team = team;
    if (team && team.type === 'single' && team.members && team.members.length > 0) {
      this.name = (team.members[0].leaderboard_name) ? team.members[0].leaderboard_name : team.members[0].user.fullname;
      this.avatar = team.members[0].user.avatar ? team.members[0].user.avatar : null;
    } else {
      this.name = team.name;
    }

    return this;
  }
}
