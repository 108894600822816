import { Breadcrumb } from './breadcrumb';
import { Injectable } from '@angular/core';

@Injectable()
export class BreadcrumbsService {

  constructor() { }

    public breadcrumbs: Breadcrumb[] = []

    addItem(item: Breadcrumb)
    {
        this.breadcrumbs.push(item)
    }

    replaceAll(items: Breadcrumb[])
    {
        this.removeAll()
        items.forEach(item => {
            this.breadcrumbs.push(item);    
        });
    }

    removeLastItem()
    {
        this.breadcrumbs.splice(this.breadcrumbs.length-1, 1)
    }

    removeAll()
    {
        //this.breadcrumbs = new[]
        this.breadcrumbs.splice(0, this.breadcrumbs.length)
    }

}
