import { WorkoutsEventComponent } from './workouts-event.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';

const routes = [
  {
    path: 'workouts',
    component: WorkoutsEventComponent,
    // canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    WorkoutsEventComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot(),
  ],
  exports: [
    WorkoutsEventComponent
  ]
})
export class WorkoutsEventModule { }
