export class SubscriptionPayment {
  id: number
  amount: number
  coupon: string
  lot: string
  name: string
  paymentDate?: Date
  paymentMethod?: string
  receiveDate?: Date
  status?: string
  statusCode?: number
  taxAmout?: number
  boleto_url?: string = null
  isBoleto?: boolean = false

  deserialize(object: any) {
    (<any>Object).assign(this, object);

    if (object.boleto_url && object.boleto_url.length > 0) {
      this.isBoleto = true
    }

    return this;
  }
}