import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material';
import { BtnFabComponent } from './btn-fab.component';
import { MaterialModule } from '../../modules/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MaterialModule,
    FlexLayoutModule
  ],
  declarations: [
    BtnFabComponent
  ],
  exports: [
    BtnFabComponent,
    MaterialModule,
    FlexLayoutModule
  ],
  providers: [
  ]
})

export class BtnFabModule { }
