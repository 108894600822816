export const locale = {
    lang: 'pt-BR',
    data: {
        'NAV': {
          'DASHBOARD': {
            'TITLE': 'Dashboard',
            'COMPONENTS': 'Componentes',
            'MANAGEMENT': 'Gerencial',
            'TRAINING': 'Treino',
            'CLIENT': 'Cliente',
            'FINANCIAL': 'Finaneiro'
          },
          'EVENTCONFIG': {
            'TITLE': 'Configurações',
            'INFORMATIONS': 'Informações',
            'SUBSCRIPTIONS': 'Inscrições',
            'CHAMPIONSHIPS': 'Etapas',
            'LEADERBOARD': 'Geral',
            'PERMISSIONS': 'Permissões',
            'APP_CROSSX': 'Aplicativo CrossX'
          },
          'PARTICIPANTS': {
            'TITLE': 'Participantes'
          },
          'CUPONS': {
            'TITLE': 'Cupons'
          },
          'POINTS': {
            'TITLE': 'Pontos'
          },
          'SUBSCRIPTIONS': {
            'TITLE': 'Inscrições'
          },
          'CHAMPIONSHIPS': {
            'TITLE': 'Etapas',
            'TITLE_PLURAL': 'Etapas',
            'TICKETS': 'Ingressos',
            'PARTICIPANTS': 'Participantes',
            'WODS': `Wods`,
            'RESULTS': 'Resultados',
            'LEADERBOARD': 'Leaderboard',
            'CATEGORY': 'Categorias',
            'HEATS': 'Baterias',
            'SUB_DIVISION': 'Sub-categorias',
            'HEATS-TESTE': 'Heats-TESTE',
            'LOTS': 'Ingressos',
          },
          'USER_DASHBOARD': {
            'INFORMATION': 'Informações',
            'RESULTS': 'Resultados',
            'PAYMENTS': 'Pagamentos',
            'LEADERBOARD': 'Leaderboard'
          }
        }
    }
};
