import {Competition} from './competition';
import {Team} from './team';
import {Invitation} from './invitation.model';
import { SubDivision } from './sub-division';

export class Championship {
  id?: number;
  event_id?: number;
  name?: string;
  sub_division_group_name?: string;
  enable_sub_division?: number;
  sub_divisions?: SubDivision[];
  limit?: number;
  type?: number;
  online?: boolean;
  is_invitation = false;
  competitions?: Competition[] = null;
  competition?: Competition = null;
  team?: Team = null;
  invitation?: Invitation = null;
  error = '';
  created_at?: Date;
  updated_at?: Date;

  deserialize(input: any) {
    (<any>Object).assign(this, input);

    if (input.competitions && input.competitions.length > 0) {
      this.competitions = [];
      input.competitions.forEach(competition => {
        this.competitions.push(new Competition().deserialize(competition));
      });
    }
    
    this.team = input.team ? new Team().deserialize(input.team) : null;
    this.invitation = input.invitation ? new Invitation().deserialize(input.invitation) : null;
    this.competition = input.competition ? new Competition().deserialize(input.competition) : null;
    
    return this;
  }
}
