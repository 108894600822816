import { UserBankAccount } from './user-bank-account';
import { User } from './user';
import { Lot } from './lot';
import { BankAccount } from './bank-account';
import { Championship } from './championship';
import { forEach } from '@angular/router/src/utils/collection';
import * as moment from 'moment';
import { Invitation, InvitationStatus } from './invitation.model';
import {
  CustomField,
  CustomFieldSubscriptionType,
  CustomFieldTypeForm
} from './custom-field.model';

export class Event {
  id?: number;
  user_id: number;
  user?: User;
  name?: string;
  slug?: string = null;
  description?: string | null = null;
  type?: string;
  datetime_start?: Date;
  datetime_end?: Date;
  // datetime_start?: {
  //   date?: Date
  //   timezone_type?: number
  //   timezone?: string
  // }
  // datetime_end?: {
  //   date?: Date
  //   timezone_type?: number
  //   timezone?: string
  // }
  timezone?: string;
  location?: string;
  address?: string;
  place_id?: string;
  header_image?: string;
  email_logo?: string;
  soft_descriptor?: string;
  private?: boolean;
  default_gateway_id?: number = null;
  bank_account?: BankAccount;
  paymentMethodsStrings?: string[] = [];
  paymentMethods?: PaymentMethod[];
  installments = 1;
  boleto_days_due?: number;
  recipient_percentage?: number;
  recipient_id?: string;
  published?: boolean;
  leaderboard_published?: boolean;
  has_bank_account?: boolean;
  enabled_leaderboard?: boolean;
  enabled_subscription?: boolean;
  enable_sub_division = false;
  enabled_heats = false;
  published_heats = false;
  doc_rule?: string;
  doc_term?: string;
  user_bank_account?: any;
  lot_sold_off = false;
  enabled_nfe = false;
  lot_invitation?: boolean;
  championships?: Championship[];
  lots?: Lot[];
  custom_fields?: CustomField[];
  event_configurations?: any[];
  isResultTimeInMilliseconds = false;
  isShowBrandInLeaderboard = false;
  maxDateUserEdit?: Date = null;
  success_event_url?: string;
  enable_checkin_online = false;

  created_at: Date;
  updated_at: Date;

  deserialize(input: any) {
    (<any>Object).assign(this, input);

    if (input.user) { this.user = new User().deserialize(input.user); }
    // if (input.user_bank_account) this.user_bank_account = new UserBankAccount().deserialize(input.user_bank_account)
    if (input.championships && input.championships.length > 0) {
      this.championships = [];
      input.championships.forEach(championship => {
        this.championships.push(new Championship().deserialize(championship));
      });
    }

    if (input.installments === 0) {
      this.installments = 1;
    }

    if (input.payment_methods) {
      this.paymentMethods = input.payment_methods.map((event: Event) =>
        new Event().deserialize(event)
      );
      this.paymentMethods.forEach(paymentMethod => {
        if (
          paymentMethod.name === 'credit_card' &&
          paymentMethod.installments !== null
        ) {
          this.installments = paymentMethod.installments;
        }
        this.paymentMethodsStrings.push(paymentMethod.name);
      });
      // this.paymentMethods = input.payment_methods.split(',')
    }

    let eventConfigurations =
      input.event_configurations && input.event_configurations.length > 0
        ? input.event_configurations
        : null;
    if (
      eventConfigurations === null &&
      input.configuration &&
      input.configuration.length > 0
    ) {
      eventConfigurations = input.configuration;
    }

    if (eventConfigurations !== null) {
      eventConfigurations.forEach(eventConfig => {
        if (eventConfig.name === 'result-time-in-milliseconds') {
          this.isResultTimeInMilliseconds = parseInt(eventConfig.value) == 1;
        } else if (eventConfig.name === 'leaderboard-show-brand') {
          this.isShowBrandInLeaderboard = parseInt(eventConfig.value) == 1;
        } else if (eventConfig.name === 'enabled-heats') {
          this.enabled_heats = parseInt(eventConfig.value) == 1;
        } else if (eventConfig.name === 'published-heats') {
          this.published_heats = parseInt(eventConfig.value) == 1;
        } else if (eventConfig.name === 'enable-sub-division') {
          this.enable_sub_division = parseInt(eventConfig.value) === 1;
        } else if (eventConfig.name === 'max-date-user-edit') {
          this.maxDateUserEdit = moment(eventConfig.value).toDate();
        }
      });

      if (this.maxDateUserEdit === null) {
        this.maxDateUserEdit = this.datetime_start;
      }
    }

    if (input.custom_fields && input.custom_fields.length > 0) {
      this.custom_fields = [];
      input.custom_fields.forEach(customField => {
        this.custom_fields.push(new CustomField(customField));
      });
    }

    return this;
  }

  hasInvitation(): boolean {
    let hasInvitationPedding = false;
    this.championships.forEach(championship => {
      if (
        championship.is_invitation &&
        (championship.invitation.status === InvitationStatus.PENDING ||
          championship.invitation.status === InvitationStatus.REFUSED)
      ) {
        hasInvitationPedding = true;
      }
    });

    return hasInvitationPedding;
  }

  public getCustomFieldWithSubscriptionType(type: CustomFieldSubscriptionType) {
    return this.custom_fields.filter(customField => {
      return (
        customField.subscription_type === type ||
        customField.subscription_type === null
      );
    });
  }
}

export class PaymentMethod {
  id: number;
  name: string;
  soft_descriptor?: string;
  boleto_days_due?: number;
  installments?: number;
  interest_rate?: number;

  deserialize(input: any) {
    (<any>Object).assign(this, input);

    return this;
  }
}

export enum EventConfigation {
  ENABLED_SUBSCRIPTION = 'enabled_subscription',
  ENABLED_LEADERBOARD = 'enabled_leaderboard',
  RESULT_TIME_MILLISECONDS = 'result-time-in-milliseconds',
  ENABLED_HEATS = 'enabled-heats',
  PUBLISHED_HEATS = 'published-heats',
  MAX_DATE_USER_EDIT = 'max-date-user-edit',
  SUCCESS_EVENT_URL = 'success-event-url',
  START_CHECKIN_ONLINE = 'start-checkin-online',
  END_CHECKIN_ONLINE = 'end-checkin-online',
  ENABLED_CHECKIN_FILE = 'enabled-checkin-file',
  LEADERBOARD_CUT_LINE_COLOR = 'leaderboard-cut-line-color'
}
