export const locale = {
  lang: 'pt-BR',
  data: {
    'SETTINGS': {
      'TITLE': 'Configurações',
      'TABS': {
        'EVENT': 'O EVENTO',
        'REGISTRATIONS': 'INSCRIÇÕES',
        'STEPS': 'ETAPAS',
        'LEADERBOARD': 'LEADERBOARD'
      },
      'EVENT_STATUS': {
        'PUBLISHED': 'Publicado',
        'NOT_PUBLISHED': 'Não publicado'
      },
      'EVENT_PAGE': 'Página do evento',
      'SAVE': 'SALVAR',
      'CANCEL': 'CANCELAR',
      'PUBLISH_EVENT': 'PUBLICAR EVENTO',
      'UNPUBLISH_EVENT': 'DESPUBLICAR EVENTO',
      'EVENT_PUBLISH_DIALOG': {
        'TITLE': 'Tem certeza que deseja {{action}}?',
        'DESCRIPTION': 'Você tem certeza que deseja {{action}} este evento.',
        'PUBLISH': 'Publicar',
        'UNPUBLISH': 'Despublicar',
      },
      'TOASTR': {
        'PUBLISH_TITLE': 'Evento publicado com sucesso!',
        'PUBLISH_DESCRIPTION': 'Seu evento foi publicado e está disponível para todos',
        'UNPUBLISH_TITLE': 'Evento despublicado com sucesso!',
        'UNPUBLISH_DESCRIPTION': 'Seu evento foi despublicado e o seu link não está mais público.',
        'UPDATE': {
          'SUCCESS': {
            'TITLE': 'Evento atualizado com sucesso!',
            'DESCRIPTION': 'Evento atualizado.'
          },
          'ERROR': {
            'TITLE': 'Erro ao atualizar o evento!',
          },
        }
      },
      'DATE_FORMAT': 'DD/MM/YYYY',
      'DATETIME_FORMAT': 'DD/MM/YYYY HH:mm:ss',
      'FIELDS': {
        'NAME': 'Nome do evento',
        'ADDRESS': 'Endereço',
        'LOCATION': 'Local',
        'TIMEZONE': 'Fuso horário do local',
        'DATE_START': 'Data inicial',
        'DATE_END': 'Data final',
        'TIME_START': 'Horário de início',
        'TIME_END': 'Horário de término',
        'EVENT_URL': 'URL do evento',
        'DESCRIPTION': 'Descrição',
        'REGULATION': 'Regulamento',
        'SEE_REGULATION': 'Ver o regulamento',
        'TERMS': 'Termos',
        'SEE_TERMS': 'Ver os termos'
      },
      'VALIDATIONS': {
        'NAME_REQUIRED': 'Nome do evento é obrigatório',
        'ADDRESS_REQUIRED': 'Endereço do evento é obrigatório',
        'LOCATION_REQUIRED': 'Local do evento é obrigatório',
        'TIMEZONE_REQUIRED': 'O fuso horário do evento é obrigatório',
        'DATE_INVALID': 'O formato da data é inválida',
        'DATE_START_REQUIRED': 'Data de início é obrigatório',
        'DATE_END_REQUIRED': 'Data de término é obrigatório',
        'URL_EVENT_REQUIRED': 'A url do evento é obrigatório',
        'URL_EVENT_MINLENGTH': 'Mínimo de {{count}} caracteres',
        'DESCRIPTION_REQUIRED': 'A descrição é obrigatório',
        'REGULATION_REQUIRED': 'O regulamento é obrigatório'
      },
      'SELECT': 'SELECIONAR',
      'IMAGES': {
        'BRAND': 'Logo',
        'HEADER': 'Cabeçalho',
        'DESCRIPTION_BRAND': 'Tamanho recomentado: 500px X 500px.<br>Tamanho máximo de 5MB.<br>Tipos de imagem aceitos: JPG ou PNG.',
        'DESCRIPTION_HEADER': 'Tamanho recomentado: 1000px X 320px.<br>Tamanho máximo de 5MB.<br>Tipos de imagem aceitos: JPG ou PNG.',
        'SELECT_BRAND': 'SELECIONAR LOGO',
        'SELECT_IMAGE': 'SELECIONAR IMAGEM',
      },
      'REGISTRATION': {
        'USER_BANK_ACCOUNT': 'Conta bancária',
        'NEW_ACCOUNT': '+ Criar nova conta',
        'PAYMENT_METHOD': 'Formas de pagamento',
        'PAYMENT_METHOD_DESCRIPTION': 'Os dados do cartão de crédito serão criptografados, usando SSL, antes de serem enviados para o nosso gateway de pagamento.<br>Nenhum dado sensível será armazenado em nossos servidores.',
        'CREDIT_CARD': 'Cartão de crédito',
        'CREDIT_CARD_REQUIRED': '* A cobrança via cartão de crédito é obrigatória.',
        'BOLETO': 'Boleto',
        'SOFT_DESCRIPTION': 'Descrição na fatura',
        'SOFT_DESCRIPTION_REQUIRED': 'Descrição na fatura é obrigatória',
        'DAYS_DUE_DATE': 'Dias para o vencimento do boleto',
        'DAYS_DUE_DATE_REQUIRED': 'Dias para o vencimento do boleto obrigatório',
        'DAYS_DUE_DATE_MIN_VALUE': 'O valor mínimo é 2 dias',
        'BANK_ACCOUNT': 'Dados para recebimento',
        'TYPE_PERSON': 'Tipo de pessoa',
        'TYPES_PERSON': {
          'PHYSICAL': 'Pessoa física',
          'LEGAL': 'Pessoa jurídica'
        },
        'NAME': 'Nome',
        'SOCIAL_NAME': 'Razão social',
        'NAME_REQUIRED': 'O campo nome é obrigatório',
        'CPF': 'CPF',
        'CNPJ': 'CNPJ',
        'BANK': 'Banco',
        'BANK_REQUIRED': 'O banco é obrigatório',
        'BANK_MINLENGTH': 'O banco deve conter no mínimo 3 digitos',
        'TYPE_ACCOUNT': 'Tipo de conta',
        'TYPES_ACCOUNT': {
          'CC': 'Conta corrente',
          'CP': 'Conta poupança',
          'CCC': 'Conta corrente conjunta',
          'CPC': 'Conta poupança conjunta'
        },
        'AGENCY': 'Agência',
        'AGENCY_REQUIRED': 'A agência é obrigatória',
        'AGENCY_DV': 'Agência DV',
        'ACCOUNT': 'Conta corrente',
        'ACCOUNT_REQUIRED': 'A conta corrente é obrigatória',
        'ACCOUNT_DV': 'Conta DV',
        'ACCOUNT_DV_REQUIRED': 'Obrigatório',
        'CPF_REQUIRED': 'O CPF é obrigatório',
        'CNPJ_REQUIRED': 'O CNPJ é obrigatório',
        'MAX_LENGTH_ERROR': 'Limite de {{count}} caractéres',
        'TOASTR': {
          'PAYMENT_METHODS': {
            'TITLE': 'Formas de pagamento atualizado com sucesso!',
            'DESCRIPTION': 'Os métodos de pagamentos foram atualizados.',
            'ERROR': 'Erro ao atualizar as formas de pagamentos.'
          },
          'BANKACCOUNT': {
            'UPDATE': {
              'TITLE': 'Conta bancária alterada com sucesso!',
              'DESCRIPTION': 'Conta bancária alterada.',
              'ERROR': 'Erro ao atualizar a conta bancária'
            },
            'CREATE': {
              'TITLE': 'Conta bancária criada com sucesso!',
              'DESCRIPTION': 'Conta bancária criada.',
              'ERROR': 'Erro ao criar conta bancária'
            }
          }
        }
      },
      'STEPS': {
        'NAME_STEP': 'Nome da etapa',
        'RANKING_TYPE': 'Tipo do ranking',
        'POINTS': 'Pontos',
        'RANK': 'Colocação',
        'NEW': 'Adicionar etapa',
        'VALIDATION': {
          'NAME_REQUIRED': 'O Campo nome é obrigatório'
        },
        'RESPONSE': {
          'TITLE': 'Etapas salvas com sucesso',
          'DESCRIPTION': 'As etapas foram salvas com sucesso'
        }
      },
      'LEADERBOARD': {
        'SETTINGS': 'Configuração do leaderboard',
        'SETTINGS_DESCRIPTION': 'Configure as cores do leaderboard',
        'PREVIEW': 'VISUALIZAR',
        'SETTINGS_TV': 'Configuração do leaderboard tv',
        'SETTINGS_TV_DESCRIPTION': 'Configure as cores do leaderboard tv',
        'SETTINGS_TV_CATEGORY': 'Configure a ordem das categorias no leaderboard tv.',
        'STEPS': 'Etapas',
        'CATEGORIES': 'Categorias',
        'SPLIT_LEFT': 'Coluna da esquerda',
        'SPLIT_RIGHT': 'Coluna da direita',
        'BRAND': 'Logo',
        'DESCRIPTION_BRAND': 'Tamanho recomentado: 500px X 500px.<br>Tamanho máximo de 5MB.<br>Tipos de imagem aceitos: JPG ou PNG.',
        'SELECT_BRAND': 'SELECIONAR LOGO',
        'COLOR_BACKGROUND': 'Cor de fundo',
        'COLOR_LINE_DARK': 'Cor da linha escura',
        'COLOR_TEXT': 'Cor do texto',
        'COLOR_NAME_BOX': 'Cor do nome do box',
        'COLOR_HIGHLIGHT': 'Cor das melhores pontuações',
        'COLOR_EVENT_NAME': 'Cor do nome do evento',
        'COLOR_CATEGORY': 'Cor da categoria',
        'COLOR_BACKGROUND_CELL': 'Cor do fundo da célula',
        'COLOR_PROGRESS_BAR': 'Cor da barra de loading',
        'LEADERBOARD_BRAND': 'Exibir logo do evento no leaderboard',
        'VALIDATIONS': {
          'FIELD_REQUIRED': 'Campo obrigatório'
        },
        'STATUS': {
          'PUBLISH': 'Publicar leaderboard',
          'UNPUBLISH': 'Despublicar leaderboard'
        },
        'PUBLISH_DIALOG': {
          'TITLE': 'Tem certeza que deseja {{action}}?',
          'DESCRIPTION': 'Você tem certeza que deseja {{action}} este evento.',
          'PUBLISH': 'Publicar',
          'UNPUBLISH': 'Despublicar',
        },
        'TOASTR': {
          'PUBLISH_TITLE': 'Leaderboard do evento publicado com sucesso',
          'PUBLISH_DESCRIPTION': 'O leaderboard do evento está público, todos conseguem ver.',
          'UNPUBLISH_TITLE': 'Leaderboard do evento despublicado com sucesso',
          'UNPUBLISH_DESCRIPTION': 'O leaderboard foi despublicado e seu link não tem mais acesso.',
          'LEADERBOARD': {
            'ERROR': {
              'TITLE': 'Erro ao salvar as configurações',
              'DESCRIPTION': 'Todos os campos são obrigatórios'
            },
            'SUCCESS': {
              'TITLE': 'Configurações salvas com sucesso!',
              'DESCRIPTION': 'As configurações do leaderboard foram salvas.'
            }
          }
        },
      }
    }
  }
};
