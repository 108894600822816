import { PaymentService } from './../../../../../core/services/payment/payment.service';
import { Payment, PaymentMethods, PaymentStatus } from '../../../../../core/model/payment';
import { NgxMaskModule } from 'ngx-mask';
import { Competitor, CompetitorStatus } from './../../../../../core/model/competitor';
import { User } from './../../../../../core/model/user';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MatMenuTrigger } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { TableConfig } from '../../../../../core/components/table-x/table-x.component';
import { BoxService } from '../../../../../core/services/box.service';
import { CompetitorService } from '../../../../../core/services/competitor/competitor.service';
import { FormErrorStateMatcher } from '../../../../../core/utils/form-error-state-matcher';
import { Utils } from '../../../../../core/utils';
import { Competition } from '../../../../../core/model/competition';
import { Errors } from '../../../../../core/model/errors';
import {SubscriptionCustomField} from '../../../../../core/model/subscription-custom-field.model';
import {CustomFieldTypes} from '../../../../../core/model/custom-field-type.model';
import { ToastrService } from '../../../../../core/components/toastr_/public_api';
import { Subscription } from 'rxjs';
import { Disqualified } from '../../../../../core/enum/disqualified';
import { SubDivision } from '../../../../../core/model/sub-division';
import { Championship } from '../../../../../core/model/championship';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {

  @ViewChild(MatMenuTrigger) configDisqualifiedMenu: MatMenuTrigger;
  
  private subscriptions: Subscription = new Subscription();

  public competitor: Competitor = null;
  public competitions: any[] = [];
  public user: User = null;
  public subDivisions: SubDivision[];
  public championship: Championship;

  enableSubDivision = false;

  // cut and wd
  confirmStatusDisqualifiedCompetitor = false;
  paramsDisqualified: any;
  successDisqualifiedCompetitor = false;
  successDiqualifiedCompetitorRequest: string;
  disqualifiedMessage: string;
  enumDisqualified = Disqualified;
  
  translate: any;

  // Table
  tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: null,
    massActionCountTitle: null
  };

  tshirtSize = Utils.getTShirts();

  payments: Payment[] = [];

  // Box
  boxes = [];
  boxId: number;
  timeOutBoxSearch;

  userForm: FormGroup;
  matchers: any;
  userFormErrors: any;

  leaderboardName = '';
  requestErrors: string = null;
  inactiveRequestError: string = null;

  isLoading = false;
  isUpdateLoading = false;
  isEmptyResult = false;
  confirmStatusOpened = false;
  isLoadingConfirmStatus = false;
  isLoadingDisqualifiedStatus = false;

  editNumber = false;
  numberForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<UserProfileComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private boxesService: BoxService,
    private competitorService: CompetitorService,
    private paymentService: PaymentService,
    private toastrService: ToastrService
  ) { 
    this.translationLoader.loadTranslations(english, portugues);
  }

  ngOnInit() {
    this.user = this.competitor.user;
    // return;
    
    const subscription = this.translateService.get('USER').subscribe((res: any) => {
      this.translate = res;
    });

    this.subscriptions.add(subscription);
    if (this.competitor.competition.enable_sub_division) {
      this.enableSubDivision = true;
    }
    this.formUser();
  }

  private formUser(): void {
    if (this.competitor) {
      this.leaderboardName = (this.competitor.team.members[0] && this.competitor.team.members[0].leaderboard_name) ? this.competitor.team.members[0].leaderboard_name : (this.competitor.user.leaderboard_name ? this.competitor.user.leaderboard_name : this.competitor.user.fullname);
      this.userForm = this.formBuilder.group({
        competition: [this.competitor.competition.id, Validators.required],
        leaderboardName: [{
          value: this.leaderboardName.toUpperCase(),
          disabled: true
        }, Validators.required],
        box: [(this.competitor.team.box) ? this.competitor.team.box : ''],
        tShirt: [(this.competitor.team.members[0].size) ? this.competitor.team.members[0].size : '']
      });
  
      this.matchers = {
        competition: new FormErrorStateMatcher(),
        leaderboardName: new FormErrorStateMatcher()
      };

      this.numberForm = this.formBuilder.group({
        number: [this.competitor.number ? this.competitor.number : this.competitor.competition.start_number, [
          Validators.required, 
          Validators.min(this.competitor.competition.start_number)
        ]]
      });

      if (this.enableSubDivision) {
        this.enableSubDivision = true;
        this.userForm = this.formBuilder.group({
          competition: [this.competitor.competition.id, Validators.required],
          subDivision: [this.competitor.sub_division ? this.competitor.sub_division.id : null, Validators.required],
          leaderboardName: [{
            value: this.leaderboardName.toUpperCase(),
            disabled: true
          }, Validators.required],
          box: [(this.competitor.team.box) ? this.competitor.team.box : ''],
          tShirt: [(this.competitor.team.members[0].size) ? this.competitor.team.members[0].size : '']
        });
        this.matchers = {
          competition: new FormErrorStateMatcher(),
          subDivision: new FormErrorStateMatcher(),
          leaderboardName: new FormErrorStateMatcher()
        };
      }

      this.getPayments(); 
    }
  }
  
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public competitionSelect(event: any): void {
    const id = event.value;
    
    this.competitions
      .forEach((competition: Competition, key: number) => {
        if (key === 0) {
          this.enableSubDivision = false;
        }
        if (competition.id === id) {
          this.competitor.competition = competition;
          this.enableSubDivision = competition.enable_sub_division;
        }
      });
    this.formUser();
  }

  public editNumberCompetitor(): void {
    const parameters = {
      number: this.numberForm.value.number,
      competition_id: this.userForm.value.competition,
    };

    const subscription = this.competitorService.updateCompetitor(parameters, this.competitor.id)
      .subscribe(
        result => {
          const competitorUpdated = new Competitor().deserialize(result);
          // this.competitor = competitorUpdated
          this.competitor.competition = competitorUpdated.competition;
          this.competitor.team.members[0].leaderboard_name = competitorUpdated.team.members[0].leaderboard_name;
          this.competitor.team.members[0].box = competitorUpdated.team.members[0].box;
          this.competitor.team.members[0].size = competitorUpdated.team.members[0].size;
          this.leaderboardName = this.userForm.value.leaderboardName;
          this.isUpdateLoading = false;

          const competition = this.competitions.filter(obj => {
            return obj.id === this.userForm.value.competition;
          });
          this.competitor.competition = new Competition().deserialize(competition[0]);
          this.competitor = competitorUpdated;
          
          this.dialogRef.close({
            action: 'update',
            object: competitorUpdated,
            toastr: {
              title: this.translate.TOASTR.UPDATE.TITLE,
              description: this.translate.TOASTR.UPDATE.DESCRIPTION,
              type: 'success'
            }
          });
        }, err => {
          console.log('err', err);
          this.isUpdateLoading = false;
          const errors: Errors = new Errors().deserialize((err.error as any));
          console.log('err', errors);
          this.requestErrors = errors.getFullMessages();
        }
      );
    
    this.subscriptions.add(subscription);
  }

  // Update competitor
  updateCompetitor() {
    // validate all form fields
    if (!this.userForm.valid) {
      Utils.formValid(this.userForm);
      return;
    } 

    const parameters = {
      leaderboard_name: this.leaderboardName.toUpperCase(),
      competition_id: this.userForm.value.competition,
      sub_division_id: this.enableSubDivision ? this.userForm.value.subDivision : null,
      size: (this.userForm.value.tShirt) ? this.userForm.value.tShirt : null,
      box_id: (this.userForm.value.box.id) ? this.userForm.value.box.id : null,
      box: (!this.userForm.value.box.id && this.userForm.value.box) ? this.userForm.value.box.toUpperCase() : null,
    };

    console.log(parameters);
    
    this.isUpdateLoading = true;
    const subscription = this.competitorService.updateCompetitor(parameters, this.competitor.id)
      .subscribe(
        result => {
          const competitorUpdated = new Competitor().deserialize(result);
          // this.competitor = competitorUpdated
          this.competitor.competition = competitorUpdated.competition;
          this.competitor.team.members[0].leaderboard_name = competitorUpdated.team.members[0].leaderboard_name;
          this.competitor.team.members[0].box = competitorUpdated.team.members[0].box;
          this.competitor.team.members[0].size = competitorUpdated.team.members[0].size;
          this.leaderboardName = this.userForm.value.leaderboardName;
          this.isUpdateLoading = false;

          const competition = this.competitions.filter(obj => {
            return obj.id === this.userForm.value.competition;
          });
          this.competitor.competition = new Competition().deserialize(competition[0]);
          this.competitor = competitorUpdated;
          
          this.dialogRef.close({
            action: 'update',
            object: competitorUpdated,
            toastr: {
              title: this.translate.TOASTR.UPDATE.TITLE,
              description: this.translate.TOASTR.UPDATE.DESCRIPTION,
              type: 'success'
            }
          });
        }, err => {
          console.log('err', err);
          this.isUpdateLoading = false;
          const errors: Errors = new Errors().deserialize((err.error as any));
          console.log('err', errors);
          this.requestErrors = errors.getFullMessages();
        }
      );
    
    this.subscriptions.add(subscription);
  }

  // Active/Inactive
  setActiveOrInactive() {
    this.inactiveRequestError = null;

    const parameters = {
      status: (this.competitor.status === CompetitorStatus.ACTIVE) ? CompetitorStatus.INACTIVE : CompetitorStatus.ACTIVE
    };

    console.log(parameters);
    this.isLoadingConfirmStatus = true;

    const subscription = this.competitorService.updateCompetitor(parameters, this.competitor.id)
      .subscribe(
        result => {
          console.log('result', result);
          this.isLoadingConfirmStatus = false;
          this.competitor.status = (this.competitor.status === CompetitorStatus.ACTIVE) ? CompetitorStatus.INACTIVE : CompetitorStatus.ACTIVE;
          this.confirmStatusOpened = !this.confirmStatusOpened;

          this.dialogRef.close({
            action: 'remove-to-list',
            toastr: {
              title: (this.competitor.status === CompetitorStatus.ACTIVE) ? this.translate.TOASTR.ACTIVE.TITLE : this.translate.TOASTR.INACTIVE.TITLE,
              description: (this.competitor.status === CompetitorStatus.ACTIVE) ? this.translate.TOASTR.ACTIVE.DESCRIPTION : this.translate.TOASTR.INACTIVE.DESCRIPTION,
              type: 'success'
            }
          });
        }, err => {
          console.log('err', err);
          this.isLoadingConfirmStatus = false;
          this.confirmStatusOpened = !this.confirmStatusOpened;

          const errors: Errors = new Errors().deserialize((err.error as any));
          this.inactiveRequestError = errors.getFullMessages();
        }
      );
    
    this.subscriptions.add(subscription);
  }


  getPayments() {
    const subscription = this.paymentService.getTeamPayments(this.competitor.team.id)
      .subscribe(
        result => {
          console.log(result);
          this.payments = result;
        }, err => {
          console.log('err', err);
        }
      );
    this.subscriptions.add(subscription);
  }

  getPaymentMethodName(payment: Payment): string
  {
    switch (payment.payment_method) {
      case PaymentMethods.CREDITCARD:
        return this.translate.PAYMENT_METHOD.CREDIT_CARD;

      case PaymentMethods.BOLETO:
        return this.translate.PAYMENT_METHOD.BOLETO;
    
      default:
        return '';
    }
  }

  getPaymentStatus(payment: Payment, type: boolean = false): string 
  {
    switch (payment.payment_status) {
      case PaymentStatus.APPROVED:
        return (!type) ? this.translate.PAYMENT_STATUS.APPROVED : 'success';
    
      case PaymentStatus.WAITING:
        return (!type) ? this.translate.PAYMENT_STATUS.WAITING : 'normal';
    
      case PaymentStatus.REFUSED:
        return (!type) ? this.translate.PAYMENT_STATUS.REFUSED : 'danger';
    
      case PaymentStatus.REFUNDED:
        return (!type) ? this.translate.PAYMENT_STATUS.REFUNDED : 'purple';
    
      case PaymentStatus.CHARGEBACK:
        return (!type) ? this.translate.PAYMENT_STATUS.CHARGEBACK : 'purple';
    
      default:
        return '';
    }
  }

  formatCurrency(value: string, currency: string): string
  {
    return Utils.formatCurrency(value, currency);
  }


  searchBox(name: string) {
    clearTimeout(this.timeOutBoxSearch);
    this.timeOutBoxSearch = setTimeout(() => {
      if (name.length > 0) {
        const subscription = this.boxesService.getListBoxes(name)
          .subscribe(
            result => {
              console.log('result', result);
              this.boxes = result;
            }, err => {
              this.boxes = [];
            }
          );
        
        this.subscriptions.add(subscription);
      } else {
        this.boxes = [];
      }
    }, 100);
  }

  displayFnBox(box: any): string {
    console.log(box);
    return box ? box.name.toUpperCase() : box;
  }

  returnFn(box: any): number | undefined {
    return box ? box.id : undefined;
  }


  cancelForm() {
    this.userForm.controls['competition'].setValue(this.competitor.competition.id);
    this.userForm.controls['leaderboardName'].setValue(this.leaderboardName);
    this.userForm.controls['box'].setValue((this.competitor.team.box) ? this.competitor.team.box : '');
    this.userForm.controls['tShirt'].setValue(this.competitor.team.members[0].size);
  }

  public getFieldValue(field: SubscriptionCustomField): string {
    if (field.custom_field_type.type === CustomFieldTypes.SINGLE_CHECKBOX) {
      return Boolean(JSON.parse(field.value)) ? 'Sim' : 'Não';
    } 
    
    return field.value;
  }

  public disqualifiedCompetitor(type: number): void {
    this.confirmStatusDisqualifiedCompetitor = true;
    let customFieldName: string;
    let customFieldType = '';
    if (type === Disqualified.CUT) {
      this.paramsDisqualified = {
        disqualified: 0,
        is_cut: 1,
      };
      customFieldName = this.translate.DISQUALIFIED.CUT;
      customFieldType = this.translate.DISQUALIFIED.TYPE_CUT;
    }
    if (type === Disqualified.WD) {
      this.paramsDisqualified = {
        disqualified: 1,
        is_cut: 0,
      };
      customFieldName = this.translate.DISQUALIFIED.WD;
      customFieldType = this.translate.DISQUALIFIED.TYPE_WD;
    }
    if (type === Disqualified.CANCEL) {
      this.paramsDisqualified = {
        disqualified: 0,
        is_cut: 0,
      };
      customFieldName = this.translate.DISQUALIFIED.REMOVE;
    }
    const subscription = this.translateService
      .get('USER.DISQUALIFIED.MESSAGE', { custom_field_name: customFieldName.toLowerCase(), custom_field_type: customFieldType  })
      .subscribe((disqualifiedMessage: string) => {
        this.disqualifiedMessage = disqualifiedMessage;
      });
    this.subscriptions.add(subscription);
  }

  public confirmDisqualifiedCompetitor(): void {
    this.isLoadingDisqualifiedStatus = true;
    const subscription = this.competitorService
      .disqualifiedCompetitor(this.paramsDisqualified, this.competitor.id)
      .subscribe((result: Competitor) => {
        this.competitor = result;
        this.isLoadingDisqualifiedStatus = false;
        this.confirmStatusDisqualifiedCompetitor = false;
        this.successDisqualifiedCompetitor = true;
        this.successDiqualifiedCompetitorRequest = this.translate.DISQUALIFIED.SUCCESS;
      });

    this.subscriptions.add(subscription);
  }
}
