import { LocalizedDatePipe } from './localized-date/localized-date.pipe';
import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { PostsPipe } from './posts.pipe';
import { PlaceholderImagePipe } from './placeholder-image.pipe';

@NgModule({
    declarations: [
      KeysPipe,
      GetByIdPipe,
      HtmlToPlaintextPipe,
      FilterPipe,
      CamelCaseToDashPipe,
      SanitizeHtmlPipe,
      PostsPipe,
      PlaceholderImagePipe,
      LocalizedDatePipe
    ],
    imports     : [],
    exports     : [
      KeysPipe,
      GetByIdPipe,
      HtmlToPlaintextPipe,
      FilterPipe,
      CamelCaseToDashPipe,
      SanitizeHtmlPipe,
      PostsPipe,
      PlaceholderImagePipe,
      LocalizedDatePipe
    ]
})

export class FusePipesModule
{

}
