import { FormControl, Validators } from '@angular/forms';
import { SubDivision } from './sub-division';

export class SubDivisionLimit {
  id?: number;
  competition_id?: number;
  sub_division_id?: number;
  sub_division?: SubDivision;
  limit: number | null = null;
  cut_line: number | null = null;
  canSubscribe?: boolean;
  
  // form control
  cutLineFormControl = new FormControl(null, [
    Validators.min(1)
  ]);
  limitFormControl = new FormControl(null, [
    Validators.min(1)
  ]);
}
