<div class="page-layout blank" fxLayoutAlign="center center" *ngIf="event === null && isLoading">
  <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
</div>

<div id="background-leaderboard" #divScroll fxLayout="column" fxLayoutAlign="stretch stretch" fxFlex="100" class="page-layout blank pt-32" [ngClass]="{'hidden-leaderboard': isLoading }">
  <div class="content">
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="isShowBrand">
      <img src="{{ brandUrl }}" class="brand-event mb-16" />
    </div>
    <h1><span>LEADERBOARD</span></h1>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start" fxLayoutAlign.xs="start stretch" class="p-16">
      <div fxFlex="25" fxLayout="column" fxLayoutAlign="stretch stretch" *ngIf="championships && championships.length > 1">
        <label>Fases</label>
        <select id="championship_select" (change)="championshipSeleted($event)">
          <option [value]="championship.id" *ngFor="let championship of championships">{{ championship.name }}</option>
        </select>
      </div>
      
      <div [fxFlex]="(championships && championships.length > 1) ? 25 : 50" fxLayout="column" fxLayoutAlign="stretch stretch">
        <label *ngIf="championship && championship.competitions.length > 0">Divisions</label>
        <select id="competitions_select" *ngIf="championship && championship.competitions.length > 0" (change)="competitionSeleted($event)">
          <option [value]="competition.id" *ngFor="let competition of championship.competitions">{{ competition.name }}</option>
        </select>
      </div>

      <div *ngIf="enableSubDivision" [fxFlex]="(championships && championships.length > 1) ? 25 : 50" fxLayout="column" fxLayoutAlign="stretch stretch">
        <label>{{ (championship.sub_division_group_name ? (championship.sub_division_group_name | titlecase) : 'Sub-categorias') }}</label>
        <select id="sub_division_select" (change)="subDivisionSeleted($event)">
          <option [value]="0">TODAS</option>
          <option [value]="subDivision.id" *ngFor="let subDivision of championship.sub_divisions">{{ subDivision.name }}</option>
        </select>
      </div>
      
      <div fxFlex="25" fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.xs="start center">
        <button id="refresh" (click)="getResults(competitionId)">Refresh</button>
      </div>
      
      <div fxFlex="25" fxLayout="column" fxLayoutAlign="start end" fxLayoutAlign.xs="start center" class="pt-24">
        <a href="http://appcrossx.com" target="_blank" fxLayoutAlign="end end">
          <img src="./assets/images/leaderboard/crossx.png" />
        </a>
      </div>
    </div>

    <div class="table-responsive mt-16">
      <table width="100%" border="0" cellspacing="0" cellpadding="0" id="table-leaderboard" data-type-user="team">
        <thead>
          <tr *ngIf="!isLoadingResults">
            <th valign="bottom"></th>
            <th valign="bottom">{{'LEADERBOARD.NAME' | translate}}</th>
            <th class="center" *ngFor="let wod of wods"><span>{{ wod.name }}</span></th>
          </tr>
        </thead>
        <tbody>
<!--          <tr *ngIf="isLoadingResults" [ngStyle]="{'background': getPreset(eventConfiguration, 'leaderboard-dark-color')}">-->
<!--            <td>Carregando...</td>-->
<!--          </tr>-->
          <tr *ngFor="let athlete of athletes; let i = index; let odd = odd" [ngStyle]="{'background': !odd ? getPreset(eventConfiguration, 'leaderboard-dark-color') : 'transparent'}">
            <td [ngStyle]="{'border-color': (i + 1) === cutLine ? getPreset(eventConfiguration, eventConfigation.LEADERBOARD_CUT_LINE_COLOR) : 'rgba(0,0,0,0)'}" valign="middle" align="center" class="ranking" width="100">
              <div class="eventResult">
                <div class="cell">
                  <strong *ngIf="athlete.rank !== null && !athlete.disqualified">{{ subDivisionId === 0 ? athlete.rank : athlete.sub_division_rank }}</strong><br>
                  <span *ngIf="athlete.total !== null && !athlete.disqualified">({{ subDivisionId === 0 ? athlete.total : athlete.sub_division_total }})</span>
                </div>
              </div>
            </td>
            <td [ngStyle]="{'border-color': (i + 1) === cutLine ? getPreset(eventConfiguration, eventConfigation.LEADERBOARD_CUT_LINE_COLOR) : 'rgba(0,0,0,0)'}" valign="middle" align="left" class="name-user p-0 pr-8">
              <div fxLayout="row" fxLayoutAlign="space-between center" class="content-name-user" (click)="showTeamMembers(athlete);">
                <span fxLayout="row" fxLayoutAlign="start center">
                  <!--<img src="{{ (athlete.team.members[0].avatar) ? athlete.team.members[0].avatar : null | placeholderImage:'user' }}" width="35" class="avatar-user mr-8" *ngIf="athlete.team.type === 'single'" />-->
                  <div class="eventResult" fxLayout="column" fxLayoutAlign="center start">
                    <div fxLayout="row">
                      <div [ngStyle]="{'color': chooseNameColor(athlete.team.name)}"
                           class="cell" >{{ athlete.team.name }}</div>
                      <span class="wd p-4"
                            [ngStyle]="{
                              'background': getPreset(eventConfiguration, 'leaderboard-light-color'),
                              'color': odd ? getPreset(eventConfiguration, 'leaderboard-background-color') : getPreset(eventConfiguration, 'leaderboard-dark-color')
                            }"
                            *ngIf="athlete.disqualified">{{ 'LEADERBOARD.DISQUALIFIED.WD' | translate }}</span>
                      <span class="wd p-4"
                        [ngStyle]="{
                          'background': getPreset(eventConfiguration, 'leaderboard-light-color'),
                          'color': odd ? getPreset(eventConfiguration, 'leaderboard-background-color') : getPreset(eventConfiguration, 'leaderboard-dark-color')
                        }"
                        *ngIf="athlete.is_cut">{{ 'LEADERBOARD.DISQUALIFIED.CUT' | translate }}</span>
                    </div>
                    <span class="box-name" *ngIf="getBox(athlete) !== null" [ngStyle]="{'color': getPreset(eventConfiguration, 'leaderboard-box-name-color')}">{{
                      getBox(athlete) }}</span>
                  </div>
                </span>
                <img src="{{ athlete.team.country.flag_url }}" *ngIf="athlete.team.country" class="flag ml-32 mr-12" />
              </div>
            </td>

            <td [ngStyle]="{'border-color': (i + 1) === cutLine ? getPreset(eventConfiguration, eventConfigation.LEADERBOARD_CUT_LINE_COLOR) : 'rgba(0,0,0,0)'}" valign="middle" align="center" *ngFor="let wod of wods; let indexWod = index;" [ngClass]="{ 'highlight': subDivisionId === 0 ? getResultLine(indexWod, athlete).rank === 1 : getResultLine(indexWod, athlete).sub_division_rank === 1, 'wod-result': true}" (click)="showTiebreak($event)">
              <div class="eventResult">
                <div class="cell" *ngIf="athlete.disqualified === 0 && athlete.is_cut === 0 && getResultLine(indexWod, athlete).status != 2">
                  <div class="rank">
                    {{ subDivisionId === 0 ? getResultLine(indexWod, athlete).rank : getResultLine(indexWod, athlete).sub_division_rank }}
                  </div>
                  <div class="points" *ngIf="championship.type === 3">
                    {{ getResultLine(indexWod, athlete).points }} {{ (getResultLine(indexWod, athlete).points !== null) ? 'pts' : '' }} 
                  </div>
                  <div class="results">{{ getResultLine(indexWod, athlete).result !== '' ? '(' : ''}} {{ getResultLine(indexWod, athlete).result }} {{ getResultLine(indexWod, athlete).wod === null || getResultLine(indexWod, athlete).wod.wod_type_id === 2 ? '' : getResultLine(indexWod, athlete).wod.wod_type.unit }}{{ getResultLine(indexWod, athlete).result !== '' ? ' )' : ''}}</div>
                  <div class="tiebreak" [ngStyle]="{'color': getPreset(eventConfiguration, 'leaderboard-dark-color')}" *ngIf="wod.tiebreak_type !== null">Tiebreak: {{ getResultLine(indexWod, athlete).tiebreak }} {{ unitName(getResultLine(indexWod, athlete).tiebreak_type_id) }}</div>
                </div>
                <div class="cell" *ngIf="(athlete.disqualified || athlete.is_cut) && getResultLine(indexWod, athlete).status != 2">
                  <div class="rank">
                    {{ getResultLine(indexWod, athlete).rank ? subDivisionId === 0  ? getResultLine(indexWod, athlete).rank : getResultLine(indexWod, athlete).sub_division_rank  : athlete.disqualified ? translate.DISQUALIFIED.WD : translate.DISQUALIFIED.CUT }}
                  </div>
                  <div class="points" *ngIf="championship.type === 3 && getResultLine(indexWod, athlete).rank">
                    {{ getResultLine(indexWod, athlete).points ? getResultLine(indexWod, athlete).points : '' }} 
                    {{ (getResultLine(indexWod, athlete).points !== null) ? 'pts' : '' }} 
                  </div>
                  <div class="results" *ngIf="getResultLine(indexWod, athlete).rank">
                    {{ getResultLine(indexWod, athlete).result !== '' && getResultLine(indexWod, athlete).result !== null  ? '(' : ''}} {{ getResultLine(indexWod, athlete).result }} {{ getResultLine(indexWod, athlete).wod === null || getResultLine(indexWod, athlete).wod.wod_type_id === 2 ? '' : getResultLine(indexWod, athlete).wod.wod_type.unit }}{{ getResultLine(indexWod, athlete).result !== '' ? ' )' : ''}}
                  </div>
                  <div class="tiebreak" [ngStyle]="{'color': getPreset(eventConfiguration, 'leaderboard-dark-color')}" *ngIf="wod.tiebreak_type !== null">Tiebreak: {{ getResultLine(indexWod, athlete).tiebreak }} {{ unitName(getResultLine(indexWod, athlete).tiebreak_type_id) }}</div>
                </div>
              </div>
            </td>
            <!-- <div style="border-bottom: 1px solid #ff0000; width: 100%;"></div> -->
          </tr>
        </tbody>
      </table>
      <div fxLayout="row" fxLayoutAlign="center center" class="p-16 footer-loader" *ngIf="isLoadingTable || isLoadingResults">
        <mat-spinner diameter="25" strokeWidth="2" class="loader"></mat-spinner>
      </div>
    </div>
  </div>
</div>

<scroll-to-top
  scrollId="background-leaderboard"
  [animate]="true"
  [speed]="50"
  [acceleration]="2"
  [scrollDistance]="10"
  (scrollEvent)="scrollEvent($event)"
  [scrollInfinite]="true"
  [scrollRetriveDistance]="20"></scroll-to-top>
