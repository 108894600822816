import { Component, OnDestroy, OnInit } from '@angular/core';
import { locale as portugues } from '../i18n/pt-BR';
import { Subscription, zip } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../../core/services/translation-loader.service';
import { HeatsService } from '../services/heats.service';
import { LanesHeatsQuantity } from '../model/lanes-heats-quantity.model';
import { HeatsHttpService } from '../../../../../../core/services/heats/heats-http.service';
import { HeatsUrlService } from '../services/heats-url.service';
import { HeatQueryParams } from '../enums/heat-query.params';
import { KeyValue } from '../model/key-value.model';
import { HeatCompetitor } from '../model/heat-competitor.model';
import { Heat } from '../model/heat.model';
import { Key } from 'quill';

@Component({
  selector: 'app-heats-items',
  templateUrl: './heats-items.component.html',
  styleUrls: ['./heats-items.component.scss']
})

export class HeatsItemsComponent implements OnInit, OnDestroy {

  heatsItems = new Array<Array<HeatCompetitor>>();
  translate: any;
  isLoading: boolean = false;
  private lanesHeatsQuantity: LanesHeatsQuantity;
  private subscriptions = new Subscription();
  private heats = new Array<Heat>();
  private championshipId: string;
  private type: string;
  private queryParams = new Array<KeyValue>();

  constructor(
    private translateService: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private heatsService: HeatsService,
    private heatsHttpService: HeatsHttpService,
    private heatsUrlService: HeatsUrlService
  ) {
    this.registerOnTranslateHeatCompetitors();
    this.registerOnHeatsServiceHeatsGenerator();
    this.registerOnPathParamsObservable();
    this.registerOnQueryParamsObservable();
    this.registerOnHeatsHttpService();
    this.registerOnHeatsDeletedObservable();
    this.registerOnSaveOrUpdateHeatsObservable();
  }

  get showHeats(): boolean {
    return this.heats && this.heats.length > 0;
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  heatCompetitorsChanged(event: { data: Array<HeatCompetitor>, matrixIndex: number }): void {

    if (!event.data.length) {
      this.heatsItems = new Array<Array<HeatCompetitor>>();
    }

    this.heatsItems[event.matrixIndex] = event.data;

    this.heatsService.sendDropCollection(event);
  }

  private registerOnTranslateHeatCompetitors(): void {
    this.translationLoader.loadTranslations(portugues);
    const subscription = this.translateService
      .get('HEAT_HEATCOMPETITORS')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);
  }

  private registerOnHeatsDeletedObservable(): void {
    // this.heatsHttpService
    //   .heatsDeletedObservable()
    //   .subscribe((data: boolean) => {
    //     if (data) {
    //       this.heats
    //         .forEach((heat: Heat, index: number) => {
    //           heat.type = this.type;
    //
    //           if (this.heatsItems[index] !== undefined) {
    //             heat.heat_competitors = this.heatsItems[index];
    //             return;
    //           }
    //
    //           heat.heat_competitors = [];
    //
    //         });
    //       this.heatsHttpService
    //         .createHeats(this.heats);
    //     }
    //   });
  }

  private registerOnHeatsServiceHeatsGenerator(): void {
    const subscription =

      this.heatsService.heatsGeneratorObservable()
        .subscribe((data) => {

          this.lanesHeatsQuantity = data;
          this.heats = new Array<Heat>();

          for (let i = 0; i < this.lanesHeatsQuantity.heats; i++) {
            const heat = new Heat();
            heat.championship_id = this.championshipId;
            heat.total_lanes = this.lanesHeatsQuantity.lanes;
            heat.name = `${i}`;
            this.heats.push(heat);
          }

        });
    this.subscriptions.add(subscription);
  }

  private registerOnPathParamsObservable(): void {
    const subscription = zip(
      this.heatsUrlService
        .paramsObservable(),
      this.heatsUrlService
        .queryParamsObservable()
    )
      .subscribe(([params, queryParams]) => {
        this.queryParams = queryParams;
        this.championshipId = params[HeatQueryParams.CHAMPIONSHIP_ID];
        this.loadDefaults();
      });

    this.subscriptions.add(subscription);
  }

  private registerOnQueryParamsObservable(): void {
    const subscription = this.heatsUrlService
      .queryParamsObservable()
      .subscribe((params) => {

        const param = params.find((queryParam: KeyValue) => {
          return queryParam.key === HeatQueryParams.TYPE;
        });

        if (!param) {
          return;
        }

        this.type = param.value;
      });

    this.subscriptions.add(subscription);
  }

  private registerOnHeatsHttpService(): void {
    const subscription = this.heatsHttpService
      .subscribe((data) => {
        const heats = data ? data : new Array<Heat>();

        if (heats[0]) {
          const lanesQuantity = new LanesHeatsQuantity();
          lanesQuantity.lanes = heats[0].total_lanes;
          lanesQuantity.heats = heats.length;
          this.heatsService.sendLanesHeatsQuantity(lanesQuantity);
        }

        this.heats = heats;
      });

    this.subscriptions.add(subscription);
  }

  private onClick(event): void {
    event.preventDefault();

    this.heats
      .forEach((heat: Heat, index: number) => {
        heat.type = this.type;

        if (this.heatsItems[index] !== undefined) {
          heat.heat_competitors = this.heatsItems[index];

          heat.heat_competitors.forEach((heatCompetitor: HeatCompetitor) => {
            // heatCompetitor.heat_id = Number(heat.id);
          });

          return;
        }

        heat.heat_competitors = [];

      });

    const heatsWithId = this.heats.filter(heat => heat.id);


    if (
      heatsWithId &&
      heatsWithId.length <= 0
    ) {
      this.isLoading = true;
      this.heatsHttpService.createHeats(this.heats);
      return;
    }

    this.isLoading = true;
    const heatsCompetitorsCollection = this.heats
      .map((heat: Heat) => {
        return heat.heat_competitors.map(heatCompetitor => heatCompetitor);
      }).filter((data) => {
        return data.length > 0;
      });

    let newHeatsCompetitorsCollection = new Array<HeatCompetitor>();
    heatsCompetitorsCollection.forEach(item => {
      newHeatsCompetitorsCollection = newHeatsCompetitorsCollection.concat(item);
    });
    this.heatsHttpService.updateHeatCompetitors(newHeatsCompetitorsCollection);
  }

  private loadDefaults(): void {
    const params = new Array<KeyValue>();
    params.push(
      {
        key: HeatQueryParams.CHAMPIONSHIP_ID,
        value: this.championshipId
      }
    );

    const queryParamsType = this.queryParams.find((param) => param.key === HeatQueryParams.TYPE);

    params.push(queryParamsType || {
      key: HeatQueryParams.TYPE,
      value: queryParamsType
    });

    this.heatsHttpService.fetchHeats(params);
  }

  private registerOnSaveOrUpdateHeatsObservable() {
    const subscription = this.heatsHttpService
      .heatsSavedObservable()
      .subscribe((result: boolean) => {
        this.isLoading = false;
      });

    this.subscriptions.add(subscription);
  }
}
