export const locale = {
  lang: 'pt-BR',
  data: {
    'PAYMENT': {
      'TITLE': 'Pagamento',
      'TABLE': {
        'PAYMENT_DATE': 'Data de pagamento',
        'PAYMENT_METHOD': 'Forma de pagamento',
        'VALUE': 'Valor',
        'STATUS': 'Status',
      },
      'PAYMENT_INFORMATION': 'Informações do pagamento',
      'CREDIT_CARD': 'Cartão de crédito',
      'BOLETO': 'Boleto bancário',
      'SEE_BOLETO': 'Ver boleto',
      'SEND_PAYMENT': 'FINALIZAR E EFETUAR PAGAMENTO',
      'DESCRIPTION_CANCEL': 'Caso deseje cancelar sua inscrição, clique no botão a baixo',
      'CANCEL_SUBSCRIPTION': 'Cancelar inscrição',
      'FORM': {
        'INSTALLMENTS': 'Parcelas',
        'CARD_NUMBER': 'Número do cartão',
        'EXPIRATION_DATE': 'Data de vencimento',
        'CVV': 'Código de segurança',
        'NAME_COMPLETED': 'Nome completo',
        'CPF': 'CPF',
        'CEP': 'CEP',
        'ADDRESS': 'Endereço',
        'ADDRESS_NUMBER': 'Número',
        'NEIGHBORHOOD': 'Bairro',
        'CITY': 'Cidade',
        'STATE': 'UF'
      },
      'VALIDATIONS': {
        'CREDIT_CARD_REQUIRED': 'Número do cartão obrigatório',
        'CREDIT_CARD_INVALID': 'Número do cartão inválido',
        'EXPIRATION_REQUIRED': 'Validade obrigatória',
        'CVV_REQUIRED': 'Campo obrigatório',
        'HOLD_NAME_REQUIRED': 'Nome impresso no cartão obrigatório',
        'HOLD_CPF_REQUIRED': 'CPF obrigatório',
        'CEP_REQUIRED': 'CEP é obrigatório',
        'ADDRESS_REQUIRED': 'Endereço obrigatório',
        'ADDRESS_NUMBER_REQUIRED': 'Número obrigatório',
        'NEIGHBORHOOD_REQUIRED': 'Bairro é obrigatório',
        'CITY_REQUIRED': 'Cidade é obrigatório',
        'STATE_REQUIRED': 'UF é obrigatório'
      },
      'PAYMENT_STATUS': {
        'WAITING': 'Aguardando resposta',
        'APPROVED': 'Aprovado',
        'REFUSED': 'Recusado',
        'REFUNDED': 'Estornado',
        'CHARGEBACK': 'Chargeback'
      },
      'TOASTR': {
        'CEP_NOT_FOUND': {
          'TITLE': 'CEP não encontrado',
          'DESCRIPTION': 'O CEP não foi encontrado em nossa base de dados, por favor, verificar se está correto.'
        },
        'CEP_NOT_ADDRESS': {
          'TITLE': 'Não encontrado endereço válido para esse CEP',
          'DESCRIPTION': 'Esse CEP não encontrou um endereço válido, por favor, verifique se está correto.'
        },
        'CANCEL_SUBSCRIPTION': {
          'ERROR': 'Erro ao cancelar inscrição!',
        }
      },
      'ALTER_PAYMENT_METHOD': 'Alterar método de pagamento',
      'DATE_FORMAT': 'dd/MM/yyyy',
      'CURRENCY': 'R$',
      'CURRENCY_CODE': 'BRL',
      'DIALOG': {
        'CANCEL_SUBSCRIPTION': {
          'TITLE': 'Tem certeza que deseja cancelar a inscrição?',
          'DESCRIPTION': 'Essa ação não poderá ser desfeita, ao cancelar a inscrição você terá que se inscriver novamente no <strong>{{ championship_name }}</strong>.'
        },
      },
      'CANCEL_SUBSCRIPTION_CONFIRM': {
        'TITLE': 'Inscrição cancelada!',
        'DESCRIPTION': 'Você cancelou a sua inscrição, não é possível reverter, caso deseje faça a inscrição novamente.'
      },
      'INVITATION': {
        'WELCOME': 'Parabéns, você foi convidado para o <strong>{{ championship_name }}</strong> na categoria <strong>{{ competition_name }}</strong>, é necessário efetuar o pagamento para a inscrição desta nova fase.',
        'SUBSCRIPTION': 'Inscrição',
        'TOTAL_VALUE': 'Valor total',
        'TAX': 'taxa',
        'REFUSED': 'Recusar convite',
        'ACCEPT': 'Aceitar e efetuar pagamento',
        'DIALOG': {
          'REFUSED': {
            'TITLE': 'Tem certeza que deseja recusar o convite?',
            'DESCRIPTION': 'Essa ação não poderá ser desfeita, ao recusar o convite você não poderá aceitar novamente a não ser que seja convidado novamente para o <strong>{{ championship_name }}</strong>.'
          },
        },
        'TOASTR': {
          'REFUSE': {
            'ERROR': 'Erro ao recusar convite!',
            'TITLE': 'Convite recusado!',
            'DESCRIPTION': 'O convite para {{ championship_name }} foi recusado.'
          }
        },
        'CANCELLED_TITLE': 'Convite recusado!',
        'CANCELLED_MESSAGE': 'Você recusou o convite para o {{ championship_name }}.',
        'REFUSED_TITLE': 'Pagamento Recusado!',
        'REFUSED_MESSAGE': 'Você já tentou fazer o pagamento para {{ championship_name }}, mas o pagamento foi recusado. Tente novamente.',
      },
      'BUTTONS_DIALOG': {
        'CANCEL': 'Cancelar',
        'CONFIRM': 'Confirmar'
      },
      'ERRORS': {
        'PAYMENT_TITLE_FAILED': 'Pagamento recusado',
        'SUBSCRIBE_TITLE_FAILED': 'Erro na inscrição'
      }
    },
    
    // INFORMATION
    'INFORMATION': {
      'TITLE': 'Informações',
      'MY_INFORMATION': {
        'TITLE': 'Meus dados',
        'DESCRIPTION': 'Atualize as suas informações para o evento <strong>{{ event_name }}</strong>. A data máxima para as alterações é <strong>{{ max_date_update }}</strong>',
      },
      'MY_PROFILE': {
        'LEADERBOARD_NAME': 'Nome no leaderboard',
        'BOX': 'Box',
        'TSHIRT': 'Tamanho da camiseta',
        'VALIDATION': {
          'LEADERBOARD_NAME_REQUIRED': 'O nome no leaderboard é obrigatório',
          'TSHIRT_REQUIRED': 'O tamanho da camiseta é obrigatório',
          'BOX_REQUIRED': 'O box é obrigatório',
        }
      },
      'DATE_FORMAT': 'dd/MM/yyyy',
      'DATETIME_FORMAT': 'DD/MM/YYYY hh:mm',
      'TEAM': {
        'TITLE': 'Meu time',
        'DESCRIPTION': 'Visualize as informações do seu time no evento <strong>{{ event_name }}</strong>',
        'DESCRIPTION_CAPTAIN': 'Visualize e edite informações do seu time no evento <strong>{{ event_name }}</strong>. A data máxima para as alterações é <strong>{{ max_date_update }}</strong>',
        'MEMBERS': 'Membros',
        'CATEGORY': 'Categoria',
        'STATUS': 'Status',
        'TEAM_NAME': 'Nome do time',
        'VALIDATION': {
          'TEAM_NAME_REQUIRED': 'O nome do time é obrigatório',
        }
      },
      'DIALOG': {
        'ALTER_CAPITAIN_TITLE': 'Alterar o capitão do time?',
        'ALTER_CAPITAIN_DESCRIPTION': 'Você tem certeza que deseja alter o capitão do time?'
      },
      'TOASTER': {
        'TEAM_NAME': {
          'SUCCESS': {
            'TITLE': 'Nome do time alterado com sucesso',
            'DESCRIPTION': 'Nome do time alterado'
          },
          'ERROR': {
            'TITLE': 'Erro ao alterar o nome do time'
          }
        },
        'PROFILE': {
          'SUCCESS': {
            'TITLE': 'Perfil alterado com sucesso!',
            'DESCRIPTION': 'Perfil alterado.'
          },
          'ERROR': {
            'TITLE': 'Erro ao atualizar o perfil!!'
          }
        }
      },
      'CANCEL': 'Cancelar',
      'SAVE': 'Salvar',
      'ALTER': 'Alterar'
    }
  }
};