<div class="page-layout blank pb-64">
  <div class="p-32">
    <div class="content">
      <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
        <div class="pt-16">
          <mat-tab-group class="padding tabs-left" [disableRipple]="true" backgroundColor="">
            <mat-tab label="{{ 'SUBSCRIPTIONS.TABS.PAYMENT' | translate | uppercase }}">
              <div class="p-32 pt-16 registration">
                <form name="registrationForm" [formGroup]="registrationForm" autocomplete="off" novalidate>
                  <div fxLayout="column" fxLayoutAlign="stretch stretch">
                    <h2>{{ 'SETTINGS.REGISTRATION.PAYMENT_METHOD' | translate }}</h2>
                    <span innerHTML="{{ 'SETTINGS.REGISTRATION.PAYMENT_METHOD_DESCRIPTION' | translate }}"></span>
                    <div fxLayout="row" fxLayoutAlign="start start" class="pt-16 pb-16">
                      <mat-checkbox [checked]="true" [disabled]="true" class="mr-32">{{ 'SETTINGS.REGISTRATION.CREDIT_CARD' | translate }}*</mat-checkbox>
                      <mat-checkbox #checkboxBoleto formControlName="boleto">{{ 'SETTINGS.REGISTRATION.BOLETO' | translate }}</mat-checkbox>
                    </div>

                    <span><i>{{ 'SETTINGS.REGISTRATION.CREDIT_CARD_REQUIRED' | translate }}</i></span>

                    <div fxFlex="60" fxFlex.xs="100" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="mt-16">
                      <mat-form-field fxFlex="50" class="mr-16">
                        <input matInput #input maxlength="13" placeholder="{{ 'SETTINGS.REGISTRATION.SOFT_DESCRIPTION' | translate }}" formControlName="softDescription" [errorStateMatcher]="registrationMatchers.softDescription" uppercase required>
                        <mat-error *ngIf="registrationForm.controls.softDescription.hasError('required')">
                          {{ 'SETTINGS.REGISTRATION.SOFT_DESCRIPTION_REQUIRED' | translate }}
                        </mat-error>
                        <mat-hint align="end">{{input.value?.length || 0}}/13</mat-hint>
                      </mat-form-field>

                      <div fxFlex="50" fxLayout="row" fxLayoutAlign="start start" class="pr-16">
                        <mat-form-field fxFlex="100" *ngIf="checkboxBoleto.checked">
                          <input matInput placeholder="{{ 'SETTINGS.REGISTRATION.DAYS_DUE_DATE' | translate }}" formControlName="daysDueDate" [errorStateMatcher]="registrationMatchers.daysDueDate" required>
                          <mat-error *ngIf="registrationForm.controls.daysDueDate.hasError('required')">
                            {{ 'SETTINGS.REGISTRATION.DAYS_DUE_DATE_REQUIRED' | translate }}
                          </mat-error>
                          <mat-error *ngIf="!registrationForm.controls.daysDueDate.hasError('required') && registrationForm.controls.daysDueDate.hasError('invalid')">
                            {{ 'SETTINGS.REGISTRATION.DAYS_DUE_DATE_MIN_VALUE' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="end center" class="mt-32">
                      <btn [btnLoading]="true" [loading]="isRegistrationLoading" [buttonDisabled]="registrationForm.invalid" (click)="savePaymentMethods()"
                           class="ml-16">{{ 'SETTINGS.SAVE' | translate }}</btn>
                    </div>
                  </div>
                </form>

                <div class="line-bottom mt-32 mb-16"></div>

                <form name="bankAccountForm" [formGroup]="bankAccountForm" *ngIf="event.enabled_subscription && event.default_gateway_id === 1" autocomplete="off" novalidate>
                  <div fxLayout="column" fxLayoutAlign="stretch stretch">
                    <h2>{{ 'SETTINGS.REGISTRATION.BANK_ACCOUNT' | translate }}</h2>

                    <div fxFlex="100" fxLayout="row" fxLayoutAlign="stretch start">
                      <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-16">
                        <mat-select [disabled]="(userMe.id != 2 && userMe.id != 18) && (!userMe.admin || currentAccount !== null)" placeholder="{{ 'SETTINGS.REGISTRATION.USER_BANK_ACCOUNT' | translate }}"  formControlName="bankAccount" [errorStateMatcher]="bankAccountMatchers.bankAccount" required>
                          <mat-option *ngFor="let bankAccount of bankAccountList" [value]="bankAccount">
                            {{ (bankAccount.id !== 0) ? bankAccount.bank.title + ' - ' : '' }}{{ bankAccount.legal_name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div fxFLex="50"></div>
                    </div>

                    <div class="show-bank-account-container" *ngIf="currentAccount !== null">
                      <table>
                        <tr class="no-border-top">
                          <td>Nome da conta</td>
                          <td>Número da conta</td>
                          <td>Agência</td>
                          <td>Banco</td>
                          <td>Tipo de Conta</td>
                        </tr>
                        <tr>
                          <td>{{ currentAccount.legal_name }}</td>
                          <td>{{ currentAccount.account_number }}</td>
                          <td>{{ currentAccount.agency }}</td>
                          <td>{{ currentAccount.bank.title }}</td>
                          <td>{{ currentAccount.type }}</td>
                        </tr>
                      </table>
                    </div>

                    <div fxFlex="100" fxLayout="row" fxLayoutAlign="stretch start" *ngIf="isShowInputBank">
                      <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-16">
                        <mat-select placeholder="{{ 'SETTINGS.REGISTRATION.TYPE_PERSON' | translate }}"  formControlName="personType" [errorStateMatcher]="bankAccountMatchers.personType" (selectionChange)="personTypeSelect($event)" required>
                          <mat-option *ngFor="let type of personTypes" [value]="type">
                            {{ type.title }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div fxFLex="50"></div>
                    </div>

                    <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start" *ngIf="isShowInputBank">
                      <mat-form-field fxFlex="50" class="pr-16">
                        <input matInput #inputName maxlength="15" placeholder="{{ ((this.personTypeSelected.value === 1) ? 'SETTINGS.REGISTRATION.NAME' : 'SETTINGS.REGISTRATION.SOCIAL_NAME') | translate }}" formControlName="name" [errorStateMatcher]="bankAccountMatchers.name" required>
                        <mat-error *ngIf="bankAccountForm.controls.name.hasError('required')">
                          {{ 'SETTINGS.REGISTRATION.NAME_REQUIRED' | translate }}
                        </mat-error>
                        <mat-hint align="end">{{inputName.value?.length || 0}}/15</mat-hint>
                      </mat-form-field>

                      <mat-form-field fxFlex="50" class="pr-16">
                        <input
                          matInput
                          placeholder="{{ ((this.personTypeSelected.value === 1) ? 'SETTINGS.REGISTRATION.CPF' : 'SETTINGS.REGISTRATION.CNPJ') | translate }}"
                          [mask]="(this.personTypeSelected.value === 1) ? '000.000.000-00' : '00.000.000/0000-00'"
                          formControlName="documentNumber"
                          [errorStateMatcher]="bankAccountMatchers.documentNumber"
                          required>
                        <mat-error *ngIf="bankAccountForm.controls.documentNumber.hasError('required')">
                          {{ ((this.personTypeSelected.value === 1) ? 'SETTINGS.REGISTRATION.CPF_REQUIRED' : 'SETTINGS.REGISTRATION.CNPJ_REQUIRED') | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start" *ngIf="isShowInputBank">
                      <mat-form-field fxFlex="50" class="pr-16">
                        <input matInput [matAutocomplete]="autoBank" autocomplete="off" placeholder="{{ 'SETTINGS.REGISTRATION.BANK' | translate }}"
                               formControlName="bank" (ngModelChange)="searchBank($event)">

                        <mat-autocomplete autoActiveFirstOption panelWidth="auto" #autoBank="matAutocomplete" xPosition="before"
                                          [displayWith]="displayFnBank">
                          <mat-option *ngFor="let bank of banks" [value]="bank">
                            {{ bank.code }} - {{ bank.title }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>

                      <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-16">
                        <mat-select placeholder="{{ 'SETTINGS.REGISTRATION.TYPE_ACCOUNT' | translate }}" formControlName="accountType" [errorStateMatcher]="bankAccountMatchers.accountType" required>
                          <mat-option *ngFor="let accountType of accountTypes" [value]="accountType">
                            {{ accountType.title }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start" *ngIf="isShowInputBank">
                      <div fxFlex="50" fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field fxFlex="auto" class="pr-16">
                          <input matInput maxlength="5" placeholder="{{ 'SETTINGS.REGISTRATION.AGENCY' | translate }}" formControlName="agency" [errorStateMatcher]="bankAccountMatchers.agency" required>
                          <mat-error *ngIf="bankAccountForm.controls.agency.hasError('required')">
                            {{ 'SETTINGS.REGISTRATION.AGENCY_REQUIRED' | translate }}
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex="100px" class="pr-16">
                          <input matInput maxlength="1" placeholder="{{ 'SETTINGS.REGISTRATION.AGENCY_DV' | translate }}" formControlName="agencyDv">
                        </mat-form-field>
                      </div>

                      <div fxFlex="50" fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field fxFlex="auto" class="pr-16">
                          <input matInput maxlength="13" placeholder="{{ 'SETTINGS.REGISTRATION.ACCOUNT' | translate }}" formControlName="account" [errorStateMatcher]="bankAccountMatchers.account" required>
                          <mat-error *ngIf="bankAccountForm.controls.account.hasError('required')">
                            {{ 'SETTINGS.REGISTRATION.ACCOUNT_REQUIRED' | translate }}
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex="100px" class="pr-16">
                          <input matInput maxlength="2" placeholder="{{ 'SETTINGS.REGISTRATION.ACCOUNT_DV' | translate }}" formControlName="accountDv" [errorStateMatcher]="bankAccountMatchers.accountDv" required>
                          <mat-error *ngIf="bankAccountForm.controls.accountDv.hasError('required')">
                            {{ 'SETTINGS.REGISTRATION.ACCOUNT_DV_REQUIRED' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center" class="mt-32">
                      <btn [btnLoading]="true" [loading]='isBankAccountLoading' [buttonDisabled]="bankAccountForm.invalid || (userMe.id != 2 && userMe.id != 18) && (!userMe.admin || currentAccount !== null)"  (click)="saveBankAccount()" class="ml-16">{{ 'SETTINGS.SAVE' | translate }}</btn>
                    </div>
                  </div>
                </form>

                <ng-container *ngIf="userMe.admin">
                  <div class="line-bottom mt-32 mb-32"></div>

                  <form name="taxForm" [formGroup]="taxForm" autocomplete="off" novalidate>
                    <div fxLayout="column" fxLayoutAlign="stretch stretch">
                      <h2>{{ 'SETTINGS.REGISTRATION.EVENT_TAX' | translate }}</h2>
                      
                      <div fxLayout="row">
                        <mat-form-field fxFlex="50" class="mr-16">
                          <input
                            matInput
                            placeholder="{{ 'SETTINGS.REGISTRATION.TAX_PERCENTAGE' | translate }}"
                            formControlName="tax"
                            [errorStateMatcher]="taxMatchers.tax"
                            currencyMask [min]="4.00" [max]="30.00"
                            [options]="{ prefix: '', suffix: '%', thousands: '', decimal: ',' }"
                            autocomplete="off" required>
                          <mat-error *ngIf="taxForm.controls.tax.hasError('required')">
                            {{ 'SETTINGS.REGISTRATION.TAX_PERCENTAGE_REQUIRED' | translate }}
                          </mat-error>
                          <mat-error *ngIf="taxForm.controls.tax.hasError('max')">
                            {{ 'SETTINGS.REGISTRATION.TAX_PERCENTAGE_MAX' | translate:{tax_max: '30,00'} }}
                          </mat-error>
                          <mat-error *ngIf="taxForm.controls.tax.hasError('min')">
                            {{ 'SETTINGS.REGISTRATION.TAX_PERCENTAGE_MIN' | translate:{tax_min: '4,00'} }}
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div fxLayout="row" fxLayoutAlign="end center" class="mt-32">
                        <btn [btnLoading]="true" [loading]='isTaxLoading' [buttonDisabled]="taxForm.invalid"  (click)="saveRecepientTax()" class="ml-16">{{ 'SETTINGS.SAVE' | translate }}</btn>
                      </div>
                    </div>
                  </form>
                </ng-container>
                
                
              </div>
            </mat-tab>

            <mat-tab label="{{ 'SUBSCRIPTIONS.TABS.SUBSCRIPTION_FORM' | translate | uppercase }}">
              <app-event-subscription-form typeActive="athletes"></app-event-subscription-form>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>
