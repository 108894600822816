import { LoggedProfileComponent } from './logged-profile.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '../../../../core/guards/auth.guard';
import { SharedModule } from '../../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import {NgxMaskModule} from 'ngx-mask';
import {AgmCoreModule} from '@agm/core';
import {environment} from '../../../../../environments/environment';


const routes = [
  {
    path: 'myprofile',
    component: LoggedProfileComponent,
    canActivate: [AuthGuard]
  }
];


@NgModule({
  declarations: [
    LoggedProfileComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    })
  ],
  exports: [
    LoggedProfileComponent
  ]
})

export class LoggedProfileModule { }
