import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';

import { LoginComponent } from './login.component';

const routes = [
  {
    path     : 'login',
    component: LoginComponent
  }
];

@NgModule({
  imports     : [
      SharedModule,
      RouterModule.forChild(routes)
  ],
  declarations: [
    LoginComponent
  ]
})

export class LoginModule
{

}
