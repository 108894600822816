import {RankingService} from './../../../../../core/services/ranking/ranking.service';
import {ToastrService} from './../../../../../core/components/toastr_/toastr/toastr.service';
import {ModalVideoResulComponent} from './../modal-video-resul/modal-video-resul.component';
import {Utils} from './../../../../../core/utils';
import {ResultService} from './../../../../../core/services/result/result.service';
import {TabBarComponent} from './../../../../../core/components/tab-bar/tab-bar.component';
import {WodService} from './../../../../../core/services/wod/wod.service';
import {EventGuard} from './../../../../../core/guards/event.guard';
import {config} from './../../../../../core/config/config';
import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {BreadcrumbsService} from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import {FuseTranslationLoaderService} from '../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthGuard} from '../../../../../core/guards/auth.guard';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

import {locale as english} from './i18n/en-US';
import {locale as portugues} from './i18n/pt-BR';
import {MatDialog, MatIconRegistry, MatMenuTrigger, MatSelectChange} from '@angular/material';
import {TableConfig, TableXComponent} from '../../../../../core/components/table-x/table-x.component';
import * as moment from 'moment';
import {CrossXDialogComponent} from '../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import {takeUntil} from 'rxjs/operators';
import {FilterPipe} from 'ngx-filter-pipe';
import {Result, ResultEnum, ResultStatus} from '../../../../../core/model/result';
import {FusePerfectScrollbarDirective} from '../../../../../core/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {Wod} from '../../../../../core/model/wod';
import {Subject, Subscription} from 'rxjs';
import {Errors} from '../../../../../core/model/errors';
import {Event} from '../../../../../core/model/event';
import {Competitor} from '../../../../../core/model/competitor';
import {CompetitorService} from '../../../../../core/services/competitor/competitor.service';
import {Disqualified} from '../../../../../core/enum/disqualified';
import {SubDivision} from '../../../../../core/model/sub-division';
import {SubDivisionHttpService} from '../../../../../core/services/sub-division/sub-division-http.service';
import {ModalCommentsComponent} from "./modal-comments/modal-comments.component";

// https://stackoverflow.com/questions/46068908/how-to-cancel-unsubscribe-all-pending-http-requests-angular-4/46069029

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit, OnDestroy {
  @ViewChild('tabbar', {read: TabBarComponent}) tabBar: TabBarComponent;
  @ViewChild(MatMenuTrigger) resultOptionsMenu: MatMenuTrigger;
  @ViewChild(MatMenuTrigger) configDisqualifiedMenu: MatMenuTrigger;
  @ViewChild(FusePerfectScrollbarDirective) fusePerfectScrollbarDirective;
  @ViewChild('tableList', {read: TableXComponent}) tableList: TableXComponent;
  translate: any;
  event: any;
  championshipId: number;
  championship: any;
  competition: any;
  competitionId: number;
  wod: Wod;
  wodId: number;
  // disqualified
  enumDisqualified = Disqualified;
  disqualifiedMessage: string;
  results: Result[] = [];
  resultsFilter: any = {
    fullnameSort: '',
    numberSort: null
  };
  resultsFilterData: Result[] = [];
  tabsFilter: string[] = [];
  // resultsFiltered: any[] = []
  wods: Wod[] = [];
  isLoading = false;
  tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: [
      {
        type: 'reproved',
        title: 'Reprovar'
      }
    ],
    massActionCountTitle: {
      single: 'resultado selecionado',
      multiple: 'resultados selecionados'
    }
  };
  unitType = '';
  unitTypeTiebreak = '';
  timeCapOptionVal = 'time';
  tipeCapError: string;
  inputRepsVal: string;
  selectedResult: any;
  unitTypeTime = 2;
  // indexSelectedResult: number
  isLoadingSaveTimeCap = false;
  isLoadingSaveResult = false;
  isLoadingPublish = false;
  isEmptyResult = false;
  isNotWods = false;
  isShowScrollToTop = false;
  isShowPublishButton = false;
  timeOut;
  // sub-division
  subDivisions: SubDivision[] = [];
  enableSubDivision = false;
  subDivisionId = 0;
  // filter
  resultEnum = ResultEnum;
  search = '';
  typeSearch = ResultEnum.ALL;
  totalFilter = 0;
  totalNoResult = 0;
  totalPublished = 0;
  totalNotPublished = 0;
  metaData = {
    page: 1,
    per_page: 1000,
    total: 0
  };

  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  private subscriptions: Subscription = new Subscription();

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private wodService: WodService,
    private resultService: ResultService,
    private rankingService: RankingService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private filterPipe: FilterPipe,
    private zone: NgZone,
    private competitorService: CompetitorService,
    private subDivisionHttpService: SubDivisionHttpService,
    private changeDetectorRefs: ChangeDetectorRef
  ) {
    this.registerSubDivision();
    this.translationLoader.loadTranslations(english, portugues);

    this.subscriptions.add(
      this.translateService.get('RESULTS').subscribe((res: any) => {
        this.translate = res;
      })
    );

    this.subscriptions.add(
      this.activatedRoute.params.subscribe(params => {
        this.championshipId = params.championship_id;

        this.event = new Event().deserialize(
          this.eventGuard.getActiveEvent().event
        );
        this.championship = this.eventGuard.getChampionshipPerId(
          this.championshipId
        );
        this.resultService.verifyResults(
          this.championshipId,
          this.championship.competitions[0].id
        );
        // this.championship.competitions = Utils.sortArray(this.championship.competitions, 'order', 'ASC')

        if (this.translate) {
          this.titleService.setTitle(
            `${config.titleBase} - ${this.translate.TITLE}`
          );

          this.breadcrumbsService.replaceAll([
            {
              text: this.translate.TITLE,
              route: `/championship/results/${this.championshipId}`
            }
          ]);
        }
      })
    ); // end router

    // Icon Info
    iconRegistry.addSvgIcon(
      'ic-info',
      sanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/myprofile/ic-profile.svg'
      )
    );

    this.registerVerifyResultsObservableObservable();
  }

  ngOnInit() {
    this.loadInit();
    const subscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.loadInit();
      }
    });

    this.subscriptions.add(subscription);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeOut);
    this.results = [];
    this.subscriptions.unsubscribe();
  }

  public searchFilter(event: any): void {
    this.search = event.target.value.toUpperCase();
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.filterData();
    }, 300);
  }

  public filterResults(type: number): void {
    this.typeSearch = type;
    this.filterData();
  }

  loadInit() {
    this.results = [];
    this.competition = this.championship.competitions[0];
    this.competitionId = this.championship.competitions[0].id;

    this.enableSubDivision = !!this.competition.enable_sub_division;

    if (this.championship.enable_sub_division) {
      this.fetchSubDivision();
    }

    this.getWods(this.competitionId);
  }

  getWods(competitionId: number) {
    this.wods = [];
    this.isNotWods = false;

    if (this.tabBar) {
      this.tabBar.resetIndex();
    }
    this.tabsFilter = [];

    const subscription = this.wodService
      .getWods(this.championship.id, competitionId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        result => {
          console.log('result', result);

          this.isNotWods = result.length === 0;

          this.wods = result;
          if (this.wods[0]) {
            this.wod = this.wods[0];
            this.wodId = this.wod.id;
            this.unitType = Utils.getUnitName(this.wod.wod_type.id, true);
            this.unitTypeTiebreak = this.wod.tiebreak_type
              ? Utils.getUnitName(this.wod.tiebreak_type.id, true)
              : '';
          }

          result.forEach(wod => {
            this.tabsFilter.push(wod.name);
          });
          this.getResults();
        },
        err => {
          // console.log("err", err);
        }
      );
    this.subscriptions.add(subscription);
  }

  competitionSelect(event: any) {
    const value: any = event.value;

    this.ngUnsubscribe.next();
    // this.ngUnsubscribe.complete();

    this.wods = [];
    this.results = [];

    this.competition = value;
    this.competitionId = value.id;
    this.wodId = null;
    this.wod = null;
    this.enableSubDivision = this.competition.enable_sub_division;
    if (this.subDivisionId === 0) {
      if (this.subDivisions.length > 0) {
        this.subDivisionId = this.subDivisions[0].id;
      }
    }
    if (!this.enableSubDivision) {
      this.subDivisionId = 0;
    }
    this.getWods(this.competitionId);
    this.resultService.verifyResults(
      this.championshipId,
      this.championship.competitions[0].id
    );
  }

  getResults(): void {
    this.isLoading = true;
    this.isShowPublishButton = false;

    // clear before results load
    clearTimeout(this.timeOut);
    this.results = [];
    this.resultsFilterData = [];
    this.metaData.page = 1;
    this.metaData.per_page = 1000;
    this.metaData.total = 0;
    if (this.competition.enable_sub_division === 1) {
      if (!this.subDivisionId) {
        return;
      }
    }
    if (!this.wodId || !this.championshipId || !this.competitionId) {
      return;
    }

    this.fetchResults();
  }

  public subDivisionSelect(event: MatSelectChange): void {
    this.subDivisionId = event.value;
    this.getResults();
  }

  tabAction(event) {
    // console.log('tab action', event)
    this.ngUnsubscribe.next();

    this.wod = this.wods[event.index];
    console.log(this.wod);
    this.wodId = this.wod.id;

    // clear before timeout results
    clearTimeout(this.timeOut);
    this.results = [];

    this.unitType = Utils.getUnitName(this.wod.wod_type.id, true);
    this.unitTypeTiebreak = this.wod.tiebreak_type
      ? Utils.getUnitName(this.wod.tiebreak_type.id, true)
      : '';
    // console.log('tiebreak', this.unitTypeTiebreak, this.wod.tiebreak_type_id)

    this.getResults();
  }

  // Get Result Status
  getResultStatus(result: any, isType: boolean): string {
    if (isType) {
      if (result.result.status === ResultStatus.APPROVED) {
        return 'success';
      } else if (result.result.status === ResultStatus.REFUSED) {
        return 'danger';
      } else if (result.result.status === ResultStatus.WAITING) {
        return 'normal';
      }
    } else {
      if (result.result.status === ResultStatus.APPROVED) {
        return this.translate.STATUS.APPROVED.toUpperCase();
      } else if (result.result.status === ResultStatus.REFUSED) {
        return this.translate.STATUS.REPROVE.toUpperCase();
      } else if (result.result.status === ResultStatus.WAITING) {
        return this.translate.STATUS.WAITING.toUpperCase();
      }
    }

    return '';
  }

  getInputIsDisabled(result: any): boolean {
    if (result) {
      if (result.has_cap) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  getValueResult(result: any) {
    if (result) {
      if (this.wod.wod_type.id === this.unitTypeTime && result.has_cap) {
        return this.wod.goal - result.result > 0
          ? `CAP+${this.wod.goal - result.result}`
          : 'CAP';
      } else {
        return result.result;
      }
    }

    return '';
  }

  getValueTiebreak(result: any) {
    if (result && this.wod.tiebreak_type) {
      if (this.wod.tiebreak_type.id === this.unitTypeTime && result.has_cap) {
        return this.wod.goal - result.result > 0
          ? `CAP+${this.wod.goal - result.result}`
          : 'CAP';
      } else {
        return result.result;
      }
    }

    return '';
  }

  inputOnBlur(event: any, type: string, item: any) {
    console.log(event.target.value, type, item);
    let result: any;
    const value = event.target.value;

    // Prevent send api with not update result
    if (
      (item.result === null && value === '') ||
      (type === 'result' && item.result && value === item.result.result)
    ) {
      return;
    } else if (
      (item.result === null && value === '') ||
      (type === 'tiebreak' &&
        item.result &&
        value === item.result.tiebreakresult)
    ) {
      return;
    }

    if (type === 'result') {
      result = {
        // result: (this.wod.unit_type_id === 1) ? moment.duration(value).asSeconds() : value,
        result: value !== '' ? value : null,
        has_cap: 0,
        published: 0
      };
    } else if (type === 'tiebreak') {
      result = {
        tiebreakresult: value,
        published: 0
      };
    }

    console.log(result);
    this.isLoadingSaveResult = true;
    this.saveOrUpdateResult(result, item);
    /*if ((value.length === 0) && type === 'result') {
      const subscription = this.resultService.deleteResult(item.result.id)
        .subscribe(
          response => {
            // console.log("result", response);
            this.isLoadingSaveResult = false
            this.isShowPublishButton = true;
            item['result'] = null
            if (this.resultOptionsMenu) this.resultOptionsMenu.closeMenu();
          }, err => {
            this.isLoadingSaveResult = false
            // console.log("err", err);
            // this.isLoadingSaveTimeCap = false
          }
        );
      this.subscriptions.add(subscription);
    } else {
      this.saveOrUpdateResult(result, item);
    }*/
  }

  radioChange(event, item: any) {
    // console.log(event)

    if (event.value === 'time') {
      const result = {
        result: null,
        has_cap: 0,
        published: 0
      };
      this.saveOrUpdateResult(result, item);
      /*const subscription = this.resultService.deleteResult(this.selectedResult.result.id)
        .subscribe(
          response => {
            // console.log("result", response);
            this.selectedResult.result = null
            // this.isLoadingSaveTimeCap = false
            if (this.resultOptionsMenu) this.resultOptionsMenu.closeMenu();
          }, err => {
            // console.log("err", err);
            // this.isLoadingSaveTimeCap = false
          }
        );
      this.subscriptions.add(subscription);*/
    }
  }

  saveTimeCap(result: any, option, value: string) {
    // console.log(result, option, this.inputRepsVal, this.selectedResult)
    if (parseInt(value) > this.wod.goal) {
      this.tipeCapError = this.translate.TIMECAP_ERROR;
      return;
    }

    this.isLoadingSaveTimeCap = true;
    const resultTimeCap = {
      has_cap: option === 'timeCap' ? 1 : 0,
      result: value,
      published: 0
    };
    // console.log(resultTimeCap)

    this.isLoadingSaveTimeCap = true;
    // result.result.id
    const subscription = this.resultService
      .saveOrUpdateResult(resultTimeCap, result, this.wodId)
      .subscribe(
        response => {
          // console.log("result", response);
          this.tipeCapError = null;
          this.selectedResult['result'] = response;
          console.log(result, option, this.inputRepsVal, this.selectedResult);
          this.isLoadingSaveTimeCap = false;
          this.isShowPublishButton = true;
          this.totalFilterData();
          if (this.resultOptionsMenu) {
            this.resultOptionsMenu.closeMenu();
          }
        },
        err => {
          // console.log("err", err);
          this.isLoadingSaveTimeCap = false;
          this.tipeCapError = 'Erro';
        }
      );
    this.subscriptions.add(subscription);
  }

  showCapReps(wod: Wod): boolean {
    return wod && wod.wod_type.id === 2 && wod.goal && wod.goal > 0;
  }

  openModalComment(result: Result): void {
    const modalRef = this.dialog.open(ModalCommentsComponent, {
      width: '440px',
      height: '100%',
      panelClass: 'modal'
    });

    modalRef.componentInstance.result = result;

    const subscription = modalRef.afterClosed()
      .subscribe(results => {
        modalRef.close();
        if (results && results.update) {
          this.isShowPublishButton = true;
        }
      });
    this.subscriptions.add(subscription);
  }

  // Open Modal
  openModalWithResult(result: any) {
    if (!result.result) {
      this.toastr.error(
        this.translate.TOASTR.ERROR_WITHOUT_RESULT.DESCRIPTION,
        this.translate.TOASTR.ERROR_WITHOUT_RESULT.TITLE,
        null,
      );
      return;
    }
    const modalRef = this.dialog.open(ModalVideoResulComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'modal'
    });

    modalRef.componentInstance.result = result;
    modalRef.componentInstance.wod = this.wod;
    modalRef.componentInstance.unitTypeTime = this.unitTypeTime;
    modalRef.componentInstance.event = this.event;

    const subscription = modalRef.afterClosed()
      .subscribe(results => {
        modalRef.close();
        console.log(results);
        if (results && results.update) {
          this.isLoadingSaveTimeCap = false;
          this.isShowPublishButton = true;
          this.totalFilterData();
        }
      });
    this.subscriptions.add(subscription);
  }

  // Publish result
  publishResults() {
    this.isLoadingPublish = true;
    this.isLoadingSaveResult = true;

    const publishChampionship = `championship_id=${this.championshipId}`;
    const publishCompetition = `competition_id=${this.competitionId}`;

    const subscription = this.rankingService
      .publishRanking(publishChampionship, publishCompetition)
      .subscribe(
        response => {
          this.toastr.show(
            this.translate.PUBLISH.TOASTR_DESCRIPTION,
            this.translate.PUBLISH.TOASTR_TITLE,
            null,
            'success'
          );

          this.isLoadingPublish = false;
          this.isLoadingSaveResult = false;
          this.typeSearch = ResultEnum.ALL;
          this.getResults();
        },
        err => {
          this.toastr.show(
            this.translate.PUBLISH.TOASTR_DESCRIPTION_FAIL,
            this.translate.PUBLISH.TOASTR_TITLE_FAIL,
            null,
            'error'
          );
          // console.log("err", err);
          this.isLoadingPublish = false;
          this.isLoadingSaveResult = false;
        }
      );
    this.subscriptions.add(subscription);

    // let publish = {
    //   championship_id: this.championshipId,
    //   competition_id: this.competitionId
    // }
    // this.resultService.publishResults(publish)
    //   .subscribe(
    //     response => {
    //       // console.log("result", response);

    //       this.toastr.show(
    //         this.translate.PUBLISH.TOASTR_DESCRIPTION,
    //         this.translate.PUBLISH.TOASTR_TITLE,
    //         null,
    //         'success',
    //       )

    //       this.isLoadingPublish = false
    //       this.isLoadingSaveResult = false

    //       this.getResults()
    //     }, err => {
    //       // console.log("err", err);
    //       this.isLoadingPublish = false
    //       this.isLoadingSaveResult = false
    //     }
    //   )
  }

  // SORT
  sortAction(sort) {
    // console.log("sort", sort)

    if (sort.sort === null) {
      this.sortResult('result', 'DESC');
    } else {
      this.sortResult(sort.sort, sort.order);
    }
  }

  sortResult(column: string, order: string) {
    Utils.sortArray(this.resultsFilterData, column, order);
  }

  scrollToTop(event) {
    // console.log('scroll', event)
    if (event.type === 'scrollToTop') {
      this.fusePerfectScrollbarDirective.scrollToTop(0, 2);
    }
  }

  public saveOrUpdateResult(result: any, item: Competitor): void {
    const subscription = this.resultService
      .saveOrUpdateResult(result, item, this.wodId)
      .subscribe(
        response => {
          this.isShowPublishButton = true;
          this.isLoadingSaveResult = false;
          console.log('result', response);
          const responseResult = response;

          if (
            this.wod.wod_type.id === this.unitTypeTime &&
            response.result &&
            !response.has_cap
          ) {
            const d = moment.duration(response.result * -1);
            // @ts-ignore
            const milliseconds = `${d.milliseconds()}`.padStart(3, '0');
            // @ts-ignore
            const minutes = `${d.minutes()}`.padStart(2, '0');
            // @ts-ignore
            const seconds = `${d.seconds()}`.padStart(2, '0');

            if (this.event.isResultTimeInMilliseconds) {
              responseResult.result = `${minutes}:${seconds}.${milliseconds}`;
            } else {
              responseResult.result = `${minutes}:${seconds}`;
            }
          }

          if (
            this.wod.tiebreak_type &&
            this.wod.tiebreak_type.id === this.unitTypeTime &&
            response.tiebreakresult
          ) {
            const d = moment.duration(response.tiebreakresult * -1);
            // @ts-ignore
            const milliseconds = `${d.milliseconds()}`.padStart(3, '0');
            // @ts-ignore
            const minutes = `${d.minutes()}`.padStart(2, '0');
            // @ts-ignore
            const seconds = `${d.seconds()}`.padStart(2, '0');
            if (this.event.isResultTimeInMilliseconds) {
              responseResult.tiebreakresult = `${minutes}:${seconds}.${milliseconds}`;
            } else {
              responseResult.tiebreakresult = `${minutes}:${seconds}`;
            }
          }
          responseResult.published = false;

          item['result'] = responseResult;
          if (this.resultOptionsMenu) {
            this.resultOptionsMenu.closeMenu();
          }
          this.totalFilterData();
        },
        err => {
          // console.log("err", err);
          this.isLoadingSaveResult = false;
          const errors: Errors = new Errors().deserialize(err.error as any);
          this.toastr.show(
            errors.getFullMessages(),
            this.translate.TOASTR.ERROR.TITLE,
            null,
            'error'
          );
        }
      );
    this.subscriptions.add(subscription);
  }

  public disqualifiedCompetitor(item: any, type: number): void {
    let customFieldName = '';
    let customFieldType = '';
    if (type === Disqualified.CUT) {
      customFieldName = this.translate.DISQUALIFIED.CUT;
      customFieldType = `(${this.translate.DISQUALIFIED.TYPE_CUT})`;
    }
    if (type === Disqualified.WD) {
      customFieldName = this.translate.DISQUALIFIED.WD;
      customFieldType = `(${this.translate.DISQUALIFIED.TYPE_WD})`;
    }
    if (type === Disqualified.CANCEL) {
      customFieldName = this.translate.DISQUALIFIED.REMOVE;
      customFieldType = '';
    }
    this.subscriptions.add(
      this.translateService
        .get('RESULTS.DISQUALIFIED.MESSAGE', {
          custom_field_name: customFieldName,
          custom_field_type: customFieldType
        })
        .subscribe((disqualifiedMessage: string) => {
          this.disqualifiedMessage = disqualifiedMessage;
        })
    );

    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });
    dialogRef.componentInstance.type = 'warning';
    dialogRef.componentInstance.titleDialog = this.translate.DISQUALIFIED.TITLE;
    dialogRef.componentInstance.dialogContent = this.disqualifiedMessage;
    dialogRef.componentInstance.cancelButton = this.translate.DISQUALIFIED.CANCEL;
    dialogRef.componentInstance.actionButton = this.translate.DISQUALIFIED.CONFIRM;
    dialogRef.componentInstance.object = type;
    dialogRef.componentInstance.dialogConfirm = true;
    const subscription = dialogRef.afterClosed().subscribe(resultType => {
      let parameter = {};
      if (resultType !== null) {
        if (resultType === Disqualified.CUT) {
          parameter = {
            is_cut: 1,
            disqualified: 0
          };
        }
        if (resultType === Disqualified.WD) {
          parameter = {
            is_cut: 0,
            disqualified: 1
          };
        }
        if (resultType === Disqualified.CANCEL) {
          parameter = {
            is_cut: 0,
            disqualified: 0
          };
        }
        if (item.result) {
          parameter = {
            ...parameter,
            result_id: item.result.id
          };
        }
        this.subscriptions.add(
          this.competitorService
            .disqualifiedCompetitor(parameter, item.id)
            .subscribe((competitor: Competitor) => {
              item['is_cut'] = competitor.is_cut;
              item['disqualified'] = competitor.disqualified;
              this.isShowPublishButton = true;
              this.isLoadingSaveResult = false;
              if (item['result']) {
                item['result']['published'] = false;
              }
              this.totalFilterData();
              if (resultType === Disqualified.WD) {
                item['result'] = null;
                return;
              }
            })
        );
      }
    });
    this.subscriptions.add(subscription);
  }

  private fetchResults(): void {
    const subscription = this.resultService
      .getResults(
        this.championshipId,
        this.competitionId,
        this.wodId,
        this.subDivisionId,
        this.metaData.page,
        this.metaData.per_page
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        resultsData => {
          this.metaData.total = resultsData.total;
          const results = resultsData.data.map((result: Result) => new Result().deserialize(result));

          const newResult: any[] = results;
          this.isLoading = false;
          // this.results = newResult
          this.populateArray(newResult);

          this.isEmptyResult = this.results.length === 0;

          const resultsPedding = this.results.filter((result: Result) => {
            return result.result && result.result.published === false;
          });

          if (resultsPedding && resultsPedding.length > 0) {
            this.isShowPublishButton = true;
          } else {
            this.isShowPublishButton = false;
          }
          // this.results = result
        },
        err => {
          console.log('err', err);
          // this.isLoading = false
        }
      );
    this.subscriptions.add(subscription);
  }

  private populateArray(newResults: Result[]) {
    for (const newResult of newResults) {
      this.results.push(newResult);
      this.resultsFilterData.push(newResult);
    }
    this.resultsFilterData = this.results;
    this.filterData();
    if (this.metaData.total > this.results.length) {
      this.metaData.page++;
      this.fetchResults();
      return;
    }

    const resultsPedding = this.results.filter((result: Result) => {
      return result.result && result.result.published === false;
    });

    if (resultsPedding && resultsPedding.length > 0) {
      this.isShowPublishButton = true;
    } else {
      this.isShowPublishButton = false;
    }
  }

  private filterData(): void {
    this.populateFilter(this.results.filter(element => {
      if (this.typeSearch === ResultEnum.ALL) {
        const number = String(element.number);
        return (
          element.name.toUpperCase().indexOf(this.search) > -1 ||
          number.toUpperCase().indexOf(this.search) > -1
        );
      }
      if (this.typeSearch === ResultEnum.NO_RESULT) {
        const number = String(element.number);
        return (
          (element.name.toUpperCase().indexOf(this.search) > -1 ||
            number.toUpperCase().indexOf(this.search) > -1) &&
          (!element.result || element.result.result === null)
        );
      }
      if (this.typeSearch === ResultEnum.PUBLISHED) {
        const number = String(element.number);
        return (
          (element.name.toUpperCase().indexOf(this.search) > -1 ||
            number.toUpperCase().indexOf(this.search) > -1) &&
          element.result &&
          element.result.published === true
        );
      }
      if (this.typeSearch === ResultEnum.NOT_PUBLISHED) {
        const number = String(element.number);
        return (
          (element.name.toUpperCase().indexOf(this.search) > -1 ||
            number.toUpperCase().indexOf(this.search) > -1) &&
          element.result &&
          element.result.published === false
        );
      }
    }));
  }

  private populateFilter(newResults: Result[], index: number = 0): boolean {
    if (index === 0) {
      this.resultsFilterData = [];
    }
    const items = 1000;
    const maxCount =
      index + items <= newResults.length
        ? index + items
        : index + (newResults.length - index);
    let newIndex = index;
    for (let i = index; i <= maxCount; i++) {
      const result = newResults[i];
      if (result) {
        this.resultsFilterData.push(result);
      }
      newIndex = i;
    }

    if (maxCount < newResults.length) {
      setTimeout(() => {
        this.populateFilter(newResults, newIndex + 1);
        if (this.tableList) {
          this.tableList.reloadItems();
        }
      }, 0.5);
      return false;
    }
    this.totalFilterData();
    return true;
  }

  private totalFilterData(): void {
    this.totalFilter = this.results.filter(element => {
      const number = String(element.number);
      return (
        element.name.toUpperCase().indexOf(this.search) > -1 ||
        number.toUpperCase().indexOf(this.search) > -1
      );
    }).length;
    this.totalNoResult = this.results.filter(element => {
      const number = String(element.number);
      return (
        (element.name.toUpperCase().indexOf(this.search) > -1 ||
          number.toUpperCase().indexOf(this.search) > -1) &&
        (!element.result || element.result.result === null)
      );
    }).length;
    this.totalPublished = this.results.filter(element => {
      const number = String(element.number);
      return (
        (element.name.toUpperCase().indexOf(this.search) > -1 ||
          number.toUpperCase().indexOf(this.search) > -1) &&
        element.result &&
        element.result.published === true
      );
    }).length;
    this.totalNotPublished = this.results.filter(element => {
      const number = String(element.number);
      return (
        (element.name.toUpperCase().indexOf(this.search) > -1 ||
          number.toUpperCase().indexOf(this.search) > -1) &&
        element.result &&
        element.result.published === false
      );
    }).length;
    this.changeDetectorRefs.detectChanges();
  }

  private registerSubDivision(): void {
    const subscription = this.subDivisionHttpService
      .subDivisionObservable()
      .subscribe((results: SubDivision[]) => {
        this.subDivisions = results || [];
        if (this.subDivisions.length > 0) {
          this.subDivisionId = this.subDivisions[0].id;
        }
        this.getResults();
      });

    this.subscriptions.add(subscription);
  }

  private registerVerifyResultsObservableObservable(): void {
    const subscription = this.resultService
      .verifyResultsObservable()
      .subscribe(publish => {
        this.isShowPublishButton = publish;
      });

    this.subscriptions.add(subscription);
  }

  private fetchSubDivision(): void {
    this.subDivisionHttpService.fetchSubDivision(this.championshipId);
  }
}
