<div class="tab-bar" #tabbar>
  <ul [ngClass]="{'pl-32': padding, 'pr-32': padding}">
    <li *ngFor="let item of items; let i = index">
      <button
        [ngClass]="{'active': i == tabActive}"
        (mouseover)="mouseOver($event)"
        (mouseleave)="mouseLeave($event)"
        (click)="tabClick($event, i)"
      >
        {{ item }}
      </button>
    </li>
  </ul>
  <div class="line-tab-bottom" [style.width]="width + 'px'" [style.left]="left + 'px'"></div>
  </div>