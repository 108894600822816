export const locale = {
  lang: 'en-US',
  data: {
    'MODAL_COPY_LOT': {
      'TITLE': 'Copiar ingressos',
      'TITLE_SECTION': 'Informações do ingresso',
      'DESCRIPTION_SECTION': 'Selecione os ingressos que deseja copiar e para quais categorias eles serão copiados.',
      'COMPETITIONS': 'Categorias',
      'LOTS': 'Ingressos',
      'SAVE': 'Salvar',
      'CANCEL': 'Cancelar',
    }
  }
}