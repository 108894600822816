import { BtnModule } from './../btn/btn.module';
import { MaterialModule } from './../../modules/material.module';
import { SharedModule } from './../../modules/shared.module';
import { TabBarModule } from './../tab-bar/tab-bar.module';
import { FiltersComponent } from './filters.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTab, MatTabsModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    TabBarModule,
    MaterialModule,
    FlexLayoutModule,
    BtnModule
  ],
  declarations: [
    FiltersComponent
  ],
  exports: [
    FiltersComponent,
    FlexLayoutModule,
    BtnModule
  ],
  providers: [
  ]
})
export class FiltersModule { }
