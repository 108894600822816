<div mat-dialog-content class="dialog-container">
  <!-- <div class="modal-background" (click)="dialogRef.close()"></div> -->
  <div class="card-content">
    <div fxLayout="column" class="header-modal p-32 pb-16">
      <div fxLayout="row" fxLayoutAlign="space-between start">
        <h1 fxLayout="row" fxLayoutAlign="start center">
          <img src="{{ (user.avatar) ? user.avatar : null | placeholderImage:'user' }}" width="80" class="avatar mr-8"
          />
          <div fxLayout="column">
            <div
              fxLayout="row"
              fxLayoutAlign="space-between center"
              class="edit-number-competitor"
            >
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                *ngIf="!editNumber"
              >
                {{ competitor.number || '-' }}
                <btn-transparent
                  materialIcon="create"
                  buttonWidth="25px"
                  iconAlign="left"
                  color="#fff"
                  class="ml-8"
                  isSmall="true"
                  (click)="editNumber = !editNumber"
                ></btn-transparent>
              </div>
              <div
                fxLayout="row"
                fxLayout.xs="column"
                fxLayoutAlign.xs="start start"
                fxLayoutAlign="start end"
                *ngIf="editNumber"
              >
                <div fxLayout="row">
                  <form
                    [formGroup]="numberForm"
                    name="numberForm"
                    autocomplete="off"
                    novalidate
                    fxLayout="row"
                  >
                    <div fxLayout="column" fxLayoutAlign="start start">
                      <input
                        matInput
                        formControlName="number"
                        uppercase
                        required
                        [ngClass]="{'error': (numberForm.controls.number.hasError('min') ||numberForm.controls.number.hasError('required')) }"
                      />
                    </div>
                  </form>
                  <btn-transparent
                    [loading]="false"
                    materialIcon="done"
                    buttonWidth="25px"
                    iconAlign="left"
                    classInside="small"
                    class="ml-8"
                    color="#fff"
                    isSmall="true"
                    (click)="!numberForm.invalid ? editNumberCompetitor() : ''"
                  ></btn-transparent>
                </div>
                <mat-error style="font-size: 10px; color: #ebae46" *ngIf="numberForm.controls.number.hasError('required')">
                  {{ 'USER.VALIDATIONS.NUMBER_REQUIRED' | translate }}
                </mat-error>
                <mat-error style="font-size: 10px; color: #ebae46" *ngIf="numberForm.controls.number.hasError('min')">
                  {{ 'USER.VALIDATIONS.NUMBER_LIMIT' | translate: { custom_number: competitor.competition.start_number } }}
                </mat-error>
              </div>
            </div>
            {{ user.fullname }}
          </div>

          <mat-menu class="submenu user-menu bottom arrow-right" #configDisqualifiedMenu="matMenu" [overlapTrigger]="false" xPosition="before">
            <ng-template matMenuContent let-competitorDisqualified="competitorDisqualified" var-timeCapOption="timeCapOption">
              <ng-container *ngIf="competitorDisqualified.is_cut === 0 && competitorDisqualified.disqualified === 0">
                <button class="line-bottom mat-menu-item" (click)="disqualifiedCompetitor(enumDisqualified.CUT)">
                  <span>{{ 'USER.DISQUALIFIED.CUT' | translate }} {{ 'USER.DISQUALIFIED.TYPE_CUT' | translate }}</span>
                </button>
                <button class="line-bottom mat-menu-item" (click)="disqualifiedCompetitor(enumDisqualified.WD)">
                  <span>{{ 'USER.DISQUALIFIED.WD' | translate }} {{ 'USER.DISQUALIFIED.TYPE_WD' | translate }}</span>
                </button>
              </ng-container>
              <ng-container *ngIf="competitorDisqualified.is_cut || competitorDisqualified.disqualified">
                <button class="line-bottom mat-menu-item" (click)="disqualifiedCompetitor(enumDisqualified.CANCEL)">
                  <span class="text-red">{{ 'USER.DISQUALIFIED.REMOVE' | translate | titlecase }}</span>
                </button>
              </ng-container>
            </ng-template>
          </mat-menu>
          
        </h1>
        <span class="close" (click)="dialogRef.close()">
          <img src="./assets/icons/modal/ic-close-white.svg" />
        </span>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="mt-16">
        <span fxFlex="0 1 50%" fxFlex.xs="0 1 100%" *ngIf="user.status !== 0">
          <strong>E-mail:</strong> {{ user.email}}
        </span>
        <span fxFlex="0 1 50%" fxFlex.xs="0 1 100%">
          <strong>{{ translate.LEADERBOARD_NAME }}:</strong> {{ leaderboardName | uppercase }}
        </span>
        
        <span fxFlex="0 1 50%" fxFlex.xs="0 1 100%" *ngIf="user.status !== 0">
          <strong>{{ translate.GENDER }}:</strong> {{ user.gender_string }}
        </span>
        <span fxFlex="0 1 50%" fxFlex.xs="0 1 100%" *ngIf="user.status !== 0">
          <strong>{{ translate.CPF }}:</strong> {{ user.cpf }}
        </span>
        
        <span fxFlex="0 1 50%" fxFlex.xs="0 1 100%" *ngIf="user.status !== 0">
          <strong>{{ translate.BIRTHDATE }}</strong> {{ user.birthdate | date:translate.DATE_FORMAT }}
        </span>
        <span fxFlex="0 1 50%" fxFlex.xs="0 1 100%">
          <strong>{{ translate.SIZE_TSHIRT }}:</strong> {{ (competitor.team.members && competitor.team.members[0] && competitor.team.members[0].size) ? competitor.team.members[0].size : '' }}
        </span>
        
        <span fxFlex="0 1 50%" fxFlex.xs="0 1 100%">
          <strong>{{ translate.BOX }}:</strong> {{ competitor.team?.box?.name | uppercase }}
        </span>
        <span fxFlex="0 1 50%" fxFlex.xs="0 1 100%" *ngIf="user.status !== 0">
          <strong>{{ translate.PHONE }}:</strong> {{ user.phone }}
        </span>
        
        <span fxFlex="0 1 50%" fxFlex.xs="0 1 100%" *ngIf="user.state !== null">
          <strong>{{ translate.CITY }}:</strong> {{ (user.state) ? user.state : '' }}
        </span>

        <span fxFlex="0 1 50%" fxFlex.xs="0 1 100%" *ngFor="let field of competitor.team.subscription_custom_fields">
          <strong>{{ field.event_custom_field.name }}:</strong> {{ getFieldValue(field) }}
        </span>
        
      </div>
      <div fxLayout="row" fxLayoutAlign="end end" class="mt-16 mb-16">
        <btn [btnLoading]="true" type="grayscale" (click)="confirmStatusOpened = !confirmStatusOpened">{{
          ((competitor.status === 2) ? translate.INACTIVE : translate.ACTIVE) | uppercase }}</btn>
      </div>
    </div> <!-- END HEADER -->
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="confirm-status pl-32 pr-32 pt-12 pb-12"
      *ngIf="confirmStatusDisqualifiedCompetitor">
      <div>
        <strong>{{ disqualifiedMessage }}</strong>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <btn [btnLoading]="true" type="grayscale" [buttonDisabled]="isLoadingDisqualifiedStatus" (click)="confirmStatusDisqualifiedCompetitor = !confirmStatusDisqualifiedCompetitor"
          class="mr-16">{{ 'USER.DISQUALIFIED.CANCEL' | translate | uppercase}}</btn>
        <btn [btnLoading]="true" [loading]="isLoadingDisqualifiedStatus" (click)="confirmDisqualifiedCompetitor()">
            {{ 'USER.DISQUALIFIED.CONFIRM' | translate | uppercase}}
        </btn>
      </div>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="confirm-status pl-32 pr-32 pt-12 pb-12"
      *ngIf="confirmStatusOpened">
      <div>
        <strong>{{ (competitor.status === 2) ? translate.CONFIRM_STATUS_INACTIVE : translate.CONFIRM_STATUS_ACTIVE }}</strong>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <btn [btnLoading]="true" type="grayscale" [buttonDisabled]="isLoadingConfirmStatus" (click)="confirmStatusOpened = !confirmStatusOpened"
          class="mr-16">{{ translate.CANCEL | uppercase }}</btn>
        <btn [btnLoading]="true" [loading]="isLoadingConfirmStatus" (click)="setActiveOrInactive()">{{
          ((competitor.status === 2) ? translate.INACTIVE : translate.ACTIVE) | uppercase }}</btn>
      </div>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="error-status pl-32 pr-32 pt-12 pb-12"
      *ngIf="inactiveRequestError">
        {{ inactiveRequestError }}
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="success-status pl-32 pr-32 pt-12 pb-12"
      *ngIf="successDisqualifiedCompetitor">
        {{ successDiqualifiedCompetitorRequest }}
    </div>

    <form name="userForm" [formGroup]="userForm" autocomplete="off" novalidate *ngIf="competitor !== null">
      <div fxLayout="row" fxLayout.xs="column" class="pl-32 pr-32 pt-32">
        <mat-form-field fxFlex="50" fxFlex.xs="100" class="mr-8">
          <input
            matInput
            placeholder="{{ translate.LEADERBOARD_NAME }}"
            type="text"
            formControlName="leaderboardName"
            [errorStateMatcher]="matchers.leaderboardName"
            uppercase required>
          <mat-error *ngIf="userForm.controls.leaderboardName.hasError('required')">
            {{ translate.VALIDATIONS.LEADERBOARDNAME_REQUIRED }}
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="50" fxFlex.xs="100" class="ml-8">
          <mat-select
            placeholder="{{ translate.SIZE_TSHIRT }}"
            formControlName="tShirt">
            <mat-option *ngFor="let size of tshirtSize" [value]="size">
              {{ size }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.xs="column" class="pl-32 pr-32 mt-8">
        <mat-form-field fxFlex="50" fxFlex.xs="100" class="mr-8">
          <input matInput placeholder="{{ translate.BOX }}" formControlName="box" [matAutocomplete]="autoBox" (ngModelChange)="searchBox($event)" uppercase>
          <mat-autocomplete #autoBox="matAutocomplete" [displayWith]="displayFnBox">
            <mat-option *ngFor="let box of boxes" [value]="box" >
              {{ box.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        

        <mat-form-field fxFlex="50" fxFlex.xs="100" class="ml-8">
          <mat-select placeholder="{{ translate.CATEGORY }}" formControlName="competition" [errorStateMatcher]="matchers.competition" required (selectionChange)="competitionSelect($event)">
            <mat-option *ngFor="let competition of competitions" [value]="competition.id">
              {{ competition.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="userForm.controls.competition.hasError('required')">
            {{ translate.VALIDATIONS.CATEGORY_REQUIRED }}
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="50" fxFlex.xs="100" class="ml-8" *ngIf="enableSubDivision">
          <mat-select placeholder="{{ championship.sub_division_group_name ? championship.sub_division_group_name : translate.SUB_DIVISION }}" formControlName="subDivision" [errorStateMatcher]="matchers.subDivision" required>
            <mat-option *ngFor="let subDivision of subDivisions" [value]="subDivision.id">
              {{ subDivision.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="userForm.controls.subDivision.hasError('required')">
            {{ translate.VALIDATIONS.SUB_DIVISION_REQUIRED }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>

    <div class="pl-32 pr-32 mt-16 mb-8 text-red" *ngIf="requestErrors !== null">
      {{ requestErrors }}
    </div>

    <div class="pb-32 mt-8 p-32" fxLayoutAlign="end center">
      <!-- <btn [btnLoading]="true" type="grayscale" (click)="cancelForm()">{{ translate.CANCEL | uppercase }}</btn> -->
      <btn [btnLoading]="true" class="ml-16" (click)="updateCompetitor()"  [buttonDisabled]="!userForm.valid" [loading]="isUpdateLoading">{{ translate.SAVE | uppercase }}</btn>
    </div>

    
    <h4 class="pl-32 pr-32" *ngIf="payments.length > 0">{{ translate.PAYMENTS }}</h4>
    <!-- Table -->
    <table-x [items]="payments" [config]="tableConfig" [isLoading]="isLoading" *ngIf="payments.length > 0" (sortEvent)="sortAction($event)">
      <column property="payment_date" header="{{ translate.TABLE_HEADER.PAYMENT_DATE }}" [sortable]="false" align="left" [borderLeftColor]="{ '1':'#8195A5', '2':'#81BB46', '3': '#EB5B46', '4': '#BC83EE', '11': '#BC83EE' }">
        <ng-template #dataTableCell let-item="item">
          <div class="pt-16 pb-16">
            {{ ((item.payment_date) ? item.payment_date : item.created_at) | date:translate.DATE_FORMAT}}
          </div>
        </ng-template>
      </column>

      <column property="payment_method" header="{{ translate.TABLE_HEADER.PAYMENT_METHOD }}" [sortable]="false" align="left">
        <ng-template #dataTableCell let-item="item">
          {{ getPaymentMethodName(item) }}
        </ng-template>
      </column>

      <!--<column property="payment_delivery" header="{{ translate.TABLE_HEADER.PAYMENT_DELIVERY }}" [sortable]="false" align="left">
        <ng-template #dataTableCell let-item="item">
          {{ item.receive_date_vendor | date:translate.DATE_FORMAT }}
        </ng-template>
      </column>-->

      <column property="value" header="{{ translate.TABLE_HEADER.VALUE }}" [sortable]="false" align="left">
        <ng-template #dataTableCell let-item="item">
          {{ formatCurrency((item.payment_amount | currency:translate.CURRENCY_CODE:'symbol-narrow':'1.2-2'), translate.CURRENCY_CODE) }}
        </ng-template>
      </column>

      <column property="payment_id" header="{{ translate.TABLE_HEADER.PAYMENT_ID }}" [sortable]="false" align="left">
        <ng-template #dataTableCell let-item="item">
          {{ item.payment_id ? item.payment_id : '' }}
        </ng-template>
      </column>

      <column property="status" header="{{ translate.TABLE_HEADER.STATUS }}" [sortable]="false" align="left">
        <ng-template #dataTableCell let-item="item">
          <label-x type="{{ getPaymentStatus(item, true) }}">
            {{ getPaymentStatus(item) | uppercase }}
          </label-x>
        </ng-template>
      </column>
    </table-x>
    
  </div>
</div>