<ng-template #sidebarTempRef>
  <div class="filter">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="p-24 pb-16 line-bottom">
      <h2 class="mt-0 mb-0">{{ title }}</h2>
      <button class="close" (click)="filterSidebarService.toggle();">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="p-24">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>