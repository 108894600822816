import { RegistrationIdentificationComponent } from './registration-identification.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../../../../../environments/environment';

const routes = [
  {
    path: 'event-identification',
    component: RegistrationIdentificationComponent,
    // canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    RegistrationIdentificationComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    })
  ],
  exports: [
    RegistrationIdentificationComponent
  ]
})

export class RegistrationIdentificationModule { }
