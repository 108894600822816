<div mat-dialog-content class="dialog-container">
  <!-- <div class="modal-background" (click)="dialogRef.close()"></div> -->
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>{{ isEdit ? translate.EDIT_TITLE : translate.NEW_TITLE}}</h1>
      <span class="close" (click)="dialogRef.close(null)">
        <img src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>

    <div fxLayout="row" class="mt-16 mb-32">
      <description-section title="{{ translate.SECTION_TITLE }}" text="{{ translate.SECTION_DESCRIPTION }}">
        <mat-icon class="ic-info mr-8" svgIcon="ic-cat-info"></mat-icon>
      </description-section>
    </div>

    <div fxLayout="column" fxLayoutAlign="start stretch">
      <form [formGroup]="promocodeForm" name="promocodeForm" autocomplete="off" novalidate>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="pl-16 pr-16">
          <mat-form-field fxFlex="50" class="pr-8">
            <input matInput placeholder="{{ translate.FIELDS.NAME }}" formControlName="name" [errorStateMatcher]="matchers.name" uppercase required>
            <mat-error *ngIf="promocodeForm.controls.name.hasError('required')">
              {{ translate.VALIDATIONS.NAME_REQUIRED }}
            </mat-error>
          </mat-form-field>
          
          <mat-form-field fxFlex="50" class="pl-8">
            <input matInput placeholder="{{ translate.FIELDS.PROMOCODE }}" formControlName="code" [errorStateMatcher]="matchers.code" uppercase removeWhitespace required>
            <mat-error *ngIf="promocodeForm.controls.code.hasError('required')">
              {{ translate.VALIDATIONS.PROMOCODE_REQUIRED }}
            </mat-error>
            <mat-error *ngIf="promocodeForm.controls.code.hasError('pattern')">
              {{ translate.VALIDATIONS.PROMOCODE_PATTERN }}
            </mat-error>
          </mat-form-field>
        </div>
        
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="mt-16 pl-16 pr-16">
          <mat-form-field fxFlex="25" class="pr-8">
            <mat-select placeholder="{{ translate.FIELDS.TYPE}}" formControlName="type" [errorStateMatcher]="matchers.type" required>
              <mat-option *ngFor="let type of types" [value]="type">
                {{ type }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="promocodeForm.controls.type.hasError('required')">
              {{ translate.VALIDATIONS.TYPE_REQUIRED }}
            </mat-error>
          </mat-form-field>
          
          <mat-form-field fxFlex="25" class="pl-8 pr-8">
            <input matInput placeholder="{{ translate.FIELDS.VALUE }}" formControlName="value" [errorStateMatcher]="matchers.value"
                   currencyMask min="0.00" [max]="promocodeForm.value.type === null || types.indexOf(promocodeForm.value.type) === 0 ? 100.00 : 9999.99"
                   [options]="promocodeForm.value.type === null || types.indexOf(promocodeForm.value.type) === 0 ? { prefix: '', suffix: '%', thousands: '', decimal: ',' } : { prefix: 'R$ ', suffix: '', thousands: '.', decimal: ',' }"
                   uppercase required>
            <mat-error *ngIf="promocodeForm.controls.value.hasError('required')">
              {{ translate.VALIDATIONS.VALUE_REQUIRED }}
            </mat-error>
            <mat-error *ngIf="promocodeForm.controls.value.hasError('max')">
              {{ promocodeForm.value.type === null || types.indexOf(promocodeForm.value.type) === 0 ? translate.VALIDATIONS.PERCENTAGE_MAX_VALUE : translate.VALIDATIONS.VALUE_MAX_VALUE }}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="25" class="pl-8 pr-8">
            <input matInput placeholder="{{ translate.FIELDS.LIMIT }}" formControlName="limit" [errorStateMatcher]="matchers.limit" uppercase>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center pr-8" fxLayoutAlign.xs="start stretch" class="mt-16 pl-16 pr-16">
          <div fxFlex="50" fxFlex.xs="100" fxLayout="row" fxLayoutAlign="space-around center" class="mb-8 pr-8">
            <mat-form-field fxFlex="65" class="inputDate">
              <input matInput placeholder="{{ translate.FIELDS.DATE_START }}" formControlName="dateStart" [errorStateMatcher]="matchers.dateStart" [dropSpecialCharacters]="false" mask="00/00/0000" required>
              <mat-icon matSuffix>date_range</mat-icon>
              <mat-error *ngIf="promocodeForm.controls.dateStart.hasError('required')">
                {{ translate.VALIDATIONS.DATE_START_REQUIRED }}
              </mat-error>
              <mat-error *ngIf="!promocodeForm.controls.dateStart.hasError('required') && promocodeForm.controls.dateStart.hasError('invalid')">
                {{ translate.VALIDATIONS.DATE_INVALID }}
              </mat-error>
            </mat-form-field>

            <select-time
              #pickerTimeStart
              fxFlex="35"
              class="pl-12"
              label="{{ translate.FIELDS.TIME_START }}"
              [value]="timeStart"
              [required]="false"></select-time>
          </div>

          <div fxFlex="50" fxFlex.xs="100" fxLayout="row" fxLayoutAlign="start center" class="mb-8 pl-8">
            <mat-form-field fxFlex="65" class="inputDate">
              <input matInput placeholder="{{ translate.FIELDS.DATE_END }}" formControlName="dateEnd" [dropSpecialCharacters]="false"
                     mask="00/00/0000" required>
              <mat-icon matSuffix>date_range</mat-icon>
              <mat-error *ngIf="promocodeForm.controls.dateEnd.hasError('required')">
                {{ translate.VALIDATIONS.DATE_END_REQUIRED }}
              </mat-error>
              <mat-error *ngIf="!promocodeForm.controls.dateEnd.hasError('required') && promocodeForm.controls.dateEnd.hasError('invalid')">
                {{ translate.VALIDATIONS.DATE_INVALID }}
              </mat-error>
            </mat-form-field>

            <select-time
              #pickerTimeEnd
              fxFlex="35"
              class="pl-12"
              label="{{ translate.FIELDS.TIME_END }}"
              [value]="timeEnd"
              [required]="false"></select-time>
          </div>
        </div>
      </form>
    </div>

    <div class="mt-16 pl-16 pr-16 mb-8 text-red" *ngIf="requestErrors !== null">
      {{ requestErrors }}
    </div>

    <div class="mb-0 mt-8" fxLayoutAlign="end center">
      <btn type="grayscale" (click)="dialogRef.close(null)" class="mr-16">{{ translate.CANCEL | uppercase }}</btn>
      <btn [btnLoading]="true" [loading]="isLoading" [buttonDisabled]="promocodeForm.invalid" (click)="promocodeForm.invalid ? '' : saveCoupon()">{{ translate.SAVE | uppercase }}</btn>
    </div>
  </div>
</div>