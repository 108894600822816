<div class="page-layout blank" fxLayoutAlign="center center" *ngIf="event === null && isLoading">
  <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
</div>

<div class="page-layout blank emtpy-content" fxLayoutAlign="center center" *ngIf="event === null && !isLoading">
  <div class="card auto-width p-64" fxLayout="column" fxLayoutAlign="center center">
    <img src="./assets/icons/placeholder/ic-empty.svg" class="mb-16" />
    {{ 'EVENT.EVENT_NOT_EXIST' | translate | uppercase }}
    <btn class="mt-16" (click)="gotoHome()">{{ 'EVENT.GOTO_HOME' | translate | uppercase }}</btn>
  </div>
</div>

<!-- FIXED NAVBAR -->
<div id="fixed-navbar" fxLayoutAlign="center" [ngClass]="{'hide': hideNavBar}" *ngIf="event !== null" fxHide.xs>
  <div class="wrapper">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="start start" class="p-32 pt-16 pb-16">
      <div fxLayout="column">
        <h2 class="m-0 mb-4">{{ event.name | uppercase }}</h2>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="center start" class="event-information">
          <span class="mr-8" fxLayoutAlign="start center">
            <mat-icon class="mr-4">date_range</mat-icon>{{ event.datetime_start |
            localizedDate:('EVENT.DATE_FORMAT_COMPLETE' | translate) }}
          </span>
          <span fxLayoutAlign="start center" *ngIf="event.address">
            <mat-icon class="mr-4">location_on_outlined</mat-icon>{{ event.address }}
          </span>
        </div>
      </div>
      <div fxFlex="325px" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="space-around center" class="pl-32" *ngIf="event.enabled_subscription">
        <btn style="width: 100%" buttonWidth="100%" (click)="showLotsList()">
          {{ getTitleLotButton() | uppercase }}</btn>
      </div>
    </div>
  </div>
</div>
<!-- END FIXED NAVBAR -->

<div id="scrollBar" class="page-layout blank pb-64" [hidden]="event === null">
  <div id="event" fxLayoutAlign="center" *ngIf="event !== null" >
    <div class="background-img">
      <img src="{{ event.header_image ? event.header_image : headerPlaceholder }}" />
    </div>

    <div id="event-wrapper" class="mt-60" class.xs="mt-0">
      <!-- Manager Event -->
      <div class="card auto-width mb-56" [ngClass.gt-sm]="'mb-0'">
        <img src="{{ event.header_image ? event.header_image : headerPlaceholder }}" class="header-img" />

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="start stretch" class="p-32 pt-16 pb-16">
          <div fxLayout="column">
            <h2 class="m-0 mb-4">{{ event.name | uppercase }}</h2>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="center start" class="event-information">
              <span class="mr-8" fxLayoutAlign="start center"><mat-icon class="mr-4">date_range</mat-icon>{{ event.datetime_start | localizedDate:('EVENT.DATE_FORMAT_COMPLETE' | translate) }}</span>
              <span fxLayoutAlign="start center" *ngIf="event.address"><mat-icon class="mr-4">location_on_outlined</mat-icon>{{ event.address }}</span>
            </div>
          </div>

<!--          <div fxFlex="325px" fxFlex.xs="auto" fxLayout="column" fxLayoutAlign="space-around center" class="pl-32" ngClass.xs="pl-0 lot-button-mobile" *ngIf="event.enabled_subscription">-->
          <div fxFlex="325px" fxFlex.xs="auto" fxLayout="column" fxLayoutAlign="space-around center" class="pl-32" ngClass.xs="pl-0 lot-button-mobile">
<!--            <btn [loading]="isLotLoading" [buttonDisabled]="(event.lots === null || event.lots.length === 0) && !event.lot_invitation" style="width: 100%" buttonWidth="100%" (click)="showLotsList()">{{ getTitleLotButton() | uppercase }}</btn>-->
            <btn style="width: 100%" buttonWidth="100%" (click)="showLotsList()">{{ getTitleLotButton() | uppercase }}</btn>
          </div>
        </div>

        <mat-tab-group #tabGroup class="padding tabs-left" [disableRipple]="true" backgroundColor="" [selectedIndex]="0" (selectedTabChange)="gotoLeaderboard($event)">
          <mat-tab label="{{ 'EVENT.TABS.INFORMATION' | translate | uppercase}}">
            <div class="pb-40">
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between space-between" fxLayoutAlign.xs="start start">
                <div fxLayout="column" class="p-32 event-description" innerHtml="{{ event.description }}"></div>
              
                <!-- SIDEBAR -->
                <div id="sidebar" fxFlex="357px" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="start start" class="p-32 line-left"> 
                  <btn type="grayscale" style="width: 100%" buttonWidth="100%" *ngIf="event.doc_rule" (click)="seeRegulation()">{{ 'EVENT.SEE_REGULATION' | translate | uppercase }}</btn>
                  <btn type="grayscale" style="width: 100%" buttonWidth="100%" [ngClass]="{'mt-16': event.doc_rule }" *ngIf="event.doc_term" (click)="seeTerms()">{{ 'EVENT.SEE_TERM' | translate | uppercase }}</btn>

                  <h5 class="mt-40">{{ 'EVENT.DATE_TIME.TITLE' | translate | uppercase }}</h5>
                  <span><strong>{{ 'EVENT.DATE_TIME.START' | translate }}: </strong>{{ event.datetime_start | localizedDate:('EVENT.DATETIME_FORMAT' | translate) }}</span>
                  <span *ngIf="event.datetime_end"><strong>{{ 'EVENT.DATE_TIME.END' | translate }}: </strong>{{ event.datetime_end | localizedDate:('EVENT.DATETIME_FORMAT' | translate) }}</span>

                  <h5 *ngIf="event.location || event.address">{{ 'EVENT.LOCATION' | translate | uppercase }}</h5>
                  <strong *ngIf="event.location">{{ event.location }}</strong>
                  <span *ngIf="event.address">{{ event.address }}</span>

                  <h5>{{ 'EVENT.SHARE' | translate | uppercase }}</h5>
                  <div fxLayout="row" fxLayoutAlign="start center" class="share-buttons">
                    <a href="mailto:?&subject={{event.name}}&body={{eventUrl}}" fxLayoutAlign="center center">
                      <svg width="17px" height="13px" viewBox="0 0 17 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Registration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="Event---Home" transform="translate(-824.000000, -963.000000)" fill="#4C6070" fill-rule="nonzero">
                            <g id="Group-8" transform="translate(815.000000, 913.000000)">
                              <g id="Group-2" transform="translate(2.000000, 42.000000)">
                                <path d="M23.1428571,10.1142857 C23.1428571,9.26571429 22.4485714,8.57142857 21.6,8.57142857 L9.25714286,8.57142857 C8.40857143,8.57142857 7.71428571,9.26571429 7.71428571,10.1142857 L7.71428571,19.3714286 C7.71428571,20.22 8.40857143,20.9142857 9.25714286,20.9142857 L21.6,20.9142857 C22.4485714,20.9142857 23.1428571,20.22 23.1428571,19.3714286 L23.1428571,10.1142857 Z M21.6,10.1142857 L15.4285714,13.9714286 L9.25714286,10.1142857 L21.6,10.1142857 Z M21.6,19.3714286 L9.25714286,19.3714286 L9.25714286,11.6571429 L15.4285714,15.5142857 L21.6,11.6571429 L21.6,19.3714286 Z"
                                  id="Shape"></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </a>

                    <a href="https://www.facebook.com/sharer/sharer.php?u={{eventUrl}}" target="_blank" fxLayoutAlign="center center">
                      <svg width="10px" height="18px" viewBox="0 0 10 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Registration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="Event---Home" transform="translate(-873.000000, -961.000000)" fill="#4C6070">
                            <g id="Group-8" transform="translate(815.000000, 913.000000)">
                              <g id="Group-2-Copy" transform="translate(48.000000, 42.000000)">
                                <path d="M19.3592334,6.86070972 L17.0977478,6.85714286 C14.5570464,6.85714286 12.9151335,8.51305693 12.9151335,11.0760231 L12.9151335,13.0212094 L10.6413109,13.0212094 C10.4448256,13.0212094 10.2857143,13.1777945 10.2857143,13.3709399 L10.2857143,16.1892932 C10.2857143,16.3824386 10.4450071,16.5388453 10.6413109,16.5388453 L12.9151335,16.5388453 L12.9151335,23.6504479 C12.9151335,23.8435933 13.0742448,24 13.2707301,24 L16.2374218,24 C16.433907,24 16.5930184,23.8434149 16.5930184,23.6504479 L16.5930184,16.5388453 L19.2516473,16.5388453 C19.4481325,16.5388453 19.6072439,16.3824386 19.6072439,16.1892932 L19.6083324,13.3709399 C19.6083324,13.2782016 19.5707771,13.1893868 19.5041934,13.1237566 C19.4376098,13.0581264 19.3468964,13.0212094 19.2525544,13.0212094 L16.5930184,13.0212094 L16.5930184,11.3722507 C16.5930184,10.5796948 16.7851494,10.1773531 17.8354294,10.1773531 L19.3588705,10.1768181 C19.5551744,10.1768181 19.7142857,10.020233 19.7142857,9.82726596 L19.7142857,7.21026185 C19.7142857,7.01747315 19.5553558,6.8610664 19.3592334,6.86070972 Z"
                                  id="Path"></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </a>

                    <a href="https://twitter.com/home?status={{eventTextShare}}" target="_blank" fxLayoutAlign="center center">
                      <svg width="16px" height="13px" viewBox="0 0 16 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Registration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="Event---Home" transform="translate(-915.000000, -1084.000000)" fill="#4C6070">
                            <path d="M930.666667,1085.75388 C930.102553,1085.99479 929.496197,1086.15766 928.85994,1086.2309 C929.509307,1085.85592 930.008177,1085.26225 930.243083,1084.55482 C929.635193,1084.90204 928.961983,1085.15417 928.24538,1085.28994 C927.671607,1084.70122 926.854033,1084.33333 925.949213,1084.33333 C924.211947,1084.33333 922.80335,1085.68965 922.80335,1087.3627 C922.80335,1087.60006 922.83118,1087.83129 922.884847,1088.05308 C920.27036,1087.92668 917.952343,1086.72068 916.40084,1084.88787 C916.130053,1085.33527 915.97488,1085.85562 915.97488,1086.41082 C915.97488,1087.46185 916.530253,1088.38907 917.374353,1088.93231 C916.858693,1088.91659 916.373623,1088.7803 915.949503,1088.55342 C915.949197,1088.56604 915.949197,1088.57874 915.949197,1088.59151 C915.949197,1090.05932 917.03357,1091.28363 918.47268,1091.56204 C918.208717,1091.63122 917.9308,1091.66828 917.643913,1091.66828 C917.441207,1091.66828 917.244097,1091.64923 917.05197,1091.61394 C917.452323,1092.81736 918.614053,1093.69334 919.99068,1093.71771 C918.91405,1094.53027 917.557663,1095.01452 916.083747,1095.01452 C915.829827,1095.01452 915.579433,1095.0002 915.333333,1094.97214 C916.725523,1095.83174 918.37907,1096.33333 920.15559,1096.33333 C925.941853,1096.33333 929.106117,1091.71715 929.106117,1087.71391 C929.106117,1087.58264 929.102973,1087.45196 929.096917,1087.32202 C929.71163,1086.89492 930.244923,1086.36135 930.666667,1085.75388"
                              id="Fill-3"></path>
                          </g>
                        </g>
                      </svg>
                    </a>

                    <a href="https://api.whatsapp.com/send?text={{eventTextShare}}" target="_blank" fxLayoutAlign="center center">
                      <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Registration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="Event---Home" transform="translate(-962.000000, -962.000000)" fill="#4C6070">
                            <g id="Group-8" transform="translate(815.000000, 913.000000)">
                              <g id="Group-2-Copy-3" transform="translate(139.000000, 42.000000)">
                                <g id="whatsapp" transform="translate(8.571429, 7.714286)">
                                  <path d="M6.52189544,0.00793112435 C2.87045466,0.180386656 -0.0110452666,3.19675133 3.18296427e-05,6.82277813 C0.00340455745,7.92717575 0.272197738,8.96958383 0.746363589,9.89063675 L0.0183503709,13.3959316 C-0.0210311862,13.5855408 0.151374724,13.7515678 0.341272526,13.7069616 L3.80403894,12.8931932 C4.69367857,13.3327613 5.69293185,13.586656 6.75048041,13.6026617 C10.4826021,13.6592067 13.5945387,10.7197549 13.7108648,7.01917671 C13.8355565,3.05240428 10.5349786,-0.181645326 6.52189544,0.00793112435 Z M10.6537185,10.5676346 C9.63961857,11.5735377 8.29128797,12.1275077 6.85715119,12.1275077 C6.0174081,12.1275077 5.21344238,11.9406207 4.46750741,11.5720618 L3.98527346,11.3337793 L1.8622405,11.832713 L2.30912694,9.68088911 L2.07154802,9.21941148 C1.68431258,8.46717344 1.48796692,7.65373304 1.48796692,6.80172137 C1.48796692,5.37914362 2.04645096,4.04174408 3.06055086,3.03580814 C4.06559068,2.03889184 5.43591015,1.47590226 6.85725039,1.47590226 C8.29138716,1.47593506 9.63965164,2.029905 10.6537515,3.03577534 C11.6678514,4.04167848 12.2263355,5.37907802 12.2263685,6.80162297 C12.2263355,8.21150762 11.6587583,9.57075109 10.6537185,10.5676346 Z"
                                    id="Shape" fill-rule="nonzero"></path>
                                  <path d="M9.94146601,8.37609225 L8.66610687,7.98236302 C8.49843599,7.93061224 8.31787468,7.98174857 8.19570131,8.11559739 L7.88382268,8.45723447 C7.75231486,8.60129007 7.55276714,8.64757903 7.37871454,8.5718645 C6.77540417,8.30935559 5.50629975,7.09614797 5.18219743,6.4893052 C5.08869417,6.31425375 5.10415634,6.09540529 5.21896374,5.93568108 L5.49125033,5.55693773 C5.59792978,5.40854681 5.62044041,5.20925851 5.54989228,5.0377231 L5.01332006,3.73292752 C4.88479672,3.42040879 4.5133554,3.32943528 4.27069141,3.55009297 C3.914744,3.8737743 3.49240769,4.36564568 3.44106821,4.91056502 C3.35054943,5.87129983 3.73376994,7.082391 5.18292768,8.5366016 C6.8570965,10.2166245 8.19776505,10.4385793 9.07066327,10.2112309 C9.56577021,10.0822977 9.96143665,9.56540439 10.2111491,9.14218132 C10.3813917,8.85362634 10.2469629,8.47041112 9.94146601,8.37609225 Z"
                                    id="Path"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>

              <div fxLayout="column" fxLayoutAlign="start center" class="event-location" *ngIf="lat && lng">
                <agm-map fxFlex="100" class="gmaps" [latitude]="lat" [longitude]="lng" [zoom]="15" [streetViewControl]="false" [scrollwheel]="false">
                  <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                </agm-map>

                <h2>{{ event.name | uppercase }}</h2>
                <strong *ngIf="event.location">{{ event.location }}</strong>
                <span *ngIf="event.address">{{ event.address }}</span>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="{{ 'EVENT.TABS.HEATS' | translate | uppercase}}" *ngIf="event.championships.length > 0 && event.published_heats && event.enabled_leaderboard">
            <app-heats #heats></app-heats>
          </mat-tab>

          <mat-tab label="{{ 'EVENT.TABS.WODS' | translate | uppercase}}" *ngIf="event.championships.length > 0 && event.enabled_leaderboard">
            <app-workout #workouts></app-workout>
          </mat-tab>
          
          <mat-tab label="{{ 'EVENT.TABS.LEADERBOARD' | translate | uppercase}}" *ngIf="event.leaderboard_published && event.enabled_leaderboard">
            
          </mat-tab>
        </mat-tab-group>

      </div>

      
    </div>
  </div>
</div>