import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbsService } from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import { Title } from '@angular/platform-browser';
import { config } from '../../../../../core/config/config';
import { SubDivisionHttpService } from '../../../../../core/services/sub-division/sub-division-http.service';
import { SubDivision } from '../../../../../core/model/sub-division';
import { TableConfig, TableXComponent } from '../../../../../core/components/table-x/table-x.component';
import { MatDialog } from '@angular/material';
import { ModalCreateSubDivisionComponent } from './modal-create-sub-division/modal-create-sub-division.component';
import { ToastrService } from '../../../../../core/components/toastr_/public_api';
import { Errors } from '../../../../../core/model/errors';
import { CrossXDialogComponent } from '../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import { ChampionshipService } from '../../../../../core/services/championship/championship.service';
import { Championship } from '../../../../../core/model/championship';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormErrorStateMatcher } from '../../../../../core/utils/form-error-state-matcher';
import { ModalConfigurationSubDivisionComponent } from './modal-configuration-sub-division/modal-configuration-sub-division.component';
import { CompetitionService } from '../../../../../core/services/competition/competition.service';
import { Competition } from '../../../../../core/model/competition';
import { EventGuard } from '../../../../../core/guards/event.guard';

@Component({
  selector: 'fuse-app-sub-division',
  templateUrl: './sub-division.component.html',
  styleUrls: ['./sub-division.component.scss']
})

export class SubDivisionComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  
  @ViewChild('table', { read: TableXComponent }) tableList: TableXComponent;

  // table-x
  tabsFilter: string[] = [];
  tabIndexActive = 0;
  tableCatConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: [
      {
        type: 'reproved',
        title: 'Reprovar'
      }
    ],
    massActionCountTitle: {
      single: 'resultado selecionado',
      multiple: 'resultados selecionados'
    }
  };

  // translate
  translate: any;
  isLoadingSubDivision = true;
  isLoadingSubDivisionTable = true;
  isSubDivisionEmpty = true;
  isEditSubDivisionGroupName = false;
  championshipId: number;
  championship: Championship;
  subDivisions: SubDivision[];
  competitions: Competition[];

  // form
  championshipForm: FormGroup;
  matchers: any;
  requestErrors: string;
  isLoadingChampionshipForm = false;

  constructor(
    private titleService: Title,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private subDivisionHttpService: SubDivisionHttpService,
    private toastr: ToastrService,
    private championshipService: ChampionshipService,
    private competitionService: CompetitionService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private eventGuard: EventGuard,
  ) {
    this.registerTranslate();
    this.registerRoute();
    this.resgisterSubDivision();
    this.registerChampionship();
  }

  ngOnInit(): void {
    this.fetchSubDivision();
    this.fetchChampionship();
    this.championshipForm = this.formBuilder
      .group({
        sub_division_group_name: ['', [Validators.required]],
      });
    this.matchers = {
      sub_division_group_name: new FormErrorStateMatcher(),
    };
  }

  private resgisterSubDivision(): void {
    const subscription = this.subDivisionHttpService
      .subDivisionObservable()
      .subscribe((results: SubDivision[]) => {
        this.subDivisions = results;
        this.isLoadingSubDivision = false;
        if (this.subDivisions.length === 0) {
          this.isSubDivisionEmpty = true;
          return;
        }
        this.isSubDivisionEmpty = false;
        this.isLoadingSubDivisionTable = false;
      });

    this.subscriptions.add(subscription);
  }

  private fetchCompetition(): void {
    this.competitionService.getCompetitions(this.championshipId)
      .subscribe(
        (result: Competition[]) => {
          this.competitions = result;
        }); 
  }

  private registerChampionship(): void {
    const subscription = this.championshipService
      .championshipObservable()
      .subscribe((data: Championship) => {
        data.sub_division_group_name = data.sub_division_group_name ? data.sub_division_group_name : this.translate.TITLE;
        this.championship = data;
        if (this.championship.sub_division_group_name) {
          this.championshipForm.controls['sub_division_group_name'].setValue(this.championship.sub_division_group_name);
          return;
        }
        this.isEditSubDivisionGroupName = true;
      });

    this.subscriptions.add(subscription);
  }

  private registerTranslate(): void {
    this.translationLoader.loadTranslations(english, portugues);

    const subscription = this.translateService.get('SUB_DIVISION')
      .subscribe((res: any) => {
        this.translate = res;
      });

    this.subscriptions.add(subscription);
  }

  private registerRoute(): void {
    this.activatedRoute.params.subscribe(
      params => {
        this.championshipId = params.championship_id;

        this.fetchCompetition();

        if (this.translate) {
          this.titleService
            .setTitle(`${config.titleBase} - ${this.translate.TITLE}`);

          this.breadcrumbsService
            .replaceAll([{
              text: this.translate.TITLE,
              route: `/championship/leaderboard/${this.championshipId}`
            }]);
        }
      }
    );
  }

  private fetchSubDivision(): void {
    this.subDivisionHttpService
      .fetchSubDivision(this.championshipId);
  }

  private fetchChampionship(): void {
    this.championshipService
      .fetchChampionship(this.championshipId);
  }

  public reorderAction(event: any): void {
    if (event.data.length > 0) {
      const dataSubDivision = {
        sub_division: event.data
      };
      const subscription = this.subDivisionHttpService
      .updateOrderSubDivision(dataSubDivision, this.championshipId)
        .subscribe((data: SubDivision[]) => { },
        (err: any) => {
          const errors: Errors = new Errors().deserialize((err.error as any));
          this.isLoadingSubDivisionTable = true;
          this.subDivisions = [];
          this.fetchSubDivision();
          this.toastr
            .show(
              errors.getFullMessages(),
              this.translate.TOASTR.UPDATE_ORDER.ERROR.TITLE,
              null,
              'error',
            );
        });

      this.subscriptions.add(subscription);
    }
  }

  public createSubDivision(): void {
    const modalRef = this.dialog.open(ModalCreateSubDivisionComponent, {
      width: '440px',
      height: '100%',
      panelClass: 'modal'
    });
    modalRef.componentInstance.championshipId = this.championshipId;

    this.subscriptions.add(modalRef.afterClosed()
      .subscribe(result => {
        modalRef.close();
        if (result && result.isCreate) {
          if (this.subDivisions.length === 0) {
            this.isLoadingSubDivision = true;
            this.fetchSubDivision();
          }
          this.subDivisions.push(result.subDivision);
          this.tableList.reloadItems();
          this.toastr
            .show(
              null,
              this.translate.TOASTR.CREATE.SUCCESS.TITLE,
              null,
              'success',
            );
        }
      }));
  }

  public editSubDivision(event: SubDivision): void {
    const modalRef = this.dialog.open(ModalCreateSubDivisionComponent, {
      width: '440px',
      height: '100%',
      panelClass: 'modal'
    });
    modalRef.componentInstance.championshipId = this.championshipId;
    modalRef.componentInstance.subDivision = event;

    this.subscriptions.add(modalRef.afterClosed()
      .subscribe(result => {
        modalRef.close();
        if (result && result.isUpdate) {
          this.subDivisions.filter((subDivision, index) => {
            if (subDivision.id === event.id) {
              this.subDivisions[index] = result.subDivision;
              this.tableList.reloadItems();
            }
          });
          this.toastr
            .show(
              null,
              this.translate.TOASTR.UPDATE.SUCCESS.TITLE,
              null,
              'success',
            );
        }
      }));
  }

  public deleteSubDivision(event: SubDivision): void {
    if (!event.canBeDeleted) {
      this.toastr
      .show(
        this.translate.TOASTR.DELETE.ERROR.DESCRIPTION,
        this.translate.TOASTR.DELETE.ERROR.TITLE,
        null,
        'error',
      );
      return;
    }
    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });
    dialogRef.componentInstance.type = 'warning';
    dialogRef.componentInstance.titleDialog = 'Excluir sub-categoria';
    this.subscriptions.add(
      this.translateService.get('SUB_DIVISION.DIALOG.DESCRIPTION', { sub_division_name: event.name })
        .subscribe((res: string) => {
          dialogRef.componentInstance.dialogContent = res;
        })
    );
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.confirmActionRequest = true;
    dialogRef.componentInstance.cancelButton = this.translate.DIALOG.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.DIALOG.EXCLUIR.toUpperCase();
    this.subscriptions.add(dialogRef.componentInstance.dialogEvent
      .subscribe((result: any) => {
        dialogRef.componentInstance.confirmLoading = true;
        this.subscriptions.add(this.subDivisionHttpService
          .deleteSubDivision(event.id, this.championshipId)
          .subscribe(() => {
            if (this.subDivisions.length === 1) {
              this.fetchSubDivision();
              this.isLoadingSubDivision = true;
            }
            this.subDivisions.filter((subDivision, index) => {
              if (subDivision.id === event.id) {
                this.subDivisions.splice(index, 1);
                this.tableList.reloadItems();
              }
            });
            dialogRef.close();
            this.toastr
              .show(
                null,
                this.translate.TOASTR.DELETE.SUCCESS.TITLE,
                null,
                'success',
              );
          }, (err: any) => {
            const errors: Errors = new Errors().deserialize((err.error as any));
            this.isLoadingSubDivisionTable = false;
            dialogRef.componentInstance.confirmLoading = false;
            dialogRef.close();
            this.toastr
              .show(
                errors.getFullMessages(),
                this.translate.TOASTR.DELETE.ERROR.TITLE,
                null,
                'error',
              );
          }));
        }));
  }
  

  public configurationSubDivision(): void {
    const modalRef = this.dialog.open(ModalConfigurationSubDivisionComponent, {
      width: '816px',
      height: '100%',
      panelClass: 'modal'
    });
    modalRef.componentInstance.championshipId = this.championshipId;
    modalRef.componentInstance.competitions = this.competitions;
    this.subscriptions.add(modalRef.afterClosed()
      .subscribe(result => {
        modalRef.close();
        if (result && result.isSave) {
          this.competitions = result.competitions;
          this.eventGuard.getEventUpdates();
          this.toastr
            .show(
              null,
              this.translate.TOASTR.CONFIGURATION.SUCCESS.TITLE,
              null,
              'success',
            );
        }
      }));
  }

  public editGroupNameChampionship(): void {
    if (this.championshipForm.value.sub_division_group_name) {
      this.isLoadingChampionshipForm = true;
      const data = {
        sub_division_group_name: this.championshipForm.value
          .sub_division_group_name
          .toUpperCase()
      };
      const subscription = this.championshipService
        .updateChampionshipById(this.championshipId, data)
        .subscribe((result: any) => {
          this.championship = result;
          this.isLoadingChampionshipForm = false;
          this.isEditSubDivisionGroupName = false;
          this.championshipForm.controls['sub_division_group_name'].setValue(this.championship.sub_division_group_name);
          this.eventGuard.getEventUpdates();
        });
      this.subscriptions.add(subscription);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
