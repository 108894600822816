import {ChangeDetectorRef, Component, NgZone, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation, OnDestroy} from '@angular/core';
import {locale as english} from './i18n/en-US';
import {locale as portugues} from './i18n/pt-BR';
import {combineLatest, Subscription, interval} from 'rxjs';
import {FuseConfigService} from '../../../../../core/services/config.service';
import {EventPageService} from '../../../../../core/services/event-page/event-page.service';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialog, MatTabGroup} from '@angular/material';
import {FuseTranslationLoaderService} from '../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {WodService} from '../../../../../core/services/wod/wod.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Event} from '../../../../../core/model/event';
import * as moment from 'moment';
import {Moment} from 'moment';
import {Utils} from '../../../../../core/utils';
import {TableConfig, TableXComponent} from '../../../../../core/components/table-x/table-x.component';
import {KeyValue} from '../../championship/heats/model/key-value.model';
import {HeatQueryParams} from '../../championship/heats/enums/heat-query.params';
import {HeatCompetitor} from '../../championship/heats/model/heat-competitor.model';
import {Observable} from 'rxjs/Observable';
import {HeatsHttpService, PublicHeat} from '../../../../../core/services/heats/heats-http.service';
import {Heat} from '../../championship/heats/model/heat.model';
import { HeatCompetitorsHttpService } from '../../../../../core/services/heat-competitors/heat-competitors-http.service';

@Component({
  selector: 'app-heats',
  templateUrl: './heats.component.html',
  styleUrls: ['./heats.component.scss'],
})
export class HeatsComponent implements OnInit, OnDestroy {
  @ViewChildren('table') table: QueryList<TableXComponent>;
  @ViewChild('MatTabGroup') tabGroup: MatTabGroup;

  private translate: any;
  private readonly subscriptions = new Subscription();

  event: Event = null;
  heatsForm: FormGroup;
  heatsData: HeatsWithType[] = [];
  private heatsDataLocal: HeatsWithType[] = [];
  heatIdOpen: number = null;
  private championshipId: number = null;

  tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: false,
    massActions: [],
    massActionCountTitle: null
  };

  timeOut;
  isLoading = false;
  private isFirstLoad = false;

  constructor(
    private fuseConfig: FuseConfigService,
    private eventPageService: EventPageService,
    private eventGuard: EventGuard,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private wodService: WodService,
    private sanitizer: DomSanitizer,
    private heatsService: HeatsHttpService,
    private readonly heatCompetitorsHttpService: HeatCompetitorsHttpService,
    private change: ChangeDetectorRef,
    private zone: NgZone
  ) {
    this.translationLoader.loadTranslations(english, portugues);

    this.translateService.get('HEATS').subscribe((res: any) => {
      this.translate = res;
    });

    // Get Event
    this.event = this.eventPageService.event;
    if (this.event.championships.length > 0) {
      this.championshipId = this.event.championships[0].id;
    }
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // Create form
  createForm() {
    this.heatsForm = this.formBuilder.group({
      championship: this.event.championships.length > 0 ? this.event.championships[0].id : ''
    });


    this.getHeatsWithChampionship(this.event.championships.length > 0 ? this.event.championships[0].id : 0);

    const subscription = this.heatsForm.get('championship').valueChanges.subscribe(val => {
      this.championshipId = val;
      this.heatsData = [];
      this.heatsDataLocal = [];
      this.getTabTypes();
      this.getHeatsWithChampionship(val);
    });
    this.subscriptions.add(subscription);
  }
  
  private getTabTypes(): void {
    if (this.event.championships.length > 0) {
      // this.heatsTab = [];
      
      // Verify competitions has single and team
      // @ts-ignore
      const championship = this.event.championships.find((championship: any) => championship.id === this.championshipId);
      const hasTypeCompetitions = Utils.validateChampionshipHasTeamAndSingle(championship.competitions);
    }
  }

  getHeatsWithChampionship(championshipId: number) {
    if (championshipId) {
      // this.heatsTab = [];
      this.isLoading = true;
      const subscription = this.heatsService
        .fetchHeatsWithChampionshipAndType(championshipId)
        .subscribe(
          (result: Heat[]) => {
            this.isLoading = false;
            this.populateHeatsWithType(result);
          }, err => {
            this.isLoading = false;
          }
        );

      this.subscriptions.add(subscription);
    }
  }

  private populateHeatsWithType(array: Heat[]): void {
    const heatsArray = this.heatsDataLocal;
    // var heatsArray: HeatsWithType[] = [];
    if (array.length > 0) {
      array.forEach((heat, index, heats) => {
        heat.total_lane_busy = 0;
        const dateHeat = heatsArray.filter(function (item) {
          return moment(heat.due_date).isSame(item.date);
        });
        if (heat.published_competitor === 0) {
          heat.heat_competitors = [];
        }

        if (dateHeat && dateHeat.length > 0) {
          const heatModify = heat.heat_competitors;
          dateHeat[0].heats.push(heat);
        } else {
          heatsArray.push({
            date: heat.due_date,
            heats: [heat]
          });
        }
      });

      if (heatsArray.length > 0) {
        heatsArray.sort((a, b) => {
          if (a.date < b.date) {
            return -1;
          } else if (a.date > b.date) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      this.heatsData = heatsArray;
    }
  }

  private getHeatCompetitorByHeatId(): void {
    this.heatCompetitorsHttpService.getHeatCompetitorByHeatId(this.heatIdOpen)
      .subscribe((data) => {
        this.heatsData.forEach((heatData, index) => {
          const indexHeat = heatData.heats.findIndex((heat: Heat) => heat.id === this.heatIdOpen);
          if (indexHeat === -1) {
            return;
          }
          // Add lane empty
          const heatCompetitors: HeatCompetitor[] = [];
          for (let i = 1; i <= this.heatsData[index].heats[indexHeat].total_lanes; i++) {
            // @ts-ignore
            let heatCompetitor: HeatCompetitor | null = data.find(heatCompetitorFilter => heatCompetitorFilter.lane === i);
  
            if (!heatCompetitor) {
              heatCompetitor = new HeatCompetitor();
              heatCompetitor.lane = i;
              heatCompetitor.competitor = null;
              heatCompetitor.competitor_id = null;
            }

            heatCompetitors.push(heatCompetitor);
          }
          this.heatsData[index].heats[indexHeat].heat_competitors = heatCompetitors;
          this.heatsData[index].heats[indexHeat].total_lane_busy = this.heatsData[index].heats[indexHeat].heat_competitors.length;
        });
      });
  }

  setHeatId(heatId: number) {
    this.heatIdOpen = this.heatIdOpen === null || this.heatIdOpen !== heatId ? heatId : null;
    this.heatsData.forEach(heatData => {
      const result = heatData.heats.find((heat: Heat) => heat.id === this.heatIdOpen);
      if (result) {
        if (!result.heat_competitors) {
          this.getHeatCompetitorByHeatId();
        }
      }
    });
  }
  
  populateArrays(array: HeatsWithType[], arrayLocal: HeatsWithType[]): void
  {
    if (array.length === 0) {
      arrayLocal.forEach((value, index) => {
        this.timeOut = setTimeout(() => {
          array.push(value);
        }, 0.3);
      });
    }
  }
  
  public tabHeatChanged(): void
  {
    if (!this.isFirstLoad) {
      this.isFirstLoad = true;
    }
  }

  trackByRow(index, item) {
    return index;
  }

}

export class HeatsWithType {
  date: string;
  heats: Heat[];
}
