export const locale = {
  lang: 'en-US',
  data: {
    'MODEL_COUPONS': {
      'NEW_TITLE': 'NOVO CUPOM',
      'EDIT_TITLE': 'EDITAR CUPOM',
      'SECTION_TITLE': 'Informações do cupom',
      'SECTION_DESCRIPTION': 'Insira as informações do cupom.',
      'FIELDS': {
        'NAME': 'Nome',
        'PROMOCODE': 'Código do cupom',
        'TYPE': 'Tipo',
        'TYPES': {
          'VALUE': 'Valor',
          'PERCENTAGE': 'Porcentagem',
        },
        'VALUE': 'Valor',
        'DATE_START': 'Data de inicial',
        'TIME_START': 'Horário de início',
        'DATE_END': 'Data de término',
        'TIME_END': 'Horário de término',
        'LIMIT': 'Limite'
      },
      'VALIDATIONS': {
        'NAME_REQUIRED': 'O nome é obrigatório',
        'PROMOCODE_REQUIRED': 'O código é obrigatório',
        'PROMOCODE_PATTERN': 'O código do cupom possui caracteres inválidos',
        'TYPE_REQUIRED': 'O tipo é obrigatório',
        'VALUE_REQUIRED': 'O valor é obrigatório',
        'PERCENTAGE_MAX_VALUE': 'O valor máximo é 100%',
        'VALUE_MAX_VALUE': 'O valor máximo é R$ 9.999,99',
        'DATE_START_REQUIRED': 'Data de início é obrigatória',
        'DATE_END_REQUIRED': 'Data de término é obrigatória',
        'DATE_INVALID': 'Formato da data inválida'
      },
      'CANCEL': 'Cancelar',
      'SAVE': 'Salvar',
      'DATE_FORMAT': 'DD/MM/YYYY',
    }
  }
};
