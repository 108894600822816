export const locale = {
  lang: 'en-US',
  data: {
    'RESULT': {
      'TITLE': 'Resultados',
      'NOT_TIMECAP': 'Finalizei dentro do TimeCap',
      'TIMECAP': 'NÃO finalizei o workout',
      'RESULT': 'Resultado',
      'TIEBREAK': 'Tiebreak',
      'URL_VIDEO': 'Link do vídeo',
      'VALIDATIONS': {
        'REQUIRED_CHECKBOX': 'É obrigatório a escolha da finalização do workout',
        'VIDEO_REQUIRED': 'O link do vídeo é obrigatório',
        'RESULT_REQUIRED': 'O resultado obrigatório',
        'TIEBREAK_REQUIRED': 'O tiebreak é obrigatório'
      },
      'TABLE': {
        'NAME': 'Nome',
        'RESULT': 'Resultado',
        'TIEBREAK': 'Tiebreak',
        'VIDEO': 'Vídeo',
        'STATUS': 'Status'
      },
      'STATUS': {
        'APPROVED': 'Aprovado',
        'REPROVE': 'Reprovado',
        'WAITING': 'Aguardando'
      },
      'EMPTY': 'Não existe WOD cadastrados',
      'SAVE': 'Salvar',
      'CANCEL': 'Cancelar'
    }
  }
};
