export const locale = {
  lang: 'en-US',
  data: {
    'MODAL_COMPETITION': {
      'NEW_COMPETITION': 'Nova categoria',
      'EDIT_COMPETITION': 'Edit categoria',
      'INFO_TITLE': 'Informações da categoria',
      'INFO_DESCRIPTION': 'Crie suas categorias individuais ou equipe.',
      'CUT_LINE': 'Linha de corte',
      'LIMIT': 'Limite',
      'FIELDS': {
        'NAME': 'Nome',
        'LIMIT': 'Limite',
        'CUT_LINE': 'Linha de corte',
        'START_NUMBER': 'Número inicial',
        'TEAM': 'Equipe',
        'TEAM_SIZE': 'Nº de integrantes',
        'SUM_SINGLE': 'Somatória individual',
        'MALE': 'Masculino',
        'FEMALE': 'Feminino'
      },
      'VALIDATIONS': {
        'NAME_REQUIRED': 'O nome é obrigatório',
        'START_NUMBER_REQUIRED': 'O número inicial é obrigatório',
        'START_NUMBER_MIN': 'O número inicial deve ser superior a 1',
        'TEAMSIZE_REQUIRED': 'O número de mebros é obrigatório',
        'CUT_LINE_MIN': 'A linha de corte deve ser superior a 1',
        'LIMIT_MIN': 'O limite deve ser superior a 1',
      },
      'ADD_COMPETITION': 'Adicionar categoria',
      'INFO_SUM_SINGLE': 'Lorem ipsum dolor sit amet, vis suas mutat graece cu.',
      'SAVE': 'Salvar',
      'CANCEL': 'Cancelar',
    }
  }
};
