<div class="main-content">
  <div class="p-32" *ngIf="event !== null && event.championships !== null && event.championships.length > 1">
    <form class="" name="workoutForm" [formGroup]="workoutForm" novalidate>
      <mat-form-field fxFlex="30" fxFlex.xs="100">
        <mat-select placeholder="{{'WORKOUT.CHAMPIONSHIP' | translate | uppercase}}" formControlName="championship">
          <mat-option *ngFor="let championship of event.championships" [value]="championship.id">
            {{ championship.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>

  <div class="pb-32">
    <div fxLayoutAlign="center center" class="p-32" *ngIf="isLoading">
      <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
    </div>
    
    <mat-tab-group class="padding tabs-left tab-inner pt-24" [disableRipple]="true" backgroundColor="" *ngIf="!isLoading">
      <mat-tab label="{{ (competition ? competition.name : '') | uppercase}}" *ngFor="let competition of competitions">
        <div class="p-32">
          <div class="pt-16" fxLayout="column" *ngIf="(competition.wods === null || competition.wods.length === 0) && !isLoading">
            <div fxLayout="column" fxLayoutAlign="center center" class="p-32 emtpy-content">
              <img src="./assets/icons/placeholder/ic-empty.svg" />
              <span class="mt-8">{{ 'WORKOUT.WOD.EMPTY' | translate }}</span>
            </div>
          </div>
          <div class="box-line mb-4" *ngFor="let wod of competition.wods">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="p-16 pt-8 pb-8">
              <h3 class="m-0">{{ wod.name | uppercase }}</h3>
              <btn-transparent
                icon="common/ic-arrow-up-collapsible.svg"
                buttonWidth="35px" iconAlign="left" class="btn-collapsible"
                [ngClass]="{'rotate-180': wodIdOpen === null || wodIdOpen !== wod.id}"
                (click)="setWodId(wod.id)" >
              </btn-transparent>
            </div>
            <div fxLayout="column" [fxShow]="wodIdOpen === wod.id" class="p-16">
              <div fxLayout="row" fxLayoutAlign="center center">
                <div class="video-container mb-24" *ngIf="wod.video_url !== '' && wod.video_url !== null">
                  <iframe width="560" height="315" [src]="getVideoUrl(wod)" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                </div>
              </div>
              <!--<span><strong>{{ 'WORKOUT.RESULT_TYPE' | translate | uppercase }}:</strong> {{ getUnitPerId(wod.wod_type) }}</span>
              <span *ngIf="wod.tiebreak_type !== null"><strong>{{ 'WORKOUT.TIEBREAK_TYPE' | translate | uppercase }}:</strong> {{ getUnitPerId(wod.tiebreak_type) }}</span>
              <span *ngIf="wod.goal !== null"><strong>{{ 'WORKOUT.TIME_CAP' | translate | uppercase }}:</strong> {{ wod.goal }}</span>-->
              <div *ngIf="wod.description !== null && wod.description.length > 0" innerHtml="{{ wod.description }}"></div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>