export const locale = {
  lang: 'pt-BR',
  data: {
    'NEW_PARTICIPANTS': {
      'TITLE': 'Novo time',
      'CATEGORY': {
        'TITLE': 'Categoria'
      },
      'SUB_DIVISION': 'Sub-categorias',
      'TEAM': {
        'TITLE': 'Informações do time',
        'DESCRIPTION': 'Crie um time e adicione seus membros.',
        'TEAM_NAME': 'Nome do time',
        'EMAIL': 'E-mail',
        'FIRSTNAME': 'Nome',
        'LASTNAME': 'Sobrenome',
        'LEADERBOARD_NAME': 'Nome no leaderboard',
        'BOX': 'Box',
        'TSHIRT': 'Tamanho da camiseta'
      },
      'VALIDATIONS': {
        'SUB_DIVISION_REQUIRED': 'A sub-categoria é obrigatória',
        'TEAM_NAME_REQUIRED': 'O nome do time é obrigatório',
        'CATEGORY_REQUIRED': 'A categoria é obrigatória',
        'EMAIL_INVALID': 'Digite um e-mail válido',
        'FIRSTNAME_REQUIRED': 'O nome é obrigatório',
        'LASTNAME_REQUIRED': 'O Sobrenome é obrigatório',
        'LEADERBOARDNAME_REQUIRED': 'O nome do leaderboard é obrigatório',
      },
      'MEMBERS_NOCOUNT': 'Membros do time',
      'MEMBERS': 'Membros do time ({{ count }}/{{ total }})',
      'MEMBER_COUNT_ERROR': 'Você precisa adicionar todos os memberos do time',
      'MEMBER_INFO_REQUIRED': 'Preencha todas as informações do membro para adicionar um novo',
      'ADD_MEMBER': 'Adicionar membro',
      'CANCEL': 'Cancelar',
      'SAVE': 'Salvar'
    }
  }
};
