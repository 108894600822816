import { User } from './user';
import { Team } from './team';

export class Ranking {
  competitor_id: number;
  total: number;
  nrResults: number;
  competitor: {
    id: number
    disqualified: boolean
    championship_id?: number
    user_id?: number
    team_id?: number
    rank?: number
    user?: User
    team?: Team
    championship?: any
    results?: any[]
    fullname?: string
    fullnameSort?: string
    box_name?: string
    avatar?: string,
    tiebreak_order?: number | null;
  };
  sort?: any;
  
  deserialize(input: any) {
    (<any>Object).assign(this, input);
    // const user = (input.competitor.user !== null) ? new User().deserialize(input.competitor.user) : null
    // this.competitor.user = user

    const team = (input.competitor.team !== null) ? new Team().deserialize(input.competitor.team) : null;
    this.competitor.team = team;

    // this.competitor.fullname = (user) ? user.fullname : team.name
    // this.competitor.fullnameSort = (user) ? user.fullnameSort : team.fullnameSort
    // this.competitor.avatar = (user) ? user.avatar : null

    this.competitor.fullname = (team.type === 'single') ? team.members[0].user.fullname : team.name;
    this.competitor.fullnameSort = (team.type === 'single') ? team.members[0].user.fullnameSort : team.fullnameSort;
    this.competitor.avatar = (team.type === 'single') ? team.members[0].user.avatar : null;
    this.competitor.box_name = (team.type === 'single') ? (team.members[0].box ? team.members[0].box.name : '') : (team.box ? team.box.name : '');
    
    this.sort = {
      rank: input.competitor.rank,
      // name: (user) ? user.fullnameSort : team.fullnameSort
      name: (team.type === 'single') ? team.members[0].user.fullnameSort : team.fullnameSort
    };

    input.competitor.results.forEach(result => {
      this.sort[result.wod_id] = result.rank;
    });

    return this;
  }

}
