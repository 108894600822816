export const locale = {
  lang: 'en-US',
  data: {
    'CHANGE_PASSWORD': {
      'TITLE': 'Redefinir senha',
      'BACK': 'VOLTAR',
      'SEND': 'SALVAR',
      'PASSWORD': 'Nova senha',
      'CONFIRM_PASSWORD': 'Repetir nova senha',
      'PASSWORD_REQUIRED': 'Nova senha obrigatória',
      'PASSWORD_MINLENGHT': 'A senha deve conter no mínimo 6 caracters',
      'CONFIRM_PASSWORD_REQUIRED': 'Confirmação da senha obrigatória',
      'CONFIRM_PASSWORD_INVALID': 'As senhas não são iguais',
    }
  }
};
