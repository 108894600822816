import { BoxService } from './../../../../../core/services/box.service';
import { TeamMember } from './../../../../../core/model/team-member';
import { Team } from './../../../../../core/model/team';
import { TeamService } from './../../../../../core/services/team/team.service';

import { config } from './../../../../../core/config/config';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { locale as english } from './../i18n/en-US';
import { locale as portugues } from './../i18n/pt-BR';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { BreadcrumbsService } from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthGuard } from '../../../../../core/guards/auth.guard';
import { EventGuard } from '../../../../../core/guards/event.guard';
import { MatIconRegistry, MatDialog, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from '../../../../../core/components/toastr_/public_api';
import { CrossXDialogComponent } from '../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import { AlterMemberComponent } from './alter-member/alter-member.component';
import { Errors } from '../../../../../core/model/errors';
import { Utils } from '../../../../../core/utils';
import { FormErrorStateMatcher } from '../../../../../core/utils/form-error-state-matcher';
import {Subscription} from 'rxjs';
import {Event} from '../../../../../core/model/event';
import {Championship} from '../../../../../core/model/championship';
import * as moment from 'moment';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();
  private subscriptionRequest: Subscription = new Subscription();

  //Translate 
  translate: any;

  // Championship
  championshipId: number;
  championship: any;
  
  //Event
  public event: Event = null;
  public enabledEditProfile = false;
  public maxDateUpdate = '';

  // form
  profileForm: FormGroup;
  matchers: any;
  profileFormErrors: any;
  profileError = false;
  tshirtSize = Utils.getTShirts();

  // Box
  boxes = [];
  boxId: number;
  timeOutBoxSearch;

  // Team
  isEditTeamName = false;
  isLoadingTeamName = false;
  isProfileLoading = false;
  colsGrid: number;
  team: Team = null;
  meMember: TeamMember = null;
  meIsCaptain = false;

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private teamService: TeamService,
    private boxService: BoxService
  ) {

    // Icon Info
    iconRegistry.addSvgIcon(
      'ic-info',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/myprofile/ic-profile.svg')
    );

    
  }

  ngOnInit() {
    this.registerOnTranslatePayment();
    // this.registerOnRoute();
    this.getEvent();
    this.configForm();
    this.getTeamDetail();
  }

  public updateUserInfo(): void {
    // validate all form fields
    console.log(this.profileForm.controls);
    if (!this.profileForm.valid) {
      Utils.formValid(this.profileForm);
      return;
    } 

    const parameters = {
      leaderboard_name: this.profileForm.value.leaderboardName.toUpperCase(),
      size: (this.profileForm.value.tShirt) ? this.profileForm.value.tShirt : null,
      box_id: (this.profileForm.value.box && this.profileForm.value.box.id) ? this.profileForm.value.box.id : null,
      box: (!this.profileForm.value.box.id && this.profileForm.value.box) ? this.profileForm.value.box.toUpperCase() : null,
    };

    console.log(parameters);
    this.isProfileLoading = true;
    this.teamService.updateMember(parameters, this.meMember.id, false)
      .subscribe(
        result => {
          console.log('result', result);
          this.meMember = result;
          this.isProfileLoading = false;

          this.toastr.show(
            this.translate.TOASTER.PROFILE.SUCCESS.DESCRIPTION,
            this.translate.TOASTER.PROFILE.SUCCESS.TITLE,
            null,
            'success',
          );

        }, err => {
          console.log('err', err);
          this.isProfileLoading = false;
          const errors: Errors = new Errors().deserialize((err.error as any));

          this.toastr.show(
            errors.getFullMessages(),
            this.translate.TOASTER.PROFILE.ERROR.TITLE,
            null,
            'error',
          );
        }
      );
  }

  // Alter member in team
  alterMember(member: any) {

    if (this.team.user.id !== this.authGuard.getMe().user.id) {
      return;
    }

    const modalRef = this.dialog.open(AlterMemberComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'modal'
    });

    modalRef.componentInstance.member = member;
    modalRef.componentInstance.team = this.team;

    modalRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
      // console.log(result);
      if (result && result.action === 'update-team-member') {
        this.team = result.team;
      }
    });
  }

  // Edit team name
  saveTeamName() {
    
    const parameters = {
      name: this.team.name
    };

    this.isLoadingTeamName = true;
    this.teamService.update(parameters, this.team.id, false)
      .subscribe(
        result => {
          console.log('result', result);
          this.isEditTeamName = !this.isEditTeamName;
          this.isLoadingTeamName = false;

          this.toastr.show(
            this.translate.TOASTER.TEAM_NAME.SUCCESS.DESCRIPTION,
            this.translate.TOASTER.TEAM_NAME.SUCCESS.TITLE,
            null,
            'success',
          );

        }, err => {
          console.log('err', err);
          this.isLoadingTeamName = false;
          const errors: Errors = new Errors().deserialize((err.error as any));
          
          this.toastr.show(
            errors.getFullMessages(),
            this.translate.TOASTER.TEAM_NAME.ERROR.TITLE,
            null,
            'error',
          );
        }
      );
  }

  // Set Capitain
  setCapitain(member: any) {

    if (this.team.user.id !== this.authGuard.getMe().user.id || !this.enabledEditProfile) {
      return;
    }

    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      disableClose: true
    });

    dialogRef.componentInstance.type = 'warning';
    dialogRef.componentInstance.titleDialog = this.translate.DIALOG.ALTER_CAPITAIN_TITLE;
    dialogRef.componentInstance.dialogContent = this.translate.DIALOG.ALTER_CAPITAIN_DESCRIPTION;
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.SAVE.toUpperCase();
    dialogRef.componentInstance.confirmActionRequest = true;
    
    dialogRef.componentInstance.dialogEvent.subscribe(result => {
      dialogRef.componentInstance.confirmLoading = true;
      this.setCaptainAction(member, dialogRef);
    });
  }

  setCaptainAction(teamMember: any, dialogRef: MatDialogRef<CrossXDialogComponent>) {
    this.teamService.setTeamCaptain(this.team, teamMember.user.id, false)
      .subscribe(
        result => {
          console.log('result', result);
          
          this.team.members.forEach(member => {
            member.capitan = (member.user.id === teamMember.user.id);
          });
          this.team.user = teamMember.user;
          this.team.user_id = teamMember.user.id;
          this.team.user_name = teamMember.user.fullname;

          if (this.team) {
            this.meIsCaptain = this.team.user.id === this.authGuard.getMe().user.id;
          }

          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();


        }, err => {
          console.log('err', err);
          const errors: Errors = new Errors().deserialize((err.error as any));

          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();

          this.toastr.show(
            errors.getFullMessages(),
            this.translate.CATEGORY.ERROR_DELETE_TITLE,
            null,
            'error',
          );
        }
      );
  }

  private getEvent(): void {
    console.warn('caiu aqui');
    this.event = new Event().deserialize(this.eventGuard.getActiveEvent().event);
    const today = moment();
    const maxDate = moment(this.event.maxDateUserEdit);
    this.enabledEditProfile = !maxDate.isSameOrBefore(today);
    this.maxDateUpdate = maxDate.format(this.translate.DATETIME_FORMAT);

    const allChampionship = this.eventGuard.getAllChampionships();
    this.championship = allChampionship[0];
    this.championshipId = this.championship.id;

    if (this.translate) {
      this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE}`);

      this.breadcrumbsService.replaceAll([{
        text: this.translate.TITLE,
        route: `/user-dashboard/information`
      }]);
    }
  }
  
  private configForm(): void {
    this.profileFormErrors = {
      leaderboardName: {
        required: true
      },
      box: { },
      tShirt: {
        required: true
      },
    };
    
    this.profileForm = this.formBuilder.group({
      leaderboardName: [{
        value: '',
        disabled: !this.enabledEditProfile
      }, Validators.required],
      box: [{
        value: '',
        disabled: !this.enabledEditProfile
      }, Validators.required],
      tShirt: [{
        value: '',
        disabled: !this.enabledEditProfile
      }, Validators.required]
    });

    this.matchers = {
      leaderboardName: new FormErrorStateMatcher(),
      box: new FormErrorStateMatcher(),
      tShirt: new FormErrorStateMatcher()
    };

    this.colsGrid = (window.innerWidth <= 700) ? 1 : 4;
  }
  
  private getTeamDetail(): void {
    console.log('championship', this.championship);
    // if (this.championship.team.type === 'team') {
    this.teamService.show(this.championship.team.id)
      .subscribe(
        result => {
          console.log('result', result);
          this.team = result;
          this.meIsCaptain = this.team.user.id === this.authGuard.getMe().user.id;

          this.team.members.forEach(member => {
            if (member.user.id === this.authGuard.getMe().user.id) {
              this.meMember = member;
              this.profileForm.controls['leaderboardName'].setValue(member.leaderboard_name);
              this.profileForm.controls['tShirt'].setValue((member.size) ? member.size : this.profileForm.value.size);
              this.profileForm.controls['box'].setValue((member.box) ? member.box : this.profileForm.value.box);
            }
          });

          console.log(this.meMember);
        }, err => {
          console.log('err', err);
        }
      );
    // }
  }

  private registerOnTranslatePayment(): void {
    
    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService
      .get('INFORMATION')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);

  }

  private registerOnRoute(): void {

    const subscription = this.activatedRoute.params.subscribe(
      params => {
        // console.log(params)
        this.championshipId = params.championship_id;
        this.championship = this.eventGuard.getChampionshipPerId(this.championshipId);

        if (this.translate) {
          this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE}`);

          this.breadcrumbsService.replaceAll([{
            text: this.translate.TITLE,
            route: `/user-dashboard/championship/${this.championshipId}/information`
          }]);
        }
      }
    ); //end router

    this.subscriptions.add(subscription);
  }


  searchBox(name: string) {
    clearTimeout(this.timeOutBoxSearch);
    this.timeOutBoxSearch = setTimeout(() => {
      if (name.length > 0) {
        this.boxService.getListBoxes(name)
          .subscribe(
            result => {
              console.log('result', result);
              this.boxes = result;
            }, err => {
              this.boxes = [];
            }
          );
      } else {
        this.boxes = [];
      }
    }, 300);
  }

  displayFnBox(box: any): string {
    console.log(box);
    return box ? box.name.toUpperCase() : box;
  }

  returnFn(box: any): number | undefined {
    return box ? box.id : undefined;
  }

  // Resize 
  onResize(event) {
    this.colsGrid = (event.target.innerWidth <= 700) ? 1 : 4;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
