export class Coupon {
  id: number
  name: string
  promocode: string
  type: number
  type_description: string
  value: number
  free: boolean
  limit?: number
  start?: Date
  end?: Date
  subscribed: number
  status?: number

  deserialize(input: any) {
    (<any>Object).assign(this, input);
    return this;
  }
}

