<div fxFlex="100" class="pt-12 pb-12 pr-24 pl-24 total-count text-right" *ngIf="totalCount !== null">Total de
  <strong>{{ totalCount }}</strong> registro{{ totalCount > 1 ? 's' : '' }}.
</div>
<div class="main-table" fxFlex="100">
  <table>
    <thead *ngIf="isHeader">
    <tr>
      <th
        *ngIf="config.checkbox"
        [ngClass]="{'pl-32': config.paddingSide}"
        width="60"
        class="checkbox">
        <mat-checkbox [checked]="allSelected" (change)="allCheckboxAction($event)"></mat-checkbox>
      </th>
      <th
        *ngFor="let column of columns; let i = index"
        class="{{ (column.sortable) ? 'sortable' : '' }} {{column.align}} {{(column.classes) ? column.classes : '' }}"
        [ngClass]="{'pl-32': config.paddingSide && !config.checkbox && i === 0, 'pr-32': config.paddingSide && i === columns.length-1}"
        [ngStyle]="{'max-width': (column.maxWidth) ? column.maxWidth : '500px' }">

        <button
          *ngIf="column.sortable"
          (click)="sortClicked(column.property)"
          [ngClass]="{'sort-asc': sortActive === column.property && sortOrder === 'ASC', 'sort-desc': sortActive === column.property && sortOrder === 'DESC'}">
          <!-- [ngClass]="{'pl-12': sortActive === null, 'pr-12': sortActive === null}" -->
          {{column.header}}
          <mat-icon *ngIf="sortActive === column.property && sortOrder === 'ASC'" class="arrow-header">
            keyboard_arrow_down
          </mat-icon>
          <mat-icon *ngIf="sortActive === column.property && sortOrder === 'DESC'" class="arrow-header">
            keyboard_arrow_up
          </mat-icon>
        </button>
        <span *ngIf="!column.sortable">{{column.header}}</span>
      </th>
    </tr>
    </thead>
    <thead *ngIf="isHeaderLoading">
    <tr>
      <th [colSpan]="columns.length + 1" class="loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </th>
    </tr>
    </thead>
    <thead *ngIf="itemsSelected.length > 0 && showMassActions" class="mass-actions">
    <tr>
      <th [colSpan]="columns.length + 1">
        <div class="line">
          {{ itemsSelected.length }} {{ (itemsSelected.length < 2) ? config.massActionCountTitle.single : config.massActionCountTitle.multiple }}
          <button [matMenuTriggerFor]="massActionMenu">
            Ações em massa
            <mat-icon>
              keyboard_arrow_down
            </mat-icon>
          </button>
        </div>
      </th>
    </tr>
    </thead>

    <ng-container *ngIf="!generateDropzones">
      <tbody [dragula]="tableName" [attr.id]="externalId">

      <tr row *ngFor="let item of items; index as i; trackBy: trackByRow"
          [row]="item"
          [index]="i"
          [config]="config"
          [columns]="columns"
          [selected]="allSelected"
          (rowEvent)="rowEvent($event)"
          [attr.item-id]="item.id"
          [attr.item-index]="i"></tr>

      </tbody>
    </ng-container>

    <!-- placeholders -->
    <ng-container *ngIf="generateDropzones">
      <ng-container *ngFor="let dropzone of dropzones; index as i">
        <tbody
          [dragula]="tableName"
          [attr.item-index]="i"
          [attr.external-index]="externalIndex"
        >
        
        <ng-container *ngIf="!dropzone?.hasItem">

          <tr class="dropable-zone">
            <td></td>
            <td>
              {{i + 1}}
            </td>
            <td [attr.colspan]="columns.length">
              {{dropzonesText}}
            </td>
          </tr>
        </ng-container>

        <ng-container *ngIf="dropzone?.hasItem">
          <tr row
              [row]="dropzone?.item"
              [index]="i"
              [config]="config"
              [columns]="columns"
              [selected]="allSelected"
              (rowEvent)="rowEvent($event)"
              [attr.item-id]="dropzone?.item?.id">
          </tr>
        </ng-container>

        </tbody>

      </ng-container>
    </ng-container>
  </table>
</div>

<div fxLayout="row" fxLayoutAlign="center center" class="p-16 footer-loader" *ngIf="isLoading">
  <mat-spinner diameter="25" strokeWidth="2" class="loader"></mat-spinner>
</div>


<!-- MAT-MENU OPTIONS -->
<mat-menu class="submenu bottom arrow-right" #massActionMenu="matMenu" xPosition="before" [overlapTrigger]="false">
  <button mat-menu-item *ngFor="let massAction of config.massActions" (click)="massSelectedAction(massAction)">
    <div fxLayout="row" fxLayoutAlign="start center" class="{{ massAction.class }}">
      <span> {{ massAction.title }} </span>
    </div>
  </button>
</mat-menu>