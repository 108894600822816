import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EventSubscriptionsComponent} from '../event-subscriptions/event-subscriptions.component';
import {AuthGuard} from '../../../../../core/guards/auth.guard';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {EventSubscriptionFormComponent} from '../event-subscriptions/event-subscription-form/event-subscription-form.component';
import {SharedModule} from '../../../../../core/modules/shared.module';
import {RouterModule} from '@angular/router';
import {NgxMaskModule} from 'ngx-mask';
import {AgmCoreModule} from '@agm/core';
import {environment} from '../../../../../../environments/environment';
import {EventPermissionsComponent} from './event-permissions.component';

const routes = [
  {
    path: 'event/permissions',
    component: EventPermissionsComponent,
    canActivate: [AuthGuard, EventGuard]
  }
];

@NgModule({
  declarations: [
    EventPermissionsComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    })
  ],
  exports: [
    EventPermissionsComponent
  ]
})
export class EventPermissionsModule { }
