import { EventGuard } from './../../../../core/guards/event.guard';
import { AuthGuard } from './../../../../core/guards/auth.guard';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../../../core/modules/shared.module';

import { FuseSampleComponent } from './sample.component';

const routes = [
    {
        path     : 'components',
        component: FuseSampleComponent,
        canActivate: [AuthGuard, EventGuard]
    }
];

@NgModule({
    declarations: [
        FuseSampleComponent
    ],
    imports     : [
        SharedModule,
        RouterModule.forChild(routes)
    ],
    exports     : [
        FuseSampleComponent
    ]
})

export class FuseSampleModule
{
}
