import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {Subscription} from '../../model/subscription';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }


  userRefuse(invitationId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'invitations',
      invitationId,
      'refuse',
    ];
    const url = this.router.createUrlTree(baseUrl);
    const parsedUrl = url.toString().substring(1, url.toString().length);

    return this.http
      .post(parsedUrl,null, {responseType})
      .map((response: Response) => {
        return response
      });
  }
  
  userAccept(invitationId: number, parameters: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'invitations',
      invitationId,
      'accept',
      'subscription'
    ];
    const url = this.router.createUrlTree(baseUrl);
    const parsedUrl = url.toString().substring(1, url.toString().length);

    return this.http
      .post(parsedUrl, parameters, {responseType})
      .map((response: Response) => {
        return new Subscription().deserialize(response as any)
      });
  }
  
}
