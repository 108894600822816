import {User} from './user';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {FormErrorStateMatcher} from '../utils/form-error-state-matcher';
import {Utils} from '../utils';
import { trim } from 'lodash';
import {Country} from './country';
import {CustomField} from './custom-field.model';
import {CustomFieldTypes} from './custom-field-type.model';
import * as moment from 'moment';
import {SubscriptionCustomField} from './subscription-custom-field.model';
import {ConfigDefaultInput} from './default-custom-field.model';

export class TeamMember {
  id?: number = null;
  team_id?: number = null;
  user_id?: number = null;
  fullname = '';
  user?: User = null;
  country?: Country = null;
  status?: number = null;
  capitan = false;
  leaderboard_name?: string = null;
  size?: string;
  box?: any;
  customFields?: CustomField[] = [];
  checkin_file?: string;
  subscription_custom_fields?: SubscriptionCustomField[] = null;

  created_at?: any;
  updated_at?: any;

  // Settings to create or update
  isOpen = false;
  isLoading = false;
  isLeaderboardNameEdited = false;

  // FORMS
  competitorForm?: FormGroup;
  competitorFormErrors?: any;
  matchers?: any;
  public dateFormat?: string;
  public defaultInputConfig: ConfigDefaultInput = new ConfigDefaultInput();
  public genders: any[] = [];

  constructor() {
    this.user = new User();
    this.competitorForm = new FormGroup({
      // competition: new FormControl(this.user.email, [Validators.required]),
      firstname: new FormControl(this.user.firstname, [Validators.required]),
      lastname: new FormControl(this.user.lastname, [Validators.required]),
      email: new FormControl(this.user.email, [Validators.email]),
      leaderboardName: new FormControl({
        value: this.user.leaderboard_name,
        disabled: true
      }, [Validators.required]),
      cpf: new FormControl(this.user && this.user.cpf ? this.user.cpf : ''),
      phone: new FormControl(this.user && this.user.phone ? this.user.phone : '' ),
      birthdate: new FormControl(this.user && this.user.birthdate ? moment(this.user.birthdate).format(this.dateFormat) : ''),
      gender: new FormControl(''),
      box: new FormControl(''),
      tShirt: new FormControl(''),
      city: new FormControl('')
    });

    this.matchers = {
      // competition: new FormErrorStateMatcher(),
      email: new FormErrorStateMatcher(),
      firstname: new FormErrorStateMatcher(),
      lastname: new FormErrorStateMatcher(),
      leaderboardName: new FormErrorStateMatcher(),
      cpf: new FormErrorStateMatcher(),
      phone: new FormErrorStateMatcher(),
      birthdate: new FormErrorStateMatcher(),
      gender: new FormErrorStateMatcher(),
      city: new FormErrorStateMatcher(),
    };

    this.competitorForm.get('firstname').valueChanges.subscribe(val => {
      this.fullname = `${val} ${this.competitorForm.value.lastname}`;
      if (!this.isLeaderboardNameEdited) {
        this.competitorForm.controls['leaderboardName'].setValue(`${val} ${this.competitorForm.value.lastname}`.toUpperCase());
      }
    });

    this.competitorForm.get('lastname').valueChanges.subscribe(val => {
      this.fullname = `${this.competitorForm.value.firstname} ${val}`;
      if (!this.isLeaderboardNameEdited) {
        this.competitorForm.controls['leaderboardName'].setValue(`${this.competitorForm.value.firstname} ${val}`.toUpperCase());
      }
    });
  }

  deserialize(object: any) {
    (<any>Object).assign(this, object);
    if (object.user) {
      this.user = new User().deserialize(object.user);
      this.country = (object.country !== null) ? new Country().deserialize(object.country) : null;
    }
    this.setMember(this);

    return this;
  }

  setUser(user: User) {
    this.user = user;
    this.fullname = user.getName();
    this.competitorForm.controls['email'].setValue((user.email) ? user.email : this.competitorForm.value.email);
    this.competitorForm.controls['firstname'].setValue((user.firstname) ? user.firstname : this.competitorForm.value.firstname);
    this.competitorForm.controls['lastname'].setValue((user.lastname) ? user.lastname : this.competitorForm.value.lastname);
    this.competitorForm.controls['leaderboardName'].setValue((user.leaderboard_name) ? user.leaderboard_name : this.competitorForm.value.leaderboardName);
    this.competitorForm.controls['tShirt'].setValue((user.size) ? user.size : this.competitorForm.value.leaderboardName);
    this.competitorForm.controls['box'].setValue((user.box) ? user.box : this.competitorForm.value.box);
    const gender = null;
    if (this.user && this.competitorForm.controls['gender']) {
      this.genders.forEach((genderObj) => {
        if (genderObj.value == (this.user.gender + 1)) {
          this.competitorForm.controls['gender'].setValue(genderObj);
        }
      });
    }
  }

  setMember(member: TeamMember = null) {
    const m = member ? member : this;
    this.fullname = m.user.getName();
    if (m.user.email) {
      this.competitorForm.controls['email'].setValue(m.user.email);
    }

    if (m.box) {
      this.competitorForm.controls['box'].setValue(this.competitorForm.value.box);
    }

    this.competitorForm.controls['firstname'].setValue((m.user.firstname) ? m.user.firstname : this.competitorForm.value.firstname);
    this.competitorForm.controls['lastname'].setValue((m.user.lastname) ? m.user.lastname : this.competitorForm.value.lastname);
    this.competitorForm.controls['leaderboardName'].setValue((m.leaderboard_name) ? m.leaderboard_name : this.competitorForm.value.leaderboardName);
    this.competitorForm.controls['tShirt'].setValue((m.size) ? m.size : this.competitorForm.value.leaderboardName);

  }

  sendToApi(includeUserId: boolean = true): any {
    const parameters = {
      user_id: includeUserId ? this.user.id : null,
      email: this.competitorForm.value.email.toLowerCase(),
      firstname: this.competitorForm.value.firstname.toUpperCase(),
      lastname: this.competitorForm.value.lastname.toUpperCase(),
      leaderboard_name: `${trim(this.competitorForm.value.firstname)} ${trim(this.competitorForm.value.lastname)}`.toUpperCase(),
      cpf: this.competitorForm.value.cpf,
      phone: this.competitorForm.value.phone,
      gender: this.competitorForm.value.gender.value,
      birthdate: moment(this.competitorForm.value.birthdate, this.dateFormat).format('DD/MM/YYYY'),
      box_id: (this.competitorForm.value.box.id) ? this.competitorForm.value.box.id : null,
      box: (!this.competitorForm.value.box.id && this.competitorForm.value.box) ? this.competitorForm.value.box.toUpperCase() : null,
      size: (this.competitorForm.value.tShirt) ? this.competitorForm.value.tShirt : null,
      city: this.competitorForm.value.city ? this.competitorForm.value.city : null, 
      captain: this.capitan,
      additional: []
    };

    if (this.customFields.length > 0) {
      this.customFields.forEach(customField => {
        if (this.competitorForm.get(customField.formControlName).value &&
          this.competitorForm.get(customField.formControlName).value !== '' &&
          this.competitorForm.get(customField.formControlName).value !== null) {

          let value = '';
          if (customField.custom_field_type.type === CustomFieldTypes.SINGLE_CHECKBOX) {
            value = this.competitorForm.get(customField.formControlName).value;
          }
          else if (customField.custom_field_type.type === CustomFieldTypes.SELECT) {
            value = this.competitorForm.get(customField.formControlName).value && this.competitorForm.get(customField.formControlName).value.name ? this.competitorForm.get(customField.formControlName).value.name : this.competitorForm.get(customField.formControlName).value;
          }
          else if (customField.custom_field_type.type === CustomFieldTypes.DATE_PICKER) {
            value = moment(this.competitorForm.get(customField.formControlName).value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          }
          else {
            value = this.competitorForm.get(customField.formControlName).value;
          }
          parameters['additional'].push({
            event_custom_field_id: customField.id,
            custom_field_type_id: customField.custom_field_type.id,
            value: value
          });
        }
      });
    }
    
    return parameters;
  }

  // Form
  leaderboardNameFocus(event: any) {
    this.isLeaderboardNameEdited = true;
  }

  leaderboardNameBlur(event: any) {
    this.isLeaderboardNameEdited = (this.competitorForm.value.leaderboardName !== `${this.competitorForm.value.firstname} ${this.competitorForm.value.lastname}`);
  }
}
