import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { TeamMember } from '../../model/team-member';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TeamMembersHttpService implements OnDestroy {

  private subscriptions = new Subscription();

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  uploadFileCheckin(teamMemberId: number, params: any, queryParams = {}): Observable<TeamMember | HttpErrorResponse> {
    const formData: FormData = new FormData();
    Object.keys(params).forEach(element => {
      if (element === 'checkin_file') {
        if (params[element] === null) {
          return;
        }
        formData.append('checkin_file', params[element], params[element].name);
        return;
      }
      formData.append(element, params[element]);
    });
    const baseUrl = [
      'team-members-upload',
      teamMemberId,
    ];

    const url = this.router.createUrlTree(baseUrl, {
      queryParams
    });
    const parsedUrl = url.toString().substring(1, url.toString().length);
    return this.http
      .post(parsedUrl, formData)
      .map((result: TeamMember | HttpErrorResponse) => {
        return result;
      });
  }
}
