import { Injectable } from '@angular/core';

@Injectable()
export class FacebookUserService {
  facebookUser: any

  constructor() { }

  setUser(user: any)
  {
    this.facebookUser = user
  }

  getUser()
  {
    return this.facebookUser
  }

}
