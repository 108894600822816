<div id="login" fxLayout="column" fusePerfectScrollbar>

  <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <div id="login-form" *fuseIfOnDom> <!--[@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"-->

      <div class="logo">
        <img src="assets/images/logos/brand-crossx.svg">
      </div>

      <h1>{{'CHANGE_PASSWORD.TITLE' | translate}}</h1>

      <form name="changeForm" [formGroup]="changeForm" novalidate>

        <mat-form-field>
          <input matInput minlength="8" placeholder="{{'CHANGE_PASSWORD.PASSWORD' | translate}}" formControlName="password" [type]="hidePassword ? 'password' : 'text'"
            required>
          <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
          <mat-error *ngIf="changeFormErrors.password.required">
            {{'CHANGE_PASSWORD.PASSWORD_REQUIRED' | translate}}
          </mat-error>
          <mat-error *ngIf="changeFormErrors.password.minlength && changeFormErrors.password.minlength.actualLength < changeFormErrors.password.minlength.requiredLength">
            {{'CHANGE_PASSWORD.PASSWORD_MINLENGHT' | translate}}
          </mat-error>
        </mat-form-field>


        <mat-form-field>
          <input matInput minlength="8" placeholder="{{'CHANGE_PASSWORD.CONFIRM_PASSWORD' | translate}}" formControlName="confirmPassword" [type]="hideConfirmPassword ? 'password' : 'text'"
            required>
          <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
          <mat-error *ngIf="changeFormErrors.confirmPassword.required">
            {{'CHANGE_PASSWORD.CONFIRM_PASSWORD_REQUIRED' | translate}}
          </mat-error>
          <mat-error *ngIf="!changeFormErrors.confirmPassword.required && !changeFormErrors.confirmPassword.equals">
            {{'CHANGE_PASSWORD.CONFIRM_PASSWORD_INVALID' | translate}}
          </mat-error>
        </mat-form-field>

        <mat-error class="mb-8" *ngIf="changeError">
          {{ changeError }}
        </mat-error>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" class="mt-16">
          <btn class="submit-button mr-16" type="grayscale" (click)="gotoLogin()">{{'CHANGE_PASSWORD.BACK' | translate}}</btn>
          <btn [btnLoading]="true" (click)="changePassword()" class="submit-button m-0" [buttonDisabled]="changeForm.invalid" [loading]="loadingButton">{{'CHANGE_PASSWORD.SEND' | translate}}</btn>
        </div>

      </form>

    </div>

  </div>

</div>