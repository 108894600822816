import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeatsUrlService } from '../services/heats-url.service';
import { Subscription } from 'rxjs';
import { HeatsService } from '../services/heats.service';
import { HeatQueryParams } from '../enums/heat-query.params';
import {MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-nav-heats-agenda',
  templateUrl: './nav-heats-agenda.component.html',
  styleUrls: ['./nav-heats-agenda.component.scss'],
})

export class NavHeatsAgendaComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();
  
  private championshipId: string;

  public itemsRouteData = [
    'items'
  ];

  public scheduleRouteData = [
    'schedule'
  ];

  public url = '';
  
  private readonly DEFAULT_ROUTE = 'heats';

  constructor(
    private heatsService: HeatsService,
    private heatsUrlService: HeatsUrlService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.registerOnPathUrl();
    this.registerOnActivatedRoute();
    this.transformSVGtoIcon();
  }

  ngOnInit() {
  }
  
  private transformSVGtoIcon(): void {
    //Icon Info
    this.iconRegistry.addSvgIcon(
      'ic-lanes',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/heats/ic-lanes.svg')
    );
    this.iconRegistry.addSvgIcon(
      'ic-calendar',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/heats/ic-calendar.svg')
    );
  }

  private registerOnPathUrl(): void {
    const subscription = this.heatsUrlService
      .subscribe((url: { path: string }) => {
        const {path} = url;
        this.url = path.split('/').pop();
      });

    this.subscriptions.add(subscription);
  }

  get heatsClasses() {
    return {
      'active': this.isItemRouteData
    };
  }

  get isItemRouteData(): boolean {
    return this.url === this.itemsRouteData[0] || this.url === this.DEFAULT_ROUTE;
  }

  get isScheduleRouteData(): boolean {
    return this.url === this.scheduleRouteData[0];
  }

  get scheduleClasses() {
    return {
      'active': this.isScheduleRouteData
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get queryParams(): Object {
    const queryParams = this.heatsUrlService.getQueryParams();
    return queryParams || {type: 'single'};
  }

  private registerOnActivatedRoute(): void {
    const subscription = this.heatsUrlService
      .paramsObservable()
      .subscribe((params: { championship_id: string }) => {
        this.championshipId = params.championship_id;
      });

    this.subscriptions.add(subscription);
  }

}
