import { Component, OnInit, ViewChild, TemplateRef, ViewContainerRef, Input, OnChanges } from '@angular/core';
import { FilterSidebarService } from '../../services/filter-sidebar/filter-sidebar.service';
import { TemplatePortal } from '@angular/cdk/portal';
import { Observable } from 'rxjs';

@Component({
  selector: 'fuse-filter-sidebar',
  templateUrl: './filter-sidebar.component.html',
  styleUrls: ['./filter-sidebar.component.scss']
})
export class FilterSidebarComponent implements OnInit {
  @ViewChild('sidebarTempRef') sidebarTempRef: TemplateRef<any>;
  @Input() title = 'Filter222';

  constructor(
    public filterSidebarService: FilterSidebarService,
    private vcf: ViewContainerRef
  ) { }

  ngOnInit() {
    this.openRightPanel(this.sidebarTempRef);
  }

  openRightPanel(templateRef: TemplateRef<any>): void {
    const portal = new TemplatePortal(templateRef, this.vcf);
    this.filterSidebarService.setPanelPortal(portal);
  }

}
