<div id="register" fxLayout="column" fusePerfectScrollbar>

    <div id="register-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="register-form" *fuseIfOnDom [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"> 
           

            <div class="logo">
                <img src="assets/images/logos/fuse.svg">
            </div>

            <div class="picture">
              <img [src]="url">
              <input type="file" name="file" id="file" class="inputfile" (change)="readUrl($event)" />
              <label for="file" class="btn-take-photo"><img src="./assets/icons/content/take-photo.svg"></label>
              <!-- <button class="btn-take-photo" (click)="takePhoto()"><img src="./assets/icons/content/take-photo.svg"></button> -->
              <!-- <btn type="transparent" icon="" ></btn> -->
            </div>

            <h1 class="mb-4">{{'REGISTER.TITLE' | translate}}</h1>
            <form name="registerForm" [formGroup]="registerForm" autocomplete="off" novalidate>
                
                <div fxLayout="row" fxLayout.xs="column">
                  <mat-form-field fxFlex="50" fxFlex.xs="100">
                    <input matInput placeholder="{{'REGISTER.NAME' | translate}}" formControlName="firstname" [errorStateMatcher]="matchers.firstname" uppercase required>
                    <mat-error *ngIf="registerForm.controls.firstname.hasError('required')">
                      {{'REGISTER.NAME_REQUIRED' | translate}}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="50" fxFlex.xs="100">
                    <input matInput placeholder="{{'REGISTER.LAST_NAME' | translate}}" formControlName="lastname" [errorStateMatcher]="matchers.firstname" uppercase required>
                    <mat-error *ngIf="registerForm.controls.lastname.hasError('required')">
                      {{'REGISTER.LAST_NAME_REQUIRED' | translate}}
                    </mat-error>
                  </mat-form-field>
                </div>
                
                <div fxLayout="row" fxLayout.xs="column">
                  <mat-form-field fxFlex="50" fxFlex.xs="100" class="birthdayInput">
                    <!-- [(ngModel)]="birthdayModel" -->
                    <input matInput placeholder="{{'REGISTER.BIRTHDAY' | translate}}" formControlName="birthdate" [errorStateMatcher]="matchers.birthdate" [dropSpecialCharacters]="false"
                      mask="00/00/0000" required>
                    <mat-icon matSuffix>date_range</mat-icon>
                    <mat-error *ngIf="registerForm.controls.birthdate.hasError('required')">
                      {{'REGISTER.BIRTHDAY_REQUIRED' | translate}}
                    </mat-error>
                    <mat-error *ngIf="!registerForm.controls.birthdate.hasError('required') && registerForm.controls.birthdate.hasError('invalid')">
                      {{'REGISTER.BIRTHDAY_INVALID' | translate}}
                    </mat-error>
                  </mat-form-field>
                
                  <mat-form-field fxFlex="50" fxFlex.xs="100">
                    <input matInput placeholder="{{'REGISTER.EMAIL' | translate}}" formControlName="email" [errorStateMatcher]="matchers.email" required>
                    <mat-error *ngIf="registerForm.controls.email.hasError('required')">
                      {{'REGISTER.EMAIL_REQUIRED' | translate}}
                    </mat-error>
                    <mat-error *ngIf="registerForm.controls.email.hasError('email')">
                      {{'REGISTER.EMAIL_INVALID' | translate}}
                    </mat-error>
                    <mat-error *ngIf="registerFormErrors.email && !registerFormErrors.email.valid">
                      {{registerFormErrors.email.message}}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.xs="column">
                  <mat-form-field fxFlex="50" fxFlex.xs="100">
                    <input matInput minlength="6" placeholder="{{'REGISTER.PASSWORD' | translate}}" formControlName="password" [errorStateMatcher]="matchers.password" [type]="hidePassword ? 'password' : 'text'" required>
                    <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                    <mat-error *ngIf="registerForm.controls.password.hasError('invalid')">
                      {{'REGISTER.PASSWORD_REQUIRED' | translate}}
                    </mat-error>
                    <mat-error *ngIf="registerForm.controls.password.hasError('minlength')">
                      {{'REGISTER.PASSWORD_MINLENGHT' | translate}}
                    </mat-error>
                  </mat-form-field>
                
                  <mat-form-field fxFlex="50" fxFlex.xs="100">
                    <input matInput minlength="6" placeholder="{{'REGISTER.CONFIRM_PASSWORD' | translate}}" formControlName="confirmPassword" [errorStateMatcher]="matchers.confirmPassword" [type]="hideConfirmPassword ? 'password' : 'text'" required>
                    <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                    <mat-error *ngIf="registerForm.controls.confirmPassword.hasError('required')">
                      {{'REGISTER.CONFIRM_PASSWORD_REQUIRED' | translate}}
                    </mat-error>
                    <mat-error *ngIf="registerForm.controls.confirmPassword.hasError('passwordsNotMatch')">
                       {{'REGISTER.CONFIRM_PASSWORD_INVALID' | translate}}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.xs="column">
                  <mat-form-field fxFlex="50" fxFlex.xs="100">
                      <mat-select placeholder="{{'REGISTER.GENDER' | translate}}" formControlName="gender" [errorStateMatcher]="matchers.gender" required>
                          <mat-option *ngFor="let gender of genders" [value]="gender.value">
                              {{ gender.text }}
                          </mat-option>
                      </mat-select>
                      <mat-error *ngIf="registerForm.controls.gender.hasError('required')">
                      {{'REGISTER.GENDER_REQUIRED' | translate}}
                    </mat-error>
                  </mat-form-field>
                  
                  <mat-form-field fxFlex="50" fxFlex.xs="100">
                    <input matInput placeholder="{{'REGISTER.CITY' | translate}}" formControlName="city" [errorStateMatcher]="matchers.city" (ngModelChange)="searchCity($event)" [matAutocomplete]="autoCity" autocomplete="off" uppercase required>
                    <mat-autocomplete autoActiveFirstOption #autoCity="matAutocomplete">
                      <mat-option *ngFor="let city of cities" [value]="city.description | uppercase" (onSelectionChange)="citySelected(city)">
                        {{ city.description }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="registerForm.controls.city.hasError('required')">
                      {{'REGISTER.CITY_REQUIRED' | translate}}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.xs="column">
                  <mat-form-field fxFlex="47" fxFlex.xs="100">
                    <input matInput placeholder="{{'REGISTER.BOX' | translate}}" formControlName="box" [matAutocomplete]="autoBox" (ngModelChange)="searchBox($event)" uppercase>
                    <mat-autocomplete autoActiveFirstOption #autoBox="matAutocomplete" [displayWith]="displayFnBox">
                      <mat-option *ngFor="let box of boxes" [value]="box">
                        {{ box.name }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                
                <div fxLayout="row" fxLayout.xs="column" class="pl-12 mb-16">
                  <i fxFlex="100">{{ 'REGISTER.BOX_EMPTY' | translate }}</i>
                </div>

                <mat-checkbox formControlName="terms" required>
                  {{'REGISTER.ACCEPT' | translate}} <a href="http://appcrossx.com/termos" target="_blank">{{'REGISTER.TERMS' | translate}}</a>
                </mat-checkbox>

                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center">
                  <btn class="submit-button mr-16" type="grayscale" (click)="backToLogin()">{{'REGISTER.BACK' | translate}}</btn>
                  <btn [btnLoading]="true" (click)="register()" class="submit-button m-0" [buttonDisabled]="registerForm.invalid" [loading]="loadingButton">{{'REGISTER.REGISTER' | translate}}</btn>
                </div>

            </form>

          
        </div>

    </div>

</div>