<div class="btn-fab-multiple">
  <ng-container *ngFor="let matFab of listMatFab, let i = index">
    <div class="fab">
      <button 
        mat-fab
        [ngClass]="{
          'primary': matFab.color && matFab.color === 'primary',
          'secondary': matFab.color && matFab.color === 'secondary'
        }"
        (click)="matFab.listIcons ? openSubMenu(i) : clickMenu(i, matFab.type)"
      >
        <mat-icon *ngIf="!matFab.isLoading" [ngClass]="{'close': matFab.enableListIcons, 'open': enableClick === 1 && !matFab.enableListIcons && matFab.listIcons }">{{ !matFab.enableListIcons ? matFab.icon : 'close' }}</mat-icon>
        <div class="loader" *ngIf="!!matFab.isLoading">
          <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="4" stroke-miterlimit="10" [ngStyle]="{ 'stroke': matFab.color && matFab.color === 'primary' ? '#fff' : '#4c6070' }" />
          </svg>
        </div>
      </button>
      <div class="list-icons" [ngClass]="{'active': matFab.listIcons && matFab.enableListIcons && !matFab.isLoading}" [ngStyle]="{'right': getRight(i+1) }">
        <ng-container *ngFor="let icon of matFab.listIcons">
          <button 
            mat-mini-fab
            [ngClass]="{
              'primary': icon.color && icon.color === 'primary',
              'secondary': icon.color && icon.color === 'secondary',
              'normal': icon.textColor && icon.textColor === 'normal',
              'danger': icon.textColor && icon.textColor === 'danger'
            }"
            (click)="clickMenu(i, icon.type)"
          >
            <p *ngIf="icon.text">{{ icon.text }}</p>
            <mat-icon>{{ icon.icon }}</mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
