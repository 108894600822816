<!-- <div mat-dialog-content class="p-24 dialog-container">
  <h1 class="m-0 mb-4">{{titleDialog}}</h1>
  <span [innerHTML]="dialogContent"></span>
  <div class="mb-0" fxLayoutAlign="end center">
      <btn type="grayscale" class="mr-16" (click)="dialogRef.close(false)">{{cancelButton}}</btn>
      <btn class="mr-16" (click)="dialogRef.close({id: 25, type: type})" *ngIf="dialogConfirm == true">{{actionButton}}</btn>
  </div>
</div> -->

<header matDialogTitle [ngClass]="type" *ngIf="iconPath || headerDialog" [ngStyle]="{ 'background-color': backgroundHeaderColor, 'color': textHeaderColor }">
  <img src="{{iconPath}}" *ngIf="iconPath" />
  <br> {{headerDialog}}
</header>
<div mat-dialog-content class="p-24 dialog-container">
  <h1 class="m-0 mb-4">{{titleDialog}}</h1>
  <span [innerHTML]="dialogContent"></span>
</div>
<div mat-dialog-content class="p-24 dialog-container" *ngIf="withdraw">
  <form class="" name="withdrawPackageForm" [formGroup]="withdrawPackageForm" novalidate>
    <mat-form-field fxFlex="70" fxFlex.xs="100" ngClass="pr-8" ngClass.xs="pr-0">
      <input matInput placeholder="{{'USER_CHECKIN.DIALOG.WITHDRAW.NAME_WHO_WILL_GET_PACKAGE' | translate}}" formControlName="who_will_get_package" uppercase required>
<!--          <mat-error *ngIf="withdrawPackageForm.controls.who_will_get_package.hasError('required')">-->
<!--            {{'MYPROFILE.VALIDATIONS.FIRSTNAME_REQUIRED' | translate}}-->
<!--          </mat-error>-->
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="mb-0" fxLayoutAlign="end center">
  <btn type="grayscale" class="mr-16" (click)="dialogRef.close(null)">{{cancelButton}}</btn>
  <btn class="mr-16" [loading]="confirmLoading" (click)="confirmAction()" *ngIf="dialogConfirm == true">{{actionButton}}</btn>
</div>