import * as moment from 'moment';
import { AbstractControl, ValidatorFn, FormControl } from '@angular/forms';
import { ValidCreditCard } from './credit-card';

export function dateValidator(dateFormat: string = 'YYYY-MM-DD'): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control && control.value && !moment(control.value, dateFormat, true).isValid()) {
      return { 'invalid': true };
    }
    return null;
  };
}

export function maxDateValidator(maxDate: string, dateFormat: string = 'YYYY-MM-DD'): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const dateInput = moment(control.value, dateFormat, true);
    if (control && control.value && dateInput.isValid()) {
      const maxDateMoment = moment(maxDate, dateFormat, true);
      if (dateInput.isAfter(maxDateMoment)) {
        return { 'max_date_invalid': true };
      }
    }
    return null;
  };
}

export function requiredWithConditional(inputConditional: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if ((control.value == null || control.value === undefined || control.value === '') && inputConditional) {
      return { 'required': true };
    }
    return null;
  };
}

export function invalidInput(isInvalid: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if ((control.value !== null || control.value !== undefined || control.value !== '') && isInvalid) {
      return { 'invalid': true };
    }
    return null;
  };
}

export function CreditCardValidate(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {

    if (control.value !== null && control.value !== undefined && control.value !== '' && control.value > 12 && !ValidCreditCard(control.value)) {
      return { 'invalid': true };
    }
    
    return null;
  };
}

export function confirmPasswordEquals(control: AbstractControl) {
  if (!control.parent || !control) {
    return;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('confirmPassword');

  if (!password || !passwordConfirm) {
    return;
  }

  if (passwordConfirm.value === '') {
    return;
  }

  if (password.value !== passwordConfirm.value) {
    return {
      passwordsNotMatch: true
    };
  }
}

export function requiredFileType(type: string | string[]) {
  return function (control: FormControl) {
    const file = control.value;
    if (file) {
      if (file.split('.').length === 2) {
        const extension = file.split('.')[1];
        if (extension) {
          if (Array.isArray(type)) {
            let isValid = false;
            type.forEach((element: string) => {
              if (element.toLowerCase() === extension.toLowerCase()) {
                isValid = true;
              }
            });

            if (isValid) {
              return;
            }

            return {
              requiredFileType: true
            };
          }
          if (type.toLowerCase() === extension.toLowerCase()) {
            return;
          }
        }
      }
    }
    return {
      requiredFileType: true
    };
  };
}


