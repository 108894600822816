import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeatsManagerComponent} from './heats-manager.component';
import {AuthGuard} from '../../../../../core/guards/auth.guard';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {SharedModule} from '../../../../../core/modules/shared.module';
import {RouterModule} from '@angular/router';
import {NgxMaskModule} from 'ngx-mask';
import {NgxDnDModule} from '@swimlane/ngx-dnd';
import { GeneratorHeatsComponent } from './generator-heats/generator-heats.component';
import { ScheduleHeatsComponent } from './schedule-heats/schedule-heats.component';
import {HeatsModule} from '../heats/heats.module';
import { EditHeatNameNumberComponent } from './edit-heat-name-number/edit-heat-name-number.component';
import { DragulaModule } from 'ng2-dragula';

const routes = [
  {
    path: 'championship/heats/:championship_id',
    component: HeatsManagerComponent,
    canActivate: [AuthGuard, EventGuard]
  }
];

@NgModule({
  declarations: [
    HeatsManagerComponent,
    GeneratorHeatsComponent,
    ScheduleHeatsComponent,
    EditHeatNameNumberComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot(),
    NgxDnDModule,
    HeatsModule,
    DragulaModule.forRoot()
  ],
  exports: [
    HeatsManagerComponent
  ],
  entryComponents: [
    EditHeatNameNumberComponent 
  ]
})
export class HeatsManagerModule { }
