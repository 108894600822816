export const locale = {
  lang: 'en-US',
  data: {
    'DEFAULT': {
      'CANCEL': 'Cancel',
      'SAVE': 'Save',
      'SHARE': 'Share',
      //STATUS
      'ACTIVE': 'Active',
      'INACTIVE': 'Inactive',
      'PENDING': 'Pending',
      'BLOCKED': 'Blocked',
      'ABANDONED': 'Abandoned'
    }
  }
}