import { Team } from './team';
import { User } from './user';
import { Wod } from '../../main/content/pages/championship/heats/model/wod.model';

export class Result {
  id: number;
  user_id?: number;
  team_id?: number;
  championship_id: number;
  competition_id: number;
  rank: number;
  sub_division_rank?: number;
  points?: number;
  global_rank: number;
  tiebreak_order?: string;
  tiebreak?: string;
  total: number;
  number: number;
  global_total: number;
  disqualified: boolean;
  payment_status: number;
  status: number | null;
  result: {
    id: number
    competitor_id: number
    rank?: number
    global_rank?: number
    event_id?: number
    disqualified?: boolean
    published?: boolean
    video?: string
    status?: number
    has_cap?: boolean
    result?: string
    tiebreakresult?: string;
    description?: string;
    wod_id?: number
  };
  wod?: Wod;
  wod_id?: number;
  user?: User = null;
  team?: Team;
  name = '';
  avatar = '';
  fullnameSort?: string;
  sort?: any;

  created_at?: string;
  updated_at?: string;

  deserialize(input: any) {
    (<any>Object).assign(this, input);

    const team = new Team().deserialize(input.team);
    this.team = team;
    this.name = (team.type === 'single') ? team.members[0].user.fullname : team.name;
    this.avatar = (team.type === 'single') ? team.members[0].user.avatar : null;
    this.fullnameSort = (team.type === 'single') ? team.members[0].user.fullnameSort : team.fullnameSort;

    if (input.result && typeof input.result.published === 'boolean'){
      this.result.published = input.result.published;
    } else if (input.result && typeof input.result.published === 'number'){
      this.result.published = input.result.published !== 0;
    }

    this.sort = {
      name: this.name,
      number: this.number,
      result: (input.result && input.result.result !== null) ? input.result.result : 0,
      tiebreak: (input.result && input.result.tiebreakresult !== null) ? input.result.tiebreakresult : 0,
      status: (input.result) ? input.result.status : null,
    };

    return this;
  } 
}
export enum ResultEnum {
  ALL = 0,
  NO_RESULT = 1,
  PUBLISHED = 2,
  NOT_PUBLISHED = 3,
}
export enum ResultStatus {
  WAITING = 0,
  APPROVED = 1,
  REFUSED = 2
}
