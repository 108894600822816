import { ParticipantsComponent } from './participants.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '../../../../../core/guards/auth.guard';
import { EventGuard } from '../../../../../core/guards/event.guard';
import { SharedModule } from '../../../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';

const routes = [
  {
    path: 'championship/participants/:championship_id',
    component: ParticipantsComponent,
    canActivate: [AuthGuard, EventGuard]
  },
  {
    path: 'participants/:championship_id',
    component: ParticipantsComponent,
    canActivate: [AuthGuard, EventGuard]
  }
];

@NgModule({
  declarations: [
    ParticipantsComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot()
  ],
  exports: [
    ParticipantsComponent
  ]
})
export class ParticipantsModule { }
