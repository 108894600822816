// import { Observable } from 'rxjs/Rx';
import { Component, OnInit, EventEmitter, Output, Input, HostListener, ViewEncapsulation, ElementRef, NgZone } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Observable } from '../../../../../node_modules/rxjs';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';


@Component({
  selector: 'scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('appearInOut', [
      state('in', style({
        'display': 'inline-block',
        'opacity': '1'
      })),
      state('out', style({
        'display': 'none',
        'opacity': '0'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ],
})
export class ScrollToTopComponent implements OnInit {

  @Output() scrollEvent = new EventEmitter()
  @Input() isShowButton: boolean = true;

  animationState: string = 'out';
  private timerID: any = null;

  
  @Input() scrollInfinite: boolean = false;
  @Input() scrollRetriveDistance: number = 200;

  /**
   * Go top button will appear when user scrolls Y to this position
   */
  @Input() scrollDistance: number = 200;

  /**
   * User styles config object
   */
  @Input() styles: any = {};

  /**
   * Classes to be applied to the button
   */
  @Input() classNames: string = "go-top-button";

  /**
   * If true scrolling to top will be animated
   */
  @Input() animate: boolean = false;

  /**
   * Animated scrolling speed
   */
  @Input() speed: number = 80;

  /**
   * Acceleration coefficient, added to speed when using animated scroll
   */
  @Input() acceleration: number = 0;

  @Input() scrollId: string = ''
  private element: HTMLElement


  constructor(
    private el: ElementRef,
    private zone: NgZone
  ) { }

  ngOnInit() {
    // if (this.scrollId) {
    //   this.element = document.getElementById(this.scrollId)

    //   this.element.addEventListener("scroll", this.scroll, {
    //     capture: true,
    //     passive: true
    //   })
    // }

    if (this.scrollId) {
      this.element = document.getElementById(this.scrollId)
      
      this.zone.runOutsideAngular(() => {
        Observable.fromEvent(this.element, 'scroll')
          .debounceTime(1)
          .subscribe(res => {
            // console.log('scroll', res);
            this.onScroll(res)
            if (this.scrollInfinite) this.onScrollInfinite(res)
          });
      });
    }

    this.validateInputs();
  }

  onScrollInfinite(e) {
    // console.log(this.element.offsetHeight)
    // console.log(e)

    let el = e.target;
    if (el.scrollTop >= this.element.offsetHeight - this.scrollRetriveDistance) {
      this.scrollEvent.emit({
        type: 'retriveWithScroll'
      })  
    }
    
  }

  onScroll(e) {
    let el = e.target;
    if (this.isBrowser() && this.isShowButton) {
      const ani = el.scrollTop > this.scrollDistance ? 'in' : 'out';

      this.zone.run(() => {
        this.animationState = ani
      })
    }
  }

  ngOnDestroy(): void {
    
  }

  private validateInputs() {
    const errorMessagePrefix = 'GoTopButton component input validation error: ';

    if (this.scrollDistance < 0) {
      throw Error(errorMessagePrefix + "'scrollDistance' parameter must be greater or equal to 0");
    }

    if (this.speed < 1) {
      throw Error(errorMessagePrefix + "'speed' parameter must be a positive number");
    }

    if (this.acceleration < 0) {
      throw Error(errorMessagePrefix + "'acceleration' parameter must be greater or equal to 0");
    }

    if (typeof this.classNames !== "string") {
      throw Error(errorMessagePrefix + "'classNames' parameter must be a string like 'class1 class2 class3'");
    }
  };

  /**
   * Listens to window scroll and animates the button
   */
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.isBrowser() && this.isShowButton) {
      this.animationState = this.getCurrentScrollTop() > this.scrollDistance ? 'in' : 'out';
    }
  };

  /**
   * Scrolls window to top
   * @param event
   */
  scrollTop(event: any) {
    if (!this.isBrowser()) {
      return;
    }

    event.preventDefault();
    if (this.animate && this.element === null) {
      this.animateScrollTop();
    } else {
      if (this.element) {
        this.element.scrollTo(0, 0)
      } else {
        window.scrollTo(0, 0);
      }
    }
  };

  /**
   * Performs the animated scroll to top
   */
  animateScrollTop() {
    if (this.timerID !== null) {
      return;
    }

    let initialSpeed = this.speed;
    const that = this;

    this.timerID = setInterval(function () {
      window.scrollBy(0, -initialSpeed);
      initialSpeed = initialSpeed + that.acceleration;
      if (that.getCurrentScrollTop() === 0) {
        clearInterval(that.timerID);
        that.timerID = null;
      }
    }, 15);
  };

  /**
   * Get current Y scroll position
   */
  getCurrentScrollTop() {

    if (this.element && typeof this.element.scrollTop !== 'undefined' && this.element.scrollTop >= 0) {
      return this.element.scrollTop;
    }

    if (typeof window.scrollY !== 'undefined' && window.scrollY >= 0) {
      return window.scrollY;
    }

    if (typeof window.pageYOffset !== 'undefined' && window.pageYOffset >= 0) {
      return window.pageYOffset;
    }

    if (typeof document.body.scrollTop !== 'undefined' && document.body.scrollTop >= 0) {
      return document.body.scrollTop;
    }

    return 0;
  };

  /**
   * Get button style
   */
  getStyle() {
    return this.styles || {};
  };

  /**
   * This check will prevent 'window' logic to be executed
   * while executing the server rendering
   */
  isBrowser(): boolean {
    return typeof (window) !== 'undefined';
  };

  gotoTop() {
    this.scrollEvent.emit({
      type: 'scrollToTop'
    })
  }

}
