export const locale = {
  lang: 'en-US',
  data: {
    'MODAL_COPY_WODS': {
      'TITLE': 'Copiar WOD',
      'TITLE_SECTION': 'Informações do WOD',
      'DESCRIPTION_SECTION': 'Selecione os WOD\'s que deseja copiar e para quais categorias eles serão copiados.',
      'COMPETITIONS': 'Categorias',
      'WODS': 'WOD\'s',
      'SAVE': 'Salvar',
      'CANCEL': 'Cancelar',
    }
  }
};
