import {CustomField} from './custom-field.model';
import {CustomFieldType} from './custom-field-type.model';

export class SubscriptionCustomField {
  id: number;
  team_id?: number;
  team_member_id?: number;
  event_custom_field_id: number;
  custom_field_type_id: number;
  value?: string | boolean | number | any | null;
  event_custom_field?: CustomField;
  custom_field_type?: CustomFieldType

  constructor(object: any) {
    (<any>Object).assign(this, object);
  }
}