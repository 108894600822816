import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {MatDialogRef, MatIconRegistry} from '@angular/material';
import {FuseTranslationLoaderService} from '../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer} from '@angular/platform-browser';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CompetitionService} from '../../../../../core/services/competition/competition.service';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import {FormErrorStateMatcher} from '../../../../../core/utils/form-error-state-matcher';
import {SelectTimeComponent} from '../../../../../core/components/select-time/select-time.component';
import {dateValidator} from '../../../../../core/utils/custom-validators';
import {Utils} from '../../../../../core/utils';
import { Event } from '../../../../../core/model/event';
import * as moment from 'moment';
import {CouponsService} from '../../../../../core/services/coupons/coupons.service';
import {Errors} from '../../../../../core/model/errors';
import {Coupon} from '../../../../../core/model/coupon';
import {BreadcrumbsService} from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-coupon',
  templateUrl: './modal-coupon.component.html',
  styleUrls: ['./modal-coupon.component.scss']
})
export class ModalCouponComponent implements OnInit, OnDestroy {
  @ViewChild('pickerTimeStart', { read: SelectTimeComponent }) pickerTimeStart: SelectTimeComponent;
  @ViewChild('pickerTimeEnd', { read: SelectTimeComponent }) pickerTimeEnd: SelectTimeComponent;
  
  private subscriptions = new Subscription();
  public isEdit = false;
  public event: Event = null;
  public coupon: Coupon = null;
  
  // Translate
  translate: any;

  promocodeForm: FormGroup;
  matchers: any;
  types: any[];
  timeStart = '10:00';
  timeEnd = '10:00';

  isLoading = false;
  requestErrors: string;
  
  constructor(
    public dialogRef: MatDialogRef<ModalCouponComponent>,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private formBuilder: FormBuilder,
    private couponService: CouponsService
  ) {
    this.translationLoader.loadTranslations(english, portugues);

    this.subscriptions.add(this.translateService.get('MODEL_COUPONS').subscribe((res: any) => {
      this.translate = res;
    }));
    
    // Types select
    this.types = [
      this.translate.FIELDS.TYPES.PERCENTAGE,
      this.translate.FIELDS.TYPES.VALUE
    ];

    this.breadcrumbsService.replaceAll([{
      text: this.translate.TITLE,
      route: `/coupons`
    }]);

    // Icon Info
    iconRegistry.addSvgIcon(
      'ic-cat-info',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/category/ic-cat-info.svg')
    );
  }

  ngOnInit() {
    this.promocodeForm = this.formBuilder.group({
      name: [this.coupon ? this.coupon.name : '', Validators.required],
      code: [this.coupon ? this.coupon.promocode : '', [Validators.required, Validators.pattern('^[0-9A-Za-zàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')]],
      type: [this.coupon ? this.types[this.coupon.type] : this.types[0], Validators.required],
      value: [this.coupon ? this.coupon.value : '', Validators.required],
      dateStart: [
        this.coupon ? 
          moment(this.coupon.start).format(this.translate.DATE_FORMAT) : 
          moment().format(this.translate.DATE_FORMAT), 
        [dateValidator(this.translate.DATE_FORMAT), Validators.required]],
      dateEnd: [
        this.coupon ? 
          moment(this.coupon.end).format(this.translate.DATE_FORMAT) :
          moment(this.event.datetime_end).format(this.translate.DATE_FORMAT),
        [dateValidator(this.translate.DATE_FORMAT), Validators.required]],
      limit: [this.coupon ? this.coupon.limit : '']
    });

    this.timeStart = (this.coupon && this.coupon.start) ? moment(this.coupon.start).format('HH:mm') : '10:00';
    this.timeEnd = (this.coupon && this.coupon.end) ? moment(this.coupon.end).format('HH:mm') : '10:00';
    this.pickerTimeEnd.time = this.timeEnd;
    this.pickerTimeStart.time = this.timeStart;

    this.matchers = {
      name: new FormErrorStateMatcher(),
      code: new FormErrorStateMatcher(),
      type: new FormErrorStateMatcher(),
      value: new FormErrorStateMatcher(),
      dateStart: new FormErrorStateMatcher(),
      dateEnd: new FormErrorStateMatcher(),
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  saveCoupon() {
    this.requestErrors = null;

    // validate all form fields
    if (!this.promocodeForm.valid) {
      Utils.formValid(this.promocodeForm);
      return;
    }
    if (this.promocodeForm.value.value.length > 0 && Number(this.promocodeForm.value.value) === 0) {
      this.requestErrors = this.translate.VALIDATIONS.LIMIT;
      return;
    } 

    const parameters = {
      event_id: this.event.id,
      name: this.promocodeForm.value.name.toUpperCase(),
      promocode: this.promocodeForm.value.code.toUpperCase(),
      type: this.types.indexOf(this.promocodeForm.value.type),
      value: this.promocodeForm.value.value,
      start: null,
      end: null,
      limit: null
    };
    
    if (this.promocodeForm.value.limit !== null && this.promocodeForm.value.limit !== '') {
      parameters['limit'] = this.promocodeForm.value.limit;
    }

    const dateTimeStart = moment(`${this.promocodeForm.value.dateStart} ${this.pickerTimeStart.time}:00`, `${this.translate.DATE_FORMAT} HH:mm:ss`);
    if (dateTimeStart.isValid()) {
      parameters['start'] = dateTimeStart.format(`YYYY-MM-DD HH:mm:ss`);
    }

    
    const dateTimeEnd = moment(`${this.promocodeForm.value.dateEnd} ${this.pickerTimeEnd.time}:00`, `${this.translate.DATE_FORMAT} HH:mm:ss`);
    if (dateTimeEnd.isValid()) {
      parameters['end'] = dateTimeEnd.format(`YYYY-MM-DD HH:mm:ss`);
    }

    if (dateTimeEnd.isBefore()) {
      this.requestErrors = this.translate.VALIDATIONS.DATE_END_LESS;
      return;
    }
    
    this.isLoading = true;
    if (!this.isEdit) {
      this.subscriptions.add(this.couponService.create(parameters)
        .subscribe(
          result => {
            console.log('result', result);
            this.isLoading = false;
            this.dialogRef.close({ coupon: result, isEdit: this.isEdit });
          }, err => {
            console.log('err', err);
            this.isLoading = false;
            
            const errors: Errors = new Errors().deserialize((err.error as any));
            this.requestErrors = errors.getFullMessages();
          }
        ));
    } 
    else {
      this.subscriptions.add(this.couponService.update(parameters, this.coupon.id)
        .subscribe(
          result => {
            console.log('result', result);
            this.isLoading = false;
            this.dialogRef.close({ coupon: result, isEdit: this.isEdit });
          }, err => {
            console.log('err', err);
            this.isLoading = false;

            const errors: Errors = new Errors().deserialize((err.error as any));
            this.requestErrors = errors.getFullMessages();
          }
        ));
    }
  }

}
