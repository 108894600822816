import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFieldComponent } from './custom-field.component';
import {MaterialModule} from '../../modules/material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxMaskModule} from 'ngx-mask';
import {TranslateModule} from '@ngx-translate/core';
import {InputUppercaseDirective} from '../../directives/input-uppercase/input-uppercase.directive';
import {SharedModule} from '../../modules/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    NgxMaskModule.forRoot(),
    TranslateModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [
    CustomFieldComponent,
  ],
  exports: [
    CustomFieldComponent,
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,
  ],
  providers: [
    InputUppercaseDirective,
  ]
})
export class CustomFieldModule { }
