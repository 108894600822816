export class Country {
  id?: number = null
  name: string = null
  flag_url: string = null

  deserialize(object: any) {
    (<any>Object).assign(this, object);

    return this;
  }
}
