<div mat-dialog-content class="dialog-container" id="scrollBar">
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>{{ translate.TITLE | uppercase }}</h1>
      <span class="close" (click)="dialogRef.close(null)">
        <img src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>
    
    <div fxLayout="column">
      <form fxLayout="column" [formGroup]="searchForm" name="searchForm" autocomplete="off" novalidate>
        <mat-form-field floatLabel="never" class="search pr-8">
          <mat-icon matPrefix class="icon-search">search</mat-icon>
          <input matInput placeholder="{{ translate.SEARCH }}" formControlName="term">
        </mat-form-field>
      </form>

      <table-x #tableOptions [items]="boxes" [config]="tableConfig" [isLoading]="isLoading" tableName="custom-fields-options" [isDraggable]="false" [isHeader]="false">
        <column property="order" header="" [sortable]="false" align="left" classes="drag-handle">
          <ng-template #dataTableCell let-item="item">
            {{ item.name | uppercase }}
          </ng-template>
        </column>
        
      </table-x>
    </div>
    
  </div>
</div>

<scroll-to-top
  scrollId="scrollBar"
  [isShowButton]="false"
  [animate]="true"
  [speed]="50"
  [acceleration]="2"
  [scrollDistance]="100"
  (scrollEvent)="scrollEvent($event)"
  [scrollInfinite]="true"
  [scrollRetriveDistance]="100"></scroll-to-top>