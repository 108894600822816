import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {locale as portugues} from '../../heats-manager/i18n/pt-BR';
import {locale as english} from '../../heats-manager/i18n/en-US';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {FuseTranslationLoaderService} from '../../../../../../core/services/translation-loader.service';
import {EventGuard} from '../../../../../../core/guards/event.guard';
import {ActivatedRoute, Router} from '@angular/router';
import {MatCheckbox, MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from '../../../../../../core/components/toastr_/toastr/toastr.service';
import {Heat} from '../../../../../../core/model/heat.model';
import {TableConfig, TableXComponent} from '../../../../../../core/components/table-x/table-x.component';
import * as moment from 'moment';
import {Errors} from '../../../../../../core/model/errors';
import {HeatsService} from '../../../../../../core/services/heats/heats.service';
import {DragulaService} from 'ng2-dragula';
import {BtnMatFabConfig} from '../../../../../../core/components/btn-mat-fab/btn-mat-fab.component';
import {CalcDataScheduleInterface} from '../heats-manager.component';

@Component({
  selector: 'fuse-app-schedule-heats',
  templateUrl: './schedule-heats.component.html',
  styleUrls: ['./schedule-heats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleHeatsComponent implements OnInit, OnDestroy {

  @ViewChild('tableSchedule', {read: TableXComponent}) tableSchedule: TableXComponent;
  // translate
  translate: any;
  @Output() heatsOldEvent = new EventEmitter<Heat[] | ''>();
  @Output() calcHeatsEvent = new EventEmitter<CalcDataScheduleInterface>();
  @Output() addNewHeatEvent = new EventEmitter<void>();
  @Output() saveHeatEvent = new EventEmitter<void>();
  @Input() heats: Heat[] = [];
  @Input() heatsOld: string;
  @Input() championship: any;
  @Input() dueDate: string;
  @Input() idsDelete: number[] = [];
  @Input() isLoadingSaveHeats = false;
  @Input() btnMatFabConfig: BtnMatFabConfig[] = [];
  heatsSelected = new Array<Heat>();
  sticky: boolean;

  // heats: Heat[] = [];
  // form
  eventForm: FormGroup;
  isLoading = false;
  tableConfig: TableConfig = {
    checkbox: true,
    paddingSide: true,
    massActions: null,
    massActionCountTitle: null
  };
  // table
  tableName = 'schedule-heats';
  selected: string[] = [];
  private subscriptions: Subscription = new Subscription();

  constructor(
    private translateService: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private eventGuard: EventGuard,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private heatService: HeatsService,
    private changeDetectorRefs: ChangeDetectorRef,
    private dragulaService: DragulaService
  ) {
  }

  // @ts-ignore
  get isSticky(): boolean {
    return this.sticky;
  }

  // @ts-ignore
  @Input('isSticky') set isSticky(sticky: boolean) {
    this.sticky = sticky;
  }

  ngOnInit(): void {
    // Translate
    this.registerOnTranslateHeatCompetitors();
    this.createForm();
    // this.registerOnSaveHeats();
    this.table();
  }

  ngOnDestroy(): void {
    this.dragulaService.destroy(this.tableName);
    this.subscriptions.unsubscribe();
  }

  public clickType({index, type}: { index: number, type: number | string }): void {
    if (type === 'add_heat') {
      this.generateHeat();
      return;
    }
    if (type === 'save_heat') {
      this.onSubmitSave();
      return;
    }
  }

  // Heats Generator
  public generateHeat(): void {
    this.generateHeatsOld(this.heats);
    this.addNewHeatEvent.emit();
    return;
  }

  public getSelected(idRandom: string): boolean {
    // @ts-ignore
    return !!this.selected.find((element: string) => element === idRandom);
  }

  public selectedAllCheckbox(event: MatCheckbox): void {
    if (event.checked) {
      this.heats.forEach((heat: Heat) => {
        this.selected.push(heat.idRandom);
      });
      this.eventForm.setValue({
        ...this.eventForm.value,
        transition: this.heats.find((heat: Heat) => heat.idRandom === this.selected[0]).transition
      });
      this.heatsSelected = this.heats;
      return;
    }
    this.eventForm.setValue({
      ...this.eventForm.value,
      transition: null
    });
    this.heatsSelected = [];
    this.selected = [];
  }

  public checkboxAction(event: MatCheckbox, idRandom: string): void {
    if (event.checked) {
      this.selected.push(idRandom);
      this.heatsSelected.push(this.heats.find((heatFind: Heat) => heatFind.idRandom === idRandom));
      this.eventForm.setValue({
        ...this.eventForm.value,
        transition: this.heats.find((heat: Heat) => heat.idRandom === this.selected[0]).transition
      });
      return;
    }
    this.heatsSelected.splice(this.heats.findIndex((heatFind: Heat) => heatFind.idRandom === idRandom), 1);
    this.selected.splice(this.selected.findIndex((selected: string) => selected === idRandom), 1);
    if (this.selected.length === 0) {
      this.eventForm.setValue({
        ...this.eventForm.value,
        transition: null
      });
      return;
    }
    this.eventForm.setValue({
      ...this.eventForm.value,
      transition: this.heats.find((heat: Heat) => heat.idRandom === this.selected[0]).transition
    });
  }

  public editHeatName(heat: Heat): void {
    if (heat.isEditName.form.invalid) {
      return;
    }
    heat.isEditName.edit = !heat.isEditName.edit;
    heat.isUpdated = true;
    heat.is_edit_name = true;
    heat.name = heat.isEditName.form.value.toUpperCase();
    heat.isEditName.form = new FormControl(heat.name, [
      Validators.required
    ]);
    this.calcHeatsEvent.emit({
      screen: 'schedule'
    });
  }

  public onSubmitGenerate(): void {
    if (this.eventForm.invalid) {
      return;
    }
    this.generateHeatsOld(this.heats);
    const {transition} = this.eventForm.value;
    this.heatsSelected.forEach((selected) => {
      // @ts-ignore
      const index = this.heats.findIndex((heatData: Heat) => selected.idRandom === heatData.idRandom);
      this.heats[index].transition = transition;
    });
    this.calcHeatsEvent.emit({
      screen: 'schedule'
    });
  }

  public formatTime(minutes: number): string {
    if (!minutes) {
      return '';
    }
    return moment('00:00', 'HH:mm').add('minutes', minutes).format('HH:mm');
  }

  public isEditStartTime(heat: Heat): boolean {
    // @ts-ignore
    if (heat.number === 1) {
      return false;
    }

    return !heat.enable_custom_start_time;
  }
  
  public enableTransition(heat: Heat): boolean {
    if (this.heats.length === heat.number) {
      return false;
    }
    // @ts-ignore
    const heatResult: Heat | null = this.heats.find((heatFilter: Heat) => heat.number + 1 === heatFilter.number);
    if (heatResult) {
      return !heatResult.enable_custom_start_time;
    }
    return true;
  }

  public exitCompetitor(heat: Heat): boolean {
    const total = heat.heat_competitors.filter(heatCompetitorFilter => heatCompetitorFilter.type !== 'placeholder');
    return total.length > 0;
  }

  public updateHeatInput(event: any, heat: Heat, typeUpdate: number): void {
    this.generateHeatsOld(this.heats);
    if (!moment(event.target.value, 'HH:mm').isValid() && event.target.value) {
      this.toastr.show(
        this.translate.HEAT_EDIT.TOASTR.ERROR_DATE.DESCRIPTION,
        this.translate.HEAT_EDIT.TOASTR.ERROR_DATE.TITLE,
        null,
        'error'
      );
      if (typeUpdate === 1) {
        event.target.value = moment(heat.start_time, 'HH:mm:ss').format('HH:mm');
      }
      if (typeUpdate === 2) {
        event.target.value = this.formatTime(heat.transition);
      }
      if (typeUpdate === 3) {
        event.target.value = this.formatTime(heat.duration);
      }

      this.heatsOldEvent.emit('');
      return;
    }
    // start_time
    if (typeUpdate === 1) {
      heat.start_time = moment(event.target.value, 'HH:mm').format('HH:mm:ss');
    }
    // transition
    if (typeUpdate === 2) {
      heat.transition = moment(event.target.value, 'HH:mm').diff(moment('00:00', 'HH:mm'), 'minutes', true);
    }
    // duration
    if (typeUpdate === 3) {
      heat.duration = moment(event.target.value, 'HH:mm').diff(moment('00:00', 'HH:mm'), 'seconds', true);
    }
    heat.isUpdated = true;
    this.calcHeatsEvent.emit({
      event,
      hasItem: heat,
      typeUpdate,
      screen: 'schedule'
    });
  }

  public editStartTime(heat: Heat, enable: boolean): void {
    this.generateHeatsOld(this.heats);
    heat.enable_custom_start_time = enable;
    this.calcHeatsEvent.emit({
      screen: 'schedule'
    });
  }

  public reorderAction(orders: Order): void {
    if (orders.type === 'reorder') {
      this.generateHeatsOld(this.heats);
      const indexHeatOrders: { index: number; number: number }[] = [];
      if (Number(orders.data[0].number) !== 1) {
        // @ts-ignore
        const index = this.heats.findIndex((heatFilter: Heat) => heatFilter.number === orders.data[0].number);
        // @ts-ignore
        this.heats[index].start_time = this.heats.find((heatFilter: Heat) => heatFilter.number === 1).start_time;
      }
      orders.data.forEach((orderData) => {
        indexHeatOrders.push({
          // @ts-ignore
          index: this.heats.findIndex((heatFilter: Heat) => heatFilter.number === orderData.number),
          number: orderData.order + 1
        });
      });
      indexHeatOrders.forEach((indexHeatOrder) => {
        this.heats[indexHeatOrder.index].number = indexHeatOrder.number;
      });
      this.calcHeatsEvent.emit({
        screen: 'schedule'
      });
    }
  }

  public detectChanges(): void {
    this.changeDetectorRefs.detectChanges();
  }

  private table(): void {
    this.dragulaService.createGroup(this.tableName, {
      removeOnSpill: false,
      revertOnSpill: true,
      moves: (el, container, handle) => {
        return (handle.className.indexOf('menu-reorder') > -1);
      }
    });
    this.subscriptions.add(this.dragulaService.drop(this.tableName)
      .subscribe(({name, el, target, source, sibling}) => {
        const itemsOrder: any[] = [];
        const elements = source.getElementsByTagName('tr') as HTMLCollectionOf<HTMLTableRowElement>;
        for (let index = 0; index < elements.length; index++) {
          const element = elements[index];
          itemsOrder.push({
            number: Number(element.getAttribute('item-number')),
            order: index
          });
        }
        this.reorderAction({
          type: 'reorder',
          tableName: this.tableName,
          data: itemsOrder,
          number: Number(el.getAttribute('item-number'))
        });
      })
    );
  }

  private generateHeatsOld(heats: Heat[]): void {
    this.heatsOldEvent.emit(heats);
  }

  private onSubmitSave(): void {
    this.saveHeatEvent.emit();
  }

  // private registerOnSaveHeats(): void {
  //   const subscription = this.heatService
  //     .heatsUpdateScheduleObservable()
  //     .subscribe((heats: Heat[]) => {
  //         this.heats = heats.sort((a, b) => {
  //           return a.number - b.number;
  //         });
  //         this.isLoadingSaveHeats = false;
  //         this.changeDetectorRefs.detectChanges();
  //
  //         this.toastr.success(
  //           this.translate.HEATS_SCHEDULE.TOASTR.UPDATE.SUCCESS.DESCRIPTION,
  //           this.translate.HEATS_SCHEDULE.TOASTR.UPDATE.SUCCESS.TITLE,
  //           null
  //         );
  //
  //       },
  //       err => {
  //         this.isLoadingSaveHeats = false;
  //         const errors: Errors = new Errors().deserialize((err.error as any));
  //
  //         this.toastr.error(
  //           errors.getFullMessages(),
  //           this.translate.HEATS_SCHEDULE.TOASTR.UPDATE.ERROR.TITLE,
  //           null,
  //         );
  //       }
  //     );
  //
  //   this.subscriptions.add(subscription);
  // }

  private createForm(): void {
    this.eventForm = this.formBuilder.group({
      transition: [
        null,
        [
          Validators.required,
          Validators.min(0)
        ]
      ],
    });
  }

  private getIndexHeatOld(heat: Heat | number): number {
    if (typeof heat === 'object') {
      return this.heats
        .findIndex((heatFilter: Heat) => heatFilter.number === heat.number - 1);
    }
    if (typeof heat === 'number') {
      return this.heats
        .findIndex((heatFilter: Heat) => heatFilter.number === heat - 1);
    }
    return -1;
  }

  private registerOnTranslateHeatCompetitors(): void {
    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService
      .get('HEAT_COMPETITORS')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);
  }
}

export interface Order {
  type: string;
  data: { number: number, order: number }[];
  tableName: string;
  number: number;
}
