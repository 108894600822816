<div mat-dialog-content class="dialog-container">
  <!-- <div class="modal-background" (click)="dialogRef.close()"></div> -->
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>{{ 'HEAT_COMPETITORS.HEAT_EDIT.TITLE' | translate:{ heat_number: heat.number } | uppercase }}</h1>
      <span class="close" (click)="dialogRef.close(null)">
        <img src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>

    <form class="mt-16" name="heatFormGroup" [formGroup]="heatFormGroup" autocomplete="off" novalidate>
      <div
        fxFlex="100"
        fxFlex.xs="100"
        fxLayout="column"
        fxLayoutAlign="start stretch"
      >
        <mat-form-field fxFlex="100">
          <input
            matInput
            type="number"
            formControlName="heatNumber"
            placeholder="{{ 'HEAT_COMPETITORS.HEAT_EDIT.FORM.NUMBER' | translate }}"
            [errorStateMatcher]="matchers.heatNumber"
            required
          />
          <mat-error *ngIf="heatFormGroup.controls.heatNumber.hasError('required')">
            {{ 'HEAT_COMPETITORS.HEAT_EDIT.FORM.ERROR.NUMBER_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
        
        <mat-form-field fxFlex="100">
          <input
            matInput
            type="text"
            formControlName="heatName"
            placeholder="{{ 'HEAT_COMPETITORS.HEAT_EDIT.FORM.NAME' | translate }}"
            [errorStateMatcher]="matchers.heatName"
            uppercase
            required
          />
          <mat-error *ngIf="heatFormGroup.controls.heatName.hasError('required')">
            {{ 'HEAT_COMPETITORS.HEAT_EDIT.FORM.ERROR.NAME_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>

    <div class="mt-16 mb-8 text-red" *ngIf="requestErrors !== null">
      {{ requestErrors }}
    </div>

    <div class="mb-0 mt-8" fxLayoutAlign="end center">
      <btn type="grayscale" (click)="dialogRef.close(null)" class="mr-16">{{ 'HEAT_COMPETITORS.CANCEL' | translate | uppercase }}</btn>
      <btn [btnLoading]="true" [loading]="isLoading" [buttonDisabled]="heatFormGroup.invalid" (click)="saveHeat()">{{ 'HEAT_COMPETITORS.SAVE' | translate | uppercase }}</btn>
    </div>
  </div>
</div>