import { Injectable } from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {Heat} from '../../model/heat.model';
import {HttpClient} from '@angular/common/http';
import {AuthGuard} from '../../guards/auth.guard';
import {Router} from '@angular/router';
import {CustomField} from '../../model/custom-field.model';
import {Lot} from '../../model/lot';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldsService {

  private subscriptions = new Subscription();
  private _fetchCustomFieldsObservable = new Subject<{ data: CustomField[], type: string }>();

  constructor(
    private http: HttpClient,
    private authGuard: AuthGuard,
    private router: Router,
  ) { }

  fetchCustomFieldsObservable(): Observable<{ data: CustomField[], type: string }> {
    return this._fetchCustomFieldsObservable.asObservable();
  }

  fetchCustomFieldWithEventAndType(eventId: number, type: string): void {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'custom-fields',
      'event',
      eventId,
      'subscription_type',
      type
    ];

    const url = this.router.createUrlTree(baseUrl);
    const parsedUrl = url.toString().substring(1, url.toString().length);
    
    const subscription = this.http
      .get(parsedUrl, {responseType})
      .subscribe((customFields: CustomField[]) => {
          this._fetchCustomFieldsObservable.next({
            data: customFields,
            type: type
          });
        },
        err => {
          this._fetchCustomFieldsObservable.error({ error: err, type: type });
        });
    
    this.subscriptions.add(subscription);
  }
  
  create(eventId: number, parameters: any):  Observable<CustomField>{
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'custom-fields',
      eventId
    ];

    const url = this.router.createUrlTree(baseUrl);

    const parsedUrl = url.toString().substring(1, url.toString().length);
    return this.http.post(parsedUrl, parameters, { responseType })
      .map(response => {
        return new CustomField(response);
      })
  }
  
  update(customFieldId: number, parameters: any): Observable<CustomField>{
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'custom-fields',
      customFieldId
    ];

    const url = this.router.createUrlTree(baseUrl);

    const parsedUrl = url.toString().substring(1, url.toString().length);
    return this.http.put(parsedUrl, parameters, { responseType })
      .map(response => {
        return new CustomField(response);
      })
  }

  delete(customFieldId: number): Observable<any>{
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'custom-fields',
      customFieldId
    ];

    const url = this.router.createUrlTree(baseUrl);

    const parsedUrl = url.toString().substring(1, url.toString().length);
    return this.http.delete(parsedUrl, { responseType })
      .map(response => {
        return response
      })
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
