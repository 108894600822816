export class Payment {
  id: number
  subscription_id: number
  user_id: number
  event_id: number
  team_id?: number
  lot_id?: number
  coupon_id?: number
  invitation_id?: number
  amount?: number
  payment_amount?: number
  tax?: number
  payment_id?: number
  payment_method?: string
  payment_method_name?: string
  cc_brand?: string
  cc_last_digits?: string
  boleto_url?: string
  paymentMethod?: string
  payment_date?: Date
  paymentDate?: Date
  receive_date_date?: Date
  receive_date_available?: Date
  statusCode?: number
  status?: string
  payment_status?: number
  hash?: string
  color: string = ''
  created_at?: Date
  updated_at?: Date
  deleted_at?: Date

  deserialize(object: any) {
    (<any>Object).assign(this, object)

    switch (object.status) {
      case PaymentStatus.APPROVED:
        this.color = '#81BB46' 

      case PaymentStatus.WAITING:
        this.color = '#8195A5' 

      case PaymentStatus.REFUSED:
        this.color = '#EB5B46' 

      case PaymentStatus.REFUNDED:
        this.color = '#BC83EE' 

      case PaymentStatus.CHARGEBACK:
        this.color = '#BC83EE' 

      default:
        break;
    }

    this.payment_status = object.status

    return this;
  }
}

export enum PaymentMethods {
  CREDITCARD = 'credit_card',
  BOLETO = 'boleto'
}

export enum PaymentStatus {
  WAITING = 1,
  APPROVED = 2,
  REFUSED = 3,
  REFUNDED = 4,
  CHARGEBACK = 11,
}

export enum CardBrand {
  AMEX = 'amex',
  AURA = 'aura',
  DINNERS = 'dinners',
  DISCOVERER = 'discover',
  ELO = 'elo',
  HIPERCARD = 'hipercard',
  MASTERCARD = 'mastercard',
  VISA = 'visa',
  JCB = 'jcb'
}



