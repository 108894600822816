<div mat-dialog-content class="dialog-container">
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>{{ titleModel | uppercase }}</h1>
      <span class="close" (click)="dialogRef.close(null)">
        <img src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>

    <form [formGroup]="urlForm" fxLayout="column" name="urlForm" autocomplete="off" novalidate>
      <mat-form-field>
        <input matInput placeholder="{{ translate.FORM.TITLE }}" formControlName="title" [errorStateMatcher]="matchers.title" uppercase required>
        <mat-error *ngIf="urlForm.controls.title.hasError('required')">
          {{ translate.VALIDATIONS.TITLE_REQUIRED }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="mt-8">
        <input matInput placeholder="{{ translate.FORM.URL }}" formControlName="url" [errorStateMatcher]="matchers.url">
        <mat-error *ngIf="urlForm.controls.url.hasError('pattern')">
          {{ translate.VALIDATIONS.URL_INVALID }}
        </mat-error>
      </mat-form-field>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center" class="mb-8">
        <mat-form-field fxFlex="auto" class="pr-16">
          <input matInput placeholder="{{ translate.FORM.FILE }}" formControlName="file" [value]="fileName">
          <!--<mat-error *ngIf="urlForm.controls.file.hasError('required')">
            {{ 'SETTINGS.VALIDATIONS.REGULATION_REQUIRED' | translate}}
          </mat-error>-->
        </mat-form-field>
        
        <input type="file" name="file-regulation" id="file-regulation" class="inputfile" (change)="readUrl($event)" accept=".pdf, .jpg, .jpeg, .png" />
        <label fxFlex="auto" for="file-regulation" class="btn mt-8">{{ translate.FORM.SELECT | uppercase }}</label>
      </div>
    </form>

    <div class="text-red" *ngIf="errorRequest !== null">
      {{ errorRequest }}
    </div>

    <div class="mb-0 mt-8" fxLayoutAlign="end center">
      <btn type="grayscale" (click)="dialogRef.close(null)" class="mr-16">{{ translate.CANCEL | uppercase }}</btn>
      <btn [btnLoading]="true" [loading]="isLoading" (click)="saveUrl()">{{ translate.SAVE | uppercase }}</btn>
    </div>
  </div>
</div>