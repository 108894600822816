<fuse-filter-sidebar title="{{ 'PARTICIPANTS.FILTER.FILTER' | translate | uppercase }}">
  <form (ngSubmit)="submitFilter()" [formGroup]="formFilter" fxLayout="column">
    <mat-form-field>
      <mat-select id="form-status" placeholder="Status" formControlName="status" multiple>                
        <mat-option *ngFor="let select of selectStatus" [value]="select">
          {{ select.name | uppercase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="{{ 'PARTICIPANTS.CATEGORY' | translate }}" id="form-competition" formControlName="competition">
        <mat-option [value]="defaultValueCompetition">
          {{ 'PARTICIPANTS.ALL' | translate | uppercase }}
        </mat-option>
        <mat-option *ngFor="let competition of championship.competitions" [value]="{ 
          value: competition.id,
          name: competition.name,
          placeholder: ('PARTICIPANTS.CATEGORY' | translate)
         }">
          {{ competition.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="enableSubDivision">
      <mat-select  id="form-sub-division" placeholder="{{ championship.sub_division_group_name ? (championship.sub_division_group_name | titlecase)  : 'PARTICIPANTS.SUB_DIVISION' | translate }}" formControlName="sub_division">
        <mat-option [value]="defaultValueSubDivision">
          {{ 'PARTICIPANTS.ALL' | translate | uppercase }}
        </mat-option>
        <mat-option *ngFor="let subDivision of subDivisions" [value]="{ 
          value: subDivision.id,
          name: subDivision.name,
          placeholder: (championship.sub_division_group_name ? (championship.sub_division_group_name | titlecase)  : 'PARTICIPANTS.SUB_DIVISION' | translate)
         }">
          {{ subDivision.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div fxLayoutAlign="end center">
      <btn [typeButton]="'button'" type="grayscale" (click)="toggleFilter()" class="mr-16">{{ 'PARTICIPANTS.FILTER.CANCEL' | translate | uppercase }}</btn>
      <btn [btnLoading]="true" [loading]="false" [buttonDisabled]="formFilter.invalid">{{ 'PARTICIPANTS.FILTER.SUBMIT' | translate | uppercase }}</btn>
    </div>
  </form>
</fuse-filter-sidebar>
<div class="page-layout blank pb-64" id="scrollBar">
  <div class="p-32">
    <div class="content">
      <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
        <div class="pt-16">
          <tab-bar
            #tabbar
            [padding]="true"
            (tabEvent)="tabAction($event)"
            [items]="tabsFilter"
            [tabActive]="0">
          </tab-bar>
        </div>
        
        <div fxLayout="row wrap" fxlayoutalign.xs="column" fxLayoutAlign="space-between start" class="pl-32 pr-32 pt-16 pb-12 mb-16 search line-bottom">
          <mat-form-field fxFlex="40" fxFlex.xs="100" floatLabel="never">
            <mat-icon matPrefix class="icon-search">search</mat-icon>
            <input
              matInput
              placeholder="{{ 'PARTICIPANTS.SEARCH' | translate }}"
              [(ngModel)]="searchTerm"
              (ngModelChange)="searchName($event)">
          </mat-form-field>
          <div fxFlex="60" fxFlex.xs="100" fxLayoutAlign="end center">
            <div>
              <btn color="#2c4151" (click)="toggleFilter()" [btnLoading]="true" [loading]="false" type="grayscale" materialIcon="tune" iconAlign="left">{{ 'PARTICIPANTS.FILTER.FILTER' | translate }}</btn>
            </div>
            <div class="pl-8" fxHide.xs *ngIf="!isEmptyResult">
              <btn [btnLoading]="true" [loading]='isLoadingExport' type="grayscale" icon="content/ic-export.svg" iconAlign="left" (click)="exportCompetitors()">{{ 'PARTICIPANTS.EXPORT' | translate}}</btn>
            </div>
          </div>
          <div>
            <span class="filters-tags-title" *ngIf="tags.length > 0">Filtros selecionados: </span> 
            <tag class="mr-8 mt-8" *ngFor="let tag of tags; let i = index" (tagEvent)="tagDelete($event)" [index]="i" [tag]="tag">{{tag.text}}</tag>
          </div>
        </div>

        <!-- Empty list -->
        <div fxLayout="column" fxLayoutAlign="center center" class="p-32 emtpy-content" *ngIf="isEmptyResult">
          <img src="./assets/icons/placeholder/ic-empty.svg" />
          <span class="mt-8">{{ 'PARTICIPANTS.EMPTY' | translate }}</span>
        </div>
        
        <!-- Table -->
        <table-x #table [items]="competitors" [config]="tableConfig" [isLoading]="isLoading" [totalCount]="totalCount" *ngIf="!isEmptyResult" (sortEvent)="sortAction($event)">

          <column width="50px" property="number" header="{{ 'PARTICIPANTS.TABLE_HEADER.NUMBER' | translate }}" [sortable]="true" align="left" classesBody="pt-20 pb-20">
            <ng-template #dataTableCell let-item="item" let-index="index">
              {{ item.number || '-' }}
            </ng-template>
          </column>

          <column property="teams.name" header="{{ 'PARTICIPANTS.TABLE_HEADER.TEAM' | translate }}" [sortable]="true" align="left" classesBody="pt-20 pb-20" *ngIf="type === 'team'">
            <ng-template #dataTableCell let-item="item" let-index="index">
              <a (click)="openTeamProfile(item, index)" class="small-font" fxLayoutAlign="start center" *ngIf="item.team !== null">
                {{ item.team.name }}
                <label-x class="ml-8" *ngIf="item.is_cut || item.disqualified">{{ item.disqualified ? ('PARTICIPANTS.WD' | translate) : ('PARTICIPANTS.CUT' | translate)  }}</label-x>
              </a>
            </ng-template>
          </column>
        
          <column [property]="(type === 'team') ? 'teams.name' : 'users.firstname'" header="{{ (type === 'team') ? ('PARTICIPANTS.TABLE_HEADER.CAPITAIN' | translate) : ('PARTICIPANTS.TABLE_HEADER.NAME' | translate) }}" [sortable]="type !== 'team'" align="left">
            <ng-template #dataTableCell let-item="item" let-index="index">
              <a (click)="openUserProfile(item, index)" fxLayout="row" fxLayoutAlign="start center" class="small-font" *ngIf="type !== 'team' && item.team.type === 'single'">
                <img src="{{ (item.user && item.user.avatar) ? item.user.avatar : null | placeholderImage:'user' }}" width="80" class="avatar corner-radius" />
                <span fxLayout="column">
                  {{ (item.user) ? item.user.fullname : '' }}
                </span>
                <label-x class="ml-8" *ngIf="item.is_cut || item.disqualified">{{ item.disqualified ? ('PARTICIPANTS.WD' | translate) : ('PARTICIPANTS.CUT' | translate)  }}</label-x>
              </a>
              <span fxLayout="row" fxLayoutAlign="start center" *ngIf="type === 'team' || (type !== 'team' && item.team.type === 'team')">
                <img src="{{ (item.user && item.user.avatar) ? item.user.avatar : null | placeholderImage:'user' }}" width="80" class="avatar corner-radius" *ngIf="type !== 'team'" />
                {{ (item.user) ? item.user.fullname : '' }}
              </span>
            </ng-template>
          </column>
        
          <!--<column property="users.email" header="{{ 'PARTICIPANTS.TABLE_HEADER.EMAIL' | translate }} {{ (type === 'team') ? ('PARTICIPANTS.TABLE_HEADER.CAPITAIN' | translate | lowercase) : '' }}" [sortable]="type !== 'team'" align="left">
            <ng-template #dataTableCell let-item="item">
              <a href="mailto:{{ item.user.email }}" fxLayout="row" class="small-font" *ngIf="type !== 'team' && item.user">
                {{ (item.user) ? item.user.email : '' }}
              </a>
              <span *ngIf="type === 'team'">
                {{ (item.user) ? item.user.email : '' }}
              </span>
            </ng-template>
          </column>-->
        
          <!--<column property="competitors.created_at" header="{{ 'PARTICIPANTS.TABLE_HEADER.DATE' | translate }}" [sortable]="true" align="left">
            <ng-template #dataTableCell let-item="item">
              {{ (item.created_at) ? (item.created_at.date | date:('PARTICIPANTS.DATE_FORMAT' | translate)) : '' }}
            </ng-template>
          </column>-->

          <column property="users.gender" header="{{ 'PARTICIPANTS.TABLE_HEADER.GENDER' | translate }}" [sortable]="false" align="left" *ngIf="type === 'single' || type === 'all'">
            <ng-template #dataTableCell let-item="item" let-index="index">
              {{ (item.user) ? item.user.gender_string : '' }}
            </ng-template>
          </column>

          <column property="team.box" header="{{ 'PARTICIPANTS.TABLE_HEADER.BOX' | translate }}" [sortable]="false" align="left">
            <ng-template #dataTableCell let-item="item" let-index="index">
              {{ (item.team.box) ? item.team.box.name : '' }}
            </ng-template>
          </column>
        
          <column property="teams.name" header="{{ 'PARTICIPANTS.TABLE_HEADER.TEAM' | translate }}" [sortable]="false" align="left" *ngIf="type === 'all' || type === 'inactive'">
            <ng-template #dataTableCell let-item="item" let-index="index">
              <a (click)="openTeamProfile(item, index)" class="small-font" fxLayoutAlign="start center" *ngIf="item.team.type === 'team'">
                {{ item.team.name }}
                <label-x class="ml-8" *ngIf="item.is_cut || item.disqualified">{{ item.disqualified ? ('PARTICIPANTS.WD' | translate) : ('PARTICIPANTS.CUT' | translate)  }}</label-x>
              </a>
            </ng-template>
          </column>

          <column property="competition_id" header="{{ 'PARTICIPANTS.TABLE_HEADER.CATEGORY' | translate }}" [sortable]="true" align="left">
            <ng-template #dataTableCell let-item="item">
              {{ item.competition.name }}
              <span *ngIf="item.sub_division">/ {{ item?.sub_division?.name }}</span>
            </ng-template>
          </column>

          <column property="checkin" header="Status" [sortable]="false" align="center" width="180px">
            <ng-template #dataTableCell let-item="item" let-index="index">
              <label-x class="mr-8 ml-8" type="{{ getStatusCheckinType(item.checkin_status) }}">{{ item.checkin_name | uppercase }}</label-x>
              <div class="checkin">
                <mat-icon *ngIf="item.checkin_status === CompetitorCheckin.REGISTERED || item.checkin_status === CompetitorCheckin.NO_SHOW && item.checkin_status !== CompetitorCheckin.WITHDRAW" (click)="checkin(CompetitorCheckin.CHECKIN, item.id, index)" matTooltip="{{ 'PARTICIPANTS.TOOLTIPS.CHECKIN' | translate }}" matTooltipPosition="below" matTooltipClass="below">check</mat-icon>
                <mat-icon *ngIf="item.checkin_status === CompetitorCheckin.CHECKIN || item.checkin_status === CompetitorCheckin.NO_SHOW || item.checkin_status === CompetitorCheckin.NO_SHOW_ONLINE || item.checkin_status === CompetitorCheckin.WITHDRAW" (click)="checkin(CompetitorCheckin.REGISTERED, item.id, index)" matTooltip="{{ (item.checkin_status === CompetitorCheckin.NO_SHOW_ONLINE ? 'Check-in online' : 'PARTICIPANTS.TOOLTIPS.REGISTERED' | translate) }}" matTooltipPosition="below" matTooltipClass="below">undo</mat-icon>
                <mat-icon *ngIf="item.checkin_status !== CompetitorCheckin.NO_SHOW &&  item.checkin_status !== CompetitorCheckin.NO_SHOW_ONLINE && item.checkin_status !== CompetitorCheckin.WITHDRAW" (click)="checkin(CompetitorCheckin.NO_SHOW, item.id, index)" matTooltip="{{ 'PARTICIPANTS.TOOLTIPS.NO_SHOW' | translate }}" matTooltipPosition="below" matTooltipClass="below">cancel</mat-icon>
                <ng-container *ngFor="let member of item.team.members">
                  <a href="{{ member.checkin_file }}" download target="_blank" *ngIf="item.user.id === member.user_id && member.checkin_file && item.checkin_status === CompetitorCheckin.CHECKIN_ONLINE && (type !== 'team')">
                    <mat-icon matTooltip="{{ 'PARTICIPANTS.TOOLTIPS.FILE' | translate }}" matTooltipPosition="below" matTooltipClass="below">attach_file</mat-icon>
                  </a>
                </ng-container>
              </div>
            </ng-template>
          </column>
          <!-- <column property="action" header="" [sortable]="false" align="center">
            <ng-template #dataTableCell let-item="item">
              <btn-transparent icon="common/ic-settings.svg" buttonWidth="35px" iconAlign="left"></btn-transparent>
              [matMenuTriggerFor]="resultActionMenu" [matMenuTriggerData]="{item: item, valor: 'teste'}"
            </ng-template>
          </column> -->
        
        </table-x>

        <button mat-fab (click)="addNew()" *ngIf="type !== 'inactive' && type !== 'all'">
          <mat-icon class="mat-30">add</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>


<scroll-to-top
  scrollId="scrollBar"
  [animate]="true"
  [speed]="50"
  [acceleration]="2"
  [scrollDistance]="300"
  (scrollEvent)="scrollEvent($event)"
  [scrollInfinite]="true"
  [scrollRetriveDistance]="400"></scroll-to-top>