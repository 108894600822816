<div mat-dialog-content class="dialog-container">
  <div class="header-modal" *ngIf="competitor">
    <img *ngIf="competitor.team.type !== 'team'" src="{{ (competitor.team.user &&competitor.team.user.avatar) ? competitor.team.user.avatar : null | placeholderImage:'user' }}" class="avatar corner-radius" />
    <h3 fxLayout="column">
      {{ competitor.team.name }}
      <span>{{ competitor.team.box }}</span>
    </h3>
    <div class="country" *ngIf="competitor?.team?.country">
      <img src="{{ competitor.team.country.flag_url }}" alt="{{ competitor.team.country.name }}" />
      <p>{{ competitor.team.country.initials | uppercase }}</p>
    </div>
    <span class="close" (click)="dialogRef.close(null)">
      <img src="./assets/icons/modal/ic-close-white.svg" />
    </span>
  </div>
  <div class="card-content mt-0 p-0">
    <div class="main" fxLayout="column">
      <div clas="mt-6">
        <tab-bar
          #tabbar
          [padding]="true"
          (tabEvent)="tabAction($event)"
          [items]="tabsFilter"
          [tabActive]="0">
        </tab-bar>
      </div>
      <div id="rank" *ngIf="tabActive === TABS.RANK">
        <div fxLayout="column" fxLayoutAlign="center center" class="p-64 emtpy-content" *ngIf="!competitor.rank">
          <img src="./assets/icons/placeholder/ic-empty.svg" />
          <span class="mt-8">{{'MODAL_COMPETITOR.RANK.EMPTY' | translate}}</span>
        </div>
        <div class="title-rank pb-16 pt-16" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="competitor.rank && !competitor.disqualified">
          <div fxFlex="50" fxLayout="column" fxLayoutAlign="center center">
            {{ !subDivisionId ? competitor.rank : competitor.sub_division_rank }}
            <span>{{ 'MODAL_COMPETITOR.RANK.POSITION' | translate | uppercase }}</span>
          </div>
          <div fxFlex="50" fxLayout="column" fxLayoutAlign="center center">
            {{ !subDivisionId ? competitor.total : competitor.sub_division_total }}
            <span>{{ 'MODAL_COMPETITOR.RANK.POINTS' | translate | uppercase }}</span>
          </div>
        </div>
        <div class="content-rank" *ngFor="let result of results, let i = index">
          <div *ngIf="result.rank">
            <h3>{{ result.name | uppercase }}</h3>
            <span *ngIf="result.points != null">{{ 'MODAL_COMPETITOR.RANK.RANK' | translate }}: {{ result.rank }} | {{ 'MODAL_COMPETITOR.RANK.POINTS' | translate }}: {{ result.points }}</span>
            <span *ngIf="result.points == null">{{ 'MODAL_COMPETITOR.RANK.RANK' | translate }}: {{ result.rank }}</span>
          </div>
          <div *ngIf="result.rank" class="result m-0" fxLayout="column" fxLayoutAlign="center end">
              {{ result.result }}
              <span *ngIf="result.result && result.tiebreak && result.tiebreak !== '0'">{{ result.tiebreak }}</span>
          </div>
        </div>
      </div>
      <div id="schedule" *ngIf="tabActive === TABS.SCHEDULE">
        <div fxLayout="column" fxLayoutAlign="center center" class="p-64 emtpy-content" *ngIf="heatCompetitors && heatCompetitors.length === 0 && !isLoadingHeats">
          <img src="./assets/icons/placeholder/ic-empty.svg" />
          <span class="mt-8">{{'MODAL_COMPETITOR.HEAT.EMPTY' | translate}}</span>
        </div>
        <div class="content-schedule" fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let heatCompetitor of heatCompetitors, let i = index">
          <div fxFlex="auto">
            <h3 class="m-0">{{ heatCompetitor?.wod?.name }}</h3>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="start start">
              <span fxLayoutAlign="start center">{{ 'MODAL_COMPETITOR.HEAT.HEAT' | translate | uppercase }} {{ heatCompetitor?.heat?.number }}</span>
              <span fxLayoutAlign="center center">{{ 'MODAL_COMPETITOR.LANE' | translate | uppercase }} #{{ heatCompetitor?.lane }}</span>
              <span fxLayoutAlign="end center">{{ heatCompetitor?.heat?.due_date | date:('MODAL_COMPETITOR.DATE_FORMAT' | translate) }}</span>
            </div>
          </div>
          <div class="result" fxFlex="auto" fxLayoutAlign="end center">
            {{ heatCompetitor?.heat?.start_time }}
            <mat-icon class="ml-8" *ngIf="heatCompetitor.result">check_circle</mat-icon>
          </div>
        </div>
      </div>
      <div fxLayoutAlign="center center" class="p-64" *ngIf="isLoadingTeam || isLoadingHeats">
        <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
      </div>
      <div id="members" *ngIf="tabActive === TABS.TEAM_MATES && team !== null && !isLoadingTeam">
        <div class="content-members line-bottom pt-16 pb-16" *ngFor="let member of team.members, let i = index">
          <div>
            <div style="position: relative;" class="mr-12">
              <img src="{{ (member.user.avatar) ? member.user.avatar : null | placeholderImage:'user' }}" class="avatar corner-radius" />
              <div *ngIf="member.capitan" class="btn-capitain">
                <img src="./assets/icons/profiles/ic-capitain.svg" />
              </div>
            </div>
            <p>
              {{ member?.leaderboard_name }}
              <span>{{ team.box?.name }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
