import {CustomFieldType} from '../model/custom-field-type.model';

export function GetFieldTypes(lang: string = 'pt', someShow: boolean = true): CustomFieldType[]
{
  const language = lang.replace('-BR', '')
  const types: CustomFieldType[] = [
    {
      id: 1,
      type: 'email',
      name: 'E-mail',
      hasOptions: false,
      showType: false,
    },
    {
      id: 2,
      type: 'box',
      name: 'Box',
      hasOptions: false,
      showType: false,
    },
    {
      id: 3,
      type: 'phone',
      name: (language === 'pt') ? 'Telefone' : 'Phone',
      hasOptions: false,
      showType: false,
    },
    {
      id: 4,
      type: 'cpf',
      name: (language === 'pt') ? 'CPF' : 'CPF',
      hasOptions: false,
      showType: false,
    },
    {
      id: 5,
      type: 'single_checkbox',
      name: (language === 'pt') ? 'Checkbox' : 'Checkbox',
      hasOptions: false,
      showType: true,
    },
    {
      id: 6,
      type: 'multiple_checkbox',
      name: (language === 'pt') ? 'Multiplos checkbox' : 'Multiple Checkbox',
      hasOptions: true,
      showType: false,
    },
    {
      id: 7,
      type: 'select',
      name: (language === 'pt') ? 'Select' : 'Drop down',
      hasOptions: true,
      showType: true,
    },
    {
      id: 8,
      type: 'single_line_text',
      name: (language === 'pt') ? 'Linha de texto' : 'Single line text',
      hasOptions: false,
      showType: true,
    },
    {
      id: 9,
      type: 'number',
      name: (language === 'pt') ? 'Campo de número' : 'Number',
      hasOptions: false,
      showType: true,
    },
    {
      id: 10,
      type: 'multiple_line_text',
      name: (language === 'pt') ? 'Multiplas linhas de texto' : 'Multiple line text',
      hasOptions: false,
      showType: true,
    },
    {
      id: 11,
      type: 'date_picker',
      name: (language === 'pt') ? 'Campo de data' : 'Date',
      hasOptions: false,
      showType: true,
    },
    {
      id: 12,
      type: 'datetime_picker',
      name: (language === 'pt') ? 'Campo de data e hora' : 'Date time',
      hasOptions: false,
      showType: false,
    },
    {
      id: 13,
      type: 'autocomplete',
      name: (language === 'pt') ? 'Campo de autocomplete' : 'Auto Complete',
      hasOptions: false,
      showType: false,
    },
    {
      id: 14,
      type: 'address',
      name: (language === 'pt') ? 'Endereço' : 'Address',
      hasOptions: false,
      showType: false,
    },
    {
      id: 15,
      type: 'country',
      name: (language === 'pt') ? 'Países' : 'Countrys',
      hasOptions: false,
      showType: false,
    }
  ];
  
  if (someShow) {
    return types.filter(item => {
      return item.showType === true;
    });
  } 

  return types
}


export function SearchFieldTypes(id: number, lang: string = 'pt'): CustomFieldType
{
  const language = lang.replace('-BR', '')
  const types: CustomFieldType[] = [
    {
      id: 1,
      type: 'email',
      name: 'E-mail',
      hasOptions: false,
      showType: false,
    },
    {
      id: 2,
      type: 'box',
      name: 'Box',
      hasOptions: false,
      showType: false,
    },
    {
      id: 3,
      type: 'phone',
      name: (language === 'pt') ? 'Telefone' : 'Phone',
      hasOptions: false,
      showType: false,
    },
    {
      id: 4,
      type: 'cpf',
      name: (language === 'pt') ? 'CPF' : 'CPF',
      hasOptions: false,
      showType: false,
    },
    {
      id: 5,
      type: 'single_checkbox',
      name: (language === 'pt') ? 'Checkbox' : 'Checkbox',
      hasOptions: false,
      showType: true,
    },
    {
      id: 6,
      type: 'multiple_checkbox',
      name: (language === 'pt') ? 'Multiplos checkbox' : 'Multiple Checkbox',
      hasOptions: true,
      showType: true,
    },
    {
      id: 7,
      type: 'select',
      name: (language === 'pt') ? 'Select' : 'Drop down',
      hasOptions: true,
      showType: true,
    },
    {
      id: 8,
      type: 'single_line_text',
      name: (language === 'pt') ? 'Linha de texto' : 'Single line text',
      hasOptions: false,
      showType: true,
    },
    {
      id: 9,
      type: 'number',
      name: (language === 'pt') ? 'Campo de número' : 'Number',
      hasOptions: false,
      showType: true,
    },
    {
      id: 10,
      type: 'multiple_line_text',
      name: (language === 'pt') ? 'Multiplas linhas de texto' : 'Multiple line text',
      hasOptions: false,
      showType: true,
    },
    {
      id: 11,
      type: 'date_picker',
      name: (language === 'pt') ? 'Campo de data' : 'Date',
      hasOptions: false,
      showType: true,
    },
    {
      id: 12,
      type: 'datetime_picker',
      name: (language === 'pt') ? 'Campo de data e hora' : 'Date time',
      hasOptions: false,
      showType: false,
    },
    {
      id: 13,
      type: 'autocomplete',
      name: (language === 'pt') ? 'Campo de autocomplete' : 'Auto Complete',
      hasOptions: false,
      showType: false,
    },
    {
      id: 14,
      type: 'address',
      name: (language === 'pt') ? 'Endereço' : 'Address',
      hasOptions: false,
      showType: false,
    },
    {
      id: 15,
      type: 'country',
      name: (language === 'pt') ? 'Países' : 'Countrys',
      hasOptions: false,
      showType: false,
    }
  ];

  // @ts-ignore
  return types.find(item => item.id === id);
}