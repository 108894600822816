<div fxLayout="column">
  <div class="card auto-width pt-16 pb-16" fxFlex="100" fxLayout="column">
    <div class="m-32 mt-0 mb-0" fxFlex="100" fxLayout="column" *ngIf="heats">
      <description-section class="mb-16"
                           title="{{'HEAT_HEATCOMPETITORS.HEATS_SCHEDULE.TITLE_CONTENT' | translate}}"></description-section>
      <form
        class="mb-16"
        name="eventForm"
        [formGroup]="eventForm"
        (ngSubmit)="onSubmitGenerate()"
        autocomplete="off"
        novalidate
      >
        <div
          fxFlex="100"
          fxFlex.xs="100"
          fxLayout="row"
          fxLayoutAlign="stretch flex-end"
        >
          <mat-form-field class="mr-16">
            <input
              matInput
              mask="00/00/0000"
              formControlName="dueDate"
              placeholder="{{
            'HEAT_HEATCOMPETITORS.HEATS_SCHEDULE.FIELDS.DUE_DATE' | translate
          }}"
            />
            <mat-error *ngIf="eventForm.controls.dueDate.hasError('required')">
              {{
              "HEAT_HEATCOMPETITORS.HEATS_SCHEDULE.VALIDATIONS.DUE_DATE_REQUIRED" | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="mr-16">
            <input
              matInput
              mask="00:00"
              [dropSpecialCharacters]="false"
              formControlName="startTime"
              placeholder="{{
            'HEAT_HEATCOMPETITORS.HEATS_SCHEDULE.FIELDS.START_TIME' | translate
          }}"
            />
            <mat-error *ngIf="eventForm.controls.startTime.hasError('required')">
              {{
              "HEAT_HEATCOMPETITORS.HEATS_SCHEDULE.VALIDATIONS.START_TIME_REQUIRED" | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="mr-16">
            <input
              matInput
              type="number"
              formControlName="duration"
              placeholder="{{
            'HEAT_HEATCOMPETITORS.HEATS_SCHEDULE.FIELDS.DURATION' | translate
          }}"
            />
            <mat-error *ngIf="eventForm.controls.duration.hasError('required')">
              {{
              "HEAT_HEATCOMPETITORS.HEATS_SCHEDULE.VALIDATIONS.DURATION_REQUIRED" | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="mr-16">
            <input
              matInput
              type="number"
              formControlName="transition"
              placeholder="{{
            'HEAT_HEATCOMPETITORS.HEATS_SCHEDULE.FIELDS.TRANSITION' | translate
          }}"
            />
            <mat-error *ngIf="eventForm.controls.transition.hasError('required')">
              {{
              "HEAT_HEATCOMPETITORS.HEATS_SCHEDULE.VALIDATIONS.TRANSITION_REQUIRED" | translate
              }}
            </mat-error>
          </mat-form-field>
          <div class="mb-16">
            <btn
              [buttonDisabled]="eventForm.invalid"
            >
              {{
              "HEAT_HEATCOMPETITORS.HEATS_SCHEDULE.SUBMIT_GENERATE_SCHEDULE" | translate | uppercase
              }}
            </btn>
          </div>
        </div>
      </form>
    </div>
    <!-- Empty list -->
    <div fxLayout="column" fxLayoutAlign="center center" class="p-32 emtpy-content" *ngIf="!heats">
      <img src="./assets/icons/placeholder/ic-empty.svg" />
      <span class="mt-8">{{ 'HEAT_HEATCOMPETITORS.HEATS_SCHEDULE.EMPTY' | translate }}</span>
    </div>
    <div fxFlex="100" *ngIf="heats" fxLayout="column">
      <table-x
        #tableCompetition
        [items]="heats"
        [config]="tableCatConfig"
        [isDraggable]="false"
        [tableName]="'schedule'"
        (allSelectedTable)="allSelectedItems($event)"
        [showMassActions]="false"
      >
        <column property="" header="{{'HEAT_HEATCOMPETITORS.HEATS_SCHEDULE.TABLE.HEAT' | translate}}" [sortable]="false"
                align="center">
          <ng-template #dataTableCell let-item="item" let-index="index" matColumnDef="index">
            {{index + 1}}
          </ng-template>
        </column>
        <column property="" header="{{'HEAT_HEATCOMPETITORS.HEATS_SCHEDULE.TABLE.DESCRIPTION' | translate}}"
                [sortable]="false" align="left">
          <ng-template #dataTableCell let-item="item">
            <ng-container *ngIf="!item?.heat_competitors?.length">
              <span class="hide-in-drag">-</span>
            </ng-container>
            <ng-container *ngFor="let heatCompetitor of item?.heat_competitors">
              <p>
                <span class="hide-in-drag">{{ heatCompetitor?.wod?.name }}</span>/
                <span class="hide-in-drag">{{ heatCompetitor?.competitor?.competition?.name }}</span>
              </p>
            </ng-container>
          </ng-template>
        </column>
        <column property="" header="{{'HEAT_HEATCOMPETITORS.HEATS_SCHEDULE.TABLE.DATE' | translate}}" [sortable]="false"
                align="center">
          <ng-template #dataTableCell let-item="item">
              <span class="hide-in-drag">{{ item?.due_date | customDateHeatPipe:'dd/MM/yyyy' }}</span>
          </ng-template>
        </column>
        <column property="" header="{{'HEAT_HEATCOMPETITORS.HEATS_SCHEDULE.TABLE.HOUR' | translate}}" [sortable]="false"
                align="center">
          <ng-template #dataTableCell let-item="item">
            <span class="hide-in-drag">{{ item?.start_time | customDateHeatPipe:'HH:mm':true }}</span>
          </ng-template>
        </column>
      </table-x>
      <div class="mb-16 p-32 pb-16" fxLayoutAlign="flex-end">
        <btn
          [btnLoading]="true"
          fxLayoutAlign="flex-end"
          (click)="onSubmitSave()"
          [loading]="isLoading"
        >
          {{
          "HEAT_HEATCOMPETITORS.HEATS_SCHEDULE.SUBMIT_SCHEDULE" | translate | uppercase
          }}
        </btn>
      </div>
    </div>
  </div>
</div>