export class WodType {
  id: number
  name: string
  unit: string
  order: number

  deserialize(object: any) {
    (<any>Object).assign(this, object);

    return this;
  }
}
