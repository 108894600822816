<button (click)="clickButton()" [ngClass]="{'isLoading': loading, 'btnLoading': btnLoading, 'material': materialIcon, 'small': isSmall}" [ngStyle]="{ 'background-color': loading ? color : backgroundColor, 'color': color, 'width': loading ? '35px' : buttonWidth }"
  class="{{type}}" [disabled]="buttonDisabled" ng-disabled="buttonDisabled">

  <div class="loader" [ngClass]="{'hide': !loading}">
    <svg class="circular" viewBox="25 25 50 50">
      <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="4" stroke-miterlimit="10" [ngStyle]="{ 'stroke': backgroundColor }"
      />
    </svg>
  </div>
  <div [ngClass]="{'hide': loading}" class="btn-container {{ classInside }}">
    <img src="./assets/icons/{{icon}}" *ngIf="icon && (iconAlign =='left' || iconAlign == null)" class="mr-4">
    <mat-icon 
      class="mr-4"
      *ngIf="materialIcon && (iconAlign === 'left' || iconAlign === null)"
      [ngStyle]="{'color': color ? color : 'rgba(0, 0, 0, 0.54)'}">{{ materialIcon }}</mat-icon>
    <ng-content></ng-content>
    <img src="./assets/icons/{{icon}}" *ngIf="icon && (iconAlign =='right')" class="ml-4">
    <mat-icon
      class="mr-4"
      *ngIf="materialIcon && iconAlign === 'right'"
      [ngStyle]="{'color': color ? color : 'rgba(0, 0, 0, 0.54)'}">{{ materialIcon }}</mat-icon>
  </div>

</button>