export const locale = {
  lang: 'pt-BR',
  data: {
    'MODAL_REORDER_ATHLETES': {
      'TITLE': 'Reordenar atletas empatados',
      'TIE': 'Empate ranking {{ rank }}',
      'SAVE': 'Salvar',
      'CANCEL': 'Cancelar',
      'TOASTR': {
        'SUCCESS': {
          'TITLE': 'Atletas ordenados com sucesso'
        },
        'ERROR': {
          'TITLE': 'Falha ao ordenar atletas'
        }
      }
    }
  }
};
    
  
