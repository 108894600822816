import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';

import { RegisterComponent } from './register.component';
import { NgxMaskModule } from 'ngx-mask';

const routes = [
  {
    path     : 'register',
    component: RegisterComponent
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot()
  ],
  declarations: [
    RegisterComponent
  ]
})
export class RegisterModule { }
