<div id="forgot" fxLayout="column" fusePerfectScrollbar>

  <div id="forgot-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <div id="forgot-form" *fuseIfOnDom > <!-- [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}" -->

      <div class="logo">
        <img src="assets/images/logos/brand-crossx.svg">
      </div>

      <h1>{{'FORGOT_PASSWORD.TITLE' | translate}}</h1>

      <form name="forgotForm" [formGroup]="forgotForm" novalidate>

        <mat-form-field>
          <input matInput placeholder="E-mail" formControlName="email" required>
          <mat-error *ngIf="forgotFormErrors.email.required">
            {{'FORGOT_PASSWORD.EMAIL_REQUIRED' | translate}}
          </mat-error>
          <mat-error *ngIf="forgotFormErrors.email.invalid && !forgotFormErrors.email.required">
            {{'FORGOT_PASSWORD.EMAIL_INVALID' | translate}}
          </mat-error>
        </mat-form-field>

        <mat-error class="mb-8" *ngIf="forgotError">
          Usário ou senha inválidos
        </mat-error>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center">
          <btn class="submit-button mr-16" type="grayscale" (click)="gotoLogin()">{{'FORGOT_PASSWORD.BACK' | translate}}</btn>
          <btn [btnLoading]="true" (click)="forgotPassword()" class="submit-button m-0" [buttonDisabled]="forgotForm.invalid" [loading]="loadingButton">{{'FORGOT_PASSWORD.SEND' | translate}}</btn>
        </div>

      </form>
    </div>
  </div>

</div>