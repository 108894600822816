<div class="card auto-width pt-16 mb-16">
  <div fxLayout="column" fxFlex="100">

    <div class="m-32 mt-0 mb-0 title-heat" fxLayoutAlign="space-between center">
      <p fxLayoutAlign="flex-start baseline">
        {{heatName}}

        <ng-container *ngIf="showStartTimeAndEndTime">
          <small>{{heat?.start_time |  customDateHeatPipe:'HH:mm':true}} - {{heat?.end_time |  customDateHeatPipe:'HH:mm':true}}</small>
        </ng-container>
        
      </p>
      <div class="options" fxLayoutAlign="flex-end center">
        <span>
          {{'HEAT_HEATCOMPETITORS.COMPETITOR_DATAGRID.BUSY_LANES' | translate}}
          {{totalItemsOnGrid}}
          {{'HEAT_HEATCOMPETITORS.COMPETITOR_DATAGRID.OF' | translate}}
          {{lanesQuantity}}
        </span>
        <btn-transparent
          icon="common/ic-settings.svg"
          buttonWidth="35px"
          iconAlign="left"
          class="hide-in-drag ml-12"
        ></btn-transparent>
      </div>
    </div>

    <table-x
      #tableCompetition
      [items]="heatCompetitors"
      [config]="tableConfig"
      [isDraggable]="true"
      [tableName]="'DRAGULA_FACTS'"
      (reorderEvent)="reoder($event)"
      [externalId]="externalId"
      [externalIndex]="index"
      [generateDropzones]="generateDropzones"
      [dropzonesQuantity]="lanesQuantity"
      [dropzonesText]="'Arraste o competidor'"
    >

      <column property="" header="" [sortable]="false" align="left" classes="lanes" width="50px">
        <ng-template #dataTableCell>
          <mat-icon class="mr-8 menu-reorder" (click)="onClick($event)">
            menu
          </mat-icon>
        </ng-template>
      </column>

      <column property="index" header="{{'HEAT_HEATCOMPETITORS.TABLE.LANE' | translate}}" [sortable]="false"
              align="left" classes="lanes" width="45px">
        <ng-template #dataTableCell let-item="item" let-item="item" let-index="index" matColumnDef="index">
          <div fxLayout="row" fxLayoutAlign="start center">
            {{index + 1}}
          </div>
        </ng-template>
      </column>

      <column property="" header="{{'HEAT_HEATCOMPETITORS.TABLE.COMPETITOR' | translate}}" [sortable]="false"
              align="left">
        <ng-template #dataTableCell let-item="item">
          <span class="hide-in-drag team-name">{{ item?.team?.name }}</span>
          <span class="hide-in-drag box-name">{{ item?.team?.box?.name }}</span>
        </ng-template>
      </column>

      <!--      <column property="id" header="{{'HEAT_HEATCOMPETITORS.TABLE.ID' | translate}}" [sortable]="false" align="left">-->
      <!--        <ng-template #dataTableCell let-item="item">-->
      <!--          <span class="hide-in-drag">{{ item?.id }}</span>-->
      <!--        </ng-template>-->
      <!--      </column>-->

      <column property="rank" header="{{'HEAT_HEATCOMPETITORS.TABLE.RANK' | translate}}" [sortable]="true" align="left">
        <ng-template #dataTableCell let-item="item">
          <span class="hide-in-drag">{{ item?.rank }}</span>
        </ng-template>
      </column>

      <column property="category" header="{{'HEAT_HEATCOMPETITORS.TABLE.COMPETITION' | translate}}" [sortable]="false"
              align="left">
        <ng-template #dataTableCell let-item="item">
          <span class="hide-in-drag">{{ item?.competition?.name }}</span>
        </ng-template>
      </column>

      <column property="wod" header="{{'HEAT_HEATCOMPETITORS.TABLE.WOD' | translate}}" [sortable]="false" align="left">
        <ng-template #dataTableCell let-item="item">
        <span class="hide-in-drag">
          {{ item?.wod }}
          <img src="./assets/icons/common/ic-info.svg" matTooltip="teste" matTooltipPosition="right" matTooltipClass="right" />
        </span>
        </ng-template>
      </column>

      <column property="" header="" [sortable]="false" align="center" width="100px">
        <ng-template #dataTableCell let-item="item">
          <btn-transparent materialIcon="delete" buttonWidth="35px" iconAlign="left" (click)="remove" class="hide-in-drag"></btn-transparent>
        </ng-template>
      </column>
      
    </table-x>
  </div>
</div>