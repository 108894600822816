<div id="event-list" class="page-layout blank pb-64" fxLayoutAlign="center">
  <!-- <div class="content"> -->
    <div class="header-img"></div>
    <div id="event-list-wrapper" class="mt-64">
      <!-- Manager Event -->
      <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
          <div class="p-32" fxLayoutAlign="space-between start">
            <description-section
              *ngIf="tabsFilter.length > 0"
              title="{{'MYPROFILE.MANAGER_EVENTS.TITLE' | translate}}"
              text="{{'MYPROFILE.MANAGER_EVENTS.DESCRIPTION' | translate}}">
              <mat-icon class="ic-info mr-8" svgIcon="ic-info"></mat-icon>
            </description-section>
            <description-section 
              *ngIf="tabsFilter.length === 0"
              title="{{'MYPROFILE.MY_EVENTS.TITLE' | translate}}" 
              text="{{'MYPROFILE.MY_EVENTS.DESCRIPTION' | translate}}">
              <mat-icon class="ic-info mr-8" svgIcon="ic-info"></mat-icon>
            </description-section>
            <div id="chevron-left" fxLayoutAlign="space-between center" (click)="openMyProfile()">
              <mat-icon class="material-icons">chevron_left</mat-icon>
              <span>Voltar</span>
            </div>
          </div>
          <div class="pt-16">
            <tab-bar
              #tabbar
              [padding]="true"
              (tabEvent)="tabAction($event)"
              [items]="tabsFilter"
              *ngIf="tabsFilter.length > 0"
              [tabActive]="0">
            </tab-bar>
          </div>
          <div class="p-32 pt-16 pb-0" fxLayout="column">
            <div fxLayout="row" fxlayoutalign.xs="column" fxFlex="80" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="50" fxFlex.xs="100" floatLabel="never" class="search">
                <mat-icon matPrefix class="icon-search">search</mat-icon>
                <input 
                  matInput 
                  (input)="searchEvents($event)" 
                  placeholder="{{'MYPROFILE.SEARCH' | translate}}">
              </mat-form-field>
            </div>
          </div>
          <table-x 
            #table 
            [items]="listEvents" 
            [config]="tableConfig" 
            [isLoading]="isLoading" 
            [totalCount]="totalCount" 
            *ngIf="!isEmpty">
            <!-- Manager Event -->
            <column *ngIf="this.type === 'my-event'" property="name" header="{{ 'MYPROFILE.TABLE_NAME' | translate }}" [sortable]="false" align="left">
              <ng-template #dataTableCell let-item="item">
                <a (click)="openEvent(item)" fxLayout="row" fxLayoutAlign="start center">
                  <img src="{{ (item.email_logo) ? item.email_logo : null | placeholderImage:'user' }}" width="80"
                    class="avatar" /> {{ item.name }}
                </a>
              </ng-template>
            </column>
            <column *ngIf="this.type === 'my-event'" property="date" header="{{ 'MYPROFILE.TABLE_DATA' | translate }}" [sortable]="false" align="center">
              <ng-template #dataTableCell let-item="item">
                {{ item.datetime_start ? dateFormat(item.datetime_start) : '' }}
              </ng-template>
            </column>
            <column *ngIf="this.type === 'my-event'" property="status" header="{{ 'MYPROFILE.TABLE_STATUS' | translate }}" [sortable]="false" align="center">
              <ng-template #dataTableCell let-item="item">
                <label-x type="{{ getEventStatus(item, true) }}" *ngIf="item.datetime_start">{{ getEventStatus(item, false) }}</label-x>
              </ng-template>
            </column>
            <!-- MY Events -->
            <column *ngIf="this.type === 'my-event-competitor'" property="name" header="{{ 'MYPROFILE.TABLE_NAME' | translate }}" [sortable]="false" align="left">
              <ng-template #dataTableCell let-item="item">
                <a (click)="openEventCompetitor(item)" fxLayout="row" fxLayoutAlign="start center">
                  <img src="{{ (item.email_logo) ? item.email_logo : null | placeholderImage:'user' }}" width="80" class="avatar" />
                  <span matBadge="&#8288;" matBadgeOverlap="false" matBadgeSize="small" matBadgeColor="warn" [matBadgeHidden]="!getEventBadge(item)">{{ item.name }}</span>
                </a>
              </ng-template>
            </column>
        
            <column *ngIf="this.type === 'my-event-competitor'" property="year" header="{{ 'MYPROFILE.TABLE_YEAR' | translate }}" [sortable]="false" align="center">
              <ng-template #dataTableCell let-item="item">
                {{ item.year }}
              </ng-template>
            </column>
          </table-x>
        </div>
    </div>
  <!-- </div> -->
</div>
<scroll-to-top
  scrollId="event-list"
  [animate]="true"
  [speed]="50"
  [acceleration]="2"
  [scrollDistance]="300"
  (scrollEvent)="scrollEvent($event)"
  [scrollInfinite]="true"
  [scrollRetriveDistance]="400"></scroll-to-top>