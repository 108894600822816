import {Lot} from './lot';
import {Competition} from './competition';
import {Team} from './team';

export class Invitation {
  id: number;
  team_id: number;
  event_id: number;
  competition_id: number;
  lot_id: number;
  name: string;
  status: number;
  lot?: Lot = null;

  constructor() {
  }

  deserialize(input: any) {
    (<any>Object).assign(this, input);

    this.lot = input.lot ? new Lot().deserialize(input.lot) : null;

    return this;
  }
}

export enum InvitationStatus {
  PENDING = 0,
  ACCEPT = 2,
  REFUSED = 3,
  CANCELLED = 5,
}