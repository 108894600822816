export const locale = {
  lang: 'pt-BR',
  data: {
    'FORGOT_PASSWORD': {
      'TITLE': 'Esqueci minha senha',
      'BACK': 'VOLTAR',
      'SEND': 'ENVIAR',
      'EMAIL_REQUIRED': 'E-mail obrigatório',
      'EMAIL_INVALID': 'Formato de e-mail inválido',
    }
  }
};
