import {CustomField} from './custom-field.model';

export class DefaultCustomField {
  id: number;
  type: string;
  subscription_type?: string;
  name: string;
  description?: string | null;
  max_count?: number | null;
  is_required: boolean;
  allow_hidden: boolean;
}

export enum DefaultCustomFieldType {
  EMAIL = 1,
  FIRSTNAME = 2,
  LASTNAME = 3,
  GENDER = 4,
  CPF = 5,
  PHONE = 6,
  BIRTHDATE = 7,
  ADDRESS = 8,
  TSHIRT = 9,
  BOX = 10,
  COUNTRY = 11,
}

export function GetDefaultFieldNotHidden(customField: CustomField): boolean
{
  if (customField.default_custom_field === null) {
    return true;
  } 
  
  const defaultFieldNotHiddenOption: number[] = [
    DefaultCustomFieldType.EMAIL,
    DefaultCustomFieldType.FIRSTNAME,
    DefaultCustomFieldType.LASTNAME,
    DefaultCustomFieldType.GENDER,
  ]
  // @ts-ignore
  return !defaultFieldNotHiddenOption.includes(customField.default_custom_field.id);
}

export class ConfigDefaultInput {
  box: ConfigDefaultInputOption = new ConfigDefaultInputOption();
  address: ConfigDefaultInputOption = new ConfigDefaultInputOption();
  tshirt: ConfigDefaultInputOption = new ConfigDefaultInputOption();
  country: ConfigDefaultInputOption = new ConfigDefaultInputOption();
  cpf: ConfigDefaultInputOption = new ConfigDefaultInputOption();
  phone: ConfigDefaultInputOption = new ConfigDefaultInputOption();
  birthdate: ConfigDefaultInputOption = new ConfigDefaultInputOption();
}

export class ConfigDefaultInputOption {
  required: boolean = false;
  hidden: boolean = false;
}

const defaultNotShow: number[] = [
  DefaultCustomFieldType.BOX,
  DefaultCustomFieldType.ADDRESS,
  DefaultCustomFieldType.TSHIRT,
  DefaultCustomFieldType.COUNTRY,
  DefaultCustomFieldType.CPF,
  DefaultCustomFieldType.PHONE,
  DefaultCustomFieldType.BIRTHDATE,
]