import { Directive, EventEmitter, HostListener, Output, ElementRef, Renderer } from '@angular/core';
@Directive({
  selector: '[uppercase]'
})
export class InputUppercaseDirective {
  value: any;

  constructor(
    private renderer: Renderer,
    private elementRef: ElementRef
  ){}

  @HostListener('input', ['$event']) onInputChange($event) {
    this.value = $event.target.value.toUpperCase();
    this.renderer.setElementProperty(this.elementRef.nativeElement, 'value', this.value);
  }
}