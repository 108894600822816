import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, Subject, Subscription} from 'rxjs';
import {CustomField} from '../model/custom-field.model';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BoxService {

  private subscriptions = new Subscription();
  private _fetchBoxesObservable = new Subject<any>();

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  fetchBoxesObservable(): Observable<any> {
    return this._fetchBoxesObservable.asObservable();
  }

  getListBoxes(name: string): Observable<any>
  {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.get(`public/boxes?query=${name}&only=licensed`, { responseType })
      .map((response: Response) => {
        // console.log(response)
        if (response['data'] !== false) {
          let boxes = response["data"]
          return boxes
        }

        return []
      })
  }

  fetchBoxesWithName(name: string = '', page: number = 1): void {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'public',
      'boxes',
      'list'
    ];

    const queryParams = {
      query: name,
      only: 'licensed',
      page: page
    };

    const url = this.router.createUrlTree(baseUrl, {
      queryParams: queryParams
    });
    const parsedUrl = url.toString().substring(1, url.toString().length);

    const subscription = this.http
      .get(parsedUrl, {responseType})
      .subscribe(response => {
          this._fetchBoxesObservable.next(response);
        },
        err => {
          this._fetchBoxesObservable.error(err);
        });

    this.subscriptions.add(subscription);
  }

}
