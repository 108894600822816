import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CepService {

  constructor(
    private http: HttpClient
  ) { }

  search(cep: string): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `https://api.pagar.me/1/zipcodes/${cep}`

    return this.http.get(url,{ responseType })
      .map((response: Response) => {
        return response
      })
  }
}
