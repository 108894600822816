<div class="navbar-header">

    <button mat-button class="toggle-button-navbar mat-icon-button" fuseNavbarVertical="toggleFold" fxHide.lt-lg>
        <mat-icon>menu</mat-icon>
    </button>

    <button mat-button class="toggle-button-navbar mat-icon-button" fuseNavbarVertical="closeBar" fxHide.gt-md>
        <mat-icon>menu</mat-icon>
    </button>

    <div class="logo">
      <img class="logo-icon" src="assets/images/logos/crossx.svg">
      <span class="logo-text"></span>
    </div>

</div>

<div class="navbar-content" fusePerfectScrollbar>
    <fuse-navigation layout="vertical"></fuse-navigation>
</div>
