<div class="page-layout blank pb-64" id="scrollBar">
  <div class="p-32" fxLayout="column">
    <div class="content">
      <!-- loading -->
      <div class="card auto-width mb-32" *ngIf="isLoadingSubDivision">
        <div class="pt-16" fxLayout="column">
          <div fxLayoutAlign="center center" class="p-64">
            <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
          </div>
        </div>
      </div>

      <div
        class="card auto-width mb-32"
        [ngClass.gt-sm]="'mb-0'"
        *ngIf="!isLoadingSubDivision"
      >
        <div class="pt-16" fxLayout="column">
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="pl-32 pr-32 pb-16 mb-4 mt-0 line-bottom"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              *ngIf="!isEditSubDivisionGroupName"
            >
              <h2 class="m-0 mr-4">
                {{ championship?.sub_division_group_name | uppercase }}
              </h2>
              <btn-transparent
                materialIcon="create"
                buttonWidth="35px"
                iconAlign="left"
                color="#4C6070"
                class="ml-8"
                (click)="
                  isEditSubDivisionGroupName = !isEditSubDivisionGroupName
                "
              ></btn-transparent>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              *ngIf="isEditSubDivisionGroupName"
            >
              <form
                [formGroup]="championshipForm"
                name="championshipForm"
                autocomplete="off"
                novalidate
              >
                <div fxLayout="row" fxLayoutAlign="start start">
                  <mat-form-field fxFlex="auto" class="pr-8">
                    <input
                      matInput
                      placeholder="{{ 'SUB_DIVISION.FORM.NAME' | translate }}"
                      formControlName="sub_division_group_name"
                      [errorStateMatcher]="matchers.sub_division_group_name"
                      uppercase
                      required
                    />
                    <mat-error
                      *ngIf="
                        championshipForm.controls.sub_division_group_name.hasError(
                          'required'
                        )
                      "
                    >
                      {{ "SUB_DIVISION.VALIDATIONS.NAME_REQUIRED" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </form>
              <btn-transparent
                [loading]="isLoadingChampionshipForm"
                materialIcon="done"
                buttonWidth="35px"
                iconAlign="left"
                color="#4C6070"
                class="ml-8"
                (click)="editGroupNameChampionship()"
              ></btn-transparent>
            </div>
            <button
              fxLayout="row"
              fxLayoutAlign="start center"
              (click)="configurationSubDivision()"
              class="button-copy pl-8 pr-8"
            >
              <img src="./assets/icons/common/ic-copy.svg" class="mr-8" />
              {{ "SUB_DIVISION.CONFIGURATION" | translate | uppercase }}
            </button>
          </div>
          <!-- PLACEHOLDER EMPTY SUB_DIVISION -->
          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            class="p-32 emtpy-content"
            *ngIf="isSubDivisionEmpty && !isLoadingSubDivision"
          >
            <img
              src="./assets/icons/sub-division/placeholder-subdivision.svg"
              class="mb-16"
            />
            <span class="mt-8">{{ "SUB_DIVISION.EMPTY" | translate }}</span>
            <btn (click)="createSubDivision()" class="mt-8">{{
              "SUB_DIVISION.ADD" | translate | uppercase
            }}</btn>
          </div>

          <table-x
            #table
            [items]="subDivisions"
            [config]="tableCatConfig"
            [isLoading]="isLoadingSubDivisionTable"
            tableName="sub-division"
            [isDraggable]="true"
            (reorderEvent)="reorderAction($event)"
            *ngIf="!isSubDivisionEmpty && !isLoadingSubDivision"
          >
            <column
              property="name"
              header="{{ 'SUB_DIVISION.TABLE.NAME' | translate }}"
              [sortable]="false"
              align="left"
            >
              <ng-template #dataTableCell let-item="item">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon class="mr-8 menu-reorder">menu</mat-icon>
                  <a (click)="editSubDivision(item)">
                    {{ item.name | uppercase }}
                  </a>
                </div>
              </ng-template>
            </column>

            <column
              property="actions"
              header=""
              [sortable]="false"
              align="center"
              width="100px"
            >
              <ng-template #dataTableCell let-item="item">
                <btn-transparent
                  icon="common/ic-settings.svg"
                  buttonWidth="35px"
                  iconAlign="left"
                  [matMenuTriggerFor]="subDivisionAction"
                  [matMenuTriggerData]="{ subDivision: item }"
                  class="hide-in-drag"
                ></btn-transparent>
              </ng-template>
            </column>
          </table-x>
          <div class="m-32 mt-16 mb-16" *ngIf="subDivisions.length > 0">
            <btn-icon-small
              icon="add_circle_outline"
              iconAlign="left"
              (click)="createSubDivision()"
              >{{ "SUB_DIVISION.TABLE.ADD_NEW" | translate }}</btn-icon-small
            >
          </div>

          <!-- MAT-MENU ACTIONS -->
          <mat-menu
            class="submenu bottom arrow-right"
            #subDivisionAction="matMenu"
            [overlapTrigger]="false"
            xPosition="before"
          >
            <ng-template matMenuContent let-subDivision="subDivision">
              <button
                mat-menu-item
                class="line-bottom"
                (click)="deleteSubDivision(subDivision)"
              >
                <span class="text-red">{{
                  "SUB_DIVISION.TABLE.DELETE" | translate
                }}</span>
              </button>
            </ng-template>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>
