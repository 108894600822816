export const locale = {
  lang: 'pt-BR',
  data: {
    'DEFAULT': {
      'CANCEL': 'Cancelar',
      'SAVE': 'Salvar',
      'SHARE': 'Compartilhar',
      //STATUS
      'ACTIVE': 'Ativo',
      'INACTIVE': 'Inativo',
      'PENDING': 'Pendente',
      'BLOCKED': 'Bloqueado',
      'ABANDONED': 'Abandonou'
    }
  }
}