import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Tag } from './tag';


@Component({
  selector: 'tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {

    @Input() disableClose: boolean = false;
    @Output() tagEvent = new EventEmitter();
    @Input() tag: Tag;
    @Input() index: number;
    
    constructor() { }

    ngOnInit() {

    }

    //TAG
    deleteTag()
    {
        this.tagEvent.emit({
            tag: this.tag,
            index: this.index 
        })
    }

    

}
