import {ResultService} from './../../../../../core/services/result/result.service';
import {Event, EventConfigation} from './../../../../../core/model/event';
import {Competitor} from './../../../../../core/model/competitor';
import {locale as english} from './i18n/en-US';
import {locale as portugues} from './i18n/pt-BR';
import {ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FuseConfigService} from '../../../../../core/services/config.service';
import {EventPageService} from '../../../../../core/services/event-page/event-page.service';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {FuseTranslationLoaderService} from '../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthGuard} from '../../../../../core/guards/auth.guard';
import {Router} from '@angular/router';
import {MatDialog, MatIconRegistry} from '@angular/material';
import {EventsService} from '../../../../../core/services/event/events.service';
import {BreadcrumbsService} from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {UserService} from '../../../../../core/services/user/user.service';
import {SEOService} from '../../../../../core/services/seo-service/seo.service';
import {GooglePlacesService} from '../../../../../core/services/google-places.service';
import {environment} from '../../../../../../environments/environment';
import {Subscription} from 'rxjs';
import {ModalCompetitorComponent} from './modal-competitor/modal-competitor.component';
import {Competition} from '../../../../../core/model/competition';
import {Utils} from '../../../../../core/utils';
import {Championship} from '../../../../../core/model/championship';

@Component({
  selector: 'app-leaderboard-event',
  templateUrl: './leaderboard-event.component.html',
  styleUrls: ['./leaderboard-event.component.scss']
})
export class LeaderboardEventComponent implements OnInit, OnDestroy {

  @ViewChild('divScroll') divScroll: ElementRef;
  // translate
  translate: any;
  event: Event = null;
  championships: any[] = null;
  championship: Championship = null;
  competitionId: number = null;
  enableHeats = false;
  // sub-division
  enableSubDivision = false;
  subDivisionId = 0;
  eventConfigation = EventConfigation;
  // cut-line
  cutLine: number | null = null;
  wods: any[] = [];
  athletes: Competitor[] = [];
  isLastPage = false;
  metaData = {
    page: 1,
    per_page: 70,
    total: 0
  };
  eventConfiguration: any[] = [
    {
      'name': 'leaderboard-background-color',
      'value': '#000000'
    },
    {
      'name': 'leaderboard-dark-color',
      'value': '#2c2c2c'
    },
    {
      'name': 'leaderboard-light-color',
      'value': '#ffffff'
    },
    {
      'name': 'leaderboard-box-name-color',
      'value': '#c6c6c6'
    },
    {
      'name': 'leaderboard-highlight-color',
      'value': '#fff200'
    },
    {
      'name': EventConfigation.LEADERBOARD_CUT_LINE_COLOR,
      'value': '#ff0000'
    }
  ];
  slug: string;
  brandUrl = '';
  isLoading = true;
  isLoadingResults = false;
  isShowBrand = false;
  isLoadingTable = false;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private fuseConfig: FuseConfigService,
    private eventPageService: EventPageService,
    private titleService: Title,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private authGuard: AuthGuard,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private eventsService: EventsService,
    private breadcrumbsService: BreadcrumbsService,
    private eventGuard: EventGuard,
    public dialog: MatDialog,
    public userService: UserService,
    private seoService: SEOService,
    private googlePlacesService: GooglePlacesService,
    private el: ElementRef,
    private zone: NgZone,
    private resultService: ResultService,
    private changeDetectorRefs: ChangeDetectorRef
  ) {

    this.translationLoader.loadTranslations(english, portugues);

    const subscription = this.translateService.get('LEADERBOARD').subscribe((res: any) => {
      this.translate = res;
    });

    this.subscriptions.add(subscription);

    this.fuseConfig.setSettings({
      layout: {
        navigation: 'none',
        toolbar: 'none',      // 'above', 'below', none
        footer: 'none'
      }
    });

    // Get Slug event
    this.slug = this.eventPageService.getSlug();
    if (this.slug === null) {
      this.gotoHome();
    }
  }

  ngOnInit() {
    // {{url}}/public/championships/:slug get event detail
    this.getEventDetail();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getEventDetail() {
    const subscription = this.eventsService.getEventDetailPerSlug(this.slug)
      .subscribe(
        result => {
          this.isLoading = false;
          this.event = new Event().deserialize(result);

          if (result) {
            if (result.event && !result.event.published) {
              window.location.href = environment.dashUrl;
              return;
            } else if (result.event && !result.event.leaderboard_published) {
              window.location.href = '/event';
              return;
            }

            if (result.event.published_heats) {
              this.enableHeats = !!result.event.published_heats;
            }


            this.titleService.setTitle(`${result.event.name} - LEADERBOARD`);

            this.seoService.addMetaTags({
              title: result.event.name,
              image: result.event.image,
              url: `${window.location}`,
            });
            this.brandUrl = result.event.image;

            if (result.configuration) {
              if (result.configuration && result.configuration.length > 0) {
                this.eventConfiguration = result.configuration;
              }
              this.addCSSPresets(this.eventConfiguration);
              this.isShowBrand = this.getPreset(this.eventConfiguration, 'leaderboard-show-brand') === '1';
            }

            if (result.championships && result.championships.length > 0) {
              this.championships = result.championships;
              this.championship = result.championships[0];
              this.enableSubDivision = this.championship.competitions[0].enable_sub_division || false;
              if (this.championship && this.championship.competitions.length > 0) {
                this.getResults(this.championship.competitions[0].id);
              }
            }
          }

        }, err => {
          console.log('err', err);
        }
      );
    this.subscriptions.add(subscription);
  }

  championshipSeleted(event) {
    const championshipId = Number(event.target.value);
    const championship = this.championships.filter(function (champ) {
      return champ.id === championshipId;
    });
    this.cutLine = championship[0].cut_line;
    this.enableSubDivision = false;
    if (championship.length > 0) {
      this.championship = championship[0];
      console.log('aqui');
      if (this.championship.competitions.length > 0) {
        this.enableSubDivision = this.championship.competitions[0].enable_sub_division || false;
      }
      this.getResults(this.championship.competitions[0].id);
    }

    console.log(this.championship);
  }

  competitionSeleted(event) {
    this.competitionId = Number(event.target.value);
    const competitionData = this.championship.competitions.filter((competition: Competition) => competition.id === this.competitionId)[0];
    this.enableSubDivision = competitionData.enable_sub_division;
    this.getResults(this.competitionId);
  }

  public subDivisionSeleted(event: any): void {
    this.subDivisionId = Number(event.target.value);
    this.getResults(this.competitionId);
  }

  getResults(competitionId: number) {
    this.competitionId = competitionId;
    this.isLoadingResults = true;
    this.athletes = [];
    this.metaData.page = 1;
    this.metaData.per_page = 70;
    if (!this.enableSubDivision) {
      this.subDivisionId = 0;
    }
    this.fetchResults();
  }

  scrollEvent(event: any): void {
    if (
      event.type === 'retriveWithScroll' &&
      !this.isLoadingTable &&
      !this.isLastPage &&
      window.innerHeight + this.divScroll.nativeElement.scrollTop >= this.divScroll.nativeElement.scrollHeight - 100
    ) {
      this.isLoadingTable = true;
      this.metaData.page++;
      this.changeDetectorRefs.detectChanges();
      this.fetchResults();
    }
  }

  getResultLine(indexWod: number, athlete: any): any {
    const wod = this.wods[indexWod];
    if (athlete.results && athlete.results.length > 0) {
      const result = athlete.results.filter(function (result) {
        return result.wod_id === Number(wod.id);
      });
      if (result[0]) {
        result[0]['tiebreak_type_id'] = wod.tiebreak_type_id ? wod.tiebreak_type_id : null;
      }

      // console.log(result);
      return (result.length > 0 && result[0].result !== null) ? result[0] : {
        result: '',
        tiebreak: '',
        unit: '',
        total: null,
        points: null,
        wod: null,
        tiebreak_type_id: null
      };
    }

    return {rank: '', result: '', tiebreak: '', unit: '', total: null, points: null, wod: null, tiebreak_type_id: null};
  }

  getBox(athlete: any): string {
    if (athlete.team && athlete.team.box) {
      return athlete.team.box;
    }
    return null;

    /*if (athlete.team && athlete.team.type === 'team' && athlete.team.box) {
      return athlete.team.box
    }
    else if (athlete.team && athlete.team.type === 'single' && athlete.team.members && athlete.team.members.length > 0 && athlete.team.members[0].box) {
      return athlete.team.members[0].box.name
    }

    return null*/
  }

  showTeamMembers(athlete: Competitor) {
    const modalRef = this.dialog.open(ModalCompetitorComponent, {
      width: '530px',
      height: '100%',
      panelClass: 'modal'
    });

    modalRef.componentInstance.competitor = athlete;
    modalRef.componentInstance.wods = this.wods;
    modalRef.componentInstance.event = this.event;
    modalRef.componentInstance.subDivisionId = this.subDivisionId;
    modalRef.componentInstance.enableHeats = this.enableHeats;
    // if (this.enableHeats) {
    //   if (athlete.sub_division_id) {
    //     modalRef.componentInstance.enableHeats = !!this.subDivisionId;
    //   }
    // }

    /**
     * find competitor_id = results -> wods
     * verificar se é por pontos ou rank, é o type do championship
     *
     * heat_competitors competitor_id
     */
    // this.athletes.forEach((a: Competitor) => {
    //   if (a.id === athlete.id) {
    //     modalRef.componentInstance.competitor = a;
    //     modalRef.componentInstance.wods = this.wods;
    //     // a.open = !a.open;
    //   }
    // });

    // if (athlete.team.type === 'team') {
    // }
  }

  public unitName(id: number, isAbbreviation = true): string {
    if (!id) {
      return '';
    }
    if (id === 2) {
      return '';
    }
    return Utils.getUnitName(id, isAbbreviation);
  }

  addCSSPresets(configurations) {
    const body = document.getElementById('background-leaderboard') as HTMLElement;
    body.style.background = this.getPreset(configurations, 'leaderboard-background-color');
    body.style.color = this.getPreset(configurations, 'leaderboard-light-color');

    // console.log(this.getPreset(configurations, 'leaderboard-light-color'))

    const h1 = document.getElementsByTagName('h1') as HTMLCollectionOf<HTMLHeadingElement>;
    if (h1.length > 0) {
      for (let index = 0; index < h1.length; index++) {
        const element = h1[index];
        element.style.color = this.getPreset(configurations, 'leaderboard-light-color');
      }
    }

    const h2 = document.getElementsByTagName('h2') as HTMLCollectionOf<HTMLHeadingElement>;
    if (h2.length > 0) {
      for (let index = 0; index < h2.length; index++) {
        const element = h2[index];
        element.style.color = this.getPreset(configurations, 'leaderboard-light-color');
      }
    }

    const selects = document.getElementsByTagName('select');
    for (let index = 0; index < selects.length; index++) {
      const element = selects[index];
      element.style.color = this.getPreset(configurations, 'leaderboard-dark-color');
    }


    const labels = document.getElementsByTagName('label');
    for (let index = 0; index < labels.length; index++) {
      const element = labels[index];
      element.style.color = this.getPreset(configurations, 'leaderboard-light-color');
    }

    (document.getElementById('refresh') as HTMLElement).style.color = this.getPreset(configurations, 'leaderboard-light-color');


    // $('#leaderboard table[data-type-user="team"] td.name-user a').css('color', APP.getPreset(configurations, 'leaderboard-light-color'));
    // APP.addCSSChangePresets(configurations);
    // $('section#leaderboard').css('background', APP.getPreset(configurations, 'leaderboard-background-color'));
    // $('section#leaderboard').css('color', APP.getPreset(configurations, 'leaderboard-light-color'));
  }

  addCSSChangePresets(configurations) {
    const table = (document.getElementsByTagName('table') as HTMLCollectionOf<HTMLTableElement>);
    if (table.length > 0) {
      const boxNames = table[0].getElementsByClassName('box-name') as HTMLCollectionOf<HTMLElement>;
      for (let index = 0; index < boxNames.length; index++) {
        const element = boxNames[index];
        element.style.color = this.getPreset(configurations, 'leaderboard-box-name-color');
      }

      const highlight = table[0].getElementsByClassName('highlight') as HTMLCollectionOf<HTMLElement>;
      for (let index = 0; index < highlight.length; index++) {
        const element = highlight[index];
        element.style.color = this.getPreset(configurations, 'leaderboard-highlight-color');
      }

      /*let nameUserWd = table[0].getElementsByClassName('wd') as HTMLCollectionOf<HTMLElement>
      for (let index = 0; index < nameUserWd.length; index++) {
        const element = nameUserWd[index];
        element.style.background = this.getPreset(configurations, 'leaderboard-wd-background-color')
        element.style.color = this.getPreset(configurations, 'leaderboard-dark-color')
      }*/

      // var tiebreak = document.getElementsByClassName('tiebreak') as HTMLCollectionOf<HTMLElement>
      // console.log('tiebreak', tiebreak, tiebreak.length)
      // for (let indexTie = 0; indexTie < tiebreak.length; indexTie++) {
      //   const element = tiebreak[indexTie];
      //   element.style.color = `${this.getPreset(configurations, 'leaderboard-dark-color')} !important`
      //   console.log('color', `${this.getPreset(configurations, 'leaderboard-dark-color')} !important`)
      // }

    }

    // $('#leaderboard table tbody tr:nth-child(odd)').css('background-color', APP.getPreset(configurations, 'leaderboard-dark-color'));
  }

  getPreset(presets, name): string {
    let preset = presets.filter(function (configuration) {
      return configuration.name === name;
    });

    if (preset.length === 0) {
      preset = this.eventConfiguration.filter(function (configuration) {
        return configuration.name === name;
      });
    }

    // console.log(preset)

    return (preset.length > 0) ? preset[0].value : '#000';
  }

  showTiebreak(element) {

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
    if (isMobile) {
      const table = (document.getElementsByTagName('table') as HTMLCollectionOf<HTMLTableElement>);
      if (table.length > 0) {
        const tiebreaks = table[0].getElementsByClassName('tiebreak') as HTMLCollectionOf<HTMLElement>;
        for (let index = 0; index < tiebreaks.length; index++) {
          const element = tiebreaks[index];
          element.style.display = 'none';
        }
      }

      let tiebreakShow = element.target.getElementsByClassName('tiebreak') as HTMLCollectionOf<HTMLElement>;
      if (tiebreakShow.length === 0) {
        tiebreakShow = element.target.parentNode.getElementsByClassName('tiebreak') as HTMLCollectionOf<HTMLElement>;
      }
      for (let index = 0; index < tiebreakShow.length; index++) {
        const element = tiebreakShow[index];
        element.style.display = 'inline-block';
      }
    }
  }

  gotoHome() {
    window.location.href = environment.dashUrl;
  }

  private fetchResults(): void {
    this.resultService
      .getPublicResults(this.championship.id, this.competitionId, this.metaData.per_page, this.metaData.page, this.subDivisionId)
      .toPromise()
      .then(
        result => {
          this.isLastPage = false;

          const competition = this.championship.competitions.filter((competitionFilter: Competition) => competitionFilter.id === this.competitionId)[0];
          this.cutLine = null;
          if (competition) {
            this.cutLine = competition.cut_line;
          }
          if (this.subDivisionId !== 0) {
            this.cutLine = null;
            const subDivisionLimit = competition.sub_division_limits.filter((subDivisionLimitFilter) => subDivisionLimitFilter.sub_division_id === this.subDivisionId)[0];
            if (subDivisionLimit) {
              this.cutLine = subDivisionLimit.cut_line;
            }
          }

          if (result) {
            this.wods = result.wods;
            if (this.metaData.page === 1) {
              this.athletes = result.athletes.data.map((competitor: Competitor) => new Competitor().deserialize(competitor));
            } else {
              result.athletes.data.map((competitor: Competitor) => this.athletes.push(new Competitor().deserialize(competitor)));
            }

            this.addCSSChangePresets(this.eventConfiguration);
          }
          
          if (result.athletes.current_page >= result.athletes.last_page) {
            this.isLastPage = true;
          }

          this.isLoadingResults = false;
          this.isLoadingTable = false;

          this.changeDetectorRefs.detectChanges();
        }
      )
      .catch(err => {
        this.isLoadingResults = false;
      });
  }

  public chooseNameColor(name): string
  {
    const arrayNames = [
      'DANIEL COUTINHO RODRIGUES', 'LUCAS ALMEIDA', 'GUILHERME DOMINGUES', 'DAVID DE SOUSA', 'EVALDO DA SILVA CORREA JUNIOR', 'EDUARDO PROENCA',
      'AMANDA ANDRADE', 'DILUANA RAQUEL ZANTUT', 'GIOVANNA MAGNANI', 'SABRINA BEDIN', 'FRANCIELLY NASCIMENTO', 'ISADORA MORENO MARQUES', 'LUIZ HENRIQUE ALVES MOREIRA',
      'ANDERON PRIMO', 'GABRIEL OLIVEIRA', 'CAKES MARTINEZ', 'CAROLINE RIBEIRO FERREIRA', 'BRUNA BAXHIX', 'MATHEUS OLIVEIRA', 'CHRISTIAN KHALED DE ANDRADE', 'ADRIANO PONTE',
      'MARIA LÍVIA NASCIMENTO', 'PRISCILA HORVATH', 'MARINA ZAGO', 'VINICIUS KLAUS STOELBEN', 'VICTOR AMANCIO', 'LUCAS DE OLIVEIRA', 'GABRIELLA LUQUE', 'JOICE DEODATO', 
      'ALINE SANEFUJI', 'PEDRO MARTINS', 'PABLO CHALFUN', 'CAIO NASCIMENTO', 'NATHALIA MENCARI', 'MARCELLA ALONSO', 'GIULIA CIABOTTI',
      'BERNARDO TORQUATO COSTA', 'JOAO VICTOR AQUINO', 'LUCIANO ROCHA SOUZA', 'GABRIELA MARRAFON', 'LETÍCIA ARAGÃO', 'ALINE CABRAL'
    ];
    
    if (this.championship.id === 760 && arrayNames.indexOf(name.toUpperCase()) !== -1) {
      return '#4BFFFF';  
    }

    return '#FFF';
  }
  
  // public chooseNameColor(pos): string
  // {
  //   return this.championship.id === 239 && (this.competitionId === 1569 || this.competitionId === 1570) && pos >= 1 && pos <= 9 ? '#4BFFFF' : '#FFF';
  // }

}
