
export const environment = {
  serverUrl: 'https://api-dev.crossx.com.br/api',
  dashUrl: 'https://campeonatos.dev-campeonatos.crossx.com.br',
  hostDash: 'campeonatos',
  domain: 'dev-campeonatos.crossx.com.br',
  pagarme_encryption_key: 'ek_test_alBXDlO79eTuK8Zq523KWVTfHpaWqg',
  googleMapsApiKey: 'AIzaSyDJmwupyxfGeqgin5odLadErGa6S6rTlhI',
  type: 'staging',
  production: false,
  hmr: false
};
