<div class="page-layout blank pb-64" id="scrollBar">
  <div class="p-32">
    <div class="content">
      <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
        <!--<div class="pt-16">
          <tab-bar
            #tabbar
            [padding]="true"
            (tabEvent)="tabAction($event)"
            [items]="tabsFilter"
            [tabActive]="0">
          </tab-bar>
        </div>-->

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start" fxLayoutAlign.xs="start stretch" class="pl-32 pr-32 pt-16 pb-12 mb-16 search line-bottom">
          <div fxLayout="row" fxLayout.xs="column" fxFlex="80" fxLayoutAlign="space-between start" fxLayoutAlign.xs="start stretch">
            <mat-form-field fxFlex="50" fxFlex.xs="100" floatLabel="never" class="search pr-8">
              <mat-icon matPrefix class="icon-search">search</mat-icon>
              <input
                matInput
                placeholder="{{ 'SUBSCRIPTIONS.SEARCH' | translate }}"
                [(ngModel)]="searchTerm"
                (ngModelChange)="searchName($event)">
            </mat-form-field>

            <mat-form-field fxFlex="50" fxFlex.xs="100" class="competitions pl-8">
              <mat-select placeholder="{{ 'SUBSCRIPTIONS.CHAMPIONSHIPS' | translate }}" [value]="0" (selectionChange)="championshipSelect($event)">
                <mat-option [value]="0">
                  {{ 'SUBSCRIPTIONS.ALL' | translate | uppercase }}
                </mat-option>

                <mat-option *ngFor="let championship of championships" [value]="championship.id">
                  {{ championship.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <btn [btnLoading]="true" [loading]='isLoadingExport' type="grayscale" icon="content/ic-export.svg" iconAlign="left" (click)="exportReport()" *ngIf="!isEmptyResult" fxHide.xs>{{ 'SUBSCRIPTIONS.EXPORT' | translate}}</btn>
        </div>

        <!-- Empty list -->
        <div fxLayout="column" fxLayoutAlign="center center" class="p-32 emtpy-content" *ngIf="isEmptyResult">
          <img src="./assets/icons/placeholder/ic-empty.svg" />
          <span class="mt-8">{{ 'SUBSCRIPTIONS.EMPTY' | translate }}</span>
        </div>

        <!-- Table -->
        <table-x #table [items]="subscriptions" [config]="tableConfig" [isLoading]="isLoading" *ngIf="!isEmptyResult"
                 (sortEvent)="sortAction($event)">

          <column property="users.name" header="{{ 'SUBSCRIPTIONS.TABLE_HEADER.BUYER' | translate }}" [sortable]="false" align="left" classesBody="pt-20 pb-20">
            <ng-template #dataTableCell let-item="item" let-index="index">
              <a fxLayout="row" fxLayoutAlign="start center" class="small-font">
                <img src="{{ (item.user_avatar) ? item.user_avatar : null | placeholderImage:'user' }}" width="80" class="avatar corner-radius" />
                <span fxLayout="column">
                  {{ (item.user_name) ? item.user_name : '' }}
                </span>
              </a>
            </ng-template>
          </column>

          <column property="championships.name" header="{{ 'SUBSCRIPTIONS.TABLE_HEADER.CHAMPIONSHIP' | translate }}" [sortable]="false" align="left" classesBody="pt-20 pb-20">
            <ng-template #dataTableCell let-item="item" let-index="index">
              {{ item.championship_name }}
            </ng-template>
          </column>
          
          <column property="lots.name" header="{{ 'SUBSCRIPTIONS.TABLE_HEADER.LOT' | translate }}" [sortable]="false" align="left" classesBody="pt-20 pb-20">
            <ng-template #dataTableCell let-item="item" let-index="index">
              {{ item.lot_name }}
            </ng-template>
          </column>

          <column property="subscription_payments.receive_date" header="{{ 'SUBSCRIPTIONS.TABLE_HEADER.DATE_PAYMENT' | translate }}" [sortable]="false" align="center" classesBody="pt-20 pb-20">
            <ng-template #dataTableCell let-item="item" let-index="index">
              {{ item.payment_date ? (item.payment_date | date:translate.DATE_FORMAT) : '-' }}
            </ng-template>
          </column>

          <column property="subscription_payments.payment_date" header="{{ 'SUBSCRIPTIONS.TABLE_HEADER.DATE_RECEIVE' | translate }}" [sortable]="false" align="center" classesBody="pt-20 pb-20">
            <ng-template #dataTableCell let-item="item" let-index="index">
              {{ item.receive_date ? (item.receive_date | date:translate.DATE_FORMAT) : '-' }}
            </ng-template>
          </column>

          <column property="subscription_payments.payment_method" header="{{ 'SUBSCRIPTIONS.TABLE_HEADER.PAYMENT_METHOD' | translate }}" [sortable]="false" align="left" classesBody="pt-20 pb-20">
            <ng-template #dataTableCell let-item="item" let-index="index">
              {{ ( item.payment_method === 'boleto' ? 'SUBSCRIPTIONS.PAYMENT_METHOD.BOLETO' : 'SUBSCRIPTIONS.PAYMENT_METHOD.CREDIT_CARD' ) | translate }}
            </ng-template>
          </column>
          
          <column property="coupons.name" header="{{ 'SUBSCRIPTIONS.TABLE_HEADER.COUPON' | translate }}" [sortable]="false" align="center" classesBody="pt-20 pb-20">
            <ng-template #dataTableCell let-item="item" let-index="index">
              {{ item.cupon }}
              <!--<span *ngIf="item.coupon && item.coupon.type === 0">{{ item.coupon.value }}%</span>-->
              <!--<span *ngIf="item.coupon && item.coupon.type === 1">{{ formatCurrency((item.coupon.value | currency:('SUBSCRIPTIONS.CURRENCY_CODE' | translate):'symbol-narrow':'1.2-2'), ('SUBSCRIPTIONS.CURRENCY_CODE' | translate)) }}</span>-->
            </ng-template>
          </column>
          
          <column property="subscription_payments.gross_value" header="{{ 'SUBSCRIPTIONS.TABLE_HEADER.GROSS_VALUE' | translate }}" [sortable]="false" align="left" classesBody="pt-20 pb-20">
            <ng-template #dataTableCell let-item="item" let-index="index">
              {{ item.gross_value ? formatCurrency((item.gross_value | currency:translate.CURRENCY_CODE:'symbol-narrow':'1.2-2'), translate.CURRENCY_CODE) : '-' }}
            </ng-template>
          </column>
          
          <column property="subscription_payments.tax_value" header="{{ 'SUBSCRIPTIONS.TABLE_HEADER.TAX' | translate }}" [sortable]="false" align="left" classesBody="pt-20 pb-20">
            <ng-template #dataTableCell let-item="item" let-index="index">
              <span class="text-red">{{ item.tax_value ? formatCurrency(('-'+item.tax_value | currency:translate.CURRENCY_CODE:'symbol-narrow':'1.2-2'), translate.CURRENCY_CODE) : '-' }}</span>
            </ng-template>
          </column>
          
          <column property="subscription_payments.net_value" header="{{ 'SUBSCRIPTIONS.TABLE_HEADER.NET_VALUE' | translate }}" [sortable]="false" align="left" classesBody="pt-20 pb-20">
            <ng-template #dataTableCell let-item="item" let-index="index">
              {{ item.net_value ? formatCurrency((item.net_value | currency:translate.CURRENCY_CODE:'symbol-narrow':'1.2-2'), translate.CURRENCY_CODE) : '-' }}
            </ng-template>
          </column>
          
          <column property="subscription_payments.payment_id" header="{{ 'SUBSCRIPTIONS.TABLE_HEADER.TRANSACTION_ID' | translate }}" [sortable]="false" align="left" classesBody="pt-20 pb-20">
            <ng-template #dataTableCell let-item="item" let-index="index">
              {{ item.transaction_id ||'-'  }}
            </ng-template>
          </column>
          
          <column property="subscription_payments.status" header="{{ 'SUBSCRIPTIONS.TABLE_HEADER.STATUS' | translate }}" [sortable]="false" align="left" classesBody="pt-20 pb-20">
            <ng-template #dataTableCell let-item="item" let-index="index">
              <label-x *ngIf="!item.status" class="mr-8" type="success">{{ 'Aprovada' | uppercase }}</label-x>
              <label-x *ngIf="item.status === 6 && item.status" class="mr-8" backgroundColor="#B7425C" color="#FFF">{{ item.status_description | uppercase }}</label-x>
              <label-x *ngIf="item.status !== 6 && item.status" class="mr-8" type="{{ getStatusType(item.status) }}">{{ item.status_description | uppercase }}</label-x>
            </ng-template>
          </column>

           <column property="action" header="" [sortable]="false" align="center" width="80px">
            <ng-template #dataTableCell let-item="item">
              <btn-transparent icon="common/ic-settings.svg" [matMenuTriggerFor]="subscriptionsActionMenu" [matMenuTriggerData]="{subscription: item, index: index}" buttonWidth="35px" iconAlign="left" *ngIf="item.status !== 4 && item.payment_method === 'credit_card' && item.status !== 6"></btn-transparent>
            </ng-template>
          </column>
        </table-x>

        <!-- MAT-MENU ACTIONS -->
        <mat-menu class="submenu bottom arrow-right" #subscriptionsActionMenu="matMenu" [overlapTrigger]="false" xPosition="before">
          <ng-template matMenuContent let-subscription="subscription" let-index="index">
            <button mat-menu-item class="line-bottom" (click)="resendEmailConfirmed(subscription, index)" *ngIf="subscription.status === 2">
              <span>{{ 'SUBSCRIPTIONS.CONFIRMED_EMAIL' | translate }}</span>
            </button>
            
            <button mat-menu-item class="line-bottom" (click)="refund(subscription, index)">
              <span class="text-red">{{ 'SUBSCRIPTIONS.REFUND' | translate }}</span>
            </button>
          </ng-template>
        </mat-menu>
      </div>
    </div>
  </div>
</div>


<scroll-to-top
  scrollId="scrollBar"
  [animate]="true"
  [speed]="50"
  [acceleration]="2"
  [scrollDistance]="300"
  (scrollEvent)="scrollEvent($event)"
  [scrollInfinite]="true"
  [scrollRetriveDistance]="400"></scroll-to-top>