<div mat-dialog-content class="dialog-container">
  <div class="modal-background" (click)="dialogRef.close()"></div>
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>{{ 'RESULT_COMMENT.TITLE' | translate | uppercase }}</h1>
      <span class="close" (click)="dialogRef.close()">
        <img alt="close" src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>
    
    <h3 class="my-0 mb-12">Atleta: {{ result?.name }}</h3>

    <form name="commentForm" [formGroup]="commentForm" (submit)="submitFormComment()" autocomplete="off" novalidate fxLayout="column">
      <mat-form-field>
        <textarea matInput placeholder="{{ 'RESULT_COMMENT.FORM.FIELDS.COMMENT' | translate }}" rows="6" formControlName="description"></textarea>
      </mat-form-field>
      
      <div fxFlex="column" class="text-red" *ngIf="errorMessage">
        {{ errorMessage }}
      </div>

      <div class="mb-0 mt-8" fxLayoutAlign="end center">
        <btn typeButton="button" ngClass="mr-8" type="grayscale" (click)="dialogRef.close(null)">
          {{ 'RESULT_COMMENT.FORM.BUTTONS.CANCEL' | translate | uppercase }}
        </btn>
        <btn [loading]="isLoading" [buttonDisabled]="commentForm.invalid" >
          {{ 'RESULT_COMMENT.FORM.BUTTONS.SUBMIT' | translate | uppercase }}
        </btn>
      </div>
    </form>
  </div>
</div>