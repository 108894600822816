import { Lot } from './../../model/lot';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthGuard } from '../../guards/auth.guard';
import { Observable } from 'rxjs';
import { Competition } from '../../model/competition';
import {CustomField} from '../../model/custom-field.model';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LotService {

  constructor(
    private http: HttpClient,
    private authGuard: AuthGuard,
    private router: Router,
  ) { }

  fetchLotWithoutCompetition(eventId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'lot',
      'with-event'
    ];

    const queryParams = {
      event_id: eventId
    };
    
    const url = this.router.createUrlTree(baseUrl, {
        queryParams: queryParams
      }
    );
    const parsedUrl = url.toString().substring(1, url.toString().length);

    return this.http.get(parsedUrl, { responseType })
      .map((response: Response) => {
        // console.log(response)
        return (response as any).map((result: Lot) => new Lot().deserialize(result))
      })
  }

  createLot(lot: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.post(`admin/lot`, lot, { responseType })
      .map((response: Response) => {
        // console.log(response)
        return new Lot().deserialize(response as any)
      })
  }

  updateLot(lot: any, lotId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.put(`admin/lot/${lotId}`, lot, { responseType })
      .map((response: Response) => {
        // console.log(response)
        return new Lot().deserialize(response as any)
      })
  }

  massUpdate(parameters: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.post(`admin/lot/mass-update`, parameters, { responseType })
      .map((response: Response) => {
        // console.log(response)
        return (response as any).map((result: Lot) => new Lot().deserialize(result))
      })
  }

  deleteLot(lotId: number) {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/lot/${lotId}`

    return this.http.delete(url, { responseType })
      .map((response: Response) => {
        console.log(response)
        let results = response as any
        return results
      })
  }

  copyLots(parameters: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.post(`admin/lot/duplicate`, parameters, { responseType })
      .map((response: Response) => {
        console.log(response)
        return (response as any).map((result: Competition) => new Competition().deserialize(result))
        // return new Lot().deserialize(response as any)
      })
  }
  
}
