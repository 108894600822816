export const locale = {
  lang: 'en-US',
  data: {
    'ERROR': {
      'TITLE': 'Erro inesperado!!!',
      'DESCRIPTION': 'Desculpe, ocorreu um erro inesperado no servidor.',
      'BACK': 'Clique aqui para voltar'
    }
  }
};
