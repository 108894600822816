import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatDialogRef} from '@angular/material';
import {Competitor} from '../../../../../../core/model/competitor';
import {Wod} from '../../../../../../core/model/wod';
import {HeatCompetitorsHttpService} from '../../../../../../core/services/heat-competitors/heat-competitors-http.service';
import {HeatCompetitor} from '../../../../../../core/model/heat-competitors';
import {TabBarComponent} from '../../../../../../core/components/tab-bar/tab-bar.component';
import {FuseTranslationLoaderService} from '../../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {locale as english} from './i18n/en-US';
import {locale as portugues} from './i18n/pt-BR';
import {TABS} from './enum/tabs';
import {Result} from '../../../../../../core/model/result';
import {TeamService} from '../../../../../../core/services/team/team.service';
import {Team} from '../../../../../../core/model/team';
import {Event} from '../../../../../../core/model/event';
import * as moment from 'moment';
import {Utils} from '../../../../../../core/utils';

@Component({
  selector: 'app-modal-competitor',
  templateUrl: './modal-competitor.component.html',
  styleUrls: ['./modal-competitor.component.scss'],
})
export class ModalCompetitorComponent implements OnInit, OnDestroy {
  @ViewChild('tabbar', {read: TabBarComponent}) tabBar: TabBarComponent;
  public event: Event = null;
  public wods: Wod[];
  public competitor: Competitor;
  public enableHeats = true;
  public subDivisionId = 0;
  heatCompetitors: HeatCompetitor[] = null;
  team: Team = null;
  isLoadingHeats = false;
  isLoadingTeam = false;
  tabsFilter = [];
  tabActive: string;
  TABS = TABS;
  title: string;
  results = [];
  private readonly subscriptions = new Subscription();
  private tabsOptions = [];
  private translate: any;

  constructor(
    public dialogRef: MatDialogRef<ModalCompetitorComponent>,
    private heatCompetitorsService: HeatCompetitorsHttpService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private teamService: TeamService
  ) {
    this.registerLanguage();
    this.registerHeatCompetitorsObservable();
    this.registerFindCompetitorObservable();
  }

  // @ts-ignore
  public get showSchedule(): boolean {
    return this.tabActive === TABS.SCHEDULE;
  }

  // @ts-ignore
  public get showTeam(): boolean {
    return this.tabActive === TABS.TEAM_MATES && this.team !== null;
  }

  // @ts-ignore
  public get showRank(): boolean {
    return this.tabActive === TABS.RANK;
  }

  // public getTypes(): string {

  // }

  ngOnInit() {
    this.registerTabs();
    this.resultUser();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public tabAction(event: any): void {
    this.tabActive = this.tabsOptions[event.index].type;
    if (this.tabActive === TABS.TEAM_MATES && !this.team) {
      this.findTeam();
    } else if (this.tabActive === TABS.SCHEDULE && this.heatCompetitors === null) {
      this.findHeatCompetitor();
    }
  }

  private registerFindCompetitorObservable(): void {
    this.teamService
      .teamObservable()
      .subscribe((team: Team) => {
        this.team = team;
        this.isLoadingTeam = false;
      });
  }

  private resultUser(): void {
    this.competitor
      .results
      .forEach((result: Result) => {
        const wodData = this.wods.filter((data: Wod) => {
          return data.id === result.wod_id;
        })[0];
        const resultUser = {
          name: wodData.name,
          rank: !this.subDivisionId ? result.rank : result.sub_division_rank,
          result: `${result.result} ${wodData.wod_type.id && wodData.wod_type.id !== 2 ? Utils.getUnitName(wodData.wod_type.id, true) : ''}`,
          points: result.points,
          tiebreak: result.tiebreak,
          status: Number(result.status)
        };
        if (wodData.tiebreak_type_id) {
          resultUser['tiebreak'] = `${result.tiebreak} ${wodData.tiebreak_type_id !== 2 ? Utils.getUnitName(wodData.tiebreak_type_id, true) : ''}`;
        }
        if (resultUser.status !== 2) {
          this.results.push(resultUser);
        }
      });
  }

  private registerLanguage(): void {
    this.translationLoader.loadTranslations(english, portugues);
    this.translateService.get('MODAL_COMPETITOR')
      .subscribe((res: any) => {
        this.translate = res;
      });
  }

  private registerTabs(): void {
    this.tabsOptions = [
      {
        name: this.translate.TABS.RANK,
        type: TABS.RANK
      },
    ];

    if (this.event.enabled_heats) {
      if (this.enableHeats) {
        this.tabsOptions
          .push({
            name: this.translate.TABS.SCHEDULE,
            type: TABS.SCHEDULE
          });
      }
    }

    if (this.competitor.team.type === TABS.TEAM) {
      this.tabsOptions
        .push({
          name: this.translate.TABS.TEAM_MATES,
          type: TABS.TEAM_MATES
        });
    }
    this.tabsOptions
      .forEach((tab) => {
        this.tabsFilter.push(tab.name);
        if (!this.tabActive) {
          return this.tabActive = tab.type;
        }
      });
  }

  private registerHeatCompetitorsObservable(): void {
    const subscription = this.heatCompetitorsService
      .getHeatCompetitorObservable()
      .subscribe((heatCompetitors: HeatCompetitor[]) => {
        console.log('-----------------AQUI-----------------');
        console.log(heatCompetitors);
        console.log('-----------------AQUI-----------------');
        this.heatCompetitors = heatCompetitors;
        this.heatCompetitors.forEach((heatCompetitor, index: number) => {
          if (heatCompetitor.heat.start_time) {
            const now = moment().format('YYYY-MM-DD');
            this.heatCompetitors[index].heat.start_time = moment(`${now} ${heatCompetitor.heat.start_time}`, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
          }
        });
        this.isLoadingHeats = false;
      });
    this.subscriptions.add(subscription);
  }

  private findHeatCompetitor(): void {
    this.isLoadingHeats = true;
    this.heatCompetitorsService
      .fetchHeatCompetitorObservable(this.competitor.id);
  }

  private findTeam(): void {
    this.isLoadingTeam = true;
    this.teamService
      .fetchTeam(this.competitor.team_id);
  }

}
