<div mat-dialog-content class="dialog-container">
  <!-- <div class="modal-background" (click)="dialogRef.close()"></div> -->
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>
        {{
          subDivision
            ? ("MODAL_CREATE_SUB_DIVISION.UPDATE.TITLE" | translate | uppercase)
            : ("MODAL_CREATE_SUB_DIVISION.CREATE.TITLE" | translate | uppercase)
        }}
      </h1>
      <span class="close" (click)="dialogRef.close(null)">
        <img src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>

    <form
      [formGroup]="subDivisionForm"
      name="subDivisionForm"
      autocomplete="off"
      novalidate
    >
      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field fxFlex="100" fxFlex.xs="100" class="pr-8">
          <input
            matInput
            placeholder="{{
              'MODAL_CREATE_SUB_DIVISION.FORM.NAME' | translate
            }}"
            formControlName="name"
            [errorStateMatcher]="matchers.name"
            uppercase
            required
          />
          <mat-error *ngIf="subDivisionForm.controls.name.hasError('required')">
            {{
              "MODAL_CREATE_SUB_DIVISION.VALIDATIONS.NAME_REQUIRED" | translate
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>

    <div class="mt-16 mb-8 text-red" *ngIf="requestErrors">
      {{ requestErrors }}
    </div>

    <div class="mb-0 mt-8" fxLayoutAlign="end center">
      <btn type="grayscale" (click)="dialogRef.close(null)" class="mr-16">{{
        "MODAL_CREATE_SUB_DIVISION.FORM.CANCEL" | translate | uppercase
      }}</btn>
      <btn
        [btnLoading]="true"
        [loading]="isLoading"
        [buttonDisabled]="subDivisionForm.invalid"
        (click)="subDivision ? updateSubDivision() : createSubDivision()"
        >{{
          subDivision
            ? ("MODAL_CREATE_SUB_DIVISION.FORM.UPDATE" | translate | uppercase)
            : ("MODAL_CREATE_SUB_DIVISION.FORM.CREATE" | translate | uppercase)
        }}</btn
      >
    </div>
  </div>
</div>
