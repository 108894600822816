import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../../core/services/translation-loader.service';
import { locale as portugues } from '../i18n/pt-BR';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HeatsService } from '../services/heats.service';
import { LanesHeatsQuantity } from '../model/lanes-heats-quantity.model';
import { HeatsHttpService } from '../../../../../../core/services/heats/heats-http.service';
import { Heat } from '../model/heat.model';

@Component({
  selector: 'app-heats-generator',
  templateUrl: './heats-generator.component.html',
  styleUrls: ['./heats-generator.component.scss']
})

export class HeatsGeneratorComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();

  // form
  heatsGeneratorGroup: FormGroup;

  // translate
  translate: any;

  heats: Heat[];

  constructor(
    private translateService: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private formBuilder: FormBuilder,
    private heatsService: HeatsService,
    private heatsHttpService: HeatsHttpService
  ) {
    this.registerOnTranslateHeatCompetitors();
    this.registerOnHeatsObservable();
    this.createForm();
  }

  ngOnInit() {
  }

  private registerOnHeatsObservable(): void {
    this.heatsHttpService
      .subscribe((data: Heat[]) => {
        this.heats = data;
      });
  }

  public onSubmit(event): void {
    const lanesHeatsQuantity = new LanesHeatsQuantity();
    const data = this.heatsGeneratorGroup.value;
    lanesHeatsQuantity.heats = +data.numberHeats;
    lanesHeatsQuantity.lanes = +data.numberLanes;
    if (this.heats) {
      this.heatsHttpService
        .deletedHeats(this.heats);
    }
    this.heatsService.sendLanesHeatsQuantity(lanesHeatsQuantity);
    this.heatsGeneratorGroup.reset();
    Object.keys(this.heatsGeneratorGroup.controls).forEach(key => {
      this.heatsGeneratorGroup.controls[key].setErrors(null)
    });
    
    // this.heatsGeneratorGroup.controls['numberHeats'].setValue('');
    // this.heatsGeneratorGroup.controls['numberLanes'].setValue('');
  }
  

  private createForm(): void {
    this.heatsGeneratorGroup = this.formBuilder.group({
      numberHeats: ['', Validators.required],
      numberLanes: ['', Validators.required],
    });
  }

  private registerOnTranslateHeatCompetitors(): void {
    this.translationLoader.loadTranslations(portugues);
    const subscription = this.translateService
      .get('HEAT_HEATCOMPETITORS')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
