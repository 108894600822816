import { Injectable, OnDestroy } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthGuard} from '../../guards/auth.guard';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import {Router} from '@angular/router';
// import {Heat} from '../../../main/content/pages/championship/heats/model/heat.model';
import {HeatCompetitor} from '../../../main/content/pages/championship/heats/model/heat-competitor.model';
import {Competitor} from '../../model/competitor';
import {Heat} from '../../model/heat.model';

@Injectable({
  providedIn: 'root'
})
export class HeatsService implements OnDestroy {

  private subscriptions = new Subscription();
  private _heatsObservable = new Subject<Heat[]>();
  private _heatsUpdateScheduleObservable = new Subject<Heat[]>();
  private _fetchHeatsObservable = new Subject<Heat[]>();

  constructor(
    private http: HttpClient,
    private authGuard: AuthGuard,
    private router: Router,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  heatsObservable(): Observable<Heat[]> {
    return this._heatsObservable.asObservable();
  }

  heatsUpdateScheduleObservable(): Observable<Heat[]> {
    return this._heatsUpdateScheduleObservable.asObservable();
  }

  fetchHeatsObservable(): Observable<Heat[]> {
    return this._fetchHeatsObservable.asObservable();
  }

  
  
  saveOrUpdateHeats(heats: { heats: any[], heat_delete_ids: number[] }): void {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'heats',
      'save-or-update'
    ];

    const url = this.router.createUrlTree(baseUrl);

    const parsedUrl = url.toString().substring(1, url.toString().length);
    const subscription = this.http
      .post(parsedUrl, heats, {responseType})
      .subscribe((heatsData: Heat[]) => {
          this._heatsObservable.next(heatsData);
        },
        err => {
          this._heatsObservable.error(err);
        }
      );

    this.subscriptions.add(subscription);
  }
  
  updateHeatsWithSchedule(heats: any[], includeResponse: boolean = true): void {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'heats'
    ];

    const queryParams = {
      include_response: includeResponse ? 1 : 0
    };

    const url = this.router.createUrlTree(baseUrl, {
      queryParams: queryParams
    });

    const parsedUrl = url.toString().substring(1, url.toString().length);
    const subscription = this.http
      .put(parsedUrl, heats, {responseType})
      .subscribe((heats: Heat[]) => {
          this._heatsUpdateScheduleObservable.next(heats);
        },
        err => {
          this._heatsUpdateScheduleObservable.error(err);
        }
      );

    this.subscriptions.add(subscription);
  }


  fetchHeatsWithChampionshipAndType(championshipId: number, due_date: string): void {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'heats',
    ];

    const queryParams = {
      championship_id: championshipId,
      due_date
    };

    const url = this.router.createUrlTree(baseUrl, {
      queryParams: queryParams
    });
    const parsedUrl = url.toString().substring(1, url.toString().length);
    
    const subscription = this.http
      .get(parsedUrl, {responseType})
      .subscribe((heats: Heat[]) => {
          this._fetchHeatsObservable.next(heats);
        },
        () => {
          this._fetchHeatsObservable.next([]);
        });

    this.subscriptions.add(subscription);
  }

  deleteAllHeatsWithChampionshipAndType(championshipId: number | string, dueDate: string): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'heats',
      championshipId,
      dueDate
    ];

    const url = this.router.createUrlTree(baseUrl);

    const parsedUrl = url.toString().substring(1, url.toString().length);
    return this.http.delete(parsedUrl, { responseType })
      .map(response => {
        // console.log(response)
        return response;
      });
  }
  
  deleteHeat(heatId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'heats',
      heatId,
    ];

    const url = this.router.createUrlTree(baseUrl);

    const parsedUrl = url.toString().substring(1, url.toString().length);
    return this.http.delete(parsedUrl, { responseType })
      .map(response => {
        return response;
      });
  }
}


