<div fxLayout="column">
  <app-heats-generator fxLayout="column"></app-heats-generator>
</div>
<div fxLayout="row" fxLayoutAlign="space-between flex-start">
  <div fxLayout="column" fxFlex="30">
    <app-filter-competitors></app-filter-competitors>
  </div>
  <div fxLayout="column" fxFlex="70">
    <ng-container *ngIf="showHeats">
      <ng-container *ngFor="let heat of heats; let i = index">
        <app-competitors-datagrid
          (onHeatCompetitorsChanged)="heatCompetitorsChanged($event)"
          [index]="i"
          [lanesQuantity]="heat.total_lanes"
          [generateDropzones]="true"
          [heat]="heat"
        >
        </app-competitors-datagrid>
      </ng-container>

      <div fxLayoutAlign="flex-end">
        <btn
          [btnLoading]="true"
          [loading]="isLoading"
          (click)="onClick($event)"
        >
          {{
          "HEAT_HEATCOMPETITORS.HEATS_ITEMS.SUBMIT" | translate | uppercase
          }}
        </btn>
      </div>
    </ng-container>
  </div>
</div>
