import { environment } from '../../../environments/environment.staging';

export var config = {
  serverUrl: environment.serverUrl,
  hostDash: environment.hostDash ? environment.hostDash : "",
  versionAPI: "0.1",
  titleBase: "CrossX",
  limitCount: 10,
  pathAvatar: "./assets/images/avatars/profile.jpg",
  gMapsApiKey: environment.googleMapsApiKey,
};
