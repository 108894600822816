export const locale = {
  lang: 'en-US',
  data: {
    'LOTS': {
      'TITLE': 'Ingressos',
      'TABS': {
        'ATHLETES': 'Inscrições',
        'TICKETS': 'Ingressos'
      },
      'CATEGORY': {
        'EMPTY': 'Não existe categoria cadastrada',
      },
      'COPY': 'Copiar ingressos',
      'TABLE': {
        'NAME': 'Nome',
        'PRICE': 'Valor',
        'DATE_START': 'Data de início',
        'DATE_END': 'Data final',
        'COUNT': 'Quantidade'
      },
      'CONTENT_EMPTY': 'Nenhum ingresso cadastrado',
      'CURRENCY': 'R$',
      'CURRENCY_CODE': 'BRL',
      'ADD': 'Adicionar',
      'ADD_NEW': 'Adicionar novo ingresso',
      'TOASTR': {
        'CREATE': {
          'TITLE': 'Ingresso criado com sucesso!',
          'DESCRIPTION': '',
        },
        'EDIT': {
          'TITLE': 'Ingresso editado com sucesso!',
          'DESCRIPTION': '',
        },
        'DELETE': {
          'TITLE': 'Ingresso deletado com sucesso!',
          'DESCRIPTION': '',
          'ERROR_DELETE_TITLE': 'Erro ao deletar o ingresso!',
          'ERROR_DELETE_DESCRIPTION': 'Já existe venda para esse Ingresso',
        },
        'COPY': {
          'TITLE': 'Ingresso copiados com sucesso!',
          'DESCRIPTION': '',
        }
      },
      'DIALOG': {
        'TITLE': 'Excluir ingresso',
        'DESCRIPTION': 'Tem certeza que deseja excluir o ingresso <strong>{{ lot_name }}</strong>?<br><br>Após esta ação não poderá ser revertido.',
        'EXCLUIR': 'Excluir',
        'CANCEL': 'Cancelar',
      },
      'DELETE': 'Deletar',
      'DATE_FORMAT': 'dd/MM/yyyy',
      'DATETIME_FORMAT': 'dd/MM/yyyy HH:mm:ss',
    }
  }
}