export const locale = {
  lang: 'pt-BR',
  data: {
    'LEADERBOARD': {
      'NAME': 'Name',
      'DISQUALIFIED' : {
        'CUT': 'CUT',
        'WD': 'WD',
      },
    },
  }
};
