import { Injectable, OnDestroy } from '@angular/core';
import { HeatsUrlService } from './heats-url.service';
import { HeatsHttpService } from '../../../../../../core/services/heats/heats-http.service';
import {HttpClient} from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { LanesHeatsQuantity } from '../model/lanes-heats-quantity.model';
import { Heat } from '../model/heat.model';
import { HeatCompetitor } from '../model/heat-competitor.model';
import { Scroll } from '@angular/router';
import { Competition } from '../../../../../../core/model/competition';

@Injectable()
export class HeatsService implements OnDestroy {

  private subscriptions = new Subscription();
  private heatsGeneratorSubject = new Subject<LanesHeatsQuantity>();
  private _initialHeatsSubject = new BehaviorSubject<Array<Heat>>(null);
  private heatsGeneratorDropCollectionSubject = new Subject<{ data: Array<HeatCompetitor>, matrixIndex: number }>();
  private _scrollSubject = new Subject<any>();
  private _competitionSubject = new Subject<Competition[]>();

  constructor(
    private heatsUrlService: HeatsUrlService,
    private http: HttpClient,
    private heatsHttpService: HeatsHttpService
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  addFilter(name: string, value: string): HeatsService {
    this.heatsUrlService.setQueryParam(name, value);

    this.heatsUrlService
      .navigate()
      .then((result: boolean) => {

        if (result) {
          this.doRequest();
        }

      });

    return this;
  }

  removeFilter(name: string): HeatsService {
    this.heatsUrlService.removeQueryParam(name);

    this.heatsUrlService
      .navigate()
      .then((result: boolean) => {
        if (result) {
          this.doRequest();
        }
      });

    return this;
  }

  public doRequest(): HeatsService {
    const queryParams = this.heatsUrlService.getQueryParamsAsArray();
    const pathParams = this.heatsUrlService.getPathParamsAsArray();
    const params = queryParams.concat(pathParams);
    this.heatsHttpService.fetchCompetitors(params);
    return this;
  }

  heatsGeneratorObservable(): Observable<any> {
    return this.heatsGeneratorSubject.asObservable();
  }

  competitionObservable(): Observable<Competition[]> {
    return this._competitionSubject.asObservable();
  }

  heatsGeneratorDropCollectionObservable(): Observable<{ data: Array<HeatCompetitor>, matrixIndex: number }> {
    return this.heatsGeneratorDropCollectionSubject.asObservable();
  }

  sendDropCollection(data: { data: Array<HeatCompetitor>, matrixIndex: number }): void {
    this.heatsGeneratorDropCollectionSubject.next(data);
  }

  sendLanesHeatsQuantity(data: LanesHeatsQuantity): void {
    this.heatsGeneratorSubject.next(data);
  }

  sendInitialHeats(heats: Array<Heat>): void {
    this._initialHeatsSubject.next(heats);
  }

  sendEventScroll(event: any) {
    this._scrollSubject.next(event);
  }

  initialHeatsObservable(): Observable<Heat[]> {
    return this._initialHeatsSubject.asObservable();
  }

  scrollEventObservable(): Observable<any> {
    return this._scrollSubject.asObservable();
  }

  sendCompetitionAndWod(competition: Competition[]): void {
    this._competitionSubject.next(competition);
  }

  export(championshipId: number, dueDate: string): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/heats/export?championship_id=${championshipId}&due_date=${dueDate}`;

    return this.http.get(url, {responseType})
      .map((response: Response) => {
        return response;
      });
  }

}
