<div id="my-profile" class="page-layout blank pb-64" fxLayoutAlign="center">
  <div class="header-img"></div>

  <div id="my-profile-wrapper">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="user-header pt-44 pb-44">
      <div fxLayout="row" fxLayoutAlign="start center" ngClass.xs="pl-32 pr-32">
        <div class="picture">
          <img [src]="urlAvatar" class="avatar mr-16" />
          <input type="file" name="file" id="file" class="inputfile" (change)="readUrl($event)" />
          <label for="file" class="btn-take-photo"><img src="./assets/icons/content/take-photo-white.svg"></label>
        </div>
        <div fxLayout="column" fxLayoutAlign="start start">
           {{ userMe.firstname }}
           <span>{{ userMe.lastname }}</span>
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="center start" fxHide.xs class="user-birtdate" *ngIf="userMe.birthdate">
        {{'MYPROFILE.AGE' | translate}}
        <span>{{ userMe.age }}</span>
      </div>
    </div>

    <div class="card auto-width mb-32 p-32">
      <description-section
        title="{{'MYPROFILE.TITLE' | translate}}"
        text="{{'MYPROFILE.DESCRIPTION' | translate}}">
        <mat-icon class="ic-info mr-8" svgIcon="ic-info"></mat-icon>
      </description-section>

      <h4 class="mt-32">{{'MYPROFILE.BASIC_INFO' | translate}}</h4>
      <form class="" name="registrationForm" [formGroup]="registrationForm" novalidate>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutAlign="start stretch">
          <mat-form-field fxFlex="50" fxFlex.xs="100" ngClass="pr-8" ngClass.xs="pr-0">
            <input matInput placeholder="{{'MYPROFILE.FORM.FIRSTNAME' | translate}}" formControlName="firstname" [errorStateMatcher]="matchers.firstname" uppercase required>
            <mat-error *ngIf="registrationForm.controls.firstname.hasError('required')">
              {{'MYPROFILE.VALIDATIONS.FIRSTNAME_REQUIRED' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="50" fxFlex.xs="100" ngClass="pl-8" [ngClass.xs]="'p-0'">
            <input matInput placeholder="{{'MYPROFILE.FORM.LASTNAME' | translate}}" formControlName="lastname" [errorStateMatcher]="matchers.lastname" uppercase required>
            <mat-error *ngIf="registrationForm.controls.lastname.hasError('required')">
              {{'MYPROFILE.VALIDATIONS.LASTNAME_REQUIRED' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
        
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutAlign="start stretch">
          <mat-form-field fxFlex="50" fxFlex.xs="100" ngClass="pr-8" ngClass.xs="pr-0">
            <input matInput placeholder="E-mail" formControlName="email" [errorStateMatcher]="matchers.email" required>
            <mat-error *ngIf="registrationForm.controls.email.hasError('required')">
              {{'MYPROFILE.VALIDATIONS.EMAIL_REQUIRED' | translate}}
            </mat-error>
            <mat-error *ngIf="registrationForm.controls.email.hasError('email') && !registrationForm.controls.email.hasError('required')">
              {{'MYPROFILE.VALIDATIONS.EMAIL_INVALID' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="50" fxFlex.xs="100" ngClass="pl-8" ngClass.xs="pl-0">
            <mat-select placeholder="{{'MYPROFILE.FORM.GENDER' | translate}}" formControlName="gender" required>
              <mat-option *ngFor="let gender of genders" [value]="gender">
                {{ gender.text }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="registrationForm.controls.gender.hasError('required')">
              {{'MYPROFILE.VALIDATIONS.GENDER_REQUIRED' | translate}}
            </mat-error>
          </mat-form-field>
        </div>


        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutAlign="start stretch">
          <mat-form-field style="margin-right: 10px;" fxFlex="50" fxFlex.xs="100" ngClass="pr-8" ngClass="pr-0">
            <input matInput placeholder="{{'MYPROFILE.FORM.CPF' | translate}}" formControlName="cpf" [errorStateMatcher]="matchers.cpf"
                   mask="000.000.000-00" required>
            <mat-error *ngIf="registrationForm.controls.cpf.hasError('required')">
              {{'MYPROFILE.VALIDATIONS.CPF_REQUIRED' | translate}}
            </mat-error>
          </mat-form-field>
          
          <mat-form-field fxFlex="50" fxFlex.xs="100" ngClass="pl-8" ngClass.xs="pl-0">
            <input matInput placeholder="{{'MYPROFILE.FORM.BIRTHDATE' | translate}}" formControlName="birthdate" [errorStateMatcher]="matchers.birthdate"
                   mask="00/00/0000" required>
            <mat-icon matSuffix>date_range</mat-icon>
            <mat-error *ngIf="registrationForm.controls.birthdate.hasError('required')">
              {{'MYPROFILE.VALIDATIONS.BIRTHDATE_REQUIRED' | translate}}
            </mat-error>
            <mat-error *ngIf="!registrationForm.controls.birthdate.hasError('required') && registrationForm.controls.birthdate.hasError('invalid')">
              {{'MYPROFILE.VALIDATIONS.DATE_INVALID' | translate}}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutAlign="start stretch">
          <mat-form-field fxFlex="50" fxFlex.xs="100" ngClass="pr-8" ngClass.xs="pr-0">
            <input matInput placeholder="{{'MYPROFILE.FORM.BOX' | translate}}" formControlName="box" [matAutocomplete]="autoBoxUser" [errorStateMatcher]="matchers.box"
                   (ngModelChange)="searchBox($event)" uppercase required>
            <mat-autocomplete #autoBoxUser="matAutocomplete" [displayWith]="displayFnBox">
              <mat-option *ngFor="let box of boxes" [value]="box">
                {{ box.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="registrationForm.controls.box.hasError('required')">
              {{'MYPROFILE.VALIDATIONS.BOX_REQUIRED' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="50" fxFlex.xs="100" ngClass="pl-8" ngClass.xs="pl-0">
            <input matInput placeholder="{{'MYPROFILE.FORM.CITY' | translate}}" formControlName="city" [errorStateMatcher]="matchers.city"
                   (ngModelChange)="searchCity($event)" [matAutocomplete]="autoCity" autocomplete="off" uppercase required>
            <mat-autocomplete autoActiveFirstOption #autoCity="matAutocomplete">
              <mat-option *ngFor="let city of cities" [value]="city.description | uppercase" (onSelectionChange)="citySelected(city)">
                {{ city.description }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="registrationForm.controls.city.hasError('required')">
              {{'MYPROFILE.VALIDATIONS.CITY_REQUIRED' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
      </form>

      <div fxLayoutAlign="end start">
        <btn [loading]="isLoadingUpdateBasicProfile" [buttonDisabled]="registrationForm.invalid" (click)="actionUpdateBasicProfile()">SALVAR</btn>
      </div> 
      
      <!--<form>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
          <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8">
            <input matInput placeholder="{{'MYPROFILE.FORM.PHONE' | translate}}" formControlName="phone" [errorStateMatcher]="matchers.phone"
                   mask="(00) 00000.0000" required>
            <mat-error *ngIf="registrationForm.controls.phone.hasError('required')">
              {{'MYPROFILE.VALIDATIONS.PHONE_REQUIRED' | translate}}
            </mat-error>
          </mat-form-field>
        
          <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8">
            <mat-select placeholder="{{'MYPROFILE.FORM.TSHIRT' | translate}}" formControlNme="tshirt" required>
              <mat-option *ngFor="let size of tshirtSize" [value]="size">
                {{ size }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="registrationForm.controls.tshirt.hasError('required')">
              {{'MYPROFILE.VALIDATIONS.TSHIRT_REQUIRED' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
        
      </form>-->
    </div>

    <!-- Manager Event -->
    <div class="card auto-width mb-32" *ngIf="iShowMyManagerEvents">
      <div class="p-32">
        <description-section
          title="{{'MYPROFILE.MANAGER_EVENTS.TITLE' | translate}}"
          text="{{'MYPROFILE.MANAGER_EVENTS.DESCRIPTION' | translate}}">
          <mat-icon class="ic-info mr-8" svgIcon="ic-info"></mat-icon>
        </description-section>
      </div>
      <div class="p-32 pt-0 pb-0">
        <div fxLayout="row" fxlayoutalign.xs="column" fxFlex="80" fxLayoutAlign="space-between start">
          <mat-form-field fxFlex="50" fxFlex.xs="100" floatLabel="never" class="search">
            <input 
              matInput 
              (input)="searchEvents($event, 1)" 
              placeholder="{{'MYPROFILE.SEARCH' | translate}}">
            <mat-icon matPrefix class="icon-search">search</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <table-x 
        #tableManagerEvents
        [items]="myManagerEvents"
        [config]="tableConfig"
        [isHeaderLoading]="isLoading">
        
        <column property="name" header="{{ 'MYPROFILE.TABLE_NAME' | translate }}" [sortable]="false" align="left">
          <ng-template #dataTableCell let-item="item">
            <a (click)="openEvent(item)" fxLayout="row" fxLayoutAlign="start center">
              <img src="{{ (item.email_logo) ? item.email_logo : null | placeholderImage:'user' }}" width="80"
                class="avatar" /> {{ item.name }}
            </a>
          </ng-template>
        </column>
      
        <column property="date" header="{{ 'MYPROFILE.TABLE_DATA' | translate }}" [sortable]="false" align="center">
          <ng-template #dataTableCell let-item="item">
<!--             {{ (item.datetime_start) ? (item.datetime_start | date:('MYPROFILE.DATE_FORMAT' | translate)) : '' }}-->
              {{ item.datetime_start ? dateFormat(item.datetime_start) : '' }} 
<!--            <pre>{{ item | json }}</pre>-->
          </ng-template>
        </column>
      
        <column property="status" header="{{ 'MYPROFILE.TABLE_STATUS' | translate }}" [sortable]="false" align="center">
          <ng-template #dataTableCell let-item="item">
            <label-x type="{{ getEventStatus(item, true) }}" *ngIf="item.datetime_start">{{ getEventStatus(item, false) }}</label-x>
          </ng-template>
        </column>
      </table-x>
      <div fxLayoutAlign="end start" class="p-32" *ngIf="seeMoreEvents">
        <btn (click)="openMoreEvents('my-event')">VER MAIS</btn>
      </div>
    </div>

    <!-- MY Events -->
    <div class="card auto-width mb-32" *ngIf="iShowMyEvents">
      <div class="p-32">
        <description-section title="{{'MYPROFILE.MY_EVENTS.TITLE' | translate}}" text="{{'MYPROFILE.MY_EVENTS.DESCRIPTION' | translate}}">
          <mat-icon class="ic-info mr-8" svgIcon="ic-info"></mat-icon>
        </description-section>
      </div>
      <div class="p-32 pt-0 pb-0">
        <div fxLayout="row" fxlayoutalign.xs="column" fxFlex="80" fxLayoutAlign="space-between start">
          <mat-form-field fxFlex="50" fxFlex.xs="100" floatLabel="never" class="search">
            <mat-icon matPrefix class="icon-search">search</mat-icon>
            <input 
              matInput 
              (input)="searchEvents($event, 2)" 
              placeholder="{{'MYPROFILE.SEARCH' | translate}}">
          </mat-form-field>
        </div>
      </div>
      <table-x
          #tableMyEvents
          [items]="myEvents"
          [config]="tableConfig"
          [isHeaderLoading]="isLoadingEventCompetitor">
        <column property="name" header="{{ 'MYPROFILE.TABLE_NAME' | translate }}" [sortable]="false" align="left">
          <ng-template #dataTableCell let-item="item">
            <a (click)="openEventCompetitor(item)" fxLayout="row" fxLayoutAlign="start center">
              <img src="{{ (item.email_logo) ? item.email_logo : null | placeholderImage:'user' }}" width="80" class="avatar" />
              <span matBadge="&#8288;" matBadgeOverlap="false" matBadgeSize="small" matBadgeColor="warn" [matBadgeHidden]="!getEventBadge(item)">{{ item.name }}</span>
            </a>
          </ng-template>
        </column>
    
        <column property="year" header="{{ 'MYPROFILE.TABLE_YEAR' | translate }}" [sortable]="false" align="center">
          <ng-template #dataTableCell let-item="item">
            {{ item.year }}
          </ng-template>
        </column>
      </table-x>
    
      <div fxLayoutAlign="end start" class="p-32" *ngIf="seeMoreEventsCompetitor">
        <btn (click)="openMoreEvents('my-event-competitor')">VER MAIS</btn>
      </div>
    </div>
  </div>
</div>

<button mat-fab (click)="createEvent()" *ngIf="userMe.admin">
  <mat-icon class="mat-30">add</mat-icon>
</button>