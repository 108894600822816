import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './change-password.component';

const routes = [
  {
    path: 'change-password/:token',
    component: ChangePasswordComponent
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    ChangePasswordComponent
  ]
})
export class ChangePasswordModule { }
