import { Lot } from './../../../../../../core/model/lot';
import { Competition } from './../../../../../../core/model/competition';
import { LotService } from './../../../../../../core/services/lot/lot.service';
import { EventGuard } from './../../../../../../core/guards/event.guard';
import { Component, OnInit, ViewChild } from '@angular/core';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { Errors } from '../../../../../../core/model/errors';
import { MatDialogRef, MatIconRegistry } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CompetitionService } from '../../../../../../core/services/competition/competition.service';
import { FormErrorStateMatcher } from '../../../../../../core/utils/form-error-state-matcher';
import { SelectTimeComponent } from '../../../../../../core/components/select-time/select-time.component';
import { dateValidator } from '../../../../../../core/utils/custom-validators';
import * as moment from 'moment';
import { Utils } from '../../../../../../core/utils';

@Component({
  selector: 'app-modal-create-lot',
  templateUrl: './modal-create-lot.component.html',
  styleUrls: ['./modal-create-lot.component.scss']
})
export class ModalCreateLotComponent implements OnInit {

  public isEdit = false;
  public competition: Competition = null;
  public championshipId: number; 
  public eventId: number;
  public lot: Lot = null;

  @ViewChild('pickerTimeStart', { read: SelectTimeComponent }) pickerTimeStart: SelectTimeComponent;
  @ViewChild('pickerTimeEnd', { read: SelectTimeComponent }) pickerTimeEnd: SelectTimeComponent;

  // Event
  event: any;

  // Translate
  translate: any;

  lotForm: FormGroup;
  matchers: any;

  totalValueUser: string = null;
  timeStart = '10:00';
  timeEnd = '10:00';

  isLoading = false;
  requestErrors: string;

  constructor(
    public dialogRef: MatDialogRef<ModalCreateLotComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private formBuilder: FormBuilder,
    private competitionService: CompetitionService,
    private eventGuard: EventGuard,
    private lotService: LotService
  ) {
    this.translationLoader.loadTranslations(english, portugues);

    this.translateService.get('MODAL_LOT').subscribe((res: any) => {
      this.translate = res;
    });

    this.event = this.eventGuard.getActiveEvent().event;

    //Icon Info
    iconRegistry.addSvgIcon(
      'ic-cat-info',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/category/ic-cat-info.svg')
    );
  }

  ngOnInit() {
    console.log(this.lot);
    console.log(this.event);

    const hasOptionalSettings = this.verifyHasOptionalSettings();
    const dateEnd = (this.event.datetime_end) ? moment(this.event.datetime_end) : moment().add(1, 'days');
    const competitionName = this.competition ? this.competition.name : '';
    this.lotForm = this.formBuilder.group({
      name: [(this.lot) ? this.lot.name : `${competitionName}`, [Validators.required]],
      price: [{
        value: (this.lot) ? this.lot.price : 0,
        disabled: (this.lot && this.lot.sold > 0)
      }, [Validators.required]],
      repassTaxFee: [{
        value: (this.lot) ? this.lot.repass_tax_fee : false,
        disabled: (this.lot && this.lot.sold > 0)
      }],
      settings: [hasOptionalSettings],
      limit: [(this.lot) ? this.lot.limit : null],
      dateStart: [(this.lot) ? (this.lot.start ? moment(this.lot.start).format(this.translate.DATE_FORMAT) : '') : '', [dateValidator(this.translate.DATE_FORMAT)]],
      timeStart: [''],
      dateEnd: [(this.lot) ? (this.lot.end ? moment(this.lot.end).format(this.translate.DATE_FORMAT) : '') : '', [dateValidator(this.translate.DATE_FORMAT)]],
      timeEnd: [''],
    });

    this.timeStart = (this.lot && this.lot.start) ? moment(this.lot.start).format('HH:mm') : moment().format('HH:mm');
    this.timeEnd = (this.lot && this.lot.end) ? moment(this.lot.end).format('HH:mm') : dateEnd.format('HH:mm');

    this.matchers = {
      name: new FormErrorStateMatcher(),
      price: new FormErrorStateMatcher(),
      dateStart: new FormErrorStateMatcher(),
    };

    if (this.lot && this.lot.repass_tax_fee && this.lot.price > 0) {
      const finalPrice = ((100 * this.lot.price) / (100 - this.event.recipient_percentage)); 
      this.totalValueUser = `Valor total a ser pago pelo cliente R$ ${Utils.formatMoney(finalPrice.toFixed(2))}.`;
    } else {
      this.totalValueUser = null;
    }

    this.inputsChangeValue();
  }

  createOrUpdateLot()
  {
    this.requestErrors = null;
    
    // validate all form fields
    if (!this.lotForm.valid) {
      Utils.formValid(this.lotForm);
      return;
    }

    let parameters = {
      event_id: this.eventId,
      name: this.lotForm.value.name.toUpperCase(),
      price: this.lotForm.value.price,
      repass_tax_fee: this.lotForm.value.repassTaxFee,
      start: null,
      end: null
    };

    if (!this.isEdit) {
      parameters['championship_id'] = this.championshipId;
      parameters['competition_id'] = this.competition ? this.competition.id : null;
    }

    parameters['limit'] = (this.lotForm.value.settings) ? this.lotForm.value.limit : null;

    // if (this.lotForm.value.settings && this.lotForm.value.dateEnd && !this.lotForm.value.dateStart) { 
    //   this.lotForm.controls['dateStart'].setErrors({
    //     required_with_end: true
    //   })
    //   Utils.formValid(this.lotForm)
    //   console.log(this.lotForm.controls)
    //   return
    // }

    if (this.lotForm.value.dateStart && this.lotForm.value.settings) {
      // console.log('dateTimeStart', dateTimeStart, dateTimeStart.format(`YYYY-MM-DD HH:mm:ss`))
      const dateTimeStart = moment(`${this.lotForm.value.dateStart} ${this.timeStart}:00`, `${this.translate.DATE_FORMAT} HH:mm:ss`);
      if (dateTimeStart.isValid()) {
        parameters['start'] = dateTimeStart.format(`YYYY-MM-DD HH:mm:ss`);
      }
    }

    if (this.lotForm.value.dateEnd && this.lotForm.value.settings) {
      // console.log(this.pickerTimeEnd.time)
      const dateTimeEnd = moment(`${this.lotForm.value.dateEnd} ${this.timeEnd}:00`, `${this.translate.DATE_FORMAT} HH:mm:ss`);
      if (dateTimeEnd.isValid()) {
        parameters['end'] = dateTimeEnd.format(`YYYY-MM-DD HH:mm:ss`);
      }
    }
    
    console.log(parameters);
    this.isLoading = true;

    if (this.isEdit) {
      this.lotService.updateLot(parameters, this.lot.id)
        .subscribe(
          result => {
            console.log('result', result);
            this.isLoading = false;
            this.lot = result;
            this.dialogRef.close({ lot: result, isEdit: this.isEdit });
          }, err => {
            console.log('err', err);
            const errors: Errors = new Errors().deserialize((err.error as any));
            this.requestErrors = errors.getFullMessages();

            this.isLoading = false;
          }
        );
    } 
    else {
      this.lotService.createLot(parameters)
        .subscribe(
          result => {
            console.log('result', result);
            this.isLoading = false;
            if (this.competition) { this.competition.lots.push(result) }
            this.dialogRef.close({ lot: result, isEdit: this.isEdit });
          }, err => {
            console.log('err', err);
            const errors: Errors = new Errors().deserialize((err.error as any));
            this.requestErrors = errors.getFullMessages();

            this.isLoading = false;
          }
        );
    }
    
  }

  verifyHasOptionalSettings(): boolean {
    if (this.lot) {
      if (this.lot.limit) {
        return true;
      }
      else if (this.lot.start) {
        return true;
      }
      else if (this.lot.end) {
        return true;
      }
    }

    return false;
  }


  // Inputs change
  inputsChangeValue() {
    this.lotForm.valueChanges.subscribe(() => {
      // console.log(this.lotForm.controls)
    });

    this.lotForm.get('price').valueChanges.subscribe(value => {
      // console.log(value)
      if (this.lotForm.value.repassTaxFee && value > 0 && this.event.recipient_percentage) {
        const finalPrice = ((100 * value) / (100 - this.event.recipient_percentage)); 
        this.totalValueUser = `Valor total a ser pago pelo cliente R$ ${Utils.formatMoney(finalPrice.toFixed(2))}.`;
      } else {
        this.totalValueUser = null;
      }

    });

    this.lotForm.get('repassTaxFee').valueChanges.subscribe(value => {
      if (value && this.lotForm.value.price && this.lotForm.value.price > 0) {
        const finalPrice = ((100 * this.lotForm.value.price) / (100 - this.event.recipient_percentage));
        this.totalValueUser = `Valor total a ser pago pelo cliente R$ ${Utils.formatMoney(finalPrice.toFixed(2))}.`;
      } else {
        this.totalValueUser = null;
      }
    });
  }

  timeChange(event: any, type: string) {
    if (type === 'start') {
      this.timeStart = event;
    } else if (type === 'end') {
      this.timeEnd = event;
    }
  }
}
