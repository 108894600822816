<td *ngIf="config.checkbox" class="checkbox" [ngClass]="{'pl-32': config.paddingSide}">
  <mat-checkbox [checked]="selected" (change)="checkboxAction($event)" class="hide-in-drag"></mat-checkbox>
</td>
<td 
  *ngFor="let column of columns; index as i; trackBy: trackByColumn"
  class="{{column.align}} {{(column.classes) ? column.classes : ''}} {{(column.classesBody) ? column.classesBody : ''}}"
  [ngStyle]="{'width': (column.width) ? column.width : 'auto','max-width': (column.maxWidth) ? column.maxWidth : '500px ', 'border-left': (column.borderLeftColor !== null) ? '4px solid ' + column.borderLeftColor[row.status]  : ''}"
  [ngClass]="{'pl-32': config.paddingSide && !config.checkbox && i === 0, 'pr-32': config.paddingSide && i === columns.length-1}">
  
  <div 
    *ngIf="column.cellTemplate"
    [ngTemplateOutlet]="column.cellTemplate"
    [ngTemplateOutletContext]="{ item: row, index: index}">
  </div>
   <!-- {{ column.cellTemplate }} -->
  <!-- {{ column.property }} -->
</td>
