import { Injectable } from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {Heat} from '../../model/heat.model';
import {HttpClient} from '@angular/common/http';
import {AuthGuard} from '../../guards/auth.guard';
import {Router} from '@angular/router';
import {CrossxAppUrl} from '../../model/crossx-app-url.model';
import {CrossXApp} from '../../model/crossx-app.model';

@Injectable({
  providedIn: 'root'
})
export class CrossxAppService {

  private subscriptions = new Subscription();
  private _fetchCrossXUrlObservable = new Subject<CrossXApp>();

  constructor(
    private http: HttpClient,
    private authGuard: AuthGuard,
    private router: Router,
  ) { }

  fetchCrossXUrlObservable(): Observable<CrossXApp> {
    return this._fetchCrossXUrlObservable.asObservable();
  }

  fetchCrossXUrlWithEvent(eventId: number): void {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'crossx-app-urls',
      eventId
    ];

    const url = this.router.createUrlTree(baseUrl);
    const parsedUrl = url.toString().substring(1, url.toString().length);

    const subscription = this.http
      .get(parsedUrl, {responseType})
      .subscribe((crossxApp: CrossXApp) => {
          this._fetchCrossXUrlObservable.next(crossxApp);
        },
        () => {
          this._fetchCrossXUrlObservable.next(null);
        });

    this.subscriptions.add(subscription);
  }

  createCrossXAppUrl(crossxAppEventId: number, parameters: any, media: any = null): Observable<any> {
    const formData: FormData = new FormData();
    if (media && media !== null) {
      formData.append('file', media, media.name);
    }

    Object.keys(parameters).forEach(element => {
      console.log(element)
      formData.append(element, parameters[element]);
    });

    const baseUrl = [
      'admin',
      'crossx-app-urls',
      crossxAppEventId
    ];

    const url = this.router.createUrlTree(baseUrl);
    const parsedUrl = url.toString().substring(1, url.toString().length);

    return this.http.post(parsedUrl, formData)
      .map((response: Response) => {
        console.log(response)
        const res = response as any
        
        return res
      })
  }
  
  deleteCrossXAppUrl(crossXAppEventUrlId: number):  Observable<any> {
    const baseUrl = [
      'admin',
      'crossx-app-urls',
      crossXAppEventUrlId
    ];

    const url = this.router.createUrlTree(baseUrl);
    const parsedUrl = url.toString().substring(1, url.toString().length);

    return this.http.delete(parsedUrl)
      .map((response: Response) => {
        const res = response as any
        return res
      })
  }
  
  massUpdateCrossXAppUrl(crossxAppEventId: number, parameters: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'crossx-app-urls',
      crossxAppEventId,
      'mass-update'
    ];

    const url = this.router.createUrlTree(baseUrl);
    const parsedUrl = url.toString().substring(1, url.toString().length);

    return this.http.post(parsedUrl, parameters)
      .map((response: Response) => {
        const res = response as any

        return res
      })
  }
  
  publishOrUnpublishCrossXApp(crossXAppEventId: number, action: string): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'crossx-app-urls',
      crossXAppEventId,
      action
    ];

    const url = this.router.createUrlTree(baseUrl);
    const parsedUrl = url.toString().substring(1, url.toString().length);

    return this.http.put(parsedUrl, null, { responseType })
      .map((response: Response) => {
        const res = response as any
        return res
      })
  }
}
