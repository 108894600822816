import { Competition } from './competition';
import {Championship} from './championship';
export class Lot {
  id: number
  event_id?: number
  competition_id?: number
  championship_id?: number
  championship_competition_id?: number
  name?: string
  price?: number
  repass_tax_fee?: boolean = false
  start?: Date
  end?: Date
  limit?: number
  taxFee?: number
  taxPrice?: number = 0
  finalPrice?: number
  sold?: number = 0
  canSubscribe?: boolean = true
  invite_only?: boolean = false
  competition?: Competition
  championship?: Championship

  created_at?: Date
  updated_at?: Date

  active?: boolean = false

  deserialize(object: any) {
    (<any>Object).assign(this, object);

    return this;
  }
}
