import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CategoryLotWodComponent} from '../category-lot-wod/category-lot-wod.component';
import {AuthGuard} from '../../../../../core/guards/auth.guard';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {SharedModule} from '../../../../../core/modules/shared.module';
import {RouterModule} from '@angular/router';
import {NgxMaskModule} from 'ngx-mask';
import {LotsComponent} from './lots.component';
import { LotsWithCompetitionComponent } from './lots-with-competition/lots-with-competition.component';
import { LotsWithoutCompetitionComponent } from './lots-without-competition/lots-without-competition.component';

const routes = [
  {
    path: 'championship/lots/:championship_id',
    component: LotsComponent,
    canActivate: [AuthGuard, EventGuard]
  }
];

@NgModule({
  declarations: [
    LotsComponent,
    LotsWithCompetitionComponent,
    LotsWithoutCompetitionComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot()
  ],
  exports: [
    LotsComponent
  ]
})
export class LotsModule { }
