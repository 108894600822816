import { environment } from './../../../../../environments/environment';
import { SettingsComponent } from './settings.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '../../../../core/guards/auth.guard';
import { EventGuard } from '../../../../core/guards/event.guard';
import { SharedModule } from '../../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { AgmCoreModule } from '@agm/core';

const routes = [
  {
    path: 'event/informations2',
    component: SettingsComponent,
    canActivate: [AuthGuard, EventGuard]
  }
];

@NgModule({
  declarations: [
    SettingsComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    })
  ],
  exports: [
    SettingsComponent
  ]
})
export class SettingsModule { }
