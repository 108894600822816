<button (click)="clickButton()"
        [ngClass]="{'isLoading': loading, 'btnLoading': btnLoading, 'noPadding': !padding, 'circle': circle, 'only-icon': onlyIcon}"
        [ngStyle]="{ 
            'background-color': loading ? color : backgroundColor,
            'color': color,
            'width': loading ? '35px' : buttonWidth,
            'height': loading ? '35px' : buttonHeight
        }"
        class="{{type}}"
        [disabled]="buttonDisabled"
        ng-disabled="buttonDisabled"
        type="{{typeButton}}">

    <div class="loader" [ngClass]="{'hide': !loading}">
        <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="4" stroke-miterlimit="10" [ngStyle]="{ 'stroke': backgroundColor }" />
        </svg>
    </div>
    <div [ngClass]="{ 'hide': loading, 'icon-mat': materialIcon, 'only-icon': onlyIcon }" class="btn-container">
        <mat-icon
            *ngIf="materialIcon && (iconAlign =='left' || iconAlign == null)"
            [ngClass]="{ 'mr-4' : !onlyIcon }"
            [ngStyle]="{ 'color': color }">{{ materialIcon }}</mat-icon>
        <img src="./assets/icons/{{icon}}" *ngIf="!materialIcon && icon && (iconAlign =='left' || iconAlign == null)" [ngClass]="{ 'mr-4' : !onlyIcon }">
        <span *ngIf="!onlyIcon"><ng-content></ng-content></span>
        <img src="./assets/icons/{{icon}}" *ngIf="!materialIcon && icon && (iconAlign =='right')" [ngClass]="{ 'mr-4' : !onlyIcon }">
        <mat-icon
            *ngIf="materialIcon && (iconAlign =='right')"
            [ngClass]="{ 'mr-4' : !onlyIcon }"
            [ngStyle]="{ 'color': color }">{{ materialIcon }}</mat-icon>
    </div>

</button>