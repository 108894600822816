import {NgModule} from '@angular/core';
import {AuthGuard} from '../../../../../core/guards/auth.guard';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {SharedModule} from '../../../../../core/modules/shared.module';
import {RouterModule} from '@angular/router';
import {NgxMaskModule} from 'ngx-mask';
import {HeatsItemsComponent} from './heats-items/heats-items.component';
import {NavTeamTypeComponent} from './nav-team-type/nav-team-type.component';
import {NavHeatsAgendaComponent} from './nav-heats-agenda/nav-heats-agenda.component';
import {HeatsGeneratorComponent} from './heats-generator/heats-generator.component';
import {FilterCompetitorsComponent} from './filter-competitors/filter-competitors.component';
import {HeatsService} from './services/heats.service';
import {CompetitorsDatagridComponent} from './competitors-datagrid/competitors-datagrid.component';
import {HeatsScheduleComponent} from './heat-schedule/heats-schedule.component';
import {HeatsComponent} from './heats.component';
import {CompetitorsGridItemComponent} from './competitors-grid-item/competitors-grid-item.component';
import {HeatsUrlService} from './services/heats-url.service';
import { CustomDateHeatPipe } from './pipe/custom-date-heat.pipe';

const routes = [
  {
    path: 'championship/:championship_id/heats-back',
    canActivate: [AuthGuard, EventGuard],
    component: HeatsComponent,
    children: [
      {
        path: '',
        redirectTo: 'items',
        pathMatch: 'full'
      },
      {
        path: 'items',
        component: HeatsItemsComponent
      },
      {
        path: 'schedule',
        component: HeatsScheduleComponent
      }
    ]
  }
];

@NgModule({
  declarations: [
    HeatsComponent,
    HeatsItemsComponent,
    NavTeamTypeComponent,
    NavHeatsAgendaComponent,
    HeatsGeneratorComponent,
    FilterCompetitorsComponent,
    CompetitorsDatagridComponent,
    CompetitorsGridItemComponent,
    HeatsScheduleComponent,
    CustomDateHeatPipe
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot(),
  ],
  exports: [
    HeatsItemsComponent,
    HeatsScheduleComponent,
    CustomDateHeatPipe
  ],
  providers: [
    HeatsService,
    HeatsUrlService
  ]
})
export class HeatsModule {
}

