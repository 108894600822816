import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';

@Injectable()
export class ScrollService {

  private scrollEnd = new Subject<any>();

  constructor() { }

  setScrollEndOffset() 
  {
    this.scrollEnd.next('end')
  }

  watchScroll(): Observable<any> {
    return this.scrollEnd.asObservable();
  }

}


