import { LotService } from './../../../../../core/services/lot/lot.service';
import { TableXComponent } from './../../../../../core/components/table-x/table-x.component';
import { ModalCategoryComponent } from './modal-category/modal-category.component';
import { Competition } from './../../../../../core/model/competition';
import { CompetitionService } from './../../../../../core/services/competition/competition.service';
import { Component, OnInit, NgZone, ViewChild, ChangeDetectorRef, QueryList, ViewChildren, OnDestroy } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { BreadcrumbsService } from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthGuard } from '../../../../../core/guards/auth.guard';
import { EventGuard } from '../../../../../core/guards/event.guard';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MatIconRegistry, MatDialog, MatDialogRef } from '@angular/material';
import { WodService } from '../../../../../core/services/wod/wod.service';
import { ToastrService } from '../../../../../core/components/toastr_/public_api';
import { FilterPipe } from 'ngx-filter-pipe';

import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { config } from '../../../../../core/config/config';
import { TabBarComponent } from '../../../../../core/components/tab-bar/tab-bar.component';
import { TableConfig } from '../../../../../core/components/table-x/table-x.component';
import { CrossXDialogComponent } from '../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import { Errors } from '../../../../../core/model/errors';
import { Utils } from '../../../../../core/utils';
import { SubDivision } from '../../../../../core/model/sub-division';
import { SubDivisionHttpService } from '../../../../../core/services/sub-division/sub-division-http.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-category-lot-wod',
  templateUrl: './category-lot-wod.component.html',
  styleUrls: ['./category-lot-wod.component.scss']
})
export class CategoryLotWodComponent implements OnInit, OnDestroy {

  @ViewChild('tabbar', { read: TabBarComponent }) tabBar: TabBarComponent;
  @ViewChildren('tableCompetition') tablesCompetition: QueryList<TableXComponent>;
  @ViewChildren('tableLot') tablesLot: QueryList<TableXComponent>;
  @ViewChildren('tableWod') tablesWod: QueryList<TableXComponent>;

  subscriptions: Subscription = new Subscription();

  // Translate
  translate: any;

  event: any;
  championshipId: number;
  championship: any;
  competitions: Competition[] = [];
  wod: any;
  subDivisions: SubDivision[];

  // Tabs
  tabsFilter: string[] = [];
  tabIndexActive = 0;

  tableCatConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: [
      {
        type: 'reproved',
        title: 'Reprovar'
      }
    ],
    massActionCountTitle: {
      single: 'resultado selecionado',
      multiple: 'resultados selecionados'
    }
  };

  // Loadings and empty
  isLoadingCategories = false;
  isCategoryEmpty = false;

  isLoadingWods = false;

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private filterPipe: FilterPipe,
    private zone: NgZone,
    private changeDetectorRefs: ChangeDetectorRef,
    private competitionService: CompetitionService,
    private lotService: LotService,
    private wodService: WodService,
    private subDivisionHttpService: SubDivisionHttpService,
  ) { 
    this.translationLoader.loadTranslations(english, portugues);
    this.resgisterSubDivision();

    this.subscriptions.add(this.translateService.get('CAT_LOT_WOD').subscribe((res: any) => {
      this.translate = res;
    }));

    this.subscriptions.add(this.activatedRoute.params.subscribe(
      params => {
        this.championshipId = params.championship_id;
        this.fetchSubDivision();

        this.event = this.eventGuard.getActiveEvent().event;
        this.championship = this.eventGuard.getChampionshipPerId(this.championshipId);
        // this.competitions = this.championship.competitions

        if (this.translate) {
          this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE}`);

          this.breadcrumbsService.replaceAll([{
            text: this.translate.TITLE,
            route: `/championship/category/${this.championshipId}`
          }]);
        }
      }
    )); // end router

    this.tabsFilter = [
      this.translate.TABS.CATEGORY
    ];
    
    /*if (this.event.enabled_subscription) {
      this.tabsFilter.push(this.translate.TABS.LOTS)
    }*/
    
    if (this.event.enabled_leaderboard) {
      this.tabsFilter.push(this.translate.TABS.WODS);
    }
  }

  ngOnInit() {
    // Load initial and reload with alter championship
    this.loadInit();
    const subscription = this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.competitions = [];
          // this.tabBar.resetIndex()
          // this.tabIndexActive = 0
          this.loadInit();
        }
      });
    this.subscriptions.add(subscription);
  }

  private resgisterSubDivision(): void {
    const subscription = this.subDivisionHttpService
      .subDivisionObservable()
      .subscribe((results: SubDivision[]) => {
        this.subDivisions = results;
      });

    this.subscriptions.add(subscription);
  }

  private fetchSubDivision(): void {
    this.subDivisionHttpService
      .fetchSubDivision(this.championshipId);
  }
  
  loadInit(): any {
    // Get All competitions in championship
    this.isLoadingCategories = true;
    const subscription = this.competitionService.getCompetitions(this.championshipId)
      .subscribe(
        result => {
          console.log('result', result);
          this.competitions = result;
          this.isLoadingCategories = false;
          this.isCategoryEmpty = this.competitions.length === 0;
        }, err => {
          console.log('err', err);
          this.isLoadingCategories = false;
          this.isCategoryEmpty = true;
        }
      ); 
    this.subscriptions.add(subscription);
  }

  tabAction(tabEvent: any) {
    console.log('tab action', event);
    this.tabIndexActive = tabEvent.index;
    // this.competition = this.championship.competitions[event.index]
    // this.competitionId = this.championship.competitions[event.index].id

    // this.getRanking()
  }

  // Category
  createCategory() {
    const modalRef = this.dialog.open(ModalCategoryComponent, {
      width: '816px',
      height: '100%',
      panelClass: 'modal'
    });

    // modalRef.componentInstance.result = result
    modalRef.componentInstance.championshipId = this.championship.id;
    modalRef.componentInstance.eventId = this.event.id;
    modalRef.componentInstance.championship = this.championship;
    modalRef.componentInstance.subDivisions = this.subDivisions;

    const subscription = modalRef.afterClosed().subscribe(result => {
      modalRef.close();

      if (result && !result.isEdit) {
        this.eventGuard.updateCompetitionsInChampionship(this.championship.id , this.competitions);
        const com: Competition[] = result.competitions;
        com.forEach(competition => {
          const newCompetition = competition;
          newCompetition.canBeDeleted = true;
          this.competitions.push(newCompetition);
        });

        this.eventGuard.getEventUpdates();
        this.eventGuard.addCompetitionsInChampionship(this.championship.id, result.competitions);
        this.isCategoryEmpty = result.competitions === 0;
        if (this.tablesCompetition) {
          this.tablesCompetition.forEach(tableCompetition => {
            tableCompetition.reloadItems();
          });
        } 

        this.toastr.show(
          this.translate.CATEGORY.SUCCESS_CREATE_DESCRIPTION,
          this.translate.CATEGORY.SUCCESS_CREATE_TITLE,
          null,
          'success',
        );
      }
    });

    this.subscriptions.add(subscription);
  }


  editCategory(category: Competition) {
    const modalRef = this.dialog.open(ModalCategoryComponent, {
      width: '816px',
      height: '100%',
      panelClass: 'modal'
    });

    category.isOpen = true;
    modalRef.componentInstance.competitionEdit = this.competitions.filter((element) => category.id === element.id)[0];
    modalRef.componentInstance.isEdit = true;
    modalRef.componentInstance.championshipId = this.championship.id;
    modalRef.componentInstance.championship = this.championship;
    modalRef.componentInstance.subDivisions = this.subDivisions;

    const subscription = modalRef.afterClosed().subscribe(result => {
      if (result && result.isEdit) {
        this.eventGuard.updateCompetitionsInChampionship(this.championship.id , this.competitions);
        this.eventGuard.getEventUpdates();
        this.competitions.forEach((competition, index) => {
          if (competition.id === result.competition.id) {
            category = result.competition;
            result.competition.enable_sub_division = Number(result.competition.enable_sub_division);
            this.competitions[index] = result.competition;
          }
        });

        this.toastr.show(
          this.translate.CATEGORY.SUCCESS_EDIT_DESCRIPTION,
          this.translate.CATEGORY.SUCCESS_EDIT_TITLE,
          null,
          'success',
        );
      }
    });
    this.subscriptions.add(subscription);
  }

  deleteCategory(category: Competition) {

    if (!category.canBeDeleted) {
      this.toastr.show(
        this.translate.CATEGORY.TOASTR.DELETE.ERROR_DELETE_DESCRIPTION,
        this.translate.CATEGORY.TOASTR.DELETE.ERROR_DELETE_TITLE,
        null,
        'error',
      );
      return;
    }

    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    dialogRef.componentInstance.titleDialog = this.translate.CATEGORY.DIALOG.TITLE;
    this.translateService.get('CAT_LOT_WOD.CATEGORY.DIALOG.DESCRIPTION', { category_name: category.name }).subscribe((res: string) => {
      dialogRef.componentInstance.dialogContent = res;
    });
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.CATEGORY.DIALOG.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.CATEGORY.DIALOG.EXCLUIR.toUpperCase();
    dialogRef.componentInstance.object = category;
    dialogRef.componentInstance.confirmActionRequest = true;
    const subscription = dialogRef.componentInstance.dialogEvent.subscribe(result => {
      this.eventGuard.updateCompetitionsInChampionship(this.championship.id , this.competitions);
      dialogRef.componentInstance.confirmLoading = true;
      this.deleteCategoryInAPI(category, dialogRef);
      // console.log(result)
    });
    this.subscriptions.add(subscription);
  }

  deleteCategoryInAPI(category: Competition, dialogRef: MatDialogRef<CrossXDialogComponent>) {
    const subscription = this.competitionService.deleteCompetition(category.id)
      .subscribe(
        response => {
          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();

          const index = this.competitions.indexOf(category);
          this.competitions.splice(index, 1);

          if (this.tablesCompetition) {
            this.tablesCompetition.forEach(tableCompetition => {
              tableCompetition.reloadItems();
            });
          } 

          this.eventGuard.updateCompetitionsInChampionship(this.championship.id, this.competitions);
          this.isCategoryEmpty = this.competitions.length === 0;

          this.eventGuard.getEventUpdates();
          
          this.toastr.show(
            this.translate.CATEGORY.SUCCESS_DELETE_DESCRIPTION,
            this.translate.CATEGORY.SUCCESS_DELETE_TITLE,
            null,
            'success',
          );
        }, err => {
          console.log('err', err);
          const errors: Errors = new Errors().deserialize((err.error as any));

          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();
          
          this.toastr.show(
            errors.getFullMessages(),
            this.translate.CATEGORY.ERROR_DELETE_TITLE,
            null,
            'error',
          );
        }
      ); 

    this.subscriptions.add(subscription);
  }

  reorderAction(orders: any, parms: any) {
    console.log('reorder', orders);
    
    if (orders.type === 'reorder') {
      if (orders.tableName === 'categories') {
        const parameters = {
          competitions: orders.data
        };
        const subscription = this.competitionService.massUpdate(parameters)
          .subscribe(
            result => {
              console.log('result', result);
              this.eventGuard.updateCompetitionsInChampionship(this.championship.id, result);
            }, err => {
              console.log('err', err);
            }
          ); 

        this.subscriptions.add(subscription);
      }
    }
  }

  // Helper
  formatCurrency(value: string, currency: string): string {
    const unit = Utils.formatCurrency(value, currency);
    return unit ? unit : '';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
