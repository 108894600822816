<div class="card auto-width mb-32 mb-0" fxFlex="100">
  <div class="p-32 pb-16 pt-16" fxFlex="100" fxLayout="column">
    <form name="eventForm" [formGroup]="heatsGeneratorGroup" (ngSubmit)="onSubmit($event)" autocomplete="off" novalidate>
      <div
        fxFlex="100"
        fxFlex.xs="100"
        fxLayout="row"
        fxLayoutAlign="stretch flex-end"
      >
        <mat-form-field class="mr-16">
          <input
            matInput
            type="number"
            formControlName="numberHeats"
            placeholder="{{
            'HEAT_HEATCOMPETITORS.HEATS_GENERATOR.FIELDS.NUMBER_HEATS' | translate
            }}"
            required
          />
          <mat-error *ngIf="heatsGeneratorGroup.controls.numberHeats.hasError('required')">
            {{
            "HEAT_HEATCOMPETITORS.HEATS_GENERATOR.VALIDATIONS.NUMBER_HEATS_REQUIRED" | translate
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="mr-16">
          <input
            matInput
            type="number"
            formControlName="numberLanes"
            placeholder="{{
            'HEAT_HEATCOMPETITORS.HEATS_GENERATOR.FIELDS.NUMBER_LANES' | translate
            }}"
            required
          />
          <mat-error *ngIf="heatsGeneratorGroup.controls.numberLanes.hasError('required')">
            {{
            "HEAT_HEATCOMPETITORS.HEATS_GENERATOR.VALIDATIONS.NUMBER_LANES_REQUIRED" | translate
            }}
          </mat-error>
        </mat-form-field>
        <div class="mb-16">
          <btn
            [buttonDisabled]="heatsGeneratorGroup.invalid"
          >
            {{
            "HEAT_HEATCOMPETITORS.HEATS_GENERATOR.SUBMIT" | translate | uppercase
            }}
          </btn>
        </div>
      </div>
    </form>
  </div>
</div>
