export const locale = {
  lang: 'pt-BR',
  data: {
    'HEATS_TV': {
      'LANE': 'Lane',
      'STATE': 'State',
      'COMPETITOR': 'Competitor',
      'AFFILIATE': 'Affiliate',
      'SCORE': 'Score',
      'SETUP': 'Setup heats TV',
      'COLLAPSE': 'Collapse',
      'CURRENT_CHAMPIONSHIP': 'Select current championship',
      'TYPE': 'Select team type',
      'CURRENT_HEAT': 'Select current Heat',
      'SHOW_SCORE': 'Score',
      'UPDATE_HEAT': 'Update Heat',
    }
  }
};
