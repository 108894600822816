import { Utils } from './../utils';
import {Country} from './country';
export class User {
  id: number = null;
  email = '';
  facebook_id?: string;
  firstname = '';
  name = '';
  lastname = '';
  fullname = '';
  fullnameSort?: string;
  leaderboard_name?: string = null;
  gender?: number;
  gender_string?: string;
  cpf?: string;
  phone?: string;
  birthdate?: Date;
  city?: string;
  place_id?: string;
  state?: string;
  size?: string;
  box_id?: number;
  box?: any;
  avatar?: string;
  experience?: string;
  token?: string;
  type?: number;
  admin?: number;
  status?: number;
  country?: Country = null;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;

  constructor() {
    this.getName = this.getName.bind(this);
  }

  deserialize(input: any) {
    (<any>Object).assign(this, input);

    if (input){
      this.fullname = this.getName();
      this.fullnameSort = this.getName(true);
      if (input.gender !== null) { this.gender_string = (Number(input.gender) === 0) ? 'M' : 'F'; }
      this.country = (input.country !== null) ? new Country().deserialize(input.country) : null;
    }
    
    return this;
  }

  getName(sort: boolean = false) {
    if (sort) {
      const fullname = Utils.removeAccents(`${this.firstname} ${this.lastname}`);
      return fullname;
    }
    return `${this.firstname} ${this.lastname}`;
  }

  // get name() {
  //   return `${this.firstname} ${this.lastname}`;
  // }
}

export enum UserStatus {
  WITHOUT_EMAIL = 0,
  PENDING = 1,
  ACTIVE = 2,
  INACTIVE = 3
}
