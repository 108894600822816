<div mat-dialog-content class="dialog-container">
  <div class="modal-background" (click)="dialogRef.close()"></div>
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>{{ translate.TITLE | uppercase }}</h1>
      <span class="close" (click)="dialogRef.close()">
        <img src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>

    <div fxLayout="row" class="mt-16 mb-32">
      <description-section title="{{ translate.TITLE_DESCRIPTION }}" text="{{ translate.DESCRIPTION }}">
        <mat-icon class="ic-info mr-8" svgIcon="ic-info"></mat-icon>
      </description-section>
    </div>

    <form name="eventForm" [formGroup]="eventForm" autocomplete="off" novalidate>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="stretch stretch">
        <div fxFlex="50" fxFlex.xs="100" fxLayoutAlign="space-evenly stretch">
          <mat-form-field fxFlex="100">
            <input matInput placeholder="{{ translate.FIELDS.NAME }}" formControlName="name" uppercase required>
            <mat-error *ngIf="eventFormErrors.name.required">
              {{ translate.VALIDATIONS.NAME_REQUIRED }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.xs="100" fxLayoutAlign="space-evenly stretch" class="reset pl-16">
          <mat-form-field fxFlex="100">
            <input matInput [matAutocomplete]="autoUsers" autocomplete="off" placeholder="{{ translate.FIELDS.USER }}" formControlName="user"
              (ngModelChange)="searchUser($event)" required>
          
            <mat-autocomplete autoActiveFirstOption panelWidth="auto" #autoUsers="matAutocomplete" xPosition="before" [displayWith]="displayFnUser">
              <mat-option *ngFor="let user of users" [value]="user">
                <div fxLayout="row" fxLayoutAlign="start center" class="small-font">
                  <img src="{{ (user.avatar) ? user.avatar : null | placeholderImage:'user' }}" width="80"
                    class="avatar corner-radius" />
                  {{ user.firstname }} {{ user.lastname }} {{ user.email }}
                </div>
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="eventFormErrors.user.required">
              {{ translate.VALIDATIONS.USER_REQUIRED }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="stretch stretch">
        <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
          <!-- FIRST COLUMN -->
          <div fxFlex="50" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="space-evenly stretch">
            <mat-form-field>
              <input matInput [matAutocomplete]="autoAddress" autocomplete="off" placeholder="{{ translate.FIELDS.ADDRESS }}"
                formControlName="address" (ngModelChange)="searchAddress($event)">
    
              <mat-autocomplete autoActiveFirstOption panelWidth="auto" #autoAddress="matAutocomplete" xPosition="before" [displayWith]="displayFnAddress">
                <mat-option *ngFor="let city of cities" [value]="city" (onSelectionChange)="addressSelected(city)">
                  {{ city.description }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
    
            <mat-form-field>
              <input matInput placeholder="{{ translate.FIELDS.LOCATION }}" formControlName="location">
            </mat-form-field>
    
            <mat-form-field>
              <input matInput [matAutocomplete]="timezone" autocomplete="off" placeholder="{{ translate.FIELDS.TIMEZONE }}" formControlName="timezone">
              <mat-autocomplete #timezone="matAutocomplete" panelWidth="auto" xPosition="before">
                <mat-option *ngFor="let timezone of filterdTimezoneData | async" [value]="timezone.text" (onSelectionChange)="setTimezoneSelected(timezone)">
                  {{timezone.text}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="timezoneRequired">
                {{ translate.VALIDATIONS.TIMEZONE_REQUIRED }}
              </mat-error>
            </mat-form-field>
    
            <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center">
              <mat-form-field fxFlex="65" class="inputDate">
                <input matInput placeholder="{{translate.FIELDS.DATE_START}}" formControlName="dateStart" dropSpecialCharacters="false"
                  mask="00/00/0000">
                <!-- (ngModelChange)="verifyDate($event)" -->
                <mat-icon matSuffix>date_range</mat-icon>
                <mat-error *ngIf="eventFormErrors.dateStart && eventFormErrors.dateStart.invalid">
                  {{translate.VALIDATIONS.DATE_INVALID}}
                </mat-error>
              </mat-form-field>
    
              <select-time fxFlex="35" class="pl-12" label="{{translate.FIELDS.TIME_START }}" [value]="timeStart" (inputValue)="startTimeChange($event)"
                [required]="false"></select-time>
            </div>
    
            <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center">
              <mat-form-field fxFlex="65" class="inputDate">
                <input matInput placeholder="{{translate.FIELDS.DATE_END}}" formControlName="dateEnd" dropSpecialCharacters="false"
                  mask="00/00/0000">
                <!-- (ngModelChange)="verifyDate($event)" -->
                <mat-icon matSuffix>date_range</mat-icon>
                <mat-error *ngIf="eventFormErrors.dateEnd && eventFormErrors.dateEnd.invalid">
                  {{translate.VALIDATIONS.DATE_INVALID}}
                </mat-error>
              </mat-form-field>
    
              <select-time fxFlex="35" class="pl-12" label="{{translate.FIELDS.TIME_END }}" [value]="timeEnd" (inputValue)="endTimeChange($event)"
                [required]="false"></select-time>
            </div>
          </div>
    
          <!-- SECOND COLUMN -->
          <div fxFlex="50" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="space-around start">
            <agm-map fxFlex="100" class="ml-16 gmaps" [latitude]="lat" [longitude]="lng" [zoom]="15" [streetViewControl]="false">
              <agm-marker [latitude]="lat" [longitude]="lng" *ngIf="isShowMarker"></agm-marker>
            </agm-map>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="stretch stretch" class="mt-16">
        <div fxFlex="50" fxFlex.xs="100" fxLayoutAlign="space-evenly stretch">
          <mat-checkbox formControlName="subscription" fxFlex="100">
            {{ translate.FIELDS.SUBSCRIPTION }}
          </mat-checkbox>
        </div>
        <div fxFlex="50" fxFlex.xs="100" fxLayoutAlign="space-evenly stretch">
          <mat-checkbox formControlName="leaderboard" fxFlex="100">
            {{ translate.FIELDS.LEADERBOARD }}
          </mat-checkbox>
        </div>
      </div>
      
      <div fxLayout="column" class="mt-16" *ngIf="false">
        <h4 class="m-0 mb-8 textBlue">Gateway de pagamento</h4>
        <mat-radio-group formControlName="defaultGatewayId">
          <mat-radio-button [checked]="true" value="1" class="mr-20">Pagar.me</mat-radio-button>
          <mat-radio-button value="2">Zoop</mat-radio-button>
        </mat-radio-group>
      </div>

<!--      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="stretch stretch" class="mt-16" *ngIf="isShowGatewayId">
        <div fxFlex="50" fxFlex.xs="100">
          <mat-form-field fxFlex="100">
            <input matInput placeholder="{{translate.FIELDS.GATEWAY_ID}}" formControlName="eventGatewayId">
          </mat-form-field>
        </div>
      </div>-->
    </form>
    

    <div class="mb-0 mt-32" fxLayoutAlign="end center">
      <btn [btnLoading]="true" type="grayscale" (click)="dialogRef.close({ created: false })">{{ translate.CANCEL }}</btn>
      <btn [btnLoading]="true" [loading]="isLoading" class="ml-12" (click)="createEvent()" [buttonDisabled]="eventForm.invalid">{{ translate.SAVE | uppercase }}</btn>
    </div>
  </div>
</div>