import { Component, OnInit, ViewChild } from '@angular/core';

import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { MatDialogRef } from '../../../../../node_modules/@angular/material';
import { FuseTranslationLoaderService } from '../../services/translation-loader.service';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { AngularCropperjsComponent } from '../../../../../node_modules/angular-cropperjs';
import { DomSanitizer } from '../../../../../node_modules/@angular/platform-browser';

@Component({
  selector: 'app-crop-dialog',
  templateUrl: './crop-dialog.component.html',
  styleUrls: ['./crop-dialog.component.scss']
})
export class CropDialogComponent implements OnInit {

  @ViewChild('imageCropper') public imageCropper: AngularCropperjsComponent;
  public image: string
  public configCrop: any = {
    checkCrossOrigin: false,
    viewMode: 0,
    dragMode: 'move',
    zoomOnWheel: true,
    zoomable: true,
    aspectRatio: 500 / 500,
    cropBoxResizable: true,
    cropBoxMovable: true,
  }
  public width: number = 500
  public height: number = 500
  public minWidth: number = 500
  public minHeight: number = 500
  translate: any


  constructor(
    public dialogRef: MatDialogRef<CropDialogComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer
  )
  {
    this.translationLoader.loadTranslations(english, portugues);
  }

  ngOnInit() {
    this.translateService.get("CROP").subscribe((res: any) => {
      this.translate = res
    });
  }

  getImage() {
    // const imageData = this.imageCropper.cropper.getImageData();
    // const canvasData = this.imageCropper.cropper.getCanvasData({
    //   width: this.width,
    //   height: this.height,
    //   minWidth: this.minWidth,
    //   minHeight: this.minHeight,
    //   maxWidth: 1500,
    //   maxHeight: 1500,
    //   fillColor: '#fff',
    //   imageSmoothingEnabled: false,
    //   imageSmoothingQuality: 'high',
    // });
    // console.log(imageData)
    // console.log(canvasData)

    this.imageCropper.cropper.getCroppedCanvas({
      width: this.width,
      height: this.height,
      minWidth: this.minWidth,
      minHeight: this.minHeight,
      maxWidth: 1500,
      maxHeight: 1500,
      fillColor: '#fff',
      // imageSmoothingEnabled: true,
      // imageSmoothingQuality: 'high',
    }).toBlob((blob) => {
      // const formData = new FormData();
      // formData.append('croppedImage', blob);

      let urlCreator = window.URL;
      
      this.dialogRef.close({
        image: this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(blob)),
        blob: blob
      })

      // console.log(formData)
      console.log(blob)
    });
  }

}
