import { FuseNavigationModelInterface } from '../core/components/navigation/navigation.model';

export class FuseNavigationModel implements FuseNavigationModelInterface
{
    public model: any[];

    constructor()
    {
        this.model = [
          // {
          //   'id': 'components',
          //   'title': 'Dashboard',
          //   'translate': 'NAV.DASHBOARD.COMPONENTS',
          //   'type': 'item',
          //   'iconSvg': 'ic-menu-dashboard',
          //   'url': '/components'
          // },
          {
            'id': `user-dashboard-information`,
            'title': 'NAV.USER_DASHBOARD.INFORMATION',
            'type': 'item',
            'iconSvg': 'ic-menu-info',
            'url': `/user-dashboard/information`
          },
          {
            'id': 'dashboard',
            'title': 'Dashboard',
            'translate': 'NAV.DASHBOARD.TITLE',
            'type': 'item',
            'iconSvg': 'ic-menu-dashboard',
            'url': '/dashboard',
            'hidden': true
          },
          {
            'id': 'event-config',
            'title': 'Configurações',
            'translate': 'NAV.EVENTCONFIG.TITLE',
            'type': 'collapse',
            'iconSvg': 'ic-menu-info',
            'children': []
          },
          {
            'id': 'cupons',
            'title': 'Cupons',
            'translate': 'NAV.CUPONS.TITLE',
            'type': 'item',
            'iconSvg': 'ic-menu-cupons',
            'url': '/coupons'
          },
          {
            'id': 'points',
            'title': 'Pontos',
            'translate': 'NAV.POINTS.TITLE',
            'type': 'item',
            'iconSvg': 'ic-menu-points',
            'url': '/points'
          },
          {
            'id': 'subscriptions',
            'title': 'Inscrições',
            'translate': 'NAV.SUBSCRIPTIONS.TITLE',
            'type': 'item',
            'iconSvg': 'ic-menu-user',
            'url': '/subscriptions'
          },
          {
            'id': 'championships',
            'title': 'Etapas',
            'translate': 'NAV.CHAMPIONSHIPS.TITLE',
            'type': 'group',
            'children': []
          }
        ];
    }
}
