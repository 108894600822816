import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'label-x',
  templateUrl: './label-x.component.html',
  styleUrls: ['./label-x.component.scss']
})
export class LabelXComponent implements OnInit {

    @Input() type: string
    @Input() color: string
    @Input() backgroundColor: string
    
    constructor() { }

    ngOnInit() {
    }

}
