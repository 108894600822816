<div mat-dialog-content class="dialog-container">
  <div class="modal-background" (click)="dialogRef.close()"></div>
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>MODAL TITLE</h1>
      <span class="close" (click)="dialogRef.close()"><img src="./assets/icons/modal/ic-close.svg" /></span>
    </div>
     
    <div fxLayout="column">
      <h1 class="m-0 mb-4">TESTE PORRA</h1>
      Lorem ipsum dolor sit amet, mel cu quis omnium, qui ea quem tacimates, vix ei omnes propriae postulant. Nam id tation mucius
      equidem. Alia porro platonem usu ex. Eu vix quaeque nominati recteque. His eruditi patrioque id, an audire volumus cum, pri
      laboramus vituperata ad. Labores reprehendunt ad ius, sea et discere adipisci theophrastus, mundi vivendum praesent ei cum.
      Te est deleniti salutandi, eos nisl brute phaedrum ad, his fuisset consequat voluptatum te. Ei usu primis dictas incorrupte.
      In eruditi nominavi tincidunt sea. Eum tantas decore putent id. Id sit commune mediocritatem, ei mei tota everti probatus,
      in iudico invidunt elaboraret his. Ne has liber persius perpetua, lorem neglegentur mei in, ne per unum audire. Et qui dicit
      vocibus principes, vim iudico prompta graecis eu. Id sea euripidis neglegentur. Pro omnesque laboramus ut. Vix aperiri laoreet
      dolores ex, choro sensibus has te. At erat expetenda his, at ius persius dignissim vituperatoribus. Albucius voluptaria et
      eos, erat interesset sit et, vel ad novum patrioque euripidis. Ei sumo porro eam, mei ut sale graece. Tibique neglegentur
      ut mei, an usu probo commodo scribentur. Vim in etiam bonorum sapientem, eu nam homero nullam. Eros dolorem cu sed.<br><br><br><br><br>
      Lorem ipsum dolor sit amet, mel cu quis omnium, qui ea quem tacimates, vix ei omnes propriae postulant. Nam id tation mucius
      equidem. Alia porro platonem usu ex. Eu vix quaeque nominati recteque. His eruditi patrioque id, an audire volumus cum, pri
      laboramus vituperata ad. Labores reprehendunt ad ius, sea et discere adipisci theophrastus, mundi vivendum praesent ei cum.
      Te est deleniti salutandi, eos nisl brute phaedrum ad, his fuisset consequat voluptatum te. Ei usu primis dictas incorrupte.
      In eruditi nominavi tincidunt sea. Eum tantas decore putent id. Id sit commune mediocritatem, ei mei tota everti probatus,
      in iudico invidunt elaboraret his. Ne has liber persius perpetua, lorem neglegentur mei in, ne per unum audire. Et qui dicit
      vocibus principes, vim iudico prompta graecis eu. Id sea euripidis neglegentur. Pro omnesque laboramus ut. Vix aperiri laoreet
      dolores ex, choro sensibus has te. At erat expetenda his, at ius persius dignissim vituperatoribus. Albucius voluptaria et
      eos, erat interesset sit et, vel ad novum patrioque euripidis. Ei sumo porro eam, mei ut sale graece. Tibique neglegentur
      ut mei, an usu probo commodo scribentur. Vim in etiam bonorum sapientem, eu nam homero nullam. Eros dolorem cu sed.
      <br>
      <br>
      <br>
      <br>
      <br>
      Lorem ipsum dolor sit amet, mel cu quis omnium, qui ea quem tacimates, vix ei omnes propriae postulant. Nam id tation mucius
      equidem. Alia porro platonem usu ex. Eu vix quaeque nominati recteque. His eruditi patrioque id, an audire volumus cum, pri
      laboramus vituperata ad. Labores reprehendunt ad ius, sea et discere adipisci theophrastus, mundi vivendum praesent ei cum.
      Te est deleniti salutandi, eos nisl brute phaedrum ad, his fuisset consequat voluptatum te. Ei usu primis dictas incorrupte.
      In eruditi nominavi tincidunt sea. Eum tantas decore putent id. Id sit commune mediocritatem, ei mei tota everti probatus,
      in iudico invidunt elaboraret his. Ne has liber persius perpetua, lorem neglegentur mei in, ne per unum audire. Et qui dicit
      vocibus principes, vim iudico prompta graecis eu. Id sea euripidis neglegentur. Pro omnesque laboramus ut. Vix aperiri laoreet
      dolores ex, choro sensibus has te. At erat expetenda his, at ius persius dignissim vituperatoribus. Albucius voluptaria et
      eos, erat interesset sit et, vel ad novum patrioque euripidis. Ei sumo porro eam, mei ut sale graece. Tibique neglegentur
      ut mei, an usu probo commodo scribentur. Vim in etiam bonorum sapientem, eu nam homero nullam. Eros dolorem cu sed.
      <br>
      <br>
      <br>
      <br>
      <br>
      Lorem ipsum dolor sit amet, mel cu quis omnium, qui ea quem tacimates, vix ei omnes propriae postulant. Nam id tation mucius
      equidem. Alia porro platonem usu ex. Eu vix quaeque nominati recteque. His eruditi patrioque id, an audire volumus cum, pri
      laboramus vituperata ad. Labores reprehendunt ad ius, sea et discere adipisci theophrastus, mundi vivendum praesent ei cum.
      Te est deleniti salutandi, eos nisl brute phaedrum ad, his fuisset consequat voluptatum te. Ei usu primis dictas incorrupte.
      In eruditi nominavi tincidunt sea. Eum tantas decore putent id. Id sit commune mediocritatem, ei mei tota everti probatus,
      in iudico invidunt elaboraret his. Ne has liber persius perpetua, lorem neglegentur mei in, ne per unum audire. Et qui dicit
      vocibus principes, vim iudico prompta graecis eu. Id sea euripidis neglegentur. Pro omnesque laboramus ut. Vix aperiri laoreet
      dolores ex, choro sensibus has te. At erat expetenda his, at ius persius dignissim vituperatoribus. Albucius voluptaria et
      eos, erat interesset sit et, vel ad novum patrioque euripidis. Ei sumo porro eam, mei ut sale graece. Tibique neglegentur
      ut mei, an usu probo commodo scribentur. Vim in etiam bonorum sapientem, eu nam homero nullam. Eros dolorem cu sed.
      <br>
      <br>
      <br>
      <br>
      <br>
      Lorem ipsum dolor sit amet, mel cu quis omnium, qui ea quem tacimates, vix ei omnes propriae postulant. Nam id tation mucius
      equidem. Alia porro platonem usu ex. Eu vix quaeque nominati recteque. His eruditi patrioque id, an audire volumus cum, pri
      laboramus vituperata ad. Labores reprehendunt ad ius, sea et discere adipisci theophrastus, mundi vivendum praesent ei cum.
      Te est deleniti salutandi, eos nisl brute phaedrum ad, his fuisset consequat voluptatum te. Ei usu primis dictas incorrupte.
      In eruditi nominavi tincidunt sea. Eum tantas decore putent id. Id sit commune mediocritatem, ei mei tota everti probatus,
      in iudico invidunt elaboraret his. Ne has liber persius perpetua, lorem neglegentur mei in, ne per unum audire. Et qui dicit
      vocibus principes, vim iudico prompta graecis eu. Id sea euripidis neglegentur. Pro omnesque laboramus ut. Vix aperiri laoreet
      dolores ex, choro sensibus has te. At erat expetenda his, at ius persius dignissim vituperatoribus. Albucius voluptaria et
      eos, erat interesset sit et, vel ad novum patrioque euripidis. Ei sumo porro eam, mei ut sale graece. Tibique neglegentur
      ut mei, an usu probo commodo scribentur. Vim in etiam bonorum sapientem, eu nam homero nullam. Eros dolorem cu sed.
      <br>
      <br>
      <br>
      <br>
      <br>
      Lorem ipsum dolor sit amet, mel cu quis omnium, qui ea quem tacimates, vix ei omnes propriae postulant. Nam id tation mucius
      equidem. Alia porro platonem usu ex. Eu vix quaeque nominati recteque. His eruditi patrioque id, an audire volumus cum, pri
      laboramus vituperata ad. Labores reprehendunt ad ius, sea et discere adipisci theophrastus, mundi vivendum praesent ei cum.
      Te est deleniti salutandi, eos nisl brute phaedrum ad, his fuisset consequat voluptatum te. Ei usu primis dictas incorrupte.
      In eruditi nominavi tincidunt sea. Eum tantas decore putent id. Id sit commune mediocritatem, ei mei tota everti probatus,
      in iudico invidunt elaboraret his. Ne has liber persius perpetua, lorem neglegentur mei in, ne per unum audire. Et qui dicit
      vocibus principes, vim iudico prompta graecis eu. Id sea euripidis neglegentur. Pro omnesque laboramus ut. Vix aperiri laoreet
      dolores ex, choro sensibus has te. At erat expetenda his, at ius persius dignissim vituperatoribus. Albucius voluptaria et
      eos, erat interesset sit et, vel ad novum patrioque euripidis. Ei sumo porro eam, mei ut sale graece. Tibique neglegentur
      ut mei, an usu probo commodo scribentur. Vim in etiam bonorum sapientem, eu nam homero nullam. Eros dolorem cu sed.
      <br>
      <br>
      <br>
      <br>
      <br>
    </div>
    <div class="mb-0" fxLayoutAlign="end center">
      <btn class="mr-16" (click)="dialogRef.close({id: 25, type: 'type'})">FECHAR PORRA</btn>
    </div>
  </div>
</div>