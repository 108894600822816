<div fxLayoutAlign="center center" class="p-64" *ngIf="isLoading">
  <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
</div>

<ng-container *ngIf="!isLoading">
  <div class="p-32 pt-16 pb-0">
    <h4>{{ 'SUBSCRIPTIONS.BASIC_INFORMATION' | translate | uppercase }}</h4>
  </div>
  
  <table-x #tableBasic [items]="customFieldsBasic" [config]="tableConfig" [isLoading]="isLoading" tableName="custom-fields-basic" [isDraggable]="false" *ngIf="!isLoading">
    <column property="name" header="{{ 'SUBSCRIPTIONS.TABLE.NAME' | translate }}" [sortable]="false" align="left">
      <ng-template #dataTableCell let-item="item">
        {{ item.name }}
      </ng-template>
    </column>

    <column property="type" header="{{ 'SUBSCRIPTIONS.TABLE.TYPE' | translate }}" [sortable]="false" align="left">
      <ng-template #dataTableCell let-item="item">
        {{ getCustomFieldTypeName(item) }}
      </ng-template>
    </column>
  
    <column property="required" header="{{ 'SUBSCRIPTIONS.TABLE.REQUIRED' | translate }}" [sortable]="false" align="center" width="100px">
      <ng-template #dataTableCell let-item="item">
        <mat-slide-toggle
          [checked]="item.is_required"
          [disabled]="verifyIsDisabledSwitch(item)"
          (change)="onChangeSwitchRequired($event, 'required', item)">
        </mat-slide-toggle>
      </ng-template>
    </column>
  
    <column property="hidden" header="{{ 'SUBSCRIPTIONS.TABLE.VISIBLE' | translate }}" [sortable]="false" align="center" width="100px">
      <ng-template #dataTableCell let-item="item">
        <mat-slide-toggle
          [checked]="!item.is_hidden" [disabled]="item.default_field && !item.default_custom_field?.allow_hidden"
          (change)="onChangeSwitchRequired($event, 'hidden', item)">
        </mat-slide-toggle>
      </ng-template>
    </column>
  
    
    <column property="actions" header="" [sortable]="false" align="center" width="100px">
      <ng-template #dataTableCell let-item="item" let-index="index">
        <btn-transparent
          icon="common/ic-settings.svg" buttonWidth="35px" iconAlign="left"
          [matMenuTriggerFor]="customFieldActionMenu" [matMenuTriggerData]="{ customField: item, index: index, table: 'basic' }"
          *ngIf="showItemOption(item)"
        ></btn-transparent>
      </ng-template>
    </column>
  </table-x>
  
  
  <div class="p-32 pt-52 pb-0">
    <h4>{{ 'SUBSCRIPTIONS.ADDITIONAL_INFORMATION' | translate | uppercase }}</h4>
  </div>
  
  <table-x #tableAdditional [items]="customFieldsAdditional" [config]="tableConfig" [isLoading]="isLoading" tableName="custom-fields-additional" [isDraggable]="false" *ngIf="!isLoading">
    <column property="name" header="{{ 'SUBSCRIPTIONS.TABLE.NAME' | translate }}" [sortable]="false" align="left">
      <ng-template #dataTableCell let-item="item">
        {{ item.name }}
      </ng-template>
    </column>
    
    <column property="type" header="{{ 'SUBSCRIPTIONS.TABLE.TYPE' | translate }}" [sortable]="false" align="left">
      <ng-template #dataTableCell let-item="item">
        {{ getCustomFieldTypeName(item) }}
      </ng-template>
    </column>
  
    <column property="required" header="{{ 'SUBSCRIPTIONS.TABLE.REQUIRED' | translate }}" [sortable]="false" align="center" width="100px">
      <ng-template #dataTableCell let-item="item">
        <mat-slide-toggle
          [checked]="item.is_required"
          [disabled]="verifyIsDisabledSwitch(item)"
          (change)="onChangeSwitchRequired($event, 'required', item)">
        </mat-slide-toggle>
      </ng-template>
    </column>
  
    <column property="hidden" header="{{ 'SUBSCRIPTIONS.TABLE.VISIBLE' | translate }}" [sortable]="false" align="center" width="100px">
      <ng-template #dataTableCell let-item="item">
        <mat-slide-toggle
          [checked]="!item.is_hidden" [disabled]="item.default_field && !item.default_custom_field?.allow_hidden"
          (change)="onChangeSwitchRequired($event, 'hidden', item)">
        </mat-slide-toggle>
      </ng-template>
    </column>
  
  
    <column property="actions" header="" [sortable]="false" align="center" width="100px">
      <ng-template #dataTableCell let-item="item" let-index="index">
        <btn-transparent
          icon="common/ic-settings.svg" buttonWidth="35px" iconAlign="left"
          [matMenuTriggerFor]="customFieldActionMenu" [matMenuTriggerData]="{ customField: item, index: index, table: 'additional' }"
          *ngIf="showItemOption(item)"
        ></btn-transparent>
      </ng-template>
    </column>
  </table-x>
  
  <!-- MAT-MENU ACTIONS -->
  <mat-menu class="submenu bottom arrow-right" #customFieldActionMenu="matMenu" [overlapTrigger]="false" xPosition="before">
    <ng-template matMenuContent let-item="customField" let-index="index" let-table="table">
      
      <button mat-menu-item class="line-bottom" *ngIf="item.default_field && item.default_custom_field.id === 10" (click)="showBoxesList()">
        <span>{{ 'SUBSCRIPTIONS.ACTIONS.SEE_LIST' | translate }}</span>
      </button>
      
      <button mat-menu-item class="line-bottom" (click)="editCustomField(item, index, table)" *ngIf="!item.default_field || (item.default_field && item.default_custom_field.id === 9)">
        <span>{{ 'SUBSCRIPTIONS.ACTIONS.EDIT' | translate }}</span>
      </button>
      
      <button mat-menu-item class="line-bottom" (click)="deleteCustomField(item, index, table)" *ngIf="!item.default_field">
        <span class="text-red">{{ 'SUBSCRIPTIONS.ACTIONS.DELETE' | translate }}</span>
      </button>
    </ng-template>
  </mat-menu>

  <div class="p-32 pt-16" *ngIf="customFieldsCount < 3">
    <btn-icon-small icon="add_circle_outline" iconAlign="left" (click)="createCustomField()">{{ 'SUBSCRIPTIONS.ADD_CUSTOM_FIELD' | translate }}</btn-icon-small>
  </div>
</ng-container>
