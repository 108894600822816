import { environment } from './../../../environments/environment';
import { AuthenticationService } from './../services/authentication.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
import * as moment from 'moment';

// https://github.com/zoomsphere/ngx-store

@Injectable()
export class AuthGuard implements CanActivate {

  public storageSub = new Subject<any>();
  public eventStorageSub = new Subject<any>();

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate() {
    if (localStorage.getItem('current_user_crossplus')) {
      // if (!this.getMe().user.admin) {
      //   (<any>window).Conpass.init({
      //     name: `${this.getMe().user.fullname}`,
      //     email: this.getMe().user.email,
      //     userId: this.getMe().user.id,
      //     //  Informações opcionais (usado para segmentação)
      //     custom_fields: {
      //       system: 'crossx_campeonatos',
      //       user_status: this.getMe().user.status,
      //       user_gender: this.getMe().user.gender_description ? this.getMe().user.gender_description : null,
      //     }
      //   });  
      // }
      
      return true;
    }
    // not logged in so redirect to login page
    this.router.navigate(['/login']);
    return false;
  }

  canActivateWithoutRedirect(isScreenToken: boolean = false) {
    if (localStorage.getItem('current_user_crossplus')) {
      // logged in so return true
      this.router.navigate(['/myprofile'])
    }
    else {
      if (isScreenToken) {
        this.router.navigate(['/login'])
      }
    }
  }

  getToken() {
      if (localStorage.getItem('current_user_crossplus')) {
          let token = JSON.parse(localStorage.getItem('current_user_crossplus')).token
          return token
      }

      return null
  }

  setNewToken(newToken: string) {
    const token = newToken.replace('Bearer ', '')

    var currentUser = JSON.parse(localStorage.getItem('current_user_crossplus'))
    currentUser.token = token
    currentUser.user.token = token
    localStorage.removeItem('current_user_crossplus');
    localStorage.setItem('current_user_crossplus', JSON.stringify(currentUser));
    document.cookie = `crossplus_token=${token}; domain=${environment.domain}; expires=${moment().add(1, 'years').format('ddd[,] DD MMM YYYY HH:mm:ss')} UTC, path=/`;
    document.cookie = `crossplus_user=${JSON.stringify(currentUser.user)}; domain=${environment.domain}; expires=${moment().add(1, 'years').format('ddd[,] DD MMM YYYY HH:mm:ss')} UTC, path=/`;
    this.authenticationService.token = token
  }

  getMe() {
    if (localStorage.getItem('current_user_crossplus')) {
      let user = JSON.parse(localStorage.getItem('current_user_crossplus')).user
      // let accesses = JSON.parse(localStorage.getItem('current_user_crossplus')).accesses
      // let accessActive = JSON.parse(localStorage.getItem('current_user_crossplus')).accessActive
      return { user: user }
    }

    if (this.getCookie('crossplus_token')) {
      localStorage.setItem('current_user_crossplus', JSON.stringify({
        token: this.getCookie('crossplus_token'),
        user: JSON.parse(this.getCookie('crossplus_user'))
      }));

      let user = JSON.parse(localStorage.getItem('current_user_crossplus')).user
      return { user: user }
    }

    return { user: null }
  }
  
  updateMe(user: any) {
    const fullname = user.fullname ? user.fullname : `${user.firstname.toUpperCase()} ${user.lastname.toUpperCase()}`;
    let newUser: any = user;
    newUser.fullname = fullname;
    newUser.token = this.getToken();

    localStorage.setItem('current_user_crossplus', JSON.stringify({
      token: this.getToken(),
      user: newUser
    }));

    this.storageSub.next({
      type: 'updated',
      data: newUser
    });
  }

  //ACCESS
  getAccesses()
  {
    if (localStorage.getItem('current_user_crossplus')) {
      let accesses = JSON.parse(localStorage.getItem('current_user_crossplus')).accesses
      return accesses
    }
    return []
  }

  getAccessesActive() {
    if (localStorage.getItem('current_user_crossplus')) {
      let access = JSON.parse(localStorage.getItem('current_user_crossplus')).accessActive
      return access
    }
    return []
  }

  setAccessActive(access: any)
  {
    var currentUser = JSON.parse(localStorage.getItem('current_user_crossplus'))
    currentUser["accessActive"] = access
    localStorage.setItem('current_user_crossplus', JSON.stringify(currentUser));

    this.storageSub.next({
      type: 'accessActive',
      data: currentUser
    });
  }

  setAccessesList(accesses: any) {
    var currentUser = JSON.parse(localStorage.getItem('current_user_crossplus'))
    currentUser["accesses"] = accesses
    localStorage.setItem('current_user_crossplus', JSON.stringify(currentUser));

    this.storageSub.next({
      type: 'accessList',
      data: currentUser
    });
  }

  //User Logout
  logOut() {
    // clear token remove user from local storage to log user out
    this.authenticationService.logOut();
  }

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  watchEventStorage(): Observable<any> {
    return this.eventStorageSub.asObservable();
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}

