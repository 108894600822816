<div class="page-layout blank pb-64" id="scrollBar">
  <div class="p-32">
    <div class="content">
      <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
        <div class="pt-16">
          <tab-bar #tabbar [padding]="true" (tabEvent)="tabAction($event)" [items]="tabsFilter" [tabActive]="0">
          </tab-bar>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between start" class="pl-32 pr-32 pt-16 pb-12 mb-16 search line-bottom">
          <mat-form-field fxFlex="50" fxFlex.xs="100" floatLabel="never" class="search">
            <mat-icon matPrefix class="icon-search">search</mat-icon>
            <input matInput placeholder="{{ 'COUPONS.SEARCH' | translate }}" [(ngModel)]="searchTerm"
              (ngModelChange)="searchName($event)">
          </mat-form-field>

          <btn [btnLoading]="true" [loading]='isLoadingExport' type="grayscale" icon="content/ic-export.svg" iconAlign="left"
            (click)="exportCompetitors()" *ngIf="!isEmptyResult" fxHide.xs>{{ 'COUPONS.EXPORT' | translate}}</btn>
        </div>

        <!-- Empty list -->
        <div fxLayout="column" fxLayoutAlign="center center" class="p-32 emtpy-content" *ngIf="isEmptyResult">
          <img src="./assets/icons/placeholder/ic-empty.svg" />
          <span class="mt-8">{{ 'COUPONS.EMPTY' | translate }}</span>
        </div>

        <!-- Table -->
        <table-x #table [items]="coupons" [config]="tableConfig" [isLoading]="isLoading" *ngIf="!isEmptyResult"
          (sortEvent)="sortAction($event)">

          <column property="name" header="{{ 'COUPONS.TABLE_HEADER.NAME' | translate }}" [sortable]="true" align="left" classesBody="pt-20 pb-20">
            <ng-template #dataTableCell let-item="item" let-index="index">
              <a (click)="editCoupon(item, index)" class="small-font">
                {{ item.name }}
              </a>
            </ng-template>
          </column>

          <column property="promocode" header="{{ 'COUPONS.TABLE_HEADER.CODE' | translate }}" [sortable]="true" align="left">
            <ng-template #dataTableCell let-item="item">
              {{ item.promocode }}
            </ng-template>
          </column>

          <column property="value" header="{{ 'COUPONS.TABLE_HEADER.VALUE' | translate }}" [sortable]="false" align="center">
            <ng-template #dataTableCell let-item="item">
              <span *ngIf="item.type === 0">{{ item.value }}%</span>
              <span *ngIf="item.type === 1">{{ formatCurrency((item.value | currency:('COUPONS.CURRENCY_CODE' | translate):'symbol-narrow':'1.2-2'), ('COUPON.CURRENCY_CODE' | translate)) }}</span>
            </ng-template>
          </column>

          <column property="start" header="{{ 'COUPONS.TABLE_HEADER.DATE_START' | translate }}" [sortable]="false" align="left">
            <ng-template #dataTableCell let-item="item">
              {{ item.start | date:('COUPONS.DATETIME_FORMAT' | translate) }}
            </ng-template>
          </column>
          
          <column property="end" header="{{ 'COUPONS.TABLE_HEADER.DATE_END' | translate }}" [sortable]="false" align="left">
            <ng-template #dataTableCell let-item="item">
              {{ item.end | date:('COUPONS.DATETIME_FORMAT' | translate) }}
            </ng-template>
          </column>

          <column property="count" header="{{ 'COUPONS.TABLE_HEADER.COUNT' | translate }}" [sortable]="false" align="center">
            <ng-template #dataTableCell let-item="item">
              {{ item.limit !== null ? item.subscribed + ' / '+ item.limit : item.subscribed }}
            </ng-template>
          </column>

          <column property="actions" header="" [sortable]="false" align="center" width="100px">
            <ng-template #dataTableCell let-item="item" let-index="index">
              <btn-transparent icon="common/ic-settings.svg" buttonWidth="35px" iconAlign="left" [matMenuTriggerFor]="couponsActionMenu"
                class="hide-in-drag" [matMenuTriggerData]="{ coupon: item, index: index }"></btn-transparent>
            </ng-template>
          </column>
        </table-x>

        <!-- MAT-MENU ACTIONS -->
        <mat-menu class="submenu bottom arrow-right" #couponsActionMenu="matMenu" [overlapTrigger]="false" xPosition="before">
          <ng-template matMenuContent let-competition="competition" let-coupon="coupon" let-index="index">
            <button mat-menu-item class="line-bottom" (click)="deleteCoupon(coupon, index)">
              <span class="text-red">{{ 'COUPONS.DELETE' | translate }}</span>
            </button>
            <button mat-menu-item class="line-bottom" (click)="activeInactiveCoupon(coupon, index, 'inactive')" *ngIf="type !== 'inactive'">
              <span>{{ 'COUPONS.INACTIVE' | translate }}</span>
            </button>
            <button mat-menu-item class="line-bottom" (click)="activeInactiveCoupon(coupon, index, 'active')" *ngIf="type === 'inactive'">
              <span>{{ 'COUPONS.ACTIVE' | translate }}</span>
            </button>
          </ng-template>
        </mat-menu>

        <button mat-fab (click)="addNew()" *ngIf="type !== 'inactive'">
          <mat-icon class="mat-30">add</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>


<scroll-to-top scrollId="scrollBar" [animate]="true" [speed]="50" [acceleration]="2" [scrollDistance]="300"
  (scrollEvent)="scrollEvent($event)" [scrollInfinite]="true" [scrollRetriveDistance]="400"></scroll-to-top>