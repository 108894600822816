export enum HeatQueryParams {
  TYPE = 'type',
  WODS_ID = 'wods_id',
  CHAMPIONSHIP_ID = 'championship_id',
  COMPETITION_ID = 'competition_id',
  NAME = 'name',
  EXCLUDE_TEAM_ID = 'exclude_team_id',
  TYPE_SINGLE = 'single',
  TYPE_TEAM = 'team',
  CHAMPIONSHIPID = 'championshipId'
}
