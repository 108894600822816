export const locale = {
  lang: 'pt-BR',
  data: {
    'HEATS': {
      'CHAMPIONSHIP': 'Etapas',
      'HEAT': 'Bateria',
      'TABS': {
        'SINGLE': 'Individual',
        'TEAM': 'Time'
      },
      'DATE_FORMAT': 'dd/MM/yyyy',
      'TABLE': {
        'LANE': 'Raia',
        'ATHLETE': 'Atleta',
        'WOD': 'Wod',
        'BOX': 'Box'
      },
      'EMPTY': 'Competidores não publicados'
    }
  }
};
