import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'btn-icon-small',
  templateUrl: './btn-icon-small.component.html',
  styleUrls: ['./btn-icon-small.component.scss']
})
export class BtnIconSmallComponent implements OnInit {
  @Input() icon: string
  @Input() iconPath: string
  @Input() iconAlign: string = null
  
  constructor() { }

  ngOnInit() {
  }

}
