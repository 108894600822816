export const locale = {
  lang: 'pt-BR',
  data: {
    'MYPROFILE': {
      'TITLE': 'Perfil',
      'DESCRIPTION': 'Mantenha seus dados sempre atualizados.',
      'BASIC_INFO': 'Informações básicas',
      'AGE': 'Idade',
      'NAME': 'Nome',
      'LAST_NAME': 'Sobrenome',
      'USERNAME': 'Username',
      'EMAIL': 'E-mail',
      'PASSWORD': 'Senha',
      'CONFIRM_PASSWORD': 'Confirmar senha',
      'GENDER': 'Sexo',
      'GENDER_MALE': 'Masculino',
      'GENDER_FEMALE': 'Feminino',
      'BIRTHDAY': 'Data de nascimento',
      'BOX': 'Box',
      'CITY': 'Local de residência',
      'ACCEPT': 'Aceito os',
      'TERMS': 'termos de uso',
      'NAME_REQUIRED': 'Nome é obrigatório',
      'LAST_NAME_REQUIRED': 'Sobrenome é obrigatório',
      'USERNAME_REQUIRED': 'Username é obrigatório',
      'EMAIL_REQUIRED': 'E-mail é obrigatório',
      'EMAIL_INVALID': 'E-mail inválido',
      'PASSWORD_REQUIRED': 'Senha obrigatória',
      'PASSWORD_MINLENGHT': 'A senha deve conter pelo menos 6 caracters',
      'CONFIRM_PASSWORD_REQUIRED': 'Confirmação de senha obrigatória',
      'CONFIRM_PASSWORD_INVALID': 'As senhas não são iguais',
      'GENDER_REQUIRED': 'Sexo é obrigatório',
      'BIRTHDAY_REQUIRED': 'Data de nascimento é obrigatório',
      'BIRTHDAY_INVALID': 'Data inválida',
      'CITY_REQUIRED': 'A cidade é obrigatório',
      'BOX_EMPTY': 'Caso não tenha box deixar o campo vazio',
      'REGISTER': 'CADASTRAR',
      'BACK': 'VOLTAR',
      'DATE_FORMAT': 'DD/MM/YYYY',
      'FORM': {
        'TEAM_NAME': 'Nome do time',
        'TEAM_BOX': 'Box',
        'EMAIL': 'E-mail',
        'PASSWORD': 'Senha',
        'CONFIRM_PASSWORD': 'Confirmar senha',
        'FIRSTNAME': 'Nome',
        'LASTNAME': 'Sobrenome',
        'BOX': 'Box',
        'CPF': 'CPF',
        'PHONE': 'Telefone',
        'BIRTHDATE': 'Data de nascimento',
        'GENDER': 'Sexo',
        'TSHIRT': 'Tamanho da camiseta',
        'CITY': 'Cidade',
        'INSTALLMENTS': 'Parcelas',
        'CARD_NUMBER': 'Número do cartão',
        'EXPIRATION_DATE': 'Data de vencimento',
        'CVV': 'Código de segurança',
        'NAME_COMPLETED': 'Nome completo',
        'ADDRESS': 'Endereço',
        'ADDRESS_NUMBER': 'Número',
        'DISTRICT': 'Bairro',
        'STATE': 'Estado',
        'ZIPCODE': 'CEP'
      },
      'VALIDATIONS': {
        'TEAM_NAME_REQUIRED': 'O nome do time é obrigatório',
        'TEAM_BOX_REQUIRED': 'O box do time é obrigatório',
        'EMAIL_REQUIRED': 'O campo e-mail é obrigatório',
        'EMAIL_INVALID': 'O formato de e-mail é inválido',
        'PASSWORD_REQUIRED': 'O campo senha é obrigatório',
        'PASSWORD_MINLENGHT': 'A senha deve conter pelo menos 6 caracters',
        'CONFIRM_PASSWORD_REQUIRED': 'Confirmação de senha obrigatória',
        'CONFIRM_PASSWORD_INVALID': 'As senhas não são iguais',
        'FIRSTNAME_REQUIRED': 'O campo nome é obrigatório',
        'LASTNAME_REQUIRED': 'O campo sobrenome é obrigatório',
        'CPF_REQUIRED': 'O CPF é obrigatório',
        'BOX_REQUIRED': 'O box é obrigatório',
        'CITY_REQUIRED': 'O campo cidade é obrigatório',
        'GENDER_REQUIRED': 'O campo sexo é obrigatório',
        'TSHIRT_REQUIRED': 'O tamanho da camiseta é obrigatório',
        'DATE_INVALID': 'O formato da data é inválido',
        'CREDIT_CARD_REQUIRED': 'Número do cartão obrigatório',
        'CREDIT_CARD_INVALID': 'Número do cartão inválido',
        'EXPIRATION_REQUIRED': 'Validade obrigatória',
        'CVV_REQUIRED': 'Campo obrigatório',
        'HOLD_NAME_REQUIRED': 'Nome impresso no cartão obrigatório',
        'HOLD_CPF_REQUIRED': 'CPF do dono do cartão obrigatório',
        'MIN_MEMBERS_REQUIRED': 'Está faltando membro no time',
        'REGULATION_REQUIRED': 'É obrigatório aceitar os termos do regulamento',
        'FULLNAME_NFE_REQUIRED': 'O nome para a nota fiscal é obrigatório.',
        'CPF_NFE_REQUIRED': 'O cpf para a nota fiscal é obrigatório.',
        'EMAIL_NFE_REQUIRED': 'O e-mail para a nota fiscal é obrigatório.',
        'EMAIL_NFE_INVALID': 'Formato de e-mail inválido',
        'ADDRES_NFE_REQUIRED': 'O endereço para a nota fiscal é obrigatório.',
        'ADDRESNUMBER_NFE_REQUIRED': 'O número para a nota fiscal é obrigatório.',
        'CITY_NFE_REQUIRED': 'A cidade para a nota fiscal é obrigatório.',
        'DISTRICT_NFE_REQUIRED': 'O bairro para a nota fiscal é obrigatório.',
        'ZIPCODE_NFE_REQUIRED': 'O CEP para a nota fiscal é obrigatório.',
        'STATE_NFE_REQUIRED': 'O estado para a nota fiscal é obrigatório.',
      },
      'MANAGER_EVENTS': {
        'TITLE': 'Gerenciar eventos',
        'DESCRIPTION': 'Lista dos eventos que você pode gerenciar.',
      },
      'MY_EVENTS': {
        'TITLE': 'Campeonatos',
        'DESCRIPTION': 'Lista de campeonatos que você já participou, veja os resultados, wods e leaderboard.',
      },
      'SEE_MORE': 'VER MAIS',
      'TABLE_NAME': 'Nome',
      'TABLE_DATA': 'Data',
      'TABLE_STATUS': 'Status',
      'STATUS': {
        'ACTIVE': 'Ativo',
        'PAST': 'Passado',
        'NOT_PUBLISHED': 'Não Publicado'
      },
      'TABLE_RANKING': 'Ranking',
      'TABLE_YEAR': 'Ano',
      'TOASTR': {
        'PROFILE': {
          'SUCCESS': {
            'TITLE': 'Perfil atualizado com sucesso!',
            'MESSAGE': 'Seu perfil foi atualizado.'
          },
          'ERROR': {
            'TITLE': 'Erro ao atualizar o perfil!',
          }
        },
        'EVENT_IS_PAST': {
          'TITLE': 'Não é possível acessar o evento!',
          'DESCRIPTION': 'Não é possível acessar um evento que já passou.'
        }
      },
      'DIALOG': {
        'ALTER_EVENTS': {
          'TITLE': 'Você deseja atualizar suas informações nos eventos?',
          'DESCRIPTION': 'Você está atualizando informações pessoas, você deseja alterar essas informações nos eventos que ainda não acontecerão?',
          'DONT_ALTER': 'Não alterar',
          'CONFIRM': 'Alterar nos eventos'
        }
      },
      'TABS' : {
        'LIVE': 'Ao vivo/futuros',
        'PAST': 'Passado'
      },
      'SEARCH': 'Procure pelo nome do evento',
      'LIST_EVENT': {
        'TITLE1': 'Gerenciar eventos',
        'TITLE2': 'Campeonatos'
      }
    }
  }
};
