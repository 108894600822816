import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup} from "@angular/forms";
import {locale as english} from "./i18n/en-US";
import {locale as portugues} from "./i18n/pt-BR";
import {FuseTranslationLoaderService} from "../../../../../../core/services/translation-loader.service";
import {TranslateService} from "@ngx-translate/core";
import {Result} from "../../../../../../core/model/result";
import {ResultService} from "../../../../../../core/services/result/result.service";
import {Errors} from "../../../../../../core/model/errors";

@Component({
  selector: 'app-modal-comments',
  templateUrl: './modal-comments.component.html',
  styleUrls: ['./modal-comments.component.scss']
})
export class ModalCommentsComponent implements OnInit, OnDestroy {
  result: Result;
  isLoading = false;
  commentForm: FormGroup;
  errorMessage = '';
  private translate: any;
  private subscriptions = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<ModalCommentsComponent>,
    private readonly translationLoader: FuseTranslationLoaderService,
    private readonly translateService: TranslateService,
    private readonly resultService: ResultService,
    private readonly formBuilder: FormBuilder
  ) {
    this.registerTranslate();
  }

  ngOnInit(): void {
    this.registerFormComment();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private registerFormComment(): void {
    this.commentForm = this.formBuilder.group({
      description: [this.result && this.result.result && this.result.result.description ? this.result.result.description : null]
    });
  }

  private registerTranslate(): void {
    this.translationLoader.loadTranslations(english, portugues);
    this.subscriptions.add(
      this.translateService
        .get('RESULT_COMMENT')
        .subscribe((res: any) => {
          this.translate = res;
        })
    );
  }

  submitFormComment(): void {
    if (this.commentForm.invalid) {
      return;
    }
    
    const { description } = this.commentForm.value;
    const parameters = {
      video: this.result.result.video,
      status: this.result.result.status,
      description,
      published: 0
    };

    this.isLoading = true;

    this.resultService
      .updateResult(parameters, this.result.result.id)
      .toPromise()
      .then((data) => {
        this.isLoading = false;
        this.result.result.published = data.published;
        this.result.result.description = data.description;
        this.dialogRef.close({update: true});
      })
      .catch((error) => {
        this.isLoading = false;
        const errors: Errors = new Errors().deserialize((error.error as any));
        this.errorMessage = errors.getFullMessages();
      })
    
  }

}
