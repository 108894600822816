import {Component, OnInit, ViewChild} from '@angular/core';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import {TableConfig, TableXComponent} from '../../../../../core/components/table-x/table-x.component';
import {MatDialogRef, MatIconRegistry} from '@angular/material';
import {FuseTranslationLoaderService} from '../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {BoxService} from '../../../../../core/services/box.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomFieldType} from '../../../../../core/model/custom-field-type.model';
import {forEach} from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-model-boxes-list',
  templateUrl: './model-boxes-list.component.html',
  styleUrls: ['./model-boxes-list.component.scss']
})
export class ModelBoxesListComponent implements OnInit {

  @ViewChild('table', { read: TableXComponent }) table: TableXComponent;
  private subscriptions: Subscription = new Subscription();
  private subscriptionRequest: Subscription = new Subscription();
  
  //Translate
  translate: any

  public searchForm: FormGroup;
  public boxes: any[] = []
  public isLoading: boolean = false;
  private timeOutBoxSearch
  private page: number = 1;
  private term: string = ''

  //Table
  public tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: false,
    massActions: null,
    massActionCountTitle: null
  };
  private loadedEnd: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModelBoxesListComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private formBuilder: FormBuilder,
    private boxService: BoxService
  ) { }

  ngOnInit() {
    this.registerOnTranslate();
    this.registerOnFetchCustomFieldObservable();
    this.getBoxes();
    this.configForm();
    this.registerOnSearchChange();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
    this.subscriptionRequest.unsubscribe();
  }

  private registerOnSearchChange() {
    const subscription = this.searchForm
      .get('term')
      .valueChanges
      .subscribe(term => {
        clearTimeout(this.timeOutBoxSearch)
        this.timeOutBoxSearch = setTimeout(() => {
          if (term.length > 1) {
            this.subscriptionRequest.unsubscribe();
            this.isLoading = true;
            this.page = 1;
            this.boxes = [];
            this.term = term;
            this.subscriptionRequest.add(this.boxService.fetchBoxesWithName(this.term, this.page));
          }
        }, 1000);
      });
    this.subscriptions.add(subscription);
  }

  private configForm(): void {
    this.searchForm = this.formBuilder.group({
      term: '',
    });
  }

  private registerOnFetchCustomFieldObservable(): void {
    const subscription = this.boxService
      .fetchBoxesObservable()
      .subscribe(boxes => {
        console.log(boxes)
        this.isLoading = false;
        if(boxes.length > 0){
          boxes.forEach(item => {
            this.boxes.push(item)
          })
        } else {
          this.loadedEnd = true;
        }
        if(this.table) this.table.reloadItems();
      }, response => {
        console.log(response.error)
        this.isLoading = false;
        this.boxes = []
        if(this.table) this.table.reloadItems()
      });
    this.subscriptions.add(subscription);
  }

  private registerOnTranslate(): void {
    this.translationLoader.loadTranslations(english, portugues);

    this.translateService.get("BOXES").subscribe((res: any) => {
      this.translate = res
    });
  }

  private getBoxes(): void {
    this.isLoading = true;
    this.subscriptionRequest.add(this.boxService.fetchBoxesWithName(this.term, this.page));
  }

  scrollEvent(event) {
    console.log(event)
    if (event.type === 'retriveWithScroll' && !this.isLoading && !this.loadedEnd) {
      this.page++
      this.isLoading = true;
      this.subscriptionRequest.add(this.boxService.fetchBoxesWithName(this.term, this.page));
    }
  }
}
