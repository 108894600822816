export const locale = {
  lang: 'pt-BR',
  data: {
    'EVENT': {
      'EVENT_NOT_EXIST': 'Evento não encontrado!',
      'GOTO_HOME': 'IR PARA O INÍCIO',
      'LOTS': 'Ingressos',
      'LOTS_SOLDOUT': 'Ingressos esgotados',
      'LOTS_WAITING': 'Ingressos em breve!',
      'TABS': {
        'INFORMATION': 'Informações',
        'HEATS': 'Baterias',
        'WODS': 'Workouts',
        'LEADERBOARD': 'Leaderboard'
      },
      'SEE_REGULATION': 'Ver regulamento',
      'SEE_TERM': 'Ver termos',
      'DATE_TIME':{
        'TITLE': 'Data e hora',
        'START': 'Início',
        'END': 'Término'
      },
      'LOCATION': 'Localização',
      'SHARE': 'Compartilhar',
      'DATE_FORMAT_COMPLETE': 'DD [de] MMMM [de] YYYY',
      'DATETIME_FORMAT': 'DD/MM/YYYY [às] HH:mm',
      'SUBSCRIPTION': {
        'TITLE': 'Inscrição encontrada',
        'DESCRIPTION': 'Você já iniciou a inscrição neste evento, deseja continuar a mesma inscrição?',
        'DESCRIPTION_COMPLETED': 'Você já se inscreveu nesta etapa'
      },
      'SUBSCRIPTION_ORDER': {
        'TITLE': 'Inscrição encontrada',
        'DESCRIPTION': 'Você já efetuou a inscrição, mas o boleto ainda não foi pago. A confirmação de sua inscrição pode levar até 3 dias úteis após o pagamento.',
      },
      'BANK_ACCOUNT': {
        'TITLE': 'Erro na configuração do evento',
        'DESCRIPTION': 'Não existe conta bancária cadastrada'
      }
    }
  }
};