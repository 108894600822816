<div mat-dialog-content class="dialog-container">
  <!-- <div class="modal-background" (click)="dialogRef.close()"></div> -->
  <div class="card-content" fxLayout="column">
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between" class="header-modal">
      <h1>{{ translate.TITLE | uppercase }} </h1>
      <span class="close" (click)="dialogRef.close(null)">
        <img src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>

    <div fxFlex="100" fxLayout="column" class="mt-8" style="max-width:100%; max-height: 400px">
      <angular-cropper #imageCropper [cropperOptions]="configCrop" [imageUrl]="image"></angular-cropper>
    </div>


    <div class="mb-0 mt-32" fxLayoutAlign="end center">
      <btn type="grayscale" (click)="dialogRef.close(null)" class="mr-16">{{ translate.CANCEL | uppercase }}</btn>
      <btn [btnLoading]="true" (click)="getImage()">{{ translate.SAVE | uppercase }}</btn>
    </div>
  </div>
</div>


