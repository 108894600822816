import {Component, Input, OnInit} from '@angular/core';
import {CustomField} from '../../model/custom-field.model';
import {CustomFieldTypes} from '../../model/custom-field-type.model';
import {Subscription} from 'rxjs';
import {locale as english} from './i18n/en-US';
import {locale as portugues} from './i18n/pt-BR';
import {TranslateService} from '@ngx-translate/core';
import {FuseTranslationLoaderService} from '../../services/translation-loader.service';
import {InputUppercaseDirective} from '../../directives/input-uppercase/input-uppercase.directive';
import {BoxService} from '../../services/box.service';
import {FormBuilder, FormGroup, FormGroupDirective, Validators, ControlContainer} from '@angular/forms';
import {dateValidator} from '../../utils/custom-validators';

@Component({
  selector: 'custom-field',
  templateUrl: './custom-field.component.html',
  styleUrls: ['./custom-field.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class CustomFieldComponent implements OnInit {

  @Input() customField: CustomField;
  @Input() config: CustomFieldConfig = new CustomFieldConfig();
  @Input() hasErrors: CustomFieldHasErrors = new CustomFieldHasErrors();
  @Input() form: FormGroup;
  
  @Input() maskDate: string = '00/00/0000'
  @Input() maskDatetime: string = '00/00/0000 00:00'

  private subscriptions: Subscription = new Subscription();
  private boxes: any[] = []
  private timeOut;
  private translate: any = null;
  
  constructor(
    private translateService: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private boxesService: BoxService,
    private formBuilder: FormBuilder,
    private parent: FormGroupDirective
  ) { }

  ngOnInit() {
    this.registerOnTranslate();
    // console.warn(this.form)

    let validators = []
    if (this.customField.is_required) {
      validators.push(Validators.required);
    }
    if (this.customField.max_count !== null && this.customField.max_count > 0) {
      validators.push(Validators.maxLength(this.customField.max_count));
    }
    // if (this.customField.custom_field_type.type === CustomFieldTypes.DATE_PICKER) {
    //   validators.push(dateValidator(this.translate.DATE_FORMAT));
    // }
    const myForm = this.parent.form;
    myForm.addControl(this.customField.formControlName, this.formBuilder.control('', validators));
  }

  // Number or Text
  public getIsSingleTextOrNumber(): boolean {
    const typesTextOrNumber: CustomFieldTypes[] = [
      CustomFieldTypes.SINGLE_LINE_TEXT,
      CustomFieldTypes.NUMBER
    ];
    
    // @ts-ignore
    return typesTextOrNumber.includes(this.customField.custom_field_type.type);
  }

  public getTypeSingleTextOrNumber(): string {
    return this.customField.custom_field_type.type === CustomFieldTypes.NUMBER ? 'number' : 'text';
  }

  // Select
  public getIsSelect(): boolean {
    return this.customField.custom_field_type.type === CustomFieldTypes.SELECT;
  }

  // Checkbox
  public getIsCheckbox(): boolean {
    return this.customField.custom_field_type.type === CustomFieldTypes.SINGLE_CHECKBOX;
  }

  // Date
  public getIsDate(): boolean {
    return this.customField.custom_field_type.type === CustomFieldTypes.DATE_PICKER;
  }
  
  // DateTime
  public getIsDatetime(): boolean {
    return this.customField.custom_field_type.type === CustomFieldTypes.DATETIME_PICKER;
  }

  // Box
  public getIsBox(): boolean {
    return this.customField.custom_field_type.type === CustomFieldTypes.BOX;
  }
  
  public searchBox($event: KeyboardEvent): void {
    // @ts-ignore
    const name = $event.target.value + $event.key;
    console.log('searchBox', name)

    clearTimeout(this.timeOut)
    this.timeOut = setTimeout(() => {
      if (name.length > 0) {
        this.boxesService.getListBoxes(name)
          .subscribe(
            result => {
              console.log("result", result);
              this.boxes = result
            }, err => {
              this.boxes = []
            }
          )
      } else {
        this.config.boxes = []
      }
    }, 1000);
  }
  
  public displayFnBox(box: any): string {
    console.log(box)
    return box ? box.name.toUpperCase() : box;
  }
  
  // CPF
  public getIsCPF(): boolean {
    return this.customField.custom_field_type.type === CustomFieldTypes.CPF;
  }

  // PHONE
  public getIsPhone(): boolean {
    return this.customField.custom_field_type.type === CustomFieldTypes.PHONE;
  }
  
  //Multiline text
  public getIsMultilineText(): boolean {
    return this.customField.custom_field_type.type === CustomFieldTypes.MULTIPLE_LINE_TEXT;
  }

  private registerOnTranslate(): void {
    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService
      .get('CUSTOM_FIELD')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);
  }
}

export class CustomFieldHasErrors {
  required: boolean = false;
  invalid:boolean = false;
}

export class CustomFieldConfig {
  boxes: any[] = [];
}
