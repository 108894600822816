<div mat-dialog-content class="dialog-container">
  <!-- <div class="modal-background" (click)="dialogRef.close()"></div> -->
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>{{ (isEdit) ? translate.TITLE : translate.TITLE | uppercase }}</h1>
      <span class="close" (click)="dialogRef.close(null)">
        <img src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>

    <form [formGroup]="competitionForm" name="competitionForm" autocomplete="off" novalidate>
      <div fxLayout="row" class="mt-16 mb-16">
        <description-section title="{{ translate.TEAM.TITLE }}" text="{{ translate.TEAM.DESCRIPTION }}">
          <mat-icon class="ic-info mr-8" svgIcon="ic-cat-info"></mat-icon>
        </description-section>
      </div>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="mt-16">
        <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8">
          <input matInput placeholder="{{ translate.TEAM.TEAM_NAME }}" formControlName="teamName" [errorStateMatcher]="matchers.teamName"
            uppercase required>
          <mat-error *ngIf="competitionForm.controls.teamName.hasError('required')">
            {{ translate.VALIDATIONS.TEAM_NAME_REQUIRED }}
          </mat-error>
        </mat-form-field>
      
        <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8">
          <mat-select placeholder="{{ translate.CATEGORY.TITLE }}" formControlName="competition" [errorStateMatcher]="matchers.competition" (selectionChange)="competitionChange($event)" required>
            <mat-option *ngFor="let c of competitions" [value]="c">
              {{ c.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="competitionForm.controls.competition.hasError('required')">
            {{ translate.VALIDATIONS.CATEGORY_REQUIRED }}
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="50" fxFlex.xs="100" class="ml-8" *ngIf="enableSubDivision">
          <mat-select placeholder="{{ championship.sub_division_group_name ? championship.sub_division_group_name : translate.SUB_DIVISION }}" formControlName="subDivision" [errorStateMatcher]="matchers.subDivision" required>
            <mat-option *ngFor="let subDivision of subDivisions" [value]="subDivision.id">
              {{ subDivision.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="competitionForm.controls.subDivision.hasError('required')">
            {{ translate.VALIDATIONS.SUB_DIVISION_REQUIRED }}
          </mat-error>
        </mat-form-field>
      </div>
      
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="mt-4">
        <mat-form-field fxFlex="50" fxFlex.xs="100">
          <input matInput placeholder="{{ translate.TEAM.BOX }}" formControlName="box" [matAutocomplete]="autoBox"
            (ngModelChange)="searchBox($event)" uppercase>
          <mat-autocomplete #autoBox="matAutocomplete" [displayWith]="displayFnBox">
            <mat-option *ngFor="let box of boxes" [value]="box">
              {{ box.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </form>

    <h4>{{ memberText }}</h4>

    <div fxLayout="column" fxLayoutAlign="start stretch">
      <div [ngClass]="{'box-line': !isEdit, 'p-16': !isEdit}" class="mb-16" *ngFor="let member of members; let i = index">
        <btn-transparent
          icon="common/ic-arrow-up-collapsible.svg"
          buttonWidth="35px" iconAlign="left"
          [ngClass]="{'rotate-180': !member.isOpen}" class="btn-collapsible"
          (click)="actionCollapsible(); member.isOpen = !member.isOpen">
        </btn-transparent>
        <div fxLayout="row" fxLayoutAlign="start center" class="mt-4">
          <button
            class="capitain mr-8"
            [ngClass]="{'active': member.capitan}"
            (click)="setCapitain(member)">C</button>
          <h3 class="m-0">{{ member.fullname | uppercase }}</h3>
        </div>
        
        <div [fxShow]="member.isOpen">
          <form [formGroup]="member.competitorForm" name="competitorForm" autocomplete="off" novalidate>
            <div fxLayout="column" fxLayoutAlign="stretch stretch">
              <div fxLayout="row" fxLayoutAlign="start start" class="mt-16">
                <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8">
                  <input
                    matInput
                    placeholder="{{ translate.TEAM.EMAIL }}"
                    formControlName="email" [errorStateMatcher]="member.matchers.email"
                    (blur)="searchUser($event, member)">
                  <mat-error *ngIf="member.competitorForm.controls.email.hasError('email')">
                    {{ translate.VALIDATIONS.EMAIL_INVALID }}
                  </mat-error>
                </mat-form-field>
              </div>
              
              <div fxLayout="column" fxLayoutAlign="start start" class="user" *ngIf="member.user.id !== null">
                <div fxLayout="row" fxLayoutAlign="start center" class="small-font">
                  <img src="{{ (member.user.avatar !== null) ? member.user.avatar : null | placeholderImage:'user' }}" width="80" class="avatar corner-radius" /> 
                  {{ member.user.name }}
                </div>
                <span class="user-exist mt-16">Usuário encontrado em nossa base de dados.</span>
              </div>
              
              <div fxLayout="row" fxLayoutAlign="start start" class="mt-16" *ngIf="member.user.id === null">
                <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8">
                  <input
                    matInput
                    placeholder="{{ translate.TEAM.FIRSTNAME }}"
                    formControlName="firstname" [errorStateMatcher]="member.matchers.firstname"
                    uppercase required>
                  <mat-error *ngIf="member.competitorForm.controls.firstname.hasError('required')">
                    {{ translate.VALIDATIONS.FIRSTNAME_REQUIRED }}
                  </mat-error>
                </mat-form-field>
              
                <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8">
                  <input
                    matInput
                    placeholder="{{ translate.TEAM.LASTNAME }}"
                    formControlName="lastname" [errorStateMatcher]="member.matchers.lastname"
                    uppercase required>
                  <mat-error *ngIf="member.competitorForm.controls.lastname.hasError('required')">
                    {{ translate.VALIDATIONS.LASTNAME_REQUIRED }}
                  </mat-error>
                </mat-form-field>
              </div>
              
              <div fxLayout="row" fxLayoutAlign="start start" class="mt-16">
                <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8">
                  <input matInput placeholder="{{ translate.TEAM.LEADERBOARD_NAME }}" formControlName="leaderboardName" (blur)="member.leaderboardNameBlur($event)" (focus)="member.leaderboardNameFocus($event)" [errorStateMatcher]="member.matchers.leaderboardName" uppercase required>
                  <mat-error *ngIf="member.competitorForm.controls.leaderboardName.hasError('required')">
                    {{ translate.VALIDATIONS.LEADERBOARDNAME_REQUIRED }}
                  </mat-error>
                </mat-form-field>
              
                <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8">
                  <input matInput placeholder="{{ translate.TEAM.BOX }}" formControlName="box" [matAutocomplete]="autoBox" (ngModelChange)="searchBox($event)" uppercase>
                  <mat-autocomplete #autoBox="matAutocomplete" [displayWith]="displayFnBox">
                    <mat-option *ngFor="let box of boxes" [value]="box">
                      {{ box.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              
              <div fxLayout="row" fxLayoutAlign="start start" class="mt-16">
                <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8">
                  <mat-select placeholder="{{ translate.TEAM.TSHIRT }}" formControlName="tShirt">
                    <mat-option *ngFor="let size of tshirtSize" [value]="size">
                      {{ size }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
      
      
              <div fxFlex="100" fxLayout="row" fxLayoutAlign="end end" fxLayout.xs="column">
                <btn-transparent fxFlex="35px" materialIcon="delete" buttonWidth="35px" iconAlign="left" (click)="removeMember(i)" *ngIf="!isEdit && members.length > 1"></btn-transparent>
              </div>
            </div>
          </form>
        </div>
      </div>
      <btn-icon-small icon="add_circle_outline" iconAlign="left" (click)="addMember()" *ngIf="!isEdit && members.length < membersTotal">{{ translate.ADD_MEMBER }}</btn-icon-small>
    </div>

    <div class="mt-16 mb-8 text-red" *ngIf="requestErrors !== null">
      {{ requestErrors }}
    </div>

    <div class="mb-0 mt-8" fxLayoutAlign="end center">
      <btn type="grayscale" (click)="dialogRef.close(null)" class="mr-16">{{ translate.CANCEL | uppercase }}</btn>
      <btn [btnLoading]="true" [loading]="isLoading" (click)="createCompetitor()">{{ translate.SAVE | uppercase }}</btn>
    </div>
  </div>
</div>