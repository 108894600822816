export const locale = {
  lang: 'en-US',
  data: {
    RESULT_COMMENT: {
      TITLE: 'Inserir comentário',
      FORM: {
        FIELDS: {
          COMMENT: 'Comentário do Judge'
        },
        BUTTONS: {
          SUBMIT: 'Salvar',
          CANCEL: 'Cancelar',
        }
      }
    }
  }
};
