import { Component, OnInit, Input, HostListener } from '@angular/core';

@Component({
  selector: 'btn',
  templateUrl: './btn.component.html',
  styleUrls: ['./btn.component.scss']
})
export class BtnComponent implements OnInit {
  @Input() loading = false;
  @Input() btnLoading = false;
  @Input() type: string | 'grayscale';
  @Input() typeButton = 'submit';
  @Input() icon: string;
  @Input() iconAlign: string;
  @Input() materialIcon: string = null;
  @Input() backgroundColor: string;
  @Input() color: string;
  @Input() buttonDisabled = false;
  @Input() testLoading = false;
  @Input() padding = true;
  @Input() circle = false;
  @Input() buttonWidth = 'auto';
  @Input() onlyIcon = false;
  @Input() buttonHeight = '35px';

  constructor() { }

  ngOnInit() {
  }

  clickButton()
  {
    if (this.btnLoading && !this.buttonDisabled && this.testLoading) {
      this.loading = !this.loading;
    }
  }
}
