import { config } from './../../core/config/config';
import { Utils } from './../../core/utils';
import { AccessService } from './../../core/services/access.service';
import { FuseTranslationLoaderService } from './../../core/services/translation-loader.service';
import { AuthGuard } from './../../core/guards/auth.guard';
import { BreadcrumbsService } from './../../core/components/breadcrumbs/breadcrumbs.service';
import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { FuseConfigService } from '../../core/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { Breadcrumb } from '../../core/components/breadcrumbs/breadcrumb';
import { AuthenticationService } from '../../core/services/authentication.service';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

// LOCALE
import { locale as english } from './i18n/en-US';
import { locale as portuguese } from './i18n/pt-BR';
import {FreshChatService} from '../../core/services/freshdesk/fresh-chat.service';
import {EventGuard} from '../../core/guards/event.guard';

@Component({
    selector   : 'fuse-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls  : ['./toolbar.component.scss']
})

export class FuseToolbarComponent
{
  
  userStatusOptions: any[];
  languages: any;
  selectedLanguage: any;
  showLoadingBar: boolean;
  horizontalNav: boolean;
  breadcrumbs: Breadcrumb[] = this.breadcrumbsService.breadcrumbs
  userFullName: string = ""
  gymPicture: string = "assets/images/avatars/profile.jpg"
  userPicture: string = "assets/images/avatars/profile.jpg"
  gymActive: string = ""
  userGym: string = ""
  accesses: any[]
  access: any
  user: any = null
  event: any = null
  showBrand: boolean = false

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private router: Router,
    private fuseConfig: FuseConfigService,
    private translate: TranslateService,
    private breadcrumbsService: BreadcrumbsService,
    private authenticationService: AuthenticationService,
    private accessService: AccessService,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private freshChatService: FreshChatService
  )
  {
    this.userStatusOptions = [
        {
            'title': 'Online',
            'icon' : 'icon-checkbox-marked-circle',
            'color': '#4CAF50'
        },
        {
            'title': 'Away',
            'icon' : 'icon-clock',
            'color': '#FFC107'
        },
        {
            'title': 'Do not Disturb',
            'icon' : 'icon-minus-circle',
            'color': '#F44336'
        },
        {
            'title': 'Invisible',
            'icon' : 'icon-checkbox-blank-circle-outline',
            'color': '#BDBDBD'
        },
        {
            'title': 'Offline',
            'icon' : 'icon-checkbox-blank-circle-outline',
            'color': '#616161'
        }
    ];

    this.languages = [
        {
            'id'   : 'en-US',
            'title': 'English',
            'flag' : 'us'
        },
        {
            'id'   : 'pt-BR',
            'title': 'Protuguês',
            'flag' : 'es'
        }
    ];

    var lang = this.languages.find(item => item.id === navigator.language);
    this.selectedLanguage = (lang) ? lang : this.languages[0];

    router.events.subscribe(
        (event) => {
            if ( event instanceof NavigationStart )
            {
                this.showLoadingBar = true;
            }
            if ( event instanceof NavigationEnd )
            {
                this.showLoadingBar = false;
            }
        });

    this.fuseConfig.onSettingsChanged.subscribe((settings) => {
        this.horizontalNav = settings.layout.navigation === 'top';
    });

    //Icon logout
    iconRegistry.addSvgIcon(
      'ic-logout',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/toolbar/ic-exit.svg')
    );

    iconRegistry.addSvgIcon(
      'ic-profile',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/toolbar/ic-profile.svg')
    );

    // Set Translate
    this.translationLoader.loadTranslations(english, portuguese);

    //User Logged
    this.user = this.authGuard.getMe().user;
    
    this.populateUserProfile()
    
    const event = this.eventGuard.getActiveEvent();
    if (event && event.isUserDashboard === false) {
      this.event = event;
    }

    this.authGuard.watchStorage().subscribe((data: any) => {
      console.log(data)
      
      if (data.type === "logged" || data.type === 'updated') {
        // this.accesses = this.authGuard.getAccesses()
        this.user = this.authGuard.getMe().user;
        this.populateUserProfile()
      }
      else {

      }
    })
    
    this.eventGuard.watchStorage().subscribe((data: any) => {
      console.log('event toolbar data', data);
      if (data && data.data !== null && data.data !== undefined && data.isUserDashboard === false) {
        this.event = data.data;
      } else {
        this.event = null;
      }
      console.log('event toolbar', this.event)
    });

    //show/hide brand
    this.router.events
      .subscribe((event) => {
        const { host } = window.location;
        const slug = host.substr(0, host.indexOf('.'))

        if (event instanceof NavigationStart || event instanceof NavigationEnd) {
          if (slug !== config.hostDash && slug !== '') {
            this.showBrand = (event.url === '/event' || event.url === '/event-identification' || event.url === '/event-registration')
          } else {
            this.showBrand = (event.url === '/myprofile' || event.url === '/list-event/my-event' || event.url === '/list-event/my-event-competitor')
          }
        }
        /*if (event instanceof NavigationEnd) {
          if (slug !== config.hostDash && slug !== '') {
            this.showBrand = (event.url === '/event' || event.url === '/event-identification' || event.url === '/event-registration')
          } else {
            this.showBrand = (event.url === '/myprofile' || event.url === '/list-event/my-event')
          }
        }*/
      })
  }

  populateUserProfile() {
    if (this.user) {
      this.userFullName = (this.user !== null) ? this.user.fullname : ""
      this.userGym = (this.user.box) ? this.user.box.name.toUpperCase() : ""
      this.gymPicture = Utils.getPicture(this.user.avatar, 'user')
      if (this.user && this.user.avatar) {
        this.userPicture = Utils.getPicture(this.user.avatar, 'user')
      }
    }
  }

  getPicture(object: any, type: string = 'gym'): string
  {
    return Utils.getPicture((object !== undefined) ? object.profileImageThumb.defaultUrl : null, type)
  }

  getPictureUser(object: string, type: string = 'user'): string
  {
    return Utils.getPicture(object, type)
  }

  search(value)
  {
      // Do your search here...
      console.log(value);
  }

  setLanguage(lang)
  {
      // Set the selected language for toolbar
      this.selectedLanguage = lang;

      // Use the selected language for translations
      this.translate.use(lang.id);
  }

  //Breadcrumbs
  getBreadCrumbs(): Breadcrumb[] {
      return [
          {
              text: "Atletas",
              route: "/login"
          },
          {
              text: "Rodrigo Priolo",
              route: "/user/rodrigopriolo"
          }
      ]
  }

  //ACCESS
  retriveAccess()
  {
    this.accessService.getMyAccesses()
      .subscribe(
        result => {
          console.log("result", result);
        }, err => {
          console.log("err", err);
        }
      );
  }

  //User menu actions
  logOut() {
    const { host } = window.location;
    const slug = host.substr(0, host.indexOf('.'))
    this.freshChatService.destroy();
    this.authenticationService.logOut((slug !== config.hostDash) ? 'event' : 'login')
    this.user = null
  }

  selectAccess(access: any) {
    //console.log(access)
    this.gymActive = access.box.name
    this.authGuard.setAccessActive(access)
    this.gymPicture = access.box.profileImage.profileImageThumb.defaultUrl
  }

  showHelpChat() {
    this.freshChatService.show();
  }
}
