import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventListService } from '../../../../../core/services/event-list/event-list.service';
import { TableConfig, TableXComponent } from '../../../../../core/components/table-x/table-x.component';
import { Event } from '../../../../../core/model/event';
import { EventGuard } from '../../../../../core/guards/event.guard';
import { FusePerfectScrollbarDirective } from '../../../../../core/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { TabBarComponent } from '../../../../../core/components/tab-bar/tab-bar.component';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { locale as english } from '../i18n/en-US';
import { locale as portugues } from '../i18n/pt-BR';
import { FuseConfigService } from '../../../../../core/services/config.service';
import { BreadcrumbsService } from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {

  @ViewChild('tabbar', { read: TabBarComponent }) tabBar: TabBarComponent;
  @ViewChild('table', { read: TableXComponent }) tableList: TableXComponent;
  @ViewChild(FusePerfectScrollbarDirective) FusePerfectScrollbarDirective;
  
  type: string;
  typeFlap = 'live';
  listEvents = [];
  tabsFilter = [];
  tabsOptions= [];
  isLoading = true;
  isLoadingEnd = true;
  isEmpty = true;
  totalCount = 0;
  page = 1;
  totalPage: number;
  search = '';
  loadedEnd = false;
  isShowScrollToTop = false;
  translate: any;
  genders = [];
  timeOutSearch: any;
  //Table
  tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: null,
    massActionCountTitle: null
  }

  private readonly subscriptions = new Subscription();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private eventListService: EventListService,
    private eventGuard: EventGuard,
    private translateService: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private breadcrumbsService: BreadcrumbsService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) { 
    this.registerFuse();
    this.registerOnTranslate();
    this.pathParamRoute();
    this.eventListObservable();
    this.registerTabs();

    iconRegistry.addSvgIcon(
      'ic-info',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/myprofile/ic-profile.svg')
    );
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private registerFuse(): void {
    this.fuseConfig.setSettings({
      layout: {
        navigation: 'none',
        toolbar   : 'below',      // 'above', 'below', none
        footer    : 'none'
      }
    });
  }

  private registerTabs(): void {
    if (this.type === 'my-event') {
      this.tabsOptions = [
        {
          name: this.translate.TABS.LIVE,
          type: 'live'
        },
        {
          name: this.translate.TABS.PAST,
          type: 'past'
        }
      ];
      this.tabsOptions.forEach(tab => {
        this.tabsFilter.push(tab.name);
      });
    }
  }

  private pathParamRoute(): void {
    const subscription = this.activatedRoute
      .params
      .subscribe((params: Params) => {
        this.type = params.type;
        this.breadcrumbsService.replaceAll([{
          text: 'Perfil',
          route: `/myprofile`
        },
        {
          text: this.type === 'my-event' ? this.translate.LIST_EVENT.TITLE1 : this.translate.LIST_EVENT.TITLE2,
          route: `/list-event/${this.type}`
        }]);
        this.getEventList();
      });
    this.subscriptions.add(subscription);
  }

  private registerOnTranslate(): void {
    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService
      .get("MYPROFILE")
      .subscribe((res: any) => {
        this.translate = res
        this.genders = [
          {
            value: 0,
            text: res.GENDER_MALE
          },
          {
            value: 1,
            text: res.GENDER_FEMALE
          }
        ];
      });
    this.subscriptions.add(subscription);
  }

  private getEventList(): void {
    if (this.type === 'my-event') {
      this.eventListService.fetchMyEvents(this.page, this.search, this.typeFlap);
      return;
    }
    if (this.type === 'my-event-competitor') {
      this.eventListService.fetchMyEventsCompetitor(this.page, this.search);
      return;
    }
  }

  private eventListObservable(): void {
    const subscription = this.eventListService.eventListObservable()
      .subscribe((data) => {
        const { paginate, list } = data;
        this.totalCount = paginate.total;
        this.page = paginate.current_page;
        if (paginate.current_page === 1) {
          this.listEvents = list;
        } else {
          list.forEach(list => {
            this.listEvents.push(list);
          });
        }
        if (this.tableList) this.tableList.reloadItems();
        this.isEmpty = this.listEvents.length === 0;
        this.isLoading = false;
        this.isLoadingEnd = false;
        if (this.listEvents.length >= this.totalCount) {
          this.isLoadingEnd = true;
          this.isLoading = false;
          return;
        }
      });
    this.subscriptions.add(subscription)
  }

  public scrollEvent(event): void {
    if (event.type === 'retriveWithScroll' && !this.isLoading && !this.isLoadingEnd) {
      this.page++;
      this.isLoading = true;
      this.isLoadingEnd = true;
      this.getEventList();
    }
  }

  public getEventStatus(event: any, isType: boolean): string
  {
    const date = (event.datetime_end) ? moment(event.datetime_end) : (event.datetime_start ? moment(event.datetime_start) : null)
    const now = moment();

    if (event.published && now.diff(date, 'days') < 0) {
      return (isType) ? 'success' : this.translate.STATUS.ACTIVE.toUpperCase()
    }
    else if (event.published && now.diff(date, 'days') >= 0) {
      return (isType) ? 'normal' : this.translate.STATUS.PAST.toUpperCase()
    }
    else if (!event.published) {
      return (isType) ? 'warning' : this.translate.STATUS.NOT_PUBLISHED.toUpperCase()
    }

    return ''
  }

  public getEventBadge(item: any): boolean {
    const event = new Event().deserialize(item)
    return event.hasInvitation();
  }

  public openEvent(event: any): void {
    this.eventGuard.setEvent(event)
    this.router.navigate(["/event/informations"]) ;
  }
  public openMyProfile(): void {
    this.router.navigate(["/myprofile"]); 
  }

  public openEventCompetitor(event: any): void {
    this.eventGuard.setEventUserDashboard(event, true)
    const championship = event.championships[0];
    var url = `/user-dashboard/information`;
    
    if (championship.is_invitation) {
      url = `/user-dashboard/championship/${championship.id}/payment`;
    }

    this.router.navigate([url]);
  }

  public searchEvents(event: any): void {
    this.search = event.target.value;  
    clearTimeout(this.timeOutSearch)
    this.timeOutSearch = setTimeout(() => {
      this.page = 1;
      this.getEventList();
    }, 600);
  }

  public tabAction(event): void {
    this.typeFlap = this.tabsOptions[event.index].type;
    this.page = 1;
    this.listEvents = [];
    this.isLoading = true;
    this.getEventList();
  }

  //Date format table
  public dateFormat(dateString: string): string {
    let date = moment(dateString)
    return date.format(`${this.translate.DATE_FORMAT}`)
  }
}
