import { HeatCompetitor } from './heat-competitor.model';

export class Heat {
  id: string | number;
  championship_id: string | number;
  name: string;
  number?: number;
  total_lanes: number;
  due_date: any;
  start_time: any;
  end_time: any;
  transition: string;
  duration: string;
  type?: string | null;
  total_lane_busy?: number | null;
  published_competitor?: number;
  published_schedule?: number;
  heat_competitors: Array<HeatCompetitor>;
}
