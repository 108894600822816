import { Wod } from './../../../../../../core/model/wod';
import { WodService } from './../../../../../../core/services/wod/wod.service';
import { PointsGroupService } from './../../../../../../core/services/pointgroup/points-group.service';
import { Championship } from './../../../../../../core/model/championship';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MatIconRegistry } from '@angular/material';
import { ModalCreateLotComponent } from '../modal-create-lot/modal-create-lot.component';
import { FuseTranslationLoaderService } from '../../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { CompetitionService } from '../../../../../../core/services/competition/competition.service';
import { EventGuard } from '../../../../../../core/guards/event.guard';
import {
  QuillComponent, QuillDirective,
  QuillConfigInterface, QuillModulesInterface
} from 'ngx-quill-wrapper';
import { FormErrorStateMatcher } from '../../../../../../core/utils/form-error-state-matcher';

import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { Competition } from '../../../../../../core/model/competition';
import { Utils } from '../../../../../../core/utils';
import { SelectTimeComponent } from '../../../../../../core/components/select-time/select-time.component';
import * as moment from 'moment';
import { dateValidator, requiredWithConditional, maxDateValidator } from '../../../../../../core/utils/custom-validators';
import { Errors } from '../../../../../../core/model/errors';
import { Subscription } from 'rxjs';
import { SubDivision } from '../../../../../../core/model/sub-division';
import { TableConfig } from '../../../../../../core/components/table-x/table-x.component';


@Component({
  selector: 'app-modal-wod',
  templateUrl: './modal-wod.component.html',
  styleUrls: ['./modal-wod.component.scss']
})
export class ModalWodComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  public isEdit = false;
  public championship: Championship;
  public competition: Competition;
  public allCompetitions: Competition[];
  public wod: Wod = null;

  @ViewChild('pickerTimePublish', { read: SelectTimeComponent }) pickerTimePublish: SelectTimeComponent;
  @ViewChild('pickerTimeEnd', { read: SelectTimeComponent }) pickerTimeEnd: SelectTimeComponent;

  // Event
  event: any;
  competitionsCheckbox: any[] = [];

  // translate
  translate: any;

  wodForm: FormGroup;
  matcher = new FormErrorStateMatcher();
  description = '';
  timePublish = '10:00';
  timeEnd = '10:00';
  timezoneDescription: string;
  unitsType: Unit[] = [];
  unitsTypeTiebreak: Unit[] = [];
  points: any[] = [];

  public configEditor: QuillConfigInterface = {
    readOnly: false
  };
  public subDivisions: SubDivision[];
  public modules: QuillModulesInterface = {};
  private toolbar: any = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction

    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    // [{ 'font': [] }],
    [{ 'align': [] }],
    ['link']
  ];

  // Request
  requestErrors: string;

  isLoading = false;

  tableCatConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: [
      {
        type: 'reproved',
        title: 'Reprovar'
      }
    ],
    massActionCountTitle: {
      single: 'resultado selecionado',
      multiple: 'resultados selecionados'
    }
  };

  constructor(
    public dialogRef: MatDialogRef<ModalCreateLotComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private formBuilder: FormBuilder,
    private competitionService: CompetitionService,
    private eventGuard: EventGuard,
    private pointGroups: PointsGroupService,
    private wodService: WodService
  ) {
    this.translationLoader.loadTranslations(english, portugues);

    this.subscriptions.add(this.translateService.get('MODAL_WOD').subscribe((res: any) => {
      this.translate = res;
      this.configEditor.placeholder = this.translate.FORM.DESCRIPTION_PLACEHOLDER;
    }));

    this.event = this.eventGuard.getActiveEvent().event;
    this.unitsType = Utils.getUnitsTypes(this.translateService.currentLang);
    this.unitsTypeTiebreak = [
      {
        id: null,
        name: 'Nenhum',
        unit: '',
      },
    ];
    
    this.unitsType.forEach(unit => {
      this.unitsTypeTiebreak.push(unit);
    });

    // Icon Info
    iconRegistry.addSvgIcon(
      'ic-cat-info',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/category/ic-cat-info.svg')
    );
   }
   
  ngOnInit() {
    // this.allCompetitions.forEach(competition => {
    //   if (competition.id !== this.competition.id) {
    //     this.competitionsCheckbox.push({
    //       id: competition.id,
    //       name: competition.name
    //     })
    //   }
    // });

    this.competitionsCheckbox = this.allCompetitions;
    const controlsCompetitions = this.competitionsCheckbox.map(c => new FormControl(c.id === this.competition.id)); 
    // { value: c.id === this.competition.id, disabled: c.id === this.competition.id}

    // Populate variable if update wod
    const wodS = this.wod;
    const wodType = (this.wod) ? this.unitsType.filter(function (unit) {
      return unit.id === wodS.wod_type.id;
    })[0] : '';

    const tiebreakType = (this.wod && this.wod.tiebreak_type) ? this.unitsTypeTiebreak.filter(function (unit) {
      if (unit.id === 0 && wodS.tiebreak_type_id === null) {
        return true;
      } 
      return unit.id === wodS.tiebreak_type.id;
    })[0] : '';

    this.description = (this.wod) ? this.wod.description : '';
    const opensAt = (this.wod) ? moment(this.wod.opens_at).format(this.translate.DATE_FORMAT) : moment().format(this.translate.DATE_FORMAT);
    this.timePublish = (this.wod) ? moment(this.wod.opens_at).format('HH:mm') : '10:00';
    // this.pickerTimePublish.time = this.timePublish;
    const closesAt = (this.wod) ? moment(this.wod.closes_at).format(this.translate.DATE_FORMAT) : '';
    this.timeEnd = (this.wod) ? moment(this.wod.closes_at).format('HH:mm') : '10:00';
    // this.pickerTimeEnd.time = this.timeEnd;
    const wodDate = (this.wod) ? 
      this.wod.wod_date ? 
        moment(this.wod.wod_date).format(this.translate.DATE_FORMAT) : 
        null : 
      null;
    this.wodForm = this.formBuilder.group({
      name: [(this.wod) ? this.wod.name : ''],
      resultType: [{
        value: wodType,
        disabled: (this.wod && this.wod.results > 0)
      }, [Validators.required]],
      goals: [(this.wod) ? this.wod.goal : ''],
      tiebreakType: [{
        value: tiebreakType,
        disabled: (this.wod && this.wod.results > 0)
      }],
      time_cap: [{
        value: this.wod ? this.wod.time_cap / 60 || null : null,
        disabled: (this.wod && !this.wod.canEditHeat)
      }, [Validators.nullValidator, Validators.pattern(/^[0-9]+$/), Validators.min(1)]],
      points: [''],
      urlVideo: [(this.wod) ? this.wod.video_url : '', [Validators.pattern(Utils.getRegUrlFormat())]],
      description: [(this.wod) ? this.wod.description : ''],
      addAllCompetition: [false],
      competitions: new FormArray(controlsCompetitions),
      publishWorkout: [this.wod ? this.wod.published : true],
      datePublish: [opensAt, [dateValidator(this.translate.DATE_FORMAT)]],
      dateEnd: [closesAt, [requiredWithConditional(this.championship.online)]],
      sendEmail: [(this.wod && this.wod.notification) ? this.wod.notification : true]
    });
    if (!this.competition.enable_sub_division) {
      const wodDateControl = this.formBuilder.control({
        value: wodDate,
        disabled: (this.wod && !this.wod.canEditHeat)
      }, [
        Validators.nullValidator,
        dateValidator(this.translate.DATE_FORMAT),
        maxDateValidator(moment(this.event.datetime_end).format(this.translate.DATE_FORMAT), this.translate.DATE_FORMAT)
      ]);
      this.wodForm.addControl('wod_date', wodDateControl);
    }

    if (this.championship.type === 3) {
      this.wodForm.controls['points'].setValidators([Validators.required]);
      this.getPointGroups();
    }

    if (this.event.timezone && this.event.timezone.length > 0) {
      this.subscriptions.add(this.translateService.get('MODAL_WOD.TIMEZONE', { timezone: this.event.timezone }).subscribe((res: string) => {
        this.timezoneDescription = res; 
      }));
    }
    this.registerFormSubDivision();

    // this.subscriptions.add(this.wodForm.valueChanges.subscribe(() => {
    //   console.log(this.wodForm.controls)
    // }));
  }

  private registerFormSubDivision(): void {
    if (this.competition.enable_sub_division) {
      const subDivision = [];
      this.subDivisions.forEach(element => {
        let wodDate = null;
        let canEditHeat = true;
        if (this.wod) {
          if (this.wod.wod_sub_division_dates) {
            const resultFilter = this.wod.wod_sub_division_dates.filter(filterElement => filterElement.sub_division_id === element.id);
            if (resultFilter.length === 1) {
              wodDate = moment(resultFilter[0].wod_date).format(this.translate.DATE_FORMAT);
              canEditHeat = resultFilter[0].canEditHeat;
            }
          }
        }
        subDivision.push(this.formBuilder.group({
          sub_division_id: [{value: element.id, disabled: false}, Validators.required],
          wod_date: [{
            value: wodDate,
            disabled: (this.wod && !this.wod.canEditHeat && !canEditHeat)
          }, [
            Validators.nullValidator,
            dateValidator(this.translate.DATE_FORMAT),
            maxDateValidator(moment(this.event.datetime_end).format(this.translate.DATE_FORMAT), this.translate.DATE_FORMAT)
          ]],
        }));
      });
      this.wodForm.addControl('wod_sub_division_dates', this.formBuilder.array(subDivision));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
  createOrUpdateWod() {
    if (!this.wodForm.valid) {
      Utils.formValid(this.wodForm);
      return;
    }

    let selectedCompetitions = (this.wodForm.value.competitions) ? this.wodForm.value.competitions
      .map((v, i) =>  v ? this.competitionsCheckbox[i].id : null)
      .filter(v => v !== null) : [];

    if (this.wodForm.value.addAllCompetition) {
      selectedCompetitions = this.allCompetitions.map(v => v.id);
    }

    const wodType = this.wodForm.value.resultType ? this.wodForm.value.resultType : (this.wod && this.wod.wod_type ? this.wod.wod_type : null);

    let parameters: any = {
      name: this.wodForm.value.name.toUpperCase(),
      wod_type_id: wodType.id,
      goal: (wodType && wodType.id === 2) ? this.wodForm.value.goals : null,
      tiebreak_type_id: 
        (this.wodForm.value.tiebreakType && this.wodForm.value.tiebreakType.name) ? 
        this.wodForm.value.tiebreakType.id : 
        (this.wod && this.wod.tiebreak_type) ? this.wod.tiebreak_type.id : null,
      points_group_id: (this.championship.type === 3) ? this.wodForm.value.points.id : null,
      video_url: this.wodForm.value.urlVideo,
      description: this.description ? Utils.removeParagraphInText(this.description) : '',
      notification: (this.wodForm.value.sendEmail) ? 1 : 0,
      published: (this.wodForm.value.publishWorkout) ? 1 : 0,
    }; 
    if (this.wodForm.value.time_cap) {
      parameters = {
        ...parameters,
        time_cap: this.wodForm.value.time_cap * 60
      };
    }
    if (!this.wodForm.value.time_cap) {
      parameters = {
        ...parameters,
        time_cap: this.wodForm.value.time_cap
      };
    }
    if (this.wodForm.value.wod_date) {
      parameters = {
        ...parameters,
        wod_date: moment(this.wodForm.value.wod_date, this.translate.DATE_FORMAT).format('YYYY-MM-DD')
      };
    }
    if (!this.wodForm.value.wod_date) {
      parameters = {
        ...parameters,
        wod_date: null
      };
    }

    if (this.wodForm.value.wod_sub_division_dates) {
      const wodSubDivisionDates = [];
      this.wodForm.value.wod_sub_division_dates.forEach(({sub_division_id, wod_date }: { 
        sub_division_id: number; 
        wod_date: Date | null
      }) => {
        if (wod_date) {
          wodSubDivisionDates.push({
            sub_division_id,
            wod_date: moment(wod_date, this.translate.DATE_FORMAT).format('YYYY-MM-DD')
          });
        }
      });
      parameters = {
        ...parameters,
        wod_sub_division_dates: wodSubDivisionDates
      };
    }
    if (this.wodForm.value.datePublish && this.pickerTimePublish) {
      const dateTimePublish = moment(`${this.wodForm.value.datePublish} ${this.timePublish}:00`, `${this.translate.DATE_FORMAT} HH:mm:ss`).format(`YYYY-MM-DD HH:mm:ss`);
      parameters['opens_at'] = dateTimePublish;
    }

    if (this.wodForm.value.dateEnd && this.pickerTimeEnd) {
      const dateTimeEnd = moment(`${this.wodForm.value.dateEnd} ${this.timeEnd}:00`, `${this.translate.DATE_FORMAT} HH:mm:ss`).format(`YYYY-MM-DD HH:mm:ss`);
      parameters['closes_at'] = dateTimeEnd;
    }

    console.log(parameters);
    
    this.isLoading = true;

    if (this.isEdit) {
      this.subscriptions.add(this.wodService.updateWod(this.wod.id, parameters)
        .subscribe(
          result => {
            this.isLoading = false;
            this.wod = result;
            this.dialogRef.close({ wod: result, isEdit: true });
          }, err => {
            const errors: Errors = new Errors().deserialize((err.error as any));
            this.requestErrors = errors.getFullMessages();

            this.isLoading = false;
          }
      ));
    }
    else {
      parameters['championship_id'] = this.championship.id;
      parameters['competition_ids'] = selectedCompetitions;

      this.subscriptions.add(this.wodService.createWod(parameters)
        .subscribe(
          result => {
            this.isLoading = false;
            // this.lot = result
            this.dialogRef.close({ wods: result, isEdit: false });
          }, err => {
            const errors: Errors = new Errors().deserialize((err.error as any));
            this.requestErrors = errors.getFullMessages();

            this.isLoading = false;
          }
        ));
    }
    
  }

  // Get Points Group
  getPointGroups() {
    this.subscriptions.add(this.pointGroups.getPointGroupPerEvent(this.event.id)
      .subscribe(
        result => {
          this.points = result;

          if (this.wod) {
            const wodS = this.wod;
            const pointS = (this.wod) ? this.points.filter(function (point) {
              return point.id === wodS.point_group.id;
            })[0] : '';
            this.wodForm.controls['points'].setValue(pointS);
            // this.wodForm.controls['points'].setValue({
            //   value: pointS,
            //   disabled: (this.wod && this.wod.results > 0)
            // })
          }
        }, err => {
          console.log('err', err);
        }
      )); 
  }

  formCompetition() { 
    return <FormArray>this.wodForm.get('competitions');
  }
  
  updatePickerValue(time, pickerType: string): void {
    if (pickerType === 'time-publish') {
      this.timePublish = time;
    }
    else if (pickerType === 'time-end') {
      this.timeEnd = time;
    }
  }

}
