<div mat-dialog-content class="dialog-container">
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>
        {{ "MODAL_REORDER_ATHLETES.TITLE" | translate | uppercase }}
      </h1>
      <span class="close" (click)="dialogRef.close(null)">
        <img src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>
    <div class="mb-16" style="margin-left: -30px; margin-right: -30px;" *ngFor="let rankings of rankingReorder; index as i;">
      <div class="pt-16" fxLayout="column">
        <!-- <div fxLayout="row" fxLayoutAlign="space-between center" class="pl-32 pr-32 pb-16 mb-4 mt-0 line-bottom">
          <div fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 mr-4">{{ 'MODAL_REORDER_ATHLETES.TIE' | translate: { rank: rankings[0].competitor.rank } | uppercase }}</h2>
          </div>
        </div> -->
        <table-x 
          #tableList
          [items]="rankings"
          [config]="tableConfig"
          [isLoading]="false"
          tableName="rankings-{{ i }}"
          [isDraggable]="true"
          (reorderEvent)="reorderAction($event)"
        >
          <column property="id" header="" [sortable]="false" align="left" classes='menu-reorder' width="50px">
            <ng-template #dataTableCell let-item="item">
              <mat-icon class="menu-reorder">menu</mat-icon>
            </ng-template>
          </column>
          <column property="rank" header="{{ 'LEADERBOARD.TABLE_HEADER.RANKING' | translate }}" [sortable]="false" align="center" width="50px">
            <ng-template #dataTableCell let-item="item">
              <span>{{ item.competitor.rank }}</span>
            </ng-template>
          </column>
          <column property="name" header="{{ 'LEADERBOARD.TABLE_HEADER.NAME' | translate }}" [sortable]="false" align="left">
            <ng-template #dataTableCell let-item="item">
              <a fxLayout="row" fxLayoutAlign="start center" class="small-font">
                <img src="{{ (item.competitor.avatar) ? item.competitor.avatar : null | placeholderImage:'user' }}" width="80" class="avatar corner-radius" /> 
                <span fxLayout="column">
                    {{ item.competitor.fullname }}
                    <span class='box'>{{ (item.competitor?.team?.box) ? item.competitor?.team?.box.name : ''}}</span>
                </span>
                <label-x class="ml-8" *ngIf="item.competitor.disqualified">{{ 'LEADERBOARD.DISQUALIFIED.TYPE_WD' | translate }}</label-x>
                <label-x class="ml-8" *ngIf="item.competitor.is_cut">{{ 'LEADERBOARD.DISQUALIFIED.TYPE_CUT' | translate }}</label-x>
              </a>
            </ng-template>
          </column>
        </table-x>
      </div>
    </div>

    <div class="mb-0 mt-8" fxLayoutAlign="end center">
      <btn type="grayscale" (click)="dialogRef.close(null)" class="mr-16">{{
        "MODAL_REORDER_ATHLETES.CANCEL" | translate | uppercase
      }}</btn>
      <btn
        [btnLoading]="true"
        [loading]="btnLoading"
        [buttonDisabled]="btnDisable"
        (click)="!btnDisable ? saveOrder() : ''"
      >
        {{ "MODAL_REORDER_ATHLETES.SAVE" | translate | uppercase }}
      </btn>
    </div>
  </div>
</div>