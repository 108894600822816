<div class="heats-agenda mb-32">
  <a
    [routerLink]="itemsRouteData"
    [ngClass]="heatsClasses"
    [queryParams]="queryParams"
  >
    <div>
      <mat-icon svgIcon="ic-lanes"></mat-icon>
      <span>
        {{ "HEAT_HEATCOMPETITORS.HEATS_ITEMS.HEATS" | translate }}
      </span>
    </div>
    <mat-icon>check_circle</mat-icon>
  </a>
  <a
    [routerLink]="scheduleRouteData"
    [ngClass]="scheduleClasses"
    [queryParams]="queryParams"
  >
    <div>
      <mat-icon svgIcon="ic-calendar"></mat-icon>
      <span>
        {{ "HEAT_HEATCOMPETITORS.HEATS_SCHEDULE.SCHEDULE" | translate }}
      </span>
    </div>
    <mat-icon>check_circle</mat-icon>
  </a>
</div>
