import { Lot } from '../../../../../../core/model/lot';
import { Competition } from '../../../../../../core/model/competition';
import { EventGuard } from '../../../../../../core/guards/event.guard';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { MatDialogRef, MatIconRegistry } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectTimeComponent } from '../../../../../../core/components/select-time/select-time.component';
import { FormErrorStateMatcher } from '../../../../../../core/utils/form-error-state-matcher';
import { SubDivisionHttpService } from '../../../../../../core/services/sub-division/sub-division-http.service';
import { SubDivision } from '../../../../../../core/model/sub-division';
import { Errors } from '../../../../../../core/model/errors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fuse-app-modal-create-sub-division',
  templateUrl: './modal-create-sub-division.component.html',
  styleUrls: ['./modal-create-sub-division.component.scss']
})
export class ModalCreateSubDivisionComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();

  public championshipId: number; 
  public subDivision: SubDivision;

  // Translate
  translate: any;

  subDivisionForm: FormGroup;
  matchers: any;
  isLoading = false;
  requestErrors: string;

  constructor(
    public dialogRef: MatDialogRef<ModalCreateSubDivisionComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private subDivisionHttpService: SubDivisionHttpService
  ) {
    this.registerTranslate();
  }

  ngOnInit(): void {
    this.subDivisionForm = this.formBuilder
      .group({
        name: [this.subDivision ? this.subDivision.name : '' , [Validators.required]],
      });
    this.matchers = {
      name: new FormErrorStateMatcher(),
    };
  }

  private registerTranslate(): void {
    this.translationLoader
      .loadTranslations(english, portugues);

      const subscription = this.translateService.get('MODAL_CREATE_SUB_DIVISION')
      .subscribe((res: any) => {
        this.translate = res;
      });

      this.subscriptions.add(subscription);
  }

  public createSubDivision(): void {
    if (this.subDivisionForm.value.name && this.championshipId) {
      this.isLoading = true;
      const subDivisionData = {
        ...this.subDivisionForm.value,
        name: this.subDivisionForm.value.name.toUpperCase()
      };
      const subscription = this.subDivisionHttpService
        .createSubDivision(subDivisionData, this.championshipId)
        .subscribe((data: SubDivision) => {
          this.dialogRef.close({ isCreate:  true, subDivision: data });
        },
        (err: any) => {
          const errors: Errors = new Errors().deserialize((err.error as any));
          this.requestErrors = errors.getFullMessages();
          this.isLoading = false;
        });
      this.subscriptions.add(subscription);
    }
  }

  public updateSubDivision(): void {
    if (this.subDivisionForm.value.name && this.championshipId) {
      this.isLoading = true;
      const subDivisionData = {
        ...this.subDivisionForm.value,
        name: this.subDivisionForm.value.name.toUpperCase()
      };
      const subcription = this.subDivisionHttpService
        .updateSubDivision(this.subDivision.id, subDivisionData, this.championshipId)
        .subscribe((data: SubDivision) => {
          this.dialogRef.close({ isUpdate: true , subDivision: data });
        },
        (err: any) => {
          const errors: Errors = new Errors().deserialize((err.error as any));
          this.requestErrors = errors.getFullMessages();
          this.isLoading = false;
        });
      this.subscriptions.add(subcription);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
