<div mat-dialog-content class="dialog-container">
    <!-- <div class="modal-background" (click)="dialogRef.close()"></div> -->
    <div class="card-content" [ngClass]="{ 'pb-16': payments.length === 0 }">
        <div fxLayout="column" class="header-modal p-32 pb-16">
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <h1 fxLayout="row" fxLayoutAlign="start center">
                    <div fxLayout="column">
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="edit-number-competitor">
                            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="!editNumber">
                                {{ competitor.number || "-" }}
                                <btn-transparent
                                    materialIcon="create"
                                    buttonWidth="25px"
                                    iconAlign="left"
                                    color="#fff"
                                    class="ml-8"
                                    isSmall="true"
                                    (click)="editNumber = !editNumber"
                                ></btn-transparent>
                            </div>
                            <div
                                fxLayout="row"
                                fxLayout.xs="column"
                                fxLayoutAlign.xs="start start"
                                fxLayoutAlign="start end"
                                *ngIf="editNumber"
                            >
                                <div fxLayout="row">
                                    <form [formGroup]="numberForm" name="numberForm" autocomplete="off" novalidate fxLayout="row">
                                        <div fxLayout="column" fxLayoutAlign="start start">
                                            <input
                                                matInput
                                                formControlName="number"
                                                uppercase
                                                required
                                                [ngClass]="{
                                                    error:
                                                        numberForm.controls.number.hasError('min') ||
                                                        numberForm.controls.number.hasError('required')
                                                }"
                                            />
                                        </div>
                                    </form>
                                    <btn-transparent
                                        [loading]="false"
                                        materialIcon="done"
                                        buttonWidth="25px"
                                        iconAlign="left"
                                        classInside="small"
                                        class="ml-8"
                                        color="#fff"
                                        isSmall="true"
                                        (click)="!numberForm.invalid ? editNumberCompetitor() : ''"
                                    ></btn-transparent>
                                </div>
                                <mat-error style="font-size: 10px; color: #ebae46" *ngIf="numberForm.controls.number.hasError('required')">
                                    {{ "USER.VALIDATIONS.NUMBER_REQUIRED" | translate }}
                                </mat-error>
                                <mat-error style="font-size: 10px; color: #ebae46" *ngIf="numberForm.controls.number.hasError('min')">
                                    {{
                                        "USER.VALIDATIONS.NUMBER_LIMIT" | translate : { custom_number: competitor.competition.start_number }
                                    }}
                                </mat-error>
                            </div>
                        </div>
                        {{ competitor.team.name }}
                    </div>
                    <btn-transparent
                        [matMenuTriggerData]="{ competitorDisqualified: competitor }"
                        (click)="successDisqualifiedCompetitor = false"
                        buttonWidth="35px"
                        materialIcon="more_vert"
                        iconAlign="left"
                        classInside="white-hover-gray"
                        class="white-hover-gray ml-8"
                        [matMenuTriggerFor]="configDisqualifiedMenu"
                    >
                    </btn-transparent>
                    <mat-menu
                        class="submenu user-menu bottom arrow-right"
                        #configDisqualifiedMenu="matMenu"
                        [overlapTrigger]="false"
                        xPosition="before"
                    >
                        <ng-template matMenuContent let-competitorDisqualified="competitorDisqualified" var-timeCapOption="timeCapOption">
                            <ng-container *ngIf="competitorDisqualified.is_cut === 0 && competitorDisqualified.disqualified === 0">
                                <button class="line-bottom mat-menu-item" (click)="disqualifiedCompetitor(enumDisqualified.CUT)">
                                    <span>{{ "USER.DISQUALIFIED.CUT" | translate }} {{ "USER.DISQUALIFIED.TYPE_CUT" | translate }}</span>
                                </button>
                                <button class="line-bottom mat-menu-item" (click)="disqualifiedCompetitor(enumDisqualified.WD)">
                                    <span>{{ "USER.DISQUALIFIED.WD" | translate }} {{ "USER.DISQUALIFIED.TYPE_WD" | translate }}</span>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="competitorDisqualified.is_cut || competitorDisqualified.disqualified">
                                <button class="line-bottom mat-menu-item" (click)="disqualifiedCompetitor(enumDisqualified.CANCEL)">
                                    <span class="text-red">{{ "USER.DISQUALIFIED.REMOVE" | translate | titlecase }}</span>
                                </button>
                            </ng-container>
                        </ng-template>
                    </mat-menu>
                </h1>
                <span class="close" (click)="dialogRef.close()">
                    <img src="./assets/icons/modal/ic-close-white.svg" />
                </span>
            </div>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start" class="mt-16">
                <div fxLayout="column" fxFlex="100" fxFlex.xs="100">
                    <span>
                        <strong>{{ translate.CATEGORY }}:</strong> {{ competitor.competition.name }}
                    </span>
                    <span>
                        <strong>{{ translate.STATUS }}:</strong> {{ getCompetitorStatus() }}
                    </span>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end end" class="mt-16 mb-16" *ngIf="!confirmStatusOpened">
                <btn [btnLoading]="true" type="grayscale" (click)="confirmStatusOpened = !confirmStatusOpened">{{
                    (competitor.status === 2 ? translate.INACTIVE : translate.ACTIVE) | uppercase
                }}</btn>
            </div>
        </div>
        <!-- END HEADER -->

        <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="space-between center"
            class="confirm-status pl-32 pr-32 pt-12 pb-12"
            *ngIf="confirmStatusDisqualifiedCompetitor"
        >
            <div>
                <strong>{{ disqualifiedMessage }}</strong>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center">
                <btn
                    [btnLoading]="true"
                    type="grayscale"
                    [buttonDisabled]="isLoadingConfirmStatus"
                    (click)="confirmStatusDisqualifiedCompetitor = !confirmStatusDisqualifiedCompetitor"
                    class="mr-16"
                    >{{ "USER.DISQUALIFIED.CANCEL" | translate | uppercase }}</btn
                >
                <btn [btnLoading]="true" [loading]="isLoadingConfirmStatus" (click)="confirmDisqualifiedCompetitor()">
                    {{ "USER.DISQUALIFIED.CONFIRM" | translate | uppercase }}
                </btn>
            </div>
        </div>

        <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="space-between center"
            class="confirm-status pl-32 pr-32 pt-12 pb-12"
            *ngIf="confirmStatusOpened"
        >
            <div>
                <strong>{{ competitor.status === 2 ? translate.CONFIRM_STATUS_INACTIVE : translate.CONFIRM_STATUS_ACTIVE }}</strong>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center">
                <btn
                    [btnLoading]="true"
                    type="grayscale"
                    [buttonDisabled]="isLoadingConfirmStatus"
                    (click)="confirmStatusOpened = !confirmStatusOpened"
                    class="mr-16"
                    >{{ translate.CANCEL | uppercase }}</btn
                >
                <btn [btnLoading]="true" [loading]="isLoadingConfirmStatus" (click)="setActiveOrInactive()">{{
                    (competitor.status === 2 ? translate.INACTIVE : translate.ACTIVE) | uppercase
                }}</btn>
            </div>
        </div>

        <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="space-between center"
            class="error-status pl-32 pr-32 pt-12 pb-12"
            *ngIf="inactiveRequestError"
        >
            {{ inactiveRequestError }}
        </div>
        <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="space-between center"
            class="success-status pl-32 pr-32 pt-12 pb-12"
            *ngIf="successDisqualifiedCompetitor"
        >
            {{ successDiqualifiedCompetitorRequest }}
        </div>

        <form name="userForm" [formGroup]="userForm" autocomplete="off" novalidate>
            <div fxLayout="row" fxLayout.xs="column" class="pl-32 pr-32 pt-32">
                <mat-form-field fxFlex="50" fxFlex.xs="100" class="mr-8">
                    <input
                        matInput
                        placeholder="{{ translate.TEAM_NAME }}"
                        type="text"
                        formControlName="teamName"
                        [errorStateMatcher]="matchers.name"
                        uppercase
                        required
                    />
                    <mat-error *ngIf="userForm.controls.teamName.hasError('required')">
                        {{ translate.VALIDATIONS.TEAM_NAME_REQUIRED }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="50" fxFlex.xs="100" class="mr-8">
                    <mat-select
                        placeholder="{{ translate.CATEGORY }}"
                        class="fix-value"
                        formControlName="competition"
                        (selectionChange)="competitionSelect($event)"
                    >
                        <!-- [value]="championship.competitions[0]" (selectionChange)="competitionSelect($event)" -->
                        <mat-option *ngFor="let competition of competitions" [value]="competition.id">
                            {{ competition.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex="50" fxFlex.xs="100" class="ml-8" *ngIf="enableSubDivision">
                    <mat-select
                        placeholder="{{
                            championship.sub_division_group_name ? championship.sub_division_group_name : translate.SUB_DIVISION
                        }}"
                        formControlName="subDivision"
                        [errorStateMatcher]="matchers.subDivision"
                        required
                    >
                        <mat-option *ngFor="let subDivision of subDivisions" [value]="subDivision.id">
                            {{ subDivision.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="userForm.controls.subDivision.hasError('required')">
                        {{ translate.VALIDATIONS.SUB_DIVISION_REQUIRED }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="pl-32 pr-32 pt-4">
                <mat-form-field fxFlex="50" fxFlex.xs="100">
                    <input
                        matInput
                        placeholder="{{ translate.BOX }}"
                        formControlName="box"
                        [matAutocomplete]="autoBox"
                        (ngModelChange)="searchBox($event)"
                        uppercase
                    />
                    <mat-autocomplete #autoBox="matAutocomplete" [displayWith]="displayFnBox">
                        <mat-option *ngFor="let box of boxes" [value]="box">
                            {{ box.name }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </form>

        <div class="mb-8 mt-8 p-32 pb-0" fxLayoutAlign="end center">
            <!-- <btn [btnLoading]="true" type="grayscale" (click)="cancelUpdateCompetitor()">{{ translate.CANCEL | uppercase }}</btn> -->
            <btn [btnLoading]="true" [loading]="isUpdateLoading" (click)="updateCompetitor()" class="ml-16">{{
                translate.SAVE | uppercase
            }}</btn>
        </div>

        <h4 class="pl-32 pr-32">{{ translate.TEAM_MEMBERS }}</h4>

        <!-- Table -->
        <table-x
            #tableList
            [items]="competitor.team.members"
            [config]="tableConfigTeam"
            *ngIf="!isEmptyResult && competitor.team.members && competitor.team.members.length > 0"
            (sortEvent)="sortAction($event)"
        >
            <column property="payment_date" header="{{ translate.TABLE_HEADER.NAME }}" [sortable]="false" align="left">
                <ng-template #dataTableCell let-item="item">
                    <a fxLayout="row" fxLayoutAlign="start center" class="small-font">
                        <img
                            src="{{ item.user.avatar ? item.user.avatar : (null | placeholderImage : 'user') }}"
                            width="80"
                            class="avatar corner-radius"
                        />
                        <span fxLayout="column">
                            {{ item.user.fullname }}
                        </span>
                    </a>
                </ng-template>
            </column>

            <column property="email" header="{{ translate.TABLE_HEADER.EMAIL }}" [sortable]="false" align="left">
                <ng-template #dataTableCell let-item="item">
                    {{ item.user.email }}
                </ng-template>
            </column>

            <column property="tshirt" header="{{ translate.TABLE_HEADER.TSHIRT }}" [sortable]="false" align="center">
                <ng-template #dataTableCell let-item="item">
                    {{ item.size }}
                </ng-template>
            </column>

            <column property="captain" header="" [sortable]="false" align="center">
                <ng-template #dataTableCell let-item="item">
                    <btn-transparent
                        icon="profiles/ic-capitain.svg"
                        backgroundColor="#CFD5D9"
                        color="#004CFF"
                        buttonWidth="35px"
                        iconAlign="left"
                        [loading]="item.isLoading"
                        *ngIf="!item.capitan"
                        (click)="setTeamCaptain(item)"
                        classInside="ml-4"
                    ></btn-transparent>
                    <div class="btn-capitain" *ngIf="item.capitan">
                        <img src="./assets/icons/profiles/ic-capitain.svg" />
                    </div>
                </ng-template>
            </column>

            <column property="remove" header="" [sortable]="false" align="center" *ngIf="!event.enabled_subscription">
                <ng-template #dataTableCell let-item="item" let-index="index">
                    <btn-transparent
                        materialIcon="delete"
                        buttonWidth="35px"
                        iconAlign="left"
                        (click)="removeMember(item, index)"
                    ></btn-transparent>
                </ng-template>
            </column>
        </table-x>

        <div class="pr-32 pl-32 pb-32 pt-16" *ngIf="!isAlterMember">
            <btn-icon-small
                iconPath="common/ic-edit-small.svg"
                iconAlign="left"
                (click)="getConfigManagerMember(); isAlterMember = !isAlterMember; isLeaderboardNameEdited = false"
            >
                {{ translate.MANAGER_MEMBERS }}
            </btn-icon-small>
            <!-- <button fxLayout="row" fxLayoutAlign="start center" class="alter-member" (click)="isAlterMember = !isAlterMember">
        <img src="./assets/icons/common/ic-edit-small.svg" class="mr-8" /> {{ translate.MANAGER_MEMBERS }} 
      </button> -->
        </div>

        <div class="box-line ml-32 mr-32 mt-16 mb-32 p-16" *ngIf="isAlterMember">
            <h4 class="mt-0 mb-12">{{ translate.MANAGER_MEMBERS }}</h4>
            <span class="description">{{ translate.MANAGER_MEMBER_DESCRIPTION }}</span>

            <form [formGroup]="alterMemberForm" name="alterMemberForm" autocomplete="off" novalidate>
                <div fxLayout="row" fxLayoutAlign="start start" class="mt-16">
                    <mat-radio-group formControlName="radioManagerType">
                        <mat-radio-button [value]="0" class="mr-32" *ngIf="isAddMemberEnabled">{{
                            translate.INSERT_MEMBER
                        }}</mat-radio-button>
                        <mat-radio-button [value]="1" *ngIf="competitor.team.members && competitor.team.members.length > 0">{{
                            translate.ALTER_MEMBER
                        }}</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-around center" class="mt-16" *ngIf="managerType === 1">
                    <mat-form-field fxFlex="auto" class="mr-8">
                        <mat-select
                            placeholder="{{ translate.USER }}"
                            formControlName="member"
                            [errorStateMatcher]="matchers.member"
                            required
                        >
                            <mat-option *ngFor="let member of competitor.team.members" [value]="member.id">
                                <span fxLayout="row" fxLayoutAlign="start center" class="small-font">
                                    <img
                                        src="{{ member.user.avatar ? member.user.avatar : (null | placeholderImage : 'user') }}"
                                        width="30"
                                        class="avatar-select mr-8"
                                    />
                                    {{ member.user.fullname }}
                                </span>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="alterMemberForm.controls.member.hasError('required')">
                            {{ translate.VALIDATIONS.MEMBER_REQUIRED }}
                        </mat-error>
                    </mat-form-field>
                    <span fxFlex="70px" class="member-action out" fxLayout="row" fxLayoutAlign="center center">
                        <img src="./assets/icons/profiles/ic-member-exit.svg" class="mr-8" />
                        {{ translate.EXIT }}
                    </span>
                </div>

                <h4 class="mt-16 mb-12">{{ translate.NEW_MEMBER }}</h4>

                <div fxLayout="row" fxLayoutAlign="space-around center">
                    <mat-form-field fxFlex="auto" class="mr-8">
                        <input
                            matInput
                            placeholder="{{ translate.EMAIL }}"
                            type="email"
                            formControlName="email"
                            [errorStateMatcher]="matchers.email"
                            (blur)="searchUser($event)"
                        />
                        <mat-error *ngIf="alterMemberForm.controls.email.hasError('email')">
                            {{ translate.VALIDATIONS.EMAIL_INVALID }}
                        </mat-error>
                    </mat-form-field>
                    <span fxFlex="70px" class="member-action in" fxLayout="row" fxLayoutAlign="center center">
                        <img src="./assets/icons/profiles/ic-member-enter.svg" class="mr-8" /> {{ translate.ENTER }}
                    </span>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-around center" class="mt-8" *ngIf="user !== null">
                    <div fxFlex="auto" fxLayout="column" fxLayoutAlign="start start" class="user">
                        <div fxLayout="row" fxLayoutAlign="start center" class="small-font">
                            <img
                                src="{{ user.avatar !== null ? user.avatar : (null | placeholderImage : 'user') }}"
                                width="80"
                                class="avatar corner-radius"
                            />
                            {{ user.name }}
                        </div>
                        <span class="user-exist mt-16">Usuário encontrado em nossa base de dados.</span>
                    </div>
                    <span fxFlex="70px" class="member-action in" fxLayout="row" fxLayoutAlign="center center"></span>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-around center" class="mt-8" *ngIf="user === null">
                    <mat-form-field fxFlex="auto" class="mr-8">
                        <input
                            matInput
                            placeholder="{{ translate.NAME }}"
                            type="text"
                            formControlName="firstname"
                            [errorStateMatcher]="matchers.firstname"
                            uppercase
                            required
                        />
                        <mat-error *ngIf="alterMemberForm.controls.firstname.hasError('required')">
                            {{ translate.VALIDATIONS.FIRSTNAME_REQUIRED }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="auto" class="mr-8">
                        <input
                            matInput
                            placeholder="{{ translate.LASTNAME }}"
                            type="text"
                            formControlName="lastname"
                            [errorStateMatcher]="matchers.lastname"
                            uppercase
                            required
                        />
                        <mat-error *ngIf="alterMemberForm.controls.lastname.hasError('required')">
                            {{ translate.VALIDATIONS.LASTNAME_REQUIRED }}
                        </mat-error>
                    </mat-form-field>
                    <span fxFlex="70px"></span>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-around center" class="mt-8">
                    <mat-form-field fxFlex="auto" class="mr-8">
                        <input
                            matInput
                            placeholder="{{ translate.LEADERBOARD_NAME }}"
                            type="text"
                            formControlName="leaderboardName"
                            [errorStateMatcher]="matchers.leaderboardName"
                            (blur)="leaderboardNameBlur($event)"
                            (focus)="leaderboardNameFocus($event)"
                            uppercase
                            required
                        />
                        <mat-error *ngIf="alterMemberForm.controls.leaderboardName.hasError('minlength')">
                            {{ translate.VALIDATIONS.LEADERBOARDNAME_MINLENGTH }}
                        </mat-error>
                        <mat-error *ngIf="alterMemberForm.controls.leaderboardName.hasError('required')">
                            {{ translate.VALIDATIONS.LEADERBOARDNAME_REQUIRED }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="auto" class="mr-8">
                        <input
                            matInput
                            placeholder="{{ translate.BOX }}"
                            [matAutocomplete]="autoBox"
                            formControlName="box"
                            (ngModelChange)="searchBox($event)"
                            uppercase
                        />
                        <mat-autocomplete #autoBox="matAutocomplete" [displayWith]="displayFnBox">
                            <mat-option *ngFor="let box of boxes" [value]="box">
                                {{ box.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <span fxFlex="70px"></span>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-around center" class="mt-8">
                    <mat-form-field fxFlex="auto" class="mr-8">
                        <mat-select placeholder="{{ translate.TSHIRT }}" formControlName="tShirt">
                            <mat-option *ngFor="let size of tshirtSize" [value]="size">
                                {{ size }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span fxFlex="70px"></span>
                </div>
            </form>

            <div class="mt-8" fxLayoutAlign="end center">
                <btn [btnLoading]="true" type="grayscale" (click)="cancelAlterMember()">{{ translate.CANCEL | uppercase }}</btn>
                <btn
                    [btnLoading]="true"
                    [loading]="isLoadingAlterMember"
                    [buttonDisabled]="alterMemberForm.invalid"
                    class="ml-16"
                    (click)="alterMember()"
                    >{{ translate.SAVE | uppercase }}</btn
                >
            </div>
        </div>

        <div class="mr-32 ml-32 mt-16 mb-8 text-red" *ngIf="requestErrors">
            {{ requestErrors }}
        </div>

        <h4 class="pl-32 pr-32" *ngIf="payments.length > 0">{{ translate.PAYMENTS }}</h4>

        <!-- Table -->
        <table-x
            [items]="payments"
            [config]="tableConfig"
            [isLoading]="isLoading"
            *ngIf="payments.length > 0"
            (sortEvent)="sortAction($event)"
        >
            <column
                property="payment_date"
                header="{{ translate.TABLE_HEADER.PAYMENT_DATE }}"
                [sortable]="false"
                align="left"
                [borderLeftColor]="{ '1': '#8195A5', '2': '#81BB46', '3': '#EB5B46', '4': '#BC83EE', '11': '#BC83EE' }"
            >
                <ng-template #dataTableCell let-item="item">
                    <div class="pt-16 pb-16">
                        {{ (item.payment_date ? item.payment_date : item.created_at) | date : translate.DATE_FORMAT }}
                    </div>
                </ng-template>
            </column>

            <column property="payment_method" header="{{ translate.TABLE_HEADER.PAYMENT_METHOD }}" [sortable]="false" align="left">
                <ng-template #dataTableCell let-item="item">
                    {{ getPaymentMethodName(item) }}
                </ng-template>
            </column>

            <!--<column property="payment_delivery" header="{{ translate.TABLE_HEADER.PAYMENT_DELIVERY }}" [sortable]="false" align="left">
        <ng-template #dataTableCell let-item="item">
          {{ item.receive_date_vendor | date:translate.DATE_FORMAT }}
        </ng-template>
      </column>-->

            <column property="value" header="{{ translate.TABLE_HEADER.VALUE }}" [sortable]="false" align="left">
                <ng-template #dataTableCell let-item="item">
                    {{
                        formatCurrency(
                            item.payment_amount | currency : translate.CURRENCY_CODE : "symbol-narrow" : "1.2-2",
                            translate.CURRENCY_CODE
                        )
                    }}
                </ng-template>
            </column>

            <column property="payment_id" header="{{ translate.TABLE_HEADER.PAYMENT_ID }}" [sortable]="false" align="left">
                <ng-template #dataTableCell let-item="item">
                    {{ item.payment_id ? item.payment_id : "" }}
                </ng-template>
            </column>

            <column property="status" header="{{ translate.TABLE_HEADER.STATUS }}" [sortable]="false" align="left">
                <ng-template #dataTableCell let-item="item">
                    <label-x type="{{ getPaymentStatus(item, true) }}">
                        {{ getPaymentStatus(item) | uppercase }}
                    </label-x>
                </ng-template>
            </column>
        </table-x>
    </div>
</div>
