<a class="nav-link" *ngIf="!item.hidden && item.url" [routerLink]="[item.url]"
   [class.active]="isLinkActive(item.url)" matRipple>
    <div class="line"></div>
    <i class="nav-link-icon {{item.iconSvg}}" *ngIf="item.iconSvg"></i>
    <mat-icon class="nav-link-icon" *ngIf="item.icon && !item.iconSvg">{{item.icon}}</mat-icon>
    <span class="nav-link-title" [translate]="item.translate">{{item.title}}</span>
    <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate"
          [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
        {{item.badge.title}}
    </span>
</a>

<span class="nav-link" *ngIf="!item.hidden && item.function" (click)="item.function()" matRipple>
    <mat-icon class="nav-link-icon" *ngIf="item.icon">{{item.icon}}</mat-icon>
    <span class="nav-link-title" [translate]="item.translate">{{item.title}}</span>
    <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate"
          [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
        {{item.badge.title}}
    </span>
</span>
