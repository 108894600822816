export const locale = {
  lang: 'en-US',
  data: {
    'ALTER_MEMBER': {
      'TITLE': 'Alterar membro',
      'DESCRIPTION': 'Lorem ipsum dolor sit amet, vis suas mutat graece cu. In mea mazim periculis, sed porro pertinacia deterruisset ut, sea omnium facilisi necessitatibus at. Detracto.',
      'MEMBER_IN': 'Entra',
      'MEMBER_OUT': 'Sai',
      'NEW_MEMBER': 'Novo membro',
      'FIELDS': {
        'EMAIL': 'E-mail',
        'NAME': 'Nome',
        'LASTNAME': 'Sobrenome',
        'LEADERBOARD_NAME': 'Nome no leaderboard',
        'BOX': 'Box',
        'TSHIRT': 'Tamanho da camiseta'
      },
      'VALIDATIONS': {
        'EMAIL_INVALID': 'Formato de e-mail inválido',
        'NAME_REQUIRED': 'O campo nome é obrigatório',
        'LASTNAME_REQUIRED': 'O campo sobrenome é obrigatório'
      },
      'SAVE': 'Salvar',
      'CANCEL': 'Cancelar'
    }
  }
};
