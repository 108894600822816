export const locale = {
  lang: 'pt-BR',
  data: {
    'LEADERBOARD': {
      'TITLE': 'Leaderboard',
      'DESCRIPTION': 'Veja o leaderboard dessa etapa.',
      'PUBLISH_RANKING': 'Leaderboard preview, estes resultados não foram publicados no leaderboard público.',
      'REORDER': 'Reordenar empatados',
      'OPTIONS': 'Opções',
      'SEE_RANKING': 'Clique aqui e veja o leaderboard público.',
      'FORM': {
        'ALL': 'Todas',
        'SUB_DIVISION': 'Sub-categoria'
      },
      'PUBLISH': {
        'TITLE': 'PUBLICAR',
        'TITLE_DIALOG': 'Publicar resultados',
        'DESCRIPTION_DIALOG': 'Tem certeza que deseja publicar os resultados?',
        'DIALOG_CANCEL': 'CANCELAR',
        'TOASTR_TITLE': 'Resultados publicados',
        'TOASTR_DESCRIPTION': 'Resultados publicados',
      },
      'SEARCH': 'Procure pelo nome do atleta',
      'EXPORT': 'Exportar',
      'EXPORT_ALL': 'Exportar todos',
      'TABLE_HEADER': {
        'RANKING': 'Ranking',
        'NAME': 'Nome',
      },
      'EMPTY': 'Não existe usuários cadastrados para essa categoria.',
      'DISQUALIFIED': {
        'TYPE_CUT': 'CUT',
        'TYPE_WD': 'WD'
      },
    }
  }
};
