export const locale = {
  lang: 'pt-BR',
  data: {
    'MODAL_LOT': {
      'TITLE_NEW': 'Novo ingresso',
      'TITLE_EDIT': 'Editar ingresso',
      'TITLE_SECTION': 'Informações do ingresso',
      'DESCRIPTION_SECTION': 'Coloque as informações dos ingressos.',
      'DATE_FORMAT': 'DD/MM/YYYY',
      'FORM': {
        'NAME': 'Nome',
        'VALUE': 'Valor',
        'REPASS_TAX_FEE': 'Repassar taxa do CrossX ao cliente',
        'VALUE_TOTAL': 'Valor total a ser pago pelo cliente {{ value }}',
        'SETTINGS_OPTIONAL': 'Configurações opcionais',
        'LIMIT': 'Limite',
        'DATE_START': 'Data do início das vendas',
        'TIME_START': 'Horário de início',
        'DATE_END': 'Data do término das vendas',
        'TIME_END': 'Horário de término',
      },
      'VALIDATIONS': {
        'NAME_REQUIRED': 'O nome é obrigatório',
        'VALUE_REQUIRED': 'O Valor é obrigatório',
        'VALUE_MAX': 'O Valor máximo é de R$ 9.999,99'
      },
      'SAVE': 'Salvar',
      'CANCEL': 'Cancelar',
    }
  }
};
