import { TeamMember } from './team-member';
import { User } from './user';
import { Utils } from '../utils';
import {Country} from './country';
import {CustomField} from './custom-field.model';
import {SubscriptionCustomField} from './subscription-custom-field.model';

export class Team {
  id?: number = null;
  event_id?: number = null;
  user_id?: number;
  name?: string = null;
  user_name?: string = null;
  user?: User = null;
  fullnameSort?: string = null;
  leaderboard_name?: string = null;
  status?: number = null;
  box?: any = null;
  members?: TeamMember[] = null;
  type = 'single';
  country?: Country = null;
  subscription_custom_fields?: SubscriptionCustomField[] = null;

  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;

  deserialize(object: any) {
    (<any>Object).assign(this, object);

    if (object !== null) {
      if (object.members && object.members.length > 0) {
        this.members = (object.members as any).map((competitor: TeamMember) => new TeamMember().deserialize(competitor));
      }
      this.user = (object.user) ? new User().deserialize(object.user) : null;
      this.fullnameSort = this.getName(true);

      if (object.owner) {
        this.user = new User().deserialize(object.owner);
      }

      this.country = (object.country !== null) ? new Country().deserialize(object.country) : null;
      if (object.subscription_custom_fields && object.subscription_custom_fields !== undefined && object.subscription_custom_fields.length > 0) {
        this.subscription_custom_fields = (object.subscription_custom_fields as any).map((subscriptionCustomFields: SubscriptionCustomField) => new SubscriptionCustomField(subscriptionCustomFields));
        console.log(this.subscription_custom_fields);
      } 
    }

    return this;
  }

  getName(sort: boolean = false) {
    if (sort) {
      const fullname = Utils.removeAccents(`${this.name}`);
      return fullname;
    }
    return this.name;
  }
}
