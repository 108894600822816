export const locale = {
  lang: 'en-US',
  data: {
    USER_CHECKIN: {
      MESSAGE_DATE:
        'Check-in online estará disponível em {{ dateStartCheckin }} às {{ timeStartCheckin }} até {{ dateEndCheckin }} às {{ timeEndCheckin }}.',
      MESSAGE_DATE_CAN:
        'Check-in online está disponível até {{ dateEndCheckin }} às {{ timeEndCheckin }}.',
      MESSAGE_NO_SHOW: 'Check-in não realizado no evento.',
      MESSAGE_NO_SHOW_ONLINE:
        'A organização do evento definiu seu check-in como no show.',
      TITLE: 'Check-in',
      DIALOG: {
        CONFIRM: 'Confirmar',
        CANCEL: 'Cancelar',
        WITHDRAW: {
          TITLE: 'Desistir da participação',
          DESCRIPTION: '<b>{{ user_name }}</b> você tem certeza que deseja desistir de participar do evento <b>{{ event_name }}</b> na categoria <b>{{ category_name }}</b>? <br> <br> <b>Essa ação não poderá ser desfeita!</b>',
          DESCRIPTION_WITH_SUB_DIVISION: '<b>{{ user_name }}</b> você tem certeza que deseja desistir de participar do evento <b>{{ event_name }}</b> na categoria <b>{{ category_name }}</b> e {{ sub_division }} <b>{{ sub_division_name }}</b>? <br> <br> <b>Essa ação não poderá ser desfeita!</b>',
          NAME_WHO_WILL_GET_PACKAGE: 'Quem vai retirar minha camiseta:'
        },
        UPDATE_FILE: {
          TITLE: 'Alterar termo',
          DESCRIPTION: 'Você tem certeza que deseja realizar a alteração do termo?'
        }
      },
      TOASTR: {
        ERROR: {
          TITLE: 'Falha ao realizar check-in!'
        },
        SUCCESS: {
          TITLE: 'Check-in feito com sucesso!'
        },
        SUCCESS_WITHDRAW: {
          TITLE: 'Desistência realizada com sucesso!'
        },
        UPLOAD: {
          ERROR_FILE_REQUIRED: {
            TITLE: 'O upload dos termos são obrigatórios'
          },
          ERRO_FILE_SIZE: {
            DESCRIPTION: 'Arquivo excede o limite máximo de 10MB.'
          },
          ERROR: {
            TITLE: 'Falha ao realizar upload de termo'
          },
          SUCCESS_UPLOAD: {
            TITLE: 'Termo enviado com sucesso'
          },
          SUCCESS_DELETE: {
            TITLE: 'Termo removido com sucesso'
          }
        }
      },
      DELETE_FILE: 'Deletar termo',
      FILE: 'Termo',
      FORM: {
        FIELDS: {
          FILE: 'Upload',
          FILE_NAME: 'Anexo',
          UPDATE: 'Alterar'
        }
      },
      CHECKIN_ONLINE: 'Fazer Check-in online',
      CHECKIN_SUCCESS: 'Check-in realizado.',
      WITHDRAW: 'Desistir da participação'
    }
  }
};
