export const locale = {
  lang: 'en-US',
  data: {
    'LOTS_LIST': {
      'TITLE': 'Selecionar ingresso',
      'ADD_CUPOM': 'Adicionar código promocional',
      'CANCEL': 'Cancelar',
      'SELECT': 'Selecionar',
      'TAX': 'Taxa',
      'CURRENCY': 'R$',
      'CURRENCY_CODE': 'BRL',
      'SUBSCRIPTION_END': 'Final das inscrições: ',
      'DATE_FORMAT': 'DD.MM.YYYY',
      'PROMOCODE': 'Código promocional',
      'PROMOCODE_REQUIRED': 'Código obrigatório',
      'PROMOCODE_INVALID': 'Código inválido',
      'VALIDATE': 'Validar',
      'NOT_CAN_SUBSCRIBE': 'Ingressos esgotados',
      'EMPTY': 'Ingressos indisponiveis'
    }
  }
};