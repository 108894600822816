import { Competition } from './competition';
import { WodType } from './wod-type';
import { WodSubDivisionDate } from './wod-sub-division-date';
export class Wod {
  id: number;
  name: string;
  description?: string;
  video_url?: string;
  opens_at?: Date;
  closes_at?: Date;
  wod_type_id?: number;
  wod_type?: WodType;
  tiebreak_type_id?: number;
  tiebreak_type?: WodType = null;
  time_cap?: number | null;
  wod_date?: Date | null;
  competition?: Competition;
  points_group_id?: number;
  point_group?: any;
  goal?: number;
  goal_time?: string;
  results = 0;
  notification = false;
  published = true;
  created_at: Date;
  updated_at: Date;
  wod_sub_division_dates: WodSubDivisionDate[];
  canEditHeat?: boolean;

  deserialize(object: any) {
    (<any>Object).assign(this, object);

    if (object.wod_type) { this.wod_type = new WodType().deserialize(object.wod_type); }
    if (object.tiebreak_type) { this.tiebreak_type = new WodType().deserialize(object.tiebreak_type); }

    return this;
  }
}
