<div mat-dialog-content class="dialog-container">
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>{{ titleModel | uppercase }}</h1>
      <span class="close" (click)="dialogRef.close(null)">
        <img src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>
  
    <form [formGroup]="customFieldForm" fxLayout="column" name="customFieldForm" autocomplete="off" novalidate>
      <mat-form-field>
        <input matInput placeholder="{{ translate.FORM.NAME }}" formControlName="name" [errorStateMatcher]="matchers.name" required>
        <mat-error *ngIf="customFieldForm.controls.name.hasError('required')">
          {{ translate.VALIDATIONS.NAME_REQUIRED }}
        </mat-error>
      </mat-form-field>
      
      <mat-form-field>
        <input matInput placeholder="{{ translate.FORM.DESCRIPTION }}" formControlName="description" [errorStateMatcher]="matchers.description">
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="{{ translate.FORM.TYPE }}" formControlName="type" required>
          <mat-option *ngFor="let type of fieldTypes" [value]="type">
            {{ type.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="customFieldForm.controls.type.hasError('required')">
          {{ translate.VALIDATIONS.TYPE_REQUIRED }}
        </mat-error>
      </mat-form-field>

      <div fxLayoutAlign="row" *ngIf="showMaxCount()">
        <mat-form-field fxFlex="40" fxFlex.xs="100">
          <input matInput type="number" placeholder="{{ showMaxCountLabel() }}" formControlName="max_count" [errorStateMatcher]="matchers.max_count" uppercase>
        </mat-form-field>
      </div>
      
      <ng-container *ngIf="typeSelected && typeSelected.hasOptions">
        <table-x #tableOptions [items]="options" [config]="tableConfig" [isLoading]="false" tableName="custom-fields-options" [isDraggable]="true" (reorderEvent)="reorderAction($event)" [isHeader]="false">
          <column property="order" header="" [sortable]="false" align="left" classes="drag-handle">
            <ng-template #dataTableCell let-item="item">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="mr-8 menu-reorder">menu</mat-icon>
                <input
                  type="text"
                  value="{{ item.name }}"
                  class="result_field"
                  (blur)="inputOnBlur($event, item)"
                  placeholder="Digite o nome" />
              </div>
            </ng-template>
          </column>

          <column property="actions" header="" [sortable]="false" align="center" classes="action" maxWidth="50px" width="50px">
            <ng-template #dataTableCell let-item="item" let-index="index">
              <btn-transparent materialIcon="delete" buttonWidth="35px" iconAlign="left" (click)="removeOption(item)" class="hide-in-drag"></btn-transparent>
            </ng-template>
          </column>
        </table-x>
        <div class="pt-16">
          <btn-icon-small icon="add_circle_outline" iconAlign="left" (click)="addOption()">{{ translate.FORM.ADD_OPTION }}</btn-icon-small>
        </div>
      </ng-container>
    </form>
    
    <div class="text-red" *ngIf="errorRequest !== null">
      {{ errorRequest }}
    </div>
    
    <div class="mb-0 mt-8" fxLayoutAlign="end center">
      <btn type="grayscale" (click)="dialogRef.close(null)" class="mr-16">{{ translate.CANCEL | uppercase }}</btn>
      <btn [btnLoading]="true" [loading]="isLoading" (click)="saveCustomField()">{{ translate.SAVE | uppercase }}</btn>
    </div>
  </div>
</div>