export const locale = {
    lang: 'en-US',
    data: {
        'NAV': {
          'DASHBOARD': {
              'TITLE': 'Dashboard',
              'COMPONENTS': 'Componentes',
              'MANAGEMENT': 'Gerencial',
              'TRAINING': 'Treino',
              'CLIENT': 'Cliente',
              'FINANCIAL': 'Finaneiro'
          },
          'EVENTCONFIG': {
            'TITLE': 'Event setup',
            'INFORMATIONS': 'Informations',
            'SUBSCRIPTIONS': 'Subscription',
            'CHAMPIONSHIPS': 'Championship',
            'LEADERBOARD': 'Geral',
            'PERMISSIONS': 'Permissões',
            'APP_CROSSX': 'Aplicativo CrossX'
          },
          'PARTICIPANTS': {
            'TITLE': 'Participants'
          },
          'CUPONS': {
            'TITLE': 'Cupons'
          },
          'POINTS': {
            'TITLE': 'Points'
          },
          'SUBSCRIPTIONS': {
            'TITLE': 'Subscriptions'
          },
          'CHAMPIONSHIPS': {
            'TITLE': 'Etapas',
            'TITLE_PLURAL': 'Etapas',
            'TICKETS': 'Ingressos',
            'PARTICIPANTS': 'Participantes',
            'WODS': `Wods`,
            'RESULTS': 'Resultados',
            'LEADERBOARD': 'Leaderboard',
            'CATEGORY': 'Categories',
            'SUB_DIVISION': 'Sub-categorias',
            'HEATS': 'Heats',
            'HEATS-TESTE': 'Heats-TESTE',
            'LOTS': 'Tickets'
          },
          'USER_DASHBOARD': {
            'INFORMATION': 'Informações',
            'RESULTS': 'Resultados',
            'PAYMENTS': 'Pagamentos',
            'LEADERBOARD': 'Leaderboard'
          }
        }
    }
};
