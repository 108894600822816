import { FacebookUserService } from './../../../../core/services/facebook.service';
import { AuthGuard } from './../../../../core/guards/auth.guard';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './../../../../core/services/authentication.service';
import { config } from './../../../../core/config/config';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '../../../../core/services/config.service';
import { fuseAnimations } from '../../../../core/animations';
import { Title } from '@angular/platform-browser';
import { FuseTranslationLoaderService } from '../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

// LOCALE
import { locale as english } from './i18n/en-US';
import { locale as portuguese } from './i18n/pt-BR';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changeForm: FormGroup;
  changeFormErrors: any;
  loadingButton: boolean = false
  changeError: string
  hidePassword = true
  hideConfirmPassword = true
  token: string

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private fuseConfig: FuseConfigService,
    private formBuilder: FormBuilder,
    private titleService: Title,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private authGuard: AuthGuard,
    private location: Location
  )
  {
    this.translationLoader.loadTranslations(english, portuguese);

    this.fuseConfig.setSettings({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });

    this.changeFormErrors = {
      password: {},
      confirmPassword: {}
    };

    //verify user logged
    //this.authGuard.canActivateWithoutRedirect()

    this.route.params.subscribe(
      params => {
        console.log(params)
        this.token = params.token

        if (this.token === null) {
          this.router.navigate(['login'])
          // this.location.back()
        }
      }
    );
  }

  ngOnInit() {
    // Set page Tilte
    this.translateService.get("CHANGE_PASSWORD").subscribe((res: any) => {
      this.titleService.setTitle(`${config.titleBase} - ${res.TITLE}`)
    });

    this.changeForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
    });

    this.changeForm.valueChanges.subscribe(() => {
      this.onregisterFormValuesChanged();
    });

    this.onregisterFormValuesChanged()
  }

  changePassword()
  {
    this.loadingButton = true
    
    this.authenticationService.changePasswordWithToken(
      this.changeForm.value.password,
      this.changeForm.value.confirmPassword,
      this.token
    ).subscribe(
      result => {
        console.log("result", result);
        this.loadingButton = false
        this.router.navigate(['login'])

      }, err => {
        console.log("err", err);
        this.loadingButton = false
        this.changeError = err.error.errors[0].message
      }
    );
  }

  gotoLogin()
  {
    this.router.navigate(['login'])
  }

  onregisterFormValuesChanged() {
    for (const field in this.changeFormErrors) {
      if (!this.changeFormErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.changeFormErrors[field] = {};

      // Get the control
      const control = this.changeForm.get(field);
      if (control && control.dirty && !control.valid) {
        control.errors["invalid"] = (!control.valid) ? true : null;

        if (field === 'confirmPassword' && this.changeForm.get(field).value !== "" && this.changeForm.get('password').value !== "") {
          if (this.changeForm.get('confirmPassword').value !== this.changeForm.get('password').value) {
            control.errors["equals"] = false;
          } else {
            control.errors["equals"] = null;
          }
        }

        this.changeFormErrors[field] = control.errors;
      }
    }
  }

}
