import { NgModule } from '@angular/core';
import { CouponsComponent } from './coupons.component';
import { AuthGuard } from '../../../../core/guards/auth.guard';
import { EventGuard } from '../../../../core/guards/event.guard';
import { SharedModule } from '../../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../../../../environments/environment';
import { ModalCouponComponent } from './modal-coupon/modal-coupon.component';

const routes = [
  {
    path: 'coupons',
    component: CouponsComponent,
    canActivate: [AuthGuard, EventGuard]
  }
];

@NgModule({
  declarations: [
    CouponsComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    })
  ],
  exports: [
    CouponsComponent
  ]
})
export class CouponsModule { }
