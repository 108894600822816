export const locale = {
  lang: 'en-US',
  data: {
    'HEATS': {
      'CHAMPIONSHIP': 'Championship',
      'HEAT': 'Heat',
      'TABS': {
        'SINGLE': 'Single',
        'TEAM': 'Team'
      },
      'DATE_FORMAT': 'MM/dd/yyyy',
      'TABLE': {
        'LANE': 'Lane',
        'ATHLETE': 'Athlete',
        'WOD': 'Wod',
        'BOX': 'Box'
      }
    }
  }
};
