import { Component, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { locale as portugues } from './i18n/pt-BR';
import { Title } from '@angular/platform-browser';
import { TabBarComponent } from '../../../../../core/components/tab-bar/tab-bar.component';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { config } from '../../../../../core/config/config';
import { combineLatest, Subscription, zip } from 'rxjs';
import { BreadcrumbsService } from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import { HeatsUrlService } from './services/heats-url.service';
import { Params } from '@angular/router';
import { Competition } from '../../../../../core/model/competition';
import { HeatsHttpService } from '../../../../../core/services/heats/heats-http.service';
import { HeatsService } from './services/heats.service';
import { HeatQueryParams } from './enums/heat-query.params';

@Component({
  selector: 'app-heats-items',
  templateUrl: './heats.component.html',
  styleUrls: ['./heats.component.scss']
})

export class HeatsComponent implements OnInit, OnDestroy {

  @ViewChild('tabbar', {read: TabBarComponent})
  tabBar: TabBarComponent;

  // championshipId: number;
  // translate
  translate: any;
  private readonly subscriptions = new Subscription();

  private championshipId: number;
  private competition = new Array<Competition>();

  constructor(
    private titleService: Title,
    private translateService: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private breadcrumbsService: BreadcrumbsService,
    private heatsUrlService: HeatsUrlService,
    private heatsHttpService: HeatsHttpService,
    private heatsService: HeatsService
  ) {
    // translate
    this.registerOnTranslateHeatCompetitors();
    this.registerOnPathParamsAndQueryParams();
    this.registerOnCompetition();
  }

  private registerOnCompetition(): void {
    const subscription = this.heatsHttpService
    .competitionsObservable()
    .subscribe((competition: Competition[]) => {
      this.competition = competition;
      this.heatsService
        .sendCompetitionAndWod(this.competition);
    });
    this.subscriptions.add(subscription);
  }

  ngOnInit() {
    this.setTitle();
  }

  public onScroll(event): void {
    this.heatsService
      .sendEventScroll(event);
  }

  private registerOnPathParamsAndQueryParams(): void {
    const subscription = combineLatest(
      this.heatsUrlService
        .paramsObservable(),
      this.heatsUrlService
        .queryParamsObservable(),
      this.heatsHttpService
    ).subscribe(([params, queryParams, url]) => {
      this.championshipId = params.championship_id;

      let type = queryParams.find((queryParam) => queryParam.key === HeatQueryParams.TYPE );
      if (!type) {
        type = {
          key: HeatQueryParams.TYPE,
          value: HeatQueryParams.TYPE_SINGLE
        };
      }
      
      this.heatsHttpService
        .fetchCompetitions(this.championshipId, type.value);
    });
    
    this.subscriptions.add(subscription);
  }

  private registerOnTranslateHeatCompetitors(): void {
    this.translationLoader.loadTranslations(portugues);
    const subscription = this.translateService
      .get('HEAT_HEATCOMPETITORS')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);
  }

  private setTitle(): void {
    if (this.translate) {
      this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE}`);
      this.breadcrumbsService.replaceAll([{
        text: this.translate.TITLE,
        route: `/championship/${this.championshipId}/heats/`
      }]);
    }

  }

  get showHeats(): boolean {
    return this.competition.length !== 0;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
