import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Subscription, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SubDivision } from '../../model/sub-division';

@Injectable({
  providedIn: 'root'
})
export class SubDivisionHttpService implements OnDestroy {

  private subscriptions = new Subscription();
  private _subDivisionObservable = new Subject<SubDivision[]>();

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) { }

  subDivisionObservable(): Observable<SubDivision[]> {
    return this._subDivisionObservable
        .asObservable();
  }

  fetchSubDivision(championshipId: number): void {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'sub-division'
    ];
    const queryParams = {
        championship_id: championshipId
    };

    const url = this.router.createUrlTree(baseUrl, {
        queryParams: queryParams
      });

    const parsedUrl = url.toString().substring(1, url.toString().length);

    const subscription = this.httpClient
      .get(parsedUrl, { responseType })
      .subscribe((result: SubDivision[] ) => {
            this._subDivisionObservable.next(result);
        },
        (error) => {
            this._subDivisionObservable.next([]);
        });

    this.subscriptions.add(subscription);
  }

  createSubDivision(data: SubDivision, championshipId: number): Observable<SubDivision> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'sub-division'
    ];
    const queryParams = {
        championship_id: championshipId
    };

    const url = this.router.createUrlTree(baseUrl, {
        queryParams: queryParams
      });

    const parsedUrl = url.toString().substring(1, url.toString().length);

    return this.httpClient
      .post(parsedUrl, data, { responseType })
      .map((response: SubDivision ) => {
        return response;
      });
  }

  updateOrderSubDivision(data: any, championshipId: number): Observable<SubDivision[]> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'sub-division-order'
    ];
    const queryParams = {
        championship_id: championshipId
    };

    const url = this.router.createUrlTree(baseUrl, {
        queryParams: queryParams
      });

    const parsedUrl = url.toString().substring(1, url.toString().length);

    return this.httpClient
      .put(parsedUrl, data, { responseType })
      .map((response: SubDivision[] ) => {
        return response;
      });
  }
  updateSubDivision(id: number, data: SubDivision, championshipId: number): Observable<SubDivision> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'sub-division',
      id
    ];
    const queryParams = {
        championship_id: championshipId
    };

    const url = this.router.createUrlTree(baseUrl, {
        queryParams: queryParams
      });

    const parsedUrl = url.toString().substring(1, url.toString().length);

    return this.httpClient
      .put(parsedUrl, data, { responseType })
      .map((response: SubDivision ) => {
        return response;
      });
  }

  deleteSubDivision(id: number, championshipId: number): Observable<SubDivision> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'sub-division',
      id
    ];
    const queryParams = {
        championship_id: championshipId
    };

    const url = this.router.createUrlTree(baseUrl, {
        queryParams: queryParams
      });

    const parsedUrl = url.toString().substring(1, url.toString().length);

    return this.httpClient
      .delete(parsedUrl, { responseType })
      .map((response: SubDivision ) => {
        return response;
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
