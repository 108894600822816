<div mat-dialog-content class="dialog-container">
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>{{ ((isEdit) ? translate.TITLE_EDIT : translate.TITLE_NEW) | uppercase }}</h1>
      <span class="close" (click)="dialogRef.close(null)">
        <img src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>

    <div fxLayout="row" class="mt-16 mb-32">
      <description-section title="{{ translate.TITLE_SECTION }}" text="{{ translate.DESCRIPTION_SECTION }}">
        <mat-icon class="ic-info mr-8" svgIcon="ic-cat-info"></mat-icon>
      </description-section>
    </div>

    <form [formGroup]="wodForm" name="wodForm" autocomplete="off" novalidate>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
        <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8">
          <input matInput placeholder="{{ translate.FORM.NAME }}" formControlName="name" [errorStateMatcher]="matcher"
            uppercase required>
          <mat-error *ngIf="wodForm.controls.name.hasError('required')">
            {{ translate.VALIDATIONS.NAME_REQUIRED }}
          </mat-error>
        </mat-form-field>
      </div>
      
      <h4 class="mt-32">{{ translate.TITLE_RESULTS }}</h4>

      <div fxLayout="row" fxLayout.xs="column" fxLayout.xs="column" fxLayoutAlign="start start">
        <div fxLayout="row" fxLayout.xs="column" fxFlex="50" fxLayoutAlign="start start" >
          <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-16">
            <mat-select placeholder="{{ translate.FORM.RESULT_TYPE }}" formControlName="resultType" [errorStateMatcher]="matcher" required>
              <mat-option *ngFor="let unit of unitsType" [value]="unit">
                {{ unit.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="wodForm.controls.resultType.hasError('required')">
              {{ translate.VALIDATIONS.RESULT_TYPE_REQUIRED }}
            </mat-error>
          </mat-form-field>
          
          <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-16">
            <mat-select placeholder="{{ translate.FORM.TIEBREAK_TYPE }}" formControlName="tiebreakType">
              <mat-option *ngFor="let unit of unitsTypeTiebreak" [value]="unit">
                {{ unit.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-16" *ngIf="championship.type === 3">
          <mat-select placeholder="{{ translate.FORM.POINTS }}" formControlName="points" [errorStateMatcher]="matcher" [disabled]="wod && wod.results > 0" required>
            <mat-option *ngFor="let point of points" [value]="point">
              {{ point.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="wodForm.controls.points.hasError('required')">
            {{ translate.VALIDATIONS.POINTS_REQUIRED }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="(wod && wod.wod_type && wod.wod_type.id === 2) || (wodForm.value.resultType && wodForm.value.resultType.id === 2)">
        <mat-form-field fxFlex="25" fxFlex.xs="80" class="pr-8">
          <input type="number" matInput placeholder="{{ translate.FORM.GOALS }}" formControlName="goals" [errorStateMatcher]="matcher">
        </mat-form-field>
        <img src="./assets/icons/common/ic-info.svg" matTooltip="teste" matTooltipPosition="right" matTooltipClass="right" class="mb-8 ml-4" />
      </div>

      <h4 class="mt-32">{{ translate.TITLE_ADDTIONAL }}</h4>

      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field fxFlex="50" fxFlex.xs="80" class="pr-8">
          <input matInput min="1" type="number" placeholder="{{ 'MODAL_WOD.FORM.TIME_CAP' | translate }}" formControlName="time_cap">
          <mat-error *ngIf="wodForm.controls.time_cap.hasError('pattern')">
            {{ 'MODAL_WOD.VALIDATIONS.TIME_CAP_PATTERN' | translate }}
          </mat-error>
          <mat-error *ngIf="wodForm.controls.time_cap.hasError('min')">
            {{ 'MODAL_WOD.VALIDATIONS.TIME_CAP_MIN' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="!competition.enable_sub_division">
        <mat-form-field fxFlex="50" fxFlex.xs="80" class="pr-8 inputDate">
          <input matInput placeholder="{{ 'MODAL_WOD.FORM.WOD_DATE' | translate }}" formControlName="wod_date" [dropSpecialCharacters]="false" mask="{{ 'MODAL_WOD.DATE_FORMAT_INPUT' | translate }}">
          <mat-icon matSuffix>date_range</mat-icon>
          <mat-error *ngIf="wodForm.controls.wod_date.hasError('invalid')">
            {{ translate.VALIDATIONS.DATE_WOD_INVALID }}
          </mat-error>
          <mat-error *ngIf="wodForm.controls.wod_date.hasError('max_date_invalid')">
            {{ translate.VALIDATIONS.DATE_WOD_MAX_DATE }}
          </mat-error>
        </mat-form-field>
      </div>
      
      <div fxLayout="column" class="sub-division" *ngIf="competition.enable_sub_division">
        <div formArrayName="wod_sub_division_dates">
          <table-x #tableSubDivision [items]="subDivisions" [config]="tableCatConfig" [isDraggable]="false">
            <column 
              class="p-0"
              property="sub_division"
              header="{{ (championship.sub_division_group_name ? (championship.sub_division_group_name) : ('MODAL_WOD.SUB_DIVISION.TITLE' | translate | uppercase)) }}"
              [sortable]="false"
              align="left"
            >
              <ng-template #dataTableCell let-item="item" let-index="index">
                {{ item.name ? (item.name | uppercase) : '' }}
              </ng-template>
            </column>
            <column 
              class="p-0"
              property="sub_division"
              header="{{ 'MODAL_WOD.FORM.WOD_DATE' | translate }}"
              [sortable]="false"
              align="center"
              width="30px"
            >
              <ng-template #dataTableCell let-item="item" let-index="index">
                <div [formGroupName]="index">
                  <mat-form-field class="inputDate">
                    <input matInput formControlName="wod_date" placeholder="" [dropSpecialCharacters]="false" mask="{{ 'MODAL_WOD.DATE_FORMAT_INPUT' | translate }}">
                    <mat-icon matSuffix>date_range</mat-icon>
                    <mat-error *ngIf="wodForm.controls['wod_sub_division_dates'].controls[index].get('wod_date').hasError('invalid')">
                      {{ translate.VALIDATIONS.DATE_WOD_INVALID }}
                    </mat-error>
                    <mat-error *ngIf="wodForm.controls['wod_sub_division_dates'].controls[index].get('wod_date').hasError('max_date_invalid')">
                      {{ translate.VALIDATIONS.DATE_WOD_MAX_DATE }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </ng-template>
            </column>
          </table-x>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field fxFlex="50" fxFlex.xs="80" class="pr-8 mt-8">
          <input matInput placeholder="{{ translate.FORM.URL_VIDEO }}" formControlName="urlVideo" [errorStateMatcher]="matcher">
          <mat-error *ngIf="wodForm.controls.urlVideo.hasError('pattern')">
            {{ translate.VALIDATIONS.URL_INVALID }}
          </mat-error>
        </mat-form-field>
      </div>
      
      <div fxLayout="row" fxLayoutAlign="start center" class="mt-16">
         <quill [config]="configEditor" [(value)]="description">
          <div quillToolbar></div><!-- Optional custom toolbar -->
          <div quillContent></div><!-- Optional pre-filled content -->
        </quill>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="mt-32" *ngIf="wod === null && formCompetition().controls.length > 1">
        <mat-slide-toggle fxFlex="auto" formControlName="addAllCompetition">{{ translate.FORM.ADD_ALL_COMPETITIONS }}</mat-slide-toggle>
      </div>

      <div fxLayout="column" *ngIf="!wodForm.value.addAllCompetition && wod === null" class="mt-16">
        <div formArrayName="competitions" class="competitions-list">
          <div *ngFor="let competitionCheckbox of formCompetition().controls; let i = index" class="pb-4 competition-item">
            <mat-checkbox [formControlName]="i" [disabled]="(competitionsCheckbox[i].id === competition.id) ? true : null">{{ competitionsCheckbox[i].name }}</mat-checkbox>
          </div>
        </div>
      </div>

      <div class="line-bottom mt-32 mb-32"></div>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" *ngIf="!championship.online">
        <mat-checkbox fxFlex="55" formControlName="publishWorkout" class="mb-4">{{ translate.FORM.PUBLISH_WORKOUT }}</mat-checkbox>
        <!-- <mat-slide-toggle fxFlex="55" formControlName="publishWorkout">{{ translate.FORM.PUBLISH_WORKOUT }}</mat-slide-toggle> -->

        <div fxFlex="45" fxLayout="row" fxLayoutAlign="start center" class="pl-8" *ngIf="wodForm.value.publishWorkout">
          <mat-form-field fxFlex="40" class="inputDate">
            <input matInput placeholder="{{ translate.FORM.DATE_PUBLISH }}" formControlName="datePublish"
              [errorStateMatcher]="matcher" [dropSpecialCharacters]="false" mask="00/00/0000" required>
            <!-- (ngModelChange)="verifyDate($event)" -->
            <mat-icon matSuffix>date_range</mat-icon>
            <mat-error *ngIf="wodForm.controls.datePublish.hasError('required')">
              {{ translate.VALIDATIONS.DATE_REQUIRED }}
            </mat-error>
            <mat-error *ngIf="!wodForm.controls.datePublish.hasError('required') && wodForm.controls.datePublish.hasError('invalid')">
              {{ translate.VALIDATIONS.DATE_INVALID }}
            </mat-error>
          </mat-form-field>
          
          <select-time
            #pickerTimePublish
            fxFlex="44"
            class="pl-12"
            label="{{ translate.FORM.TIME_PUBLISH }}" [value]="timePublish"
            [required]="true"
            (inputValue)="updatePickerValue($event, 'time-publish')"
          ></select-time>
            
          <img src="./assets/icons/common/ic-info.svg" [matTooltip]="timezoneDescription" matTooltipPosition="right" matTooltipClass="right"
            class="mb-8 ml-4" *ngIf="timezoneDescription" />
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="start start" *ngIf="championship.online">
        <h4 class="mt-0">{{ translate.TITLE_PUBLISH }} <img src="./assets/icons/common/ic-info.svg" [matTooltip]="timezoneDescription" matTooltipPosition="right"
          matTooltipClass="right" class="ml-4" *ngIf="timezoneDescription" /></h4>

        <div fxLayout="1 1 100%" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="line-date">
          <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center" class="pr-8">
            <mat-form-field fxFlex="60" class="inputDate">
              <input matInput placeholder="{{ translate.FORM.DATE_PUBLISH }}" formControlName="datePublish" [errorStateMatcher]="matcher"
                 [dropSpecialCharacters]="false" mask="00/00/0000" required>
              <!-- (ngModelChange)="verifyDate($event)" -->
              <mat-icon matSuffix>date_range</mat-icon>
              <mat-error *ngIf="wodForm.controls.datePublish.hasError('required')">
                {{ translate.VALIDATIONS.DATE_REQUIRED }}
              </mat-error>
              <mat-error *ngIf="!wodForm.controls.datePublish.hasError('required') && wodForm.controls.datePublish.hasError('invalid')">
                {{ translate.VALIDATIONS.DATE_INVALID }}
              </mat-error>
            </mat-form-field>
          
            <select-time #pickerTimePublish fxFlex="40" class="pl-12" label="{{ translate.FORM.TIME_PUBLISH }}" [value]="timePublish"
              [required]="true" (inputValue)="updatePickerValue($event, 'time-publish')"></select-time>
          </div>

          <div fxFlex="50" fxLayout="row" fxLayout.xs="columnn" fxLayoutAlign="start center" fxLayoutAlign.xs="start stretch" class="pl-8">
            <mat-form-field fxFlex="60" fxFlex.xs="100" class="inputDate">
              <input matInput placeholder="{{ translate.FORM.DATE_END }}" formControlName="dateEnd" [errorStateMatcher]="matcher"
                [dropSpecialCharacters]="false" mask="00/00/0000" required>
              <!-- (ngModelChange)="verifyDate($event)" -->
              <mat-icon matSuffix>date_range</mat-icon>
              <mat-error *ngIf="wodForm.controls.dateEnd.hasError('required')">
                {{ translate.VALIDATIONS.DATE_REQUIRED }}
              </mat-error>
              <mat-error *ngIf="!wodForm.controls.dateEnd.hasError('required') && wodForm.controls.dateEnd.hasError('invalid')">
                {{ translate.VALIDATIONS.DATE_INVALID }}
              </mat-error>
            </mat-form-field>
          
            <select-time #pickerTimeEnd fxFlex="40" fxFlex.xs="100" class="pl-12" label="{{ translate.FORM.TIME_END }}" [value]="timeEnd"
                         (inputValue)="updatePickerValue($event, 'time-end')" [required]="true"></select-time>
          </div>
        </div>
      </div>

      <!-- <div fxLayout="row" fxLayoutAlign="start center">
        <mat-checkbox fxFlex="auto" formControlName="sendEmail">{{ translate.FORM.EMAIL_PUBLISH }}</mat-checkbox>
      </div> -->

    </form>

    <div class="mt-16 mb-8 text-red" *ngIf="requestErrors !== null">
      {{ requestErrors }}
    </div>

    <div class="mb-0 mt-8" fxLayoutAlign="end center">
      <btn type="grayscale" (click)="dialogRef.close(null)" class="mr-16">{{ translate.CANCEL | uppercase }}</btn>
      <btn [btnLoading]="true" [loading]="isLoading" [buttonDisabled]="wodForm.invalid" (click)="createOrUpdateWod()">{{
        translate.SAVE | uppercase }}</btn>
    </div>
  </div>
</div>