import { Utils } from './../utils';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'placeholderImage',
  pure: false
})
export class PlaceholderImagePipe implements PipeTransform {

  transform(value: any, type: string = 'user'): string {
    // console.log("placeholder", value, 'type:', type)
    return Utils.getPicture(value, type);
  }

}
