export const locale = {
  lang: 'pt-BR',
  data: {
    'USER': {
      'SAVE': 'Salvar',
      'CANCEL': 'Cancelar',
      'DATE_FORMAT': 'dd/MM/yyyy',
      'TEAM_NAME': 'Nome do time',
      'GENDER': 'Sexo',
      'BIRTHDATE': 'Data de nascimento',
      'BOX': 'Box',
      'CITY': 'Cidade',
      'CPF': 'CPF',
      'SIZE_TSHIRT': 'Tamanho da camiseta',
      'PHONE': 'Telefone',
      'CATEGORY': 'Categoria',
      'SUB_DIVISION': 'Sub-categorias',
      'PAYMENTS': 'Pagamentos',
      'STATUS': 'Status',
      'TEAM_MEMBERS': 'Membros do time',
      'ALTER_MEMBER': 'Alterar membro',
      'ALTER_MEMBER_DESCRIPTION': 'Gerencie os membros da equipe, inclua um novo membro caso o limite não tenha sido atingido, troque algum membro por outro.',
      'MANAGER_MEMBERS': 'Gerenciar membros',
      'MANAGER_MEMBER_DESCRIPTION': 'Gerencie os membros da equipe, inclua um novo membro caso o limite não tenha sido atingido, troque algum membro por outro.',
      'INSERT_MEMBER': 'Inserir membro',
      'USER': 'Usuário',
      'NEW_MEMBER': 'Novo membro',
      'NAME': 'Nome',
      'LASTNAME': 'Sobrenome',
      'EMAIL': 'E-mail',
      'LEADERBOARD_NAME': 'Nome no leaderboard',
      'TSHIRT': 'Tamanho da camiseta',
      'ENTER': 'ENTRA',
      'EXIT': 'SAI',
      'TABLE_HEADER': {
        'PAYMENT_DATE': 'Data pagto',
        'PAYMENT_METHOD': 'Forma de pagamento',
        'PAYMENT_DELIVERY': 'Data do recebimento',
        'VALUE': 'Valor',
        'STATUS': 'Status',
        'NAME': 'Nome',
        'EMAIL': 'E-mail',
        'TSHIRT': 'Camiseta',
        'PAYMENT_ID': 'ID da transação'
      },
      'VALIDATIONS': {
        'SUB_DIVISION_REQUIRED': 'A sub-categoria é obrigatória',
        'TEAM_NAME_REQUIRED': 'O nome do time é obrigatório',
        'MEMBER_REQUIRED': 'É Obrigatório selecionar o membro',
        'CATEGORY_REQUIRED': 'A categoria é obrigatória',
        'EMAIL_INVALID': 'Digite um e-mail válido',
        'FIRSTNAME_REQUIRED': 'O nome é obrigatório',
        'LASTNAME_REQUIRED': 'O Sobrenome é obrigatório',
        'LEADERBOARDNAME_MINLENGTH': 'Necessário ter no mínimo 4 caracteres',
        'LEADERBOARDNAME_REQUIRED': 'O nome no leaderboard é obrigatório',
        'NUMBER_REQUIRED': 'O número é obrigatório.',
        'NUMBER_LIMIT': 'O número não pode ser menor que {{ custom_number }}.',
      },
      'INACTIVE': 'Inativar',
      'ACTIVE': 'Ativar',
      'CONFIRM_STATUS_INACTIVE': 'Tem certeza que deseja inativar?',
      'CONFIRM_STATUS_ACTIVE': 'Tem certeza que deseja ativar?',
      'STATUS_COMPETITOR': {
        'ACTIVE': 'Ativo',
        'INACTIVE': 'Inativo',
        'PEDDING': 'Pendente',
        'CANCELLED': 'Cancelado'
      },
      'DISQUALIFIED' : {
        'CUT': 'Cortar',
        'TYPE_CUT': '(CUT)',
        'WD': 'Desclassificar',
        'TYPE_WD': '(WD)',
        'REMOVE': 'Cancelar',
        'SUCCESS': 'Atualizado com sucesso! Para postar acesse resultados e publique.',
        'MESSAGE': 'Tem certeza que deseja {{ custom_field_name }} {{ custom_field_type }} este competidor?',
        'CANCEL': 'Cancelar',
        'CONFIRM': 'Confirmar'
      },
      'PAYMENT_METHOD': {
        'CREDIT_CARD': 'Cartão de crédito',
        'BOLETO': 'Boleto'
      },
      'PAYMENT_STATUS': {
        'WAITING': 'Aguardando resposta',
        'APPROVED': 'Aprovado',
        'REFUSED': 'Recusado',
        'REFUNDED': 'Estornado',
        'CHARGEBACK': 'Chargeback'
      },
      'CURRENCY': 'R$',
      'CURRENCY_CODE': 'BRL',
      'TOASTR': {
        'INACTIVE': {
          'TITLE': 'Time inativado com sucesso',
          'DESCRIPTION': 'Você inativou o time'
        },
        'ACTIVE': {
          'TITLE': 'Time ativado com sucesso',
          'DESCRIPTION': 'Você ativado o time'
        },
        'UPDATE': {
          'TITLE': 'Participante atualizado com sucesso!',
          'DESCRIPTION': ''
        }
      }
    }
  }
};
