export const locale = {
  lang: 'en-US',
  data: {
    'EVENT': {
      'EVENT_NOT_EXIST': 'Event not found!',
      'GOTO_HOME': 'GO TO HOME',
      'LOTS': 'Tickets',
      'LOTS_SOLDOUT': 'Ticket Soldout',
      'LOTS_WAITING': 'Ticket coming soon!',
      'TABS': {
        'INFORMATION': 'Information',
        'HEATS': 'Heats',
        'WODS': 'Workouts',
        'LEADERBOARD': 'Leaderboard'
      },
      'SEE_REGULATION': 'Rule book',
      'SEE_TERM': 'Waiver',
      'DATE_TIME':{
        'TITLE': 'Date/time',
        'START': 'Start',
        'END': 'End'
      },
      'LOCATION': 'Location',
      'SHARE': 'Share',
      'DATE_FORMAT_COMPLETE': 'DD[,] MMMM[,] YYYY',
      'DATETIME_FORMAT': 'MM/DD/YYYY HH:mm',
      'SUBSCRIPTION': {
        'TITLE': 'Subscription found',
        'DESCRIPTION': 'You have already started a subscription for this event, would you like to continue?',
        'DESCRIPTION_COMPLETED': 'You already enrolled in this event'
      },
      'SUBSCRIPTION_ORDER': {
        'TITLE': 'Subscription found',
        'DESCRIPTION': 'You have already registered, but the ticket has not yet been paid. Confirmation of your registration may take up to 3 business days after payment.',
      },
      'BANK_ACCOUNT': {
        'TITLE': 'Event configuration error',
        'DESCRIPTION': 'No bank account configured'
      }
    }
  }
};