import {CustomFieldType} from './custom-field-type.model';
import {DefaultCustomField} from './default-custom-field.model';
import {CustomFieldOption} from './custom-field-option.model';
import {Utils} from '../utils';

export class CustomField {
  id: number;
  type: string;
  subscription_type?: string;
  name: string;
  description?: string | null;
  max_count?: number | null;
  is_required: boolean = false;
  is_hidden: boolean = false;
  default_field: boolean = false;
  can_be_delete?: boolean = true;
  custom_field_type: CustomFieldType;
  default_custom_field?: DefaultCustomField;
  event_custom_field_options?: CustomFieldOption[];
  formControlName?: string;

  constructor(object: any) {
    (<any>Object).assign(this, object);
    this.setControlName()
  }
  
  setControlName() {
    let controlName = Utils.removeAccents(this.name.toLowerCase())
    controlName = controlName.replace(/ /g, '_');
    this.formControlName = controlName;
  }
}

export enum CustomFieldTypeForm {
  BASIC = 'basic',
  ADDITIONAL = 'additional'
}

export enum CustomFieldSubscriptionType {
  ATHLETES = 'athletes',
  VOLUNTEERS = 'volunteers',
  SPECTATORS = 'spectators'
}
