<div mat-dialog-content class="dialog-container">
  <!-- <div class="modal-background" (click)="dialogRef.close()"></div> -->
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>
        {{
          "MODAL_CONFIGURATION_SUB_DIVISION.CREATE.TITLE"
            | translate
            | uppercase
        }}
      </h1>
      <span class="close" (click)="dialogRef.close(null)">
        <img src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>

    <div fxLayout="row" class="mt-16 mb-32">
      <description-section
        title="{{ 'MODAL_CONFIGURATION_SUB_DIVISION.INFO.TITLE' | translate }}"
        text="{{
          'MODAL_CONFIGURATION_SUB_DIVISION.INFO.DESCRIPTION' | translate
        }}"
      >
        <mat-icon class="ic-info mr-8" svgIcon="ic-cat-info"></mat-icon>
      </description-section>
    </div>
    <form
      [formGroup]="subDivisionForm"
      name="subDivisionForm"
      autocomplete="off"
      novalidate
    >
      <h4 class="my-0">
        {{ "MODAL_CONFIGURATION_SUB_DIVISION.FORM.CATEGORY" | translate }}
      </h4>
      <div fxLayout="column" fxLayoutAlign="start start" class="pt-16 pb-16">
        <div formArrayName="competitions">
          <div
            *ngFor="
              let item of competitionsCheckbox;
              let i = index
            "
            fxLayout="column"
          >
            <mat-checkbox [formControlName]="i" class="mr-32">{{
              competitionsCheckbox[i].name
            }}</mat-checkbox>
          </div>
        </div>
      </div>
    </form>

    <div class="mt-16 mb-8 text-red" *ngIf="requestErrors">
      {{ requestErrors }}
    </div>

    <div class="mb-0 mt-8" fxLayoutAlign="end center">
      <btn type="grayscale" (click)="dialogRef.close(null)" class="mr-16">{{
        "MODAL_CONFIGURATION_SUB_DIVISION.FORM.CANCEL" | translate | uppercase
      }}</btn>
      <btn
        [btnLoading]="true"
        [loading]="isLoading"
        [buttonDisabled]="subDivisionForm.invalid"
        (click)="saveSubDivisionConfiguration()"
        >{{
          "MODAL_CONFIGURATION_SUB_DIVISION.FORM.UPDATE" | translate | uppercase
        }}</btn
      >
    </div>
  </div>
</div>
