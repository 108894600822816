import { SharedModule } from './../../modules/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabBarComponent } from './tab-bar.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    TabBarComponent
  ],
  exports: [
    TabBarComponent
  ],
  providers: [
  ]
})
export class TabBarModule { }
