import { Errors } from './../../../../../core/model/errors';
import { ResultUserDashboard } from './../../../../../core/model/user-dashboard/result';
import { WodUserDashboard } from './../../../../../core/model/user-dashboard/wod';
import { ResultService } from './../../../../../core/services/result/result.service';
import { WodService } from './../../../../../core/services/wod/wod.service';
import { config } from './../../../../../core/config/config';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { BreadcrumbsService } from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthGuard } from '../../../../../core/guards/auth.guard';
import { EventGuard } from '../../../../../core/guards/event.guard';
import { MatDialog, MatIconRegistry } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from '../../../../../core/components/toastr_/public_api';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { TabBarComponent } from '../../../../../core/components/tab-bar/tab-bar.component';
import { Utils } from '../../../../../core/utils';
import { TableConfig } from '../../../../../core/components/table-x/table-x.component';
import { Wod } from '../../../../../core/model/wod';
import { Event } from '../../../../../core/model/event';
import { ResultStatus } from '../../../../../core/model/result';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-results',
  templateUrl: './user-results.component.html',
  styleUrls: ['./user-results.component.scss']
})
export class UserResultsComponent implements OnInit, OnDestroy {

  @ViewChild('tabbar', { read: TabBarComponent }) tabBar: TabBarComponent;
  private subscriptions = new Subscription();
  // Translate 
  translate: any;
  resultStatus = ResultStatus;

  // Championship
  championshipId: number;
  championship: any;
  competition: any;
  public event: Event = null;

  tabsFilter: string[] = [];
  wods: WodUserDashboard[] = [];

  tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
  };

  userMe: any;

  patterns = {
    '0': { pattern: new RegExp(/[0-2]/) },
    '1': { pattern: new RegExp(/[0-9]/) },
    '2': { pattern: new RegExp(/[0-5]/) }
  };

  isLoading = false;

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private wodService: WodService,
    private resultService: ResultService
  ) { 
    this.translationLoader.loadTranslations(english, portugues);

    this.subscriptions.add(this.translateService.get('RESULT').subscribe((res: any) => {
      this.translate = res;
    }));

    this.userMe = this.authGuard.getMe().user;

    this.subscriptions.add(this.activatedRoute.params.subscribe(
      params => {
        // console.log(params)
        this.championshipId = params.championship_id;

        this.event = new Event().deserialize(this.eventGuard.getActiveEvent().event);
        this.championship = this.eventGuard.getChampionshipPerId(this.championshipId);
        this.competition = this.championship.competition;

        if (this.translate) {
          this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE}`);

          this.breadcrumbsService.replaceAll([{
            text: this.translate.TITLE,
            route: `user-dashboard/championship/${this.championshipId}/results`
          }]);
        }
      }
    )); //end router
    this.iconRegistry.addSvgIcon(
      'ic-info',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/common/ic-info.svg')
    );
  }

  ngOnInit() {
  
    // Get Wods
    this.getWodsInChampionship();
    this.subscriptions.add(this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.wods = [];
          this.getWodsInChampionship();
        }
      }));
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  //  Get Wods
  getWodsInChampionship(): any {
    this.isLoading = true;
    this.subscriptions.add(
      this.wodService.getWodsWithCompetitionId(this.competition.id)
      .subscribe(
        result => {
          console.log('wods', result);
          // this.wods = result
          this.getUserResults(result);
        }, err => {
          // console.log("err", err);
          this.isLoading = false;
        }
      )
    ); 
  }

  // Get User results
  getUserResults(wods: any){
    if (this.championship.competitor_id) {
      for (const wodData of wods) {
        wodData.goal = wodData.goal === '' || wodData.goal === null ? null : wodData.goal;
      } 
      const w = wods;
      this.subscriptions.add(this.resultService.getResultsByCompetitor(this.championship.competitor_id)
        .subscribe(
          results => {  
            // Add Results in wods
            if (results.length > 0) {
              results.forEach(result => {
                const wod: WodUserDashboard[] = w.filter(wodFilter => wodFilter.id === result.wod_id);
                wod[0].result = result;
                wod[0].resultEdit = new ResultUserDashboard().deserialize(result);
                wod[0].editResult = false; 
              });
            }
            console.log(results);
  
            this.wods = w;
  
            this.isLoading = false;
  
          }, err => {
            this.isLoading = false;
          }
        ));
    } else {
      this.wods = wods;
      this.isLoading = false;
    }
  }

  cancelUserResult(wod: WodUserDashboard) {
    wod.editResult = !wod.editResult;
    this.updateEditToResult(wod, false);
  }

  updateEditToResult(wod: WodUserDashboard, editToResult: boolean = true) {
    if (editToResult) {
      wod.result.result = wod.resultEdit.result;
      wod.result.has_cap = wod.resultEdit.has_cap;
      wod.result.tiebreakresult = wod.resultEdit.tiebreakresult;
      wod.result.video = wod.resultEdit.video;
      wod.result.status = wod.resultEdit.status;
      wod.result.error = wod.resultEdit.error;
    } else {
      wod.resultEdit.result = wod.result.result;
      wod.resultEdit.has_cap = wod.result.has_cap;
      wod.resultEdit.tiebreakresult = wod.result.tiebreakresult;
      wod.resultEdit.video = wod.result.video;
      wod.resultEdit.status = wod.result.status;
      wod.resultEdit.error = wod.result.error;
    }
  }
  
  private isValidWod(wod: WodUserDashboard): boolean {
    wod.resultEdit.error = '';
    if (wod.wod_type_id === 2 && wod.goal !== null) {
      if (wod.resultEdit && wod.resultEdit.has_cap !== false && wod.resultEdit.has_cap !== true) {
        wod.resultEdit.error = this.translate.VALIDATIONS.REQUIRED_CHECKBOX;
        return false;
      }
    }

    if (wod.resultEdit.result === null || wod.resultEdit.result === '') {
      wod.resultEdit.error = this.translate.VALIDATIONS.RESULT_REQUIRED;
      wod.isLoading = false;
      return false;
    }

    if (this.championship.online && (wod.resultEdit.video === null || wod.resultEdit.video === '')) {
      wod.resultEdit.error = this.translate.VALIDATIONS.VIDEO_REQUIRED;
      return false;
    }
    return true;
  }

  saveUserResult(wod: WodUserDashboard) {
    if (!this.isValidWod(wod)) {
      return;
    }
    wod.isLoading = true;

    if (wod.tiebreak_type_id !== null && (wod.resultEdit.tiebreakresult === null || wod.resultEdit.tiebreakresult.length === 0)) {
      wod.resultEdit.tiebreakresult = '0';
      if (wod.tiebreak_type_id === 2) {
        wod.resultEdit.tiebreakresult = '00:00';  
      }
    }
    let result: any;

    result = {
      // result: (this.wod.unit_type_id === 1) ? moment.duration(value).asSeconds() : value,
      result: wod.resultEdit.result,
      tiebreakresult: wod.resultEdit.tiebreakresult,
      video: wod.resultEdit.video,
      has_cap: wod.resultEdit.has_cap,
      wod_id: wod.id,
      championship_id: this.championship.id,
      competition_id: this.competition.id,
      competitor_id: this.championship.competitor_id
    };

    if (wod.resultEdit.tiebreakresult) {
      result['tiebreakresult'] = wod.resultEdit.tiebreakresult;
    }

    this.subscriptions.add(this.resultService.saveOrUpdateResultByCompetitor(wod, result, this.competition.id, this.championship.id, this.championship.competitor_id)
      .subscribe(
        response => {
          this.updateEditToResult(wod);
          wod.result.id = response.id;
          wod.resultEdit.id = response.id;
          wod.result.status = 0;
          wod.resultEdit.status = 0;
          wod.editResult = false;
          wod.isLoading = false;
        }, err => {
          console.log('err', err);
          const errors: Errors = new Errors().deserialize((err.error as any));
          wod.result.error = errors.getFullMessages();
          wod.isLoading = false;
        }
      ));
  }

  getUnitName(wod: WodUserDashboard, typeId: number, abbreviation: boolean = true, isTiebreak = false) {
    // If Has Cap
    if (wod.resultEdit !== null && wod.resultEdit.has_cap && typeId === 2 && !isTiebreak) {
      return Utils.geTypeName(1, abbreviation);
    }

    if (typeId === 2) { 
      return 'mm:ss';
    }
    return Utils.geTypeName(typeId, abbreviation);
  }

  onTimeCapChange(wod: WodUserDashboard, hasCap: boolean){
    wod.resultEdit.result = '';
  }

  getValueResult(result: ResultUserDashboard, wod: WodUserDashboard) {
    if (result) {
      if (wod.wod_type_id === 2 && result.has_cap) {
        return (+result.result > 0) ? `CAP+${(wod.goal - +result.result)}` : 'CAP';
      } else {
        return result.result;
      }
    }

    return '';
  }

  // Get Result Status
  getResultStatus(result: any, isType: boolean): string {
    
    if (isType) {
      if (result.status === ResultStatus.APPROVED) {
        return 'success';
      } else if (result.status === ResultStatus.WAITING) {
        return 'normal';
      } else if (result.status === ResultStatus.REFUSED) {
        return 'danger';
      }
    } else {
      if (result.status === ResultStatus.APPROVED) {
        return this.translate.STATUS.APPROVED.toUpperCase();
      } else if (result.status ===  ResultStatus.WAITING) {
        return this.translate.STATUS.WAITING.toUpperCase();
      } else if (result.status === ResultStatus.REFUSED) {
        return this.translate.STATUS.REPROVE.toUpperCase();
      }
    }

    return '';
  }

  getVideoUrl(wod: Wod) 
  {
    if (wod.video_url) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(Utils.getUrlToEmbedVideo(wod.video_url));
    }
    return null;
  }

  // Open Vídeo
  openVideo(url: string) {
    window.open(url, '_blank');
  }

}
