import { AuthGuard } from './../../guards/auth.guard';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BankAccountService {

  constructor(
    private http: HttpClient,
    private authGuard: AuthGuard
  ) { }

  getAccounts(userId: number = null): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    if (!userId) {
      userId = this.authGuard.getMe().user.id
    }
    
    return this.http.get(`admin/userBankAccount?user_id=${userId}`, { responseType })
      .map((response: Response) => {
        return response
      })
  }

  create(parameters: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.post(`admin/userBankAccount`, parameters, { responseType })
      .map((response: Response) => {
        return response
      })
  }
}
