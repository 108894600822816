export const locale = {
  lang: 'en-US',
  data: {
    'MYPROFILE': {
      'TITLE': 'Perfil',
      'DESCRIPTION': 'Lorem ipsum dolor sit amet, vis suas mutat graece cu. In mea mazim periculis, sed porro pertinacia deterruisset ut, sea omnium facilisi necessitatibus at. Detracto.',
      'BASIC_INFO': 'Informações básicas',
      'AGE': 'Idade',
      'NAME': 'Nome',
      'LAST_NAME': 'Sobrenome',
      'USERNAME': 'Username',
      'EMAIL': 'E-mail',
      'PASSWORD': 'Senha',
      'CONFIRM_PASSWORD': 'Confirmar senha',
      'GENDER': 'Sexo',
      'GENDER_MALE': 'Masculino',
      'GENDER_FEMALE': 'Feminino',
      'BIRTHDAY': 'Data de nascimento',
      'BOX': 'Box',
      'CITY': 'Local de residência',
      'ACCEPT': 'Aceito os',
      'TERMS': 'termos de uso',
      'NAME_REQUIRED': 'Nome é obrigatório',
      'LAST_NAME_REQUIRED': 'Sobrenome é obrigatório',
      'USERNAME_REQUIRED': 'Username é obrigatório',
      'EMAIL_REQUIRED': 'E-mail é obrigatório',
      'EMAIL_INVALID': 'E-mail inválido',
      'PASSWORD_REQUIRED': 'Senha obrigatória',
      'PASSWORD_MINLENGHT': 'A senha deve conter pelo menos 6 caracters',
      'CONFIRM_PASSWORD_REQUIRED': 'Confirmação de senha obrigatória',
      'CONFIRM_PASSWORD_INVALID': 'As senhas não são iguais',
      'GENDER_REQUIRED': 'Sexo é obrigatório',
      'BIRTHDAY_REQUIRED': 'Data de nascimento é obrigatório',
      'BIRTHDAY_INVALID': 'Data inválida',
      'CITY_REQUIRED': 'A cidade é obrigatório',
      'BOX_EMPTY': 'Caso não tenha box deixar o campo vazio',
      'REGISTER': 'CADASTRAR',
      'BACK': 'VOLTAR',
      'DATE_FORMAT': 'DD/MM/YYYY',
      'MANAGER_EVENTS':{
        'TITLE': 'Gerenciar eventos',
        'DESCRIPTION': 'Lista dos eventos que você pode gerenciar.',
      },
      'MY_EVENTS': {
        'TITLE': 'Campeonatos',
        'DESCRIPTION': 'Lista de campeonatos que você já participou, veja os resultados, wods e leaderboard.',
      },
      'SEE_MORE': 'VER MAIS',
      'TABLE_NAME': 'Nome',
      'TABLE_DATA': 'Data',
      'TABLE_STATUS': 'Status',
      'STATUS': {
        'ACTIVE': 'Ativo',
        'PAST': 'Passado',
        'NOT_PUBLISHED': 'Não Publicado'
      },
      'TABLE_RANKING': 'Ranking',
      'TABLE_YEAR': 'Ano',
      'TOASTR': {
        'PROFILE': {
          'SUCCESS': {
            'TITLE': 'Perfil atualizado com sucesso!',
            'MESSAGE': 'Seu perfil foi atualizado.'
          },
          'ERROR': {
            'TITLE': 'Erro ao atualizar o perfil!',
          }
        }
      },
      'DIALOG': {
        'ALTER_EVENTS': {
          'TITLE': 'Você deseja atualizar suas informações nos eventos?',
          'DESCRIPTION': 'Você está atualizando informações pessoas, você deseja alterar essas informações nos eventos que ainda não acontecerão?',
          'DONT_ALTER': 'Não alterar',
          'CONFIRM': 'Alterar nos eventos'
        }
      },
      'TABS' : {
        'LIVE': 'Ao vivo/futuros',
        'PAST': 'Passado'
      },
      'SEARCH': 'Procure pelo nome do evento',
      'LIST_EVENT': {
        'TITLE1': 'Gerenciar eventos',
        'TITLE2': 'Campeonatos'
      }
    }
  }
};

