import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { HeatsTvComponent } from './heats-tv.component';
import { FuseMainModule } from '../../../../main.module';

const routes = [
  {
    path: 'heats-tv',
    component: HeatsTvComponent
  }
];

@NgModule({
  declarations: [
    HeatsTvComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    FuseMainModule
  ],
  exports: [
    HeatsTvComponent
  ],
  providers: []
})
export class HeatsTvModule {
}

