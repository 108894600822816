<header matDialogTitle [ngClass]="type" *ngIf="iconPath || headerDialog" [ngStyle]="{ 'background-color': backgroundHeaderColor, 'color': textHeaderColor }">
  <img src="{{iconPath}}" *ngIf="iconPath" />
</header>
<div mat-dialog-content class="p-24 dialog-container">
  <h1 class="m-0 mb-4">{{titleDialog}}</h1>
  <span [innerHTML]="dialogContent"></span><br>
  <btn class="mt-24" (click)="downloadBoleto()" *ngIf="subscriptionPayment && subscriptionPayment.isBoleto">{{ translate.SEE_BOLETO | uppercase }}</btn>
</div>
<div mat-dialog-actions class="mb-0" fxLayoutAlign="end center">
  <btn type="grayscale" class="mr-16" (click)="confirmAction()">{{cancelButton}}</btn>
  <btn class="mr-16" [loading]="confirmLoading" (click)="confirmAction()" *ngIf="dialogConfirm == true">{{actionButton}}</btn>
</div>