import { Component, OnInit } from '@angular/core';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {BreadcrumbsService} from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import {FuseTranslationLoaderService} from '../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthGuard} from '../../../../../core/guards/auth.guard';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {MatDialog, MatIconRegistry} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GooglePlacesService} from '../../../../../core/services/google-places.service';
import {EventsService} from '../../../../../core/services/event/events.service';
import {ChampionshipService} from '../../../../../core/services/championship/championship.service';
import {ToastrService} from '../../../../../core/components/toastr_/toastr/toastr.service';
import {BankAccountService} from '../../../../../core/services/bank-account/bank-account.service';
import {BanksService} from '../../../../../core/services/banks/banks.service';
import {EventConfigurationsService} from '../../../../../core/services/event-configurations/event-configurations.service';
import { locale as english } from './../i18n/en-US';
import { locale as portugues } from './../i18n/pt-BR';
import {Subscription} from 'rxjs';
import {Event} from '../../../../../core/model/event';
import {config} from '../../../../../core/config/config';
import {UserService} from '../../../../../core/services/user/user.service';
import {MatDialogRef} from '@angular/material';
import {FormErrorStateMatcher} from '../../../../../core/utils/form-error-state-matcher';
import { Utils } from '../../../../../core/utils';
import {CrossXDialogComponent} from '../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import {Errors} from '../../../../../core/model/errors';

@Component({
  selector: 'app-event-permissions',
  templateUrl: './event-permissions.component.html',
  styleUrls: ['./event-permissions.component.scss']
})
export class EventPermissionsComponent implements OnInit {

  private subscriptions: Subscription = new Subscription();

  // translate
  public translate: any;

  //Event
  public event: Event = null
  public users: any[] = [];
  public permissionForm: FormGroup;
  public matchers: any;
  public userSelect: any = null;
  timeOut

  public isLoading: boolean = false;

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private googlePlacesService: GooglePlacesService,
    public dialog: MatDialog,
    private eventService: EventsService,
    private championshipService: ChampionshipService,
    private toastr: ToastrService,
    private bankAccountService: BankAccountService,
    private banksService: BanksService,
    private eventConfigurationService: EventConfigurationsService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.registerOnTranslate();
    this.getEvent();
    this.configForm();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  public saveAdmin(): void {
    // validate all form fields
    if (!this.permissionForm.valid || this.permissionForm.value.user === null) {
      Utils.formValid(this.permissionForm)
      return
    }
    
    const parameters = {
      user_id: this.permissionForm.value.user.id
    }
    
    this.userSelect = this.permissionForm.value.user;
    
    console.log(parameters)

    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning'
    dialogRef.componentInstance.titleDialog = this.translate.DIALOG.CONFIRM_ALTER_ADMIN.TITLE
    this.translateService.get('PERMISSIONS.DIALOG.CONFIRM_ALTER_ADMIN.DESCRIPTION', { user_name: this.userSelect.fullname, user_email: this.userSelect.email, event_name: this.event.name }).subscribe((res: string) => {
      dialogRef.componentInstance.dialogContent = res
    });
    dialogRef.componentInstance.dialogConfirm = true
    dialogRef.componentInstance.cancelButton = this.translate.CANCEL.toUpperCase()
    dialogRef.componentInstance.actionButton = this.translate.SAVE.toUpperCase()
    dialogRef.componentInstance.object = this.userSelect
    dialogRef.componentInstance.confirmActionRequest = true
    dialogRef.componentInstance.dialogEvent.subscribe(result => {
      dialogRef.componentInstance.confirmLoading = true
      this.updateAdminInAPI(parameters, dialogRef)
      // console.log(result)
    })
  }

  private updateAdminInAPI(parameters: any, dialogRef: MatDialogRef<CrossXDialogComponent, any>):void {
    
    this.eventService.updateEvent(this.event.id, parameters)
      .subscribe(
        result => {
          console.log("result", result);
          this.eventGuard.setEvent(result)
          this.getEvent();

          this.toastr.show(
            this.translate.TOASTR.SUCCESS.DESCRIPTION,
            this.translate.TOASTR.SUCCESS.TITLE,
            null,
            'success'
          )
          
          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();

        }, err => {
          console.log("err", err);
          const errors: Errors = new Errors().deserialize((err.error as any))

          this.toastr.show(
            errors.getFullMessages(),
            this.translate.TOASTR.ERROR.TITLE,
            null,
            'error'
          )

          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();
        }
      )
    
  }
  
  
  private configForm(): void
  {
    this.permissionForm = this.formBuilder.group({
      user: [this.event.user, [Validators.required]],
    });

    this.matchers = {
      user: new FormErrorStateMatcher(),
    }
    
    // this.registerOnChangeInput();
  }

  private registerOnChangeInput(): void {
    const subscription = this.permissionForm
      .get('user')
      .valueChanges
      .subscribe((value: any) => {
        if (typeof value === "object") {
          console.log('value select', value)
        } 
        
      });
    this.subscriptions.add(subscription);
  }

  // search user
  public searchUser(term: string) {
    clearTimeout(this.timeOut)

    if (term.length > 2) {
      this.timeOut = setTimeout(() => {
        this.searchUserWithTerm(term)
      }, 1000);

    }
    else if (term.length === 0) {
      this.users = []
      // this.userId = null
    }
  }

  searchUserWithTerm(term: string) {
    clearTimeout(this.timeOut)
    const subscription = this.userService.searchUserByTerm(term)
      .subscribe(
        result => {
          console.log("result", result);
          this.users = result
        }, err => {
          console.log("err", err);
          this.users = []

        }
      )
    
    this.subscriptions.add(subscription)
  }
  
  public displayFnUser(user: any): string {
    console.log(user)
    this.userSelect = user;
    console.log(this.userSelect)
    return user ? `${user.firstname} ${user.lastname}`.toUpperCase() : user;
  }

  private registerOnTranslate(): void {

    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService
      .get('PERMISSIONS')
      .subscribe((response: Object) => {
        this.translate = response;

        this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE}`)

        this.breadcrumbsService.replaceAll([{
          text: this.translate.TITLE_SUBSCRIPTIONS,
          route: `/event/subscriptions`
        }])
      });

    this.subscriptions.add(subscription);

  }

  private getEvent(): void {
    this.event = new Event().deserialize(this.eventGuard.getActiveEvent().event)
  }
  
}
