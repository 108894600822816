export const locale = {
  lang: 'en-US',
  data: {
    'NEW_PARTICIPANTS': {
      'TITLE': 'Novo participante',
      'CATEGORY': {
        'TITLE': 'Categoria'
      },
      'SUB_DIVISION': 'Sub-categorias',
      'ATHLETE': {
        'TITLE': 'Informações do atleta',
        'DESCRIPTION': 'Adicione as informações pessoais do atleta que você deseja cadastrar.',
        'EMAIL': 'E-mail',
        'FIRSTNAME': 'Nome',
        'LASTNAME': 'Sobrenome',
        'LEADERBOARD_NAME': 'Nome no leaderboard',
        'BOX': 'Box',
        'TSHIRT': 'Tamanho da camiseta'
      },
      'VALIDATIONS': {
        'CATEGORY_REQUIRED': 'A categoria é obrigatória',
        'SUB_DIVISION_REQUIRED': 'A sub-categoria é obrigatória',
        'EMAIL_INVALID': 'Digite um e-mail válido',
        'FIRSTNAME_REQUIRED': 'O nome é obrigatório',
        'LASTNAME_REQUIRED': 'O Sobrenome é obrigatório',
        'LEADERBOARDNAME_REQUIRED': 'O nome no leaderboard é obrigatório'
      },
      'CANCEL': 'Cancelar',
      'SAVE': 'Salvar'
    }
  }
};
