import {EventGuard} from './../../../../../core/guards/event.guard';
import {ResultsComponent} from './results.component';
import {NgModule} from '@angular/core';
import {AuthGuard} from '../../../../../core/guards/auth.guard';
import {SharedModule} from '../../../../../core/modules/shared.module';
import {RouterModule} from '@angular/router';
import {NgxMaskModule} from 'ngx-mask';

const routes = [
  {
    path: 'championship/results/:championship_id',
    component: ResultsComponent,
    canActivate: [AuthGuard, EventGuard]
  }
];

@NgModule({
  declarations: [
    ResultsComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot()
  ],
  exports: [
    ResultsComponent
  ]
})
export class ResultsModule {
}
