import { Injectable } from '@angular/core';
import {AuthGuard} from '../../guards/auth.guard';

@Injectable({
  providedIn: 'root'
})
export class FreshChatService {

  constructor(
    private authGuard: AuthGuard
  ) { }

  private static initUser(user: any): void {
    // @ts-ignore
    window.fcWidget.user.setProperties({
      externalId: `cx_campeonatos_${user.id}`,
      firstName: user.firstname,
      lastName: user.lastname,
      email: user.email,
      phone: user.phone ? user.phone : "",
      phoneCountryCode: '+55',
      sistema: 'CrossX Campeonatos'
    });
  }

  public show(): void
  {
    // @ts-ignore
    if (window.fcWidget.isInitialized() === false) {
      this.initChat();
    }
    // @ts-ignore
    window.fcWidget.open();
  }

  public destroy(): void
  {
    // @ts-ignore
    if (window.fcWidget.isInitialized() === true) {
      // @ts-ignore
      window.fcWidget.destroy();
    }
  }

  public initChat(): void {
    // @ts-ignore
    if (window.fcWidget.isInitialized() === false) {
      const user: any = this.authGuard.getMe().user;
      // @ts-ignore
      window.fcWidget.init({
        token: '0aa7cb35-c2ca-45dd-ac9d-a38930d4a72c',
        host: 'https://wchat.freshchat.com',
        locale: 'pt-BR',
        config: {
          hideFAQ: true,
          headerProperty: {
            // If you have multiple sites you can use the appName and appLogo to overwrite the values.
            appName: 'Suporte',
            appLogo: 'https://app-crossx-nvi.s3.amazonaws.com/CrossX_icon_blue.jpg',
            backgroundColor: '#4284C6',
            foregroundColor: '#FFFFFF',
            // backgroundImage: 'https://wchat.freshchat.com/assets/images/texture_background_1-bdc7191884a15871ed640bcb0635e7e7.png'
            hideChatButton: true
          },
          content: {
            placeholders: {
              search_field: 'Buscar',
              reply_field: 'Responder',
              csat_reply: 'Adicionar seus comentários'
            },
            actions: {
              csat_yes: 'Sim',
              csat_no: 'Não',
              push_notify_yes: 'Sim',
              push_notify_no: 'Não',
              tab_faq: 'Perguntas e Respostas',
              tab_chat: 'Chat',
              csat_submit: 'Enviar'
            },
            headers: {
              chat: 'Precisa de ajuda?',
              chat_help: 'Entre em contato se tiver alguma dúvida',
              faq: 'Perguntas frequentes',
              faq_help: 'Busque em nosso FAQ',
              faq_not_available: 'Nenhum artigo encontrado',
              faq_search_not_available: 'Nenhum artigo foi encontrado para {{query}}',
              faq_useful: 'Esse artigo foi útil?',
              faq_thankyou: 'Obrigado pelo seu feedback!',
              faq_message_us: 'Envie sua mensagem',
              push_notification: 'Não perca nenhuma mensagem, autorize nossas notificações',
              csat_question: 'Conseguimos tirar suas dúvidas?',
              csat_yes_question: 'Como você avalia nosso atendimento?',
              csat_no_question: 'Como podemos melhorar nosso atendimento?',
              csat_thankyou: 'Obrigado por responder',
              csat_rate_here: 'Avalie seu atendimento',
              channel_response: {
                offline: 'No momento não estamos online. Por favor, Deixe sua mensagem.',
                online: {
                  minutes: {
                    // one: "Estamos respondendo em {!time!} minutos",
                    // more: "Geralmente respondemos em {!time!} minutos"
                    one: 'Estamos respondendo em poucos minutos',
                    more: 'Geralmente respondemos em poucos minutos'
                  },
                  hours: {
                    // one: "Estamos respondendo em menos de uma hora",
                    // more: "Geralmente respondemos em {!time!} horas"
                    one: 'Estamos respondendo em poucos minutos',
                    more: 'Geralmente respondemos em poucos minutos'
                  }
                }
              }
            },

          }
        },
        onInit: FreshChatService.initUser(user),
        tags: ['crossx', 'crossx_campeonatos'],
        externalId: `cx_campeonatos_${user.id}`,
        firstName: user.firstname,
        lastName: user.lastname ? user.lastname : '',
        email: user.email,
        phone: user.phone ? user.phone : '',
        phoneCountryCode: '+55',
        sistema: 'CrossX Campeonatos',
        faqTags: {
          // Array of Tags
          tags: ['crossx'],
          // For articles, the below value should be article.
          // For article category, the below value should be category.
          filterType: 'category' // Or filterType: 'article'
        }
      });
    }
  }
}
