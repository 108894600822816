import { MatDialogRef } from '@angular/material';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Utils} from '../../utils';
import { locale as english } from './../../../main/content/pages/user-dashboard/user-checkin/i18n/en-US';
import { locale as portugues } from './../../../main/content/pages/user-dashboard/user-checkin/i18n/pt-BR';
import {FuseTranslationLoaderService} from '../../services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {dateValidator} from '../../utils/custom-validators';

@Component({
  selector: 'cross-x-dialog',
  templateUrl: './cross-x-dialog.component.html',
  styleUrls: ['./cross-x-dialog.component.scss']
})
export class CrossXDialogComponent implements OnInit {
  private subscriptions = new Subscription();
  public confirmMessage: string;
  public type: string;

  //header
  public headerDialog: string = null;
  public iconPath: string;
  public textHeaderColor: string;
  public backgroundHeaderColor: string;
  withdrawPackageForm: FormGroup;

  //content
  public titleDialog: string;
  public dialogContent: string;
  public dialogConfirm = false;
  public cancelButton = 'CANCELAR';
  public actionButton = 'EXCLUIR';
  public object: any;
  translate: any;

  // Loading
  public confirmLoading = false;
  public confirmActionRequest = false;
  public withdraw = false;
  public action: any;

  // Event
  public dialogEvent = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<CrossXDialogComponent>,   
    private readonly translationLoader: FuseTranslationLoaderService,
    private readonly translateService: TranslateService,
    private formBuilder: FormBuilder
  ) {
      this.registerOnTranslate();
  }
  
  ngOnInit() {
      this.withdrawPackageForm = this.formBuilder.group({
        who_will_get_package: ''
      });

      if (this.type == 'warning') {
        this.headerDialog = this.headerDialog === null ? 'Cuidado!' : this.headerDialog;
        this.iconPath = './assets/icons/dialog/ic-dialog-warning.svg';
      }
      else if (this.type == 'success') {
        this.headerDialog = this.headerDialog === null ? 'Iupppiiiii!' : this.headerDialog;
        this.iconPath = './assets/icons/dialog/ic-dialog-success.svg';
      }
      else if (this.type == 'error') {
        this.headerDialog = this.headerDialog === null ? 'Opsss!' : this.headerDialog;
        this.iconPath = './assets/icons/dialog/ic-dialog-error.svg';
      }

  }

  private registerOnTranslate(): void {
    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService
      .get('USER_CHECKIN')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);
  }

  confirmAction() {
    if (this.confirmActionRequest && this.withdraw) {
      if (!Utils.formsVerifyIsValid([this.withdrawPackageForm])) {
        Utils.formValid(this.withdrawPackageForm);
        return;
      }
      this.dialogEvent.emit({
        type: 'confirm',
        object: this.object,
        who_will_get_package: this.withdrawPackageForm.value.who_will_get_package.toUpperCase()
      });  
    } else if (this.confirmActionRequest) {
      this.dialogEvent.emit({
        type: 'confirm',
        object: this.object
      });  
    }
    else {
      this.dialogRef.close(this.object);
    }
  }

}
