export const locale = {
  lang: 'en-US',
  data: {
    'MODAL_CREATE_SUB_DIVISION': {
      'CREATE': {
        'TITLE': 'Cadastrar sub-categoria',
      },
      'UPDATE': {
        'TITLE': 'Alterar sub-categoria',
      },
      'FORM': {
        'NAME': 'Nome',
        'CREATE': 'Salvar',
        'UPDATE': 'Salvar',
        'CANCEL': 'Cancelar'
      },
      'VALIDATIONS': {
        'NAME_REQUIRED': 'O nome é obrigatório',
      },
    }
  }
};
  
