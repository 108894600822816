<div id="login" fxLayout="column" fusePerfectScrollbar>
    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="login-form" *fuseIfOnDom [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }">
            <div class="logo">
                <img src="assets/images/logos/brand-crossx.svg" />
            </div>

            <h1>{{ "LOGIN.TITLE" | translate }}</h1>

            <form name="loginForm" [formGroup]="loginForm" novalidate>
                <mat-form-field>
                    <input matInput placeholder="E-mail" formControlName="email" required />
                    <mat-error *ngIf="loginFormErrors.email.required">
                        {{ "LOGIN.EMAIL_REQUIRED" | translate }}
                    </mat-error>
                    <mat-error *ngIf="loginFormErrors.email.invalid && !loginFormErrors.email.required">
                        {{ "LOGIN.EMAIL_INVALID" | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="mt-8">
                    <input matInput type="password" placeholder="{{ 'LOGIN.PASSWORD' | translate }}" formControlName="password" required />
                    <mat-error *ngIf="loginFormErrors.password.required">
                        {{ "LOGIN.PASSWORD_REQUIRED" | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-error class="mb-8" *ngIf="loginError">
                    {{ "LOGIN.LOGIN_ERROR" | translate }}
                </mat-error>

                <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
                    <a class="forgot-password" [routerLink]="'/forgot-password'" class="pt-4 mt-4" [ngClass.gt-xs]="'mb-8'">
                        {{ "LOGIN.FORGOT_PASSWORD" | translate }}
                    </a>

                    <btn
                        [btnLoading]="true"
                        fxFlex.xs="100"
                        fxLayoutAlign="end center"
                        (click)="logIn()"
                        class="submit-button m-0"
                        [buttonDisabled]="loginForm.invalid"
                        [loading]="loadingButton"
                        >{{ "LOGIN.LOGIN" | translate }}</btn
                    >
                </div>
            </form>

            <!-- <div style="text-align: center" class="mt-16 register-button">
                <btn color="#ffffff" backgroundColor="#2553a8" buttonWidth="100%" icon="content/ic-facebook.svg" (click)="logInWithFacebook()" [loading]="loadingButtonFacebook" [ngStyle]="{ 'width': loadingButtonFacebook ? '35px' : '100%'} " class="register-button">{{'LOGIN.FACEBOOK_BUTTON' | translate}}</btn>
            </div> -->
            <btn color="#004CFF" backgroundColor="#ffffff" buttonWidth="100%" [routerLink]="['/register']" class="mt-16 register-button">{{
                "LOGIN.REGISTER" | translate
            }}</btn>

          <mat-toolbar>
          <span fxFlex fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="center">
               © {{ currentYear }} Hyperlocal • Todos os direitos reservados.
          </span>
          </mat-toolbar>
          
        </div>
    </div>
</div>
