import { Wod } from './wod.model';
import {Competitor} from '../../../../../../core/model/competitor';

export class HeatCompetitor {
  id: string | number;
  competitor_id: string | number;
  competitor: Competitor;
  lane: number;
  wod_id: Array<Wod>;
  type: string;
  tableName: string;
}
