import { Component, OnInit, Input } from '@angular/core';
import { Breadcrumb } from './breadcrumb';

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

    @Input() breadcrumbs: Breadcrumb[]

    constructor() { }

    ngOnInit() {

    }

}
