import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatIconRegistry } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, FormControl, FormArray, ValidatorFn } from '@angular/forms';
import { CompetitionService } from '../../../../../../core/services/competition/competition.service';
import { EventGuard } from '../../../../../../core/guards/event.guard';
import { LotService } from '../../../../../../core/services/lot/lot.service';

import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { Competition } from '../../../../../../core/model/competition';
import { Utils } from '../../../../../../core/utils';
import { Errors } from '../../../../../../core/model/errors';

@Component({
  selector: 'app-modal-copy-lot',
  templateUrl: './modal-copy-lot.component.html',
  styleUrls: ['./modal-copy-lot.component.scss']
})
export class ModalCopyLotComponent implements OnInit {

  public competition: Competition
  public allCompetitions: Competition[]

  // Translate
  translate: any

  // Form
  lotForm: FormGroup;
  matchers: any
  competitionsCheckbox: any[] = []

  requestErrors: string

  isLoading: boolean = false
  
  constructor(
    public dialogRef: MatDialogRef<ModalCopyLotComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private formBuilder: FormBuilder,
    private competitionService: CompetitionService,
    private eventGuard: EventGuard,
    private lotService: LotService
  ) { 
    this.translationLoader.loadTranslations(english, portugues);

    this.translateService.get("MODAL_COPY_LOT").subscribe((res: any) => {
      this.translate = res
    });

    // this.event = this.eventGuard.getActiveEvent().event

    //Icon Info
    iconRegistry.addSvgIcon(
      'ic-cat-info',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/category/ic-cat-info.svg')
    );
  }

  ngOnInit() {
    
    this.allCompetitions.forEach(competition => {
      if (competition.id !== this.competition.id) {
        this.competitionsCheckbox.push({
          id: competition.id,
          name: competition.name
        })
      }
    });

    const controlsCompetitions = this.competitionsCheckbox.map(c => new FormControl(false));
    const controlsLots = this.competition.lots.map(c => new FormControl(false));
    this.lotForm = this.formBuilder.group({
      competitions: new FormArray(controlsCompetitions, Utils.minSelectedCheckboxes(1)),
      lots: new FormArray(controlsLots, Utils.minSelectedCheckboxes(1))
    })
  }

  copyLots() {
    // validate all form fields
    if (!this.lotForm.valid) {
      Utils.formValid(this.lotForm)
      return
    }
    
    const selectedCompetitions = this.lotForm.value.competitions
      .map((v, i) => v ? this.competitionsCheckbox[i].id : null)
      .filter(v => v !== null);
    
    const selectedLots = this.lotForm.value.lots
      .map((v, i) => v ? this.competition.lots[i].id : null)
      .filter(v => v !== null);

    console.log(selectedCompetitions, selectedLots);

    const parameters = {
      competitions_ids: selectedCompetitions,
      lots_ids: selectedLots
    }

    this.isLoading = true
    this.lotService.copyLots(parameters)
      .subscribe(
        result => {
          console.log("result", result);
          this.isLoading = false
          // this.lot = result
          this.dialogRef.close({ competitions: result, isEdit: false })
        }, err => {
          console.log("err", err);
          const errors: Errors = new Errors().deserialize((err.error as any))
          this.requestErrors = errors.getFullMessages()

          this.isLoading = false
        }
      )
  }

  formLots() { return <FormArray>this.lotForm.get('lots'); }
  formCompetition() { return <FormArray>this.lotForm.get('competitions'); }
}



  