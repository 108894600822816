import {ResultUserDashboard} from './result';
import {SafeUrl} from '@angular/platform-browser';

export class WodUserDashboard {
  id: number;
  name: string;
  description?: string;
  video_url?: string;
  videoUrlSafe?: SafeUrl;
  opens_at?: Date;
  closes_at?: Date;
  wod_type_id: number;
  tiebreak_type_id?: number;
  unit_type_id: number;
  points_id?: number;
  goal?: number;
  goal_time?: string;
  status?: string;
  competitions?: any[];
  result?: ResultUserDashboard | null = null;
  resultEdit?: ResultUserDashboard | null = null;
  created_at: Date;
  updated_at: Date;
  isLoading = false;
  editResult = true;

  constructor() {
  }

  deserialize(input: any) {
    (<any>Object).assign(this, input);
    // this.result = new ResultUserDashboard()
    this.result = (input.result) ? new ResultUserDashboard().deserialize(input.result) : new ResultUserDashboard();
    this.resultEdit = (input.result) ? new ResultUserDashboard().deserialize(input.result) : new ResultUserDashboard();

    return this;
  }
}
