export const locale = {
  lang: 'en-US',
  data: {
    'PAYMENT': {
      'CARD_SUCCESS_TITLE': 'Pagamento realizado com sucesso!',
      'CARD_SUCCESS_DESCRIPTION': 'Inscrição realizada com sucesso!',
      'BOLETO_SUCCESS_TITLE': 'Boleto gerado com sucesso!',
      'BOLETO_SUCCESS_DESCRIPTION': 'A confirmação de sua inscrição pode levar até 3 dias úteis após o pagamento.',
      'SEE_BOLETO': 'Ver boleto',
      'SUBSCRIPTION_SUCCESS_TITLE': 'Inscrição realizada com sucesso!',
      'SUBSCRIPTION_SUCCESS_DESCRITPION': 'Boa competição'
    }
  }
};
