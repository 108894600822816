<div mat-dialog-content class="dialog-container">
  <!-- <div class="modal-background" (click)="dialogRef.close()"></div> -->
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>{{ ((isEdit) ? translate.TITLE_EDIT : translate.TITLE_NEW) | uppercase }}</h1>
      <span class="close" (click)="dialogRef.close(null)">
        <img src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>

    <div fxLayout="row" class="mt-16 mb-32">
      <description-section title="{{ translate.TITLE_SECTION }}" text="{{ translate.DESCRIPTION_SECTION }}">
        <mat-icon class="ic-info mr-8" svgIcon="ic-cat-info"></mat-icon>
      </description-section>
    </div>

    <form [formGroup]="lotForm" name="lotForm" autocomplete="off" novalidate>
      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field fxFlex="100" fxFlex.xs="100" class="pr-8">
          <input matInput placeholder="{{ translate.FORM.NAME }}" formControlName="name" [errorStateMatcher]="matchers.name" uppercase required>
          <mat-error *ngIf="lotForm.controls.name.hasError('required')">
            {{ translate.VALIDATIONS.NAME_REQUIRED }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field fxFlex="200px" fxFlex.xs="100" class="pr-8">
          <input matInput placeholder="{{ translate.FORM.VALUE }}"
            formControlName="price" [errorStateMatcher]="matchers.price"
            currencyMask min="0.00" max="9999.99"
            required>
          <mat-error *ngIf="lotForm.controls.price.hasError('required')">
            {{ translate.VALIDATIONS.VALUE_REQUIRED }}
          </mat-error>
          <mat-error *ngIf="lotForm.controls.price.hasError('max')">
            {{ translate.VALIDATIONS.VALUE_MAX }}
          </mat-error>
        </mat-form-field>

        <mat-slide-toggle fxFlex="auto" formControlName="repassTaxFee">{{ translate.FORM.REPASS_TAX_FEE }}</mat-slide-toggle>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="repass_tax" *ngIf="lotForm.value.repassTaxFee">
        {{ totalValueUser }}
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="mt-16">
        <mat-slide-toggle fxFlex="auto" formControlName="settings">{{ translate.FORM.SETTINGS_OPTIONAL }}</mat-slide-toggle>
      </div>

      <div class="mt-16" *ngIf="lotForm.value.settings">

        <div *ngIf="!competition" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field fxFlex="200px" fxFlex.xs="100" class="pr-8">
            <input matInput type="number" placeholder="{{ translate.FORM.LIMIT }}" formControlName="limit">
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center pr-8">
          <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" class="mb-8 pr-8">
            <mat-form-field fxFlex="65" class="inputDate">
              <input matInput placeholder="{{ translate.FORM.DATE_START }}" formControlName="dateStart" [errorStateMatcher]="matchers.dateStart" [dropSpecialCharacters]="false" mask="00/00/0000">
              <mat-icon matSuffix>date_range</mat-icon>
              <!-- <mat-error *ngIf="lotForm.controls.dateStart.hasError('required_with_end')">
                obrigatório porra
              </mat-error> -->
            </mat-form-field>
          
            <select-time
              #pickerTimeStart
              fxFlex="35"
              class="pl-12"
              label="{{ translate.FORM.TIME_START }}"
              [value]="timeStart"
              (inputValue)="timeChange($event, 'start')"
              [required]="false"></select-time>
          </div>

          <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center" class="mb-8 pl-8">
            <mat-form-field fxFlex="65" class="inputDate">
              <input matInput placeholder="{{ translate.FORM.DATE_END }}" formControlName="dateEnd" [dropSpecialCharacters]="false"
                mask="00/00/0000">
              <mat-icon matSuffix>date_range</mat-icon>
            </mat-form-field>
          
            <select-time
              #pickerTimeEnd
              fxFlex="35"
              class="pl-12"
              label="{{ translate.FORM.TIME_END }}"
              [value]="timeEnd"
              (inputValue)="timeChange($event, 'end')"
              [required]="false"></select-time>
          </div>
        </div>

      </div>

    </form>

    <div class="mt-16 mb-8 text-red" *ngIf="requestErrors !== null">
      {{ requestErrors }}
    </div>

    <div class="mb-0 mt-8" fxLayoutAlign="end center">
      <btn type="grayscale" (click)="dialogRef.close(null)" class="mr-16">{{ translate.CANCEL | uppercase }}</btn>
      <btn [btnLoading]="true" [loading]="isLoading" [buttonDisabled]="lotForm.invalid" (click)="createOrUpdateLot()">{{ translate.SAVE | uppercase }}</btn>
    </div>
  </div>
</div>