import { MaterialModule } from './../../modules/material.module';
import { SharedModule } from './../../modules/shared.module';
import { BtnIconSmallComponent } from './btn-icon-small.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '../../../../../node_modules/@angular/material';
import { FlexLayoutModule } from '../../../../../node_modules/@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MaterialModule,
    FlexLayoutModule
  ],
  declarations: [
    BtnIconSmallComponent
  ],
  exports: [
    BtnIconSmallComponent,
    MaterialModule,
    FlexLayoutModule
  ],
  providers: [
  ]
})
export class BtnIconSmallModule { }
