<div class="page-layout blank pb-32 pt-32" (scroll)="onScroll($event)">
  <ng-container *ngIf="enableHeats()">
    <tab-bar
      [padding]="true"
      [items]="tabsFilter"
      [tabActive]="tabIndexActive"
      (tabEvent)="tabAction($event)"
    >
    </tab-bar>
  </ng-container>

  <div class="p-32" [ngStyle]="{'display': enableHeats() ? '' : 'none'}">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
      <div class="heats-agenda mb-32">
        <a
          (click)="setScreenActive('heats')"
          [ngClass]="{ 'active': screenActive === 'heats' }"
        >
          <div>
            <mat-icon svgIcon="ic-lanes"></mat-icon>
            <span>
          {{ "HEAT_COMPETITORS.SCREEN.HEATS" | translate }}
        </span>
          </div>
          <mat-icon>check_circle</mat-icon>
        </a>
        <a
          (click)="setScreenActive('schedule')"
          [ngClass]="{ 'active': screenActive === 'schedule' }"
          ngClass="ml-8 sub-division"
        >
          <div>
            <mat-icon svgIcon="ic-calendar"></mat-icon>
            <span>
          {{ "HEAT_COMPETITORS.HEATS_SCHEDULE.SCHEDULE" | translate }}
          </span>
          </div>
          <mat-icon>check_circle</mat-icon>
        </a>
      </div>
      <btn
        [matMenuTriggerFor]="optionsButton"
        [btnLoading]="true"
        [loading]='isLoadingExport'
        type="grayscale"
        iconAlign="left"
        icon="common/ic-settings.svg"
        class="mb-32"
      >
        {{ 'HEAT_COMPETITORS.OPTIONS' | translate }}
      </btn>
    </div>
    
    
    
    
    
    <app-generator-heats 
      #generatorHeats
      [hidden]="screenActive !== 'heats'"
      [heats]="heats"
      [heatsOld]="heatsOld"
      [typeActive]="typeActive"
      [isSticky]="isSticky"
      [btnMatFabConfig]="btnMatFabConfig"
      (calcHeatsEvent)="eventCalcData($event)"
      (heatsOldEvent)="generateHeatsOld($event)"
      (updateHeatEvent)="populateHeat($event)"
      (saveHeatEvent)="saveHeat()"
    ></app-generator-heats>
    <fuse-app-schedule-heats
      #scheduleHeats
      [hidden]="screenActive !== 'schedule'"
      [heats]="heats"
      [heatsOld]="heatsOld"
      [isLoadingSaveHeats]="isLoadingSaveHeats"
      [championship]="generatorHeats.championship"
      [dueDate]="generatorHeats.dueDate"
      [idsDelete]="generatorHeats.idsDelete"
      [isSticky]="isSticky"
      [btnMatFabConfig]="btnMatFabConfig"
      (calcHeatsEvent)="eventCalcData($event)"
      (heatsOldEvent)="generateHeatsOld($event)"
      (addNewHeatEvent)="addNewHeat()"
      (saveHeatEvent)="saveHeat()"
    ></fuse-app-schedule-heats>
  </div>

  <mat-menu #optionsButton="matMenu" class="bottom arrow-right" [overlapTrigger]="false" xPosition="before">
    <ng-template matMenuContent>
      <div class="submenu" fxLayout="column">
        <button mat-menu-item (click)="exportHeat()" class="line-bottom">
        <span>
          <img class="mx-4" src="./assets/icons/content/ic-export.svg"/>
          {{ 'HEAT_COMPETITORS.EXPORT' | translate }}
        </span>
        </button>
      </div>
    </ng-template>
  </mat-menu>
  
  <!-- Empty list -->
  <div class="p-32" fxLayout="column"  *ngIf="!enableHeats()">
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      class="emtpy-content card auto-width p-32"
    >
      <img src="./assets/icons/placeholder/ic-empty.svg" />
      <span class="mt-8 text-center">{{ "HEAT_COMPETITORS.EMPTY" | translate }}</span>
      <span class="mt-8 text-center">{{ "HEAT_COMPETITORS.EMPTY_DESCRIPTION" | translate }}</span>
    </div>
  </div>
</div>