import { Component, OnInit } from '@angular/core';

import { FuseConfigService } from '../../../../../core/services/config.service';
import {FuseTranslationLoaderService} from '../../../../../core/services/translation-loader.service';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';

@Component({
    selector   : 'fuse-error-500',
    templateUrl: './error-500.component.html',
    styleUrls  : ['./error-500.component.scss']
})
export class FuseError500Component implements OnInit
{
    constructor(
        private fuseConfig: FuseConfigService,
        private translationLoader: FuseTranslationLoaderService
    )
    {
        this.fuseConfig.setSettings({
            layout: {
                navigation: 'none',
                toolbar   : 'none',
                footer    : 'none'
            }
        });

      this.translationLoader.loadTranslations(english, portugues);
    }

    ngOnInit()
    {
    }
}
