<div fxFlex="100" fxLayout="column" fxLayoutAlign="start start" style="max-width: 100%;">
  <span class="label">{{ label }}{{ isRequired }}</span>
  <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-evenly start" class="selects-time">
    
    <mat-form-field fxFlex="45">
      <mat-select placeholder="" floatLabel="never" [value]="hourSelected"
                  (selectionChange)="hourChange($event)" [disabled]="readonly">
        <mat-option *ngFor="let hour of hours" [value]="hour">
          {{ hour }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <span class="pl-4 pr-4 separator" fxFlex="10" fxLayoutAlign="center start"> : </span>
    
    <mat-form-field fxFlex="45">
      <mat-select placeholder="" floatLabel="never" [value]="minuteSelected"
                  (selectionChange)="minuteChange($event)" [disabled]="readonly">
        <mat-option *ngFor="let minute of minutes" [value]="minute">
          {{ minute }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
