import { OnDestroy, Injectable } from "@angular/core";
import { Subject, Observable, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})

export class EventListService implements OnDestroy {
  private subscriptions = new Subscription();
  private _eventListObservable = new Subject<any>();

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {
  }

  eventListObservable(): Observable<any> {
    return this._eventListObservable.asObservable();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  fetchMyEvents(page = 1, search = '', type = 'live'): void {
    const baseUrl = [
      'admin',
      'me',
      'event'
    ];
    const queryParams = {
      'isAdmin': false,
      'search': search,
      'page': page,
      'type': type
    };

    const url = this.router.createUrlTree(baseUrl, {
        queryParams: queryParams
      }
    );
    const parsedUrl = url.toString().substring(1, url.toString().length);
    const subscription = this.httpClient
      .get(parsedUrl, {observe: 'response'})
      .subscribe(({ body, headers }) => {
          const result = {
            paginate: JSON.parse(headers.get('pagination')),
            list: body
          };
          this._eventListObservable.next(result);
        },
        () => {
          this._eventListObservable.next([]);
        });

    this.subscriptions.add(subscription);
  }

  fetchMyEventsCompetitor(page = 1, search = ''): void {
    const baseUrl = [
      'users',
      'me',
      'events'
    ];
    const queryParams = {
      'isAdmin': false,
      'search': search,
      'page': page,
    };

    const url = this.router.createUrlTree(baseUrl, {
        queryParams: queryParams
      }
    );
    const parsedUrl = url.toString().substring(1, url.toString().length);
    const subscription = this.httpClient
      .get(parsedUrl, {observe: 'response'})
      .subscribe(({ body, headers }) => {
        const result = {
          paginate: JSON.parse(headers.get('pagination')),
          list: body
        };
          this._eventListObservable.next(result);
        },
        () => {
          this._eventListObservable.next([]);
        });

    this.subscriptions.add(subscription);
  }
}
