<button class="mat-fab" (click)="clickButton()" fxLayoutAlign="center center">
  <img src="./assets/icons/{{icon}}" *ngIf="icon" [ngClass]="{'hide': loading}">
  <mat-icon 
    *ngIf="materialIcon"
    [ngStyle]="{'color': color ? color : 'rgba(255, 255, 255, 1)'}"
    [ngClass]="{'hide': loading}">{{ materialIcon }}</mat-icon>
  <div [ngClass]="{'loader': true, 'hide': !loading}">
    <svg class="circular" viewBox="25 25 50 50">
      <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="4" stroke-miterlimit="10" [ngStyle]="{ 'stroke': '#fff' }" />
    </svg>
  </div>
</button>