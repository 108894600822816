<div fxLayout="column">
  <!-- SINGLE TEXT OR NUMBER -->
  <mat-form-field *ngIf="getIsSingleTextOrNumber()">
    <input matInput #input [maxlength]="customField.max_count" type="{{ getTypeSingleTextOrNumber()}}" placeholder="{{ customField.name }}" formControlName="{{ customField.formControlName }}" autocomplete="off" [required]="customField.is_required">
    <mat-hint align="end" *ngIf="customField.max_count !== null">{{ input.value?.length || 0 }}/{{ customField.max_count }}</mat-hint>
    <mat-error *ngIf="form.get(customField.formControlName).hasError('required')">
      {{ 'CUSTOM_FIELD.REQUIRED' | translate:{custom_field_name: customField.name} }}
    </mat-error>
    <mat-error *ngIf="form.get(customField.formControlName).hasError('invalid')">
      {{ 'CUSTOM_FIELD.INVALID' | translate }}
    </mat-error>
    <mat-error *ngIf="form.get(customField.formControlName).hasError('maxlength')">
      {{ 'CUSTOM_FIELD.MAX_LENGTH' | translate:{ count:customField.max_count } }}
    </mat-error>
  </mat-form-field>
  <!-- END SINGLE TEXT OR NUMBER -->

  <!-- SELECT -->
  <mat-form-field *ngIf="getIsSelect()">
    <mat-select placeholder="{{ customField.name }}" formControlName="{{ customField.formControlName }}" [required]="customField.is_required">
      <mat-option *ngFor="let option of customField.event_custom_field_options" [value]="option">
        {{ option.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.get(customField.formControlName).hasError('required')">
      {{ 'CUSTOM_FIELD.REQUIRED' | translate:{custom_field_name: customField.name} }}
    </mat-error>
    <mat-error *ngIf="form.get(customField.formControlName).hasError('invalid')">
      {{ 'CUSTOM_FIELD.INVALID' | translate }}
    </mat-error>
  </mat-form-field>
  <!-- END SELECT -->
  
  <!-- CHECKBOX -->
  <mat-checkbox *ngIf="getIsCheckbox()" formControlName="{{ customField.formControlName }}">{{ customField.name }}{{customField.is_required ? '*' : ''}}</mat-checkbox>
  <!-- END CHECKBOX -->
  
  <!-- DATE -->
  <mat-form-field *ngIf="getIsDate()">
    <input matInput placeholder="{{ customField.name }}" mask="{{ maskDate }}" formControlName="{{ customField.formControlName }}" autocomplete="off" [required]="customField.is_required">
    <mat-icon matSuffix>date_range</mat-icon>
    <mat-error *ngIf="form.get(customField.formControlName).hasError('required')">
      {{ 'CUSTOM_FIELD.REQUIRED' | translate:{custom_field_name: customField.name} }}
    </mat-error>
    <mat-error *ngIf="form.get(customField.formControlName).hasError('invalid')">
      {{ 'CUSTOM_FIELD.DATE_INVALID' | translate }}
    </mat-error>
  </mat-form-field>
  <!-- END DATE -->
  
  <!-- DATETIME -->
  <mat-form-field *ngIf="getIsDatetime()">
    <input matInput placeholder="{{ customField.name }}" mask="{{ maskDatetime }}" formControlName="{{ customField.formControlName }}" autocomplete="off" [required]="customField.is_required">
    <mat-icon matSuffix>date_range</mat-icon>
    <mat-error *ngIf="form.get(customField.formControlName).hasError('required')">
      {{ 'CUSTOM_FIELD.REQUIRED' | translate:{custom_field_name: customField.name} }}
    </mat-error>
    <mat-error *ngIf="form.get(customField.formControlName).hasError('invlid')">
      {{ 'CUSTOM_FIELD.DATE_INVALID' | translate }}
    </mat-error>
  </mat-form-field>
  <!-- END DATETIME -->
  
  <!-- BOX -->
  <mat-form-field *ngIf="getIsBox()">
    <input matInput formControlName="{{ customField.formControlName }}" placeholder="{{ customField.name }}" [matAutocomplete]="autoBox" (keypress)="searchBox($event);" autocomplete="off" [required]="customField.is_required">
    <mat-autocomplete #autoBox="matAutocomplete" [displayWith]="displayFnBox">
      <mat-option *ngFor="let box of boxes" [value]="box">
        {{ box.name }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="form.get(customField.formControlName).hasError('required')">
      {{ 'CUSTOM_FIELD.REQUIRED' | translate:{custom_field_name: customField.name} }}
    </mat-error>
  </mat-form-field>
  <!-- END BOX -->

  <!-- CPF -->
  <mat-form-field *ngIf="getIsCPF()">
    <input matInput #input placeholder="{{ customField.name }}" mask="000.000.000-00" formControlName="{{ customField.formControlName }}" autocomplete="off" [required]="customField.is_required">
    <mat-error *ngIf="form.get(customField.formControlName).hasError('required')">
      {{ 'CUSTOM_FIELD.REQUIRED' | translate:{custom_field_name: customField.name} }}
    </mat-error>
    <mat-error *ngIf="form.get(customField.formControlName).hasError('invalid')">
      {{ 'CUSTOM_FIELD.INVALID' | translate }}
    </mat-error>
  </mat-form-field>
  <!-- END CPF -->


  <!-- CPF -->
  <mat-form-field *ngIf="getIsPhone()">
    <input matInput #input placeholder="{{ customField.name }}" mask="(00) 00000.0000" formControlName="{{ customField.formControlName }}" autocomplete="off" [required]="customField.is_required">
    <mat-error *ngIf="form.get(customField.formControlName).hasError('required')">
      {{ 'CUSTOM_FIELD.REQUIRED' | translate:{custom_field_name: customField.name} }}
    </mat-error>
    <mat-error *ngIf="form.get(customField.formControlName).hasError('invalid')">
      {{ 'CUSTOM_FIELD.INVALID' | translate }}
    </mat-error>
  </mat-form-field>
  <!-- END CPF -->
  
  <!-- MULTILINE TEXT -->
  <mat-form-field *ngIf="getIsMultilineText()">
    <textarea matInput #input [maxlength]="customField.max_count" placeholder="{{ customField.name }}" formControlName="{{ customField.formControlName }}" autocomplete="off" [required]="customField.is_required"></textarea>
    <mat-hint align="end" *ngIf="customField.max_count !== null">{{ input.value?.length || 0 }}/{{ customField.max_count }}</mat-hint>
    <mat-error *ngIf="form.get(customField.formControlName).hasError('required')">
      {{ 'CUSTOM_FIELD.REQUIRED' | translate:{custom_field_name: customField.name} }}
    </mat-error>
    <mat-error *ngIf="form.get(customField.formControlName).hasError('invalid')">
      {{ 'CUSTOM_FIELD.INVALID' | translate }}
    </mat-error>
    <mat-error *ngIf="form.get(customField.formControlName).hasError('maxlength')">
      {{ 'CUSTOM_FIELD.MAX_LENGTH' | translate:{ count:customField.max_count } }}
    </mat-error>
  </mat-form-field>
  <!-- END MULTILINE TEXT -->
  
</div>
