import { ResultService } from './../../../../../core/services/result/result.service';
import { SharedModule } from './../../../../../core/modules/shared.module';
import { Component, OnInit, NgModule, Pipe, SecurityContext } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { TranslateService, TranslatePipe, TranslateDirective, TranslateModule } from '@ngx-translate/core';

import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import {ResultStatus} from '../../../../../core/model/result';
import * as moment from 'moment';
import {Utils} from '../../../../../core/utils';

// Função para validar a URL
function isValidUrl(url: SafeResourceUrl): boolean {
  const trustedDomains = ['youtube.com', 'youtu.be'];
  try {
    // Converte SafeResourceUrl para string
    const urlString = url.toString();
    const parsedUrl = new URL(urlString);
    return trustedDomains.indexOf(parsedUrl.hostname) !== -1;
  } catch (e) {
    return false;
  }
}

@Component({
  selector: 'app-modal-video-resul',
  templateUrl: './modal-video-resul.component.html',
  styleUrls: ['./modal-video-resul.component.scss']
})

export class ModalVideoResulComponent implements OnInit {
  private subscriptions = new Subscription();
  public result: any;
  public wod: any;
  public unitTypeTime: any;
  public event: any;
  translate: any;
  urlVideo: SafeUrl;
  statusList: any[];
  isLoading = false;

  resultVideoForm: FormGroup;
  resultVideoFormErrors: any;

  constructor(
    public dialogRef: MatDialogRef<ModalVideoResulComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private resultService: ResultService
  ) {
    this.translationLoader.loadTranslations(english, portugues);

    this.resultVideoFormErrors = {
      video: {},
      status: {}
    };
  }

  ngOnInit() {
    this.subscriptions.add(this.translateService.get('MODALVIDEO').subscribe((res: any) => {
      this.translate = res;

      this.statusList = [
        {
          'title': res.STATUS.WAITING,
          'value': ResultStatus.WAITING
        },
        {
          'title': res.STATUS.APPROVED,
          'value': ResultStatus.APPROVED
        },
        {
          'title': res.STATUS.REPROVE,
          'value': ResultStatus.REFUSED
        },
      ];
    }));

    this.resultVideoForm = this.formBuilder.group({
      video: ['', Validators.required],
      status: ['', Validators.required],
    });

    this.subscriptions.add(this.resultVideoForm.valueChanges.subscribe(() => {
      this.onregisterFormValuesChanged();
    }));

    this.onregisterFormValuesChanged();
    if (!this.result) {
      return;
    }

    const videoUrl = Utils.getUrlToEmbedVideo(this.result.result.video);
    const urlSanitized = this.sanitizer.sanitize(SecurityContext.URL, videoUrl);
    const sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlSanitized);

    if (isValidUrl(sanitizedVideoUrl)) {
      this.urlVideo = sanitizedVideoUrl;
    } else {
      console.error('URL inválida:', videoUrl);
    }

    this.resultVideoForm.get('status').setValue(this.result.result.status);
    this.resultVideoForm.get('video').setValue(this.result.result.video);
  }

  updateVideo() {
    if (this.resultVideoForm.invalid) {
      return;
    }
    if (
      this.resultVideoForm.value.video !== this.result.result.video ||
      this.resultVideoForm.value.status !== this.result.result.status
    ) {
      const video = {
        video: this.resultVideoForm.value.video,
        status: this.resultVideoForm.value.status,
        published: 0
      };
      // console.log(video)

      this.isLoading = true;

      this.subscriptions.add(this.resultService.updateResult(video, this.result.result.id)
        .subscribe(
          result => {
            // console.log("result", result);
            this.result.result = result;
            this.isLoading = false;
            const responseResult = result;

            if (
              this.wod.wod_type.id === this.unitTypeTime &&
              result.result &&
              !result.has_cap
            ) {
              const d = moment.duration(result.result * -1);
              // @ts-ignore
              const milliseconds = `${d.milliseconds()}`.padStart(3, '0');
              // @ts-ignore
              const minutes = `${d.minutes()}`.padStart(2, '0');
              // @ts-ignore
              const seconds = `${d.seconds()}`.padStart(2, '0');

              if (this.event.isResultTimeInMilliseconds) {
                responseResult.result = `${minutes}:${seconds}.${milliseconds}`;
              } else {
                responseResult.result = `${minutes}:${seconds}`;
              }
            }

            if (
              this.wod.tiebreak_type &&
              this.wod.tiebreak_type.id === this.unitTypeTime &&
              result.tiebreakresult
            ) {
              const d = moment.duration(result.tiebreakresult * -1);
              // @ts-ignore
              const milliseconds = `${d.milliseconds()}`.padStart(3, '0');
              // @ts-ignore
              const minutes = `${d.minutes()}`.padStart(2, '0');
              // @ts-ignore
              const seconds = `${d.seconds()}`.padStart(2, '0');
              if (this.event.isResultTimeInMilliseconds) {
                responseResult.tiebreakresult = `${minutes}:${seconds}.${milliseconds}`;
              } else {
                responseResult.tiebreakresult = `${minutes}:${seconds}`;
              }
            }
            responseResult.published = false;

            this.dialogRef.close({update: true});
          }, err => {
            // console.log("err", err);
            this.isLoading = false;
          }
        )); 

    } else {
      this.dialogRef.close({ updated: false });
    }
  }


  onregisterFormValuesChanged() {
    // for (const field in this.resultVideoFormErrors) {
    //   if (!this.resultVideoFormErrors.hasOwnProperty(field)) {
    //     continue;
    //   }

    //   // Clear previous errors
    //   this.resultVideoFormErrors[field] = {};

    //   // Get the control
    //   const control = this.resultVideoForm.get(field);
    //   if (control && control.dirty && !control.valid) {
        
    //     control.errors["invalid"] = (!control.valid) ? true : null;

    //     this.resultVideoFormErrors[field] = control.errors;
    //   }

    // }
  }

}
