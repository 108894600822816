import { BtnComponent } from './btn.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material';
import { MaterialModule } from '../../modules/material.module';

@NgModule({
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MaterialModule
    ],
    declarations: [
        BtnComponent
    ],
    exports: [
        BtnComponent,
        MaterialModule
    ],
    providers: [
    ]
})
export class BtnModule { }
