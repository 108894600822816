import { NgModule } from '@angular/core';
import {AuthGuard} from '../../../../../core/guards/auth.guard';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {SharedModule} from '../../../../../core/modules/shared.module';
import {RouterModule} from '@angular/router';
import {NgxMaskModule} from 'ngx-mask';
import {AgmCoreModule} from '@agm/core';
import {environment} from '../../../../../../environments/environment';
import {EventSubscriptionsComponent} from './event-subscriptions.component';
import { EventSubscriptionFormComponent } from './event-subscription-form/event-subscription-form.component';
import {CurrencyMaskModule} from 'ng2-currency-mask';

const routes = [
  {
    path: 'event/subscriptions',
    component: EventSubscriptionsComponent,
    canActivate: [AuthGuard, EventGuard]
  }
];

@NgModule({
  declarations: [
    EventSubscriptionsComponent,
    EventSubscriptionFormComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    })
  ],
  exports: [
    EventSubscriptionsComponent
  ]
})
export class EventSubscriptionsModule { }
