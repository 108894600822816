import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AccessService {

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { 

  }

  getMyAccesses() : Observable<any>
  {
    return this.http.get('access/me/0/100')
      .map((response: Response) => {
        console.log(response)
        
        let accesses = response["accesses"]
        if (accesses) {
          return { accesses: accesses}
        } else {
          return { accesses: null }
        }
      })
  }

}
