<div class="group-title" *ngIf="!item.hidden">
    <span class="hint-text" [translate]="item.translate">{{ item.title }}</span>
</div>

<div class="group-items">
    <ng-container *ngFor="let item of item.children">
        <fuse-nav-vertical-group *ngIf="item.type=='group'" [item]="item"></fuse-nav-vertical-group>
        <fuse-nav-vertical-collapse *ngIf="item.type=='collapse'" [item]="item"></fuse-nav-vertical-collapse>
        <fuse-nav-vertical-item *ngIf="item.type=='item'" [item]="item"></fuse-nav-vertical-item>
    </ng-container>
</div>