import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'btn-transparent',
  templateUrl: './btn-transparent.component.html',
  styleUrls: ['./btn-transparent.component.scss']
})
export class BtnTransparentComponent implements OnInit {
  @Input() loading = false;
  @Input() btnLoading = false;
  @Input() type: string;
  @Input() icon: string = null;
  @Input() materialIcon: string = null;
  @Input() iconAlign: string;
  @Input() backgroundColor: string = null;
  @Input() classInside: string = null;
  @Input() color: string = null;
  @Input() buttonDisabled = false;
  @Input() testLoading = false;
  @Input() buttonWidth = 'auto';
  @Input() isSmall = false;
  
  constructor() { }

  ngOnInit() {
  }

  clickButton() {
    if (this.btnLoading && !this.buttonDisabled && this.testLoading) {
      this.loading = !this.loading;
    }
  }

}
