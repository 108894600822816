import {ModalConfirmPaymentComponent} from './modal-confirm-payment/modal-confirm-payment.component';
import {PaymentService} from './../../../../../core/services/payment/payment.service';
import {Subscription} from './../../../../../core/model/subscription';
import {PagarmeService} from './../../../../../core/services/pagarme-services/pagarme.service';
import {environment} from './../../../../../../environments/environment';
import {SubscriptionService} from './../../../../../core/services/subscription/subscription.service';
import {BoxService} from './../../../../../core/services/box.service';
import {Event} from './../../../../../core/model/event';
import {Component, ElementRef, NgZone, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {locale as english} from './i18n/en-US';
import {locale as portugues} from './i18n/pt-BR';
import {Lot} from '../../../../../core/model/lot';
import {TableConfig} from '../../../../../core/components/table-x/table-x.component';
import {FuseConfigService} from '../../../../../core/services/config.service';
import {EventPageService} from '../../../../../core/services/event-page/event-page.service';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {FuseTranslationLoaderService} from '../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthGuard} from '../../../../../core/guards/auth.guard';
import {Router} from '@angular/router';
import {MatDialog, MatIconRegistry} from '@angular/material';
import {EventsService} from '../../../../../core/services/event/events.service';
import {BreadcrumbsService} from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {UserService} from '../../../../../core/services/user/user.service';
import {SEOService} from '../../../../../core/services/seo-service/seo.service';
import {GooglePlacesService} from '../../../../../core/services/google-places.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../../../core/services/authentication.service';
import {Utils} from '../../../../../core/utils';
import {FormErrorStateMatcher} from '../../../../../core/utils/form-error-state-matcher';
import {confirmPasswordEquals, CreditCardValidate, requiredWithConditional} from '../../../../../core/utils/custom-validators';
import {TeamMember} from '../../../../../core/model/team-member';
import {User} from '../../../../../core/model/user';
import * as moment from 'moment';
import {Errors} from '../../../../../core/model/errors';
import {GetCardMaskType, GetCreditCardType} from '../../../../../core/utils/credit-card';
import {Subscription as SubscriptionEvent} from 'rxjs';
import 'rxjs/add/observable/fromEvent';
import {CrossXDialogComponent} from '../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import {CepService} from '../../../../../core/services/cep/cep.service';
import {ToastrService} from '../../../../../core/components/toastr_/toastr/toastr.service';
import {Gateways} from '../../../../../core/enum/gateways';
import {CustomField, CustomFieldSubscriptionType} from '../../../../../core/model/custom-field.model';
import {ConfigDefaultInput, DefaultCustomFieldType, GetDefaultFieldNotHidden} from '../../../../../core/model/default-custom-field.model';
import {CustomFieldOption} from '../../../../../core/model/custom-field-option.model';
import {CustomFieldTypes} from '../../../../../core/model/custom-field-type.model';
import { SubDivision } from '../../../../../core/model/sub-division';
import { SubDivisionHttpService } from '../../../../../core/services/sub-division/sub-division-http.service';
import { Championship } from '../../../../../core/model/championship';
import { Competition } from '../../../../../core/model/competition';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy {

  @ViewChild('addressNumber') addressNumberField: ElementRef;
  private editAddressNumber(): void {
    this.addressNumberField.nativeElement.focus();
  }

  private subscriptions: SubscriptionEvent = new SubscriptionEvent();

  public event: Event = null;
  public lot: Lot = null;
  public coupon: any = null;
  public user: any = null;
  public emailUser: string = null;

  // Translate
  public translate: any;

  public registrationForm: FormGroup;
  public matchers: any;
  public defaultInputConfig: ConfigDefaultInput = new ConfigDefaultInput();

  public customFields: CustomField[] = [];
  public formsGroup: FormGroup[];
  public members: TeamMember[] = [];
  public totalMembers = 0;

  public headerPlaceholder = './assets/images/backgrounds/background-myprofile.jpg';

  //Table
  public tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: null,
    massActionCountTitle: null
  };

  public tshirtSize = Utils.getTShirts();
  public genders: any[] = [];
  public cities = [];
  public placeId: string;

  public addressesNfe = [];

  // Box
  public boxes = [];
  public boxId: number;
  private timeOutBoxSearch;
  
  // Payments
  public installments: string[] = [];

  public hideConfirmPassword = true;
  public hidePassword = true;
  public paymentMethod = 'credit_card';
  public maskCard = '0000 0000 0000 0000 999';
  public cardType =  '';

  public isNameNfeEdit = false;
  private isEmailNfeEdit = false;
  private isCpfNfeEdit = false;
  public isTeam = false;
  public emailExist = false;
  public loginError = false;
  public isLoading = false;
  public enabledBoleto = false;
  public errorInPayment = false;
  public errorMembers: string = null;
  public requestErrors: string = null;
  public hiddenPaySubscription = false;

  // Subscription
  public subscription: Subscription = null;

  // sub-division
  subDivisions: SubDivision[] = [];
  subDivisionName: string;

  constructor(
    private fuseConfig: FuseConfigService,
    private eventPageService: EventPageService,
    private titleService: Title,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private authGuard: AuthGuard,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private eventsService: EventsService,
    private breadcrumbsService: BreadcrumbsService,
    private eventGuard: EventGuard,
    public userService: UserService,
    private seoService: SEOService,
    private googlePlacesService: GooglePlacesService,
    private el: ElementRef,
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private boxesService: BoxService,
    private subscriptionService: SubscriptionService,
    private pagarmeService: PagarmeService,
    private paymentService: PaymentService,
    public dialog: MatDialog,
    private cepService: CepService,
    private toastr: ToastrService,
  ) {
    // Icon Info
    iconRegistry.addSvgIcon(
      'ic-info',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/myprofile/ic-profile.svg')
    );

    this.breadcrumbsService.removeAll();
  }
  
  // @ts-ignore
  get hideSubscription(): boolean {
    return this.hiddenPaySubscription;
  }

  ngOnInit() {
    this.setThemeConfig();
    this.registerOnTranslate();
    this.getEvent();
    if (this.event) {
      this.getUserInfos();
      this.getTotalMembers();
      this.getCustomFields();
      this.configForms();
    }
    
    this.userService.enabledRegistration(this.authGuard.getMe().user.email, this.event.id)
      .subscribe(
        result => {
          if (result.errors) {
            this.hiddenPaySubscription  = true;
            const errorRef = this.dialog.open(CrossXDialogComponent, {
              width: '440px'
            });
            errorRef.componentInstance.dialogConfirm = false;
            errorRef.componentInstance.cancelButton = 'FECHAR';
            errorRef.componentInstance.type = 'error';
            errorRef.componentInstance.titleDialog = this.translate.ERRORS.SUBSCRIBE_USER_TITLE_NOT_PERMITTED;
            errorRef.componentInstance.dialogContent = this.translate.ERRORS.SUBSCRIBE_NOT_PERMITTED;
            return;
          }
          this.hiddenPaySubscription  = false;
        }, err => {
          console.log('err', err);
        }
      );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private formInputOnValueChanges(): void {
    const subscription = this.registrationForm.get('cep').valueChanges.subscribe(value => {
      console.log(value, value.length);

      if (value.length > 7) {
        const subscriptionForm = this.cepService.search(value)
          .subscribe(
            result => {
              if (result) {
                if (result.street === null || result.street.length === 0) {
                  this.toastr.show(
                    this.translate.TOASTR.CEP_NOT_ADDRESS.DESCRIPTION,
                    this.translate.TOASTR.CEP_NOT_ADDRESS.TITLE,
                    null,
                    'error',
                  );
                }
                this.registrationForm.get('address').setValue(result.street);
                this.registrationForm.get('neighborhood').setValue(result.neighborhood);
                this.registrationForm.get('cityBoleto').setValue(result.city);
                this.registrationForm.get('state').setValue(result.state);
                this.editAddressNumber();
              }
              console.log(result);
            },
            err => {
              console.log(err);

              this.registrationForm.get('address').setValue('');
              this.registrationForm.get('addressNumber').setValue('');
              this.registrationForm.get('neighborhood').setValue('');
              this.registrationForm.get('cityBoleto').setValue('');
              this.registrationForm.get('state').setValue('');

              this.toastr.show(
                this.translate.TOASTR.CEP_NOT_FOUND.DESCRIPTION,
                this.translate.TOASTR.CEP_NOT_FOUND.TITLE,
                null,
                'error',
              );
            }
          );
        
        this.subscriptions.add(subscriptionForm);
        
      } else {
        this.registrationForm.get('address').setValue('');
        this.registrationForm.get('addressNumber').setValue('');
        this.registrationForm.get('neighborhood').setValue('');
        this.registrationForm.get('cityBoleto').setValue('');
        this.registrationForm.get('state').setValue('');
      }
    });
    
    this.subscriptions.add(subscription);
  }

  prepareSubscription() {
    if (this.isLoading) {
      return;
    }
    
    // validate all form fields
    let hasError = false;
    if (!Utils.formsVerifyIsValid(this.formsGroup)) {
      Utils.formSetHasError(this.formsGroup);
      hasError = true;
    }
    this.members.forEach((member) => {
      if (!Utils.formsVerifyIsValid([member.competitorForm])) {
        Utils.formSetHasError([member.competitorForm]);
        hasError = true;
      }
    });
    if (hasError) {
      return;
    }

    if (this.isTeam && this.members.length < this.totalMembers) {
      this.errorMembers = this.translate.VALIDATIONS.MIN_MEMBERS_REQUIRED;
      return;
    }

    if (this.subscription && this.errorInPayment) {
      // Get data to payment method
      this.isLoading = true;
      this.preparePayment(this.subscription);
      return;
    }

    if (this.subscription) {
      // remove old subscription
      this.isLoading = true;
      this.subscriptionService.cancelOld(this.subscription.id)
        .subscribe(
          result => {
            this.createSubscription();
          }, err => {
          const errors: Errors = new Errors().deserialize((err.error as any));
          // this.requestErrors = errors.getFullMessages()
          this.isLoading = false;
          this.errorInPayment = true;

          const dialogRef = this.dialog.open(CrossXDialogComponent, {
            width: '440px'
          });

          dialogRef.componentInstance.dialogConfirm = false;
          dialogRef.componentInstance.cancelButton = 'FECHAR';
          dialogRef.componentInstance.type = 'error';
          dialogRef.componentInstance.titleDialog = this.translate.ERRORS.SUBSCRIBE_USER_TITLE_FAILED;
          dialogRef.componentInstance.dialogContent = errors.getFullMessages();

          dialogRef.afterClosed().subscribe(result => {
            this.router.navigate(['/event']);
          });
            this.isLoading = false;
          }
        );
    }
    else {
      this.createSubscription();
    }
  }

  createSubscription()
  {
    const parameters = {
      promocode: (this.coupon) ? this.coupon.code : null,
    };

    if (this.user) {
      const leaderboardName = (this.user.leaderboard_name) ? this.user.leaderboard_name : `${this.user.firstname} ${this.user.lastname}`.toUpperCase();
      parameters['user'] = {
        user_id: this.user.id,
        leaderboard_name: leaderboardName,
        box_id: (this.registrationForm.value.box.id) ? this.registrationForm.value.box.id : null,
        box: (!this.registrationForm.value.box.id && this.registrationForm.value.box) ? this.registrationForm.value.box.toUpperCase() : null,
        size: (this.registrationForm.value.tshirt) ? this.registrationForm.value.tshirt : null,
        additional: [],
      };
    }
    else {
      parameters['user'] = {
        email: this.emailUser,
        password: this.registrationForm.value.password,
        firstname: this.registrationForm.value.firstname.toUpperCase(),
        lastname: this.registrationForm.value.lastname.toUpperCase(),
        leaderboard_name: `${this.registrationForm.value.firstname} ${this.registrationForm.value.lastname}`.toUpperCase(),
        cpf: this.registrationForm.value.cpf,
        phone: this.registrationForm.value.phone,
        gender: this.registrationForm.value.gender.value,
        birthdate: moment(this.registrationForm.value.birthdate, this.translate.DATE_FORMAT).format('DD/MM/YYYY'),
        city: this.registrationForm.value.city.toUpperCase(),
        place_id: this.placeId,
        box_id: (this.registrationForm.value.box.id) ? this.registrationForm.value.box.id : null,
        box: (!this.registrationForm.value.box.id && this.registrationForm.value.box) ? this.registrationForm.value.box.toUpperCase() : null,
        size: (this.registrationForm.value.tshirt) ? this.registrationForm.value.tshirt : null,
        additional: [],
      };
    }

    if (this.lot.competition !== undefined && this.lot.competition.team_size && this.lot.competition.team_size > 0) {
      parameters['team_info'] = {
        name: this.registrationForm.value.teamName.toUpperCase(),
        box_id: (this.registrationForm.value.teamBox.id) ? this.registrationForm.value.teamBox.id : null,
        box: (!this.registrationForm.value.teamBox.id && this.registrationForm.value.teamBox) ? this.registrationForm.value.teamBox.toUpperCase() : null,
        type: 'team'
      };

      if (this.members.length > 0) {
        const profiles: any[] = [];
        this.members.forEach(member => {
          profiles.push(member.sendToApi(false));
        });
        parameters['team_members'] = profiles;
      }
    }
    else {
      parameters['team_info'] = {
        name: `${this.registrationForm.value.firstname} ${this.registrationForm.value.lastname}`.toUpperCase(),
        type: 'single'
      };
    }

    if (this.subDivisions.length > 0) {
      parameters['sub_division_id'] = this.registrationForm.value.subDivision;
    }
    
      parameters['nfe_info'] = {
        name: this.registrationForm.value.fullNameNfe,
        cpf: this.registrationForm.value.cpfNfe,
        email: this.registrationForm.value.emailNfe,
        address: this.registrationForm.value.addressNfe,
        address_number: this.registrationForm.value.addressNumberNfe,
        district: this.registrationForm.value.districtNfe,
        city: this.registrationForm.value.cityNfe,
        state: this.registrationForm.value.stateNfe,
        zipcode: this.registrationForm.value.zipcodeNfe
    };

    if (this.customFields.length > 0) {
      
      this.customFields.forEach(customField => {
        // console.log(customField.formControlName, this.registrationForm.get(customField.formControlName), this.registrationForm.get(customField.formControlName).value);
        if (this.registrationForm.get(customField.formControlName).value &&
          this.registrationForm.get(customField.formControlName).value !== '' &&
          this.registrationForm.get(customField.formControlName).value !== null) {
          
          let value = '';
          if (customField.custom_field_type.type === CustomFieldTypes.SINGLE_CHECKBOX) {
            value = this.registrationForm.get(customField.formControlName).value;
          }
          else if (customField.custom_field_type.type === CustomFieldTypes.SELECT) {
            value = this.registrationForm.get(customField.formControlName).value && this.registrationForm.get(customField.formControlName).value.name 
              ? this.registrationForm.get(customField.formControlName).value.name 
              : this.registrationForm.get(customField.formControlName).value;
          }
          else if (customField.custom_field_type.type === CustomFieldTypes.DATE_PICKER) {
            value = moment(this.registrationForm.get(customField.formControlName).value, this.translate.DATE_FORMAT).format('DD/MM/YYYY');
          }
          else {
            value = this.registrationForm.get(customField.formControlName).value;
          }
          parameters['user']['additional'].push({
            event_custom_field_id: customField.id,
            custom_field_type_id: customField.custom_field_type.id,
            value: value
          });
        }
      });
    }
    

    this.isLoading = true;
    this.subscriptionService.subscriptionInEvent(parameters, this.lot.id)
      .subscribe(
        response => {
          // Get data to payment method
          if (this.lot.finalPrice > 0) {
            this.preparePayment(response);
          }
          else {
            this.isLoading = false;
            const dialogRef = this.dialog.open(ModalConfirmPaymentComponent, {
              width: '440px'
            });

            dialogRef.componentInstance.dialogConfirm = false;
            dialogRef.componentInstance.cancelButton = 'FECHAR';
            dialogRef.componentInstance.type = 'success';

            dialogRef.afterClosed().subscribe(result => {
              window.location.href = this.event.success_event_url || environment.dashUrl;
            });
          }

        }, err => {
          console.log('err createSubscription', err.error.errors);

          this.isLoading = false;

          // console.log("err", err, err.error.errors);
          const errors = {
            subscription: (err.error.errors.subscription) ? (err.error.errors.subscription as any).map((error: Errors) => new Errors().deserialize(error)) : null,
            coupon: (err.error.errors.coupon) ? (err.error.errors.coupon as any).map((error: Errors) => new Errors().deserialize(error)) : null,
            user: (err.error.errors.user) ? (err.error.errors.user as any).map((error: Errors) => new Errors().deserialize(error)) : null,
            team: (err.error.errors.team) ? (err.error.errors.team as any).map((error: Errors) => new Errors().deserialize(error)) : null,
            teamMembers: (err.error.errors.team_member) ? (err.error.errors.team_member as any).map((error: Errors) => new Errors().deserialize(error)) : null,
            nfe: (err.error.errors.nfe) ? (err.error.errors.nfe as any).map((error: Errors) => new Errors().deserialize(error)) : null,
            exception: (err.error.errors.exception) ? err.error.errors.exception : null,
          };

          console.log(errors.team);
          let errorMessage = '';
          if (errors.team) {
            errors.team.forEach(error => {
              errorMessage += (errorMessage.length > 0) ? `${errorMessage}, ${error.getFullMessages()}` : error.getFullMessages();

              if (this.isTeam) {
                let field = error.field;
                if (field === 'name') { field = 'teamName'; }
                if (this.registrationForm.get(field)) {
                  const message = error.message;
                  this.registrationForm[field] = {};

                  const control = this.registrationForm.get(field);
                  control.setErrors({
                    valid: false,
                    message: message
                  });
                  this.registrationForm[field] = control.errors;
                }
              }
            });
          }

          if (errors.teamMembers) {
            errors.teamMembers.forEach(error => {
              if (error.field === 'team_id' && error.error === 'not_unique' && error.message === 'This combination of team id, user id already exists.') {
                errorMessage += 'Usuário já cadastrado, verifique todas as informações';
              } else {
                errorMessage += (errorMessage.length > 0) ? `${errorMessage}, ${error.getFullMessages()}` : error.getFullMessages();
              }
            });
          }

          if (errors.coupon) {
            errors.coupon.forEach(error => {
              errorMessage += (errorMessage.length > 0) ? `${errorMessage}, ${error.getFullMessages()}` : error.getFullMessages();
            });
          }

          if (errors.subscription) {
            errors.subscription.forEach(error => {
              errorMessage += (errorMessage.length > 0) ? `${errorMessage}, ${error.getFullMessages()}` : error.getFullMessages();
            });
          }

          if (errors.user) {
            errors.subscription.forEach(error => {
              errorMessage += (errorMessage.length > 0) ? `${errorMessage}, ${error.getFullMessages()}` : error.getFullMessages();
            });
          }
          
          if (errors.nfe) {
            errors.nfe.forEach(error => {
              errorMessage += (errorMessage.length > 0) ? `${errorMessage}, ${error.getFullMessages()}` : error.getFullMessages();
            });
          }
          
          if (errors.exception && errorMessage === '') {
            errorMessage += errors.exception; 
          }

          // const errors: Errors = new Errors().deserialize((err.error as any))
          // console.log(errors, errors.getFullMessages())
          // this.requestErrors = errors.getFullMessages()
          


          const dialogRef = this.dialog.open(CrossXDialogComponent, {
            width: '440px'
          });

          dialogRef.componentInstance.dialogConfirm = false;
          dialogRef.componentInstance.cancelButton = 'FECHAR';
          //dialogRef.componentInstance.actionButton = 'EXCLUIR'
          dialogRef.componentInstance.type = 'error';
          dialogRef.componentInstance.titleDialog = this.translate.ERRORS.SUBSCRIBE_TITLE_FAILED;
          dialogRef.componentInstance.dialogContent = errorMessage;

          dialogRef.afterClosed().subscribe(result => {
            // console.log(`Dialog result: ${result}`);
          });
        }
      );
  }

  preparePayment(subscription: Subscription) {
    this.subscription = subscription;

    if (this.paymentMethod === 'credit_card') {
      const expirationDate = moment(this.registrationForm.value.expirationCard, 'MM/YY');
      const card = {
        card_number: this.registrationForm.value.creditCard,
        card_holder_name: this.registrationForm.value.holdName,
        card_expiration_date: this.registrationForm.value.expirationCard,
        card_cvv: this.registrationForm.value.cvv
      };

      return this.pagarmeService.generateCardHash(card)
        .then(card_hash => {

          const installments = this.registrationForm.value.installments.replace('X', '');
          const parameters = {
            subscription_id: this.subscription.id,
            payment_method: this.paymentMethod,
            card_hash: card_hash,
            card_number: this.registrationForm.value.creditCard,
            expiration_month: expirationDate.format('MM'),
            expiration_year: expirationDate.format('YYYY'),
            security_code: this.registrationForm.value.cvv,
            installments: installments,
            payment_name: this.registrationForm.value.holdName.toUpperCase(),
            address: this.registrationForm.value.addressNfe,
            city: this.registrationForm.value.cityNfe,
            address_number: this.registrationForm.value.addressNumberNfe,
            district: this.registrationForm.value.districtNfe,
            state: this.registrationForm.value.stateNfe,
            zipcode: this.registrationForm.value.zipcodeNfe,
            full_name: this.registrationForm.value.fullNameNfe,
            payment_document: this.registrationForm.value.cpfNfe,
            email: this.registrationForm.value.emailNfe,
            phone: this.registrationForm.value.phone,
          };
          
          this.proccessPayment(parameters);
        })
        .catch(error => {
          console.error('error cc', error);
        });
    }

    else if (this.paymentMethod === 'boleto') {
      const parameters = {
        subscription_id: this.subscription.id,
        payment_method: this.paymentMethod,
        payment_name: this.registrationForm.value.holdName,
        payment_document: this.registrationForm.value.cpfNfe,
      };
      
        parameters['payment_address'] = {
          line1: `${this.registrationForm.get('address').value}, ${this.registrationForm.get('addressNumber').value}`,
          line2: '',
          neighborhood: this.registrationForm.get('neighborhood').value,
          city: this.registrationForm.value.cityNfe,
          state: this.registrationForm.value.stateNfe,
          postal_code: this.registrationForm.value.zipcodeNfe,
          country_code: 'BR',
          address: this.registrationForm.value.addressNfe,
          address_number: this.registrationForm.value.addressNumberNfe,
          district: this.registrationForm.value.districtNfe,
          zipcode: this.registrationForm.value.zipcodeNfe,
          full_name: this.registrationForm.value.fullNameNfe,
          payment_document: this.registrationForm.value.cpfNfe,
          email: this.registrationForm.value.emailNfe,
          phone: this.registrationForm.value.phone,
        };
        
      this.proccessPayment(parameters);
    }
  }

  proccessPayment(parameters: any) {
    this.errorInPayment = false;
    this.paymentService.makePayment(parameters)
      .subscribe(
        result => {
          this.isLoading = false;

          const dialogRef = this.dialog.open(ModalConfirmPaymentComponent, {
            width: '440px'
          });

          dialogRef.componentInstance.dialogConfirm = false;
          dialogRef.componentInstance.cancelButton = 'FECHAR';
          dialogRef.componentInstance.subscriptionPayment = result;
          dialogRef.componentInstance.type = 'success';

          dialogRef.afterClosed().subscribe(result => {
            window.location.href = this.event.success_event_url || environment.dashUrl;
          });


        }, err => {
          // console.log("err", err);
          const errors: Errors = new Errors().deserialize((err.error as any));
          console.log(errors, errors.getFullMessages());
          // this.requestErrors = errors.getFullMessages()
          this.isLoading = false;
          this.errorInPayment = true;

          const dialogRef = this.dialog.open(CrossXDialogComponent, {
            width: '440px'
          });

          dialogRef.componentInstance.dialogConfirm = false;
          dialogRef.componentInstance.cancelButton = 'FECHAR';
          //dialogRef.componentInstance.actionButton = 'EXCLUIR'
          dialogRef.componentInstance.type = 'error';
          dialogRef.componentInstance.titleDialog = this.translate.ERRORS.PAYMENT_TITLE_FAILED;
          dialogRef.componentInstance.dialogContent = errors.getFullMessages();

          dialogRef.afterClosed().subscribe(result => {
            // console.log(`Dialog result: ${result}`);
          });
        }
      );
  }

  selectPaymentMethod() {
    if (this.paymentMethod === 'credit_card') {
      this.registrationForm.controls['creditCard'].setValidators([Validators.required, CreditCardValidate()]);
      this.registrationForm.controls['expirationCard'].setValidators([Validators.required]);
      this.registrationForm.controls['cvv'].setValidators([Validators.required]);
      this.registrationForm.controls['cpfCard'].clearValidators();
      this.registrationForm.controls['cpfCard'].setErrors(null);
        this.registrationForm.controls['address'].clearValidators();
        this.registrationForm.controls['address'].setErrors(null);
        this.registrationForm.controls['addressNumber'].clearValidators();
        this.registrationForm.controls['addressNumber'].setErrors(null);
        this.registrationForm.controls['neighborhood'].clearValidators();
        this.registrationForm.controls['neighborhood'].setErrors(null);
        this.registrationForm.controls['cityBoleto'].clearValidators();
        this.registrationForm.controls['cityBoleto'].setErrors(null);
        this.registrationForm.controls['state'].clearValidators();
        this.registrationForm.controls['state'].setErrors(null);
    }
    else if (this.paymentMethod === 'boleto') {
      this.registrationForm.controls['creditCard'].clearValidators();
      this.registrationForm.controls['expirationCard'].clearValidators();
      this.registrationForm.controls['cvv'].clearValidators();
      this.registrationForm.controls['creditCard'].setErrors(null);
      this.registrationForm.controls['expirationCard'].setErrors(null);
      this.registrationForm.controls['cvv'].setErrors(null);
      this.registrationForm.controls['cpfCard'].setValidators([Validators.required]);
        this.registrationForm.controls['address'].setValidators([Validators.required]);
        this.registrationForm.controls['addressNumber'].setValidators([Validators.required]);
        this.registrationForm.controls['neighborhood'].setValidators([Validators.required]);
        this.registrationForm.controls['cityBoleto'].setValidators([Validators.required]);
        this.registrationForm.controls['state'].setValidators([Validators.required]);
    }

    console.log('controls', this.registrationForm.controls);
  }

  addMember() {
    // if (!Utils.formsVerifyIsValid(this.formsGroup)) {
    //   Utils.formSetHasError(this.formsGroup)
    //   return
    // }

    const newMember = new TeamMember();
    newMember.isOpen = true;
    newMember.capitan = false;
    newMember.dateFormat = this.translate.DATE_FORMAT;
    newMember.customFields = this.customFields;
    newMember.defaultInputConfig = this.defaultInputConfig;
    newMember.genders = this.genders;
    newMember.competitorForm.controls['email'].setValidators([
      Validators.required,
      Validators.email
    ]);

    this.members.forEach(member => {
      member.isOpen = false;
    });

    this.members.push(newMember);
    this.formsGroup.push(newMember.competitorForm);
  }

  actionCollapsible() {
    this.members.forEach(member => {
      member.isOpen = false;
    });
  }


  // Search User
  searchUser(event, member: TeamMember) {
    console.log(member.competitorForm.controls.email.hasError('email'));
    if (!member.competitorForm.controls.email.hasError('email') && event.target.value && event.target.value.length > 0) {
      const email = event.target.value;
      this.userService.searchUserByEmail(email)
        .subscribe(
          result => {
            console.log('result', result);
            if (result) {
              member.setUser(new User().deserialize(result));
            } else {
              member.user = new User();
            }
          }, err => {
            // console.log("err", err);
            member.user = new User();
          }
        );
    }
    else if (event.target.value || event.target.value.length === 0) {
      member.user = new User();
      // member.competitorForm.reset()
    }
  }
  // Box
  searchBox(name: string) {
    clearTimeout(this.timeOutBoxSearch);
    this.timeOutBoxSearch = setTimeout(() => {
      if (name.length > 0) {
        this.boxesService.getListBoxes(name)
          .subscribe(
            result => {
              console.log('result', result);
              this.boxes = result;
            }, err => {
              this.boxes = [];
            }
          );
      } else {
        this.boxes = [];
      }
    }, 300);
  }

  displayFnBox(box: any): string {
    console.log(box);
    return box ? box.name.toUpperCase() : box;
  }

  // Select city
  searchCity(city: string) {
    if (city.length > 2) {
      this.googlePlacesService.search(city)
        .subscribe(
          result => {
            console.log('result', result);
            this.cities = result;
          }, err => {
            console.log('err', err);
            this.cities = [];

          }
        );
    }
  }

  citySelected(event) {
    console.log('event: ', event);
    this.placeId = event.place_id;
  }
  
  // Select Address
  addressCity(address: string) {
    if (address.length > 2) {
      this.googlePlacesService.search(address)
        .subscribe(
          result => {
            console.log('result', result);
            this.addressesNfe  = result;
          }, err => {
            console.log('err', err);
            this.addressesNfe  = [];

          }
        );
    }
  }

  addressSelected(event) {
    this.googlePlacesService.placeIdDetail(event.place_id)
      .subscribe(
        result => {
          if (result) {
            const addressComponents = result.address_components as any[];
            addressComponents.forEach(component => {
              component.types.forEach(type => {
                if  (type === 'route') {
                  this.registrationForm.controls['addressNfe'].setValue(component.long_name);
                }
                else if  (type === 'street_number') {
                  this.registrationForm.controls['addressNumberNfe'].setValue(component.long_name);
                }
                else if  (type === 'sublocality' || type === 'sublocality_level_1') {
                  this.registrationForm.controls['districtNfe'].setValue(component.long_name);
                }
                else if  (type === 'administrative_area_level_2') {
                  this.registrationForm.controls['cityNfe'].setValue(component.long_name);
                }
                else if  (type === 'administrative_area_level_1') {
                  this.registrationForm.controls['stateNfe'].setValue(component.long_name);
                }
                else if  (type === 'postal_code') {
                  this.registrationForm.controls['zipcodeNfe'].setValue(component.long_name.replace('-', ''));
                }
              });
            });
          }
        }, err => {
          console.log('err', err);
        }
      );
  }
  

  formatCurrency(value: string, currency: string): string {
    return Utils.formatCurrency(value, currency);
  }

  nameNfeFocus(event: any) {
    this.isNameNfeEdit = true;
  }

  emailNfeFocus(event: any) {
    this.isEmailNfeEdit = true;
  }
  cpfNfeFocus(event: any) {
    this.isCpfNfeEdit = true;
  }

  public getCustomFields(): void {
    let customFields = this.event.getCustomFieldWithSubscriptionType(CustomFieldSubscriptionType.ATHLETES);
    console.log(customFields);

    const defaultNotShow: number[] = [
      DefaultCustomFieldType.BOX,
      DefaultCustomFieldType.ADDRESS,
      DefaultCustomFieldType.TSHIRT,
      DefaultCustomFieldType.COUNTRY,
      DefaultCustomFieldType.CPF,
      DefaultCustomFieldType.PHONE,
      DefaultCustomFieldType.BIRTHDATE,
    ];

    customFields = customFields.filter((customField: CustomField) => {
      // @ts-ignore
      if (customField.default_field && customField.default_custom_field && defaultNotShow.includes(customField.default_custom_field.id)) {
        if (customField.default_custom_field.id === DefaultCustomFieldType.CPF) {
          this.defaultInputConfig.cpf = {
            required: customField.is_required,
            hidden: customField.is_hidden
          };
          return false;
        }
        else if (customField.default_custom_field.id === DefaultCustomFieldType.PHONE) {
          this.defaultInputConfig.phone = {
            required: customField.is_required,
            hidden: customField.is_hidden
          };
          return false;
        }
        else if (customField.default_custom_field.id === DefaultCustomFieldType.BIRTHDATE) {
          this.defaultInputConfig.birthdate = {
            required: customField.is_required,
            hidden: customField.is_hidden
          };
          return false;
        }
        else if (customField.default_custom_field.id === DefaultCustomFieldType.BOX) {
          this.defaultInputConfig.box = {
            required: customField.is_required,
            hidden: customField.is_hidden
          };
          return false;
        }
        else if (customField.default_custom_field.id === DefaultCustomFieldType.TSHIRT) {
          this.defaultInputConfig.tshirt = {
            required: customField.is_required,
            hidden: customField.is_hidden
          };
          this.tshirtSize = [];
          customField.event_custom_field_options.forEach((customFieldOptions: CustomFieldOption) => {
            this.tshirtSize.push(customFieldOptions.name);
          });
          
          return false;
        }
        else if (customField.default_custom_field.id === DefaultCustomFieldType.ADDRESS) {
          this.defaultInputConfig.address = {
            required: customField.is_required,
            hidden: customField.is_hidden
          };
          return false;
        }
        else if (customField.default_custom_field.id === DefaultCustomFieldType.COUNTRY) {
          this.defaultInputConfig.country = {
            required: customField.is_required,
            hidden: customField.is_hidden
          };
          return false;
        }
        return true;
      }

      return true;
    });

    console.log(this.defaultInputConfig);

    //exclude default fields and not hidden
    customFields = customFields.filter((customField: CustomField) => {
      if (!customField.default_field) {
        return true;
      }

      return GetDefaultFieldNotHidden(customField);
    });


    this.customFields = customFields.filter(customField => {
      return !customField.is_hidden;
    });

    console.log(this.customFields);

    /*this.customFields.forEach((customField: CustomField) => {
      if (!customField.is_hidden) {
        let validators = []
        if (customField.is_required) {
          validators.push(Validators.required);
        }
        if (customField.max_count !== null && customField.max_count > 0) {
          validators.push(Validators.maxLength(customField.max_count));
        }
        
        this.registrationForm.addControl(customField.formControlName, this.formBuilder.control('', validators));
        console.log('add')
      } 
    })*/
  }

  private getEvent() {
    this.event = this.eventPageService.event;
    this.lot = this.eventPageService.getLot();
    this.coupon = this.eventPageService.getCoupon();
    this.subscription = this.eventPageService.getSubscription();
    // console.log('subscription', this.subscription)

    if (this.event === null || this.lot === null) {
      this.router.navigate(['/event']);
      return;
    }
    
    // Payments
    for (let index = 1; index <= this.event.installments; index++) {
      this.installments.push(`${index}X`);
    }
    if (this.lot.competition) {
      if (this.lot.competition.enable_sub_division) {
        this.subDivisions = [];
        this.event.championships.forEach((championship: Championship) => {
          championship.competitions.forEach((competition: Competition) => {
            if (competition.id === this.lot.competition.id) {
              this.subDivisions = championship.sub_divisions;
              this.subDivisions.forEach((subDivision, index) => {
                this.subDivisions[index]['canSubscribe'] = true;
              });
              this.subDivisionName = championship.sub_division_group_name || 'sub-categorias';
              competition.sub_division_limits.forEach((subDivisionLimit) => {
                this.subDivisions.forEach((subDivision, index) => {
                  if (this.subDivisions[index]['canSubscribe'] === false) {
                    return;
                  }
                  this.subDivisions[index]['canSubscribe'] = true;
                  if (subDivisionLimit.sub_division_id === subDivision.id) {
                    this.subDivisions[index]['canSubscribe'] = subDivisionLimit.canSubscribe;
                  }
                });
              });
            }
          });
        });
      }
    }
  }

  private getUserInfos(): void {
    this.user = this.authGuard.getMe().user;

    if (this.user === null) {
      this.emailUser = this.eventPageService.getEmail();
    }
    if (this.emailUser === null && this.user === null) { this.router.navigate(['/event']); }
  }

  private registerOnTranslate(): void {
    this.translationLoader.loadTranslations(english, portugues);

    const subscription = this.translateService.get('REGISTRATION').subscribe((res: any) => {
      this.translate = res;

      this.genders = [
        {
          value: 1,
          text: res.GENDER_MALE
        },
        {
          value: 2,
          text: res.GENDER_FEMALE
        }
      ];
    });
    
    this.subscriptions.add(subscription);
  }
  
  private getTotalMembers(): void {
    // total members
    if (this.lot.competition !== undefined && this.lot.competition.team_size && this.lot.competition.team_size > 1) {
      this.totalMembers = this.lot.competition.team_size - 1;
    }
  }

  private setThemeConfig(): void {
    this.fuseConfig.setSettings({
      layout: {
        navigation: 'none',
        toolbar: 'below',      // 'above', 'below', none
        footer: 'none'
      }
    });
  }

  private configForms(): void {
    this.isTeam = this.lot.competition !== undefined && this.lot.competition.team_size !== null && this.lot.competition.team_size > 0;
    const email = (this.user) ? this.user.email : (this.emailUser ? this.emailUser : '');
    let gender = null;
    if (this.user) {
      this.genders.forEach((genderObj) => {
        if (genderObj.value == (this.user.gender + 1)) {
          gender = genderObj;
        }
      });
    }
    
    let box = this.user && this.user.box ? this.user.box : '';
    if (!(typeof box === 'string')) {
      box = box.name;
    }

    this.registrationForm = this.formBuilder.group({
      teamName: [(this.subscription && this.subscription.team) ? this.subscription.team.name : ''],
      teamBox: [(this.subscription && this.subscription.team) ? this.subscription.team.box : ''],
      email: [{
        value: email,
        disabled: true
      }, [Validators.required, Validators.email]],
      password: ['', [Validators.minLength(6), requiredWithConditional(this.user === null)]], //requiredWithConditional
      confirmPassword: ['', [Validators.minLength(6), confirmPasswordEquals, requiredWithConditional(this.user === null)]],
      firstname: [this.user ? this.user.firstname : '', [Validators.required]],
      lastname: [this.user ? this.user.lastname : '', [Validators.required]],
      box: [box],
      cpf: [this.user && this.user.cpf ? this.user.cpf : ''],
      phone: [this.user && this.user.phone ? this.user.phone : '' ],
      birthdate: [this.user && this.user.birthdate ? moment(this.user.birthdate).format(this.translate.DATE_FORMAT) : ''],
      gender: [gender],
      tshirt: [this.user && this.user.size ? this.user.size : ''],
      city: [''],
      regulation: [false, [Validators.required]],
      installments: ['1X'],
      creditCard: [''],
      expirationCard: [''],
      cvv: [''],
      holdName: [this.user ? `${this.user.firstname} ${this.user.lastname}` : '', [Validators.required]],
      cpfCard: [(this.user && this.user.cpf) ? this.user.cpf : ''],
      cep: [''],
      address: [{
        value: '',
        disabled: true
      }],
      addressNumber: [''],
      neighborhood: [{
        value: '',
        disabled: true
      }],
      cityBoleto: [{
        value: '',
        disabled: true
      }],
      state: [{
        value: '',
        disabled: true
      }],
      fullNameNfe: [''],
      cpfNfe: [''],
      emailNfe: [email],
      addressNfe: [''],
      addressNumberNfe: [''],
      cityNfe: [''],
      districtNfe: [''],
      zipcodeNfe: [''],
      stateNfe: [''],
    });
    
    console.log(this.registrationForm);

    this.matchers = {
      teamName: new FormErrorStateMatcher(),
      teamBox: new FormErrorStateMatcher(),
      email: new FormErrorStateMatcher(),
      password: new FormErrorStateMatcher(),
      confirmPassword: new FormErrorStateMatcher(),
      firstname: new FormErrorStateMatcher(),
      lastname: new FormErrorStateMatcher(),
      box: new FormErrorStateMatcher(),
      cpf: new FormErrorStateMatcher(),
      phone: new FormErrorStateMatcher(),
      birthdate: new FormErrorStateMatcher(),
      gender: new FormErrorStateMatcher(),
      tshirt: new FormErrorStateMatcher(),
      city: new FormErrorStateMatcher(),
      regulation: new FormErrorStateMatcher(),
      creditCard: new FormErrorStateMatcher(),
      installments: new FormErrorStateMatcher(),
      expirationCard: new FormErrorStateMatcher(),
      cvv: new FormErrorStateMatcher(),
      holdName: new FormErrorStateMatcher(),
      cpfCard: new FormErrorStateMatcher(),
      cep: new FormErrorStateMatcher(),
      address: new FormErrorStateMatcher(),
      addressNumber: new FormErrorStateMatcher(),
      neighborhood: new FormErrorStateMatcher(),
      cityBoleto: new FormErrorStateMatcher(),
      state: new FormErrorStateMatcher(),
      fullNameNfe: new FormErrorStateMatcher(),
      cpfNfe: new FormErrorStateMatcher(),
      emailNfe: new FormErrorStateMatcher(),
      addressNfe: new FormErrorStateMatcher(),
      addressNumberNfe: new FormErrorStateMatcher(),
      cityNfe: new FormErrorStateMatcher(),
      districtNfe: new FormErrorStateMatcher(),
      zipcodeNfe: new FormErrorStateMatcher(),
      stateNfe: new FormErrorStateMatcher(),
    };
    if (this.lot.competition) {
      if (this.lot.competition.enable_sub_division) {
        this.registrationForm.addControl('subDivision', this.formBuilder.control('', [Validators.required]));
      }
    }

    this.formInputOnValueChanges();

    if (this.lot.finalPrice > 0) {
      this.selectPaymentMethod();
    }
    else {
      this.registrationForm.controls['creditCard'].clearValidators();
      this.registrationForm.controls['creditCard'].setErrors(null);
      this.registrationForm.controls['expirationCard'].clearValidators();
      this.registrationForm.controls['expirationCard'].setErrors(null);
      this.registrationForm.controls['cvv'].setErrors(null);
      this.registrationForm.controls['cvv'].clearValidators();
      this.registrationForm.controls['cpfCard'].clearValidators();
      this.registrationForm.controls['cpfCard'].setErrors(null);
      this.registrationForm.controls['holdName'].clearValidators();
      this.registrationForm.controls['holdName'].setErrors(null);
    }
    console.log(this.registrationForm.controls);

    if (this.isTeam) {
      this.registrationForm.controls['teamName'].setValidators([Validators.required]);
      this.registrationForm.controls['teamBox'].setValidators([Validators.required]);
    }
    
      this.registrationForm.controls['fullNameNfe'].setValidators([Validators.required]);
      this.registrationForm.controls['cpfNfe'].setValidators([Validators.required]);
      this.registrationForm.controls['emailNfe'].setValidators([Validators.required, Validators.email]);
      this.registrationForm.controls['addressNfe'].setValidators([Validators.required]);
      this.registrationForm.controls['addressNumberNfe'].setValidators([Validators.required]);
      this.registrationForm.controls['cityNfe'].setValidators([Validators.required]);
      this.registrationForm.controls['districtNfe'].setValidators([Validators.required]);
      this.registrationForm.controls['zipcodeNfe'].setValidators([Validators.required]);
      this.registrationForm.controls['stateNfe'].setValidators([Validators.required]);
      

    this.formsGroup = [
      this.registrationForm
    ];

    if (this.subscription && this.subscription.team && this.subscription.team.type === 'team' && this.subscription.team.members.length > 0) {
      this.subscription.team.members.forEach(member => {
        if (!member.capitan) {
          const newMember = new TeamMember().deserialize(member);
          this.members.push(newMember);
          this.formsGroup.push(newMember.competitorForm);
        }
      });

      this.members.forEach(member => {
        // member.setMember()
      });
    }

    if (this.subscription && this.subscription.team && this.subscription.team.type === 'single' && this.subscription.team.members.length > 0) {
      this.registrationForm.controls['box'].setValue(this.subscription.team.members[0].box);
      this.registrationForm.controls['tshirt'].setValue(this.subscription.team.members[0].size);
    }

    this.enabledBoleto = !(this.subscription && this.subscription.payments.paymentMethod === 'Boleto');

    this.registrationForm.get('firstname').valueChanges.subscribe(val => {
      if (!this.isNameNfeEdit) {
        this.registrationForm.controls['fullNameNfe'].setValue(`${val} ${this.registrationForm.value.lastname}`.toUpperCase());
      }
    });

    this.registrationForm.get('lastname').valueChanges.subscribe(val => {
      if (!this.isNameNfeEdit) {
        this.registrationForm.controls['fullNameNfe'].setValue(`${this.registrationForm.value.firstname} ${val}`.toUpperCase());
      }
    });

    this.registrationForm.get('cpf').valueChanges.subscribe(val => {
      if (!this.isCpfNfeEdit) {
        this.registrationForm.controls['cpfNfe'].setValue(`${val}`);
      }
    });

    this.registrationForm.get('email').valueChanges.subscribe(val => {
      if (!this.isEmailNfeEdit) {
        this.registrationForm.controls['emailNfe'].setValue(`${val}`);
      }
    });

    this.registrationForm.get('creditCard').valueChanges.subscribe(value => {
      // console.log(value)
      // console.log(GetCreditCardType(value), GetCardMaskType(GetCreditCardType(value)))
      this.maskCard = GetCardMaskType(GetCreditCardType(value));
      this.cardType = GetCreditCardType(value);
    });
  }

  public customFieldInOneColumn(customField: CustomField): boolean {
    const typeOneLine: CustomFieldTypes[] = [
      CustomFieldTypes.MULTIPLE_CHECKBOX,
      CustomFieldTypes.MULTIPLE_LINE_TEXT,
    ];
    
    // @ts-ignore
    return typeOneLine.includes(customField.custom_field_type.type);
  }
}

