import { ModalConfirmPaymentComponent } from './../../main/content/pages/event-page/registration/modal-confirm-payment/modal-confirm-payment.component';
import { ModalLotsListComponent } from './../../main/content/pages/event-page/modal-lots-list/modal-lots-list.component';
import { EventPageService } from './../services/event-page/event-page.service';
import { ModalWodCopyComponent } from './../../main/content/pages/championship/category-lot-wod/modal-wod-copy/modal-wod-copy.component';
import { ModalWodComponent } from './../../main/content/pages/championship/category-lot-wod/modal-wod/modal-wod.component';
import { ModalCopyLotComponent } from './../../main/content/pages/championship/category-lot-wod/modal-copy-lot/modal-copy-lot.component';
import { ModalCreateLotComponent } from './../../main/content/pages/championship/category-lot-wod/modal-create-lot/modal-create-lot.component';
import { InputUppercaseDirective } from './../directives/input-uppercase/input-uppercase.directive';
import { BtnFabModule } from './../components/btn-fab/btn-fab.module';
import { CreateParticipantSingleComponent } from './../../main/content/pages/championship/participants/create-participant-single/create-participant-single.component';
import { ModalCategoryComponent } from './../../main/content/pages/championship/category-lot-wod/modal-category/modal-category.component';
import { CompetitionService } from './../services/competition/competition.service';
import { AlterMemberComponent } from './../../main/content/pages/user-dashboard/information/alter-member/alter-member.component';
import { ChampionshipService } from './../services/championship/championship.service';
import { BtnIconSmallModule } from './../components/btn-icon-small/btn-icon-small.module';
import { CreateEventComponent } from './../../main/content/pages/event/create-event/create-event.component';
import { CropDialogComponent } from './../components/crop-dialog/crop-dialog.component';
import { SelectTimeModule } from './../components/select-time/select-time.module';
import { TeamProfileComponent } from './../../main/content/pages/profiles/team-profile/team-profile.component';
import { UserProfileComponent } from './../../main/content/pages/profiles/user-profile/user-profile.component';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { ModalVideoResulComponent } from './../../main/content/pages/championship/modal-video-resul/modal-video-resul.component';
import { EventGuard } from './../guards/event.guard';
import { DescriptionSectionModule } from './../components/description-section/description-section.module';
import { BoxService } from './../services/box.service';
import { TabBarModule } from './../components/tab-bar/tab-bar.module';
import { FiltersModule } from './../components/filters/filters.module';
import { ScrollToTopModule } from './../components/scroll-to-top/scroll-to-top.module';
import { UserService } from './../services/user/user.service';
import { TableXRowModule } from './../components/table-x/table-x-row/table-x-row.module';
import { TableXColumnModule } from './../components/table-x/table-x-column/table-x-column.module';
// import { DataTableModule } from './../components/data-table/index';
import { TableXModule } from './../components/table-x/table-x.module';
import { InfiniteScrollerDirective } from './../directives/infinite-scroller.directive';
import { BtnTransparentModule } from './../components/btn-transparent/btn-transparent.module';
import { ModalModule } from './../components/modal/modal.module';
import { ModalSampleComponent } from './../../main/content/sample-content/modal-sample/modal-sample.component';
import { AccessService } from './../services/access.service';
import { AuthenticationService } from './../services/authentication.service';
import { GooglePlacesService } from './../services/google-places.service';
import { BreadcrumbsModule } from './../components/breadcrumbs/breadcrumbs.module';
import { LabelXModule } from './../components/label-x/label-x.module';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { FuseMatSidenavHelperDirective, FuseMatSidenavTogglerDirective } from '../directives/fuse-mat-sidenav-helper/fuse-mat-sidenav-helper.directive';
import { FuseMatSidenavHelperService } from '../directives/fuse-mat-sidenav-helper/fuse-mat-sidenav-helper.service';
import { FusePipesModule } from '../pipes/pipes.module';
import { FuseCountdownComponent } from '../components/countdown/countdown.component';
import { FuseMatchMedia } from '../services/match-media.service';
import { FuseNavbarVerticalService } from '../../main/navbar/vertical/navbar-vertical.service';
import { FuseHighlightComponent } from '../components/highlight/highlight.component';
import { FusePerfectScrollbarDirective } from '../directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseIfOnDomDirective } from '../directives/fuse-if-on-dom/fuse-if-on-dom.directive';
import { FuseMaterialColorPickerComponent } from '../components/material-color-picker/material-color-picker.component';
import { FuseTranslationLoaderService } from '../services/translation-loader.service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from 'ng2-charts';
/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APIInterceptor } from '../apiInterceptor';
// Components
import { CrossXDialogComponent } from './../components/cross-x-dialog/cross-x-dialog.component';
// Services
import { BreadcrumbsService } from '../components/breadcrumbs/breadcrumbs.service';
// Modules
import { TagModule } from '../components/tag/tag.module';
import { BtnModule } from '../components/btn/btn.module';
import { AuthGuard } from '../guards/auth.guard';
// import { MomentModule } from 'angular2-moment';
import { MomentModule } from 'ngx-moment';
import { DynamicContentDirective } from '../directives/dynamic-content.directive';
import { NguCarouselModule } from '@ngu/carousel';
import { ScrollService } from '../services/scroll.service';
import { ToastrModule } from '../components/toastr_/public_api';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { QUILL_CONFIG, QuillConfigInterface, QuillModule } from 'ngx-quill-wrapper';
import { DragulaModule } from 'ng2-dragula';
import { AgmCoreModule } from '../../../../node_modules/@agm/core';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CreateParticipantTeamComponent } from '../../main/content/pages/championship/participants/create-participant-team/create-participant-team.component';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig } from 'ng2-currency-mask/src/currency-mask.config';
import { environment } from '../../../environments/environment';
import { ModalCouponComponent } from '../../main/content/pages/coupons/modal-coupon/modal-coupon.component';
import { InputRemoveWhitespaceDirective } from '../directives/input-remove-whitespace/input-remove-whitespace.directive';
import {ModelCustomFieldComponent} from '../../main/content/pages/event-setup/event-subscriptions/model-custom-field/model-custom-field.component';
import {ModelBoxesListComponent} from '../../main/content/pages/boxes/model-boxes-list/model-boxes-list.component';
import {CustomFieldModule} from '../components/custom-field/custom-field.module';
import { ModalCompetitorComponent } from '../../main/content/pages/event-page/leaderboard-event/modal-competitor/modal-competitor.component';
import { ModalCreateSubDivisionComponent } from '../../main/content/pages/championship/sub-divisions/modal-create-sub-division/modal-create-sub-division.component';
import { ModalConfigurationSubDivisionComponent } from '../../main/content/pages/championship/sub-divisions/modal-configuration-sub-division/modal-configuration-sub-division.component';
import {CreateCrossxAppUrlComponent} from '../../main/content/pages/event-setup/crossx-app/create-crossx-app-url/create-crossx-app-url.component';
import { ModalReorderAthletesComponent } from '../../main/content/pages/championship/leaderboard/modal-reorder-athletes/modal-reorder-athletes.component';
import { FilterSidebarModule } from '../components/filter-sidebar/filter-sidebar.module';
import {PortalModule} from '@angular/cdk/portal';
import { BtnMatFabModule } from '../components/btn-mat-fab/btn-mat-fab.module';
import {ModalCommentsComponent} from "../../main/content/pages/championship/results/modal-comments/modal-comments.component";



// currency mask
// https://github.com/cesarrew/ng2-currency-mask

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true},
];

const DEFAULT_QUILL_CONFIG: QuillConfigInterface = {
  theme: 'snow',
  modules: {
    toolbar: true
  },
  placeholder: ''
};

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.'
};

@NgModule({
  declarations: [
    FuseMatSidenavHelperDirective,
    FuseMatSidenavTogglerDirective,
    FuseCountdownComponent,
    FuseHighlightComponent,
    FuseIfOnDomDirective,
    FusePerfectScrollbarDirective,
    FuseMaterialColorPickerComponent,
    CrossXDialogComponent,
    ModalSampleComponent,
    ModalVideoResulComponent,
    ModalCommentsComponent,
    DynamicContentDirective,
    InfiniteScrollerDirective,
    UserProfileComponent,
    TeamProfileComponent,
    CropDialogComponent,
    CreateEventComponent,
    AlterMemberComponent,
    ModalCategoryComponent,
    ModalCreateLotComponent,
    ModalWodComponent,
    ModalCompetitorComponent,
    ModalCopyLotComponent,
    ModalWodCopyComponent,
    ModalLotsListComponent,
    ModalConfirmPaymentComponent,
    ModalCouponComponent,
    ModalReorderAthletesComponent,
    CreateParticipantSingleComponent,
    CreateParticipantTeamComponent,
    InputUppercaseDirective,
    InputRemoveWhitespaceDirective,
    ModelCustomFieldComponent,
    ModelBoxesListComponent,
    ModalCreateSubDivisionComponent,
    ModalConfigurationSubDivisionComponent,
    CreateCrossxAppUrlComponent
  ],
  imports: [
    FlexLayoutModule,
    CommonModule,
    MaterialModule,
    FormsModule,
    FusePipesModule,
    ReactiveFormsModule,
    ColorPickerModule,
    NgxDnDModule,
    NgxDatatableModule,
    ChartsModule,
    MomentModule,
    TagModule,
    LabelXModule,
    BtnModule,
    FilterSidebarModule,
    PortalModule,
    BtnFabModule,
    BtnTransparentModule,
    BreadcrumbsModule,
    ModalModule,
    TableXModule,
    TableXColumnModule,
    TableXRowModule,
    ScrollToTopModule,
    FiltersModule,
    BtnMatFabModule,
    TabBarModule,
    // DataTableModule,
    NguCarouselModule,
    DescriptionSectionModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      disableTimeOut: false,
      positionClass: 'toast-top-full-width',
      preventDuplicates: true,
      closeButton: true,
      enableHtml: true
    }),
    FilterPipeModule,
    SelectTimeModule,
    ColorPickerModule,
    AngularCropperjsModule,
    QuillModule,
    DragulaModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    }),
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    BtnIconSmallModule,
    CustomFieldModule
  ],
  exports: [
    FlexLayoutModule,
    CommonModule,
    MaterialModule,
    FormsModule,
    FuseMatSidenavHelperDirective,
    FuseMatSidenavTogglerDirective,
    FusePipesModule,
    FuseCountdownComponent,
    FuseHighlightComponent,
    FusePerfectScrollbarDirective,
    ReactiveFormsModule,
    ColorPickerModule,
    NgxDnDModule,
    NgxDatatableModule,
    FuseIfOnDomDirective,
    FuseMaterialColorPickerComponent,
    TranslateModule,
    ChartsModule,
    TagModule,
    LabelXModule,
    BtnModule,
    FilterSidebarModule,
    BtnMatFabModule,
    PortalModule,
    BtnFabModule,
    BtnTransparentModule,
    BreadcrumbsModule,
    ModalModule,
    TableXModule,
    TableXColumnModule,
    TableXRowModule,
    ScrollToTopModule,
    FiltersModule,
    TabBarModule,
    // DataTableModule,
    DescriptionSectionModule,
    InfiniteScrollerDirective,
    FilterPipeModule,
    SelectTimeModule,
    ColorPickerModule,
    AngularCropperjsModule,
    QuillModule,
    BtnIconSmallModule,
    InputUppercaseDirective,
    InputRemoveWhitespaceDirective,
    CustomFieldModule
  ],
  entryComponents: [
    CrossXDialogComponent,
    ModalSampleComponent,
    ModalVideoResulComponent,
    ModalCommentsComponent,
    UserProfileComponent,
    TeamProfileComponent,
    CropDialogComponent,
    CreateEventComponent,
    AlterMemberComponent,
    ModalCategoryComponent,
    ModalCreateLotComponent,
    ModalCopyLotComponent,
    ModalWodCopyComponent,
    ModalWodComponent,
    ModalCompetitorComponent,
    ModalLotsListComponent,
    ModalConfirmPaymentComponent,
    CreateParticipantSingleComponent,
    CreateParticipantTeamComponent,
    ModalCouponComponent,
    ModalReorderAthletesComponent,
    ModelCustomFieldComponent,
    ModelBoxesListComponent,
    ModalCreateSubDivisionComponent,
    ModalConfigurationSubDivisionComponent,
    CreateCrossxAppUrlComponent
  ],
  providers: [
    CookieService,
    FuseMatchMedia,
    FuseNavbarVerticalService,
    FuseMatSidenavHelperService,
    FuseTranslationLoaderService,
    httpInterceptorProviders,
    BreadcrumbsService,
    AuthenticationService,
    GooglePlacesService,
    BoxService,
    AccessService,
    AuthGuard,
    EventGuard,
    UserService,
    ChampionshipService,
    CompetitionService,
    EventPageService,
    ScrollService,
    {
      provide: QUILL_CONFIG,
      useValue: DEFAULT_QUILL_CONFIG
    },
    {
      provide: CURRENCY_MASK_CONFIG,
      useValue: CustomCurrencyMaskConfig
    }
  ]
})

export class SharedModule {
  private static ModelCouponComponent: any;

}
