export const locale = {
  lang: 'en-US',
  data: {
    'CUSTOM_FIELD': {
      'REQUIRED': 'O campo {{ custom_field_name }} é obrigatório',
      'INVALID': 'O valor inserido é inválido',
      'DATE_INVALID': 'A data inserida é inválida',
      'MAX_LENGTH': 'Limite de {{ count }} caracteres',
      'DATE_FORMAT': 'DD/MM/YYYY'
    }
  }
};
