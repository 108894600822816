import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { HeatsHttpService } from '../../../../../../core/services/heats/heats-http.service';
import {Heat} from '../../../championship/heats/model/heat.model';

@Injectable({providedIn: 'root'})
export class HeatsTvHttpService implements OnDestroy {

  private fetchHeatsByChampionshipSubject = new Subject<any>();
  private fetchHeatWithHeatsCompetitorsByHeatIdSubject = new Subject<any>();
  private subscriptions = new Subscription();

  constructor(
    private httpClient: HttpClient,
    private heatHttpService: HeatsHttpService
  ) {
    this.registerOnFetchHeatsByChampionshipIdObservable();
  }

  fetchHeatsByChampionshipObservable(): Observable<any> {
    return this.fetchHeatsByChampionshipSubject.asObservable();
  }

  fetchHeatsByChampionship(championship_id: number): void {
    this.heatHttpService
      .fetchHeatsByChampionshipId(championship_id);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  fetchHeatWithHeatsCompetitorsByHeatId(heatId: number): void {
    const url = `public/heats/${heatId}/heat-competitors`;
    this.httpClient
      .get(url)
      .subscribe((data) => {
        this.fetchHeatWithHeatsCompetitorsByHeatIdSubject.next(data);
      });
  }

  fetchHeatWithHeatsCompetitorsByHeatIdObservable(): Observable<any> {
    return this.fetchHeatWithHeatsCompetitorsByHeatIdSubject.asObservable();
  }

  private registerOnFetchHeatsByChampionshipIdObservable() {
    const subscription = this.heatHttpService
      .fetchHeatsByChampionshipIdBehaviorObservable()
      .subscribe((data) => {
        this.fetchHeatsByChampionshipSubject.next(data);
      });

    this.subscriptions.add(subscription);
  }

}