import { CouponsModule } from './main/content/pages/coupons/coupons.module';
import { LeaderboardEventModule } from './main/content/pages/event-page/leaderboard-event/leaderboard-event.module';
import { WorkoutsEventModule } from './main/content/pages/event-page/workouts-event/workouts-event.module';
import { EventPageModule } from './main/content/pages/event-page/event-page.module';
import { Error500Module } from './main/content/pages/errors/500/error-500.module';
import { CategoryLotWodModule } from './main/content/pages/championship/category-lot-wod/category-lot-wod.module';
import { UserResultsModule } from './main/content/pages/user-dashboard/user-results/user-results.module';
import { InformationModule } from './main/content/pages/user-dashboard/information/information.module';
import { SettingsModule } from './main/content/pages/settings/settings.module';
import { ParticipantsModule } from './main/content/pages/championship/participants/participants.module';
import { LeaderboardModule } from './main/content/pages/championship/leaderboard/leaderboard.module';
import { ResultsModule } from './main/content/pages/championship/results/results.module';
import { EventGuard } from './core/guards/event.guard';
import { LoggedProfileModule } from './main/content/pages/logged-profile/logged-profile.module';
import { ForgotPasswordModule } from './main/content/pages/forgot-password/forgot-password.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import 'hammerjs';
import { SharedModule } from './core/modules/shared.module';
import { AppComponent } from './app.component';
import { FuseMainModule } from './main/main.module';
import { FuseSplashScreenService } from './core/services/splash-screen.service';
import { FuseConfigService } from './core/services/config.service';
import { FuseNavigationService } from './core/components/navigation/navigation.service';
import { FuseSampleModule } from './main/content/pages/sample/sample.module';
import { TranslateModule } from '@ngx-translate/core';
import { LoginModule } from './main/content/pages/login/login.module';
import { RegisterModule } from './main/content/pages/register/register.module';
import { AuthGuard } from './core/guards/auth.guard';
import { FacebookModule } from 'ngx-facebook';
import { NgxMaskModule } from 'ngx-mask';
import { FacebookUserService } from './core/services/facebook.service';
import { ChangePasswordModule } from './main/content/pages/change-password/change-password.module';
import { SubscriptionsModule } from './main/content/pages/subscriptions/subscriptions.module';
import { HeatsModule } from './main/content/pages/championship/heats/heats.module';
import { HeatsTvModule } from './main/content/pages/event-page/heats-tv/heats-tv.module';
import {NgxDnDModule} from '@swimlane/ngx-dnd';
import {HeatsManagerModule} from './main/content/pages/championship/heats-manager/heats-manager.module';
import {PaymentsModule} from './main/content/pages/user-dashboard/payments/payments.module';
import {EventInformationsModule} from './main/content/pages/event-setup/event-informations/event-informations.module';
import {EventSubscriptionsModule} from './main/content/pages/event-setup/event-subscriptions/event-subscriptions.module';
import {EventChampionshipsModule} from './main/content/pages/event-setup/event-championships/event-championships.module';
import {EventLeaderboardModule} from './main/content/pages/event-setup/event-leaderboard/event-leaderboard.module';
import {LotsModule} from './main/content/pages/championship/lots/lots.module';
import {WodsModule} from './main/content/pages/championship/wods/wods.module';
import {EventPermissionsModule} from './main/content/pages/event-setup/event-permissions/event-permissions.module';
import {CrossxAppModule} from './main/content/pages/event-setup/crossx-app/crossx-app.module';
import { EventListModule } from './main/content/pages/logged-profile/event-list/event-list.module';
import { SubDivisionModule } from './main/content/pages/championship/sub-divisions/sub-division.module';
import { UserCheckinModule } from './main/content/pages/user-dashboard/user-checkin/user-checkin.module';

const appRoutes: Routes = [
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
    declarations: [
      AppComponent,
    ],
    imports     : [
      BrowserModule,
      HttpClientModule,
      BrowserAnimationsModule,
      RouterModule.forRoot(appRoutes),
      SharedModule,
      TranslateModule.forRoot(),
      FacebookModule.forRoot(),
      NgxMaskModule.forRoot(),
      FuseMainModule,
      FuseSampleModule,
      LoginModule,
      RegisterModule,
      ForgotPasswordModule,
      ChangePasswordModule,
      LoggedProfileModule,
      ResultsModule,
      LeaderboardModule,
      ParticipantsModule,
      SettingsModule,
      InformationModule,
      UserResultsModule,
      CategoryLotWodModule,
      LotsModule,
      HeatsModule,
      Error500Module,
      EventPageModule,
      LeaderboardEventModule,
      WorkoutsEventModule,
      CouponsModule,
      SubscriptionsModule,
      HeatsManagerModule,
      HeatsTvModule,
      PaymentsModule,
      UserCheckinModule,
      EventInformationsModule,
      EventSubscriptionsModule,
      EventChampionshipsModule,
      EventLeaderboardModule,
      WodsModule,
      SubDivisionModule,
      EventPermissionsModule,
      CrossxAppModule,
      EventListModule
    ],
    providers   : [
      FuseSplashScreenService,
      FuseConfigService,
      FuseNavigationService,
      FacebookUserService,
      AuthGuard,
      EventGuard
    ],
    bootstrap   : [
      AppComponent
    ]
})
export class AppModule {
}
