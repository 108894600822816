import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PointsGroupService {

  constructor(
    private http: HttpClient
  ) { }

  getPointGroupPerEvent(eventId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.get(`admin/pointgroups?eventId=${eventId}`, { responseType })
      .map((response: Response) => {
        console.log(response)
        let pointGroups = response as any
        return pointGroups
      })
  }
}
