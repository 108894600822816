<div class="page-layout blank pb-104" id="scrollBar">
  <div class="p-32">
    <div class="content">
      <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
        <div class="p-32">
          <description-section
            title="{{'RESULTS.TITLE' | translate}}"
            text="{{'RESULTS.DESCRIPTION' | translate}}">
            <mat-icon class="ic-info mr-8" svgIcon="ic-info"></mat-icon>
          </description-section>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="p-32">
          <mat-form-field fxFlex="50" fxFlex.xs="100" class="competitions pr-8">
            <mat-select placeholder="{{'RESULTS.CATEGORY' | translate}}" [value]="championship.competitions[0]" (selectionChange)="competitionSelect($event)">
              <mat-option *ngFor="let competition of championship.competitions" [value]="competition">
                {{ competition.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="enableSubDivision" fxFlex="50" fxFlex.xs="100" class="sub-division" class.xs="sub-division">
            <mat-select placeholder="{{ championship.sub_division_group_name ? (championship.sub_division_group_name | titlecase) : ('RESULTS.FORM.SUB_DIVISION' | translate) }}" [value]="subDivisionId" (selectionChange)="subDivisionSelect($event)">
              <!-- <mat-option [value]="0">{{ 'RESULTS.FORM.ALL' | translate | uppercase }}</mat-option> -->
              <mat-option *ngFor="let subDivision of subDivisions" [value]="subDivision.id">
                {{ subDivision.name | uppercase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <tab-bar #tabbar
          [padding]="true"
          (tabEvent)="tabAction($event)"
          [items]="tabsFilter"
          [tabActive]="0"
          *ngIf="!isNotWods">
        </tab-bar>

        <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="pl-32 pt-32 pr-32 pb-16 mb-16 search line-bottom" *ngIf="!isEmptyResult && !isNotWods">
          <mat-form-field fxFlex="50" fxFlex.xs="100" floatLabel="never" class="search">
            <mat-icon matPrefix class="icon-search">search</mat-icon>
            <input
              matInput
              placeholder="{{ 'RESULTS.SEARCH' | translate}}"
              (keyup)="searchFilter($event)">
          </mat-form-field>
          <div fxFlex="100" fxLayout="row wrap">
            <div fxFlex="25" fxFlex.xs="50" class="pr-16 py-8">
              <div class="box-line p-16 pt-8 pb-8" [ngClass]="{'active': resultEnum.ALL === typeSearch}" (click)="filterResults(resultEnum.ALL)">
                <div fxLayout="column">
                  <h1 class="m-0">
                    {{ totalFilter }}
                  </h1>
                  <span>
                    {{ 'RESULTS.TABS.ALL' | translate | uppercase }}
                  </span>
                </div>
              </div>
            </div>
            <div fxFlex="25" fxFlex.xs="50" class="py-8">
              <div class="box-line p-16 pt-8 pb-8" [ngClass]="{'active': resultEnum.NO_RESULT === typeSearch}" (click)="filterResults(resultEnum.NO_RESULT)">
                <div fxLayout="column">
                  <h1 class="m-0">
                    {{ totalNoResult }}
                  </h1>
                  <span>
                    {{ 'RESULTS.TABS.NO_RESULTS' | translate | uppercase }}
                  </span>
                </div>
              </div>
            </div>
            <div fxFlex="25" fxFlex.xs="50" class="py-8" ngClass="pl-16" ngClass.xs="pl-0">
              <div class="box-line p-16 pt-8 pb-8" [ngClass]="{'active': resultEnum.PUBLISHED === typeSearch}" (click)="filterResults(resultEnum.PUBLISHED)">
                <div fxLayout="column">
                  <h1 class="m-0">
                    {{ totalPublished }}
                  </h1>
                  <span>
                    {{ 'RESULTS.TABS.PUBLISHED' | translate | uppercase }}
                  </span>
                </div>
              </div>
            </div>
            <div fxFlex="25" fxFlex.xs="50" class="pl-16 py-8">
              <div class="box-line p-16 pt-8 pb-8" [ngClass]="{'active': resultEnum.NOT_PUBLISHED === typeSearch}" (click)="filterResults(resultEnum.NOT_PUBLISHED)">
                <div fxLayout="column">
                  <h1 class="m-0">
                    {{ totalNotPublished }}
                  </h1>
                  <span>
                    {{ 'RESULTS.TABS.NOT_PUBLISHED' | translate | uppercase }}
                  </span>
                </div>
              </div>              
            </div>
          </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center" class="p-32 emtpy-content" *ngIf="isEmptyResult || isNotWods">
          <img src="./assets/icons/placeholder/ic-empty.svg" />
          <span class="mt-8" *ngIf="isEmptyResult">{{ 'RESULTS.EMPTY' | translate }}</span>
          <span class="mt-8" *ngIf="isNotWods">{{ 'RESULTS.EMPTY_WOD' | translate }}</span>
        </div>

        <table-x #tableList [items]="resultsFilterData" [config]="tableConfig" [isLoading]="isLoading" (sortEvent)="sortAction($event)" *ngIf="!isEmptyResult && !isNotWods">
          <column property="number" header="{{ 'RESULTS.TABLE.NUMBER' | translate }}" [sortable]="true" align="left" width="50px">
            <ng-template #dataTableCell let-item="item">
              {{ item.number }}
            </ng-template>
          </column>

          <column property="name" header="{{ 'RESULTS.TABLE.NAME' | translate }}" [sortable]="true" align="left">
            <ng-template #dataTableCell let-item="item">
              <a fxLayout="row" fxLayoutAlign="start center" class="small-font">
                <img src="{{ (item.avatar) ? item.avatar : null | placeholderImage:'user' }}" width="80" class="avatar" /> 
                {{ item.name }}
                <label-x class="ml-8" *ngIf="item.disqualified">{{ 'RESULTS.DISQUALIFIED.TYPE_WD' | translate }}</label-x>
                <label-x class="ml-8" *ngIf="item.is_cut">{{ 'RESULTS.DISQUALIFIED.TYPE_CUT' | translate }}</label-x>
              </a>
            </ng-template>
          </column>
        
          <column property="result" header="{{ 'RESULTS.TABLE.RESULT' | translate }}" [sortable]="true" align="center" width="50px">
            <ng-template #dataTableCell let-item="item">
              <div *ngIf="item.disqualified === 0" [ngClass]="{'unpublished': item.result && !item.result.published, 'published': item.result && item.result.published && item.result.result !== null}">
                <input
                  type="text"
                  class="result_field"
                  [disabled]="isLoadingSaveResult"
                  value="{{ getValueResult(item.result) }}"
                  (blur)="inputOnBlur($event, 'result', item)"
                  placeholder="{{event.isResultTimeInMilliseconds ? 'mm:ss.sss' : 'mm:ss' }}"
                  mask="{{event.isResultTimeInMilliseconds ? '00:00.000' : '00:00' }}"
                  dropSpecialCharacters="false"
                  *ngIf="wod?.wod_type?.id === 2 && (item.result === null || (item.result && !item.result.has_cap))" />
                
                <input
                  type="text"
                  class="result_field"
                  disabled="true"
                  value="{{ getValueResult(item.result) }}"
                  placeholder=""
                  *ngIf="wod?.wod_type?.id === 2 && (item.result && item.result.has_cap)" />

                <input
                  type="text"
                  class="result_field"
                  [disabled]="isLoadingSaveResult"
                  (blur)="inputOnBlur($event, 'result', item)"
                  value="{{ getValueResult(item.result) }}"
                  placeholder="{{ unitType }}"
                  *ngIf="wod?.wod_type?.id !== 2" />
              </div>
            </ng-template>
          </column>

          <column property="cap" header="" [sortable]="false" align="center" *ngIf="wod && wod?.wod_type?.id == 2 && wod.goal && wod.goal > 0" width="40px">
            <ng-template #dataTableCell let-item="item">
              <btn-transparent *ngIf="item.disqualified === 0" color="#2c4151" buttonWidth="35px" [matMenuTriggerFor]="resultOptionsMenu" [matMenuTriggerData]="{result: item, timeCapOption: (item.result && item.result.has_cap) ? 'timeCap' : 'time' }"
                (click)="tipeCapError = null; timeCapOptionVal = (item.result && item.result.has_cap) ? 'timeCap' : 'time'; selectedResult = item; inputRepsVal = (item.result && item.result.result && item.result.has_cap) ? item.result.result : ''">
          
                <i class="material-icons" style="margin-left: -4px; margin-top: -4px; font-size: 26px;">more_vert</i>
              </btn-transparent>
            </ng-template>
          </column>

          <column property="tiebreak" header="{{ 'RESULTS.TABLE.TIEBREAK' | translate }}" [sortable]="true" align="center" *ngIf="wod && wod.tiebreak_type !== null" width="100px">
            <ng-template #dataTableCell let-item="item">
              <div *ngIf="item.disqualified === 0" [ngClass]="{'unpublished': item.result && !item.result.published, 'published': item.result && item.result.published && item.result.result !== null}">
                <input 
                  type="text"
                  class="result_field"
                  (blur)="inputOnBlur($event, 'tiebreak', item)"
                  value="{{ (item.result && item.result.tiebreakresult) ? item.result.tiebreakresult : '' }}"
                  placeholder="{{event.isResultTimeInMilliseconds ? 'mm:ss.sss' : 'mm:ss' }}"
                  mask="{{event.isResultTimeInMilliseconds ? '00:00.000' : '00:00' }}"
                  dropSpecialCharacters="false"
                  *ngIf="wod && wod.tiebreak_type && wod.tiebreak_type.id === 2" />
                
                <input
                  type="text"
                  class="result_field"
                  (blur)="inputOnBlur($event, 'tiebreak', item)"
                  value="{{ (item.result && item.result.tiebreakresult) ? item.result.tiebreakresult : '' }}"
                  placeholder="{{ unitTypeTiebreak }}"
                  *ngIf="wod && wod.tiebreak_type && wod.tiebreak_type.id !== 2" />

              </div>
            </ng-template>
          </column>

          <column property="video" header="{{ 'RESULTS.TABLE.VIDEO' | translate }}" [sortable]="false" align="center"
            *ngIf="championship.online" width="50px">
            <ng-template #dataTableCell let-item="item">
              <btn-transparent icon="results/ic-video.svg" [ngStyle]="{'opacity': !item?.result ? '50%' : '100%'}" buttonWidth="35px" iconAlign="right" (click)="openModalWithResult(item)"></btn-transparent>
            </ng-template>
          </column>

          <column property="status" header="{{ 'RESULTS.TABLE.STATUS' | translate }}" [sortable]="true" align="center"
            *ngIf="championship.online" width="120px">
            <ng-template #dataTableCell let-item="item">
              <label-x type="{{ getResultStatus(item, true) }}" *ngIf="item.result && item.result.status !== null">
                {{ getResultStatus(item, false) }}
              </label-x>
            </ng-template>
          </column>

          <column property="cap" header="" [sortable]="false" align="center" width="20px">
            <ng-template #dataTableCell let-item="item">
              <mat-icon style="margin-top: 7.5px; font-size: 16px" *ngIf="item?.result?.description">chat_bubble</mat-icon>
            </ng-template>
          </column>

          <column property="cap" header="" [sortable]="false" align="center" width="40px">
            <ng-template #dataTableCell let-item="item">
              <btn-transparent
                [matMenuTriggerData]="{ item: item }"
                color="#2c4151"
                buttonWidth="35px"
                [matMenuTriggerFor]="configDisqualifiedMenu">
                <img alt="configurações" src="./assets/icons/common/ic-settings.svg" />
              </btn-transparent>
            </ng-template>
          </column>
        </table-x>

        <!-- MAT-MENU OPTIONS Result -->
        <mat-menu class="submenu user-menu bottom arrow-right" #resultOptionsMenu="matMenu" [overlapTrigger]="false" xPosition="before">
          <ng-template matMenuContent let-result="result" var-timeCapOption="timeCapOption">
            <div fxLayout="column" class="pl-24 pr-24 pt-24" [ngClass]="{'pb-8': timeCapOptionVal === 'timeCap', 'pb-24': timeCapOptionVal === 'time'}">
              <mat-radio-group fxLayout="column" [(ngModel)]="timeCapOptionVal">
                <mat-radio-button value="time" class="mb-8" (click)="$event.stopPropagation();" [checked]="timeCapOptionVal === 'time'" (change)="radioChange($event, result)">{{ 'RESULTS.UNITS.TIME' | translate }}</mat-radio-button>
                <mat-radio-button value="timeCap" (click)="$event.stopPropagation();" [checked]="timeCapOptionVal === 'timeCap'">{{ 'RESULTS.TIMECAP' | translate }}</mat-radio-button>
              </mat-radio-group>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center" class="pl-24 pr-24" *ngIf="timeCapOptionVal === 'timeCap'">
              <strong fxFlex="50">{{ 'RESULTS.UNITS.REPS' | translate }}:</strong>
              <mat-form-field fxFlex="50" class="center" floatLabel="never">
                <input 
                  matInput
                  placeholder="reps"
                  type="number"
                  [(ngModel)]="inputRepsVal"
                  (click)="$event.stopPropagation();">
                  <!-- value="{{ (result && result.result && result.result.has_cap) ? result.result.result : '' }}" -->
              </mat-form-field>
            </div>
            
            <div fxLayout="row" class="textRed save-timecap-error pl-24 pr-24 pb-8" *ngIf="tipeCapError && timeCapOptionVal === 'timeCap'">
              {{ tipeCapError }}
            </div>

            <div fxLayout="row" fxLayoutAlign="end center" class="pr-24 pb-24" *ngIf="timeCapOptionVal === 'timeCap'">
              <btn 
                [btnLoading]="true"
                [loading]="isLoadingSaveTimeCap"
                (click)="saveTimeCap(result, timeCapOptionVal, inputRepsVal); $event.stopPropagation();">{{ 'RESULTS.SAVE' | translate }}</btn>
            </div>
          </ng-template>
        </mat-menu>

        <!-- MAT-MENU OPTIONS disqualified -->
        <mat-menu class="submenu user-menu bottom arrow-right" #configDisqualifiedMenu="matMenu" [overlapTrigger]="false" xPosition="before">
          <ng-template matMenuContent let-item="item" var-timeCapOption="timeCapOption">
              <div *ngIf="item.disqualified === 0 && item.is_cut === 0">
                <button class="line-bottom mat-menu-item" (click)="openModalComment(item)">
                  <span>{{ 'RESULTS.COMMENT' | translate }}</span>
                </button>
                <button class="line-bottom mat-menu-item" (click)="disqualifiedCompetitor(item, enumDisqualified.CUT)">
                  <span>{{ 'RESULTS.DISQUALIFIED.CUT' | translate | titlecase }} ({{ 'RESULTS.DISQUALIFIED.TYPE_CUT' | translate }})</span>
                </button>
                <button class="line-bottom mat-menu-item" (click)="disqualifiedCompetitor(item, enumDisqualified.WD)">
                  <span>{{ 'RESULTS.DISQUALIFIED.WD' | translate | titlecase }} ({{ 'RESULTS.DISQUALIFIED.TYPE_WD' | translate }})</span>
                </button>
              </div>
              <div *ngIf="item.disqualified || item.is_cut">
                <button class="line-bottom mat-menu-item" (click)="openModalComment(item)">
                  <span>{{ 'RESULTS.COMMENT' | translate }}</span>
                </button>
                <button class="line-bottom mat-menu-item" (click)="disqualifiedCompetitor(item, enumDisqualified.CANCEL)">
                  <span class="text-red">{{ 'RESULTS.DISQUALIFIED.REMOVE' | translate | titlecase }}</span>
                </button>
              </div>
          </ng-template>
        </mat-menu>

        <!-- MAT-MENU ACTIONS -->
        <mat-menu class="submenu bottom arrow-right" #resultActionMenu="matMenu" [overlapTrigger]="false" xPosition="before">
          <ng-template matMenuContent let-item="item" let-valor="valor">
            <button mat-menu-item class="line-bottom">
              <span>Histórico de alterações</span>
            </button>
          </ng-template>
        </mat-menu>
      </div>
    </div>
  </div>
</div>

<btn-fab [loading]="isLoadingPublish" [btnLoading]="true" materialIcon="publish" (click)="publishResults()" *ngIf="isShowPublishButton"></btn-fab>

<scroll-to-top scrollId="scrollBar" [animate]="true" [speed]="50" [acceleration]="2" [scrollDistance]="300"></scroll-to-top>