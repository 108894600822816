export const locale = {
  lang: 'en-US',
  data: {
    'MODALVIDEO': {
      'TITLE': 'Vídeo',
      'URL_VIDEO': 'URL do vídeo',
      'DESCRIPTION': 'Comentário do judge',
      'URL_REQUIRED': 'A url do vídeo é obrigatório',
      'STATUS': {
        'TITLE': 'Status',
        'APPROVED': 'Aprovado',
        'REPROVE': 'Reprovado',
        'WAITING': 'Aguardando',
      },
      'SAVE': 'Salvar',
    }
  }
};
