import { Subscription } from '../../model/subscription';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import { Subscription as SubscriptionEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private subscriptions = new SubscriptionEvent();
  private _fetchSubscriptionObservable = new Subject<Subscription>();

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  fetchSubscriptionObservable(): Observable<Subscription> {
    return this._fetchSubscriptionObservable.asObservable();
  }

  // Register
  subscriptionInEvent(parameters: any, lotId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.post(`public/registration/subscription/${lotId}`, parameters, { responseType })
      .map((response: Response) => {
        // console.log(response)
        return new Subscription().deserialize(response as any);
      });
  }

  getUserInEvent(lotId: number, userId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.get(`public/registration/subscription/${lotId}/${userId}`, { responseType })
      .map((response: Response) => {
        // console.log(response)
        return response;
      });
  }

  cancelOld(subscriptionId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.delete(`public/registration/subscription/${subscriptionId}`, { responseType })
      .map((response: Response) => {
        // console.log(response)
        return response;
      });
  }
  
  getReportSubscriptions(eventId: number, championshipId: number = 0, searchTerm: string = '' , sort: any = null, page: number = 0): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    let url = `admin/report/subscriptions/${eventId}/${championshipId}/?page=${page}`;

    if (sort) { url = url + `&order_by[${sort.column}]=${sort.order}`; }
    if (searchTerm && searchTerm.length > 0) { url = url + `&name=${searchTerm}`; } 

    return this.http.get(url, { responseType })
      .map((response: Response) => {
        // console.log(response)
        return response;
        // return (response as any).map((subscription: Subscription) => new Subscription().deserialize(subscription))
      });
  }
  
  exportSubscriptions(eventId: number, championshipId: number = 0, searchTerm: string = ''): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    let url = `admin/report/subscriptions/${eventId}/${championshipId}/export`;
    if (searchTerm && searchTerm.length > 0) { url = url + `?name=${searchTerm}`; }
    
    return this.http.get(url, {responseType})
      .map((response: Response) => {
        // console.log(response)
        return response;
      });
  }


  fetchSubscriptionWithChampionship(championshipId: number, competitionId: number, teamId: number): void {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'payments-championship',
    ];

    const queryParams = {
      championship_id: championshipId,
      competition_id: competitionId,
      team_id: teamId
    };

    const url = this.router.createUrlTree(baseUrl, {
      queryParams: queryParams
    });
    const parsedUrl = url.toString().substring(1, url.toString().length);

    const subscription = this.http
      .get(parsedUrl, {responseType})
      .subscribe((subscription: Subscription) => {
          this._fetchSubscriptionObservable.next(subscription);
        },
        err => {
          this._fetchSubscriptionObservable.error(err);
        });

    this.subscriptions.add(subscription);
  }

  cancelSubscription(subscriptionId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'subscription',
      subscriptionId,
      'cancel'
    ];
    
    const url = this.router.createUrlTree(baseUrl, );
    const parsedUrl = url.toString().substring(1, url.toString().length);

    return this.http.post(parsedUrl, {responseType})
      .map((response: Response) => {
        // console.log(response)
        return response;
      });
  }
  
}
