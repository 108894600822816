<div class="page-layout blank pb-64" *ngIf="isCancelSubscription">
    <div class="p-32">
        <div class="content">
            <div class="card auto-width mb-32 pt-20 p-0 pt-32" [ngClass.gt-sm]="'mb-0'">
                <div fxLayout="column" fxLayoutAlign="center center">
                    <div class="error-code">:(</div>
                    <div class="message">{{ "PAYMENT.CANCEL_SUBSCRIPTION_CONFIRM.TITLE" | translate | uppercase }}</div>
                    <div class="sub-message">
                        {{ "PAYMENT.CANCEL_SUBSCRIPTION_CONFIRM.DESCRIPTION" | translate : { championship_name: championship?.name } }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="page-layout blank pb-64" *ngIf="!isCancelSubscription">
    <div class="p-32">
        <div class="content">
            <div class="card auto-width mb-32 pt-20 p-0 pt-32" [ngClass.gt-sm]="'mb-0'">
                <h2 class="mt-0 pl-32 pr-32 pb-20 border-bottom">{{ championship ? championship.name : "" }}</h2>

                <div fxLayoutAlign="center center" class="p-40" *ngIf="isLoading">
                    <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
                </div>

                <!-- Table -->
                <table-x
                    [items]="payments"
                    [config]="tableConfig"
                    [isLoading]="isLoadingPayment"
                    tableName="payments"
                    [isDraggable]="false"
                    *ngIf="subscription && championship.invitation?.status !== 3"
                >
                    <column
                        property="payment_date"
                        header="{{ 'PAYMENT.TABLE.PAYMENT_DATE' | translate }}"
                        [sortable]="false"
                        align="left"
                        classesBody="pt-20 pb-20"
                        [borderLeftColor]="{ '1': '#8195A5', '2': '#81BB46', '3': '#EB5B46', '4': '#BC83EE', '11': '#BC83EE' }"
                    >
                        <ng-template #dataTableCell let-item="item" let-index="index">
                            {{ item.paymentDate ? (item.paymentDate | date : translate.DATE_FORMAT) : "" }}
                        </ng-template>
                    </column>

                    <column
                        property="payment_method"
                        header="{{ 'PAYMENT.TABLE.PAYMENT_METHOD' | translate }}"
                        [sortable]="false"
                        align="left"
                        classesBody="pt-20 pb-20"
                    >
                        <ng-template #dataTableCell let-item="item" let-index="index">
                            {{ item.paymentMethod }}.
                            <span *ngIf="item.payment_method === 'credit_card'">
                                <img src="./assets/icons/card/{{ getCardBrandIcon(item) }}" class="ml-4 mr-4" />
                                {{ "**** " + item.cc_last_digits }}
                            </span>
                        </ng-template>
                    </column>

                    <column
                        property="value"
                        header="{{ 'PAYMENT.TABLE.VALUE' | translate }}"
                        [sortable]="false"
                        align="left"
                        classesBody="pt-20 pb-20"
                    >
                        <ng-template #dataTableCell let-item="item" let-index="index">
                            {{
                                formatCurrency(
                                    item.grossValue | currency : translate.CURRENCY_CODE : "symbol-narrow" : "1.2-2",
                                    translate.CURRENCY_CODE
                                )
                            }}
                        </ng-template>
                    </column>

                    <column
                        property="status"
                        header="{{ 'PAYMENT.TABLE.STATUS' | translate }}"
                        [sortable]="false"
                        align="left"
                        classesBody="pt-20 pb-20"
                    >
                        <ng-template #dataTableCell let-item="item" let-index="index">
                            <label-x type="{{ getPaymentStatus(item, true) }}">
                                {{ getPaymentStatus(item) | uppercase }}
                            </label-x>
                        </ng-template>
                    </column>

                    <column property="actions" header="" [sortable]="false" align="center" width="100px">
                        <ng-template #dataTableCell let-item="item">
                            <btn-transparent
                                icon="common/ic-settings.svg"
                                buttonWidth="35px"
                                iconAlign="left"
                                [matMenuTriggerFor]="paymentMenu"
                                [matMenuTriggerData]="{ payment: item }"
                                class="hide-in-drag"
                                *ngIf="item.statusCode === 1"
                            ></btn-transparent>
                        </ng-template>
                    </column>
                </table-x>

                <!-- MAT-MENU ACTIONS -->
                <mat-menu class="submenu bottom arrow-right" #paymentMenu="matMenu" [overlapTrigger]="false" xPosition="before">
                    <ng-template matMenuContent let-payment="payment">
                        <button mat-menu-item class="line-bottom" (click)="isShowPaymentForm = true; enabledBoleto = false">
                            <span>{{ "PAYMENT.ALTER_PAYMENT_METHOD" | translate }}</span>
                        </button>

                        <button
                            mat-menu-item
                            class="line-bottom"
                            *ngIf="payment.payment_method === 'boleto' && payment.statusCode === 1"
                            (click)="seeBoleto(payment.boleto_url)"
                        >
                            <span>{{ "PAYMENT.SEE_BOLETO" | translate }}</span>
                        </button>
                    </ng-template>
                </mat-menu>

                <div
                    *ngIf="!championship?.is_invitation && (subscription?.status === 1 || subscription?.status === 0)"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    class="p-32"
                >
                    {{ "PAYMENT.DESCRIPTION_CANCEL" | translate | uppercase }}
                    <btn type="grayscale" (click)="cancelSubscription()" class="mr-20 mt-20">{{
                        "PAYMENT.CANCEL_SUBSCRIPTION" | translate | uppercase
                    }}</btn>
                </div>

                <!-- INVITATION -->
                <div class="p-32 pb-16 pt-24" *ngIf="championship?.is_invitation">
                    <!-- Status PENDING -->
                    <div *ngIf="championship.invitation?.status === 0">
                        <span
                            innerHTML="{{
                                'PAYMENT.INVITATION.WELCOME'
                                    | translate
                                        : { championship_name: championship?.name, competition_name: championship?.competition?.name }
                            }}"
                        ></span>
                        <h2 class="text-dark-gray mb-0">
                            {{ "PAYMENT.INVITATION.TOTAL_VALUE" | translate }}:
                            <span class="text-blue">{{
                                formatCurrency(
                                    championship?.invitation?.lot?.finalPrice
                                        | currency : translate.CURRENCY_CODE : "symbol-narrow" : "1.2-2",
                                    translate.CURRENCY_CODE
                                )
                            }}</span>
                        </h2>
                        <span class="text-gray">
                            {{ "PAYMENT.INVITATION.SUBSCRIPTION" | translate }}:
                            {{
                                formatCurrency(
                                    championship?.invitation?.lot?.price | currency : translate.CURRENCY_CODE : "symbol-narrow" : "1.2-2",
                                    translate.CURRENCY_CODE
                                )
                            }}
                            +
                            {{
                                formatCurrency(
                                    championship?.invitation?.lot?.taxPrice
                                        | currency : translate.CURRENCY_CODE : "symbol-narrow" : "1.2-2",
                                    translate.CURRENCY_CODE
                                )
                            }}
                            {{ "PAYMENT.INVITATION.TAX" | translate }}</span
                        >
                        <div fxLayout="row" class="mt-20 mb-16" *ngIf="!isShowPaymentForm">
                            <btn type="grayscale" (click)="refusedInvitation()" class="mr-20">{{
                                "PAYMENT.INVITATION.REFUSED" | translate | uppercase
                            }}</btn>
                            <btn (click)="isShowPaymentForm = !isShowPaymentForm">{{
                                "PAYMENT.INVITATION.ACCEPT" | translate | uppercase
                            }}</btn>
                        </div>
                    </div>

                    <!-- Status Refused -->
                    <div *ngIf="championship.invitation?.status === 3">
                        <div fxLayout="column" fxLayoutAlign="center center">
                            <div class="error-code">:(</div>
                            <div class="message">{{ "PAYMENT.INVITATION.REFUSED_TITLE" | translate | uppercase }}</div>
                            <div class="sub-message">
                                {{ "PAYMENT.INVITATION.REFUSED_MESSAGE" | translate : { championship_name: championship?.name } }}
                            </div>
                            <h2 class="text-dark-gray mb-0">
                                {{ "PAYMENT.INVITATION.TOTAL_VALUE" | translate }}:
                                <span class="text-blue">{{
                                    formatCurrency(
                                        championship?.invitation?.lot?.finalPrice
                                            | currency : translate.CURRENCY_CODE : "symbol-narrow" : "1.2-2",
                                        translate.CURRENCY_CODE
                                    )
                                }}</span>
                            </h2>
                            <span class="text-gray">
                                {{ "PAYMENT.INVITATION.SUBSCRIPTION" | translate }}:
                                {{
                                    formatCurrency(
                                        championship?.invitation?.lot?.price
                                            | currency : translate.CURRENCY_CODE : "symbol-narrow" : "1.2-2",
                                        translate.CURRENCY_CODE
                                    )
                                }}
                                +
                                {{
                                    formatCurrency(
                                        championship?.invitation?.lot?.taxPrice
                                            | currency : translate.CURRENCY_CODE : "symbol-narrow" : "1.2-2",
                                        translate.CURRENCY_CODE
                                    )
                                }}
                                {{ "PAYMENT.INVITATION.TAX" | translate }}</span
                            >
                            <div fxLayout="row" class="mt-32 mb-16" *ngIf="!isShowPaymentForm">
                                <btn type="grayscale" (click)="refusedInvitation()" class="mr-20">{{
                                    "PAYMENT.INVITATION.REFUSED" | translate | uppercase
                                }}</btn>
                                <btn (click)="isShowPaymentForm = !isShowPaymentForm">{{
                                    "PAYMENT.INVITATION.ACCEPT" | translate | uppercase
                                }}</btn>
                            </div>
                        </div>
                    </div>

                    <!-- Status Cancelled -->
                    <div *ngIf="championship.invitation?.status === 5">
                        <div fxLayout="column" fxLayoutAlign="center center">
                            <div class="error-code">:(</div>
                            <div class="message">{{ "PAYMENT.INVITATION.CANCELLED_TITLE" | translate | uppercase }}</div>
                            <div class="sub-message">
                                {{ "PAYMENT.INVITATION.CANCELLED_MESSAGE" | translate : { championship_name: championship?.name } }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END INVITATION -->

                <!-- PAYMENTS -->
                <form
                    class="pl-32 pr-32 pb-32"
                    name="registrationForm"
                    [formGroup]="registrationForm"
                    [hidden]="!isShowPaymentForm"
                    novalidate
                >
                    <h5>{{ "PAYMENT.PAYMENT_INFORMATION" | translate }}</h5>
                    <div fxLayout="column" class="box-line p-24">
                        <div fxLayout="row" fxLayoutAlign="start center" id="payments-methods" class="mb-24">
                            <button
                                fxLayout="row"
                                fxLayoutAlign="start center"
                                class="p-16"
                                [ngClass]="{ active: paymentMethod === 'credit_card' }"
                                (click)="paymentMethod = 'credit_card'; selectPaymentMethod()"
                            >
                                <img src="./assets/icons/registration/ic-credit-card.svg" class="mr-8" />
                                {{ "PAYMENT.CREDIT_CARD" | translate | uppercase }}
                                <svg
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    class="ml-32"
                                >
                                    <g id="Registration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g
                                            id="Event---Inscrição-Time"
                                            transform="translate(-489.000000, -1705.000000)"
                                            fill="#004CFF"
                                            fill-rule="nonzero"
                                        >
                                            <g id="Group-21" transform="translate(205.000000, 1634.000000)">
                                                <g id="Group-19" transform="translate(20.000000, 55.000000)">
                                                    <g id="Group-15" transform="translate(18.000000, 14.000000)">
                                                        <g id="verified" transform="translate(246.000000, 2.000000)">
                                                            <path
                                                                d="M20.4852656,3.5146875 C18.2187656,1.24823438 15.2053125,0 12,0 C8.79464062,0 5.7811875,1.24823438 3.5146875,3.5146875 C1.24823437,5.7811875 0,8.7946875 0,12 C0,15.2053125 1.24823437,18.2187656 3.5146875,20.4852656 C5.7811875,22.7517656 8.7946875,24 12,24 C15.2053125,24 18.2187656,22.7517656 20.4852656,20.4853125 C22.7517656,18.2187656 24,15.2053125 24,12 C24,8.7946875 22.7517656,5.78123438 20.4852656,3.5146875 Z M18.6984172,8.43996374 L10.8630775,16.7994987 C10.7026629,16.9706441 10.4924098,17.0561875 10.2822114,17.0561875 C10.0719582,17.0561875 9.86170504,16.9706441 9.70129047,16.7994987 L5.30158279,12.1055356 C4.98075365,11.7633034 4.98075365,11.2083812 5.30158279,10.8660321 C5.62235715,10.5237414 6.14254068,10.5237414 6.46336982,10.8660321 L10.2822114,14.9402726 L17.5366302,7.20046018 C17.8574045,6.85816952 18.3775881,6.85816952 18.6984172,7.20046018 C19.0192463,7.54275085 19.0192463,8.09767308 18.6984172,8.43996374 Z"
                                                                id="Shape"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                            <button
                                fxLayout="row"
                                fxLayoutAlign="start center"
                                class="p-16"
                                [ngClass]="{ active: paymentMethod === 'boleto' }"
                                (click)="paymentMethod = 'boleto'; selectPaymentMethod()"
                                *ngIf="event.paymentMethodsStrings.includes('boleto') && enabledBoleto"
                            >
                                <img src="./assets/icons/registration/ic-boleto.svg" class="mr-8" />
                                {{ "PAYMENT.BOLETO" | translate | uppercase }}
                                <svg
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    class="ml-32"
                                >
                                    <g id="Registration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g
                                            id="Event---Inscrição-Time"
                                            transform="translate(-489.000000, -1705.000000)"
                                            fill="#004CFF"
                                            fill-rule="nonzero"
                                        >
                                            <g id="Group-21" transform="translate(205.000000, 1634.000000)">
                                                <g id="Group-19" transform="translate(20.000000, 55.000000)">
                                                    <g id="Group-15" transform="translate(18.000000, 14.000000)">
                                                        <g id="verified" transform="translate(246.000000, 2.000000)">
                                                            <path
                                                                d="M20.4852656,3.5146875 C18.2187656,1.24823438 15.2053125,0 12,0 C8.79464062,0 5.7811875,1.24823438 3.5146875,3.5146875 C1.24823437,5.7811875 0,8.7946875 0,12 C0,15.2053125 1.24823437,18.2187656 3.5146875,20.4852656 C5.7811875,22.7517656 8.7946875,24 12,24 C15.2053125,24 18.2187656,22.7517656 20.4852656,20.4853125 C22.7517656,18.2187656 24,15.2053125 24,12 C24,8.7946875 22.7517656,5.78123438 20.4852656,3.5146875 Z M18.6984172,8.43996374 L10.8630775,16.7994987 C10.7026629,16.9706441 10.4924098,17.0561875 10.2822114,17.0561875 C10.0719582,17.0561875 9.86170504,16.9706441 9.70129047,16.7994987 L5.30158279,12.1055356 C4.98075365,11.7633034 4.98075365,11.2083812 5.30158279,10.8660321 C5.62235715,10.5237414 6.14254068,10.5237414 6.46336982,10.8660321 L10.2822114,14.9402726 L17.5366302,7.20046018 C17.8574045,6.85816952 18.3775881,6.85816952 18.6984172,7.20046018 C19.0192463,7.54275085 19.0192463,8.09767308 18.6984172,8.43996374 Z"
                                                                id="Shape"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>

                        <div fxLayout="column" *ngIf="paymentMethod === 'credit_card'">
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" *ngIf="installments.length > 1">
                                <mat-form-field fxFlex="30" fxFlex.xs="100" class="pr-8">
                                    <mat-select placeholder="{{ 'PAYMENT.FORM.INSTALLMENTS' | translate }}" formControlName="installments">
                                        <mat-option *ngFor="let installment of installments" [value]="installment">
                                            {{ installment }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
                                <mat-form-field
                                    fxFlex="50"
                                    fxFlex.xs="100"
                                    class="pr-8"
                                    [hideRequiredMarker]="paymentMethod !== 'credit_card'"
                                >
                                    <input
                                        matInput
                                        type="tel"
                                        placeholder="{{ 'PAYMENT.FORM.CARD_NUMBER' | translate }}"
                                        formControlName="creditCard"
                                        [errorStateMatcher]="matchers.creditCard"
                                        [mask]="maskCard"
                                        autocomplete="off"
                                        required
                                    />
                                    <img
                                        matSuffix
                                        src="./assets/icons/card/ic-{{ cardType }}.svg"
                                        *ngIf="
                                            cardType !== '' &&
                                            cardType !== 'unknown' &&
                                            !registrationForm.controls.creditCard.hasError('invalid')
                                        "
                                        height="14"
                                    />
                                    <mat-error *ngIf="registrationForm.controls.creditCard.hasError('required')">
                                        {{ "PAYMENT.VALIDATIONS.CREDIT_CARD_REQUIRED" | translate }}
                                    </mat-error>
                                    <mat-error *ngIf="registrationForm.controls.creditCard.hasError('invalid')">
                                        {{ "PAYMENT.VALIDATIONS.CREDIT_CARD_INVALID" | translate }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field
                                    fxFlex="25"
                                    fxFlex.xs="100"
                                    class="pl-8 pr-8"
                                    [hideRequiredMarker]="paymentMethod !== 'credit_card'"
                                >
                                    <mat-label>{{ "PAYMENT.FORM.EXPIRATION_DATE" | translate }}</mat-label>
                                    <input
                                        matInput
                                        placeholder="mm/aa"
                                        formControlName="expirationCard"
                                        [errorStateMatcher]="matchers.expirationCard"
                                        mask="00/00"
                                        autocomplete="off"
                                        required
                                    />
                                    <mat-icon matSuffix>date_range</mat-icon>
                                    <mat-error *ngIf="registrationForm.controls.expirationCard.hasError('required')">
                                        {{ "PAYMENT.VALIDATIONS.EXPIRATION_REQUIRED" | translate }}
                                    </mat-error>
                                    <mat-error
                                        *ngIf="
                                            !registrationForm.controls.expirationCard.hasError('required') &&
                                            registrationForm.controls.expirationCard.hasError('invalid')
                                        "
                                    >
                                        {{ "PAYMENT.VALIDATIONS.DATE_INVALID" | translate }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field
                                    fxFlex="25"
                                    fxFlex.xs="100"
                                    class="pl-8"
                                    [hideRequiredMarker]="paymentMethod !== 'credit_card'"
                                >
                                    <input
                                        matInput
                                        maxlength="4"
                                        placeholder="{{ 'PAYMENT.FORM.CVV' | translate }}"
                                        formControlName="cvv"
                                        [errorStateMatcher]="matchers.cvv"
                                        autocomplete="off"
                                        uppercase
                                        required
                                    />
                                    <mat-error *ngIf="registrationForm.controls.cvv.hasError('required')">
                                        {{ "PAYMENT.VALIDATIONS.CVV_REQUIRED" | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div fxLayout="column">
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="mt-4">
                                <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8">
                                    <input
                                        matInput
                                        placeholder="{{ 'PAYMENT.FORM.NAME_COMPLETED' | translate }}"
                                        formControlName="holdName"
                                        [errorStateMatcher]="matchers.holdName"
                                        autocomplete="off"
                                        uppercase
                                        required
                                    />
                                    <mat-error *ngIf="registrationForm.controls.holdName.hasError('required')">
                                        {{ "PAYMENT.VALIDATIONS.HOLD_NAME_REQUIRED" | translate }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8" *ngIf="paymentMethod === 'boleto'">
                                    <input
                                        matInput
                                        placeholder="{{ 'PAYMENT.FORM.CPF' | translate }}"
                                        formControlName="cpfCard"
                                        [errorStateMatcher]="matchers.cpfCard"
                                        mask="000.000.000-00"
                                        autocomplete="off"
                                        required
                                    />
                                    <mat-error *ngIf="registrationForm.controls.cpfCard.hasError('required')">
                                        {{ "PAYMENT.VALIDATIONS.HOLD_CPF_REQUIRED" | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <ng-container *ngIf="paymentMethod === 'boleto' && event.default_gateway_id === 2">
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="mt-4">
                                    <mat-form-field fxFlex="30" fxFlex.xs="100" class="pr-8">
                                        <input
                                            matInput
                                            placeholder="{{ 'PAYMENT.FORM.CEP' | translate }}"
                                            formControlName="cep"
                                            mask="00000-000"
                                            [errorStateMatcher]="matchers.cep"
                                            autocomplete="off"
                                            uppercase
                                            required
                                        />
                                        <mat-error *ngIf="registrationForm.controls.cep.hasError('required')">
                                            {{ "PAYMENT.VALIDATIONS.CEP_REQUIRED" | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="mt-4">
                                    <mat-form-field fxFlex="80" fxFlex.xs="100" class="pr-8">
                                        <input
                                            matInput
                                            placeholder="{{ 'PAYMENT.FORM.ADDRESS' | translate }}"
                                            formControlName="address"
                                            [errorStateMatcher]="matchers.address"
                                            autocomplete="off"
                                            required
                                        />
                                        <mat-error *ngIf="registrationForm.controls.address.hasError('required')">
                                            {{ "PAYMENT.VALIDATIONS.ADDRESS_REQUIRED" | translate }}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex="20" fxFlex.xs="100" class="pl-8">
                                        <input
                                            #addressNumber
                                            matInput
                                            placeholder="{{ 'PAYMENT.FORM.ADDRESS_NUMBER' | translate }}"
                                            formControlName="addressNumber"
                                            [errorStateMatcher]="matchers.addressNumber"
                                            autocomplete="off"
                                            uppercase
                                            required
                                        />
                                        <mat-error *ngIf="registrationForm.controls.addressNumber.hasError('required')">
                                            {{ "PAYMENT.VALIDATIONS.ADDRESS_NUMBER_REQUIRED" | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="mt-4">
                                    <mat-form-field fxFlex="40" fxFlex.xs="100" class="pr-8">
                                        <input
                                            matInput
                                            placeholder="{{ 'PAYMENT.FORM.NEIGHBORHOOD' | translate }}"
                                            formControlName="neighborhood"
                                            [errorStateMatcher]="matchers.neighborhood"
                                            autocomplete="off"
                                            required
                                        />
                                        <mat-error *ngIf="registrationForm.controls.neighborhood.hasError('required')">
                                            {{ "PAYMENT.VALIDATIONS.NEIGHBORHOOD_REQUIRED" | translate }}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex="40" fxFlex.xs="100" class="pl-8 pr-8">
                                        <input
                                            matInput
                                            placeholder="{{ 'PAYMENT.FORM.CITY' | translate }}"
                                            formControlName="city"
                                            [errorStateMatcher]="matchers.city"
                                            autocomplete="off"
                                            uppercase
                                            required
                                        />
                                        <mat-error *ngIf="registrationForm.controls.city.hasError('required')">
                                            {{ "PAYMENT.VALIDATIONS.CITY_REQUIRED" | translate }}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex="20" fxFlex.xs="100" class="pl-8">
                                        <input
                                            matInput
                                            placeholder="{{ 'PAYMENT.FORM.STATE' | translate }}"
                                            formControlName="state"
                                            [errorStateMatcher]="matchers.state"
                                            autocomplete="off"
                                            uppercase
                                            required
                                        />
                                        <mat-error *ngIf="registrationForm.controls.state.hasError('required')">
                                            {{ "PAYMENT.VALIDATIONS.STATE_REQUIRED" | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </form>

                <div fxLayout="row" fxLayoutAlign="end center" class="pr-32 pb-32" *ngIf="isShowPaymentForm">
                    <btn type="grayscale" (click)="isShowPaymentForm = false" class="mr-20">{{
                        "PAYMENT.BUTTONS_DIALOG.CANCEL" | translate | uppercase
                    }}</btn>
                    <btn [loading]="isLoadingForm" [buttonDisabled]="registrationForm.invalid" (click)="prepareSubscription()">{{
                        "PAYMENT.SEND_PAYMENT" | translate
                    }}</btn>
                </div>
                <!-- END PAYMENTS -->
            </div>
        </div>
    </div>
</div>
