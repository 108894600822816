<div class="page-layout blank pb-64" id="scrollBar">
  <div class="p-32">
    <!-- CARD CATEGORIES -->
    <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'" *ngIf="tabIndexActive === 0">
      <!-- PLACEHOLDER NOT CATEGORIES -->
      <div fxLayout="column" fxLayoutAlign="center center" class="p-32 emtpy-content" *ngIf="isCategoryEmpty">
        <img src="./assets/icons/placeholder/ic-empty.svg" />
        <span class="mt-8">{{ 'CAT_LOT_WOD.CATEGORY.EMPTY' | translate }}</span>
      </div>

      <table-x #tableCompetition [items]="competitions" [config]="tableCatConfig" [isLoading]="isLoadingCategories" tableName="categories" [isDraggable]="true" (reorderEvent)="reorderAction($event)" *ngIf="!isCategoryEmpty">
        <column property="name" header="{{ 'CAT_LOT_WOD.CATEGORY.TABLE.NAME' | translate }}" [sortable]="false" align="left">
          <ng-template #dataTableCell let-item="item">
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon class="mr-8 menu-reorder">menu</mat-icon>
              <a (click)="editCategory(item)" class="small-font">
                {{ item.name | uppercase }}
              </a>
            </div>
          </ng-template>
        </column>
      
        <column property="result" header="{{ 'CAT_LOT_WOD.CATEGORY.TABLE.LIMIT' | translate }}" [sortable]="false" align="center">
          <ng-template #dataTableCell let-item="item">
            <span *ngIf="!item.enable_sub_division" class="hide-in-drag">{{ (item.limit) ? item.solded : '' }} {{ (item.limit) ? ' / ' : '' }} {{ (item.limit) ? item.limit : 'ILIMITADO' }}</span>
          </ng-template>
        </column>
      
        <column property="type" header="{{ 'CAT_LOT_WOD.CATEGORY.TABLE.TYPE' | translate }}" [sortable]="false" align="center">
          <ng-template #dataTableCell let-item="item">
            <label-x type="normal" class="hide-in-drag">
              {{ ((item.team) ? 'CAT_LOT_WOD.CATEGORY.TABLE.TEAM' : 'CAT_LOT_WOD.CATEGORY.TABLE.SINGLE') | translate | uppercase }}
            </label-x>
          </ng-template>
        </column>
        
        <column property="actions" header="" [sortable]="false" align="center" width="100px">
          <ng-template #dataTableCell let-item="item">
            <btn-transparent icon="common/ic-settings.svg" buttonWidth="35px" iconAlign="left" [matMenuTriggerFor]="resultActionMenu"
              [matMenuTriggerData]="{ competition: item }" class="hide-in-drag"></btn-transparent>
          </ng-template>
        </column>
      </table-x>

      <!-- MAT-MENU ACTIONS -->
      <mat-menu class="submenu bottom arrow-right" #resultActionMenu="matMenu" [overlapTrigger]="false" xPosition="before">
        <ng-template matMenuContent let-competition="competition">
          <button mat-menu-item class="line-bottom" (click)="deleteCategory(competition)">
            <span class="text-red">{{ 'CAT_LOT_WOD.DELETE' | translate }}</span>
          </button>
        </ng-template>
      </mat-menu>

      <button mat-fab (click)="createCategory()">
        <mat-icon class="mat-30">add</mat-icon>
      </button>
    </div>
  </div>
</div>
