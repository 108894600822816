import { LeaderboardEventComponent } from './leaderboard-event.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { AgmCoreModule } from '@agm/core';

const routes = [
  {
    path: 'leaderboard',
    component: LeaderboardEventComponent,
    // canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    LeaderboardEventComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot(),
  ],
  exports: [
    LeaderboardEventComponent
  ]
})
export class LeaderboardEventModule { }
