import { BtnTransparentComponent } from './btn-transparent.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material';
import { MaterialModule } from '../../modules/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    imports: [
      CommonModule,
      MatProgressSpinnerModule,
      MaterialModule,
      FlexLayoutModule
    ],
    declarations: [
      BtnTransparentComponent
    ],
    exports: [
      BtnTransparentComponent,
      MaterialModule,
      FlexLayoutModule
    ],
    providers: [
    ]
})
export class BtnTransparentModule { }
