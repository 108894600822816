<div
  [ngClass]="{ 'card auto-width pt-16 pb-16 mr-16': true, sticky: isSticky }"
>
  <div fxLayout="column" fxLayoutAlign="flex-start" class="m-32 mt-0 mb-0">
    <description-section
      title="{{
        'HEAT_HEATCOMPETITORS.FILTER_COMPETITORS.COMPETITORS' | translate
      }}"
    ></description-section>
    <form
      class="mt-16"
      name="eventForm"
      [formGroup]="eventForm"
      autocomplete="off"
      novalidate
      fxFlex="100"
      fxFlex.xs="100"
      fxLayout="column"
      fxLayoutAlign="flex-start"
    >
      <mat-form-field fxFlex="100" fxFlex.xs="100">
        <mat-select
          matInput
          placeholder="{{
            'HEAT_HEATCOMPETITORS.FILTER_COMPETITORS.FIELDS.COMPETITIONS'
              | translate
          }}"
          formControlName="competitions"
        >
          <mat-option
            *ngFor="let competition of competitions"
            [value]="competition?.id"
          >
            {{ competition.name }}
          </mat-option>
        </mat-select>

        <mat-error
          *ngIf="eventForm.controls.competitions.hasError('required')"
        >
          {{
            "HEAT_HEATCOMPETITORS.FILTER_COMPETITORS.VALIDATIONS.COMPETITIONS_REQUIRED"
              | translate
          }}
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100" fxFlex.xs="100">
        <mat-select
          matInput
          placeholder="{{
            'HEAT_HEATCOMPETITORS.FILTER_COMPETITORS.FIELDS.WODS' | translate
          }}"
          formControlName="wods"
        >
          <mat-option *ngFor="let wod of wods" [value]="wod?.id">
            {{ wod?.name }}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="eventForm?.controls?.wods?.hasError('required')">
          {{
            "HEAT_HEATCOMPETITORS.FILTER_COMPETITORS.VALIDATIONS.WODS_REQUIRED"
              | translate
          }}
        </mat-error>
      </mat-form-field>

      <mat-form-field
        fxFlex="100"
        fxFlex.xs="100"
        floatLabel="never"
        class="search"
      >
        <mat-icon matPrefix class="icon-search">search</mat-icon>
        <input
          matInput
          placeholder="{{
            'HEAT_HEATCOMPETITORS.FILTER_COMPETITORS.FIELDS.SEARCH'
              | translate
          }}"
          formControlName="search"
        />
      </mat-form-field>
    </form>
  </div>
  <div *ngIf="dataFilterCompetitors" class="mt-16">
    <table-x
      #tableCompetition
      [isLoading]="isLoading"
      [items]="dataFilterCompetitors"
      [config]="tableCatConfig"
      [isDraggable]="true"
      [tableName]="'DRAGULA_FACTS'"
      [externalId]="'filter'"
    >
      <column property="item" header="" [sortable]="false" align="left" width="50px" classes="lanes">
        <ng-template #dataTableCell let-item="item">
          <mat-icon class="mr-8 menu-reorder">menu</mat-icon>
        </ng-template>
      </column>
      
      <column
        property="item"
        header="{{ 'HEAT_HEATCOMPETITORS.TABLE.ID' | translate }}"
        [sortable]="false"
        align="left"
        classes="lanes"
      >
        <ng-template #dataTableCell let-item="item">
          <span class="hide-in-drag">{{ item?.team?.id }}</span>
        </ng-template>
      </column>
      <column
        property="item"
        header="{{ 'HEAT_HEATCOMPETITORS.TABLE.NAME' | translate }}"
        [sortable]="false"
        align="left"
      >
        <ng-template #dataTableCell let-item="item">
          <span class="hide-in-drag team-name">{{ item?.team?.name }}</span>
          <span class="hide-in-drag box-name">{{
            item?.team?.box?.name
          }}</span>
        </ng-template>
      </column>
      <column
        property="item"
        header="{{ 'HEAT_HEATCOMPETITORS.TABLE.RANK' | translate }}"
        [sortable]="false"
        align="left"
      >
        <ng-template #dataTableCell let-item="item">
          <span class="hide-in-drag">{{ item?.rank }}</span>
        </ng-template>
      </column>
    </table-x>
  </div>
</div>
