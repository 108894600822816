<div mat-dialog-content class="dialog-container">
  <div class="modal-background" (click)="dialogRef.close()"></div>
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>{{ translate.TITLE | uppercase }} {{ result?.name }}</h1>
      <span class="close" (click)="dialogRef.close()">
        <img src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>

    <div fxLayout="column" class="mt-8" *ngIf="urlVideo">
      <div class="video-container">
        <iframe width="560" height="315" [src]="urlVideo" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
      </div>
    </div>
    
    <form name="resultVideoForm" [formGroup]="resultVideoForm" autocomplete="off" novalidate>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="mt-24">
        <mat-form-field fxFlex="49.5">
          <input matInput placeholder="{{ translate.URL_VIDEO }}" formControlName="video" required>
          <mat-error *ngIf="resultVideoFormErrors.video.required">
            {{ translate.URL_REQUIRED }}
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="49.5">
          <mat-select placeholder="{{ translate.STATUS.TITLE }}" formControlName="status" required>
            <mat-option *ngFor="let status of statusList" [value]="status.value">
              {{ status.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>

    <div class="mb-0 mt-8" fxLayoutAlign="end center">
      <btn [btnLoading]="true" [loading]="isLoading" [buttonDisabled]="resultVideoForm.invalid" (click)="updateVideo()">{{ translate.SAVE | uppercase }}</btn>
    </div>
  </div>
</div>