import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Utils} from '../../../../../../core/utils';
import {MatDialogRef, MatIconRegistry} from '@angular/material';
import {FuseTranslationLoaderService} from '../../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BoxService} from '../../../../../../core/services/box.service';
import {UserService} from '../../../../../../core/services/user/user.service';
import {CompetitorService} from '../../../../../../core/services/competitor/competitor.service';
import {locale as english} from '../i18n/en-US';
import {locale as portugues} from '../i18n/pt-BR';
import {Subscription} from 'rxjs';
import {Heat} from '../../../../../../core/model/heat.model';
import {FormErrorStateMatcher} from '../../../../../../core/utils/form-error-state-matcher';
import {Errors} from '../../../../../../core/model/errors';
import {HeatsService} from '../../../../../../core/services/heats/heats.service';
import {ToastrService} from '../../../../../../core/components/toastr_/toastr/toastr.service';

@Component({
  selector: 'app-edit-heat-name-number',
  templateUrl: './edit-heat-name-number.component.html',
  styleUrls: ['./edit-heat-name-number.component.scss']
})
export class EditHeatNameNumberComponent implements OnInit {

  private subscriptions: Subscription = new Subscription();

  // translate
  translate: any;

  public heat: Heat;

  heatFormGroup: FormGroup;
  matchers: any;
  
  isLoading: boolean = false;
  requestErrors: string = null;


  constructor(
    public dialogRef: MatDialogRef<EditHeatNameNumberComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private heatService: HeatsService,
    private toastr: ToastrService,
  ) {
    //Icon Info
    iconRegistry.addSvgIcon(
      'ic-cat-info',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/category/ic-cat-info.svg')
    );
  }

  ngOnInit() {
    this.registerOnTranslateHeatCompetitors();
    this.createForm();
    this.registerOnSaveHeats();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  private createForm(): void {
    this.heatFormGroup = this.formBuilder.group({
      heatName: [this.heat.name, Validators.required],
      heatNumber: [this.heat.number, Validators.required],
    });


    this.matchers = {
      heatName: new FormErrorStateMatcher(),
      heatNumber: new FormErrorStateMatcher()
    };

  }
  private registerOnTranslateHeatCompetitors(): void {

    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService
      .get('HEAT_COMPETITORS')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);
  }

  saveHeat() {
    // validate all form fields
    if (!this.heatFormGroup.valid) {
      Utils.formValid(this.heatFormGroup);
      return;
    }
    
    this.heat.name = this.heatFormGroup.get('heatName').value.toUpperCase();
    this.heat.number = this.heatFormGroup.get('heatNumber').value;
    
    if (this.heat.id === null) {
      this.toastr.show(
        this.translate.HEAT_EDIT.TOASTR.SUCCESS.DESCRIPTION,
        this.translate.HEAT_EDIT.TOASTR.SUCCESS.TITLE,
        null,
        'success'
      )
      this.dialogRef.close(this.heat)
      return;
    }

    let parameters: any[] = [];

    const heatSendAPI = {
      id: this.heat.id,
      championship_id: this.heat.championship_id,
      name: this.heat.name,
      number: this.heat.number,
      total_lanes: this.heat.total_lanes,
      type: this.heat.type,
      due_date: this.heat.due_date,
      start_time: this.heat.start_time,
      end_time: this.heat.end_time,
    };
    parameters.push(heatSendAPI);
    
    console.log(parameters)
    this.isLoading = true;
    this.heatService.updateHeatsWithSchedule(parameters, false)
  }
  
  private registerOnSaveHeats(): void {
    const subscription = this.heatService
      .heatsUpdateScheduleObservable()
      .subscribe((heats: Heat[]) => {
          console.log(heats)
          this.isLoading = false;
          
          this.toastr.show(
            this.translate.HEAT_EDIT.TOASTR.SUCCESS.DESCRIPTION,
            this.translate.HEAT_EDIT.TOASTR.SUCCESS.TITLE,
            null,
            'success'
          )

          this.dialogRef.close(this.heat)
        },
        err => {
          console.log("err", err);
          this.isLoading = false;
          const errors: Errors = new Errors().deserialize((err.error as any))
          this.requestErrors = errors.getFullMessages();
        }
      );
    this.subscriptions.add(subscription);
  }
}