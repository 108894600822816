import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {locale as english} from './i18n/en-US';
import {locale as portugues} from './i18n/pt-BR';
import {Subscription} from 'rxjs';
import {Event} from '../../../../../core/model/event';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {BreadcrumbsService} from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import {FuseTranslationLoaderService} from '../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthGuard} from '../../../../../core/guards/auth.guard';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatDialogRef, MatIconRegistry} from '@angular/material';
import {ToastrService} from '../../../../../core/components/toastr_/toastr/toastr.service';
import {FilterPipe} from 'ngx-filter-pipe';
import {CompetitionService} from '../../../../../core/services/competition/competition.service';
import {LotService} from '../../../../../core/services/lot/lot.service';
import {WodService} from '../../../../../core/services/wod/wod.service';
import {config} from '../../../../../core/config/config';
import {TableConfig, TableXComponent} from '../../../../../core/components/table-x/table-x.component';
import {Competition} from '../../../../../core/model/competition';
import {ModalWodComponent} from '../category-lot-wod/modal-wod/modal-wod.component';
import {Wod} from '../../../../../core/model/wod';
import {CrossXDialogComponent} from '../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import {Errors} from '../../../../../core/model/errors';
import {ModalWodCopyComponent} from '../category-lot-wod/modal-wod-copy/modal-wod-copy.component';
import {Utils} from '../../../../../core/utils';
import {SubDivision} from '../../../../../core/model/sub-division';
import {SubDivisionHttpService} from '../../../../../core/services/sub-division/sub-division-http.service';

@Component({
  selector: 'app-wods',
  templateUrl: './wods.component.html',
  styleUrls: ['./wods.component.scss']
})
export class WodsComponent implements OnInit, OnDestroy {

  @ViewChildren('tableWod') tablesWod: QueryList<TableXComponent>;
  
  private subscriptions: Subscription = new Subscription();

  // Translate
  public translate: any = null;
  public competitions: Competition[] = [];
  private championship: any = null;
  private championshipId: number = null;
  private event: Event = null;
  
  public wod: any;
  public wodId: number;

  public tableCatConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: [
      {
        type: 'reproved',
        title: 'Reprovar'
      }
    ],
    massActionCountTitle: {
      single: 'resultado selecionado',
      multiple: 'resultados selecionados'
    }
  };
  
  public isLoadingWods = false;
  public isWodsEmpty = false;
  public isCategoryEmpty = false;

  // sub-division
  private subDivisions: SubDivision[];

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private filterPipe: FilterPipe,
    private zone: NgZone,
    private changeDetectorRefs: ChangeDetectorRef,
    private competitionService: CompetitionService,
    private lotService: LotService,
    private wodService: WodService,
    private readonly subDivisionHttpService: SubDivisionHttpService
  ) {
    this.resgisterSubDivision();
  }

  ngOnInit() {
    this.registerOnTranslate();
    this.registerOnActiveRouter();
    this.getCompetitions();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getCompetitions(): void {
    this.isLoadingWods = true;
    this.subscriptions.add(this.competitionService.getCompetitions(this.championshipId)
      .subscribe(
        result => {
          console.log('result', result);
          this.competitions = result;
          this.isLoadingWods = false;
          this.isCategoryEmpty = this.competitions.length === 0;
        }, err => {
          console.log('err', err);
          this.isLoadingWods = false;
          this.isCategoryEmpty = true;
        }
      ));
  }

  // WODS
  public addWodInCompetition(competition: Competition) {
    const modalRef = this.dialog.open(ModalWodComponent, {
      width: '816px',
      height: '100%',
      panelClass: 'modal'
    });

    modalRef.componentInstance.championship = this.championship;
    modalRef.componentInstance.competition = competition;
    modalRef.componentInstance.allCompetitions = this.competitions;
    modalRef.componentInstance.subDivisions = this.subDivisions;

    this.subscriptions.add(modalRef.afterClosed().subscribe(result => {
      if (result) {
        this.competitions.forEach(c => {
          result.wods.forEach(wod => {
            if (wod.competition && wod.competition.id === c.id) {
              c.wods.push(wod);
            }
          });
        });

        if (this.tablesWod) {
          this.tablesWod.forEach(tableWod => {
            tableWod.reloadItems();
          });
        }

        this.toastr.show(
          this.translate.TOASTR.CREATE.DESCRIPTION,
          this.translate.TOASTR.CREATE.TITLE,
          null,
          'success',
        );
      }
    }));
  }

  public editWodOfCompetition(wod: Wod, competition: Competition, index: number) {
    const modalRef = this.dialog.open(ModalWodComponent, {
      width: '816px',
      height: '100%',
      panelClass: 'modal'
    });

    modalRef.componentInstance.championship = this.championship;
    modalRef.componentInstance.competition = competition;
    modalRef.componentInstance.allCompetitions = this.competitions;
    modalRef.componentInstance.isEdit = true;
    modalRef.componentInstance.wod = wod;
    modalRef.componentInstance.subDivisions = this.subDivisions;

    this.subscriptions.add(modalRef.afterClosed().subscribe(result => {
      if (result && result.isEdit) {
        wod = result.wod;
        competition.wods[index] = wod;
        if (this.tablesWod) {
          this.tablesWod.forEach(tableWod => {
            tableWod.reloadItems();
          });
        }

        this.toastr.show(
          this.translate.TOASTR.EDIT.DESCRIPTION,
          this.translate.TOASTR.EDIT.TITLE,
          null,
          'success',
        );
      }
    }));
  }

  public deleteWod(wod: Wod, competition: Competition, index: number) {
    console.log(wod, competition, index);
    if (!wod.canEditHeat) {
      this.toastr.show(
        this.translate.TOASTR.DELETE.ERROR_DELETE_DESCRIPTION_HEAT,
        this.translate.TOASTR.DELETE.ERROR_DELETE_TITLE,
        null,
        'error',
      );
      return;
    }
    if (wod.results > 0) {
      this.toastr.show(
        this.translate.TOASTR.DELETE.ERROR_DELETE_DESCRIPTION,
        this.translate.TOASTR.DELETE.ERROR_DELETE_TITLE,
        null,
        'error',
      );
      return;
    }


    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    dialogRef.componentInstance.titleDialog = this.translate.DIALOG.TITLE;
    this.subscriptions.add(this.translateService.get('WODS.DIALOG.DESCRIPTION', { wod_name: wod.name }).subscribe((res: string) => {
      dialogRef.componentInstance.dialogContent = res;
    }));
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.DIALOG.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.DIALOG.EXCLUIR.toUpperCase();
    dialogRef.componentInstance.object = wod;
    dialogRef.componentInstance.confirmActionRequest = true;
    this.subscriptions.add(dialogRef.componentInstance.dialogEvent.subscribe(result => {
      dialogRef.componentInstance.confirmLoading = true;
      this.deleteWodInAPI(wod, competition, index, dialogRef);
      // console.log(result)
    }));
  }

  private deleteWodInAPI(wod: Wod, competition: Competition, index: number, dialogRef: MatDialogRef<CrossXDialogComponent>) {
    this.subscriptions.add(this.wodService.delete(wod.id)
      .subscribe(
        response => {
          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();

          competition.wods.splice(index, 1);
          if (this.tablesWod) {
            this.tablesWod.forEach(tableWod => {
              tableWod.reloadItems();
            });
          }

          this.toastr.show(
            this.translate.TOASTR.DELETE.DESCRIPTION,
            this.translate.TOASTR.DELETE.TITLE,
            null,
            'success',
          );
        }, err => {
          console.log('err', err);
          const errors: Errors = new Errors().deserialize((err.error as any));

          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();

          this.toastr.show(
            errors.getFullMessages(),
            this.translate.TOASTR.DELETE.ERROR_DELETE_TITLE,
            null,
            'error',
          );
        }
      ));
  }

  public copyWods(competition: Competition) {

    const modalRef = this.dialog.open(ModalWodCopyComponent, {
      width: '816px',
      height: '100%',
      panelClass: 'modal'
    });

    modalRef.componentInstance.competition = competition;
    modalRef.componentInstance.allCompetitions = this.competitions;

    this.subscriptions.add(modalRef.afterClosed().subscribe(result => {
      if (result) {
        this.competitions.forEach(competition => {
          result.competitions.forEach(competitionUpdate => {
            if (competition.id === competitionUpdate.id) {
              competition.wods = competitionUpdate.wods;
            }
          });
        });

        if (this.tablesWod) {
          this.tablesWod.forEach(tableWod => {
            tableWod.reloadItems();
          });
        }

        this.toastr.show(
          this.translate.TOASTR.COPY.DESCRIPTION,
          this.translate.TOASTR.COPY.TITLE,
          null,
          'success',
        );
      }
    }));
  }
  
  public reorderAction(orders: any, parms: any) {
    console.log('reorder', orders);

    if (orders.type === 'reorder') {
      if (orders.tableName.indexOf('wods-') > -1) {
        const parameters = {
          wods: orders.data
        };

        this.subscriptions.add(this.wodService.massUpdate(parameters)
          .subscribe(
            result => {
              console.log('result', result);
            }, err => {
              console.log('err', err);
            }
        ));
      }
    }
  }

  public formatUnitType(unitTypeId: number): string {
    return Utils.getUnitPerId(unitTypeId, this.translateService.currentLang).name;
  }

  private registerOnTranslate(): void {

    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService
      .get('WODS')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);

  }

  private registerOnActiveRouter(): void {
    this.subscriptions.add(this.activatedRoute.params.subscribe(
      params => {
        this.championshipId = params.championship_id;
        
        this.event = new Event().deserialize(this.eventGuard.getActiveEvent().event);
        this.championship = this.eventGuard.getChampionshipPerId(this.championshipId);
        if (this.championship.enable_sub_division) {
          this.fetchSubDivision(this.championship.id);
        }
        // this.competitions = this.championship.competitions

        if (this.translate) {
          this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE.toUpperCase()}`);

          this.breadcrumbsService.replaceAll([{
            text: this.translate.TITLE.toUpperCase(),
            route: `/championship/wods/${this.championshipId}`
          }]);
        }
      }
    )); // end router
  }

  private fetchSubDivision(id: number): void {
    this.subDivisionHttpService.fetchSubDivision(id);
  }

  private resgisterSubDivision(): void {
    const subscription = this.subDivisionHttpService
      .subDivisionObservable()
      .subscribe((results: SubDivision[]) => {
        this.subDivisions = results;
      });

    this.subscriptions.add(subscription);
  }
}
