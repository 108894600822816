import { Directive, EventEmitter, HostListener, Output, ElementRef, Renderer } from '@angular/core';

@Directive({
  selector: '[removeWhitespace]'
})
export class InputRemoveWhitespaceDirective {
  value: any;

  constructor(
    private renderer: Renderer,
    private elementRef: ElementRef
  ){}

  @HostListener('input', ['$event']) onInputChange($event) {
    this.value = $event.target.value.replace(' ', '');
    this.renderer.setElementProperty(this.elementRef.nativeElement, 'value', this.value);
  }
}
