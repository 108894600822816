export const locale = {
  lang: 'pt-BR',
  data: {
    'IDENTIFICATION': {
      'DATE_FORMAT_COMPLETE': 'DD [de] MMMM [de] YYYY',
      'DATETIME_FORMAT': 'DD/MM/YYYY [às] HH:mm',
      'TABLE_HEADER': {
        'COMPETITION': 'Categoria',
        'PRICE': 'Valor',
        'TAX': 'Taxa',
        'DISCOUNT': 'Desconto',
        'TOTAL_PRICE': 'Valor total'
      },
      'CURRENCY': 'R$',
      'CURRENCY_CODE': 'BRL',
      'IDENTIFY': 'Identificação',
      'IDENTIFY_DESCRIPTION': 'Faça o seu login ou cadastre-se.',
      'LOGIN': 'Já tenho cadastro',
      'GO_REGISTER': 'Não tenho cadastro',
      'EMAIL': 'E-mail',
      'PASSWORD': 'Senha',
      'FORGOT_PASSWORD': 'Esqueceu a senha?',
      'ENTER': 'Entrar',
      'REGISTER': 'Cadastrar',
      'VALIDATIONS': {
        'EMAIL_REQUIRED': 'O e-mail é obrigatório',
        'EMAIL_INVALID': 'Formato de e-mail inválido',
        'EMAIL_EXIST': 'E-mail já cadastrado, faça o login',
        'PASSSWORD_REQUIRED': 'A senha é obrigatória',
        'LOGIN_ERROR': 'Usuário ou senha incorretos'
      },
      'FORGOT': {
        'SUCCESS_TITLE': 'Sucesso!',
        'SUCCESS_DESCRIPTION': 'As instruções para a redefinir a senha foram enviadas para o e-mail <strong>{{email}}</strong>',
        'ERROR_TITLE': 'Erro!',
        'ERROR_DESCRIPTION': 'Tente novamente',
        'ERROR_EMAIL_NOT_FOUND': 'E-mail não encontrado, utilizar a opção <strong>"Não tenho cadastro"</strong>',
      },
      'SUBSCRIPTION': {
        'TITLE': 'Inscrição encontrada',
        'DESCRIPTION': 'Você já iniciou a inscrição neste evento, deseja continuar a mesma inscrição?',
        'DESCRIPTION_COMPLETED': 'Você já se inscreveu nesta etapa'
      }
    }
  }
};
