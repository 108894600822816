<div class="page-layout blank pb-64">
  <div class="p-32">
    <div class="content">
      <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
        <div
          class="p-32 pt-16"
          fxLayout="column"
          fxLayoutAlign="stretch stretch"
        >
          <div
            fxFlex="100"
            class="box-line p-16 mt-16 mb-16"
            *ngFor="let championship of championships; let i = index"
          >
            <div fxLayout="row" fxLayoutAlign="strech start">
              <div fxLayout="column" fxFlex="auto">
                <div
                  fxFlex="100"
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start center"
                  fxLayoutAlign.xs="start stretch"
                >
                  <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-16">
                    <input
                      matInput
                      [disabled]="isDisabledSteps"
                      placeholder="{{
                        'SETTINGS.STEPS.NAME_STEP' | translate
                      }} {{ i + 1 }}"
                      [ngModel]="championship.name"
                      (ngModelChange)="championship.name = $event.toUpperCase()"
                      uppercase
                      required
                    />
                  </mat-form-field>
                  <div fxLayout="column">
                    <div class="mt-16">
                        <mat-slide-toggle
                          fxFlex="auto"
                          [disabled]="isDisabledSteps"
                          [checked]="championship.online ? true : false"
                          [ngModel]="championship.online"
                          (ngModelChange)="championship.online = $event"
                          [fxShow]="event.enabled_leaderboard"
                        >
                          Online
                        </mat-slide-toggle>
                    </div>
                    <div class="mt-16">
                        <mat-slide-toggle
                          fxFlex="auto"
                          [disabled]="isDisabledSteps"
                          [checked]="championship.enable_sub_division ? true : false"
                          [ngModel]="championship.enable_sub_division"
                          (ngModelChange)="championship.enable_sub_division = $event"
                          [fxShow]="event.enabled_leaderboard"
                        >
                          {{ "SETTINGS.STEPS.ENABLE_SUB_DIVISION" | translate }}
                        </mat-slide-toggle>
                    </div>
                  </div>
                </div>

                <mat-error
                  *ngIf="
                    championship.error !== null && championship.error.length > 0
                  "
                  class="mb-8"
                >
                  {{ championship.error }}
                </mat-error>

                <div
                  fxFlex="100"
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  [fxShow]="event.enabled_leaderboard"
                >
                  {{ "SETTINGS.STEPS.RANKING_TYPE" | translate }}

                  <mat-radio-group
                    class="ml-32"
                    [disabled]="isDisabledSteps"
                    [(ngModel)]="championship.type"
                  >
                    <mat-radio-button [value]="1" class="mr-32">{{
                      "SETTINGS.STEPS.RANK" | translate
                    }}</mat-radio-button>
                    <mat-radio-button [value]="3">{{
                      "SETTINGS.STEPS.POINTS" | translate
                    }}</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <btn-transparent
                fxFlex="35px"
                materialIcon="delete"
                buttonWidth="35px"
                iconAlign="left"
                (click)="removeChampionship(i)"
                *ngIf="!championship.id"
              ></btn-transparent>
            </div>
          </div>

          <div fxFlex="100" class="mt-8 mb-16" *ngIf="!isDisabledSteps">
            <btn-icon-small
              icon="add_circle_outline"
              iconAlign="left"
              (click)="addChampionship()"
              >{{ "SETTINGS.STEPS.NEW" | translate }}</btn-icon-small
            >
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="end center"
            class="mt-32"
            *ngIf="!isDisabledSteps"
          >
            <btn
              [btnLoading]="true"
              [loading]="isLoadingSteps"
              (click)="saveChampionships()"
              class="ml-16"
              >{{ "SETTINGS.SAVE" | translate }}</btn
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
