export const locale = {
  lang: 'en-US',
  data: {
    'SUBSCRIPTIONS': {
      'TITLE': 'Inscrições',
      'SEARCH': 'Procure pelo nome do inscrito',
      'CHAMPIONSHIPS': 'Etapas',
      'ALL': 'Todas',
      'TABLE_HEADER': {
        'CHAMPIONSHIP': 'Etapa',
        'BUYER': 'Comprador',
        'LOT': 'Ingresso',
        'DATE_PAYMENT': 'Data de pagamento',
        'DATE_RECEIVE': 'Data de recebimento',
        'COUPON': 'Cupom',
        'GROSS_VALUE': 'Valor bruto',
        'TAX': 'Taxa',
        'NET_VALUE': 'Valor líquido',
        'STATUS': 'Status',
        'TRANSACTION_ID': 'ID da transação',
        'PAYMENT_METHOD': 'Método de pagamento'
      },
      'PAYMENT_METHOD': {
        'CREDIT_CARD': 'Cartão de crédito',
        'BOLETO': 'Boleto'
      },
      'EXPORT': 'Exportar',
      'EMPTY': 'Não tem nenhuma inscrição',
      'DATE_FORMAT': 'dd/MM/yyyy',
      'CURRENCY': 'R$',
      'CURRENCY_CODE': 'BRL',
      'REFUND': 'Estornar',
      'CONFIRMED_EMAIL': 'Reenviar e-mail de confirmação',
      'DIALOG': {
        'REFUND_CONFIRM': {
          'TITLE': 'Estornar inscrição',
          'DESCRIPTION': 'Tem certeza que deseja estornar a inscrição do <strong>{{ user_name }}</strong>?<br><br>Após executada essa ação não poderá ser desfeita.',
          'REFUND': 'Estornar',
          'CANCEL': 'Cancelar',
        },
        'RESEND_EMAIL': {
          'TITLE': 'Reenviar e-mail de confirmação',
          'DESCRIPTION': 'Tem certeza que deseja reenviar o e-mail de confirmação da inscrição de <strong>{{ user_name }}</strong>?',
          'SEND': 'Enviar',
          'CANCEL': 'Cancelar',
        }
      },
      'TOASTR': {
        'REFUND': {
          'ERROR': {
            'TITLE': 'Erro ao efetuar o estorno!',
            'DESCRIPTION': 'Só é possível fazer estorno em pagamentos efetuados com menos de 28 dias'
          },
          'SUCCESS': {
            'TITLE': 'Estorno efetuado com sucesso!',
            'DESCRIPTION': 'O valor já foi estornado para o comprador'
          }
        },
        'RESEND_EMAIL': {
          'ERROR': {
            'TITLE': 'Erro ao enviar e-mail!',
            'DESCRIPTION': 'Ocorreu um erro ao enviar o e-mail. Tente novamente.'
          },
          'SUCCESS': {
            'TITLE': 'E-mail enviado com sucesso!',
            'DESCRIPTION': 'O e-mail com a confirmação da inscrição foi enviado.'
          }
        }
      }
    }
  }
};

