import { RegistrationModule } from './registration/registration.module';
import { RegistrationIdentificationModule } from './registration-identification/registration-identification.module';
import { EventPageComponent } from './event-page.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../../../../environments/environment';
import { WorkoutComponent } from './workout/workout.component';
import { HeatsComponent } from './heats/heats.component';
import { HeatsModule } from '../championship/heats/heats.module';

const routes = [
  {
    path: 'event',
    component: EventPageComponent,
    // canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    EventPageComponent,
    WorkoutComponent,
    HeatsComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    }),
    RegistrationIdentificationModule,
    RegistrationModule,
    HeatsModule
  ],
  exports: [
    EventPageComponent
  ]
})
export class EventPageModule {
}
