import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Subscription} from 'rxjs';
import {Event} from '../../../../../core/model/event';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {BreadcrumbsService} from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import {FuseTranslationLoaderService} from '../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthGuard} from '../../../../../core/guards/auth.guard';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {MatDialog, MatDialogRef, MatIconRegistry} from '@angular/material';
import {FormBuilder} from '@angular/forms';
import {GooglePlacesService} from '../../../../../core/services/google-places.service';
import {EventsService} from '../../../../../core/services/event/events.service';
import {ChampionshipService} from '../../../../../core/services/championship/championship.service';
import {ToastrService} from '../../../../../core/components/toastr_/toastr/toastr.service';
import {BankAccountService} from '../../../../../core/services/bank-account/bank-account.service';
import {BanksService} from '../../../../../core/services/banks/banks.service';
import {EventConfigurationsService} from '../../../../../core/services/event-configurations/event-configurations.service';
import {locale as english} from '../i18n/en-US';
import {locale as portugues} from '../i18n/pt-BR';
import {config} from '../../../../../core/config/config';
import {CrossxAppService} from '../../../../../core/services/crossx-app/crossx-app.service';
import {CrossxAppUrl} from '../../../../../core/model/crossx-app-url.model';
import {TableConfig, TableXComponent} from '../../../../../core/components/table-x/table-x.component';
import {CreateCrossxAppUrlComponent} from './create-crossx-app-url/create-crossx-app-url.component';
import {CrossXApp} from '../../../../../core/model/crossx-app.model';
import {CrossXDialogComponent} from '../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import {Errors} from '../../../../../core/model/errors';

@Component({
  selector: 'app-crossx-app',
  templateUrl: './crossx-app.component.html',
  styleUrls: ['./crossx-app.component.scss']
})
export class CrossxAppComponent implements OnInit {

  
  @ViewChild('table', { read: TableXComponent }) table: TableXComponent;
  private subscriptions: Subscription = new Subscription();

  // translate
  public translate: any;

  //Event
  public event: Event = null
  public crossxApp: CrossXApp = null;
  public crossxAppUrls: CrossxAppUrl[] = [];

  tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: [],
    massActionCountTitle: null
  }

  public isLoading: boolean = false;
  public isCrossXAppLoading: boolean = false;

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private googlePlacesService: GooglePlacesService,
    public dialog: MatDialog,
    private eventService: EventsService,
    private championshipService: ChampionshipService,
    private toastr: ToastrService,
    private bankAccountService: BankAccountService,
    private banksService: BanksService,
    private eventConfigurationService: EventConfigurationsService,
    private crossxAppService: CrossxAppService,
  ) { }

  ngOnInit() {
    this.registerOnTranslate();
    this.registerOnGetCrossXAppUrlObservable();
    this.getEvent();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
  
  public createUrl() {
    const modalRef = this.dialog.open(CreateCrossxAppUrlComponent, {
      width: '450px',
      height: '100%',
      panelClass: 'modal'
    });

    modalRef.componentInstance.event = this.event
    modalRef.componentInstance.crossXApp = this.crossxApp
    modalRef.componentInstance.order = this.crossxAppUrls.length

    modalRef.afterClosed().subscribe(result => {
      if (result && result.url) {
        this.isLoading = true;
        this.crossxAppService.fetchCrossXUrlWithEvent(this.event.id);
      }
    });
  }
  
  public deleteUrl(url: CrossxAppUrl, index: number): void
  {
    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning'
    dialogRef.componentInstance.titleDialog = this.translate.DIALOG.DELETE.TITLE
    this.translateService.get('CROSSX_APP.DIALOG.DELETE.DESCRIPTION', { url_title: url.title }).subscribe((res: string) => {
      dialogRef.componentInstance.dialogContent = res
    });
    dialogRef.componentInstance.dialogConfirm = true
    dialogRef.componentInstance.cancelButton = this.translate.CANCEL.toUpperCase()
    dialogRef.componentInstance.actionButton = this.translate.DELETE.toUpperCase()
    dialogRef.componentInstance.object = url
    dialogRef.componentInstance.confirmActionRequest = true
    dialogRef.componentInstance.dialogEvent.subscribe(result => {
      dialogRef.componentInstance.confirmLoading = true
      this.deleteUrlInAPI(url, index, dialogRef)
      // console.log(result)
    })
  }
  
  private deleteUrlInAPI(url: CrossxAppUrl, index: number, dialogRef: MatDialogRef<CrossXDialogComponent>): any {
    this.crossxAppService.deleteCrossXAppUrl(url.id)
      .subscribe(
        response => {
          dialogRef.componentInstance.confirmLoading = false
          dialogRef.close()

          this.crossxAppUrls.splice(index, 1);
          if(this.table) this.table.reloadItems()

          this.toastr.show(
            this.translate.TOASTR.DELETE.SUCCESS.DESCRIPTION,
            this.translate.TOASTR.DELETE.SUCCESS.TITLE,
            null,
            'success',
          )
        }, err => {
          console.log("err", err);
          const errors: Errors = new Errors().deserialize((err.error as any))

          dialogRef.componentInstance.confirmLoading = false
          dialogRef.close()

          this.toastr.show(
            errors.getFullMessages(),
            this.translate.TOASTR.DELETE.ERROR,
            null,
            'error',
          )
        }
      )
  }


  public reorderAction(orders: any, parms: any) {
    console.log('reorder', orders)

    if (orders.type === 'reorder') {
        let parameters = {
          urls: orders.data
        }
        
        this.crossxAppService.massUpdateCrossXAppUrl(this.crossxApp.id, parameters)
        .subscribe(
          result => {
            console.log("result", result);
          }, err => {
            console.log("err", err);
          }
        )
    }
  }

  public publishOrUnpublishCrossXApp(action: string): void {
    if (action === 'publish' && !this.event.published) {
      this.toastr.show(
        this.translate.TOASTR.EVENT_NOT_PUBLISH.DESCRIPTION,
        this.translate.TOASTR.EVENT_NOT_PUBLISH.TITLE,
        null,
        'error',
      )
      return;
    }
    
    this.isCrossXAppLoading = true;
    const subscription = this.crossxAppService.publishOrUnpublishCrossXApp(this.crossxApp.id, action)
      .subscribe(
        result => {
          console.log("result", result);

          this.isCrossXAppLoading = false;
          this.crossxApp.published = action === 'publish';
          const title = this.crossxApp.published ? this.translate.TOASTR.PUBLISH.SUCCESS.TITLE : this.translate.TOASTR.UNPUBLISH.SUCCESS.TITLE;
          const description = this.crossxApp.published ? this.translate.TOASTR.PUBLISH.SUCCESS.DESCRIPTION : this.translate.TOASTR.UNPUBLISH.SUCCESS.DESCRIPTION;
          this.toastr.show(
            description,
            title,
            null,
            'success',
          )
        }, err => {
          console.log("err", err);
          
          this.isCrossXAppLoading = false;
          const errors: Errors = new Errors().deserialize((err.error as any))
          const title = this.crossxApp.published ? this.translate.TOASTR.PUBLISH.ERROR : this.translate.TOASTR.UNPUBLISH.ERROR;
          this.toastr.show(
            errors.getFullMessages(),
            title,
            null,
            'error',
          )
        }
      )
    this.subscriptions.add(subscription)
  }

  private registerOnGetCrossXAppUrlObservable() {
    const subscription = this.crossxAppService
      .fetchCrossXUrlObservable()
      .subscribe((crossXApp: CrossXApp) => {
        console.log(crossXApp)
        this.crossxApp = crossXApp;
        this.crossxAppUrls = crossXApp.urls;
        if(this.table) this.table.reloadItems()
        this.isLoading = false;
      });
    this.subscriptions.add(subscription);
  }
  
  private registerOnTranslate(): void {

    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService
      .get('CROSSX_APP')
      .subscribe((response: Object) => {
        this.translate = response;

        this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE}`)

        this.breadcrumbsService.replaceAll([{
          text: this.translate.TITLE,
          route: `/event/crossx-app`
        }])
      });

    this.subscriptions.add(subscription);

  }

  private getEvent(): void {
    this.event = new Event().deserialize(this.eventGuard.getActiveEvent().event)
    this.isLoading = true;
    this.crossxAppService.fetchCrossXUrlWithEvent(this.event.id);
  }
}
