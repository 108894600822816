<!-- Empty list -->
<div fxLayout="column" class="main-schedule-cards">
  <div class="card auto-width mb-32 mb-0" fxFlex="100" *ngIf="heats.length === 0">
    <div fxLayout="column" class="pt-32 pb-32">
      <div fxLayout="column" fxLayoutAlign="center center" class="p-32 emtpy-content">
        <img src="./assets/icons/placeholder/ic-empty.svg" />
        <span class="mt-8">{{ 'HEAT_COMPETITORS.HEATS_SCHEDULE.EMPTY' | translate }}</span>
      </div>
    </div>
    <fuse-btn-mat-fab (clickType)="clickType($event)" [listMatFab]="btnMatFabConfig"></fuse-btn-mat-fab>
  </div>

  <div [ngClass]="{ 'card auto-width mb-32 mb-0': true, 'stickyForm': sticky }" fxFlex="100" *ngIf="heats.length > 0">
    <div fxLayout="column"[ngClass]="{ 'pt-28 pb-24': !sticky, 'pt-16 pb-0': sticky }">
  
      <form
        class="mb-16 pl-32 pr-32"
        name="eventForm"
        [formGroup]="eventForm"
        (ngSubmit)="onSubmitGenerate()"
        autocomplete="off"
        novalidate
      >
        <div
          fxFlex="100"
          fxFlex.xs="100"
          fxLayout="row"
          fxLayoutAlign="stretch flex-end"
        >
          <mat-form-field class="mr-16">
            <input
              matInput
              min="0"
              type="number"
              formControlName="transition"
              placeholder="{{ 'HEAT_COMPETITORS.HEATS_SCHEDULE.FIELDS.TRANSITION' | translate }}"
            />
            <mat-error *ngIf="eventForm.controls.transition.hasError('required')">
              {{ "HEAT_COMPETITORS.HEATS_SCHEDULE.VALIDATIONS.TRANSITION_REQUIRED" | translate }}
            </mat-error>
            <mat-error *ngIf="eventForm.controls.transition.hasError('min')">
              {{ "HEAT_COMPETITORS.HEATS_SCHEDULE.VALIDATIONS.TRANSITION_MIN" | translate }}
            </mat-error>
          </mat-form-field>
          <div class="mb-16">
            <btn
              [buttonDisabled]="eventForm.invalid || heatsSelected.length === 0"
            >
              {{ "HEAT_COMPETITORS.HEATS_SCHEDULE.SUBMIT_GENERATE_SCHEDULE" | translate | uppercase }}
            </btn>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="p-32" *ngIf="sticky">
    <div class="p-32 m-32">
    </div>
  </div>
  <div class="card auto-width mb-32 mb-0" fxFlex="100" *ngIf="heats.length > 0">
    <div class="main-table">
      <table>
        <thead>
          <tr>
            <th
              width="60"
              class="checkbox pl-32"
            >
              <mat-checkbox (change)="selectedAllCheckbox($event)"></mat-checkbox>
            </th>
            <th width="50" class="menu-reorder"></th>
            <th style="text-align: center">
              {{ 'HEAT_COMPETITORS.HEATS_SCHEDULE.TABLE.HEAT' | translate }}
            </th>
            <th>
              {{ 'HEAT_COMPETITORS.HEATS_SCHEDULE.TABLE.NAME' | translate }}
            </th>
            <th style="text-align: center">
              {{ 'HEAT_COMPETITORS.HEATS_SCHEDULE.TABLE.TIME_CAP' | translate }}
            </th>
            <th style="text-align: center">
              {{ 'HEAT_COMPETITORS.HEATS_SCHEDULE.TABLE.START_TIME' | translate }}
            </th>
            <th style="text-align: center">
              {{ 'HEAT_COMPETITORS.HEATS_SCHEDULE.TABLE.END_TIME' | translate }}
            </th>
            <th style="text-align: center">
              {{ 'HEAT_COMPETITORS.HEATS_SCHEDULE.TABLE.TRANSITION' | translate }}
            </th>
            <th 
              class="pr-32"
              style="text-align: center"
            >  
            </th>
          </tr>
        </thead>
        <tbody
          [dragula]="tableName"
        >
          <ng-container *ngFor="let item of heats">
            <tr
              [attr.item-number]="item.number"
            >
              <td class="checkbox pl-32">
                <mat-checkbox class="hide-in-drag" [checked]="getSelected(item.idRandom)" (change)="checkboxAction($event, item.idRandom)"></mat-checkbox>
              </td>
              <td width="50" style="min-width: 50px;">
                <mat-icon [ngClass]="{ 'menu-reorder' : !isLoadingSaveHeats}">menu</mat-icon>
              </td>
              <td align="center">
                {{ item.number }}
              </td>
              <td>
                <div class="hide-in-drag" fxLayout="row" fxLayoutAlign="start center">
                  <ng-container *ngIf="!item.isEditName.edit">
                    {{ item.name }}
                  </ng-container>
                  <mat-form-field style="width: 100px" fxFlex="auto" class="pr-8" *ngIf="item.isEditName.edit">
                    <input
                      [disabled]="isLoadingSaveHeats"
                      [formControl]="item.isEditName.form"
                      matInput
                      uppercase
                      required />
                      <mat-error *ngIf="item.isEditName.form.hasError('required')">
                        {{ 'HEAT_COMPETITORS.HEAT_EDIT.FORM.ERROR.LANES_REQUIRED' | translate }}
                      </mat-error>
                  </mat-form-field>
                  <btn-transparent
                    materialIcon="create"
                    buttonWidth="25px"
                    iconAlign="left"
                    class="ml-8 hide-in-drag"
                    isSmall="true"
                    *ngIf="!item.isEditName.edit"
                    (click)="item.isEditName.edit = !item.isEditName.edit"
                  ></btn-transparent>
                  <btn-transparent
                    materialIcon="done"
                    buttonWidth="25px"
                    iconAlign="left"
                    classInside="small"
                    class="ml-8 hide-in-drag"
                    isSmall="true"
                    *ngIf="item.isEditName.edit"
                    (click)="editHeatName(item)"
                  ></btn-transparent>
                </div>
              </td>
              <td>
                <input
                [disabled]="exitCompetitor(item) || isLoadingSaveHeats"
                type="text"
                class="input hide-in-drag"
                mask="00:00"
                value="{{ formatTime(item.duration / 60) }}"
                (change)="updateHeatInput($event, item, 3)"
                dropSpecialCharacters="false" />
              </td>
              <td>
                <input
                type="text"
                class="input hide-in-drag"
                [disabled]="isLoadingSaveHeats || isEditStartTime(item)"
                value="{{ item?.start_time | customDateHeatPipe:'HH:mm': true }}"
                mask="00:00"
                placeholder="{{'HEAT_COMPETITORS.HEATS_SCHEDULE.TABLE.START_TIME' | translate}}"
                dropSpecialCharacters="false"
                (change)="updateHeatInput($event, item, 1)" />
              </td>
              <td>
                <input
                type="text"
                class="input hide-in-drag"
                [disabled]="true"
                value="{{ item?.end_time | customDateHeatPipe:'HH:mm':true }}"
                mask="00:00"
                dropSpecialCharacters="false" />
              </td>
              <td>
                <input
                *ngIf="enableTransition(item)"
                type="text"
                class="input hide-in-drag"
                [disabled]="isLoadingSaveHeats"
                value="{{ formatTime(item?.transition) }}"
                mask="00:00"
                dropSpecialCharacters="false"
                (change)="updateHeatInput($event, item, 2)" />
              </td>
              <td align="center">
                <ng-container *ngIf="item.number > 1">
                  <btn-transparent
                    class="hide-in-drag"
                    [matMenuTriggerData]="{ item: item }"
                    color="#2c4151"
                    buttonWidth="35px"
                    [matMenuTriggerFor]="configSchedule">
                    <img src="./assets/icons/common/ic-settings.svg" />
                  </btn-transparent>
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <fuse-btn-mat-fab (clickType)="clickType($event)" [listMatFab]="btnMatFabConfig"></fuse-btn-mat-fab>
  </div>
</div>

<!-- MAT-MENU OPTIONS disqualified -->
<mat-menu class="submenu user-menu bottom arrow-right hide-in-drag" #configSchedule="matMenu" [overlapTrigger]="false" xPosition="before">
  <ng-template matMenuContent let-item="item" var-timeCapOption="timeCapOption">
    <button *ngIf="!item.enable_custom_start_time" class="line-bottom mat-menu-item" (click)="editStartTime(item, true)">
      <span>{{'HEAT_COMPETITORS.HEATS_SCHEDULE.TABLE.EDIT_START_TIME' | translate }}</span>
    </button>
    <button *ngIf="item.enable_custom_start_time" class="line-bottom mat-menu-item" (click)="editStartTime(item, false)">
      <span>{{'HEAT_COMPETITORS.HEATS_SCHEDULE.TABLE.DISABLE_EDIT_START_TIME' | translate }}</span>
    </button>
  </ng-template>
</mat-menu>