import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { FuseTranslationLoaderService } from '../../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Ranking } from '../../../../../../core/model/ranking';
import { TableConfig, TableXComponent } from '../../../../../../core/components/table-x/table-x.component';
import { CompetitorService } from '../../../../../../core/services/competitor/competitor.service';
import { RankingService } from '../../../../../../core/services/ranking/ranking.service';
import { Errors } from '../../../../../../core/model/errors';
import { ToastrService } from '../../../../../../core/components/toastr_/public_api';

@Component({
  selector: 'fuse-app-modal-reorder-athletes',
  templateUrl: './modal-reorder-athletes.component.html',
  styleUrls: ['./modal-reorder-athletes.component.scss']
})
export class ModalReorderAthletesComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();
  @ViewChild('tableList', { read: TableXComponent }) tableList: TableXComponent;
  public rankingReorder: Array<Ranking[]>;
  public championshipId: number;
  public competitionId: number;
  btnLoading = false;
  btnDisable = true;
  requestErrors: string;

  tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: [
      {
        type: 'reproved',
        title: 'Reprovar'
      }
    ],
    massActionCountTitle: {
      single: 'resultado selecionado',
      multiple: 'resultados selecionados'
    }
  };

  // Translate
  translate: any;
  

  constructor(
    public dialogRef: MatDialogRef<ModalReorderAthletesComponent>,
    private readonly translationLoader: FuseTranslationLoaderService,
    private readonly translateService: TranslateService,
    private readonly competitorService: CompetitorService,
    private readonly rankingService: RankingService,
    private readonly toastr: ToastrService
  ) {
    this.registerTranslate();
  }

  ngOnInit() {
    this.rankingReorder.forEach((rankings, index) => {
      rankings.forEach((ranking, i) => {
        this.rankingReorder[index][i]['id'] = this.rankingReorder[index][i].competitor_id;
        this.rankingReorder[index][i]['tiebreak_order'] = i;
      });
    });
    if (this.tableList) {
      this.tableList.reloadItems();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private registerTranslate(): void {
    this.translationLoader
      .loadTranslations(english, portugues);

    const subscription = this.translateService
      .get('MODAL_REORDER_ATHLETES')
      .subscribe((res: any) => {
        this.translate = res;
      });

    this.subscriptions
      .add(subscription);
  }
  
  public reorderAction(orders: any): void {
    if (orders.type === 'reorder') {
      if (orders.tableName.indexOf('rankings-') > -1) {
        orders.data.forEach(({ id, order }) => {
          this.rankingReorder.forEach((ranking, i) => {
            const index = ranking.findIndex((filterRankingIndex: Ranking) => filterRankingIndex.competitor_id === Number(id));
            if (index > -1) {
              this.btnDisable = false;
              this.rankingReorder[i][index].competitor.tiebreak_order = order;
            }
          });
        });
      }
    }
  }

  public saveOrder(): void {
    this.btnLoading = true;
    const parameters = {
      competitors: []
    };
    this.rankingReorder.forEach((ranking) => {
      ranking.forEach(({competitor_id, competitor }) => {
        parameters.competitors.push({ id: competitor_id, tiebreak_order: competitor.tiebreak_order });
      });
    });
    this.subscriptions.add(this.competitorService
      .massUpdateCompetitor(parameters)
      .subscribe((data) => {
        this.btnLoading = false;
        this.subscriptions.add(this.rankingService
          .getRanking(this.championshipId, this.competitionId)
          .subscribe(({ athletes }) => {
              this.btnLoading = false;
              this.toastr
                .show(
                  '',
                  this.translate.TOASTR.SUCCESS.TITLE,
                  null,
                  'success',
                );
              this.dialogRef.close({ isUpdate: true , data: athletes });
            }, err => {
              const errors: Errors = new Errors().deserialize((err.error as any));
              this.requestErrors = errors.getFullMessages();
              this.btnLoading = false;
            }
          ));
      }, err => {
        const errors: Errors = new Errors().deserialize((err.error as any));
        this.requestErrors = errors.getFullMessages();
        this.btnLoading = false;
      }));
  }
}
