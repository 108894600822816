export const locale = {
  lang: 'pt-BR',
  data: {
    'USER': {
      'SAVE': 'Salvar',
      'CANCEL': 'Cancelar',
      'DATE_FORMAT': 'dd/MM/yyyy',
      'LEADERBOARD_NAME': 'Nome no leaderboard',
      'NUMBER': 'Número',
      'GENDER': 'Sexo',
      'BIRTHDATE': 'Data de nascimento',
      'BOX': 'Box',
      'CITY': 'Cidade',
      'CPF': 'CPF',
      'SIZE_TSHIRT': 'Tamanho da camiseta',
      'PHONE': 'Telefone',
      'CATEGORY': 'Categoria',
      'SUB_DIVISION': 'Sub-categorias',
      'PAYMENTS': 'Pagamentos',
      'TABLE_HEADER': {
        'PAYMENT_DATE': 'Data pagto',
        'PAYMENT_METHOD': 'Forma de pagamento',
        'PAYMENT_DELIVERY': 'Data do recebimento',
        'VALUE': 'Valor',
        'STATUS': 'Status',
        'PAYMENT_ID': 'ID da transação'
      },
      'VALIDATIONS': {
        'CATEGORY_REQUIRED': 'A categoria é obrigatória',
        'NUMBER_REQUIRED': 'O número é obrigatório.',
        'NUMBER_LIMIT': 'O número não pode ser menor que {{ custom_number }}.',
        'SUB_DIVISION_REQUIRED': 'A sub-categoria é obrigatória',
        'LEADERBOARDNAME_REQUIRED': 'O nome no leaderboard é obrigatória',
      },
      'INACTIVE': 'Inativar',
      'ACTIVE': 'Ativar',
      'CONFIRM_STATUS_INACTIVE': 'Tem certeza que deseja inativar?',
      'CONFIRM_STATUS_ACTIVE': 'Tem certeza que deseja ativar?',
      'PAYMENT_METHOD': {
        'CREDIT_CARD': 'Cartão de crédito',
        'BOLETO': 'Boleto'
      },
      'PAYMENT_STATUS': {
        'WAITING': 'Aguardando resposta',
        'APPROVED': 'Aprovado',
        'REFUSED': 'Recusado',
        'REFUNDED': 'Estornado',
        'CHARGEBACK': 'Chargeback'
      },
      'DISQUALIFIED' : {
        'CUT': 'Cortar',
        'TYPE_CUT': '(CUT)',
        'WD': 'Desclassificar',
        'TYPE_WD': '(WD)',
        'REMOVE': 'Cancelar',
        'SUCCESS': 'Atualizado com sucesso! Para postar acesse resultados e publique.',
        'MESSAGE': 'Tem certeza que deseja {{ custom_field_name }} {{ custom_field_type }} este competidor?',
        'CANCEL': 'Cancelar',
        'CONFIRM': 'Confirmar'
      },
      'CURRENCY': 'R$',
      'CURRENCY_CODE': 'BRL',
      'TOASTR': {
        'INACTIVE': {
          'TITLE': 'Usuário inativado com sucesso',
          'DESCRIPTION': 'Você inativou o usuário'
        },
        'ACTIVE': {
          'TITLE': 'Usuário ativado com sucesso',
          'DESCRIPTION': 'Você ativado o usuário'
        },
        'UPDATE': {
          'TITLE': 'Participante atualizado com sucesso!',
          'DESCRIPTION': ''
        },
      }
    }
  }
};
