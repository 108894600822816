import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({
  name: 'customDateHeatPipe',
  pure: false
})

export class CustomDateHeatPipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private _locale: string) {

  }

  transform(value: any, ...args: any[]): string {

    if (!value) {
      return '-';
    }

    if (args[1]) {
      const datetime = moment(`2018-01-01 ${value}`, 'YYYY-MM-DD HH:mm:ss');
      return datetime.format(args[0]);
    }
    
    return moment(value).format(args[0]);
  }

}