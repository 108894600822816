<div class="page-layout blank pb-64" id="scrollbar">
  <div class="p-32">
    <div class="content">
      <div class="card auto-width mb-32" *ngIf="isLoadingWods">
        <div class="pt-16" fxLayout="column">
          <div fxLayoutAlign="center center" class="p-64" >
            <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
          </div>
        </div>
      </div>
      <!-- PLACEHOLDER NOT CATEGORIES -->
      <div class="card auto-width mb-32" *ngIf="competitions.length === 0 && !isLoadingWods">
        <div class="pt-16" fxLayout="column">
          <div fxLayout="column" fxLayoutAlign="center center" class="p-32 emtpy-content">
            <img src="./assets/icons/placeholder/ic-empty.svg" />
            <span class="mt-8">{{ 'WODS.CONTENT_EMPTY' | translate }}</span>
          </div>
        </div>
      </div>

      <div class="card auto-width mb-32" *ngFor="let competition of competitions; index as i;">
        <div class="pt-16" fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="space-between center" class="pl-32 pr-32 pb-16 mb-4 mt-0 line-bottom">
            <div fxLayout="row" fxLayoutAlign="start center">
              <h2 class="m-0 mr-4">{{ competition.name | uppercase }}</h2>
              <!-- <img src="./assets/icons/common/ic-info.svg" matTooltip="teste" matTooltipPosition="right" matTooltipClass="right" /> -->
            </div>
            <button fxLayout="row" fxLayoutAlign="start center" (click)="copyWods(competition)" class="button-copy pl-8 pr-8"
                    *ngIf="competitions && competitions.length > 1 && competition.wods && competition.wods.length > 0">
              <img src="./assets/icons/common/ic-copy.svg" class="mr-8" />
              {{ 'WODS.COPY' | translate | uppercase }}
            </button>
          </div>

          <!-- PLACEHOLDER NOT WOD -->
          <div fxLayout="column" fxLayoutAlign="center center" class="p-32 emtpy-content-lot" *ngIf="!competition.wods || competition.wods.length === 0">
            <img src="./assets/icons/category/ic-lot-placeholder.svg" class="mb-16" />
            {{ 'WODS.CONTENT_EMPTY' | translate }}
            <btn (click)="addWodInCompetition(competition)" class="mt-8">{{ 'WODS.ADD' | translate | uppercase }}</btn>
          </div>

          <table-x #tableWod [items]="competition.wods" [config]="tableCatConfig" [isLoading]="isLoadingWods" tableName="wods-{{i}}" [isDraggable]="true" (reorderEvent)="reorderAction($event)" *ngIf="competition.wods && competition.wods.length > 0">
            <column property="name" header="{{ 'WODS.TABLE.NAME' | translate }}" [sortable]="false" align="left">
              <ng-template #dataTableCell let-item="item" let-index="index">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon class="mr-8 menu-reorder">menu</mat-icon>
                  <a (click)="editWodOfCompetition(item, competition, index)" class="small-font">
                    {{ item.name | uppercase }}
                  </a>
                </div>
              </ng-template>
            </column>

            <column property="price" header="{{ 'WODS.TABLE.RESULT' | translate }}" [sortable]="false" align="center" width="150px">
              <ng-template #dataTableCell let-item="item">
                <span class="hide-in-drag">{{ item.wod_type ? formatUnitType(item.wod_type.id) : '' }}</span>
              </ng-template>
            </column>

            <column property="start" header="{{ 'WODS.TABLE.TIEBREAK' | translate }}" [sortable]="false" align="center" width="150px">
              <ng-template #dataTableCell let-item="item">
                <span class="hide-in-drag">{{ (item.tiebreak_type) ? formatUnitType(item.tiebreak_type.id) : '' }}</span>
              </ng-template>
            </column>

            <column property="actions" header="" [sortable]="false" align="center" width="100px">
              <ng-template #dataTableCell let-item="item" let-index="index">
                <btn-transparent icon="common/ic-settings.svg" buttonWidth="35px" iconAlign="left" [matMenuTriggerFor]="wodsActionMenu" class="hide-in-drag"
                                 [matMenuTriggerData]="{ competition: competition, wod: item, index: index }"></btn-transparent>
              </ng-template>
            </column>
          </table-x>

          <div class="m-32 mt-16 mb-16" *ngIf="competition.wods && competition.wods.length > 0">
            <btn-icon-small icon="add_circle_outline" iconAlign="left" (click)="addWodInCompetition(competition)">{{
              'WODS.ADD_NEW' | translate }}</btn-icon-small>
          </div>
        </div>
      </div>
      <!-- MAT-MENU ACTIONS -->
      <mat-menu class="submenu bottom arrow-right" #wodsActionMenu="matMenu" [overlapTrigger]="false" xPosition="before">
        <ng-template matMenuContent let-competition="competition" let-wod="wod" let-index="index">
          <button mat-menu-item class="line-bottom" (click)="deleteWod(wod, competition, index)">
            <span class="text-red">{{ 'WODS.DELETE' | translate }}</span>
          </button>
        </ng-template>
      </mat-menu>
    </div>
  </div>
</div>

<!--<btn-fab [loading]="isLoadingPublish" [btnLoading]="true" materialIcon="publish" (click)="publishRanking()" *ngIf="!isResultPublised"></btn-fab>-->