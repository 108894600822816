import { MaterialModule } from './../../../modules/material.module';
import { TableXRowComponent } from './table-x-row.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  declarations: [
    TableXRowComponent
  ],
  exports: [
    TableXRowComponent,
    MaterialModule
  ],
  providers: [
  ]
})
export class TableXRowModule { }
