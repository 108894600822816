import { TableXColumnComponent } from './table-x-column/table-x-column.component';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewEncapsulation,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { ReoderEvent } from './model/reoder-event.model';
import { Heat } from '../../../main/content/pages/championship/heats/model/heat.model';

@Component({
  selector: 'table-x',
  templateUrl: './table-x.component.html',
  styleUrls: ['./table-x.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableXComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() items: any[] = [];
  @Input() config: TableConfig = new TableConfig();
  @ContentChildren(TableXColumnComponent) columns: QueryList<TableXColumnComponent>;
  @Input() isDraggable = false;
  @Input() tableName: string = null;
  @Input() isLoading = false;
  @Input() isHeaderLoading = false;
  @Input() isHeader = true;
  @Input() totalCount: number = null;
  @Input() externalId = '';
  @Input() externalIndex: number;
  @Input() generateDropzones = false;
  @Input() dropzonesQuantity: number;
  @Input() dropzonesText: string;
  @Input() showMassActions = true;

  @Output() massActionEvent = new EventEmitter();
  @Output() reorderEvent = new EventEmitter();
  @Output() rowActionEvent = new EventEmitter();
  @Output() sortEvent = new EventEmitter<ReoderEvent>();
  // @ViewChildren(TableXRowComponent) rows: QueryList<TableXRowComponent>;
  @Output() allSelectedTable = new EventEmitter<Heat[]>();

  allSelected = false;
  itemsSelected: any[] = [];

  sortOrder: 'ASC' | 'DESC' = 'ASC';
  sortActive: string = null;

  subs = new Subscription();

  dropzones: Array<DropzonePlaceholderModel> = new Array<DropzonePlaceholderModel>();

  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    private dragulaService: DragulaService
  ) {

  }
  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.subs.unsubscribe();
    this.dragulaService.destroy(this.tableName);
    this.reorderEvent.unsubscribe();
    this.sortEvent.unsubscribe();
  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    // this.columns.forEach(alertInstance => console.log(alertInstance));
    // console.log("columns", this.columns)
  }

  // MASS Actions
  massSelectedAction(massAction: TableMassAction) {
    this.massActionEvent.emit({
      type: 'massAction',
      action: massAction
    });
  }

  // Checkbox
  allCheckboxAction(event) {
    this.allSelected = event.checked;
    const cloned = this.items.map(x => (<any>Object).assign({}, x));
    // new Array<any>(this.items)
    this.itemsSelected = (event.checked) ? cloned : [];
    this.allSelectedTable.emit(this.itemsSelected);
  }

  checkboxAction(event) {
    const array = this.itemsSelected.map(function (element, i) {
      if (element.id === event.row.id) {
        return {exist: true, index: i};
      }
    });
    const arrayFilter = array.filter(function (element) {
      return element !== undefined;
    })[0];

    const isExist = (arrayFilter && arrayFilter !== undefined && arrayFilter.exist !== false) ? arrayFilter : null;
    // console.log("isExist", isExist, "checked", event.checked, "porra", (isExist !== null))

    if (event.checked) {
      if (isExist === null) {
        this.itemsSelected.push(event.row);
      }
    } else {
      if (isExist !== null) {
        this.itemsSelected.splice(isExist.index, 1);
      }
    }

    // master checkbox
    if (this.itemsSelected.length === 0) {
      this.allSelected = false;
    } else if (this.itemsSelected.length === this.items.length) {
      this.allSelected = true;
    }

    this.allSelectedTable.emit(this.itemsSelected);

  }

  // Row Event
  rowEvent(event) {
    console.log('row event', event.type);
    if (event.type === 'checkbox') {
      this.checkboxAction(event);
    }
  }

  // Sort
  sortClicked(name) {
    // console.log(name)

    if (this.sortActive === name) {
      this.sortActive = name;

      if (this.sortOrder === 'ASC') {
        this.sortOrder = 'DESC';
      } else if (this.sortOrder === 'DESC') {
        this.sortActive = null;
      }
      // this.sortOrder = (this.sortOrder === 'ASC') ? 'DESC' : 'ASC'
    } else {
      this.sortOrder = 'ASC';
      this.sortActive = name;
    }


    this.sortEvent.emit({
      type: 'sort',
      order: this.sortOrder,
      sort: this.sortActive
    });
  }

  resetSort() {
    this.sortActive = null;
    this.sortOrder = 'ASC';
  }

  trackByRow(index, item) {
    return index;
  }

  // Items
  addRow(item: any) {
    this.items.push(item);
  }

  removeRow(index: number) {
    this.items.splice(index, 1);
  }

  // Reload Items
  reloadItems() {
    // if (this.items && this.items.length > 0) {
    this.changeDetectorRefs.detectChanges();
    // }
  }

  ngOnInit() {

    if (this.tableName === undefined) {
      this.tableName = null;
    }

    if (this.isDraggable) {
      if (this.tableName !== 'DRAGULA_FACTS') {
        this.dragulaService.createGroup(this.tableName, {
          removeOnSpill: false,
          revertOnSpill: true,
          moves: (el, container, handle) => {
            return (handle.className.indexOf('menu-reorder') > -1);
          }
        });
      }

      this.subs.add(this.dragulaService.drag(this.tableName)
        .subscribe(({ name, el, source }) => {
          
        })
      );
      this.subs.add(this.dragulaService.drop(this.tableName)
        .subscribe(({name, el, target, source, sibling}) => {
          const itemsOrder: any[] = [];
          const elements = source.getElementsByTagName('tr') as HTMLCollectionOf<HTMLTableRowElement>;
          for (let index = 0; index < elements.length; index++) {
            const element = elements[index];
            itemsOrder.push({
              id: element.getAttribute('item-id'),
              order: index
            });
          }
          // console.log(itemsOrder)
          this.reorderEvent.emit({
            type: 'reorder',
            tableName: this.tableName,
            data: itemsOrder,
            id: Number(el.getAttribute('item-id'))
          });
        })
      );
    } else {
      this.tableName = null;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (
      changes.dropzonesQuantity &&
      changes.dropzonesQuantity.currentValue
    ) {
      if (
        changes.items &&
        changes.items.currentValue &&
        changes.items.currentValue.length === 0
      ) {
        this.resetPlaceholders();
      }
    }

  }

  setDropzoneItem(index: number, item: any): void {

    // @ts-ignore
    const currentIndex = this.dropzones
      .findIndex((dropzone) => {
        return dropzone &&
          dropzone.item &&
          dropzone.item.id &&
          item.id &&
          dropzone.item.id === item.id;
      });

    if (currentIndex !== -1) {
      this.dropzones[currentIndex] = this.getEmptyObjet();
    }

    this.dropzones[index] = this.getPopulatedPlaceholderModel(item);
  }


  getPopulatedPlaceholderModel(item: any) {
    const dropzonePlaceholderModel = new DropzonePlaceholderModel();
    dropzonePlaceholderModel.item = item;
    dropzonePlaceholderModel.hasItem = true;
    return dropzonePlaceholderModel;
  }

  checkDropzones(index: number, data: any): void {

    if (index === this.externalIndex) {
      return;
    }

    data.forEach((currentData) => {
      this.dropzones
        .forEach((dropzone, currentIndex) => {

          if (
            dropzone &&
            dropzone.item &&
            currentData &&
            currentData.id &&
            dropzone.item.id === currentData.id
          ) {
            this.dropzones[currentIndex].item = this.getEmptyObjet();
            this.dropzones[currentIndex].hasItem = false;
          }
        });
    });

  }

  resetPlaceholders(): void {
    this.dropzones = new Array<DropzonePlaceholderModel>();
    for (let i = 0; i < this.dropzonesQuantity; i++) {
      const dropzonePlaceholderModel = this.getEmptyObjet();
      this.dropzones.push(dropzonePlaceholderModel);
    }
  }

  private getEmptyObjet(): DropzonePlaceholderModel {
    const dropzonePlaceholderModel = new DropzonePlaceholderModel();
    dropzonePlaceholderModel.hasItem = false;
    dropzonePlaceholderModel.item = null;
    return dropzonePlaceholderModel;
  }


}

export class TableConfig {
  checkbox = false;
  paddingSide = false;
  massActions?: TableMassAction[] = [];
  massActionCountTitle?: TableMassActionCountTitle;
}

export class TableMassAction {
  type: string;
  title: string;
  class?: string;
}

export class TableMassActionCountTitle {
  single: string;
  multiple: string;
}

export class DropzonePlaceholderModel {
  hasItem: boolean;
  item: any;
}
