import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fuse-btn-mat-fab',
  templateUrl: './btn-mat-fab.component.html',
  styleUrls: ['./btn-mat-fab.component.scss']
})
export class BtnMatFabComponent implements OnInit {

  @Input() listMatFab: BtnMatFabConfig[] = [];
  @Input() enableClick = 0;
  @Output() clickType = new EventEmitter<{ type: string | number }>();

  constructor() { }

  ngOnInit() {
  }

  public getRight(n: number): string {
    if (n === 1) {
      return (30 * n) + 'px';
    }
    return 30 + (50 * (n - 1)) + ((8) * (n - 1)) + 'px';
  }

  public openSubMenu(index: number): void {
    this.listMatFab[index].enableListIcons = !this.listMatFab[index].enableListIcons;
    this.verifyOpenMenu(this.listMatFab[index].enableListIcons);
  }

  private verifyOpenMenu(isClick = false): void {
    if (isClick){
      this.enableClick = 1;
      return;
    }
    setTimeout(() => {
      this.enableClick = 0;
    }, 250);
  }

  public clickMenu(index: number, type: number | string): void {
    if (!this.listMatFab[index].isLoading) {
      this.listMatFab[index].enableListIcons = false;
      const data = {
        index,
        type
      };
      this.clickType.emit(data);
    }
  }
}

export interface BtnMatFabConfig {
  icon: string;
  color: 'primary' | 'secondary';
  type?: string | number;
  isLoading: boolean;
  enableListIcons?: boolean;
  listIcons?: Icon[];
}

export interface Icon {
  icon: string;
  color: 'primary' | 'secondary';
  type: string | number;
  text?: string;
  textColor: 'normal' | 'danger';
}
