export const locale = {
  lang: 'pt-BR',
  data: {
    'COUPONS': {
      'TITLE': 'Cupons',
      'TABS': {
        'ACTIVE': 'Ativos',
        'EXPIRED': 'Expirados',
        'INACTIVE': 'Inativos'
      },
      'SEARCH': 'Procure pelo nome',
      'EMPTY': 'Não existe cupom cadastrado',
      'TABLE_HEADER': {
        'NAME': 'Nome',
        'CODE': 'Código',
        'DATE_START': 'Data de início',
        'DATE_END': 'Data de término',
        'COUNT': 'Quantidade',
        'VALUE': 'Valor'
      },
      'CANCEL': 'Cancelar',
      'DELETE': 'Deletar',
      'INACTIVE': 'Inativar',
      'ACTIVE': 'Ativar',
      'EXPORT': 'Exportar',
      'CONTENT_EMPTY': 'Nenhum cupom cadastrado',
      'CURRENCY': 'R$',
      'CURRENCY_CODE': 'BRL',
      'DATE_FORMAT': 'dd/MM/yyyy',
      'DATETIME_FORMAT': 'dd/MM/yyyy HH:mm:ss',
      'DIALOG': {
        'DELETE': {
          'TITLE': 'Excluir cupom',
          'DESCRIPTION': 'Tem certeza que deseja excluir o cupom <strong>{{ coupon_name }}</strong>?<br><br>Após executada essa ação não poderá ser desfeita.',
          'EXCLUIR': 'Excluir',
          'CANCEL': 'Cancelar', 
        },
        'ACTIVE': {
          'TITLE': 'Ativar cupom',
          'DESCRIPTION': 'Tem certeza que deseja ativar o cupom <strong>{{ coupon_name }}</strong>?<br><br>',
          'ACTIVE': 'Ativar',
          'CANCEL': 'Cancelar', 
        },
        'INACTIVE': {
          'TITLE': 'Inativar cupom',
          'DESCRIPTION': 'Tem certeza que deseja inativar o cupom <strong>{{ coupon_name }}</strong>?<br><br>',
          'INACTIVE': 'Inativar',
          'CANCEL': 'Cancelar', 
        }
      },
      'TOASTR': {
        'CREATE': {
          'TITLE': 'Cupom criado com sucesso!',
          'DESCRIPTION': 'Cupom criado com sucesso!'
        },
        'EDIT': {
          'TITLE': 'Cupom editado com sucesso!',
          'DESCRIPTION': 'Seu cupom foi editado.'
        },
        'DELETE': {
          'ERROR': {
            'TITLE': 'Erro ao deletar cupom!'
          },
          'SUCCESS': {
            'TITLE': 'Cupom deletado com sucesso!',
            'DESCRIPTION': 'Cupom deletado e não poderá mais ser utilizado.'
          }
        },
        'ACTIVE': {
          'TITLE': 'Cupom ativado com sucesso!',
          'DESCRIPTION': 'Cupom ativado e disponível para uso.',
          'ERROR': {
            'TITLE': 'Erro ao ativar cupom'
          }
        },
        'INACTIVE': {
          'TITLE': 'Cupom inativado com sucesso!',
          'DESCRIPTION': 'Cupom inativado, não será mais possível usá-lo a não ser se ativa-lo novamente.',
          'ERROR': {
            'TITLE': 'Erro ao inativar cupom'
          }
        }
      }
    }
  }
};
