export const locale = {
  lang: 'en-US',
  data: {
    'REGISTER': {
      'TITLE': 'Cadastro',
      'NAME': 'Nome',
      'LAST_NAME': 'Sobrenome',
      'USERNAME': 'Username',
      'EMAIL': 'E-mail',
      'PASSWORD': 'Senha',
      'CONFIRM_PASSWORD': 'Confirmar senha',
      'GENDER': 'Sexo',
      'GENDER_MALE': 'Masculino',
      'GENDER_FEMALE': 'Feminino',
      'BIRTHDAY': 'Data de nascimento',
      'BOX': 'Box',
      'CITY': 'Cidade de residência',
      'ACCEPT': 'Aceito os',
      'TERMS': 'termos de uso',
      'NAME_REQUIRED': 'Nome é obrigatório',
      'LAST_NAME_REQUIRED': 'Sobrenome é obrigatório',
      'USERNAME_REQUIRED': 'Username é obrigatório',
      'EMAIL_REQUIRED': 'E-mail é obrigatório',
      'EMAIL_INVALID': 'E-mail inválido',
      'PASSWORD_REQUIRED': 'Senha obrigatória',
      'PASSWORD_MINLENGHT': 'A senha deve conter pelo menos 6 caracters',
      'CONFIRM_PASSWORD_REQUIRED': 'Confirmação de senha obrigatória',
      'CONFIRM_PASSWORD_INVALID': 'As senhas não são iguais',
      'GENDER_REQUIRED': 'Sexo é obrigatório',
      'BIRTHDAY_REQUIRED': 'Data de nascimento é obrigatório',
      'BIRTHDAY_INVALID': 'Data inválida',
      'CITY_REQUIRED': 'A cidade é obrigatório',
      'BOX_EMPTY': 'Caso não tenha box deixar o campo vazio',
      'REGISTER': 'CADASTRAR',
      'BACK': 'VOLTAR',
      'DATE_FORMAT': 'DD/MM/YYYY'
    }
  }
};
