import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../../core/services/translation-loader.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { locale as portugues } from '../i18n/pt-BR';
import { combineLatest, Subscription } from 'rxjs';
import { TableConfig, TableXComponent } from '../../../../../../core/components/table-x/table-x.component';
import { Heat } from '../model/heat.model';
import { HeatsUrlService } from '../services/heats-url.service';
import { HeatQueryParams } from '../enums/heat-query.params';
import { HeatsHttpService } from '../../../../../../core/services/heats/heats-http.service';
import * as moment from 'moment';
import { ToastrService } from '../../../../../../core/components/toastr_/toastr/toastr.service';

@Component({
  selector: 'app-heats-schedule',
  templateUrl: './heats-schedule.component.html',
  styleUrls: ['./heats-schedule.component.scss']
})

export class HeatsScheduleComponent implements OnInit, OnDestroy {
  @ViewChildren('tableCompetition')
  tablesCompetition: QueryList<TableXComponent>;

  private subscriptions: Subscription = new Subscription();

  // form
  eventForm: FormGroup;

  @Input()
  heats: Array<Heat>;

  heatsSelected = new Array<Heat>();

  championshipId: number;

  // translate
  translate: any;

  isLoading = false;

  tableCatConfig: TableConfig = {
    checkbox: true,
    paddingSide: true,
    massActions: [
      {
        type: 'save',
        title: 'Salvar'
      }
    ],
    massActionCountTitle: {
      single: 'resultado selecionado',
      multiple: 'resultados selecionados'
    }
  };

  constructor(
    private translateService: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private formBuilder: FormBuilder,
    private heatsUrlService: HeatsUrlService,
    private heatsHttpService: HeatsHttpService,
    private toastr: ToastrService,
  ) {
    this.registerOnTranslateHeatCompetitors();
    this.createForm();
    this.registerOnPathParams();
    this.registerOnUpdatedHttp();
  }

  ngOnInit() {
  }

  private registerOnUpdatedHttp() {
    this.heatsHttpService
      .heatsUpdatedObservable
      .subscribe((data) => {
        if (data) {
          this.isLoading = false;
          this.toastr.show(
            this.translate.HEATS_SCHEDULE.TOASTR.UPDATE.DESCRIPTION,
            this.translate.HEATS_SCHEDULE.TOASTR.UPDATE.TITLE,
            null,
            'success',
          );
        }
      });
  }

  private createForm(): void {
    this.eventForm = this.formBuilder.group({
      dueDate: ['', Validators.required],
      startTime: ['', Validators.required],
      duration: ['', Validators.required],
      transition: ['', Validators.required],
    });
  }

  public allSelectedItems(heats: Heat[]): void {
    this.heatsSelected = heats;
  }

  public onSubmitGenerate(): void {
    const {dueDate, startTime, duration, transition} = this.eventForm.value;

    if (!this.heatsSelected.length) {
      this.toastr.show(
        this.translate.HEATS_SCHEDULE.TOASTR.GENERATE.DESCRIPTION,
        this.translate.HEATS_SCHEDULE.TOASTR.GENERATE.TITLE,
        null,
        'error',
      );
      return;
    }

    if (!dueDate || !startTime || !duration || !transition) {
      return;
    }

    const heatsSelectedLength = this.heatsSelected.length;
    for (let i = 0; i < heatsSelectedLength; i++) {
      const dateHeats = moment(dueDate, 'DDMMYYYY').format('YYYY-MM-DD');
      const currentIndex = this.heats.findIndex(heats => heats.id === this.heatsSelected[i].id);
      const current = this.heats[currentIndex];
      let prev;
      if (this.heatsSelected[i - 1]) {
        const prevHeatSelected = this.heatsSelected[i - 1];
        const prevIndex = this.heats.findIndex(heats => heats.id === prevHeatSelected.id);
        prev = this.heats[prevIndex];
      }

      let minutes;
      let hours;
      let endTime;
      if (!prev) {
        minutes = Number(moment(startTime, 'H:mm:ss').format('m'));
        hours = moment(startTime, 'H:mm:ss').format('H');
        endTime = moment(dateHeats)
          .add(hours, 'hours')
          .add(minutes, 'minutes')
          .add(duration, 'minutes')
          .format('H:mm:ss');

        current.due_date = dateHeats;
        current.start_time = startTime;
        current.end_time = endTime;
        continue;
      }
      const newPrev = Object.assign({}, prev);
      const startTimeHeats = moment(`${newPrev.due_date} ${newPrev.start_time}`, 'YYYY-MM-DD H:mm:ss')
        .add(Number(transition) + Number(duration), 'minutes');
      const newDateHeat = startTimeHeats;
      endTime = moment(startTimeHeats)
        .add(duration, 'minutes')
        .format('H:mm:ss');

      current.due_date = newDateHeat.format('YYYY-MM-DD');
      current.start_time = startTimeHeats.format('H:mm:ss');
      current.end_time = endTime;
    }

    this.eventForm.controls['dueDate'].setValue('');
    this.eventForm.controls['startTime'].setValue('');
    this.eventForm.controls['duration'].setValue('');
    this.eventForm.controls['transition'].setValue('');
  }

  public onSubmitSave(): void {
    this.isLoading = true;
    this.heatsHttpService.updateHeats(this.heats);
  }

  private registerOnTranslateHeatCompetitors(): void {
    this.translationLoader.loadTranslations(portugues);
    const subscription = this.translateService
      .get('HEAT_HEATCOMPETITORS')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);
  }

  private registerOnPathParams(): void {
    const subscription = combineLatest(
      this.heatsUrlService.paramsObservable(),
      this.heatsUrlService.queryParamsObservable(),
    ).subscribe(([pathParams, queryParams]) => {
      if (queryParams.length === 0) {
        const defaultQueryParams = [{
          key: HeatQueryParams.TYPE,
          value: HeatQueryParams.TYPE_SINGLE 
        }];
        
        queryParams = defaultQueryParams;
      }
      this.championshipId = pathParams.championship_id;
        this.heatsHttpService
          .fetchHeats([
              {
                key: HeatQueryParams.CHAMPIONSHIP_ID,
                value: this.championshipId
              },
              queryParams.find((param) => param.key === HeatQueryParams.TYPE)
            ]
          )
          .subscribe(
            (heats: Heat[]) => {
              this.isLoading = false;
              this.heats = heats;
            });
      });

    this.subscriptions.add(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
