<div class="page-layout blank pb-64">
  <div class="p-32">
    <div class="content">
      <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
        <div class="p-32 pt-16" fxLayout="column" fxLayoutAlign="stretch stretch">
          <h2>{{ 'SETTINGS.MAX_DATE_USER_EDIT.SETTINGS' | translate }}</h2>
          <span class="mb-32">{{ 'SETTINGS.MAX_DATE_USER_EDIT.DESCRIPTION' | translate }}</span>
          <form (ngSubmit)="onSubmitFormMaxDateUserEdit()" [formGroup]="formMaxDateUserEdit" fxLayout="column">
            <div fxLayout="row wrap">
              <div fxLayout="row wrap" fxFlex="70" fxFlex.xs="100">
                <mat-form-field fxFlex="50" class="pr-16">
                  <input
                    matInput
                    formControlName="date"
                    placeholder="{{ 'SETTINGS.MAX_DATE_USER_EDIT.FORM.FIELDS.DATE' | translate }}"
                    mask="{{ 'SETTINGS.MAX_DATE_USER_EDIT.FORM.MASKS.DATE' | translate }}"
                    [dropSpecialCharacters]="false"
                    required
                  >
                  <mat-icon matSuffix>date_range</mat-icon>
                  <mat-error *ngIf="formMaxDateUserEdit.controls.date.hasError('required')">
                    {{'SETTINGS.MAX_DATE_USER_EDIT.FORM.ERRORS.DATE.REQUIRED' | translate}}
                  </mat-error>
                  <mat-error *ngIf="!formMaxDateUserEdit.controls.date.hasError('required') && formMaxDateUserEdit.controls.date.hasError('invalid')">
                    {{'SETTINGS.MAX_DATE_USER_EDIT.FORM.ERRORS.DATE.DATE_INVALID' | translate}}
                  </mat-error>
                </mat-form-field>
                <div fxFlex="50" fxLayout="column" fxLayoutAlign="start start" style="max-width: 100%;">
                  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-evenly center" class="selects-time">
                    <mat-form-field fxFlex="45">
                      <mat-select placeholder="{{ 'SETTINGS.MAX_DATE_USER_EDIT.FORM.FIELDS.HOUR' | translate }}" floatLabel="never" formControlName="hour" required>
                        <mat-option *ngFor="let hour of hours" [value]="hour">
                          {{ hour }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <span class="pl-4 pr-4" fxFlex="10" fxLayoutAlign="center end"> : </span>
                    <mat-form-field fxFlex="45">
                      <mat-select placeholder="{{ 'SETTINGS.MAX_DATE_USER_EDIT.FORM.FIELDS.MINUTE' | translate }}" floatLabel="never"  formControlName="minutes" required>
                        <mat-option *ngFor="let minute of minutes" [value]="minute">
                          {{ minute }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" class="mt-16 mb-16">
              <btn [btnLoading]="true" [buttonDisabled]="!formMaxDateUserEdit.valid" [loading]="isLoadingFormMaxDateUserEdit" class="ml-16">{{ 'SETTINGS.SAVE' | translate }}</btn>
            </div>
          </form>

          <div class="line-bottom mt-32 mb-16"></div>
          <h2>{{ 'SETTINGS.CHECKIN_ONLINE.SETTINGS' | translate }}</h2>
          <form (ngSubmit)="onSubmitFormCheckin()" [formGroup]="formCheckin" fxLayout="column">
            <div fxLayout="row wrap">
              <div fxLayout="row wrap" fxFlex="70" fxFlex.xs="100">
                <mat-form-field fxFlex="50" class="pr-16">
                  <input
                    matInput
                    formControlName="start_date_date"
                    placeholder="{{ 'SETTINGS.CHECKIN_ONLINE.FORM.FIELDS.START_DATE_DATE' | translate }}"
                    mask="{{ 'SETTINGS.CHECKIN_ONLINE.FORM.MASKS.START_DATE_DATE' | translate }}"
                    [dropSpecialCharacters]="false"
                    required
                  >
                  <mat-icon matSuffix>date_range</mat-icon>
                  <mat-error *ngIf="formCheckin.controls.start_date_date.hasError('required')">
                    {{'SETTINGS.CHECKIN_ONLINE.FORM.ERRORS.START_DATE_DATE.REQUIRED' | translate}}
                  </mat-error>
                  <mat-error *ngIf="!formCheckin.controls.start_date_date.hasError('required') && formCheckin.controls.start_date_date.hasError('invalid')">
                    {{'SETTINGS.CHECKIN_ONLINE.FORM.ERRORS.START_DATE_DATE.DATE_INVALID' | translate}}
                  </mat-error>
                </mat-form-field>
                <div fxFlex="50" fxLayout="column" fxLayoutAlign="start start" style="max-width: 100%;">
                  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-evenly center" class="selects-time">
                    <mat-form-field fxFlex="45">
                      <mat-select placeholder="{{ 'SETTINGS.CHECKIN_ONLINE.FORM.FIELDS.START_DATE_HOUR' | translate }}" floatLabel="never" formControlName="start_date_hour" required>
                        <mat-option *ngFor="let hour of hours" [value]="hour">
                          {{ hour }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <span class="pl-4 pr-4" fxFlex="10" fxLayoutAlign="center end"> : </span>
                    <mat-form-field fxFlex="45">
                      <mat-select placeholder="{{ 'SETTINGS.CHECKIN_ONLINE.FORM.FIELDS.START_DATE_MINUTE' | translate }}" floatLabel="never"  formControlName="start_date_minutes" required>
                        <mat-option *ngFor="let minute of minutes" [value]="minute">
                          {{ minute }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div fxLayout="row wrap" fxFlex="70" fxFlex.xs="100">
                <mat-form-field fxFlex="50" class="pr-16">
                  <input
                    matInput
                    formControlName="end_date_date"
                    placeholder="{{ 'SETTINGS.CHECKIN_ONLINE.FORM.FIELDS.END_DATE_DATE' | translate }}"
                    mask="{{ 'SETTINGS.CHECKIN_ONLINE.FORM.MASKS.END_DATE_DATE' | translate }}"
                    [dropSpecialCharacters]="false"
                    required
                  >
                  <mat-icon matSuffix>date_range</mat-icon>
                  <mat-error *ngIf="formCheckin.controls.end_date_date.hasError('required')">
                    {{'SETTINGS.CHECKIN_ONLINE.FORM.ERRORS.END_DATE_DATE.REQUIRED' | translate}}
                  </mat-error>
                  <mat-error *ngIf="!formCheckin.controls.end_date_date.hasError('required') && formCheckin.controls.end_date_date.hasError('invalid')">
                    {{'SETTINGS.CHECKIN_ONLINE.FORM.ERRORS.END_DATE_DATE.DATE_INVALID' | translate}}
                  </mat-error>
                </mat-form-field>
                <div fxFlex="50" fxLayout="column" fxLayoutAlign="start start" style="max-width: 100%;">
                  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-evenly center" class="selects-time">
                    <mat-form-field fxFlex="45">
                      <mat-select placeholder="{{ 'SETTINGS.CHECKIN_ONLINE.FORM.FIELDS.END_DATE_HOUR' | translate }}" floatLabel="never" formControlName="end_date_hour" required>
                        <mat-option *ngFor="let hour of hours" [value]="hour">
                          {{ hour }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <span class="pl-4 pr-4" fxFlex="10" fxLayoutAlign="center end"> : </span>
                    <mat-form-field fxFlex="45">
                      <mat-select placeholder="{{ 'SETTINGS.CHECKIN_ONLINE.FORM.FIELDS.END_DATE_MINUTE' | translate }}" floatLabel="never"  formControlName="end_date_minutes" required>
                        <mat-option *ngFor="let minute of minutes" [value]="minute">
                          {{ minute }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div fxLayout="row wrap" fxFlex="70" fxFlex.xs="100">
              <mat-slide-toggle formControlName="enabled_checkin_file">{{ 'SETTINGS.CHECKIN_ONLINE.FORM.FIELDS.ENABLE_CHECKIN_FILE' | translate }}</mat-slide-toggle>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" class="mt-16 mb-16">
              <btn [btnLoading]="true" [buttonDisabled]="!formCheckin.valid" [loading]="isLoadingFormCheckin" class="ml-16">{{ 'SETTINGS.SAVE' | translate }}</btn>
            </div>
          </form>

          <div class="line-bottom mt-32 mb-16"></div>
          <h2>{{ 'SETTINGS.LEADERBOARD.SETTINGS' | translate }}</h2>
          <span class="mb-32">{{ 'SETTINGS.LEADERBOARD.SETTINGS_DESCRIPTION' | translate }}</span>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
            <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
              <input
                matInput
                placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_BACKGROUND' | translate }}"
                [value]="colors.leaderboard.bg"
                [(ngModel)]="colors.leaderboard.bg"
                [(colorPicker)]="colors.leaderboard.bg"
                [cpOutputFormat]="'hex'"
                [cpPosition]="'bottom'"
                [cpPositionOffset]="'0%'"
                [cpPositionRelativeToArrow]="false"
                [cpWidth]="'auto'"
                [cpHeight]="'auto'"
                [cpSaveClickOutside]="true"
                [cpOKButton]="false"
                [cpCancelButton]="false"
                [cpDialogDisplay]="'popup'"
                [cpAlphaChannel]="'disabled'"
                uppercase
                required>
              <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboard.bg"></div>
            </mat-form-field>

            <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
              <input matInput placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_LINE_DARK' | translate }}" [value]="colors.leaderboard.rowDark" [(colorPicker)]="colors.leaderboard.rowDark"
                     [cpOutputFormat]="'hex'" [cpPosition]="'bottom'" [cpPositionOffset]="'0%'" [cpPositionRelativeToArrow]="false" [cpWidth]="'auto'"
                     [cpHeight]="'auto'" [cpSaveClickOutside]="true" [cpOKButton]="false" [cpCancelButton]="false" [cpDialogDisplay]="'popup'"
                     [cpAlphaChannel]="'disabled'" uppercase required>
              <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboard.rowDark"></div>
            </mat-form-field>

            <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
              <input matInput placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_TEXT' | translate }}" [value]="colors.leaderboard.text" [(colorPicker)]="colors.leaderboard.text"
                     [cpOutputFormat]="'hex'" [cpPosition]="'bottom'" [cpPositionOffset]="'0%'" [cpPositionRelativeToArrow]="false" [cpWidth]="'auto'"
                     [cpHeight]="'auto'" [cpSaveClickOutside]="true" [cpOKButton]="false" [cpCancelButton]="false" [cpDialogDisplay]="'popup'"
                     [cpAlphaChannel]="'disabled'" required>
              <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboard.text"></div>
            </mat-form-field>

            <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
              <input matInput placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_NAME_BOX' | translate }}" [value]="colors.leaderboard.boxName" [(colorPicker)]="colors.leaderboard.boxName"
                     [cpOutputFormat]="'hex'" [cpPosition]="'bottom'" [cpPositionOffset]="'0%'" [cpPositionRelativeToArrow]="false" [cpWidth]="'auto'"
                     [cpHeight]="'auto'" [cpSaveClickOutside]="true" [cpOKButton]="false" [cpCancelButton]="false" [cpDialogDisplay]="'popup'"
                     [cpAlphaChannel]="'disabled'" required>
              <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboard.boxName"></div>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
            <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
              <input
                matInput
                placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_HIGHLIGHT' | translate }}"
                [value]="colors.leaderboard.highlight"
                [(colorPicker)]="colors.leaderboard.highlight"
                [cpOutputFormat]="'hex'"
                [cpPosition]="'bottom'"
                [cpPositionOffset]="'0%'"
                [cpPositionRelativeToArrow]="false"
                [cpWidth]="'auto'"
                [cpHeight]="'auto'"
                [cpSaveClickOutside]="true"
                [cpOKButton]="false"
                [cpCancelButton]="false"
                [cpDialogDisplay]="'popup'"
                [cpAlphaChannel]="'disabled'"
                required>
              <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboard.highlight"></div>
            </mat-form-field>
            <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
              <input
                matInput
                placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_CUT_LINE' | translate }}"
                [value]="colors.leaderboard.cutLine"
                [(colorPicker)]="colors.leaderboard.cutLine"
                [cpOutputFormat]="'hex'"
                [cpPosition]="'bottom'"
                [cpPositionOffset]="'0%'"
                [cpPositionRelativeToArrow]="false"
                [cpWidth]="'auto'"
                [cpHeight]="'auto'"
                [cpSaveClickOutside]="true"
                [cpOKButton]="false"
                [cpCancelButton]="false"
                [cpDialogDisplay]="'popup'"
                [cpAlphaChannel]="'disabled'"
                required>
              <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboard.cutLine"></div>
            </mat-form-field>
          </div>

          <mat-slide-toggle [checked]="isShowBrandInLeaderboard" [(ngModel)]="isShowBrandInLeaderboard">{{ 'SETTINGS.LEADERBOARD.LEADERBOARD_BRAND' | translate }}</mat-slide-toggle>

          <div fxLayout="row" fxLayoutAlign="end center" class="mt-16 mb-16">
            <!--<btn [btnLoading]="true" type="grayscale">{{ 'SETTINGS.LEADERBOARD.PREVIEW' | translate }}</btn>-->
            <btn 
              [btnLoading]="true" 
              [loading]="isLeaderboardPublishLoading" 
              type="grayscale"
              (click)="publishOrUnpublishLeaderboadEvent()"
            >
              {{ (!event.leaderboard_published ? 'SETTINGS.LEADERBOARD.STATUS.PUBLISH' : 'SETTINGS.LEADERBOARD.STATUS.UNPUBLISH') | translate | uppercase }}
            </btn>
            <btn [btnLoading]="true" [loading]="isLeaderboardLoading" (click)="saveLeaderboardColors()" class="ml-16">{{ 'SETTINGS.SAVE' | translate }}</btn>
          </div>

          <!--<div fxLayout="row" fxLayoutAlign="center start" class="mt-32">
            <img src="./assets/images/mockups/leaderboard.png" />
          </div>-->

          <!--<div class="line-bottom mt-32 mb-8"></div>-->

          <!--<h2>{{ 'SETTINGS.LEADERBOARD.SETTINGS_TV' | translate }}</h2>
          <span class="mb-32">{{ 'SETTINGS.LEADERBOARD.SETTINGS_TV_DESCRIPTION' | translate }}</span>

          <div fxLayout="row" fxLayoutAlign="start start" class="mt-32">
            <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
              <input matInput placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_BACKGROUND' | translate }}" [value]="colors.leaderboardTv.bg" [(colorPicker)]="colors.leaderboardTv.bg"
                [cpOutputFormat]="'hex'" [cpPosition]="'bottom'" [cpPositionOffset]="'0%'" [cpPositionRelativeToArrow]="false" [cpWidth]="'auto'"
                [cpHeight]="'auto'" [cpSaveClickOutside]="true" [cpOKButton]="false" [cpCancelButton]="false" [cpDialogDisplay]="'popup'"
                [cpAlphaChannel]="'disabled'" required>
              <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboardTv.bg"></div>
            </mat-form-field>
          
            <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
              <input matInput placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_EVENT_NAME' | translate }}" [value]="colors.leaderboardTv.eventName"
                [(colorPicker)]="colors.leaderboardTv.eventName" [cpOutputFormat]="'hex'" [cpPosition]="'bottom'" [cpPositionOffset]="'0%'"
                [cpPositionRelativeToArrow]="false" [cpWidth]="'auto'" [cpHeight]="'auto'" [cpSaveClickOutside]="true" [cpOKButton]="false"
                [cpCancelButton]="false" [cpDialogDisplay]="'popup'" [cpAlphaChannel]="'disabled'" required>
              <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboardTv.eventName"></div>
            </mat-form-field>
          
            <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
              <input matInput placeholder="{{ 'SETTINGS.LEADERBOARD.CATEGORY' | translate }}" [value]="colors.leaderboardTv.category" [(colorPicker)]="colors.leaderboardTv.category"
                [cpOutputFormat]="'hex'" [cpPosition]="'bottom'" [cpPositionOffset]="'0%'" [cpPositionRelativeToArrow]="false" [cpWidth]="'auto'"
                [cpHeight]="'auto'" [cpSaveClickOutside]="true" [cpOKButton]="false" [cpCancelButton]="false" [cpDialogDisplay]="'popup'"
                [cpAlphaChannel]="'disabled'" required>
              <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboardTv.category"></div>
            </mat-form-field>
          
            <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
              <input matInput placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_BACKGROUND_CELL' | translate }}" [value]="colors.leaderboardTv.cell"
                [(colorPicker)]="colors.leaderboardTv.cell" [cpOutputFormat]="'hex'" [cpPosition]="'bottom'" [cpPositionOffset]="'0%'"
                [cpPositionRelativeToArrow]="false" [cpWidth]="'auto'" [cpHeight]="'auto'" [cpSaveClickOutside]="true" [cpOKButton]="false"
                [cpCancelButton]="false" [cpDialogDisplay]="'popup'" [cpAlphaChannel]="'disabled'" required>
              <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboardTv.cell"></div>
            </mat-form-field>
          </div>
          
          <div fxLayout="row" fxLayoutAlign="start start">
            <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
              <input matInput placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_PROGRESS_BAR' | translate }}" [value]="colors.leaderboardTv.progressBar"
                [(colorPicker)]="colors.leaderboardTv.progressBar" [cpOutputFormat]="'hex'" [cpPosition]="'bottom'" [cpPositionOffset]="'0%'"
                [cpPositionRelativeToArrow]="false" [cpWidth]="'auto'" [cpHeight]="'auto'" [cpSaveClickOutside]="true" [cpOKButton]="false"
                [cpCancelButton]="false" [cpDialogDisplay]="'popup'" [cpAlphaChannel]="'disabled'" required>
              <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboardTv.progressBar"></div>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutAlign="end center" class="mt-16 mb-16">
            <btn [btnLoading]="true" type="grayscale">{{ 'SETTINGS.LEADERBOARD.PREVIEW' | translate }}</btn>
            <btn [btnLoading]="true" class="ml-16">{{ 'SETTINGS.SAVE' | translate }}</btn>
          </div>
          
          <div fxLayout="row" fxLayoutAlign="center start" class="mt-32 mb-16">
            <img src="./assets/images/mockups/leaderboard-tv.png" />
          </div>-->

          <!--<div class="line-bottom mt-32 mb-8"></div>

          <span class="mt-16">
            Configure a ordem das categorias no leaderboard tv.
          </span>

          <div fxLayout="row" fxLayoutAlign="start start">
            <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-16">
              <mat-select placeholder="{{ 'SETTINGS.REGISTRATION.TYPE_PERSON' | translate }}" (selectionChange)="personTypeSelect($event)"
                required>
                <mat-option *ngFor="let type of personTypes" [value]="type">
                  {{ type.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <div fxLayout="row" fxLayoutAlign="stretch stretch" class="wrapper">
            <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="33.3" class="pr-16">
              <h4>{{ 'SETTINGS.LEADERBOARD.CATEGORIES' | translate }}</h4>
              &lt;!&ndash; [dragula]='"columns-leaderboard-tv"' &ndash;&gt;
              <div fxFlex="100" fxLayout="column" fxLayoutAlign="start start">
                <div fxFlex="initial" fxLayout="row" fxLayoutAlign="start center" class="box-line drag-and-drop p-8 mb-4 mt-4">
                  <mat-icon class="mr-8">menu</mat-icon>
                  Trio Masculino
                </div>
                <div fxFlex="initial" fxLayout="row" fxLayoutAlign="start center" class="box-line drag-and-drop p-8 mb-4 mt-4">
                  <mat-icon class="mr-8">menu</mat-icon>
                  Trio Feminino
                </div>
                <div fxFlex="initial" fxLayout="row" fxLayoutAlign="start center" class="box-line drag-and-drop p-8 mb-4 mt-4">
                  <mat-icon class="mr-8">menu</mat-icon>
                  Ind Feminino
                </div>
                <div fxFlex="initial" fxLayout="row" fxLayoutAlign="start center" class="box-line drag-and-drop p-8 mb-4 mt-4">
                  <mat-icon class="mr-8">menu</mat-icon>
                  Ind Masculino
                </div>
                <div fxFlex="initial" fxLayout="row" fxLayoutAlign="start center" class="box-line drag-and-drop p-8 mb-4 mt-4">
                  <mat-icon class="mr-8">menu</mat-icon>
                  Elite
                </div>
              </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="33.3" class="pr-16">
              <h4>{{ 'SETTINGS.LEADERBOARD.SPLIT_LEFT' | translate }}</h4>
              &lt;!&ndash; [dragula]='"columns-leaderboard-tv"' &ndash;&gt;
              <div fxFlex="100" fxLayout="column" fxLayoutAlign="start start">
                
              </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="33.3" class="pr-16">
              <h4>{{ 'SETTINGS.LEADERBOARD.SPLIT_RIGHT' | translate }}</h4>
              &lt;!&ndash; [dragula]='"columns-leaderboard-tv"' &ndash;&gt;
              <div fxFlex="100" fxLayout="column" fxLayoutAlign="start start">
            
              </div>
            </div>
          </div>-->


        </div>
      </div>
    </div>
  </div>
</div>

