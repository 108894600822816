<div class="page-layout blank" fxLayoutAlign="center center" *ngIf="heat === null && isLoading">
  <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
</div>

<div class="page-layout blank table-container" [ngStyle]="styles" *ngIf="heat !== null">

  <div class="container-panel">

    <div class="container-top">

      <div class="container-image">
        <img src="./assets/images/leaderboard/logo_tcb.png" />
<!--        <img src="{{ (eventBrandUrl) ? eventBrandUrl : null | placeholderImage:'user' }}">-->
      </div>

      <div class="container-text" fxLayout="column" fxLayoutAlign="center start">
        <p class="first-paragraph">
          {{ eventName }}
        </p>
        <p class="last-paragraph">
          {{heat?.name}}
        </p>
      </div>
      <div class="centrum">
        <img src="./assets/images/leaderboard/centrum.png" />
      </div>
    </div>

    <div class="container-table">
      <table>

        <thead>
        <tr>
          <th class="pl-16">{{ 'HEATS_TV.LANE' | translate }} #</th>
          <th>{{ 'HEATS_TV.STATE' | translate }}</th>
          <th>{{ 'HEATS_TV.COMPETITOR' | translate }}</th>
          <th>{{ 'HEATS_TV.AFFILIATE' | translate }}</th>
          <th *ngIf="isShowScore">{{ 'HEATS_TV.SCORE' | translate }}</th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let heatCompetitor of heat?.heat_competitors">
          <td class="pl-16">
            {{heatCompetitor?.lane}}
          </td>

          <td>
            <span fxLayout="row" fxLayoutAlign="start center"> 
              <img [src]="heatCompetitor?.competitor?.team?.state?.url" class="mr-16 flag" *ngIf="heatCompetitor.competitor && heatCompetitor.competitor.team && heatCompetitor.competitor.team.state">
            </span>
          </td>

          <td>
            {{ heatCompetitor?.competitor?.team?.name | uppercase }}
          </td>

          <td class="box-name">
            {{ heatCompetitor?.competitor?.team?.box?.name | uppercase }}
          </td>
          
          <td *ngIf="isShowScore">
            {{ heatCompetitor.result ? formatResult(heatCompetitor.result, heatCompetitor.wod) : '' }}
          </td>
        </tr>

        </tbody>

        <tfoot>
        <tr>
          <th colspan="4" *ngIf="isShowScore"></th>
          <th colspan="3" *ngIf="!isShowScore"></th>
          <th class="crossx-brand">
            <a href="http://appcrossx.com" target="_blank" fxLayoutAlign="end end">
              <img src="./assets/images/leaderboard/crossx.png" />
            </a>
          </th>
        </tr>
        </tfoot>

      </table>
    </div>
  </div>
</div>

<button (click)="onCollapse()" class="open-menu">
  <img src="./assets/icons/common/ic-settings.svg" />
</button>

<aside class="container-right" [ngClass]="getClasses">
  <header class="pl-24 pr-24">
    <h1>
      {{ 'HEATS_TV.SETUP' | translate }}
    </h1>

    <button (click)="onCollapse()">
      {{ 'HEATS_TV.COLLAPSE' | translate }}
    </button>
  </header>

  <form [formGroup]="form" class="p-24">

    <ng-container *ngIf="showChampionship">
      <div>
        <label>
          {{ 'HEATS_TV.CURRENT_CHAMPIONSHIP' | translate }}
        </label>
      </div>

      <div>
        <mat-select
          placeholder="Championships"
          formControlName="championship"
        >
          <mat-option
            *ngFor="let championship of championships"
            [value]="championship?.id"
          >
            {{championship?.name}}
          </mat-option>
        </mat-select>
      </div>
    </ng-container>

    <div class="select-team-type-label">
      <label>
        {{ 'HEATS_TV.CURRENT_HEAT' | translate }}
      </label>
    </div>

    <div>
      <mat-select placeholder="" formControlName="heat">
        <ng-container *ngIf="showHeatsOptions">
          <mat-option
            *ngFor="let heat of heats"
            [value]="heat?.id"
          >
            {{heat?.name}}
          </mat-option>

        </ng-container>
      </mat-select>
    </div>
    
    <div class="show-score">
      <mat-checkbox
        formControlName="showScore" [(ngModel)]="isShowScore">
        {{ 'HEATS_TV.SHOW_SCORE' | translate }}
      </mat-checkbox>
    </div>
  </form>
  
  <div class="select-team-type-label update-heat">
    <button mat-button type="submit" [disabled]="heat === null || isLoading" (click)="onClick($event)">
      {{ 'HEATS_TV.UPDATE_HEAT' | translate }}
    </button>
  </div>

</aside>