import { config } from './config/config';
import { FormBuilder, FormGroup, FormControl, FormArray, ValidatorFn } from '@angular/forms';
import {Competition} from './model/competition';
import {CardBrand} from './model/payment';
import { ɵDomSanitizerImpl } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';

export class Utils
{

  public static getPicture(object: string = null, type: string = 'user'): string
  {
      if ( object !== null && object !== undefined )
      {
          return object;
      } else {
        if (type === 'user') {
          return config.pathAvatar;  
        }
        return config.pathAvatar;
      }
  }

  // Sort Action
  /**
   * static sortArray
   */
  public static sortArray(array: any[], column: string, order: string): any[] {
    if (array && array.length > 0) {
      console.log(typeof array[0].sort[column]);
      if (typeof array[0].sort[column] === 'string') {
         return array.sort((a, b) => {
          // console.log(a.competitor.rank - b.competitor.rank)
          if (order === 'ASC') {
            if (a.sort[column] < b.sort[column]) { return -1; }
            else if (a.sort[column] > b.sort[column]) { return 1; }
            else { return 0; }
          } else if (order === 'DESC') {
            if (a.sort[column] > b.sort[column]) { return -1; }
            else if (a.sort[column] < b.sort[column]) { return 1; }
            else { return 0; }
          }
        });
      }
      else if (typeof array[0].sort[column] === 'number') {
        return array.sort((a, b) => {
          // console.log(a.competitor.rank - b.competitor.rank)
          if (order === 'ASC') {
            return a.sort[column] - b.sort[column];
          } else if (order === 'DESC') {
            return b.sort[column] - a.sort[column];
          }
        });
      }
    }
  }

  // Remove accents
  public static removeAccents(term: string): string
  {
    let string = term.toLowerCase();
    const mapaAcentosHex = {
      a: /[\xE0-\xE6]/g,
      A: /[\xC0-\xC6]/g,
      e: /[\xE8-\xEB]/g,
      E: /[\xC8-\xCB]/g,
      i: /[\xEC-\xEF]/g,
      I: /[\xCC-\xCF]/g,
      o: /[\xF2-\xF6]/g,
      O: /[\xD2-\xD6]/g,
      u: /[\xF9-\xFC]/g,
      U: /[\xD9-\xDC]/g,
      c: /\xE7/g,
      C: /\xC7/g,
      n: /\xF1/g,
      N: /\xD1/g,
    };

    for (const letra in mapaAcentosHex) {
      const expressaoRegular = mapaAcentosHex[letra];
      string = string.replace(expressaoRegular, letra);
    }

    return string;
  }

  // Units
  public static getUnitName(unitTypeId: number, abbreviation: boolean): string
  {
    switch (unitTypeId) {
      case 1:
        return (abbreviation) ? 'reps' : 'Repetitions';

      case 2:
        return (abbreviation) ? 'time' : 'Time';

      case 3:
        return (abbreviation) ? 'cal' : 'Calories';

      case 4:
        return (abbreviation) ? 'kg' : 'Kilograms';

      case 5:
        return (abbreviation) ? 'm' : 'Meters';

      case 6:
        return (abbreviation) ? 'lb' : 'Pounds';
    
      default:
        return '';
    }
  }

  public static geTypeName(typeId: number, abbreviation: boolean): string {
    switch (typeId) {
      case 1:
        return (abbreviation) ? 'reps' : 'Repetitions';
      
      case 2:
        return (abbreviation) ? 'time' : 'Time';

      case 3:
        return (abbreviation) ? 'cal' : 'Calories';

      case 4:
        return (abbreviation) ? 'kg' : 'Kilograms';

      case 5:
        return (abbreviation) ? 'm' : 'Meters';

      case 6:
        return (abbreviation) ? 'lb' : 'Pounds';

      default:
        return '';
    }
  }


  public static allUnits(lang: string = 'pt'): Unit[]
  {
    const language = lang.replace('-BR', '');
    const units: Unit[] = [
      {
        id: 1,
        name: (language === 'pt') ? 'Repetições' : 'Repetitions',
        unit: 'reps',
      },
      {
        id: 2,
        name: (language === 'pt') ? 'Tempo' : 'Time',
        unit: '',
      },
      {
        id: 4,
        name: (language === 'pt') ? 'Peso em kg' : 'Weight kg',
        unit: 'kg',
      },
      {
        id: 6,
        name: (language === 'pt') ? 'Peso em lbs' : 'Weight lbs',
        unit: 'lbs',
      },
      {
        id: 3,
        name: (language === 'pt') ? 'Calorias' : 'Calories',
        unit: 'cal',
      },
      {
        id: 5,
        name: (language === 'pt') ? 'Distância' : 'Distance',
        unit: 'm',
      }
    ];

    return units;
  }

  public static getUnitsTypes(lang: string): Unit[]
  {
    return this.allUnits(lang);
  }

  public static getUnitPerId(id: number, lang: string = 'pt'): Unit
  {
    let unit: any;
    this.allUnits(lang).forEach(u => {
      if (u.id === id) {
        unit = u;
      }
    });

    return unit;
  }

  
  /**
   * validateChampionshipHasTeamAndSingle
   */
  public static validateChampionshipHasTeamAndSingle(competitions: any[]): { hasTeam: boolean, hasSingle: boolean }
  {
    let hasTeam = false;
    let hasSingle = false;

    if (competitions && competitions.length > 0) {
      competitions.forEach(competition => {
        console.log(competition);
        if (competition.team_size !== null && competition.team_size > 1) { hasTeam = true; }
        if (competition.team_size === null || competition.team_size === 1) { hasSingle = true; }
      });  
    }
    
    return { hasTeam, hasSingle };
  }

  /**
   * validateChampionshipHasTeamAndSingle
   */
  public static getCompetitionsPerType(competitions: Competition[], type: string): Competition[]
  {
    console.log('type search', type);
    const competitionsWithType: Competition[] = [];

    if (competitions && competitions.length > 0) {
      competitions.forEach((competition: Competition) => {
        if (type === 'team' && competition.team_size !== null && competition.team_size > 1) {
          competitionsWithType.push(competition);
        }
        else if (type === 'single' && (competition.team_size === null || competition.team_size === 1)) {
          competitionsWithType.push(competition);
        }
      });
    }

    return competitionsWithType;
  }
  
  // Form 
  public static formValid(formGroup: FormGroup) 
  {
    if (formGroup && formGroup.controls) {
      Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  public static formSetHasError(formsGroup: FormGroup[]) {
    formsGroup.forEach(form => {
      this.formValid(form);
      console.log(form.controls);
    });
  }

  public static formsVerifyIsValid(formsGroup: FormGroup[]): boolean {
    let isValid = true;

    formsGroup.forEach(form => {
      Object.keys(form.controls).forEach(controlName => {
        if (form.controls[controlName].invalid) {
          isValid = controlName === 'endereco';
        }
      });
    });

    return isValid;
  }

  public static minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);

      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

  public static formatCurrency(value: string, currency: string): string 
  {
    let money = value;
    if (currency === 'BRL') {
      money = money.replace(',', '*');
      money = money.replace('.', ',');
      money = money.replace('*', '.');
    }

    return money;
  }

  public static formatMoney(amount, decimalCount = 2, decimal = ',', thousands = '.')
  {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? '-' : '';

      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - parseInt(i)).toFixed(decimalCount).slice(2) : '');
    } catch (e) {
      console.log(e);
    }
  }

  // download
  public static forceDownloadWithUrl(url: string)
  {
    const link = document.createElement('a');
    link.href = url;
    // link.download = 'participantes.xls'
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public static openInOtherWindow(url: string) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    // link.download = 'participantes.xls'
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public static sanitizeUrl(url: string) {
    const domSanitizer = new ɵDomSanitizerImpl(document);
    const sanitizedUrl = domSanitizer.sanitize(SecurityContext.URL, url);
    const trustedUrl = domSanitizer.bypassSecurityTrustUrl(sanitizedUrl);
  
    if (typeof trustedUrl === 'string') return trustedUrl;
    
    if (trustedUrl['changingThisBreaksApplicationSecurity']) {
      return trustedUrl['changingThisBreaksApplicationSecurity'];
    }
  
    throw new Error('URL not sanitized');
  }

  public static forceDownloadWithValidationURL(url: string) {
    const safeUrl = this.sanitizeUrl(url);
    this.forceDownloadWithUrl(safeUrl);
  }

  public static openInOtherWindowWithValidationURL(url: string) {
    const safeUrl = this.sanitizeUrl(url);
    this.openInOtherWindow(safeUrl);
  }

  // T-shirt
  public static getTShirts()
  {
    return [
      'P',
      'M',
      'G',
      'GG'
    ];
  }

  public static getTShirt(value: string) 
  {
    return value;
  }

  // Regexp
  public static getRegUrlFormat() {
    const reg = /^(http?|https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/; // '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
    return reg;
  }

  // Remove paragraph in editor
  public static removeParagraphInText(value: string): string
  {
    value = value.replace(new RegExp('<p><br></p>', 'g'), '<br>');
    value = value.replace(new RegExp('<p>', 'g'), '');
    value = value.replace(new RegExp('</p>', 'g'), '<br>');

    return value;
  }

  
  public static getCardBrandIcon(cc_brand: string): string {
    switch (cc_brand) {
      case CardBrand.AMEX:
        return 'ic-amex.svg';

      case CardBrand.AURA:
        return 'ic-aura.svg';

      case CardBrand.DINNERS:
        return 'ic-dinners.svg';

      case CardBrand.DISCOVERER:
        return 'ic-discover.svg';

      case CardBrand.ELO:
        return 'ic-elo.svg';

      case CardBrand.HIPERCARD:
        return 'ic-hipercard.svg';

      case CardBrand.MASTERCARD:
        return 'ic-mastercard.svg';

      case CardBrand.VISA:
        return 'ic-visa.svg';

      case CardBrand.JCB:
        return 'ic-jcb.svg';

      default:
        return '';
    }
  }

  // Vídeo
  /*public static getYoutubeVideoId(url: string): string {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*!/;
    const match = url.match(regExp);
  
    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return '';
    }
  }*/
  
  public static getYoutubeVideoId(url: string): string
  {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }
  
  public static formatYoutubeEmbedUrl(url: string): string
  {
    const videoId = this.getYoutubeVideoId(url);
    return `//www.youtube.com/embed/${videoId}`;
  }
  
  public static getUrlToEmbedVideo(url: string): string
  {
    if (url.indexOf('youtube') > 0 || url.indexOf('youtu.be') > 0) {
      return this.formatYoutubeEmbedUrl(url);
    }
    
    return url;
  }
}
