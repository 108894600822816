<div mat-dialog-content class="dialog-container">
  <!-- <div class="modal-background" (click)="dialogRef.close()"></div> -->
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>{{ (isEdit) ? translate.EDIT_COMPETITION : translate.NEW_COMPETITION | uppercase }}</h1>
      <span class="close" (click)="dialogRef.close(null)">
        <img src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>

    <div fxLayout="row" class="mt-16 mb-32">
      <description-section title="{{ translate.INFO_TITLE }}" text="{{ translate.INFO_DESCRIPTION }}">
        <mat-icon class="ic-info mr-8" svgIcon="ic-cat-info"></mat-icon>
      </description-section>
    </div>

    <div fxLayout="column" fxLayoutAlign="start stretch">
      <div [ngClass]="{'box-line': !isEdit, 'p-16': !isEdit}" class="mb-16" *ngFor="let competition of competitions; let i = index">
        <btn-transparent
          icon="common/ic-arrow-up-collapsible.svg"
          buttonWidth="35px" iconAlign="left"
          [ngClass]="{'rotate-180': !competition.isOpen}"
          class="btn-collapsible"
          (click)="actionCollapsible(); competition.isOpen = !competition.isOpen" *ngIf="!isEdit"></btn-transparent>
        <h3 class="ml-8 m-0 mt-8" *ngIf="!competition.isOpen">{{ competition.name | uppercase }}</h3>

        <div [fxShow]="competition.isOpen">
          <div fxLayout="column" fxLayoutAlign="stretch stretch">
            <div fxFlex="100" fxLayout="row wrap" fxLayout.xs="column">
              <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-8 pl-8">
                <input matInput placeholder="{{ translate.FIELDS.NAME }}" [(ngModel)]="competition.name" [formControl]="competition.nameFormControl" [errorStateMatcher]="competition.matcher" uppercase required>
                <mat-error *ngIf="competition.nameFormControl.hasError('required') || competition.nameRequired">
                  {{ translate.VALIDATIONS.NAME_REQUIRED }}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="25" fxFlex.xs="100" class="pr-8 pl-8">
                <input type="number" matInput placeholder="{{ translate.FIELDS.START_NUMBER }}" [formControl]="competition.startNumberFormControl" [(ngModel)]="competition.start_number" required>
                <mat-error *ngIf="competition.startNumberFormControl.hasError('required')">
                  {{ translate.VALIDATIONS.START_NUMBER_REQUIRED }}
                </mat-error>
                <mat-error *ngIf="competition.startNumberFormControl.hasError('min')">
                  {{ translate.VALIDATIONS.START_NUMBER_MIN }}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="25" fxFlex.xs="100" class="pr-8 pl-8" *ngIf="!competition.enable_sub_division">
                <input type="number" matInput placeholder="{{ translate.FIELDS.LIMIT }}" [(ngModel)]="competition.limit">
              </mat-form-field>
            </div>
            <div fxFlex="100">
              <mat-form-field fxFlex="25" fxFlex.xs="100" class="pr-8 pl-8">
                <input type="number" matInput placeholder="{{ 'MODAL_COMPETITION.FIELDS.CUT_LINE' | translate }}" [formControl]="competition.cutLineFormControl" [(ngModel)]="competition.cut_line">
                <mat-error *ngIf="competition.cutLineFormControl.hasError('min')">
                  {{ 'MODAL_COMPETITION.VALIDATIONS.CUT_LINE_MIN' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="100" fxLayout="row" fxLayout.xs="column" class="team-row">
              <mat-slide-toggle class="pr-8 pl-8 pt-16" [checked]="competition.team" [(ngModel)]="competition.team">{{ translate.FIELDS.TEAM }}</mat-slide-toggle>
              <mat-form-field fxFlex="25" fxFlex.xs="100" class="pr-8 pl-8" [fxShow]="competition.team">
                <input type="number" matInput placeholder="{{ translate.FIELDS.TEAM_SIZE }}" [(ngModel)]="competition.team_size" required>
                <mat-error *ngIf="competition.teamSizeRequired">
                  {{ translate.VALIDATIONS.TEAMSIZE_REQUIRED }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="100" fxLayout="column" *ngIf="championship.enable_sub_division">
              <div *ngIf="championship.enable_sub_division" fxFlex="100" fxLayout="column" class="team-row">
                <mat-slide-toggle class="pr-8 pl-8 pt-16" [checked]="competition.enable_sub_division" [(ngModel)]="competition.enable_sub_division">
                  {{ (championship.sub_division_group_name ? ("CAT_LOT_WOD.SUB_DIVISION.ENABLE_CUSTOM" | translate: {sub_division: (championship.sub_division_group_name | lowercase)}) : ("CAT_LOT_WOD.SUB_DIVISION.ENABLE" | translate)) }}
                </mat-slide-toggle>
              </div>
              <div fxFlex="100" fxLayout="column" *ngIf="competition.enable_sub_division">
                <h3 class="pb-0 mb-0">
                  {{ (championship.sub_division_group_name ? ("CAT_LOT_WOD.SUB_DIVISION.LIMIT_CUSTOM" | translate: {sub_division: (championship.sub_division_group_name | lowercase)}) : ("CAT_LOT_WOD.SUB_DIVISION.LIMIT" | translate)) }}
                </h3>
                <div [className]="isEdit ? 'sub-division-form-list sub-division-form-list-edit' : 'sub-division-form-list'">
                  <table-x #tableSubDivision [items]="competition.sub_division_limits" [config]="tableCatConfig" [isDraggable]="false">
                    <column 
                      class="p-0"
                      property="sub_division"
                      header="{{ (championship.sub_division_group_name ? championship.sub_division_group_name : 'CAT_LOT_WOD.SUB_DIVISION.TITLE' | translate) }}"
                      [sortable]="false"
                      align="left"
                    >
                      <ng-template #dataTableCell let-item="item" let-index="index">
                        {{ item.sub_division.name ? (item.sub_division.name | uppercase) : '' }}
                      </ng-template>
                    </column>
                    <column property="limit" header="{{ 'MODAL_COMPETITION.LIMIT' | translate }}" align="center" width="30px">
                      <ng-template #dataTableCell let-item="item" let-index="index">
                        <mat-form-field>
                          <input matInput type="number" value="{{ item.limit }}" [formControl]="item.limitFormControl" [(ngModel)]="item.limit">
                          <mat-error *ngIf="item.limitFormControl.hasError('min')">
                            {{ 'MODAL_COMPETITION.VALIDATIONS.LIMIT_MIN' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </ng-template>
                    </column>
                    <column property="cut_line" header="{{ 'MODAL_COMPETITION.CUT_LINE' | translate }}" align="center" width="30px">
                      <ng-template #dataTableCell let-item="item" let-index="index">
                        <mat-form-field>
                          <input matInput type="number" value="{{ item.cut_line }}" [formControl]="item.cutLineFormControl" [(ngModel)]="item.cut_line">
                          <mat-error *ngIf="item.cutLineFormControl.hasError('min')">
                            {{ 'MODAL_COMPETITION.VALIDATIONS.CUT_LINE_MIN' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </ng-template>
                    </column>
                  </table-x>
                </div>
              </div>
            </div>

            <div fxFlex="100" fxLayout="row" fxLayoutAlign="end end" fxLayout.xs="column">
              <btn-transparent fxFlex="35px" materialIcon="delete" buttonWidth="35px" iconAlign="left" (click)="removeCompetition(i)" *ngIf="!isEdit && competitions.length > 1"></btn-transparent>
            </div>
          </div>
        </div>
      </div>
      <btn-icon-small icon="add_circle_outline" iconAlign="left" (click)="addCompetition()" *ngIf="!isEdit">{{ translate.ADD_COMPETITION }}</btn-icon-small>
    </div>

    <div class="mt-16 mb-8 text-red" *ngIf="requestErrors !== null">
      {{ requestErrors }}
    </div> 

    <div class="mb-0 mt-8" fxLayoutAlign="end center">
      <btn type="grayscale" (click)="dialogRef.close(null)" class="mr-16">{{ translate.CANCEL | uppercase }}</btn>
      <btn [btnLoading]="true" [loading]="isLoading" (click)="saveCompetitions()">{{ translate.SAVE | uppercase }}</btn>
    </div>
  </div>
</div>