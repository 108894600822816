<div class="page-layout blank pb-64">
  <div class="p-32">
    <div class="content">
      <div class="card auto-width mb-32 p-32" [ngClass.gt-sm]="'mb-0'">
        <description-section
          title="{{'INFORMATION.MY_INFORMATION.TITLE' | translate}}"
          text="{{'INFORMATION.MY_INFORMATION.DESCRIPTION' | translate: { event_name: event?.name, max_date_update: maxDateUpdate } }}">
          <mat-icon class="ic-info mr-8" svgIcon="ic-info"></mat-icon>
        </description-section>

        <form name="profileForm" [formGroup]="profileForm" autocomplete="off" novalidate>
          <div fxLayout="row" fxLayout.xs="column" class="mt-32">
            <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8 pr-8">
              <input matInput placeholder="{{'INFORMATION.MY_PROFILE.LEADERBOARD_NAME' | translate}}" formControlName="leaderboardName" [errorStateMatcher]="matchers.leaderboardName" uppercase required>
              <mat-error *ngIf="profileForm.controls.leaderboardName.hasError('required')">
                {{'INFORMATION.MY_PROFILE.VALIDATION.LEADERBOARD_NAME_REQUIRED' | translate}}
              </mat-error>
            </mat-form-field>
          
            <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8 pr-8">
              <input matInput placeholder="{{'INFORMATION.MY_PROFILE.BOX' | translate}}" formControlName="box" [errorStateMatcher]="matchers.box" [matAutocomplete]="autoBox"
                (ngModelChange)="searchBox($event)" uppercase>
              <mat-autocomplete #autoBox="matAutocomplete" [displayWith]="displayFnBox">
                <mat-option *ngFor="let box of boxes" [value]="box">
                  {{ box.name }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="profileForm.controls.box.hasError('required')">
                {{'INFORMATION.MY_PROFILE.VALIDATION.BOX_REQUIRED' | translate}}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.xs="column">
            <mat-form-field fxFlex="50" fxFlex.xs="100" class="pl-8 pr-8">
              <mat-select placeholder="{{'INFORMATION.MY_PROFILE.TSHIRT' | translate}}" [errorStateMatcher]="matchers.tShirt" formControlName="tShirt" required>
                <mat-option *ngFor="let size of tshirtSize" [value]="size">
                  {{ size }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="profileForm.controls.tShirt.hasError('required')">
                 {{'INFORMATION.MY_PROFILE.VALIDATION.TSHIRT_REQUIRED' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
        </form>

        <div fxLayout="row" fxLayoutAlign="end center" class="mt-8" *ngIf="enabledEditProfile">
          <btn [loading]="isProfileLoading" [btnLoading]="true" class="ml-16" (click)="updateUserInfo()">{{'INFORMATION.SAVE' | translate | uppercase}}</btn>
        </div>
      </div>

      <div class="card auto-width mb-32 p-32" [ngClass.gt-sm]="'mb-0'" *ngIf="team !== null && team.type === 'team'">
        <description-section 
          title="{{'INFORMATION.TEAM.TITLE' | translate}}"
          text="{{ meIsCaptain ? ('INFORMATION.TEAM.DESCRIPTION_CAPTAIN' | translate: { event_name: event?.name, max_date_update: maxDateUpdate }) : ('INFORMATION.TEAM.DESCRIPTION' | translate: { event_name: event?.name }) }}">
          <mat-icon class="ic-info mr-8" svgIcon="ic-info"></mat-icon>
        </description-section>

        <div fxLayout="row" fxLayoutAlign="space-around center" class="mt-32 mb-32 pb-32 line-bottom">
          <div fxFlex="50" fxFlex.xs="100" fxLayout="column">
            <!-- <span class="competitor-number">0001</span> -->
            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="!isEditTeamName">
              <h1 class="team-name">{{ team.name }}</h1>
              <btn-transparent materialIcon="create" buttonWidth="35px" iconAlign="left" color="#4C6070" class="ml-8" (click)="isEditTeamName = !isEditTeamName" *ngIf="meIsCaptain && enabledEditProfile" ></btn-transparent>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="isEditTeamName">
              <mat-form-field fxFlex="auto" class="pr-8">
                <input
                  matInput
                  placeholder="{{'INFORMATION.TEAM.TEAM_NAME' | translate}}"
                  [(ngModel)]="team.name"
                  [value]="team.name"
                  required>
                <mat-error *ngIf="profileFormErrors.leaderboardName.required">
                  {{'INFORMATION.TEAM.VALIDATION.TEAM_NAME_REQUIRED' | translate}}
                </mat-error>
              </mat-form-field>
              <btn-transparent [loading]="isLoadingTeamName" materialIcon="done" buttonWidth="35px" iconAlign="left" color="#4C6070" class="ml-8" (click)="saveTeamName()"></btn-transparent>
            </div>
          </div>

          <div fxFlex="50" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="end end" fxLayoutAlign.xs="start start">
            <div fxFlex="auto" fxLayout="column" fxLayoutAlign="start start">
<!--              <span><b>{{'INFORMATION.TEAM.CATEGORY' | translate}}:</b> {{ championship.competition?.name }}</span>-->
<!--              <span><b>{{'INFORMATION.TEAM.STATUS' | translate}}:</b> Ativo</span>-->
            </div>
          </div>
        </div>

        <h4 *ngIf="team !== null && team.type === 'team'">{{'INFORMATION.TEAM.MEMBERS' | translate}}</h4>

        <mat-grid-list [cols]="colsGrid" cols.xs="1" rowHeight="232px" (window:resize)="onResize($event)" *ngIf="team !== null && team.type === 'team'">
          <mat-grid-tile *ngFor="let member of team.members">
            <div  class="box-line ml-8 mr-8 team-member">
              <div class="band"></div>
              <div class="box-avatar">
                <img src="{{ (member.user.avatar) ? member.user.avatar : './assets/images/avatars/profile.jpg' }}" class="avatar" />
                <button class="capitain" [ngClass]="{'active': member.capitan}" [ngStyle]="{'cursor': meIsCaptain ? 'pointer' : 'default'}" (click)="setCapitain(member)">C</button>
              </div>
              <div fxLayout="column" fxLayoutAlign="space-evenly center" class="information p-4" >
                <div fxLayout="column" fxLayoutAlign="center center">
                  <span class="name">{{member.leaderboard_name}}</span>
                  <span class="email">{{member.user.email}}</span>
                </div>
                <btn [btnLoading]="true" (click)="member.capitan ? '' : alterMember(member)" *ngIf="meIsCaptain && enabledEditProfile" [ngStyle]="{'opacity': (member.capitan) ? 0 : 1}">{{'INFORMATION.ALTER' | translate | uppercase}}</btn>
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
  </div>
</div>


<!-- <scroll-to-top (scrollToTopEvent)="scrollToTop($event)" [isShow]="isShowScrollToTop"></scroll-to-top> -->