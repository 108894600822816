<header matDialogTitle>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="pl-32 pr-32">
    <h1 class="m-0">{{ translate.TITLE | uppercase }}</h1>
    <img src="./assets/icons/modal/ic-close.svg" class="close" (click)="dialogRef.close(null)" />
  </div>
</header>

<div mat-dialog-content class="dialog-container">
  <div fxLayout="row" fxLayoutAlign="end center" class="pr-32 pt-32" *ngIf="!showPromocodeInput">
    <btn-icon-small icon="add_circle_outline" iconAlign="left" (click)="showPromocodeInput = !showPromocodeInput">{{
      translate.ADD_CUPOM }}</btn-icon-small>
  </div>
  <form [formGroup]="promocodeForm" name="promocodeForm" autocomplete="off" novalidate *ngIf="showPromocodeInput">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutAlign.xs="center center" ngClass="mt-16 pl-32 pr-16" ngClass.xs="mt-16 pl-0 pr-0">
      <mat-form-field fxFlex="auto" ngClass="pr-8" ngClass.xs="p-0" fxFlex.xs="100">
        <input matInput placeholder="{{ translate.PROMOCODE }}" formControlName="promocode" [errorStateMatcher]="matcher" required>
        <mat-error *ngIf="promocodeForm.controls.promocode.hasError('required')">
          {{ translate.PROMOCODE_REQUIRED }}
        </mat-error>
        <mat-error *ngIf="promocodeForm.controls.promocode.hasError('invalid')">
          {{ translate.PROMOCODE_INVALID }}
        </mat-error>
      </mat-form-field>

      <btn type="grayscale" [btnLoading]="true" [loading]="isLoading" [buttonDisabled]="promocodeForm.invalid" (click)="validatePromocode()">{{ translate.VALIDATE | uppercase }}</btn>
    </div>
  </form>

  <div class="lot-item pt-16 pb-16" *ngFor="let lot of lots" [ngClass]="{'active': lot.active, 'disabled': !lot.canSubscribe}" (click)="selectedLot(lot)">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" fxLayoutAlign="center start" class="column1">
        <span class="lot-title">{{ lot.name | uppercase }}</span>
        <span class="lot-end" *ngIf="lot.end && lot.canSubscribe">{{ translate.SUBSCRIPTION_END }} {{ lot.end | localizedDate:translate.DATE_FORMAT }}</span>
        <span class="lot-end" *ngIf="!lot.canSubscribe">{{ translate.NOT_CAN_SUBSCRIBE | uppercase }}</span>
      </div>
      <div fxLayout="column" fxLayoutAlign="center end" class="pr-32">
        <span class="price">{{ formatCurrency((lot.price | currency:translate.CURRENCY_CODE:'symbol-narrow':'1.2-2'),
        translate.CURRENCY_CODE) }}</span>
        <span class="tax" *ngIf="lot.taxPrice > 0">+ {{ formatCurrency((lot.taxPrice | currency:translate.CURRENCY_CODE:'symbol-narrow':'1.2-2'),
        translate.CURRENCY_CODE) }} {{ translate.TAX }}</span>
      </div>
    </div>
  </div>

  <!-- Empty list -->
  <div fxLayout="column" fxLayoutAlign="center center" class="p-32 emtpy-content" *ngIf="lots?.length === 0">
    <img src="./assets/icons/placeholder/ic-empty.svg" />
    <span class="mt-8">{{ 'LOTS_LIST.EMPTY' | translate }}</span>
  </div>
</div>

<div mat-dialog-actions class="mb-0 p-32 pb-0 pt-16" fxLayoutAlign="end center" *ngIf="lots?.length > 0">
  <!--<btn type="grayscale" (click)="dialogRef.close(null)" class="mr-16">{{ translate.CANCEL | uppercase }}</btn>-->
  <btn [loading]="isSelectLoading" [btnLoading]="true" [buttonDisabled]="disabledButton" (click)="finishSelectedLot()">{{ translate.SELECT | uppercase }}</btn>
</div>
