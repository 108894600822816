import { Team } from './team';
import {Lot} from './lot';
import {User} from './user';
import {Payment} from './payment';
import {Coupon} from './coupon';
export class Subscription {
  id: number
  payment_amount?: number
  payments?: any
  hash?: string
  status?: number
  team?: Team = null
  lot?: Lot = null
  user?: User = null
  payment?: Payment = null
  coupon?: Coupon = null

  deserialize(object: any) {
    (<any>Object).assign(this, object);

    this.team = (object.team && object.team !== null) ? new Team().deserialize(object.team) : null
    this.payment = (object.payment && object.payment !== null) ? new Payment().deserialize(object.payment) : null
    this.lot = (object.lot && object.lot !== null) ? new Lot().deserialize(object.lot) : null
    this.user = (object.user && object.user !== null) ? new User().deserialize(object.user) : null
    this.coupon = (object.coupon && object.coupon !== null) ? new Coupon().deserialize(object.coupon) : null

    return this;
  }
}
