import {Component, ElementRef, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {TabBarComponent} from '../../../../../core/components/tab-bar/tab-bar.component';
import {SelectTimeComponent} from '../../../../../core/components/select-time/select-time.component';
import {Event, EventConfigation} from '../../../../../core/model/event';
import {Championship} from '../../../../../core/model/championship';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {QuillConfigInterface, QuillModulesInterface} from 'ngx-quill-wrapper';
import {Observable, Subscription} from 'rxjs';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {BreadcrumbsService} from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import {FuseTranslationLoaderService} from '../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthGuard} from '../../../../../core/guards/auth.guard';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {MatDialog, MatIconRegistry} from '@angular/material';
import {GooglePlacesService} from '../../../../../core/services/google-places.service';
import {DragulaService} from 'ng2-dragula';
import {EventsService} from '../../../../../core/services/event/events.service';
import {ChampionshipService} from '../../../../../core/services/championship/championship.service';
import {ToastrService} from '../../../../../core/components/toastr_/toastr/toastr.service';
import {BankAccountService} from '../../../../../core/services/bank-account/bank-account.service';
import {BanksService} from '../../../../../core/services/banks/banks.service';
import {EventConfigurationsService} from '../../../../../core/services/event-configurations/event-configurations.service';
import { locale as english } from './../i18n/en-US';
import { locale as portugues } from './../i18n/pt-BR';
import {config} from '../../../../../core/config/config';
import * as moment from 'moment';
import {Utils} from '../../../../../core/utils';
import {dateValidator} from '../../../../../core/utils/custom-validators';
import {FormErrorStateMatcher} from '../../../../../core/utils/form-error-state-matcher';
import {map, startWith} from 'rxjs/operators';
import {Errors} from '../../../../../core/model/errors';
import {CrossXDialogComponent} from '../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import {CropDialogComponent} from '../../../../../core/components/crop-dialog/crop-dialog.component';
import * as momentTimezone from 'moment-timezone';

@Component({
  selector: 'app-event-leaderboard',
  templateUrl: './event-leaderboard.component.html',
  styleUrls: ['./event-leaderboard.component.scss']
})
export class EventLeaderboardComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  //Translate 
  public translate: any;

  //Event
  public event: Event;
  public userMe: any = null;

  // checkin-online
  formCheckin: FormGroup;
  isLoadingFormCheckin = false;
  hours: string[] = [];
  minutes: string[] = [];
  startDateCheckin = null;
  endDateCheckin = null;

  // max-date-user-edit
  formMaxDateUserEdit: FormGroup;
  isLoadingFormMaxDateUserEdit = false;
  
  // Leaderboard
  public colors = {
    leaderboard: {
      bg: '#ff0000',
      rowDark: '#f00000',
      text: '#fff000',
      boxName: '#ffff00',
      highlight: '#000000',
      cutLine: '#ff0000',
    },
    leaderboardTv: {
      bg: '#264e0e',
      eventName: '#264e23',
      category: '#2e4e0e',
      cell: '#ff62da',
      progressBar: '#fff000'
    }
  };

  public isShowBrandInLeaderboard = false;
  public isLeaderboardPublishLoading = false;
  public isLeaderboardLoading = false;
  
  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private googlePlacesService: GooglePlacesService,
    public dialog: MatDialog,
    private dragulaService: DragulaService,
    private eventService: EventsService,
    private championshipService: ChampionshipService,
    private toastr: ToastrService,
    private bankAccountService: BankAccountService,
    private banksService: BanksService,
    private eventConfigurationService: EventConfigurationsService
  ) {
    this.translationLoader.loadTranslations(english, portugues);

    this.subscriptions.add(this.translateService.get('SETTINGS').subscribe((res: any) => {
      this.translate = res;
    }));

    if (this.translate) {
      this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE}`);

      this.breadcrumbsService.replaceAll([{
        text: this.translate.TITLE_LEADERBOARD,
        route: `/event/leaderboard`
      }]);
    }

    this.event = new Event().deserialize(this.eventGuard.getActiveEvent().event);
    console.log(this.event);
    this.isShowBrandInLeaderboard = this.event.isShowBrandInLeaderboard;
    this.userMe = this.authGuard.getMe().user;

    const bag: any = this.dragulaService.find('columns-leaderboard-tv');
    if (bag !== undefined) { this.dragulaService.destroy('columns-leaderboard-tv'); }

    dragulaService.createGroup('columns-leaderboard-tv', {
      revertOnSpill: true
    });

    // dragulaService.drag.subscribe((value: any) => {
    //   // console.log(`drag: ${value[0]}`); // value[0] will always be bag name
    //   this.onDrag(value.slice(1));
    // });
    // dragulaService.drop.subscribe((value: any) => {
    //   console.log(`drop: ${value[0]}`);
    //   this.onDrop(value.slice(1));
    // });
    // dragulaService.over.subscribe((value: any) => {
    //   // console.log(`over: ${value[0]}`);
    //   this.onOver(value.slice(1));
    // });
    // dragulaService.out.subscribe((value: any) => {
    //   // console.log(`out: ${value[0]}`);
    //   this.onOut(value.slice(1));
    // });
    this.registerFormCheckinOnline();
    this.registerFormMaxDateUserEdit();
  }

  ngOnInit() {
      //  Leaderboard
      this.populateColorsWithLeaderboard();
    
      // colors = {
      //   leaderboard: {
      //     bg: "#ff0000",
      //     rowDark: "#f00000",
      //     text: "#fff000",
      //     boxName: '#ffff00',
      //     highlight: '#000000'
      //   },
      //   leaderboardTv: {
      //     bg: '#264e0e',
      //     eventName: '#264e23',
      //     category: '#2e4e0e',
      //     cell: '#ff62da',
      //     progressBar: '#fff000'
      //   }
      // }
    
    this.getEventDetail();

  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private registerFormMaxDateUserEdit(): void {
    this.formMaxDateUserEdit = this.formBuilder.group({
      'date': [null, [Validators.required, dateValidator(this.translate.DATE_FORMAT)]],
      'hour': [null, Validators.required],
      'minutes': [null, Validators.required],
    });
  }

  private registerFormCheckinOnline(): void {
    for (let index = 0; index < 24; index++){
      if (index < 10) {
        this.hours.push(`0${index}`);
      }
      else {
        this.hours.push(`${index}`);
      }
    }

    for (let index = 0; index < 60; index++) {
      if (index < 10) {
        this.minutes.push(`0${index}`);
      }
      else {
        this.minutes.push(`${index}`);
      }
    }
    this.formCheckin = this.formBuilder.group({
      'start_date_date': [null, [Validators.required, dateValidator(this.translate.DATE_FORMAT)]],
      'start_date_hour': [null, Validators.required],
      'start_date_minutes': [null, Validators.required],
      'end_date_date': [null, [Validators.required, dateValidator(this.translate.DATE_FORMAT)]],
      'end_date_hour': [null, Validators.required],
      'end_date_minutes': [null, Validators.required],
      'enabled_checkin_file': [false]
    });
  }

  public onSubmitFormMaxDateUserEdit(): void {
    this.isLoadingFormMaxDateUserEdit = true;
    const maxDate = moment(
      `${this.formMaxDateUserEdit.value.date} ${this.formMaxDateUserEdit.value.hour}:${this.formMaxDateUserEdit.value.minutes}:00`, 
      this.translate.DATETIME_FORMAT
    );
    const parameters = {
      configurations: [
        {
          name: EventConfigation.MAX_DATE_USER_EDIT,
          value: momentTimezone.tz(maxDate.format('YYYY-MM-DD HH:mm:ss'), this.event.timezone).tz('GMT').format('YYYY-MM-DD HH:mm:ss')
        },
      ]
    };

    this.subscriptions.add(
      this.eventConfigurationService.updateConfigs(parameters, this.event.id)
        .subscribe(
          result => {
            this.isLoadingFormMaxDateUserEdit = false;
            if (localStorage.getItem('current_event_crossplus')) {
              const currentEventCrossplus = JSON.parse(localStorage.getItem('current_event_crossplus'));
              result.forEach((configutation) => {
                const index = currentEventCrossplus.event.event_configurations.findIndex((eventConfiguration) => eventConfiguration.name === configutation.name);
                currentEventCrossplus.event.event_configurations.splice(index, 1);
                currentEventCrossplus.event.event_configurations.push(configutation);
              });
              localStorage.setItem('current_event_crossplus', JSON.stringify(currentEventCrossplus));
            }
            this.toastr.show(
              this.translate.MAX_DATE_USER_EDIT.TOASTR.SUCCESS.DESCRIPTION,
              this.translate.MAX_DATE_USER_EDIT.TOASTR.SUCCESS.TITLE,
              null,
              'success',
            );
          }, err => {
            this.isLoadingFormMaxDateUserEdit = false;

            const errors: Errors = new Errors().deserialize((err.error as any));
            this.toastr.show(
              errors.getFullMessages(),
              this.translate.LEADERBOARD.TOASTR.LEADERBOARD.ERROR.TITLE,
              null,
              'error',
            );
          }
        )
    );
  }

  public onSubmitFormCheckin(): void {
    this.isLoadingFormCheckin = true;
    this.startDateCheckin = moment(
      `${this.formCheckin.value.start_date_date} ${this.formCheckin.value.start_date_hour}:${this.formCheckin.value.start_date_minutes}:00`, 
      this.translate.DATETIME_FORMAT
    );
    this.endDateCheckin = moment(
      `${this.formCheckin.value.end_date_date} ${this.formCheckin.value.end_date_hour}:${this.formCheckin.value.end_date_minutes}:00`, 
      this.translate.DATETIME_FORMAT
    );
    if (this.startDateCheckin.diff(this.endDateCheckin) >= 0) {
      this.isLoadingFormCheckin = false;
      this.toastr.show(
        this.translate.CHECKIN_ONLINE.TOASTR.ERROR_DATE,
        this.translate.LEADERBOARD.TOASTR.LEADERBOARD.ERROR.TITLE,
        null,
        'error',
      );
      return;
    }
    const parameters = {
      configurations: [
        {
          name: EventConfigation.START_CHECKIN_ONLINE,
          value: momentTimezone.tz(this.startDateCheckin.format('YYYY-MM-DD HH:mm:ss'), this.event.timezone).tz('GMT').format('YYYY-MM-DD HH:mm:ss')
        },
        {
          name: EventConfigation.END_CHECKIN_ONLINE,
          value: momentTimezone.tz(this.endDateCheckin.format('YYYY-MM-DD HH:mm:ss'), this.event.timezone).tz('GMT').format('YYYY-MM-DD HH:mm:ss')
        },
        {
          name: EventConfigation.ENABLED_CHECKIN_FILE,
          value: this.formCheckin.value.enabled_checkin_file
        },
      ]
    };

    this.subscriptions.add(
      this.eventConfigurationService.updateConfigs(parameters, this.event.id)
        .subscribe(
          result => {
            this.isLoadingFormCheckin = false;
            if (localStorage.getItem('current_event_crossplus')) {
              const currentEventCrossplus = JSON.parse(localStorage.getItem('current_event_crossplus'));
              result.forEach((configutation) => {
                const index = currentEventCrossplus.event.event_configurations.findIndex((eventConfiguration) => eventConfiguration.name === configutation.name);
                if (index >= 0) {
                  currentEventCrossplus.event.event_configurations.splice(index, 1);
                }
                currentEventCrossplus.event.event_configurations.push(configutation);
              });
              localStorage.setItem('current_event_crossplus', JSON.stringify(currentEventCrossplus));
            }
            this.toastr.show(
              this.translate.CHECKIN_ONLINE.TOASTR.SUCCESS.DESCRIPTION,
              this.translate.CHECKIN_ONLINE.TOASTR.SUCCESS.TITLE,
              null,
              'success',
            );
          }, err => {
            this.isLoadingFormCheckin = false;

            const errors: Errors = new Errors().deserialize((err.error as any));
            this.toastr.show(
              errors.getFullMessages(),
              this.translate.LEADERBOARD.TOASTR.LEADERBOARD.ERROR.TITLE,
              null,
              'error',
            );
          }
        )
    );
  }

  getEventDetail() {
    // Get Event details
    this.subscriptions.add(this.eventService.getEventInformation(this.event.id)
      .subscribe(
        result => {
          console.log('result', result);
          this.populateColorsWithLeaderboard();
        }, err => { }
      )
    );
  }

  publishOrUnpublishLeaderboadEvent() {
    if (this.event.leaderboard_published) { this.unpublishLeaderboardEvent(); }
    else { this.publishLeaderboardEvent(); }
  }

  publishLeaderboardEvent() {
    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    this.subscriptions.add(
      this.translateService.get('SETTINGS.LEADERBOARD.PUBLISH_DIALOG.TITLE', { action: this.translate.LEADERBOARD.PUBLISH_DIALOG.PUBLISH }).subscribe((res: string) => {
        dialogRef.componentInstance.titleDialog = res;
      })
    );
    this.subscriptions.add(
      this.translateService.get('SETTINGS.LEADERBOARD.PUBLISH_DIALOG.DESCRIPTION', { action: this.translate.LEADERBOARD.PUBLISH_DIALOG.PUBLISH }).subscribe((res: string) => {
        dialogRef.componentInstance.dialogContent = res;
      })
    );
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.LEADERBOARD.PUBLISH_DIALOG.PUBLISH.toUpperCase();
    dialogRef.componentInstance.object = this.event;
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isLeaderboardPublishLoading = true;

          const event = {
            leaderboard_published: 1,
          };

          const media = {
            brand: null,
            header: null,
            term: null,
            regulation: null
          };
          this.subscriptions.add(
            this.eventService.updateEvent(this.event.id, event, media)
              .subscribe(
              result => {
                console.log('result', result);
                this.isLeaderboardPublishLoading = false;
                this.event.leaderboard_published = true;
                this.eventGuard.updateEventInformation(this.event);

                this.toastr.show(
                  this.translate.LEADERBOARD.TOASTR.PUBLISH_DESCRIPTION,
                  this.translate.LEADERBOARD.TOASTR.PUBLISH_TITLE,
                  null,
                  'success',
                );
              }, err => {
                console.log('err', err);
                this.isLeaderboardPublishLoading = false;

                const errors: Errors = new Errors().deserialize((err.error as any));
                this.toastr.show(
                  errors.getFullMessages(),
                  this.translate.TOASTR.ERROR.TITLE,
                  null,
                  'error',
                );
              }
            )
          );
        }
      })
    );
  }

  unpublishLeaderboardEvent() {
    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    this.subscriptions.add(
      this.translateService.get('SETTINGS.LEADERBOARD.PUBLISH_DIALOG.TITLE', { action: this.translate.LEADERBOARD.PUBLISH_DIALOG.UNPUBLISH }).subscribe((res: string) => {
        dialogRef.componentInstance.titleDialog = res;
      })
    );
    this.subscriptions.add(
      this.translateService.get('SETTINGS.LEADERBOARD.PUBLISH_DIALOG.DESCRIPTION', { action: this.translate.LEADERBOARD.PUBLISH_DIALOG.UNPUBLISH }).subscribe((res: string) => {
        dialogRef.componentInstance.dialogContent = res;
      })
    );
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.LEADERBOARD.PUBLISH_DIALOG.UNPUBLISH.toUpperCase();
    dialogRef.componentInstance.object = this.event;
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isLeaderboardPublishLoading = true;

          const parameters = {
            leaderboard_published: 0
          };
          this.subscriptions.add(
            this.eventService.updateEvent(this.event.id, parameters)
              .subscribe(
                result => {
                  console.log('result', result);
                  this.isLeaderboardPublishLoading = false;
                  this.event.leaderboard_published = false;
                  this.eventGuard.updateEventInformation(this.event);

                  this.toastr.show(
                    this.translate.LEADERBOARD.TOASTR.UNPUBLISH_DESCRIPTION,
                    this.translate.LEADERBOARD.TOASTR.UNPUBLISH_TITLE,
                    null,
                    'success',
                  );
                }, err => {
                  console.log('err', err);
                  this.isLeaderboardPublishLoading = false;

                  const errors: Errors = new Errors().deserialize((err.error as any));
                  this.toastr.show(
                    errors.getFullMessages(),
                    this.translate.TOASTR.ERROR.TITLE,
                    null,
                    'error',
                  );
                }
              )
          );
        }
      })
    );
  }

  private populateColorsWithLeaderboard() {
    if (this.event.event_configurations && this.event.event_configurations.length > 0)  {
      this.event.event_configurations.forEach(config => {
        if (config.name === EventConfigation.START_CHECKIN_ONLINE) {
          this.startDateCheckin = config.value;
          this.formCheckin.setValue({
            ...this.formCheckin.value,
            start_date_date: momentTimezone.tz(this.startDateCheckin, 'GMT').tz(this.event.timezone).format(this.translate.DATE_FORMAT),
            start_date_hour: momentTimezone.tz(this.startDateCheckin, 'GMT').tz(this.event.timezone).format('HH'),
            start_date_minutes: momentTimezone.tz(this.startDateCheckin, 'GMT').tz(this.event.timezone).format('mm'),
          });
          return;
        }
        if (config.name === EventConfigation.END_CHECKIN_ONLINE) {
          this.endDateCheckin = config.value;
          this.formCheckin.setValue({
            ...this.formCheckin.value,
            end_date_date: momentTimezone.tz(this.endDateCheckin, 'GMT').tz(this.event.timezone).format(this.translate.DATE_FORMAT),
            end_date_hour: momentTimezone.tz(this.endDateCheckin, 'GMT').tz(this.event.timezone).format('HH'),
            end_date_minutes: momentTimezone.tz(this.endDateCheckin, 'GMT').tz(this.event.timezone).format('mm'),
          });
          return;
        }
        if (config.name === EventConfigation.ENABLED_CHECKIN_FILE) {
          this.formCheckin.setValue({
            ...this.formCheckin.value,
            enabled_checkin_file: Number(config.value) === 1
          });
          return;
        }
        if (config.name === EventConfigation.MAX_DATE_USER_EDIT) {
          const maxDate = config.value;
          this.formMaxDateUserEdit.setValue({
            ...this.formMaxDateUserEdit.value,
            date: momentTimezone.tz(maxDate, 'GMT').tz(this.event.timezone).format(this.translate.DATE_FORMAT),
            hour: momentTimezone.tz(maxDate, 'GMT').tz(this.event.timezone).format('HH'),
            minutes: momentTimezone.tz(maxDate, 'GMT').tz(this.event.timezone).format('mm'),
          });
          return;
        }
        if (config.name === 'leaderboard-background-color') { this.colors.leaderboard.bg = config.value; }
        else if (config.name === 'leaderboard-dark-color') { this.colors.leaderboard.rowDark = config.value; }
        else if (config.name === 'leaderboard-light-color') { this.colors.leaderboard.text = config.value; }
        else if (config.name === 'leaderboard-box-name-color') { this.colors.leaderboard.boxName = config.value; }
        else if (config.name === 'leaderboard-highlight-color') { this.colors.leaderboard.highlight = config.value; }
        else if (config.name === EventConfigation.LEADERBOARD_CUT_LINE_COLOR) { this.colors.leaderboard.cutLine = config.value; }
      });
    }
  }

  saveLeaderboardColors() {

    for (const leaderboardKey in this.colors.leaderboard) {
      if (this.colors.leaderboard[leaderboardKey].length === 0) {
        this.toastr.show(
          this.translate.LEADERBOARD.TOASTR.LEADERBOARD.ERROR.DESCRIPTION,
          this.translate.LEADERBOARD.TOASTR.LEADERBOARD.ERROR.TITLE,
          null,
          'error',
        );

        return;
      }
    }

    const parameters = {
      configurations: [
        {
          name: 'leaderboard-background-color',
          value: this.colors.leaderboard.bg
        },
        {
          name: 'leaderboard-dark-color',
          value: this.colors.leaderboard.rowDark
        },
        {
          name: 'leaderboard-light-color',
          value: this.colors.leaderboard.text
        },
        {
          name: 'leaderboard-box-name-color',
          value: this.colors.leaderboard.boxName
        },
        {
          name: 'leaderboard-highlight-color',
          value: this.colors.leaderboard.highlight
        },
        {
          name: EventConfigation.LEADERBOARD_CUT_LINE_COLOR,
          value: this.colors.leaderboard.cutLine
        },
        {
          name: 'leaderboard-show-brand',
          value: this.isShowBrandInLeaderboard
        }
      ]
    };

    console.log(parameters);
    this.isLeaderboardLoading = true;
    this.subscriptions.add(
      this.eventConfigurationService.updateConfigs(parameters, this.event.id)
        .subscribe(
          result => {
            console.log('result', result);
            this.isLeaderboardLoading = false;

            this.toastr.show(
              this.translate.LEADERBOARD.TOASTR.LEADERBOARD.SUCCESS.DESCRIPTION,
              this.translate.LEADERBOARD.TOASTR.LEADERBOARD.SUCCESS.TITLE,
              null,
              'success',
            );
          }, err => {
            console.log('err', err);
            this.isLeaderboardLoading = false;

            const errors: Errors = new Errors().deserialize((err.error as any));
            this.toastr.show(
              errors.getFullMessages(),
              this.translate.LEADERBOARD.TOASTR.LEADERBOARD.ERROR.TITLE,
              null,
              'error',
            );
          }
        )
    );
  }

}
