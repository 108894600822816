<div class="page-layout blank p-24" fusePerfectScrollbar>
    <div class="content">
        <!-- FONTS START -->
        <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
            <div class="p-32" fxLayout="column" fxLayoutAlign="start" fxLayoutWrap>
                <h1 class="mt-0">H1 TITLE</h1>
                Lorem ipsum dolor sit amet, vis suas mutat graece cu. In mea mazim periculis, sed porro pertinacia deterruisset ut, sea
                omnium facilisi necessitatibus at. Detracto vulputate no has, atqui fabulas eu duo. Te usu facer dicit, ullum choro civibus
                ne quo, vim putant oblique erroribus ex. Te eos quando nullam feugait.

                <h2 class="mt-16">H2 TITLE</h2>
                <span class="textGray">Lorem ipsum dolor sit amet, vis suas mutat graece cu. In mea mazim periculis, sed porro pertinacia deterruisset ut, sea omnium
                facilisi necessitatibus at. Detracto vulputate no has, atqui fabulas eu duo. Te usu facer dicit, ullum choro civibus ne quo,
                vim putant oblique erroribus ex. Te eos quando nullam feugait.</span>

                <h3 class="mt-16">H3 TITLE</h3>
                <span class="textGray">Lorem ipsum dolor sit amet, vis suas mutat graece cu. In mea mazim periculis, sed porro pertinacia deterruisset ut, sea omnium
                facilisi necessitatibus at. Detracto vulputate no has, atqui fabulas eu duo. Te usu facer dicit, ullum choro civibus ne quo,
                vim putant oblique erroribus ex. Te eos quando nullam feugait.</span>

                <h4 class="mt-16">H4 TITLE</h4>
                <span class="textGray">Lorem ipsum dolor sit amet, vis suas mutat graece cu. In mea mazim periculis, sed porro pertinacia deterruisset ut, sea omnium
                facilisi necessitatibus at. Detracto vulputate no has, atqui fabulas eu duo. Te usu facer dicit, ullum choro civibus ne quo,
                vim putant oblique erroribus ex. Te eos quando nullam feugait.</span>
            </div>
        </div>
        <!-- FONTS END -->

        <!-- TAG START -->

        <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
            <div class="p-32">
                <h2 class="m-0">TAGS</h2>
                <span class="textGray">Utilizado principalmente em filtros customizados</span>
                <br>
                <br>
                <tag class="mr-8 mt-8" *ngFor="let tag of tags; let i = index" (tagEvent)="tagDelete($event)" [index]="i" [tag]="tag">{{tag.text}}</tag>
                <br>
                <br>
                <btn (click)="addTag()"> ADD TAG</btn>
            </div>
        </div>

        <!-- TAG END -->


        <!-- LABEL START -->
        
        <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
            <div class="p-32">
                <h2 class="m-0">LABELS</h2>
                <span class="textGray">Utilizado principalmente em filtros customizados</span>
                <br>
                <br>
                <label-x class="mr-8" type="normal">NORMAL</label-x>
                <label-x class="mr-8" type="danger">DANGER</label-x>
                <label-x class="mr-8" type="warning">WARNING</label-x>
                <label-x class="mr-8" type="info">INFO</label-x>
                <label-x class="mr-8" type="success">SUCCESS</label-x>
                <label-x class="mr-8" backgroundColor="#ff0000" color="#000000">CUSTOM COLOR</label-x>
            </div>
        </div>
        
        <!-- LABEL END -->

        <!-- BUTTON START -->
        <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
            <div class="p-32">
                <h2 class="m-0">BUTTONS</h2>
                <span class="textGray">Animação do mouse over seguir o padrão do Material Design (wave)</span>
                <br>
                <br>
                <btn color="#000000" backgroundColor="#fff000" [btnLoading]="true" [testLoading]="true" class="mr-16">BOTÃO</btn>
                <btn [btnLoading]="true" [testLoading]="true" class="mr-16">BOTÃO</btn>
                <btn [btnLoading]="true" [testLoading]="true" type="grayscale" icon="content/ic-export.svg" iconAlign="right" class="mr-16">BOTAO</btn>
                <btn type="grayscale" icon="content/ic-export.svg" class="mr-16" [routerLink]="['/login']">botao</btn>
            </div>
        </div>
        <!-- BUTTON END -->


        <!-- BUTTON START -->
        <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
            <div class="p-32">
                <h2 class="m-0">TOOLTIPS</h2>
                <span class="textGray">Animação do mouse over seguir o padrão do Material Design (wave)</span>
                <br>
                <br>
                <btn class="mr-16" matTooltip="Tooltip Right" matTooltipPosition="right" matTooltipClass="right">RIGHT</btn>
                <btn class="mr-16" matTooltip="Tooltip Left" matTooltipPosition="left" matTooltipClass="left">LEFT</btn>
                <btn class="mr-16" matTooltip="Tooltip Below" matTooltipPosition="below" matTooltipClass="below">BELOW</btn>
                <btn class="mr-16" matTooltip="Tooltip Above" matTooltipPosition="above" matTooltipClass="above">ABOVE</btn>
            </div>
        </div>
        <!-- BUTTON END -->

        <!-- BUTTON START -->
        <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
            <div class="p-32">
                <h2 class="m-0">BREADCRUMBS</h2>
                <span class="textGray">Sempre habilitar link para as telas anteriores e deixar o link em azul</span>
                <br>
                <br>
                <breadcrumbs [breadcrumbs]="breadcrumbs"></breadcrumbs>
                <br>
                <br>
                <btn (click)="addBreadcrumbs()" class="mr-16">ADD BREADCRUMBS</btn>
                <btn (click)="removeLastBreadcrumbs()" class="mr-16">REMOVE LAST BREADCRUMB</btn>
                <btn (click)="removeAllBreadcrumbs()" class="mr-16">REMOVE ALL BREADCRUMB</btn>
                <btn (click)="replaceAllBreadcrumbs()" class="mr-16">REPLACE ALL BREADCRUMB</btn>
            </div>
        </div>
        <!-- BUTTON END -->

        <!-- FORM START -->
        <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
            <div class="p-32">
                <h2 class="m-0">FORMS</h2>
                <span class="textGray">Base do material</span>
                <br>
                <br>
                <div fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-md="row">
                    <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form" [formGroup]="form">
                        <div>
                            <h4 class="m-0 textBlue">Input</h4>
                            <span class="textGray">Os inputs tem 3 states, placeholder, preenchido e com focus</span>
                        </div>
                        
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mt-8">
                            <mat-form-field fxFlex="50">
                                <input matInput placeholder="Input" formControlName="input" required>
                                <mat-error *ngIf="formErrors.input.required">
                                    First Name is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="mt-32">
                            <h4 class="m-0 textBlue">Textarea</h4>
                            <span class="textGray">Os textarea tem 3 states, placeholder, preenchido e com focus</span>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mt-8">
                            <mat-form-field fxFlex="50">
                                <textarea matInput placeholder="Textarea" formControlName="textArea2" required></textarea>
                                <mat-error *ngIf="formErrors.textArea2.required">
                                    Last Name is required!
                                </mat-error>
                            </mat-form-field>
                        </div>


                        <div class="mt-32">
                            <h4 class="m-0 textBlue">Input Actions</h4>
                            <span class="textGray">Validação, limite, erros, etc.</span>
                        </div>
                        
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mt-8">
                            <mat-form-field fxFlex="50" fxFlex.xs="100" class="mr-8">
                                <input matInput placeholder="Email" formControlName="email" required>
                                <mat-error *ngIf="formErrors.email.required">
                                    E-mail é obrigatório
                                </mat-error>
                                <mat-error *ngIf="formErrors.email.invalid && !formErrors.email.required">
                                    E-mail inválido
                                </mat-error>
                            </mat-form-field>
                            
                            <mat-form-field fxFlex="50" fxFlex.xs="100" class="ml-8">
                                <input matInput #input maxlength="10" placeholder="Count characters" formControlName="maxCount" required>
                                <mat-error *ngIf="formErrors.maxCount.required">
                                    obrigatório o texto rapaz
                                </mat-error>
                                <mat-hint align="end">{{input.value?.length || 0}}/10</mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="mt-32">
                            <div fxFlex="50" fxFlex.xs="100" class="mr-8">
                                <h4 class="m-0 mb-8 textBlue">Checkbox</h4>
                                <mat-checkbox>Ação do checkbox</mat-checkbox>
                            </div>

                            <div fxFlex="50" fxFlex.xs="100" class="mr-8">
                                <h4 class="m-0 mb-8 textBlue">Radio</h4>
                                <mat-radio-group>
                                    <mat-radio-button value="1" class="mr-8">Radio button</mat-radio-button>
                                    <mat-radio-button value="2">Radio button2</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>

                        <div class="mt-32">
                            <h4 class="m-0 textBlue">Select</h4>
                            <span class="textGray">O select poderá somente selecionar a opção como também terá a busca</span>
                        </div>
                        
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mt-8">
                            <mat-form-field fxFlex="50">
                                <mat-select placeholder="Favorite food">
                                    <mat-option *ngFor="let select of selects" [value]="select.value">
                                        {{ select.viewValue }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>


                        <div class="mt-32">
                            <h4 class="m-0 textBlue">Switch</h4>
                            <span class="mb-16 textGray">Usar a mesma animação do material design</span>
                            <br>
                            <br>
                            <mat-slide-toggle>Switch</mat-slide-toggle>
                        </div>
                        

                    </form>
                </div>
            </div>
        </div>
        <!-- FORM END -->


        <!-- DIALOG START -->
        <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
            <div class="p-32">
                <h2 class="m-0">DIALOG</h2>
                <span class="textGray">Dialog to component</span>
                <br>
                <br>
                <btn color="#4c6070" backgroundColor="#ece698" class="mr-16" (click)="dialogWarning()">DIALOG WARNING</btn>
                <btn color="#fffffff" backgroundColor="#5aac6a" class="mr-16" (click)="dialogSuccess()">DIALOG SUCCESS</btn>
                <btn color="#fffffff" backgroundColor="#eb5b46" class="mr-16" (click)="dialogError()">DIALOG ERROR</btn>
                <btn color="#000000" backgroundColor="#fff000" class="mr-16" (click)="dialogCustom()">DIALOG CUSTOM</btn>
            </div>
        </div>
        <!-- DIALOG END -->

        <!-- MODAL START -->
        <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
          <div class="p-32">
            <h2 class="m-0">MODAL</h2>
            <span class="textGray">Modal to component</span>
            <br>
            <br>
            <btn color="#000000" backgroundColor="#fff000" class="mr-16" (click)="modalCustom()">MODAL</btn>
          </div>
        </div>
        <!-- MODAL END -->

        <!-- TABS START -->
        <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
          <div class="p-32">
            <h2 class="m-0">TABS</h2>
          </div>

          <mat-tab-group class="padding" [disableRipple]="true" backgroundColor="">
            <mat-tab label="Lorem ipsum dolor 1">Content 1</mat-tab>
            <mat-tab label="Lorem ipsum dolor 2">Content 2</mat-tab>
            <mat-tab label="Lorem ipsum dolor 3">Content 3</mat-tab>
            <mat-tab label="Lorem ipsum dolor 4">Content 4</mat-tab>
            <mat-tab label="Lorem ipsum dolor 5">Content 5</mat-tab>
            <mat-tab label="Lorem ipsum dolor 6">Content 6</mat-tab>
            <mat-tab label="Lorem ipsum dolor 7">Content 7</mat-tab>
            <mat-tab label="Lorem ipsum dolor 8">Content 8</mat-tab>
            <mat-tab label="Lorem ipsum dolor 9">Content 9</mat-tab>
            <mat-tab label="Lorem ipsum dolor 10">Content 10</mat-tab>
            <mat-tab label="Lorem ipsum dolor 11">Content 11</mat-tab>
            <mat-tab label="Lorem ipsum dolor 12">Content 12</mat-tab>
          </mat-tab-group>
        </div>
        <!-- TABS END -->
    </div>

</div>
