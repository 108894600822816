<div class="page-layout blank pb-32 pt-32" (scroll)="onScroll($event)">
  <app-nav-team-type></app-nav-team-type>
  <div class="p-32" [hidden]="!showHeats">
    <app-nav-heats-agenda></app-nav-heats-agenda>
    <router-outlet></router-outlet>
  </div>
  <!-- Empty list -->
  <div class="p-32" fxLayout="column" *ngIf="!showHeats">
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      class="emtpy-content card auto-width p-32"
    >
      <img src="./assets/icons/placeholder/ic-empty.svg" />
      <span class="mt-8 text-center">{{ "HEAT_HEATCOMPETITORS.EMPTY" | translate }}</span>
    </div>
  </div>
</div>
