import { Competition } from './competition';
import { Team } from './team';
import { User } from './user';
import * as moment from 'moment';
import { Result } from './result';
import { SubDivision } from './sub-division';
import { Championship } from './championship';

export class Competitor {
  id: number;
  user_id?: number;
  team_id?: number;
  sub_division_id?: number;
  name?: string;
  number?: number;
  checkin_status?: number;
  checkin_name?: string;
  championship_id: number;
  competition_id?: number;
  rank?: number;
  sub_division_rank?: number;
  global_rank?: number;
  total?: number;
  global_total?: number;
  disqualified?: boolean;
  published_disqualified?: boolean;
  is_cut?: boolean;
  published_is_cut?: boolean;
  payment_status?: number;
  competition?: Competition;
  sub_division?: SubDivision;
  result?: Result;
  user?: User;
  team?: Team;
  box?: any;
  sort?: any;
  leaderboard_name?: string;
  box_id?: number = null;
  solded?: number = null;
  size?: string = null;
  created_at?: Date;
  updated_at?: Date;
  status?: number;
  wod_id: number;
  open = false;
  results?: Result[];
  championship: Championship;

  deserialize(input: any) {
    (<any>Object).assign(this, input);
    this.competition = new Competition().deserialize(input.competition);
    this.team = input.team !== null ? new Team().deserialize(input.team) : null;
    this.user = input.user !== null ? new User().deserialize(input.user) : null;
    if (input.status === null) {
      this.status = CompetitorStatus.ACTIVE;
    }
    return this.populateCheckinName();
  }

  populateCheckinName(): Competitor {
    if (typeof this.checkin_status === 'number') {
      const statusNameCheckin = {
        [CompetitorCheckin.REGISTERED]: 'Registrado',
        [CompetitorCheckin.CHECKIN_ONLINE]: 'Check-in On-line',
        [CompetitorCheckin.CHECKIN]: 'Check-in',
        [CompetitorCheckin.NO_SHOW]: 'No Show',
        [CompetitorCheckin.NO_SHOW_ONLINE]: 'No Show',
        [CompetitorCheckin.WITHDRAW]: 'No Show On-line',
      };
      this.checkin_name = statusNameCheckin[this.checkin_status];
      if (!this.checkin_name) {
        this.checkin_name = '-';
      }
    }
    return this;
  }
}

export enum CompetitorStatus {
  PEDDING = 1,
  ACTIVE = 2,
  INACTIVE = 3,
  CANCELLED = 5
}

export enum CompetitorCheckin {
  REGISTERED = 0,
  CHECKIN_ONLINE = 1,
  CHECKIN = 2,
  NO_SHOW = 3,
  NO_SHOW_ONLINE = 4,
  WITHDRAW = 5
}
