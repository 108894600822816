import {Competitor} from './competitor';
import {Competition} from './competition';
import {Wod} from './wod';

export class Draggable {
  id?: number | null;
  type: string;
  tableName: string;
  lane?: number | null;
  competitor_id?: number | null;
  competitor?: Competitor | null;
  teamName?: string | null;
  competition_id?: number | null;
  competition?: Competition | null;
  wod_id?: number | null;
  wod?: Wod | null;
  children?: any[] | null;
  object?: any | null;
  selected: boolean;
  isOpen?: boolean | false;
}
