<div class="p-32 pb-0" *ngIf="event !== null && event.championships !== null && event.championships.length > 1">
  <form class="" name="heatsForm" [formGroup]="heatsForm" novalidate>
    <mat-form-field fxFlex="30" fxFlex.xs="100">
      <mat-select placeholder="{{'WORKOUT.CHAMPIONSHIP' | translate | uppercase}}" formControlName="championship">
        <mat-option *ngFor="let championship of event.championships" [value]="championship.id">
          {{ championship.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<div class="pb-32">
  <!-- <mat-tab-group #tabGroup class="padding tabs-left tab-inner pt-24" [disableRipple]="true" backgroundColor=""> -->
    <div fxLayoutAlign="center center" class="p-64" *ngIf="isLoading && heatsData.length === 0">
      <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
    </div>
    <div class="p-32 pt-0 pb-0 heat-container"  *ngFor="let heatDate of heatsData; trackBy: trackByRow">
      <h3 class="mt-24">{{ heatDate.date | date:('HEATS.DATE_FORMAT' | translate) }}</h3>

      <div class="box-line" *ngFor="let heat of heatDate.heats; trackBy: trackByRow">
        <div fxLayout="column" fxLayoutAlign="start start" class="p-16 pt-8 pb-8 title-heat" ngClass.xs="pt-16 pb-16" (click)="setHeatId(heat.id)">
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center" class="first-line">
            <span fxLayout="row" fxLayoutAlign="start center">
              <mat-icon
                [ngClass]="{'arrow_collapsible': true, 'rotate-180': heatIdOpen === null || heatIdOpen !== heat.id}">keyboard_arrow_up</mat-icon>
              <h4 class="m-0">{{'HEATS.HEAT' | translate}} {{ heat.number }}</h4>
            </span>
            <span class="heat-time">{{ heat.start_time | customDateHeatPipe:'HH:mm':true }}</span>
            
          </div>
          <span class="heat-name">{{ heat.name }}</span>
        </div>
        
        <div fxLayout="column" *ngIf="heatIdOpen === heat.id" class="p-0">
          <div fxLayout="column" fxLayoutAlign="center center" class="p-32 pb-40 emtpy-content" *ngIf="heat.heat_competitors && heat.heat_competitors.length === 0">
            <!-- <img src="./assets/icons/placeholder/ic-empty.svg" /> -->
            <span class="mt-8">{{'HEATS.EMPTY' | translate}}</span>
          </div>
          
          <table-x [items]="heat.heat_competitors" [config]="tableConfig" [isLoading]="!heat.heat_competitors" tableName="heats" [isDraggable]="false" class="heats-public" [isHeader]="false">
            <column property="lane" header="" [sortable]="false" align="center" width="45px" maxWidth="45px" classes="lanes">
              <ng-template #dataTableCell let-item="item">
                <div fxLayout="row" fxLayoutAlign="center center">
                  {{ item.lane }}
                </div>
              </ng-template>
            </column>
            
            <column property="name" header="" [sortable]="false" align="left">
              <ng-template #dataTableCell let-item="item">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="space-around start">
                  <span fxFlex="50" fxFlex.xs="auto">{{ (item.team ? item.team?.name : '') | uppercase }}</span>
                  <span fxFlex="50" fxFlex.xs="auto" class="box-name">{{ (item.team ? item.team?.box?.name : '') | uppercase }}</span>
                </div>
              </ng-template>
            </column>
          </table-x>
        </div>
      </div>
    </div>
  <!-- </mat-tab-group> -->
</div>
