export const locale = {
  lang: 'pt-BR',
  data: {
    'MODAL_WOD': {
      'TITLE_NEW': 'Novo WOD',
      'TITLE_EDIT': 'Editar WOD',
      'TITLE_SECTION': 'Informações do WOD',
      'DESCRIPTION_SECTION': 'Crie os wods, você poderá replicar-los para outras categorias.',
      'DATE_FORMAT': 'DD/MM/YYYY',
      'DATE_FORMAT_INPUT': '00/00/0000',
      'TITLE_RESULTS': 'Configuração de resultados',
      'TITLE_ADDTIONAL': 'Configuração adicionais',
      'TITLE_PUBLISH': 'Publicação do WOD',
      'TIMEZONE': 'Fuso horário de {{ timezone }}',
      'SUB_DIVISION': {
        'TITLE': 'Sub-categoria'
      },
      'FORM': {
        'NAME': 'Nome',
        'RESULT_TYPE': 'Tipo de resultado',
        'TIEBREAK_TYPE': 'Tie Break',
        'POINTS': 'Pontos',
        'TIME_CAP': 'Time Cap (min)',
        'GOALS': 'Quantidade máxima de Reps',
        'URL_VIDEO': 'Vídeo do workout',
        'DESCRIPTION': 'Descrição',
        'DESCRIPTION_PLACEHOLDER': 'Insira a descrição do WOD',
        'ADD_ALL_COMPETITIONS': 'Adicionar WOD para todas as categorias',
        'PUBLISH_WORKOUT': 'Publicar workout na pagina do evento e leaderboard',
        'DATE_PUBLISH': 'Data da publicação',
        'TIME_PUBLISH': 'Horário da publicação',
        'DATE_END': 'Data do encerramento',
        'TIME_END': 'Horário do encerramento',
        'EMAIL_PUBLISH': 'Enviar e-mail para os inscritos quando for publicado',
        'WOD_DATE': 'Data do WOD'
      },
      'VALIDATIONS': {
        'NAME_REQUIRED': 'O nome é obrigatório',
        'RESULT_TYPE_REQUIRED': 'O tipo de resultado é obrigatório',
        'DATE_REQUIRED': 'A data é obrigatória',
        'DATE_INVALID': 'Data inválida',
        'DATE_WOD_INVALID': 'Data do WOD inválida',
        'DATE_WOD_MAX_DATE': 'Data do WOD não pode ser superior a data final do evento.',
        'POINTS_REQUIRED': 'Os pontos é obrigatório',
        'URL_INVALID': 'O formato do link é inválido',
        'TIME_CAP_MIN': 'O valor deve ser superior a 1',
        'TIME_CAP_PATTERN': 'Apenas números são permitidos'
      },
      'SAVE': 'Salvar',
      'CANCEL': 'Cancelar',
    }
  }
};
