export const locale = {
    lang: 'pt-BR',
    data: {
      'CROP': {
        'TITLE': 'Editar imagem',
        'SAVE': 'SALVAR',
        'CANCEL': 'CANCELAR'
      }
    }
};
