<!-- PLACEHOLDER NOT CATEGORIES -->
<div class="card auto-width mb-32" *ngIf="competitions.length === 0">
  <div class="pt-16" fxLayout="column">
    <div fxLayoutAlign="center center" class="p-64" *ngIf="isLoading">
      <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" class="p-32 emtpy-content" *ngIf="!isLoading">
      <img src="./assets/icons/placeholder/ic-empty.svg" />
      <span class="mt-8">{{ 'LOTS.CATEGORY.EMPTY' | translate }}</span>
    </div>
  </div>
</div>

<div class="card auto-width mb-32" *ngFor="let competition of competitions; index as i;">
  <div class="pt-16" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="pl-32 pr-32 pb-16 mb-4 mt-0 line-bottom">
      <div fxLayout="row" fxLayoutAlign="start center">
        <h2 class="m-0 mr-4">{{ competition.name | uppercase }}</h2>
        <!--<img src="./assets/icons/common/ic-info.svg" matTooltip="teste" matTooltipPosition="right" matTooltipClass="right" />-->
      </div>
      <button fxLayout="row" fxLayoutAlign="start center" (click)="copyLots(competition)" class="button-copy pl-8 pr-8" *ngIf="competitions && competitions.length > 1 && competition.lots && competition.lots.length > 0">
        <img src="./assets/icons/common/ic-copy.svg" class="mr-8" />
        {{ 'LOTS.COPY' | translate | uppercase }}
      </button>
    </div>

    <!-- PLACEHOLDER NOT LOT -->
    <div fxLayout="column" fxLayoutAlign="center center" class="p-32 emtpy-content-lot" *ngIf="!competition.lots || competition.lots.length === 0">
      <img src="./assets/icons/category/ic-lot-placeholder.svg" class="mb-16" />
      {{ 'LOTS.CONTENT_EMPTY' | translate }}
      <btn (click)="addLotOfCompetition(competition)" class="mt-8">{{ 'LOTS.ADD' | translate | uppercase }}</btn>
    </div>

    <table-x #tableLot [items]="competition.lots" [config]="tableCatConfig" [isLoading]="isLoading" tableName="lots-{{i}}" [isDraggable]="true" (reorderEvent)="reorderAction($event)" *ngIf="competition.lots && competition.lots.length > 0">
      <column property="name" header="{{ 'LOTS.TABLE.NAME' | translate }}" [sortable]="false" align="left">
        <ng-template #dataTableCell let-item="item" let-index="index">
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="mr-8 menu-reorder">menu</mat-icon>
            <a (click)="editLotOfCompetition(item, competition, index)" class="small-font">
              {{ item.name | uppercase }}
            </a>
          </div>
        </ng-template>
      </column>

      <column property="price" header="{{ 'LOTS.TABLE.PRICE' | translate }}" [sortable]="false" align="center">
        <ng-template #dataTableCell let-item="item">
          <span class="hide-in-drag">{{ formatCurrency((item.price | currency:('LOTS.CURRENCY_CODE' | translate):'symbol-narrow':'1.2-2'), ('LOTS.CURRENCY_CODE' | translate)) }}</span>
        </ng-template>
      </column>

      <column property="start" header="{{ 'LOTS.TABLE.DATE_START' | translate }}" [sortable]="false" align="left">
        <ng-template #dataTableCell let-item="item">
          <span class="hide-in-drag">{{ item.start | date:('LOTS.DATETIME_FORMAT' | translate) }}</span>
        </ng-template>
      </column>

      <column property="end" header="{{ 'LOTS.TABLE.DATE_END' | translate }}" [sortable]="false" align="left">
        <ng-template #dataTableCell let-item="item">
          <span class="hide-in-drag">{{ item.end | date:('LOTS.DATETIME_FORMAT' | translate) }}</span>
        </ng-template>
      </column>

      <column property="count" header="{{ 'LOTS.TABLE.COUNT' | translate }}" [sortable]="false" align="center">
        <ng-template #dataTableCell let-item="item">
          <span class="hide-in-drag">{{ item.sold }}{{ (item.limit) ? '/' + item.limit : '' }}</span>
        </ng-template>
      </column>

      <column property="actions" header="" [sortable]="false" align="center" width="100px">
        <ng-template #dataTableCell let-item="item" let-index="index">
          <btn-transparent icon="common/ic-settings.svg" buttonWidth="35px" iconAlign="left" [matMenuTriggerFor]="lotsActionMenu" class="hide-in-drag"
                           [matMenuTriggerData]="{ competition: competition, lot: item, index: index }"></btn-transparent>
        </ng-template>
      </column>
    </table-x>

    <div class="m-32 mt-16 mb-16" *ngIf="competition.lots && competition.lots.length > 0">
      <btn-icon-small icon="add_circle_outline" iconAlign="left" (click)="addLotOfCompetition(competition)">{{ 'LOTS.ADD_NEW' | translate }}</btn-icon-small>
    </div>
  </div>
</div>
<!-- MAT-MENU ACTIONS -->
<mat-menu class="submenu bottom arrow-right" #lotsActionMenu="matMenu" [overlapTrigger]="false" xPosition="before">
  <ng-template matMenuContent let-competition="competition" let-lot="lot" let-index="index">
    <button mat-menu-item class="line-bottom" (click)="deleteLot(lot, competition, index)">
      <span class="text-red">{{ 'LOTS.DELETE' | translate }}</span>
    </button>
  </ng-template>
</mat-menu>