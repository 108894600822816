<div class="page-layout blank pb-64">
  <div id="event" fxLayoutAlign="center">
    <div class="background-img">
      <img src="{{ event.header_image ? event.header_image : headerPlaceholder }}" />
    </div>

    <div id="event-wrapper" class="mt-60" class.xs="mt-0">
      <!-- Manager Event -->
      <div class="card auto-width mb-56" [ngClass.gt-sm]="'mb-0'">
        <div fxLayout="row" fxLayoutAlign="start center" class="p-32">
          <img src="{{ event.email_logo ? event.email_logo : null | placeholderImage:'user' }}" width="110" class="mr-24" />

          <div fxLayout="column" fxLayoutAlign="start start">
            <h2 class="m-0">{{ event.name | uppercase }}</h2>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="center start" class="event-information">
              <span class="mr-8" fxLayoutAlign="start center">
                <mat-icon class="mr-4">date_range</mat-icon>{{ event.datetime_start | localizedDate:('IDENTIFICATION.DATE_FORMAT_COMPLETE' | translate) }}
              </span>
              <span fxLayoutAlign="start center" *ngIf="event.address">
                <mat-icon class="mr-4">location_on_outlined</mat-icon>{{ event.address }}
              </span>
            </div>
          </div>
        </div>

        <table-x [items]="[lot]" [config]="tableConfig">
          <!-- (sortEvent)="sortAction($event)" -->
          <column property="competitionName" header="{{ 'IDENTIFICATION.TABLE_HEADER.COMPETITION' | translate }}" [sortable]="false"
            align="left">
            <ng-template #dataTableCell let-item="item">
              <a class="pt-16 pb-16">
                {{ item.competition ? item.competition.name : item.name }}
              </a>
            </ng-template>
          </column>
        
          <column property="price" header="{{ 'IDENTIFICATION.TABLE_HEADER.PRICE' | translate }}" [sortable]="false" align="center">
            <ng-template #dataTableCell let-item="item">
              {{ formatCurrency((item.price | currency:('IDENTIFICATION.CURRENCY_CODE' | translate):'symbol-narrow':'1.2-2'), ('IDENTIFICATION.CURRENCY_CODE' | translate)) }}
            </ng-template>
          </column>
        
          <column property="tax" header="{{ 'IDENTIFICATION.TABLE_HEADER.TAX' | translate }}" [sortable]="false" align="center" *ngIf="lot.taxPrice > 0">
            <ng-template #dataTableCell let-item="item">
              {{ formatCurrency((item.taxPrice | currency:('IDENTIFICATION.CURRENCY_CODE' | translate):'symbol-narrow':'1.2-2'), ('IDENTIFICATION.CURRENCY_CODE' | translate)) }}
            </ng-template>
          </column>
        
          <column property="discount" header="{{ 'IDENTIFICATION.TABLE_HEADER.DISCOUNT' | translate }}" [sortable]="false" align="center">
            <ng-template #dataTableCell let-item="item">
              {{ (coupon) ? ((coupon.type === 0) ? coupon.value+'%' : formatCurrency((coupon.value | currency:('IDENTIFICATION.CURRENCY_CODE' | translate):'symbol-narrow':'1.2-2'), ('IDENTIFICATION.CURRENCY_CODE' | translate))) : '0' }}
            </ng-template>
          </column>
        
          <column property="total_price" header="{{ 'IDENTIFICATION.TABLE_HEADER.TOTAL_PRICE' | translate }}" [sortable]="false"
            align="center">
            <ng-template #dataTableCell let-item="item">
              <strong>{{ formatCurrency((item.finalPrice | currency:('IDENTIFICATION.CURRENCY_CODE' | translate):'symbol-narrow':'1.2-2'),('IDENTIFICATION.CURRENCY_CODE' | translate)) }}</strong>
            </ng-template>
          </column>
        </table-x>

        <div fxLayout="column" fxLayoutAlign="start center" class="pt-32 pb-32 identify">
          <h2>{{ 'IDENTIFICATION.IDENTIFY' | translate }}</h2>
          {{ 'IDENTIFICATION.IDENTIFY_DESCRIPTION' | translate }}
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start" fxLayoutAlign.xs="start stretch" class="pb-32">
          <form fxFlex="50" fxFlex.xs="auto" fxLayout="column" fxLayoutAlign="start stretch" class="login pt-8 pb-16 pl-32 pr-32 line-right" name="loginForm" [formGroup]="loginForm" novalidate>
            <h2 class="mt-0">{{ 'IDENTIFICATION.LOGIN' | translate }}</h2>
            <mat-form-field>
              <input matInput placeholder="E-mail" formControlName="email" [errorStateMatcher]="matchers.email" required>
              <mat-error *ngIf="loginForm.controls.email.hasError('required')">
                {{'IDENTIFICATION.VALIDATIONS.EMAIL_REQUIRED' | translate}}
              </mat-error>
              <mat-error *ngIf="loginForm.controls.email.hasError('email') && !loginForm.controls.email.hasError('required')">
                {{'IDENTIFICATION.VALIDATIONS.EMAIL_INVALID' | translate}}
              </mat-error>
            </mat-form-field>
          
          
            <mat-form-field class="mt-8">
              <input matInput type="password" placeholder="{{'IDENTIFICATION.PASSWORD' | translate}}" formControlName="password" [errorStateMatcher]="matchers.password" required>
              <mat-error *ngIf="loginForm.controls.password.hasError('required')">
                {{'IDENTIFICATION.VALIDATIONS.PASSSWORD_REQUIRED' | translate}}
              </mat-error>
            </mat-form-field>
          
            <mat-error class="mb-8" *ngIf="loginError">
              {{'IDENTIFICATION.VALIDATIONS.LOGIN_ERROR' | translate}}
            </mat-error>
          
            <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
              <a class="forgot-password" (click)="forgotPassword()" class="pt-4 mt-4 mb-4" [ngClass.gt-xs]="'mb-8'">
                {{'IDENTIFICATION.FORGOT_PASSWORD' | translate}}
              </a>

              <btn [btnLoading]="true" fxFlex.xs="100" fxLayoutAlign="end center" (click)="logIn()" class="submit-button m-0"
                [buttonDisabled]="loginForm.invalid" [loading]="isLoadingLogin">{{'IDENTIFICATION.ENTER' | translate | uppercase}}</btn>
            </div>
          
          </form>

          <form fxFlex="50" fxLayout="column" fxLayoutAlign="start stretch" class="register pt-8 pb-8 pl-32 pr-32" name="registerForm" [formGroup]="registerForm" novalidate>
            <h2 class="mt-0">{{ 'IDENTIFICATION.GO_REGISTER' | translate }}</h2>

            <mat-form-field>
              <input matInput placeholder="E-mail" formControlName="email" [errorStateMatcher]="matchers.email" required>
              <mat-error *ngIf="registerForm.controls.email.hasError('required')">
                {{'IDENTIFICATION.VALIDATIONS.EMAIL_REQUIRED' | translate}}
              </mat-error>
              <mat-error *ngIf="registerForm.controls.email.hasError('invalid') && !loginForm.controls.email.hasError('required')">
                {{'IDENTIFICATION.VALIDATIONS.EMAIL_INVALID' | translate}}
              </mat-error>
              <mat-error *ngIf="registerForm.controls.email.hasError('exist')">
                {{'IDENTIFICATION.VALIDATIONS.EMAIL_EXIST' | translate}}
              </mat-error>
            </mat-form-field>

            <btn [btnLoading]="true" fxFlex.xs="100" fxLayoutAlign="end center" (click)="register()" class="submit-button mt-16 m-0"
              [buttonDisabled]="registerForm.invalid" [loading]="isLoadingRegister">{{'IDENTIFICATION.REGISTER' | translate | uppercase}}</btn>
          </form>
        </div>
      </div>
      <!-- End Card -->
    </div>
  </div>
</div>