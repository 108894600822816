<div id="error-500" fxLayout="column" fxLayoutAlign="center center">

    <div class="content" fxLayout="column" fxLayoutAlign="center center">

        <div class="error-code">:(</div>

        <div class="message">{{ 'ERROR.TITLE' | translate | uppercase }}</div>

        <div class="sub-message">
          {{ 'ERROR.DESCRIPTION' | translate }}
        </div>

        <a class="report-link" [routerLink]="'/myprofile'">{{ 'ERROR.BACK' | translate }}</a>

    </div>

</div>
