<div class="page-layout blank pb-64">
  <div class="p-32">
    <div class="content">
      <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
        <div class="pt-16">
          <mat-tab-group class="padding tabs-left" [disableRipple]="true" backgroundColor="">
            <mat-tab label="{{ 'SETTINGS.TABS.EVENT' | translate }}">
              <div class="p-32 pt-16">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <h3>{{ eventName }}</h3>
                  <label-x class="ml-8" type="{{ (event.published) ? 'success' : 'warning'}}">
                    {{ (event.published) ? ('SETTINGS.EVENT_STATUS.PUBLISHED' | translate | uppercase) : ('SETTINGS.EVENT_STATUS.NOT_PUBLISHED' | translate | uppercase) }}
                  </label-x>
                </div>
                <form name="eventForm" [formGroup]="eventForm" autocomplete="off" novalidate>
                  <div fxLayout="column" fxLayoutAlign="stretch stretch">
                    <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
                      <!-- FIRST COLUMN -->
                      <div fxFlex="50" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="space-evenly stretch">
                        <mat-form-field>
                          <input #nameEventInput matInput placeholder="{{ 'SETTINGS.FIELDS.NAME' | translate }}" formControlName="name" [errorStateMatcher]="eventMatchers.name" uppercase required autofocus>
                          <mat-error *ngIf="eventForm.controls.name.hasError('required')">
                            {{ 'SETTINGS.VALIDATIONS.NAME_REQUIRED' | translate}}
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                          <input
                            matInput
                            [matAutocomplete]="autoAddress" autocomplete="off"
                            placeholder="{{ 'SETTINGS.FIELDS.ADDRESS' | translate }}" formControlName="address" [errorStateMatcher]="eventMatchers.address" (ngModelChange)="searchAddress($event)" required>

                          <mat-autocomplete autoActiveFirstOption #autoAddress="matAutocomplete" xPosition="before">
                            <mat-option *ngFor="let city of cities" [value]="city.description" (onSelectionChange)="addressSelected(city)">
                              {{ city.description }}
                            </mat-option>
                          </mat-autocomplete>
                          <mat-error *ngIf="eventForm.controls.address.hasError('required')">
                            {{ 'SETTINGS.VALIDATIONS.ADDRESS_REQUIRED' | translate}}
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="mb-8">
                          <input matInput placeholder="{{ 'SETTINGS.FIELDS.LOCATION' | translate }}" formControlName="location" [errorStateMatcher]="eventMatchers.location" required>
                          <mat-error *ngIf="eventForm.controls.location.hasError('required')">
                            {{ 'SETTINGS.VALIDATIONS.LOCATION_REQUIRED' | translate}}
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                          <input matInput [matAutocomplete]="timezone" placeholder="{{ 'SETTINGS.FIELDS.TIMEZONE' | translate }}" formControlName="timezone" [errorStateMatcher]="eventMatchers.timezone" required>
                          <mat-autocomplete #timezone="matAutocomplete" [displayWith]="displayFnTimezone">
                            <mat-option *ngFor="let timezone of filterdTimezoneData | async" [value]="timezone">
                              {{ timezone.text }}
                            </mat-option>
                          </mat-autocomplete>
                          <mat-error *ngIf="eventForm.controls.timezone.hasError('required') || timezoneRequired">
                            {{ 'SETTINGS.VALIDATIONS.TIMEZONE_REQUIRED' | translate}}
                          </mat-error>
                        </mat-form-field>

                        <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" class="mb-8">
                          <mat-form-field fxFlex="65" class="inputDate">
                            <input matInput placeholder="{{'SETTINGS.FIELDS.DATE_START' | translate}}" formControlName="dateStart" [errorStateMatcher]="eventMatchers.dateStart" dropSpecialCharacters="false"
                              mask="00/00/0000" required>
                            <!-- (ngModelChange)="verifyDate($event)" -->
                            <mat-icon matSuffix>date_range</mat-icon>
                            <mat-error *ngIf="eventForm.controls.dateStart.hasError('required')">
                              {{'SETTINGS.VALIDATIONS.DATE_START_REQUIRED' | translate}}
                            </mat-error>
                            <mat-error *ngIf="!eventForm.controls.dateStart.hasError('required') && eventForm.controls.dateStart.hasError('invalid')">
                              {{'SETTINGS.VALIDATIONS.DATE_INVALID' | translate}}
                            </mat-error>
                          </mat-form-field>
                        
                          <select-time
                            #pickerTimeStart
                            fxFlex="35"
                            class="pl-12"
                            label="{{'SETTINGS.FIELDS.TIME_START' | translate }}"
                            [value]="timeStart"
                            (inputValue)="startTimeChange($event)"
                            [required]="true"></select-time>
                        </div>

                        <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center">
                          <mat-form-field fxFlex="65" class="inputDate">
                            <input matInput placeholder="{{'SETTINGS.FIELDS.DATE_END' | translate}}" formControlName="dateEnd" [errorStateMatcher]="eventMatchers.dateEnd" dropSpecialCharacters="false"
                              mask="00/00/0000" required>
                            <!-- (ngModelChange)="verifyDate($event)" -->
                            <mat-icon matSuffix>date_range</mat-icon>
                            <mat-error *ngIf="eventForm.controls.dateEnd.hasError('required')">
                              {{'SETTINGS.VALIDATIONS.DATE_END_REQUIRED' | translate}}
                            </mat-error>
                            <mat-error *ngIf="!eventForm.controls.dateEnd.hasError('required') && eventForm.controls.dateEnd.hasError('invalid')">
                              {{'SETTINGS.VALIDATIONS.DATE_INVALID' | translate}}
                            </mat-error>
                          </mat-form-field>
                        
                          <select-time
                            #pickerTimeEnd
                            fxFlex="35"
                            class="pl-12"
                            label="{{'SETTINGS.FIELDS.TIME_END' | translate }}"
                            [value]="timeEnd"
                            (inputValue)="endTimeChange($event)"
                            [required]="true"></select-time>
                        </div>
                      </div>

                      <!-- SECOND COLUMN -->
                      <div fxFlex="50" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="space-around start">
                        <agm-map 
                          fxFlex="100" class="ml-16 gmaps"
                          [latitude]="lat" [longitude]="lng"
                          [zoom]="15" [streetViewControl]="false" [scrollwheel]="false">
                          <agm-marker [latitude]="lat" [longitude]="lng" *ngIf="isShowMarker"></agm-marker>
                        </agm-map>
                      </div>
                    </div>

                    <h3>{{ 'SETTINGS.EVENT_PAGE' | translate }}</h3>
                    <div fxLayout="row" fxLayoutAlign="start start">
                      <mat-form-field fxFlex="50" fxFlex.xs="100">
                        <input matInput placeholder="{{ 'SETTINGS.FIELDS.EVENT_URL' | translate }}" formControlName="urlEvent" [errorStateMatcher]="eventMatchers.urlEvent" required>
                        <span matSuffix class="url-suffix">.crossx.com.br</span>
                        <mat-error *ngIf="eventForm.controls.urlEvent.hasError('required')">
                          {{ 'SETTINGS.VALIDATIONS.URL_EVENT_REQUIRED' | translate}}
                        </mat-error>
                        <mat-error *ngIf="!eventForm.controls.urlEvent.hasError('required') && eventForm.controls.urlEvent.hasError('minlength')">
                          {{ 'SETTINGS.VALIDATIONS.URL_EVENT_MINLENGTH' | translate:{ count: eventForm.controls.urlEvent.errors.minlength.requiredLength } }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="images-select">
                      <div fxLayout="column" fxFlex="50">
                        <h4>{{ 'SETTINGS.IMAGES.BRAND' | translate }}</h4>
                        <div fxLayout="row" fxLayoutAlign="start start">
                          <div fxFlex="126px" class="mr-8 image-box" fxLayoutAlign="center center">
                            <img [src]="brandUrl" class="image" *ngIf="(brandUrl !== ''); else placeholderBrand">
                            <ng-template #placeholderBrand><img src="./assets/icons/placeholder/ic-picture.svg" class="mt-32 mb-32"></ng-template>
                          </div>
                          <div fxFlex="auto" class="pr-16">
                            <span innerHTML="{{ 'SETTINGS.IMAGES.DESCRIPTION_BRAND' | translate }}"></span><br>
                            <input type="file" name="file" id="file" class="inputfile" (change)="readUrl($event, 'brand')" accept=".jpg, .jpeg, .png" />
                            <label for="file" class="btn mt-8">{{ 'SETTINGS.IMAGES.SELECT_BRAND' | translate }}</label>
                          </div>
                        </div>
                      </div>

                      <div fxLayout="column" fxFlex="50" >
                        <h4>{{ 'SETTINGS.IMAGES.HEADER' | translate }}</h4>
                        <div fxLayout="row" fxLayoutAlign="start start">
                          <div fxFlex="182px" class="mr-8 image-box" fxLayoutAlign="center center">
                            <img [src]="headerUrl" class="image" *ngIf="(headerUrl !== ''); else placeholderHeader">
                            <ng-template #placeholderHeader>
                              <img src="./assets/icons/placeholder/ic-picture.svg" class="mt-8 mb-8">
                            </ng-template>
                          </div>
                          <div fxFlex="auto" fxLayout="column">
                            <span innerHTML="{{ 'SETTINGS.IMAGES.DESCRIPTION_HEADER' | translate }}"></span>
                            <input type="file" name="file-header" id="file-header" class="inputfile" (change)="readUrl($event, 'header')" accept=".jpg, .jpeg, .png"  />
                            <label for="file-header" class="btn mt-8">{{ 'SETTINGS.IMAGES.SELECT_IMAGE' | translate }}</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div fxLayout="column" fxLayoutAlign="start start" class="mt-32 mb-32">
                      <h4>{{ 'SETTINGS.FIELDS.DESCRIPTION' | translate }}</h4>
                      <quill
                        [config]="configEditor" [(value)]="event.description"
                        (valueChange)="onValueChange($event)">
                        <div quillToolbar></div><!-- Optional custom toolbar -->
                        <div quillContent></div><!-- Optional pre-filled content -->
                      </quill>
                    </div>
              
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
                      <div fxFlex="50" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center" class="pr-16">
                        <mat-form-field fxFlex="auto" class="pr-16" *ngIf="event.doc_rule === null">
                          <input matInput placeholder="{{ 'SETTINGS.FIELDS.REGULATION' | translate }}" formControlName="regulation" [errorStateMatcher]="eventMatchers.regulation" [value]="regulationFileName">
                          <mat-error *ngIf="eventForm.controls.regulation.hasError('required')">
                            {{ 'SETTINGS.VALIDATIONS.REGULATION_REQUIRED' | translate}}
                          </mat-error>
                        </mat-form-field>
                        
                        <a href="{{ event.doc_rule }}" target="_blank" fxFlex="60" *ngIf="event.doc_rule">{{ 'SETTINGS.FIELDS.SEE_REGULATION' | translate }}</a>
                        <input type="file" name="file-regulation" id="file-regulation" class="inputfile" (change)="readUrl($event, 'regulation')" accept=".pdf" />
                        <label fxFlex="auto" for="file-regulation" class="btn mt-8">{{ 'SETTINGS.SELECT' | translate }}</label>
                      </div>

                      <div fxFlex="50" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center">
                        <mat-form-field fxFlex="auto" class="pr-16" *ngIf="event.doc_term === null">
                          <input matInput placeholder="{{ 'SETTINGS.FIELDS.TERMS' | translate }}" formControlName="terms" [errorStateMatcher]="eventMatchers.terms" [value]="termsFileName" disabled>
                          <mat-error *ngIf="eventForm.controls.terms.hasError('required')">
                            {{ 'SETTINGS.VALIDATIONS.TERMS_REQUIRED' | translate}}
                          </mat-error>
                        </mat-form-field>

                        <a href="{{ event.doc_term }}" target="_blank" fxFlex="60" *ngIf="event.doc_term">{{ 'SETTINGS.FIELDS.SEE_TERMS' |
                          translate }}</a>
                        <input type="file" name="file-terms" id="file-terms" class="inputfile" (change)="readUrl($event, 'terms')" accept=".pdf" />
                        <label fxFlex="auto" for="file-terms" class="btn mt-8">{{ 'SETTINGS.SELECT' | translate }}</label>
                      </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="end center" class="mt-32">
                      <btn [btnLoading]="true" [loading]="isPublishLoading" type="grayscale" [buttonDisabled]="eventForm.invalid && !event.published" (click)="publishOrUnpublishEvent()">
                        {{ (event.published ? 'SETTINGS.UNPUBLISH_EVENT' : 'SETTINGS.PUBLISH_EVENT') | translate | uppercase }}
                      </btn>
                      <btn [btnLoading]="true" [loading]="isLoading" [buttonDisabled]="eventForm.invalid" (click)="updateEventDetail()" class="ml-16">{{ 'SETTINGS.SAVE' | translate }}</btn>
                    </div>
                  </div>
                </form>

              </div>
            </mat-tab>

            <!-- TAB REGISTRATION -->
            <mat-tab label="{{ 'SETTINGS.TABS.REGISTRATIONS' | translate }}" *ngIf="event.enabled_subscription">
              <div class="p-32 pt-16 registration">
                <form name="registrationForm" [formGroup]="registrationForm" autocomplete="off" novalidate>
                  <div fxLayout="column" fxLayoutAlign="stretch stretch">
                    <h2>{{ 'SETTINGS.REGISTRATION.PAYMENT_METHOD' | translate }}</h2>
                    <span innerHTML="{{ 'SETTINGS.REGISTRATION.PAYMENT_METHOD_DESCRIPTION' | translate }}"></span>
                    <div fxLayout="row" fxLayoutAlign="start start" class="pt-16 pb-16">
                      <mat-checkbox [checked]="true" [disabled]="true" class="mr-32">{{ 'SETTINGS.REGISTRATION.CREDIT_CARD' | translate }}*</mat-checkbox>
                      <mat-checkbox #checkboxBoleto formControlName="boleto">{{ 'SETTINGS.REGISTRATION.BOLETO' | translate }}</mat-checkbox>
                    </div>

                    <span><i>{{ 'SETTINGS.REGISTRATION.CREDIT_CARD_REQUIRED' | translate }}</i></span>

                    <div fxFlex="60" fxFlex.xs="100" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="mt-16">
                      <mat-form-field fxFlex="50" class="mr-16">
                        <input matInput #input maxlength="13" placeholder="{{ 'SETTINGS.REGISTRATION.SOFT_DESCRIPTION' | translate }}" formControlName="softDescription" [errorStateMatcher]="registrationMatchers.softDescription" uppercase required>
                        <mat-error *ngIf="registrationForm.controls.softDescription.hasError('required')">
                           {{ 'SETTINGS.REGISTRATION.SOFT_DESCRIPTION_REQUIRED' | translate }}
                        </mat-error>
                        <mat-hint align="end">{{input.value?.length || 0}}/13</mat-hint>
                      </mat-form-field>

                      <div fxFlex="50" fxLayout="row" fxLayoutAlign="start start" class="pr-16">
                        <mat-form-field fxFlex="100" *ngIf="checkboxBoleto.checked">
                          <input matInput placeholder="{{ 'SETTINGS.REGISTRATION.DAYS_DUE_DATE' | translate }}" formControlName="daysDueDate" [errorStateMatcher]="registrationMatchers.daysDueDate" required>
                          <mat-error *ngIf="registrationForm.controls.daysDueDate.hasError('required')">
                            {{ 'SETTINGS.REGISTRATION.DAYS_DUE_DATE_REQUIRED' | translate }}
                          </mat-error>
                          <mat-error *ngIf="!registrationForm.controls.daysDueDate.hasError('required') && registrationForm.controls.daysDueDate.hasError('invalid')">
                            {{ 'SETTINGS.REGISTRATION.DAYS_DUE_DATE_MIN_VALUE' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="end center" class="mt-32">
                      <btn [btnLoading]="true" [loading]="isRegistrationLoading" [buttonDisabled]="registrationForm.invalid" (click)="savePaymentMethods()"
                        class="ml-16">{{ 'SETTINGS.SAVE' | translate }}</btn>
                    </div>
                  </div>
                </form>

                <div class="line-bottom mt-32 mb-16"></div>
                  
                <form name="bankAccountForm" [formGroup]="bankAccountForm" *ngIf="event.enabled_subscription && event.default_gateway_id === 1" autocomplete="off" novalidate>
                  <div fxLayout="column" fxLayoutAlign="stretch stretch">
                    <h2>{{ 'SETTINGS.REGISTRATION.BANK_ACCOUNT' | translate }}</h2>

                    <div fxFlex="100" fxLayout="row" fxLayoutAlign="stretch start">
                      <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-16">
                        <mat-select placeholder="{{ 'SETTINGS.REGISTRATION.USER_BANK_ACCOUNT' | translate }}"  formControlName="bankAccount" [errorStateMatcher]="bankAccountMatchers.bankAccount" required>
                          <mat-option *ngFor="let bankAccount of bankAccountList" [value]="bankAccount">
                            {{ (bankAccount.id !== 0) ? bankAccount.bank.title + ' - ' : '' }}{{ bankAccount.legal_name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div fxFLex="50"></div>
                    </div>
                    
                    <div fxFlex="100" fxLayout="row" fxLayoutAlign="stretch start" *ngIf="isShowInputBank">
                      <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-16">
                        <mat-select placeholder="{{ 'SETTINGS.REGISTRATION.TYPE_PERSON' | translate }}"  formControlName="personType" [errorStateMatcher]="bankAccountMatchers.personType" (selectionChange)="personTypeSelect($event)" required>
                          <mat-option *ngFor="let type of personTypes" [value]="type">
                            {{ type.title }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div fxFLex="50"></div>
                    </div>

                    <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start" *ngIf="isShowInputBank">
                      <mat-form-field fxFlex="50" class="pr-16">
                        <input matInput #inputName maxlength="15" placeholder="{{ ((this.personTypeSelected.value === 1) ? 'SETTINGS.REGISTRATION.NAME' : 'SETTINGS.REGISTRATION.SOCIAL_NAME') | translate }}" formControlName="name" [errorStateMatcher]="bankAccountMatchers.name" required>
                        <mat-error *ngIf="bankAccountForm.controls.name.hasError('required')">
                          {{ 'SETTINGS.REGISTRATION.NAME_REQUIRED' | translate }}
                        </mat-error>
                        <mat-hint align="end">{{inputName.value?.length || 0}}/15</mat-hint>
                      </mat-form-field>

                      <mat-form-field fxFlex="50" class="pr-16">
                        <input
                          matInput
                          placeholder="{{ ((this.personTypeSelected.value === 1) ? 'SETTINGS.REGISTRATION.CPF' : 'SETTINGS.REGISTRATION.CNPJ') | translate }}"
                          [mask]="(this.personTypeSelected.value === 1) ? '000.000.000-00' : '000.000.000/0000-00'"
                          formControlName="documentNumber"
                          [errorStateMatcher]="bankAccountMatchers.documentNumber"
                          required>
                        <mat-error *ngIf="bankAccountForm.controls.documentNumber.hasError('required')">
                          {{ ((this.personTypeSelected.value === 1) ? 'SETTINGS.REGISTRATION.CPF_REQUIRED' : 'SETTINGS.REGISTRATION.CNPJ_REQUIRED') | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start" *ngIf="isShowInputBank">
                      <mat-form-field fxFlex="50" class="pr-16">
                        <input matInput [matAutocomplete]="autoBank" autocomplete="off" placeholder="{{ 'SETTINGS.REGISTRATION.BANK' | translate }}"
                          formControlName="bank" (ngModelChange)="searchBank($event)">
                      
                        <mat-autocomplete autoActiveFirstOption panelWidth="auto" #autoBank="matAutocomplete" xPosition="before"
                          [displayWith]="displayFnBank">
                          <mat-option *ngFor="let bank of banks" [value]="bank">
                            {{ bank.code }} - {{ bank.title }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    
                      <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-16">
                        <mat-select placeholder="{{ 'SETTINGS.REGISTRATION.TYPE_ACCOUNT' | translate }}" formControlName="accountType" [errorStateMatcher]="bankAccountMatchers.accountType" required>
                          <mat-option *ngFor="let accountType of accountTypes" [value]="accountType">
                            {{ accountType.title }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start" *ngIf="isShowInputBank">
                      <div fxFlex="50" fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field fxFlex="auto" class="pr-16">
                          <input matInput maxlength="5" placeholder="{{ 'SETTINGS.REGISTRATION.AGENCY' | translate }}" formControlName="agency" [errorStateMatcher]="bankAccountMatchers.agency" required>
                          <mat-error *ngIf="bankAccountForm.controls.agency.hasError('required')">
                            {{ 'SETTINGS.REGISTRATION.AGENCY_REQUIRED' | translate }}
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex="100px" class="pr-16">
                          <input matInput maxlength="1" placeholder="{{ 'SETTINGS.REGISTRATION.AGENCY_DV' | translate }}" formControlName="agencyDv">
                        </mat-form-field>
                      </div>

                      <div fxFlex="50" fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field fxFlex="auto" class="pr-16">
                          <input matInput maxlength="13" placeholder="{{ 'SETTINGS.REGISTRATION.ACCOUNT' | translate }}" formControlName="account" [errorStateMatcher]="bankAccountMatchers.account" required>
                          <mat-error *ngIf="bankAccountForm.controls.account.hasError('required')">
                            {{ 'SETTINGS.REGISTRATION.ACCOUNT_REQUIRED' | translate }}
                          </mat-error>
                        </mat-form-field>
                      
                        <mat-form-field fxFlex="100px" class="pr-16">
                          <input matInput maxlength="2" placeholder="{{ 'SETTINGS.REGISTRATION.ACCOUNT_DV' | translate }}" formControlName="accountDv" [errorStateMatcher]="bankAccountMatchers.accountDv" required>
                          <mat-error *ngIf="bankAccountForm.controls.accountDv.hasError('required')">
                            {{ 'SETTINGS.REGISTRATION.ACCOUNT_DV_REQUIRED' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center" class="mt-32">
                      <btn [btnLoading]="true" [loading]='isBankAccountLoading' [buttonDisabled]="bankAccountForm.invalid"  (click)="saveBankAccount()" class="ml-16">{{ 'SETTINGS.SAVE' | translate }}</btn>
                    </div>
                  </div>
                </form>
              </div>
            </mat-tab>
            <!-- END TAB REGISTRATION -->

            <!-- TAB STEPS -->
            <mat-tab label="{{ 'SETTINGS.TABS.STEPS' | translate }}">
              <div class="p-32 pt-16" fxLayout="column" fxLayoutAlign="stretch stretch">
                <div fxFlex="100" class="box-line p-16 mt-16 mb-16" *ngFor="let championship of championships; let i = index">
                  <div fxLayout="row" fxLayoutAlign="strech start">
                    <div fxLayout="column" fxFlex="auto">
                      <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
                        <mat-form-field fxFlex="50" class="pr-16">
                          <input matInput [disabled]="isDisabledSteps" placeholder="{{ 'SETTINGS.STEPS.NAME_STEP' | translate }} {{ (i+1) }}" [ngModel]="championship.name" (ngModelChange)="championship.name = $event.toUpperCase()" uppercase required>
                        </mat-form-field>
                        
                        <mat-slide-toggle fxFlex="auto" [disabled]="isDisabledSteps" [checked]="(championship.online) ? true : false" [ngModel]="championship.online" (ngModelChange)="championship.online = $event">Online</mat-slide-toggle>
                      </div>

                      <mat-error *ngIf="championship.error !== null && championship.error.length > 0" class="mb-8">
                        {{ championship.error }}
                      </mat-error>

                      <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center">
                        {{ 'SETTINGS.STEPS.RANKING_TYPE' | translate }}

                        <mat-radio-group class="ml-32" [disabled]="isDisabledSteps" [(ngModel)]="championship.type">
                          <mat-radio-button [value]="1" class="mr-32">{{ 'SETTINGS.STEPS.RANK' | translate }}</mat-radio-button>
                          <mat-radio-button [value]="3">{{ 'SETTINGS.STEPS.POINTS' | translate }}</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                    <btn-transparent fxFlex="35px" materialIcon="delete" buttonWidth="35px" iconAlign="left" (click)="removeChampionship(i)" *ngIf="!championship.id"></btn-transparent>
                  </div>
                </div>

                <div fxFlex="100" class="mt-8 mb-16" *ngIf="!isDisabledSteps">
                  <btn-icon-small icon="add_circle_outline" iconAlign="left" (click)="addChampionship()">{{ 'SETTINGS.STEPS.NEW' | translate }}</btn-icon-small>
                </div>

                <div fxLayout="row" fxLayoutAlign="end center" class="mt-32" *ngIf="!isDisabledSteps">
                  <btn [btnLoading]="true" [loading]="isLoadingSteps" (click)="saveChampionships()" class="ml-16">{{ 'SETTINGS.SAVE' | translate }}</btn>
                </div>

              </div>
            </mat-tab>
            <!-- END TABS STEPS -->

            <mat-tab label="{{ 'SETTINGS.TABS.LEADERBOARD' | translate }}" *ngIf="event.enabled_leaderboard">
              <div class="p-32 pt-16" fxLayout="column" fxLayoutAlign="stretch stretch">
                <div fxLayout="row" fxLayoutAlign="end center" class="mt-32">
                  <btn [btnLoading]="true" [loading]="isLeaderboardPublishLoading" type="grayscale"
                    (click)="publishOrUnpublishLeaderboadEvent()">
                    {{ (!event.leaderboard_published ? 'SETTINGS.LEADERBOARD.STATUS.PUBLISH' : 'SETTINGS.LEADERBOARD.STATUS.UNPUBLISH') | translate | uppercase }}
                  </btn>
                </div>

                <h2>{{ 'SETTINGS.LEADERBOARD.SETTINGS' | translate }}</h2>
                <span class="mb-32">{{ 'SETTINGS.LEADERBOARD.SETTINGS_DESCRIPTION' | translate }}</span>

                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
                  <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
                    <input
                      matInput
                      placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_BACKGROUND' | translate }}"
                      [value]="colors.leaderboard.bg"
                      [(ngModel)]="colors.leaderboard.bg"
                      [(colorPicker)]="colors.leaderboard.bg"
                      [cpOutputFormat]="'hex'"
                      [cpPosition]="'bottom'"
                      [cpPositionOffset]="'0%'"
                      [cpPositionRelativeToArrow]="false"
                      [cpWidth]="'auto'"
                      [cpHeight]="'auto'"
                      [cpSaveClickOutside]="true"
                      [cpOKButton]="false"
                      [cpCancelButton]="false"
                      [cpDialogDisplay]="'popup'"
                      [cpAlphaChannel]="'disabled'"
                      uppercase
                      required>
                    <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboard.bg"></div>
                  </mat-form-field>

                  <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
                    <input matInput placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_LINE_DARK' | translate }}" [value]="colors.leaderboard.rowDark" [(colorPicker)]="colors.leaderboard.rowDark"
                           [cpOutputFormat]="'hex'" [cpPosition]="'bottom'" [cpPositionOffset]="'0%'" [cpPositionRelativeToArrow]="false" [cpWidth]="'auto'"
                           [cpHeight]="'auto'" [cpSaveClickOutside]="true" [cpOKButton]="false" [cpCancelButton]="false" [cpDialogDisplay]="'popup'"
                           [cpAlphaChannel]="'disabled'" uppercase required>
                    <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboard.rowDark"></div>
                  </mat-form-field>

                  <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
                    <input matInput placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_TEXT' | translate }}" [value]="colors.leaderboard.text" [(colorPicker)]="colors.leaderboard.text"
                           [cpOutputFormat]="'hex'" [cpPosition]="'bottom'" [cpPositionOffset]="'0%'" [cpPositionRelativeToArrow]="false" [cpWidth]="'auto'"
                           [cpHeight]="'auto'" [cpSaveClickOutside]="true" [cpOKButton]="false" [cpCancelButton]="false" [cpDialogDisplay]="'popup'"
                           [cpAlphaChannel]="'disabled'" required>
                    <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboard.text"></div>
                  </mat-form-field>

                  <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
                    <input matInput placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_NAME_BOX' | translate }}" [value]="colors.leaderboard.boxName" [(colorPicker)]="colors.leaderboard.boxName"
                           [cpOutputFormat]="'hex'" [cpPosition]="'bottom'" [cpPositionOffset]="'0%'" [cpPositionRelativeToArrow]="false" [cpWidth]="'auto'"
                           [cpHeight]="'auto'" [cpSaveClickOutside]="true" [cpOKButton]="false" [cpCancelButton]="false" [cpDialogDisplay]="'popup'"
                           [cpAlphaChannel]="'disabled'" required>
                    <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboard.boxName"></div>
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
                  <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
                    <input
                      matInput
                      placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_HIGHLIGHT' | translate }}"
                      [value]="colors.leaderboard.highlight"
                      [(colorPicker)]="colors.leaderboard.highlight"
                      [cpOutputFormat]="'hex'"
                      [cpPosition]="'bottom'"
                      [cpPositionOffset]="'0%'"
                      [cpPositionRelativeToArrow]="false"
                      [cpWidth]="'auto'"
                      [cpHeight]="'auto'"
                      [cpSaveClickOutside]="true"
                      [cpOKButton]="false"
                      [cpCancelButton]="false"
                      [cpDialogDisplay]="'popup'"
                      [cpAlphaChannel]="'disabled'"
                      required>
                    <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboard.highlight"></div>
                  </mat-form-field>
                </div>

                <mat-slide-toggle [checked]="isShowBrandInLeaderboard" [(ngModel)]="isShowBrandInLeaderboard">{{ 'SETTINGS.LEADERBOARD.LEADERBOARD_BRAND' | translate }}</mat-slide-toggle>

                <div fxLayout="row" fxLayoutAlign="end center" class="mt-16 mb-16">
                  <!--<btn [btnLoading]="true" type="grayscale">{{ 'SETTINGS.LEADERBOARD.PREVIEW' | translate }}</btn>-->
                  <btn [btnLoading]="true" [loading]="isLeaderboardLoading" (click)="saveLeaderboardColors()" class="ml-16">{{ 'SETTINGS.SAVE' | translate }}</btn>
                </div>

                  <!--<div fxLayout="row" fxLayoutAlign="center start" class="mt-32">
                    <img src="./assets/images/mockups/leaderboard.png" />
                  </div>-->

                  <!--<div class="line-bottom mt-32 mb-8"></div>-->

                  <!--<h2>{{ 'SETTINGS.LEADERBOARD.SETTINGS_TV' | translate }}</h2>
                  <span class="mb-32">{{ 'SETTINGS.LEADERBOARD.SETTINGS_TV_DESCRIPTION' | translate }}</span>

                  <div fxLayout="row" fxLayoutAlign="start start" class="mt-32">
                    <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
                      <input matInput placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_BACKGROUND' | translate }}" [value]="colors.leaderboardTv.bg" [(colorPicker)]="colors.leaderboardTv.bg"
                        [cpOutputFormat]="'hex'" [cpPosition]="'bottom'" [cpPositionOffset]="'0%'" [cpPositionRelativeToArrow]="false" [cpWidth]="'auto'"
                        [cpHeight]="'auto'" [cpSaveClickOutside]="true" [cpOKButton]="false" [cpCancelButton]="false" [cpDialogDisplay]="'popup'"
                        [cpAlphaChannel]="'disabled'" required>
                      <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboardTv.bg"></div>
                    </mat-form-field>
                  
                    <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
                      <input matInput placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_EVENT_NAME' | translate }}" [value]="colors.leaderboardTv.eventName"
                        [(colorPicker)]="colors.leaderboardTv.eventName" [cpOutputFormat]="'hex'" [cpPosition]="'bottom'" [cpPositionOffset]="'0%'"
                        [cpPositionRelativeToArrow]="false" [cpWidth]="'auto'" [cpHeight]="'auto'" [cpSaveClickOutside]="true" [cpOKButton]="false"
                        [cpCancelButton]="false" [cpDialogDisplay]="'popup'" [cpAlphaChannel]="'disabled'" required>
                      <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboardTv.eventName"></div>
                    </mat-form-field>
                  
                    <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
                      <input matInput placeholder="{{ 'SETTINGS.LEADERBOARD.CATEGORY' | translate }}" [value]="colors.leaderboardTv.category" [(colorPicker)]="colors.leaderboardTv.category"
                        [cpOutputFormat]="'hex'" [cpPosition]="'bottom'" [cpPositionOffset]="'0%'" [cpPositionRelativeToArrow]="false" [cpWidth]="'auto'"
                        [cpHeight]="'auto'" [cpSaveClickOutside]="true" [cpOKButton]="false" [cpCancelButton]="false" [cpDialogDisplay]="'popup'"
                        [cpAlphaChannel]="'disabled'" required>
                      <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboardTv.category"></div>
                    </mat-form-field>
                  
                    <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
                      <input matInput placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_BACKGROUND_CELL' | translate }}" [value]="colors.leaderboardTv.cell"
                        [(colorPicker)]="colors.leaderboardTv.cell" [cpOutputFormat]="'hex'" [cpPosition]="'bottom'" [cpPositionOffset]="'0%'"
                        [cpPositionRelativeToArrow]="false" [cpWidth]="'auto'" [cpHeight]="'auto'" [cpSaveClickOutside]="true" [cpOKButton]="false"
                        [cpCancelButton]="false" [cpDialogDisplay]="'popup'" [cpAlphaChannel]="'disabled'" required>
                      <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboardTv.cell"></div>
                    </mat-form-field>
                  </div>
                  
                  <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field fxFlex="25" fxFlex.xs="50" class="pr-16">
                      <input matInput placeholder="{{ 'SETTINGS.LEADERBOARD.COLOR_PROGRESS_BAR' | translate }}" [value]="colors.leaderboardTv.progressBar"
                        [(colorPicker)]="colors.leaderboardTv.progressBar" [cpOutputFormat]="'hex'" [cpPosition]="'bottom'" [cpPositionOffset]="'0%'"
                        [cpPositionRelativeToArrow]="false" [cpWidth]="'auto'" [cpHeight]="'auto'" [cpSaveClickOutside]="true" [cpOKButton]="false"
                        [cpCancelButton]="false" [cpDialogDisplay]="'popup'" [cpAlphaChannel]="'disabled'" required>
                      <div class="picker-preview-suffix" matSuffix [style.background]="colors.leaderboardTv.progressBar"></div>
                    </mat-form-field>
                  </div>

                  <div fxLayout="row" fxLayoutAlign="end center" class="mt-16 mb-16">
                    <btn [btnLoading]="true" type="grayscale">{{ 'SETTINGS.LEADERBOARD.PREVIEW' | translate }}</btn>
                    <btn [btnLoading]="true" class="ml-16">{{ 'SETTINGS.SAVE' | translate }}</btn>
                  </div>
                  
                  <div fxLayout="row" fxLayoutAlign="center start" class="mt-32 mb-16">
                    <img src="./assets/images/mockups/leaderboard-tv.png" />
                  </div>-->

                <!--<div class="line-bottom mt-32 mb-8"></div>

                <span class="mt-16">
                  Configure a ordem das categorias no leaderboard tv.
                </span>

                <div fxLayout="row" fxLayoutAlign="start start">
                  <mat-form-field fxFlex="50" fxFlex.xs="100" class="pr-16">
                    <mat-select placeholder="{{ 'SETTINGS.REGISTRATION.TYPE_PERSON' | translate }}" (selectionChange)="personTypeSelect($event)"
                      required>
                      <mat-option *ngFor="let type of personTypes" [value]="type">
                        {{ type.title }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>


                <div fxLayout="row" fxLayoutAlign="stretch stretch" class="wrapper">
                  <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="33.3" class="pr-16">
                    <h4>{{ 'SETTINGS.LEADERBOARD.CATEGORIES' | translate }}</h4>
                    &lt;!&ndash; [dragula]='"columns-leaderboard-tv"' &ndash;&gt;
                    <div fxFlex="100" fxLayout="column" fxLayoutAlign="start start">
                      <div fxFlex="initial" fxLayout="row" fxLayoutAlign="start center" class="box-line drag-and-drop p-8 mb-4 mt-4">
                        <mat-icon class="mr-8">menu</mat-icon>
                        Trio Masculino
                      </div>
                      <div fxFlex="initial" fxLayout="row" fxLayoutAlign="start center" class="box-line drag-and-drop p-8 mb-4 mt-4">
                        <mat-icon class="mr-8">menu</mat-icon>
                        Trio Feminino
                      </div>
                      <div fxFlex="initial" fxLayout="row" fxLayoutAlign="start center" class="box-line drag-and-drop p-8 mb-4 mt-4">
                        <mat-icon class="mr-8">menu</mat-icon>
                        Ind Feminino
                      </div>
                      <div fxFlex="initial" fxLayout="row" fxLayoutAlign="start center" class="box-line drag-and-drop p-8 mb-4 mt-4">
                        <mat-icon class="mr-8">menu</mat-icon>
                        Ind Masculino
                      </div>
                      <div fxFlex="initial" fxLayout="row" fxLayoutAlign="start center" class="box-line drag-and-drop p-8 mb-4 mt-4">
                        <mat-icon class="mr-8">menu</mat-icon>
                        Elite
                      </div>
                    </div>
                  </div>

                  <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="33.3" class="pr-16">
                    <h4>{{ 'SETTINGS.LEADERBOARD.SPLIT_LEFT' | translate }}</h4>
                    &lt;!&ndash; [dragula]='"columns-leaderboard-tv"' &ndash;&gt;
                    <div fxFlex="100" fxLayout="column" fxLayoutAlign="start start">
                      
                    </div>
                  </div>

                  <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="33.3" class="pr-16">
                    <h4>{{ 'SETTINGS.LEADERBOARD.SPLIT_RIGHT' | translate }}</h4>
                    &lt;!&ndash; [dragula]='"columns-leaderboard-tv"' &ndash;&gt;
                    <div fxFlex="100" fxLayout="column" fxLayoutAlign="start start">
                  
                    </div>
                  </div>
                </div>-->


              </div>
            </mat-tab>

          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <scroll-to-top (scrollToTopEvent)="scrollToTop($event)" [isShow]="isShowScrollToTop"></scroll-to-top> -->