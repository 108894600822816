<div class="page-layout blank pb-64">
  <div class="p-32">
    <div class="content">
      <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
        <div class="p-32 pt-16">
          <h2>{{ 'PERMISSIONS.ADMIN' | translate | uppercase }}</h2>

          <form name="eventForm" [formGroup]="permissionForm" autocomplete="off" novalidate>
            <mat-form-field fxFlex="50" fxFlex.xs="100" class="mt-8">
              <input matInput [matAutocomplete]="autoUsers" autocomplete="off" placeholder="{{ 'PERMISSIONS.USER' | translate }}" formControlName="user"
                     (ngModelChange)="searchUser($event)" [errorStateMatcher]="matchers.user" required>
              
              <mat-autocomplete autoActiveFirstOption panelWidth="auto" #autoUsers="matAutocomplete" xPosition="before" [displayWith]="displayFnUser">
                <mat-option *ngFor="let user of users" [value]="user">
                  <div fxLayout="row" fxLayoutAlign="start center" class="small-font">
                    <img src="{{ (user.avatar) ? user.avatar : null | placeholderImage:'user' }}" width="80"
                         class="avatar corner-radius" />
                    {{ user.firstname }} {{ user.lastname }} {{ user.email }}
                  </div>
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="permissionForm.controls.user.hasError('required')">
                {{ 'PERMISSIONS.VALIDATORS.USER_REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
          </form>

          <div class="mb-0 mt-8" fxLayoutAlign="end center">
            <btn [btnLoading]="true" [loading]="isLoading" [buttonDisabled]="permissionForm.value.user === null" (click)="saveAdmin()">{{ 'PERMISSIONS.SAVE' | translate | uppercase }}</btn>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>
