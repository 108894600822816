import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';

import { ForgotPasswordComponent } from './forgot-password.component';

const routes = [
  {
    path     : 'forgot-password',
    component: ForgotPasswordComponent
  }
];

@NgModule({
  imports     : [
      SharedModule,
      RouterModule.forChild(routes)
  ],
  declarations: [
    ForgotPasswordComponent
  ]
})

export class ForgotPasswordModule
{

}
