import { Lot } from '../../../../../../core/model/lot';
import { Competition } from '../../../../../../core/model/competition';
import { EventGuard } from '../../../../../../core/guards/event.guard';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { MatDialogRef, MatIconRegistry } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { FormErrorStateMatcher } from '../../../../../../core/utils/form-error-state-matcher';
import { SubDivisionHttpService } from '../../../../../../core/services/sub-division/sub-division-http.service';
import { SubDivision } from '../../../../../../core/model/sub-division';
import { Errors } from '../../../../../../core/model/errors';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { CompetitionService } from '../../../../../../core/services/competition/competition.service';

@Component({
  selector: 'fuse-app-modal-configuration-sub-division',
  templateUrl: './modal-configuration-sub-division.component.html',
  styleUrls: ['./modal-configuration-sub-division.component.scss']
})
export class ModalConfigurationSubDivisionComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();

  public championshipId: number;
  public competitions: Competition[];
  

  // Translate
  translate: any;

  competitionsCheckbox = [];

  subDivisionForm: FormGroup;
  matchers: any;
  isLoading = false;
  requestErrors: string;

  constructor(
    public dialogRef: MatDialogRef<ModalConfigurationSubDivisionComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private subDivisionHttpService: SubDivisionHttpService,
    private iconRegistry: MatIconRegistry,
    private competitionService: CompetitionService
  ) {
    this.registerTranslate();
    iconRegistry.addSvgIcon(
      'ic-cat-info',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/category/ic-cat-info.svg')
    );
    this.subDivisionForm = this.formBuilder
      .group({
        competitions: new FormArray([])
      });
    this.matchers = {
      name: new FormErrorStateMatcher(),
    };
  }

  ngOnInit(): void {
    this.competitions.forEach((competition: Competition) => {
      this.competitionsCheckbox.push({
        id: competition.id,
        name: competition.name,
        checked: !!competition.enable_sub_division
      });
    });
    const controlsCompetitions = this.competitionsCheckbox.map(c => new FormControl(c.checked));
    this.subDivisionForm = this.formBuilder.group({
      competitions: new FormArray(controlsCompetitions),
    });
  }

  private registerTranslate(): void {
    this.translationLoader
      .loadTranslations(english, portugues);

      const subscription = this.translateService.get('MODAL_CONFIGURATION_SUB_DIVISION')
      .subscribe((res: any) => {
        this.translate = res;
      });

      this.subscriptions.add(subscription);
  }

  public saveSubDivisionConfiguration(): void {
    const data = [];
    this.subDivisionForm.value.competitions
      .forEach((isChecked: boolean, key: number) => {
        data.push({
          id: this.competitionsCheckbox[key].id,
          enable_sub_division: isChecked
        });
      });
    this.isLoading = true;
    this.competitionService
      .massUpdate({ competitions: data })
      .subscribe((result) => {
        this.subDivisionForm.value.competitions
        .forEach((isChecked: boolean, key: number) => {
          this.competitions[key].enable_sub_division = isChecked;
        });
        this.dialogRef.close({ isSave:  true, competitions: this.competitions });
        this.isLoading = false;
      }, (err: any) => {
        const errors: Errors = new Errors().deserialize((err.error as any));
        this.requestErrors = errors.getFullMessages();
        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
