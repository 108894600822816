import { ScrollToTopComponent } from './scroll-to-top.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ScrollToTopComponent
  ],
  exports: [
    ScrollToTopComponent
  ],
  providers: [
  ]
})
export class ScrollToTopModule { }