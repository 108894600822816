<div mat-dialog-content class="dialog-container">
  <!-- <div class="modal-background" (click)="dialogRef.close()"></div> -->
  <div class="card-content">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>{{ translate.TITLE | uppercase }} - {{ competition.name | uppercase }}</h1>
      <span class="close" (click)="dialogRef.close(null)">
        <img src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>

    <div fxLayout="row" class="mt-16 mb-32">
      <description-section title="{{ translate.TITLE_SECTION }}" text="{{ translate.DESCRIPTION_SECTION }}">
        <mat-icon class="ic-info mr-8" svgIcon="ic-cat-info"></mat-icon>
      </description-section>
    </div>

    <form [formGroup]="lotForm" name="lotForm" autocomplete="off" novalidate>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
        <div fxFlex="50" fxLayout="column">
          <h4>{{ translate.LOTS }}</h4>
          <div formArrayName="lots">
            <div *ngFor="let lot of formLots().controls; let i = index" class="pb-4">
              <mat-checkbox [formControlName]="i">{{ competition.lots[i].name }}</mat-checkbox>
            </div>
          </div>
        </div>

        <div fxFlex="50" fxLayout="column">
          <h4>{{ translate.COMPETITIONS }}</h4>
          <div formArrayName="competitions">
            <div *ngFor="let competitionCheckbox of formCompetition().controls; let i = index" class="pb-4">
              <mat-checkbox [formControlName]="i">{{ competitionsCheckbox[i].name }}</mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </form>
    

    <div class="mt-16 mb-8 text-red" *ngIf="requestErrors !== null">
      {{ requestErrors }}
    </div>

    <div class="mb-0 mt-8" fxLayoutAlign="end center">
      <btn type="grayscale" (click)="dialogRef.close(null)" class="mr-16">{{ translate.CANCEL | uppercase }}</btn>
      <btn [btnLoading]="true" [loading]="isLoading" [buttonDisabled]="lotForm.invalid" (click)="copyLots()">{{
        translate.SAVE | uppercase }}</btn>
    </div>
  </div>
</div>