import { Component, OnInit, OnDestroy } from "@angular/core";
import { Event } from "../../../../../core/model/event";
import { Championship } from "../../../../../core/model/championship";
import { FormBuilder } from "@angular/forms";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { BreadcrumbsService } from "../../../../../core/components/breadcrumbs/breadcrumbs.service";
import { FuseTranslationLoaderService } from "../../../../../core/services/translation-loader.service";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthGuard } from "../../../../../core/guards/auth.guard";
import { EventGuard } from "../../../../../core/guards/event.guard";
import { MatDialog, MatIconRegistry } from "@angular/material";
import { GooglePlacesService } from "../../../../../core/services/google-places.service";
import { DragulaService } from "ng2-dragula";
import { EventsService } from "../../../../../core/services/event/events.service";
import { ChampionshipService } from "../../../../../core/services/championship/championship.service";
import { ToastrService } from "../../../../../core/components/toastr_/toastr/toastr.service";
import { BankAccountService } from "../../../../../core/services/bank-account/bank-account.service";
import { BanksService } from "../../../../../core/services/banks/banks.service";
import { EventConfigurationsService } from "../../../../../core/services/event-configurations/event-configurations.service";
import { locale as english } from "./../i18n/en-US";
import { locale as portugues } from "./../i18n/pt-BR";
import { config } from "../../../../../core/config/config";
import { Subscription } from "rxjs";
import { Errors } from "../../../../../core/model/errors";

@Component({
  selector: "app-event-championships",
  templateUrl: "./event-championships.component.html",
  styleUrls: ["./event-championships.component.scss"]
})
export class EventChampionshipsComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();

  // Translate
  public translate: any;

  // Event
  public event: Event;

  // Championships
  public championships: Championship[] = [];
  public userMe: any = null;

  public isLoadingSteps = false;
  public isLoadingStepsSubDivision = false;
  public isDisabledSteps = true;
  public enableSubDivision = false;

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private googlePlacesService: GooglePlacesService,
    public dialog: MatDialog,
    private dragulaService: DragulaService,
    private eventService: EventsService,
    private championshipService: ChampionshipService,
    private toastr: ToastrService,
    private bankAccountService: BankAccountService,
    private banksService: BanksService,
    private eventConfigurationsService: EventConfigurationsService
  ) {
    this.translationLoader.loadTranslations(english, portugues);

    this.subscriptions.add(
      this.translateService.get("SETTINGS").subscribe((res: any) => {
        this.translate = res;
      })
    );

    if (this.translate) {
      this.titleService.setTitle(
        `${config.titleBase} - ${this.translate.TITLE}`
      );

      this.breadcrumbsService.replaceAll([
        {
          text: this.translate.TITLE_STEPS,
          route: `/event/championships`
        }
      ]);
    }

    this.event = new Event().deserialize(
      this.eventGuard.getActiveEvent().event
    );

    this.isDisabledSteps = !this.authGuard.getMe().user.admin;

    if (this.event.championships && this.event.championships.length > 0) {
      this.event.championships.forEach(championship => {
        this.championships.push(new Championship().deserialize(championship));
      });
    }

    this.userMe = this.authGuard.getMe().user;

    this.registerGetEventDetail();
  }

  ngOnInit() {
    this.fetchEventInformation();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private registerGetEventDetail(): void {
    // get Event details
    const subscription = this.eventService
      .eventInformationObservable()
      .subscribe(result => {
        this.championships = this.event.championships;
      });

    this.subscriptions.add(subscription);
  }

  fetchEventInformation() {
    this.eventService.fetchEventInformation(this.event.id);
  }

  // Add Championship
  addChampionship() {
    const champ = new Championship();
    champ.name = "";
    champ.online = false;
    champ.type = 1;
    this.championships.push(champ);
  }

  removeChampionship(index: number) {
    this.championships.splice(index, 1);
  }

  saveChampionships() {
    this.isLoadingSteps = true;

    if (this.championships.length > 0) {
      let champError = false;
      this.championships.forEach(championship => {
        if (championship.name) {
          championship.name.toUpperCase();
        }
        if (!championship.name || championship.name.length === 0) {
          championship.error = this.translate.STEPS.VALIDATION.NAME_REQUIRED;
          champError = true;
          this.isLoadingSteps = false;
        }
      });

      if (champError) {
        return;
      }
    }

    const championships = {
      championships: this.championships
    };

    const subscription = this.championshipService
      .updateChampionships(championships, this.event.id)
      .subscribe(
        result => {
          this.isLoadingSteps = false;
          this.eventGuard.updateEventChampionships(result);

          if (result.length > 0) {
            const newChampionships: Championship[] = [];
            result.forEach(championship => {
              newChampionships.push(
                new Championship().deserialize(championship)
              );
            });

            this.championships = newChampionships;

            this.toastr.show(
              this.translate.STEPS.RESPONSE.DESCRIPTION,
              this.translate.STEPS.RESPONSE.TITLE,
              null,
              "success"
            );
          }
        },
        err => {
          this.isLoadingSteps = false;
          const errors: Errors = new Errors().deserialize(err.error as any);

          this.toastr.show(
            errors.getFullMessages(),
            this.translate.STEPS.TOASTR.CHAMPIONSHIP.ERROR.TITLE,
            null,
            "error"
          );
        }
      );
    this.subscriptions.add(subscription);
  }
}
