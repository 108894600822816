<div fxLayout="row" fxLayoutAlign="space-between flex-start">
  <div fxLayout="column" fxFlex="30">
    <!-- Filter competitors -->
    <div
      [ngClass]="{ 'card auto-width pt-16 pb-16 mr-16': true, sticky: sticky && heats.length > 1 }"
      (scroll)="onScrollFilter($event)"
    >
      <div fxLayout="column" fxLayoutAlign="flex-start" class=" mt-0 mb-0">
        <description-section
          class="ml-16 mr-16"
          title="{{
            'HEAT_COMPETITORS.HEATS_GENERATOR.FILTER_COMPETITORS.COMPETITORS' | translate
          }}"
        ></description-section>
        <form
          class="m-16 mt-20 mb-8"
          name="filterFormGroup"
          [formGroup]="filterFormGroup"
          autocomplete="off"
          novalidate
          fxFlex="100"
          fxFlex.xs="100"
          fxLayout="column"
          fxLayoutAlign="flex-start"
        >
          <mat-form-field fxFlex="100" fxFlex.xs="100">
            <mat-select
              matInput
              placeholder="{{
                'HEAT_COMPETITORS.HEATS_GENERATOR.FILTER_COMPETITORS.FIELDS.COMPETITIONS'
                  | translate
              }}"
              formControlName="competitions"
              [errorStateMatcher]="matchersFilterGroup.competitions"
              required
            >
              <mat-option
                *ngFor="let competition of competitions"
                [value]="competition?.id"
              >
                {{ competition.name }}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="filterFormGroup.controls.competitions.hasError('required')">
              {{
              "HEAT_COMPETITORS.HEATS_GENERATOR.FILTER_COMPETITORS.VALIDATIONS.COMPETITIONS_REQUIRED"
                | translate
              }}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="100" fxFlex.xs="100" *ngIf="filterFormGroup.controls.sub_divisions">
            <mat-select
              matInput
              placeholder="{{
                'HEAT_COMPETITORS.HEATS_GENERATOR.FILTER_COMPETITORS.FIELDS.SUB_DIVISION'
                  | translate
              }}"
              formControlName="sub_divisions"
              required
            >
              <mat-option
                *ngFor="let subDivision of subDivisions"
                [value]="subDivision.id"
              >
                {{ subDivision.name }}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="filterFormGroup.controls.sub_divisions.hasError('required')">
              {{
              "HEAT_COMPETITORS.HEATS_GENERATOR.FILTER_COMPETITORS.VALIDATIONS.SUB_DIVISION_REQUIRED"
                | translate
              }}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="100" fxFlex.xs="100">
            <mat-select
              matInput
              placeholder="{{
                'HEAT_COMPETITORS.HEATS_GENERATOR.FILTER_COMPETITORS.FIELDS.WODS' | translate
              }}"
              formControlName="wods"
              [errorStateMatcher]="matchersFilterGroup.wods"
              required
            >
              <mat-option *ngFor="let wod of wods" [value]="wod?.id">
                {{ wod?.name }}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="filterFormGroup?.controls?.wods?.hasError('required')">
              {{
              "HEAT_COMPETITORS.HEATS_GENERATOR.FILTER_COMPETITORS.VALIDATIONS.WODS_REQUIRED"
                | translate
              }}
            </mat-error>
          </mat-form-field>

          <mat-form-field
            fxFlex="100"
            fxFlex.xs="100"
            floatLabel="never"
            class="search"
          >
            <mat-icon matPrefix class="icon-search">search</mat-icon>
            <input
              matInput
              placeholder="{{
                'HEAT_COMPETITORS.HEATS_GENERATOR.FILTER_COMPETITORS.FIELDS.SEARCH'
                  | translate
              }}"
              formControlName="search"
            />
          </mat-form-field>

          <mat-radio-group>
            <mat-radio-button [checked]="item.id === 1" fxFlex="50" value="{{ item.id }}" (click)="enableCompetitorType = item.id" class="mb-8" *ngFor="let item of [{id: 1, name: 'Nº'}, {id: 2, name: 'Rank'}]">
              {{ item.name }}
            </mat-radio-button>
          </mat-radio-group>
        </form>
        
        <!-- Empty Wod-->
        <div fxLayout="column" fxLayoutAlign="center center" class="p-32 emtpy-content" *ngIf="wods.length === 0">
          <img src="./assets/icons/placeholder/ic-empty.svg" />
          <span class="mt-8 text-center">{{ 'HEAT_COMPETITORS.HEATS_GENERATOR.FILTER_COMPETITORS.TOASTR.WODS.DESCRIPTION' | translate }}</span>
        </div>
        
        <!-- Competitor list -->
        <div
          [ngClass]="{ 'count-competitors pl-16 pr-16 mb-8': true, stickyCountCompetitor: stickyCountCompetitor && sticky }"
          *ngIf="wods && wods.length > 0"
          fxLayout="row wrap"
          fxLayoutAlign="space-between start"
        >
          <div fxLayout="row" fxLayoutAlign="start start">
            <label for="select-competitor">Selecionar</label>
            <input
              id="select-competitor"
              class="ml-8"
              style="border-bottom: 1px solid rgb(170, 170, 170); width: 45px;"
              matInput
              type="number"
              min="0"
              [max]="(competitors | filterBy: competitorsFilter).length"
              (input)="selectedCompetitor($event)"
            />
            <mat-icon (click)="clearSelectedCompetitor()" style="font-size: 13px; cursor: pointer;">close</mat-icon>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="end end" class="text-right">
            <span [innerHTML]="getSelectedsCompetitors()"></span>
            <strong>{{(competitors | filterBy: competitorsFilter).length}}</strong> competidores
          </div>
        </div>
        
        <table class="competitors" fxFlex="100" *ngIf="wods && wods.length > 0">
          <thead>
          <tr>
            <th></th>
            <th class="text-center" *ngIf="enableCompetitorType === 2">
              <button
                (click)="setSortCompetitors('rank')"
                [ngClass]="{'sort-asc': columnSortCompetitor === 'rank' && orderSortCompetitor === 'ASC', 'sort-desc': columnSortCompetitor === 'rank' && orderSortCompetitor === 'DESC'}">
                Rank
                <mat-icon *ngIf="columnSortCompetitor === 'rank'" class="arrow-header">
                  {{ orderSortCompetitor === 'ASC' ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}
                </mat-icon>
              </button>
            </th>
            <th class="text-center"  *ngIf="enableCompetitorType === 1">
              <button
                (click)="setSortCompetitors('number')"
                [ngClass]="{'sort-asc': columnSortCompetitor === 'number' && orderSortCompetitor === 'ASC', 'sort-desc': columnSortCompetitor === 'number' && orderSortCompetitor === 'DESC'}">
                Nº
                <mat-icon *ngIf="columnSortCompetitor === 'number'" class="arrow-header">
                  {{ orderSortCompetitor === 'ASC' ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}
                </mat-icon>
              </button>
            </th>
            <th>
              <button
                (click)="setSortCompetitors('name')"
                [ngClass]="{'sort-asc': columnSortCompetitor === 'name' && orderSortCompetitor === 'ASC', 'sort-desc': columnSortCompetitor === 'name' && orderSortCompetitor === 'DESC'}">
                {{ 'HEAT_COMPETITORS.HEATS_GENERATOR.TABLE.NAME' | translate }}
                <mat-icon *ngIf="columnSortCompetitor === 'name'" class="arrow-header">
                  {{ orderSortCompetitor === 'ASC' ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}
                </mat-icon>
              </button>
            </th>
          </tr>
          </thead>
          <tbody class="ngx-dnd-container" ngxDroppable [model]="competitors">
            <ng-container *ngFor="let item of competitors | filterBy: competitorsFilter">
              <tr class="ngx-dnd-item" ngxDraggable [model]="item" [ngxDraggable]="targets" [ngClass]="{'selected': item.selected}" (click)="item.selected = !item.selected;">
                <td class="drag-handle"><mat-icon class="mr-8 menu-reorder">menu</mat-icon></td>
                <td class="rank text-center" *ngIf="enableCompetitorType === 2">{{ championship.enable_sub_division ? item.competitor.sub_division_rank : item.competitor.rank }}</td>
                <td class="number text-center" *ngIf="enableCompetitorType === 1">{{ item.competitor.number }}</td>
                <td>
                  <span fxLayout="column">
                    <a class="small" fxLayoutAlign="start center">{{ item.competitor.team.name }} 
                      <label-x class="ml-8 cut-wd" *ngIf="item.competitor.disqualified">WD</label-x>
                      <label-x class="ml-8 cut-wd" *ngIf="item.competitor.is_cut">CUT</label-x>
                    </a>
                    <span class='box' *ngIf="item.competitor.team.box">{{ item.competitor.team.box?.name }}</span>
                  </span>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div fxLayout="column" fxFlex="70" class="pl-16 heat-item">
    <!-- form heats -->
    <div class="card auto-width pt-12 mb-16">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="pl-16 pr-16 pt-0 pb-12">
        <form fxLayout="row" fxLayoutAlign="start center" [formGroup]="formHeat" (submit)="updateTransitionAndStartTime()">
          <mat-form-field class="mr-16">
            <input
              matInput
              mask="00:00"
              [dropSpecialCharacters]="false"
              formControlName="startTime"
              placeholder="{{ 'HEAT_COMPETITORS.HEATS_SCHEDULE.FIELDS.START_TIME' | translate }}"
              required
            />
            <mat-error *ngIf="formHeat.controls.startTime.hasError('required')">
              {{ "HEAT_COMPETITORS.HEATS_SCHEDULE.VALIDATIONS.START_TIME_REQUIRED" | translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              type="number"
              formControlName="transition"
              min="0"
              placeholder="{{ 'HEAT_COMPETITORS.HEATS_SCHEDULE.FIELDS.TRANSITION' | translate }}"
              required
            />
            <mat-error *ngIf="formHeat.controls.transition.hasError('required')">
              {{ "HEAT_COMPETITORS.HEATS_SCHEDULE.VALIDATIONS.TRANSITION_REQUIRED" | translate }}
            </mat-error>
            <mat-error *ngIf="formHeat.controls.transition.hasError('min')">
              {{ "HEAT_COMPETITORS.HEATS_SCHEDULE.VALIDATIONS.TRANSITION_MIN" | translate }}
            </mat-error>
          </mat-form-field>
          <btn-transparent [btnLoading]="true" [loading]="formHeat.value.isLoading" type="grayscale" materialIcon="autorenew" iconAlign="left"></btn-transparent>
        </form>
          <btn-transparent
            icon="common/ic-settings.svg"
            buttonWidth="35px"
            iconAlign="left"
            class="hide-in-drag ml-12"
            [matMenuTriggerFor]="menuOptionHeat"
          ></btn-transparent>
      </div>
    </div>
    <ng-container *ngFor="let heat of heats">
      <div class="card auto-width pt-0 mb-16">
        <div fxLayout="column" fxFlex="100">
          <div class="line-bottom title-heat" fxLayout="column">
            <div fxLayoutAlign="space-between" style="position: relative;" class="pl-32 pr-16 py-16">
              <div class="options-publish">
                <button 
                  class="oval"
                  [ngClass]="{'active': heat.published_schedule}"
                  matTooltip="{{ !heat.published_schedule ? 
                    ( 'HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.INFO.UNPUBLISH.SCHEDULE' | translate ) :
                    ( 'HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.INFO.PUBLISH.SCHEDULE' | translate )
                  }}"
                  matTooltipPosition="below"
                  matTooltipClass="below"
                ></button>
                <button
                  class="oval"
                  [ngClass]="{'active': heat.published_competitor}"
                  matTooltip="{{ !heat.published_competitor ? 
                    ( 'HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.INFO.UNPUBLISH.COMPETITOR' | translate ) :
                    ( 'HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.INFO.PUBLISH.COMPETITOR' | translate )
                  }}"
                  matTooltipPosition="below"
                  matTooltipClass="below"
                ></button>
              </div>
              <div fxLayout="column">
                <div fxLayoutAlign="flex-start center" class="title">
                  <h3 class="m-0 mr-8">{{ 'HEAT_COMPETITORS.HEAT' | translate | uppercase }} {{heat.number}}</h3>
                </div>
                <span class="heat-name pr-12" fxLayoutAlign="flex-start center">
                  {{ heat.name }}
                </span>
              </div>
              <div class="options" fxLayoutAlign="flex-end center">
                <btn-transparent
                  icon="common/ic-settings.svg"
                  buttonWidth="35px"
                  iconAlign="left"
                  class="hide-in-drag ml-12"
                  [matMenuTriggerFor]="heatActionMenu" [matMenuTriggerData]="{heat: heat}"
                ></btn-transparent>
                <btn-transparent
                  icon="common/ic-arrow-up-collapsible.svg"
                  buttonWidth="35px" iconAlign="left" class="btn-collapsible"
                  [ngClass]="{'rotate-180': !heat.isOpen}"
                  (click)="actionCollapsableHeat(heat);"
                >
                </btn-transparent>
              </div>
            </div>
            <div fxLayoutAlign="space-between center" class="detail-heat pl-32 pr-16 py-8">
              <div fxLayout="row">
                <ng-container *ngIf="heat.start_time !== null">
                  <small class="ml-0">{{heat?.start_time | customDateHeatPipe:'HH:mm':true}} {{heat && heat.end_time !== null ? '-' : '' }} {{heat?.end_time |  customDateHeatPipe:'HH:mm':true}}</small>
                </ng-container>
                <ng-container *ngIf="heat.duration">
                  <small> | {{ 'TimeCap' | uppercase }}: {{ heat.duration / 60 }}min</small>
                </ng-container>
              </div>
              <div class="options" fxLayoutAlign="flex-end center">
                <span>
                  <ng-container *ngIf="!heat.isEditLanes.edit">
                    {{'HEAT_COMPETITORS.HEATS_GENERATOR.COMPETITOR_DATAGRID.BUSY_LANES' | translate}}
                  </ng-container>
                  <strong class="pl-4 pr-4" *ngIf="!heat.isEditLanes.edit">{{ getLanesNotEmpty(heat) }}/{{ heat.total_lanes }}</strong>
                  <mat-form-field style="width: 100px" fxFlex="auto" class="pr-8" *ngIf="heat.isEditLanes.edit">
                    <input
                      [min]="1"
                      [formControl]="heat.isEditLanes.form"
                      matInput
                      type="number"
                      pattern="^[0-9]\d*$"
                      required />
                      <mat-error *ngIf="heat.isEditLanes.form.hasError('required')">
                        {{ 'HEAT_COMPETITORS.HEAT_EDIT.FORM.ERROR.LANES_REQUIRED' | translate }}
                      </mat-error>
                      <mat-error *ngIf="heat.isEditLanes.form.hasError('min')">
                        {{ 'HEAT_COMPETITORS.HEAT_EDIT.FORM.ERROR.LANES_MIN' | translate }}
                      </mat-error>
                      <mat-error *ngIf="heat.isEditLanes.form.hasError('pattern')">
                        {{ 'HEAT_COMPETITORS.HEAT_EDIT.FORM.ERROR.LANES_PATTERN' | translate }}
                      </mat-error>
                  </mat-form-field>
                  <btn-transparent
                    materialIcon="create"
                    buttonWidth="25px"
                    iconAlign="left"
                    class="ml-8"
                    isSmall="true"
                    *ngIf="!heat.isEditLanes.edit"
                    (click)="heat.isEditLanes.edit = !heat.isEditLanes.edit"
                  ></btn-transparent>
                  <btn-transparent
                    materialIcon="done"
                    buttonWidth="25px"
                    iconAlign="left"
                    classInside="small"
                    class="ml-8"
                    isSmall="true"
                    *ngIf="heat.isEditLanes.edit"
                    (click)="editTotalLanes(heat)"
                  ></btn-transparent>
                </span>
              </div>
            </div>
          </div>
          <div class="main-table mt-16" *ngIf="heat.isOpen">
            <div fxLayout="row" fxLayoutAlign="end center" class="pr-16">
              <btn-transparent
                buttonWidth="35px" class="hide-in-drag"
                iconAlign="left" materialIcon="format_line_spacing"
                [matMenuTriggerFor]="heatActionSort" [matMenuTriggerData]="{heat: heat}">
              </btn-transparent>
            </div>
            <table>
              <thead>
              <tr>
                <th></th>
                <th class="text-center">{{ 'HEAT_COMPETITORS.HEATS_GENERATOR.TABLE.LANE' | translate }}</th>
                <th>{{ 'HEAT_COMPETITORS.HEATS_GENERATOR.TABLE.COMPETITOR' | translate }}</th>
                <th class="text-center">Rank</th>
                <th class="text-center">Nº</th>
                <th>{{ 'HEAT_COMPETITORS.HEATS_GENERATOR.TABLE.COMPETITION' | translate }}</th>
                <th>{{ 'HEAT_COMPETITORS.HEATS_GENERATOR.TABLE.WOD' | translate }}</th>
                <th></th>
              </tr>
              </thead>
              <tbody
                class="ngx-dnd-container"
                ngxDroppable="{{ getNameOfTable(heat) }}"
                droppableItemClass="ngx-dnd-item"
                [model]="heat.heat_competitors"
                (drop)="builderDrop($event, heat)"
                (drag)="builderDrag($event, heat, getNameOfTable(heat), heats)"
              >
                <ng-container *ngFor="let item of heat.heat_competitors">
                  <tr class="ngx-dnd-item" class="ngx-dnd-item" ngxDraggable [model]="item" [ngxDraggable]="targetsHeats" *ngIf="item.type === 'item'">
                    <td class="drag-handle"><mat-icon class="mr-8 menu-reorder" ngxDragHandle>menu</mat-icon></td>
                    <td class="lane text-center">{{ item.lane }}</td>
                    <td>
                      <span fxLayout="column" fxLayoutAlign="center space-between">
                        <a class="small" fxLayout="row wrap" fxLayoutAlign="start center">
                          {{ item.competitor.team.name }} 
                          <label-x class="ml-8 cut-wd" *ngIf="item.competitor.disqualified">WD</label-x>
                          <label-x class="ml-8 cut-wd" *ngIf="item.competitor.is_cut">CUT</label-x>
                        </a>
                        <span class="no-show">{{ item.competitor.checkin_status === competitorCheckin.WITHDRAW ? 'NO SHOW' : '' }}</span>
                        <span class='box' *ngIf="item.competitor.team.box">{{ item.competitor.team.box?.name }}</span>
                      </span>
                    </td>
                    <td class="rank text-center">{{ championship.enable_sub_division ? item.competitor.sub_division_rank : item.competitor.rank }}</td>
                    <td class="rank text-center">{{ item.competitor.number }}</td>
                    <td>{{ item.competitor?.competition?.name }}</td>
                    <td>{{ item.wod?.name }}</td>
                    <td width="60px" align="right">
                      <btn-transparent 
                        buttonWidth="35px" class="hide-in-drag"
                        iconAlign="left" materialIcon="delete"
                        (click)="deleteLane(item, heat)">
                      </btn-transparent>
                    </td>
                  </tr>
                  
                  <tr class="ngx-dnd-item card-placeholder" ngxDraggable [moves]="false" *ngIf="item.type === 'placeholder'">
                    <td class="drag-handle"></td>
                    <td class="lane text-center">{{ item.lane }}</td>
                    <td colspan="6">{{ 'HEAT_COMPETITORS.HEATS_GENERATOR.TABLE.DRAG_AREA_COMPETITOR' | translate }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div class="footer-table px-8 py-8" fxLayout="row wrap" fxLayoutAlign="start center">
              <span class="mr-16">
                <button
                  class="oval mr-8"
                  [ngClass]="{'active': heat.published_schedule}"
                ></button>
                {{ !heat.published_schedule ? 
                  ( 'HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.INFO.UNPUBLISH.SCHEDULE' | translate | uppercase ) :
                  ( 'HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.INFO.PUBLISH.SCHEDULE' | translate | uppercase )
                }}
              </span>
              <span class="mr-16">
                <button
                  class="oval mr-8"
                  [ngClass]="{'active': heat.published_competitor}"
                ></button>
                {{ !heat.published_competitor ? 
                  ( 'HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.INFO.UNPUBLISH.COMPETITOR' | translate | uppercase ) :
                  ( 'HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.INFO.PUBLISH.COMPETITOR' | translate | uppercase )
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- MAT-MENU ACTIONS -->
    <mat-menu class="submenu bottom arrow-right" #heatActionMenu="matMenu" [overlapTrigger]="false" xPosition="before">
      <ng-template matMenuContent let-competition="competition" let-heat="heat">
        <button mat-menu-item class="line-bottom" (click)="publishedAndUnpublishedScheduleAndCompetitor(1, heat);">
          <span [ngClass]="{'text-red': heat.published_schedule}">{{ !heat.published_schedule ? ('HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.PUBLISH_SCHEDULE' | translate) : ('HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.UNPUBLISH_SCHEDULE' | translate) }}</span>
        </button>
        <button mat-menu-item class="line-bottom" (click)="publishedAndUnpublishedScheduleAndCompetitor(2, heat);">
          <span [ngClass]="{'text-red': heat.published_competitor}">{{ !heat.published_competitor ? ('HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.PUBLISH_COMPETITOR' | translate) : ('HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.UNPUBLISH_COMPETITOR' | translate) }}</span>
        </button>
        <button mat-menu-item class="line-bottom" (click)="clearLanes(heat);">
          <span class="text-red">{{ 'HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.CLEAR' | translate }}</span>
        </button>
        <button mat-menu-item class="line-bottom" (click)="deleteHeat(heat);">
          <span class="text-red">{{ 'HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.DELETE' | translate }}</span>
        </button>
      </ng-template>
    </mat-menu>
    
    <mat-menu class="submenu bottom arrow-right" #heatActionSort="matMenu" [overlapTrigger]="false" xPosition="before">
      <ng-template matMenuContent let-competition="competition" let-heat="heat">
        <button mat-menu-item class="line-bottom" (click)="orderLanes(heat, 'bast-rank-center');">
          <span>{{ 'HEAT_COMPETITORS.HEATS_GENERATOR.SORT_OPTIONS.BY_RANK_IN_MIDDLE' | translate }}</span>
        </button>
        <button mat-menu-item class="line-bottom" (click)="orderLanes(heat, 'competitor-in-middle');">
          <span>{{ 'HEAT_COMPETITORS.HEATS_GENERATOR.SORT_OPTIONS.COMPETITOR_IN_MIDDLE' | translate }}</span>
        </button>
      </ng-template>
    </mat-menu>
    <!-- MAT-MENU ACTIONS -->


    <!-- MAT-MENU ACTIONS -->
    <mat-menu class="submenu bottom arrow-right" #menuOptionHeat="matMenu" [overlapTrigger]="false" xPosition="before">
      <ng-template matMenuContent>
        <button mat-menu-item class="line-bottom" (click)="publishedUnpublishedAllSchedule()">
          <span>{{ 'HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.PUBLISH_SCHEDULE_ALL' | translate }}</span>
        </button>
        <button mat-menu-item class="line-bottom" (click)="publishedUnpublishedAllCompetitor()">
          <span>{{ 'HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.PUBLISH_COMPETITOR_ALL' | translate }}</span>
        </button>
        <button mat-menu-item class="line-bottom" (click)="publishedUnpublishedAllSchedule(true)">
          <span class="text-red">{{ 'HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.UNPUBLISH_SCHEDULE_ALL' | translate }}</span>
        </button>
        <button mat-menu-item class="line-bottom" (click)="publishedUnpublishedAllCompetitor(true)">
          <span class="text-red">{{ 'HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.UNPUBLISH_COMPETITOR_ALL' | translate }}</span>
        </button>
      </ng-template>
    </mat-menu>
    <!-- <div fxLayout="row">
      <button mat-fab color="primary" id="add-button" matTooltip="Add customer"><i class="material-icons" >group_add</i></button>
      <button mat-fab color="accent" id="add-button" matTooltip="Add customer"><i class="material-icons" >add</i></button>
    </div> -->
    <fuse-btn-mat-fab (clickType)="clickType($event)" [listMatFab]="btnMatFabConfig"></fuse-btn-mat-fab>
    <!-- <btn-fab [loading]="isLoadingSaveHeats" materialIcon="save" (click)="saveHeats()"> {{ 'HEAT_COMPETITORS.HEATS_GENERATOR.HEATS_ITEMS.SUBMIT' | translate | uppercase }}</btn-fab> -->
    <!-- <btn-fab [loading]="false" materialIcon="add" [matMenuTriggerFor]="menuOptionHeat"></btn-fab> -->

  </div>
</div>


