import {Component, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {TableConfig, TableXComponent} from '../../../../../../core/components/table-x/table-x.component';
import {Event} from '../../../../../../core/model/event';
import {Competition} from '../../../../../../core/model/competition';
import {Subscription} from 'rxjs';
import { locale as english } from './../i18n/en-US';
import { locale as portugues } from './../i18n/pt-BR';
import {FuseTranslationLoaderService} from '../../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MatDialog, MatDialogRef, MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
import {ToastrService} from '../../../../../../core/components/toastr_/toastr/toastr.service';
import {CompetitionService} from '../../../../../../core/services/competition/competition.service';
import {LotService} from '../../../../../../core/services/lot/lot.service';
import {Utils} from '../../../../../../core/utils';
import {ModalCreateLotComponent} from '../../category-lot-wod/modal-create-lot/modal-create-lot.component';
import {Lot} from '../../../../../../core/model/lot';
import {CrossXDialogComponent} from '../../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import {Errors} from '../../../../../../core/model/errors';

@Component({
  selector: 'app-lots-without-competition',
  templateUrl: './lots-without-competition.component.html',
  styleUrls: ['./lots-without-competition.component.scss']
})
export class LotsWithoutCompetitionComponent implements OnInit {

  @ViewChild('tableList', { read: TableXComponent }) tableList: TableXComponent;

  //External
  @Input() championshipId: number = null;
  @Input() championship: any = null;
  @Input() event: Event = null;

  //Translate
  public translate: any = null;
  private subscriptions: Subscription = new Subscription();
  
  public lots: Lot[] = [];

  tableCatConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: [
      {
        type: 'reproved',
        title: 'Reprovar'
      }
    ],
    massActionCountTitle: {
      single: 'resultado selecionado',
      multiple: 'resultados selecionados'
    }
  };

  public isLoading = false;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private competitionService: CompetitionService,
    private lotService: LotService,
  ) { }

  ngOnInit() {
    this.registerOnTranslate();
    this.registerOnRouteChange();
    this.getLots();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public getLots(): void {
    // Get All competitions in championship
    this.isLoading = true;
    const subscription = this.lotService.fetchLotWithoutCompetition(this.event.id)
      .subscribe(
        result => {
          console.log('result', result);
          this.lots = result;
          this.isLoading = false;
          if (this.tableList) { this.tableList.reloadItems() }
        }, err => {
          console.log('err', err);
          this.isLoading = false;
        }
      );

    this.subscriptions.add(subscription);
  }

  reorderAction(orders: any, parms: any) {
    console.log('reorder', orders);

    if (orders.type === 'reorder') {
      if (orders.tableName.indexOf('lots') > -1) {
        const parameters = {
          lots: orders.data
        };

        this.lotService.massUpdate(parameters)
          .subscribe(
            result => {
              console.log('result', result);
            }, err => {
              console.log('err', err);
            }
          );
      }
    }
  }

  // Lots
  addLot() {
    const modalRef = this.dialog.open(ModalCreateLotComponent, {
      width: '816px',
      height: '100%',
      panelClass: 'modal'
    });

    modalRef.componentInstance.championshipId = this.championship.id;
    modalRef.componentInstance.eventId = this.event.id;
    // modalRef.componentInstance.competition = competition

    modalRef.afterClosed().subscribe(result => {
      if (result) {
        this.lots.push(result.lot);
        if (this.tableList) { this.tableList.reloadItems() }

        this.toastr.show(
          this.translate.TOASTR.CREATE.DESCRIPTION,
          this.translate.TOASTR.CREATE.TITLE,
          null,
          'success',
        );
      }
    });
  }

  editLot(lot: Lot, index: number) {
    const modalRef = this.dialog.open(ModalCreateLotComponent, {
      width: '816px',
      height: '100%',
      panelClass: 'modal'
    });

    // modalRef.componentInstance.championshipId = this.championship.id
    modalRef.componentInstance.eventId = this.event.id;
    // modalRef.componentInstance.competition = competition
    modalRef.componentInstance.isEdit = true;
    modalRef.componentInstance.lot = lot;

    modalRef.afterClosed().subscribe(result => {
      if (result && result.isEdit) {
        this.lots[index] = result.lot;
        if (this.tableList) { this.tableList.reloadItems() }

        this.toastr.show(
          this.translate.TOASTR.EDIT.DESCRIPTION,
          this.translate.TOASTR.EDIT.TITLE,
          null,
          'success',
        );
      }
    });
  }

  deleteLot(lot: Lot, competition: Competition, index: number) {
    console.log(lot, competition, index);

    if (lot.sold > 0) {
      this.toastr.show(
        this.translate.TOASTR.DELETE.ERROR_DELETE_DESCRIPTION,
        this.translate.TOASTR.DELETE.ERROR_DELETE_TITLE,
        null,
        'error',
      );
      return;
    }

    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    dialogRef.componentInstance.titleDialog = this.translate.DIALOG.TITLE;
    this.translateService.get('LOTS.DIALOG.DESCRIPTION', { lot_name: lot.name }).subscribe((res: string) => {
      dialogRef.componentInstance.dialogContent = res;
    });
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.DIALOG.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.DIALOG.EXCLUIR.toUpperCase();
    dialogRef.componentInstance.object = lot;
    dialogRef.componentInstance.confirmActionRequest = true;
    dialogRef.componentInstance.dialogEvent.subscribe(result => {
      dialogRef.componentInstance.confirmLoading = true;
      this.deleteLotInAPI(lot, index, dialogRef);
      // console.log(result)
    });
  }

  deleteLotInAPI(lot: Lot, index: number, dialogRef: MatDialogRef<CrossXDialogComponent>) {
    this.lotService.deleteLot(lot.id)
      .subscribe(
        response => {
          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();

          // let index = competition.lots.indexOf(lot)
          this.lots.splice(index, 1);
          if (this.tableList) { this.tableList.reloadItems() }

          // dialog.close()
          this.toastr.show(
            this.translate.TOASTR.DELETE.DESCRIPTION,
            this.translate.TOASTR.DELETE.TITLE,
            null,
            'success',
          );
        }, err => {
          console.log('err', err);
          const errors: Errors = new Errors().deserialize((err.error as any));

          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();

          this.toastr.show(
            errors.getFullMessages(),
            this.translate.TOASTR.DELETE.ERROR_DELETE_TITLE,
            null,
            'error',
          );
        }
      );
  }

  public formatCurrency(value: string, currency: string): string {
    const unit = Utils.formatCurrency(value, currency);
    return unit ? unit : '';
  }

  private registerOnTranslate(): void {
    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService
      .get('LOTS')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);
  }

  private registerOnRouteChange(): void {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.lots = [];
          this.getLots();
        }
      });
  }

}
