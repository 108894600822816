<div mat-dialog-content class="dialog-container">
  <div class="modal-background" (click)="dialogRef.close()"></div>
  <div class="card-content" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between" class="header-modal">
      <h1>{{ translate.TITLE | uppercase }}</h1>
      <span class="close" (click)="dialogRef.close()">
        <img src="./assets/icons/modal/ic-close.svg" />
      </span>
    </div>

    <span class="description">{{ translate.DESCRIPTION }}</span>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-32 mb-16 actual-member">
      <div fxLayout="row" fxLayoutAlign="start center">
        <img src="{{ (member.user.avatar) ? member.user.avatar : null | placeholderImage:'user' }}" width="40" class="avatar" /> 
        <span class="name">{{ member.user.firstname }} {{ member.user.lastname }}</span>
      </div>

      <div class="member-out" fxLayout="row" fxLayoutAlign="center center" fxFlex="90px">
        <img src="./assets/icons/profiles/ic-member-exit.svg" class="mr-8" />{{ translate.MEMBER_OUT | uppercase }}
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-16 new-member">
      <h4>{{ translate.NEW_MEMBER }}</h4>
      <div class="member-in" fxLayout="row" fxLayoutAlign="center center" fxFlex="90px">
        <img src="./assets/icons/profiles/ic-member-enter.svg" class="mr-8" />{{ translate.MEMBER_IN | uppercase }}
      </div>
    </div>

    <form name="memberForm" [formGroup]="memberForm" autocomplete="off" novalidate>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="stretch stretch">
        <div fxFlex="50" fxFlex.xs="100" fxLayoutAlign="space-evenly stretch">
          <mat-form-field fxFlex="100">
            <input matInput placeholder="{{ translate.FIELDS.EMAIL }}" formControlName="email" [errorStateMatcher]="matchers.email" (blur)="searchUser($event)">
            <mat-error *ngIf="memberForm.controls.email.hasError('email')">
              {{ translate.VALIDATIONS.EMAIL_INVALID }}
            </mat-error>
          </mat-form-field>
        </div>
    
        <div fxFlex="50" fxFlex.xs="100" fxLayoutAlign="space-evenly stretch" class="pl-16"></div>
      </div>

      <div fxLayout="column" fxLayoutAlign="start start" class="user mb-24" *ngIf="user !== null">
        <div fxLayout="row" fxLayoutAlign="start center" class="small-font">
          <img src="{{ (user.avatar !== null) ? user.avatar : null | placeholderImage:'user' }}" width="80" class="avatar corner-radius" />
          {{ user.fullname }}
        </div>
        <span class="user-exist mt-16">Usuário encontrado em nossa base de dados.</span>
      </div>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="stretch stretch" *ngIf="user === null">
        <div fxFlex="50" fxFlex.xs="100" fxLayoutAlign="space-evenly stretch">
          <mat-form-field fxFlex="100">
            <input matInput placeholder="{{ translate.FIELDS.NAME }}" formControlName="firstname" [errorStateMatcher]="matchers.email" required>
            <mat-error *ngIf="memberForm.controls.firstname.hasError('required')">
              {{ translate.VALIDATIONS.NAME_REQUIRED }}
            </mat-error>
          </mat-form-field>
        </div>
      
        <div fxFlex="50" fxFlex.xs="100" fxLayoutAlign="space-evenly stretch" class="pl-16">
          <mat-form-field fxFlex="100">
            <input matInput placeholder="{{ translate.FIELDS.LASTNAME }}" formControlName="lastname" [errorStateMatcher]="matchers.email" required>
            <mat-error *ngIf="memberForm.controls.lastname.hasError('required')">
              {{ translate.VALIDATIONS.LASTNAME_REQUIRED }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="stretch stretch">
        <div fxFlex="50" fxFlex.xs="100" fxLayoutAlign="space-evenly stretch">
          <mat-form-field fxFlex="100">
            <mat-select placeholder="{{ translate.FIELDS.TSHIRT }}" formControlName="tShirt">
              <mat-option *ngFor="let size of tshirtSize" [value]="size">
                {{ size }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      
        <div fxFlex="50" fxFlex.xs="100" fxLayoutAlign="space-evenly stretch" class="pl-16">
          <mat-form-field fxFlex="100">
            <input matInput placeholder="{{ translate.FIELDS.BOX }}" formControlName="box" [matAutocomplete]="autoBox"
              (ngModelChange)="searchBox($event)" uppercase>
            <mat-autocomplete #autoBox="matAutocomplete" [displayWith]="displayFnBox">
              <mat-option *ngFor="let box of boxes" [value]="box">
                {{ box.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </form>

    <div class="mt-16 mb-8 text-red" *ngIf="requestErrors !== null">
      {{ requestErrors }}
    </div>
    
    <div class="mb-0 mt-8" fxLayoutAlign="end center">
      <btn [btnLoading]="true" type="grayscale" (click)="dialogRef.close({ created: false })" class="mr-16">{{ translate.CANCEL | uppercase }}</btn>
      <btn [btnLoading]="true" [loading]="isLoading" (click)="saveNewMember()">{{ translate.SAVE | uppercase }}</btn>
    </div>
  </div>
</div>