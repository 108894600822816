import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HeatCompetitor } from '../../model/heat-competitors';

@Injectable({
  providedIn: 'root'
})
export class HeatCompetitorsHttpService implements OnDestroy  {
  private subscriptions = new Subscription();
  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) { }

  private _heatCompetitorsObservable = new Subject<HeatCompetitor[]>();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getHeatCompetitorObservable() {
    return this._heatCompetitorsObservable.asObservable();
  }
  
  // get
  public fetchHeatCompetitorObservable(competitorId: number): void {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'public',
      'heats-competitors',
      competitorId
    ];
    const url = this.router.createUrlTree(baseUrl);
    const parsedUrl = url.toString().substring(1, url.toString().length);

    const subscription = this.httpClient
      .get(parsedUrl, { responseType })
      .subscribe((heatCompetitors: HeatCompetitor[]) => {
          this._heatCompetitorsObservable.next(heatCompetitors);
        },
        () => {
          this._heatCompetitorsObservable.next([]);
        });

    this.subscriptions.add(subscription);
  }

  getHeatCompetitorByHeatId(heatId: number): Observable<HeatCompetitor[]> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'public',
      'heat-competitors',
      'heat',
      heatId
    ];

    const url = this.router.createUrlTree(baseUrl);

    const parsedUrl = url.toString().substring(1, url.toString().length);
    return this.httpClient.get(parsedUrl, { responseType })
      .map((response: HeatCompetitor[]) => {
        return response;
      });
  }

}
