export const locale = {
  lang: 'en-US',
  data: {
    PARTICIPANTS: {
      WD: 'WD',
      CUT: 'CUT',
      TITLE: 'Participantes',
      TOOLTIPS: {
        CHECKIN: 'Fazer check-in',
        REGISTERED: 'Registrado',
        NO_SHOW: 'No show',
        FILE: 'Termo'
      },
      TABS: {
        ALL: 'Todos',
        SINGLE: 'Individuais',
        TEAM: 'Times',
        JUDGE: 'Juizes',
        INACTIVE: 'Inativos'
      },
      FILTER: {
        CANCEL: 'Cancelar',
        SUBMIT: 'Filtrar',
        FILTER: 'Filtros'
      },
      SEARCH: 'Procure pelo nome ou número do atleta',
      EXPORT: 'Exportar',
      TABLE_HEADER: {
        NUMBER: 'Nº',
        NAME: 'Nome',
        EMAIL: 'E-mail',
        DATE: 'Data de inscrição',
        TEAM: 'Time',
        GENDER: 'Gênero',
        BOX: 'Box',
        CATEGORY: 'Categoria',
        CAPITAIN: 'Capitão'
      },
      CATEGORY: 'Categoria',
      SUB_DIVISION: 'Sub-categorias',
      ALL: 'Todas',
      EMPTY: 'Não existe usuários cadastrados para essa categoria.',
      DATE_FORMAT: 'dd/MM/yyyy',
      DIALOG: {
        TITLE: 'Status do check-in',
        REGISTERED:
          'Tem certeza que deseja marcar registrado para <strong>{{ name }}</strong>?',
        CHECKIN:
          'Tem certeza que deseja realizar o check-in para <strong>{{ name }}</strong>?',
        NO_SHOW:
          'Tem certeza que deseja marcar no show para <strong>{{ name }}</strong>?',
        CHECKIN_ONLINE:
          'Tem certeza que deseja marcar check-in online para <strong>{{ name }}</strong>?',
        CANCEL: 'CANCELAR',
        CONFIRM: 'CONFIRMAR'
      },
      TOASTR: {
        SUCCESS_CHECKIN: {
          NO_SHOW: 'No show marcado com sucesso!',
          REGISTERED: 'Registrado marcado com sucesso!',
          CHECKIN: 'Check-in realizado com sucesso!',
          CHECKIN_ONLINE: 'Check-in online realizado com sucesso!'
        },
        ERROR_CHECKIN: {
          TITLE: 'Falha ao executar esta operação!'
        },
        SUCCESS_NEW_SINGLE: {
          TITLE: 'Participante cadastrado com sucesso!',
          DESCRIPTION: 'Participante cadastrado, já está incluido no sistema.'
        },
        SUCCESS_NEW_TEAM: {
          TITLE: 'Time cadastrado com sucesso!',
          DESCRIPTION:
            'Time e membros cadastrados, já está incluido no sistema.'
        }
      }
    }
  }
};
