export function GetCreditCardType(creditCardNumber): string {
  // start without knowing the credit card type
  var result = "unknown";

  // Amex: /^3[47][0-9]{13}/
  // Diners Club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/
  // Visa: /^4[0-9]{12}(?:[0-9]{3})/
  // Mastercard: /^5[1-5][0-9]{14}/
  // Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/
  // Hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/
  // Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/
  // JCB: /^(?:2131|1800|35\d{3})\d{11}/

  // first check for MasterCard
  if (/^5[1-5][0-9]{14}/.test(creditCardNumber)) {
    result = "mastercard";
  }
  // then check for AmEx
  else if (/^3[47][0-9]{13}/.test(creditCardNumber)) {
    result = "amex";
  }
  // then check for Diners
  else if (/^3(?:0[0-5]|[68][0-9])[0-9]{11}/.test(creditCardNumber)) {
    result = "diners"
  }
  // then check for Discover
  else if (/^6(?:011|5[0-9]{2})[0-9]{12}/.test(creditCardNumber)) {
    result = "discover";
  }
  // then check for Elo
  else if (/^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/.test(creditCardNumber)) {
    result = "elo";
  }
  // then check for Hipercard
  else if (/^(606282\d{10}(\d{3})?)|(3841\d{15})/.test(creditCardNumber)) {
    result = "hipercard";
  }
  // then check for JBC
  else if (/^(?:2131|1800|35\d{3})\d{11}/.test(creditCardNumber)) {
    result = "jbc";
  }
  // then check for Aura
  else if (/^50[0-9]{6,}/.test(creditCardNumber)) {
    result = "aura";
  }
  // then check for Visa
  else if (/^4[0-9]{12}(?:[0-9]{3})/.test(creditCardNumber)) {
    result = "visa";
  }

  return result;
}

export function GetCardMaskType(cardType): string {
  var masks = {
    'mastercard': '0000 0000 0000 0000 999',
    'visa': '0000 0000 0000 0000 999',
    'amex': '0000 000000 00000',
    'diners': '0000 0000 0000 00',
    'discover': '0000 0000 0000 0000',
    'elo': '0000 0000 0000 0000',
    'hipercard': '0000 0000 0000 0000',
    'jbc': '0000 0000 0000 0000 999',
    'aura': '0000 0000 0000 0000 999',
    'unknown': '0000 0000 0000 0000 999',
  };

  return masks[cardType];
}

// takes the form field value and returns true on valid number
export function ValidCreditCard(value) {
  // accept only digits, dashes or spaces
  if (/[^0-9-\s]+/.test(value)) return false;

  // The Luhn Algorithm. It's so pretty.
  var nCheck = 0, nDigit = 0, bEven = false;
  value = value.replace(/\D/g, "");

  for (var n = value.length - 1; n >= 0; n--) {
    var cDigit = value.charAt(n),
      nDigit = parseInt(cDigit, 10);

    if (bEven) {
      if ((nDigit *= 2) > 9) nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return (nCheck % 10) == 0;
}
