<div class="page-layout blank pb-64" id="scrollBar">
  <div class="p-32">
    <div class="content">
      <div class="card auto-width mb-32" *ngIf="isLoading">
        <div class="pt-16" fxLayout="column">
          <div fxLayoutAlign="center center" class="p-64">
            <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
          </div>
        </div>
      </div>
      <div class="card auto-width mb-32 pt-8 pb-32" [ngClass.gt-sm]="'mb-0'" *ngIf="!isLoading">
        <div fxLayout="column" fxLayoutAlign="center center" class="p-32 emtpy-content" *ngIf="competitor">
          <div fxLayout="column" fxLayoutAlign="center center" class="p-32">
            <img style="width: 100px" src="./assets/icons/placeholder/ic-checkin.svg" />
            <span *ngIf="competitor.checkin_status === competitorCheckin.REGISTERED" class="mt-16">
              {{ 
                !canCheckin ? 
                ('USER_CHECKIN.MESSAGE_DATE' | translate: { dateStartCheckin: dateStartCheckin, timeStartCheckin: timeStartCheckin, dateEndCheckin: dateEndCheckin, timeEndCheckin: timeEndCheckin }) : 
                ('USER_CHECKIN.MESSAGE_DATE_CAN' | translate: { dateEndCheckin: dateEndCheckin, timeEndCheckin: timeEndCheckin })
              }}
            </span>
            <span *ngIf="competitor.checkin_status === competitorCheckin.NO_SHOW" class="mt-16">
              {{ 'USER_CHECKIN.MESSAGE_NO_SHOW' | translate }}
            </span>
            <span *ngIf="competitor.checkin_status === competitorCheckin.NO_SHOW_ONLINE" class="mt-16">
              {{ 'USER_CHECKIN.MESSAGE_NO_SHOW_ONLINE' | translate }}
            </span>
            <span *ngIf="competitor.checkin_status === competitorCheckin.CHECKIN_ONLINE || competitor.checkin_status === competitorCheckin.CHECKIN" class="mt-16">
              {{ 'USER_CHECKIN.CHECKIN_SUCCESS' | translate }}
            </span>
            <span *ngIf="competitor.checkin_status === competitorCheckin.WITHDRAW" class="mt-16">
              {{ 'USER_CHECKIN.WITHDRAW_SUCCESS' | translate }}
            </span>
          </div>

          <mat-grid-list [cols]="colsGrid" cols.xs="1" rowHeight="232px" (window:resize)="onResize($event)" *ngIf="competitor.team.members && enableCheckinFile">
            <mat-grid-tile *ngFor="let member of competitor.team.members;let i = index">
              <div  class="box-line ml-8 mr-8 mb-16 team-member">
                <div class="band"></div>
                <div class="box-avatar">
                  <img src="{{ (member.user.avatar) ? member.user.avatar : './assets/images/avatars/profile.jpg' }}" class="avatar" />
                  <button class="capitain" [ngClass]="{'active': member.capitan}" [ngStyle]="{'cursor': 'default'}">C</button>
                </div>
                <div fxLayout="column" fxLayoutAlign="space-evenly center" class="information p-4" >
                  <div fxLayout="column" fxLayoutAlign="center center">
                    <span class="name">{{member.leaderboard_name}}</span>
                    <span class="email">{{member.user.email}}</span>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="member.checkin_file === null && canCheckin">
                    <btn *ngIf="getIsLoadingUpload(member.id)" [btnLoading]="true" [loading]="true" type="grayscale" materialIcon="delete_outline" iconAlign="left" onlyIcon="true"></btn>
                    <label *ngIf="! getIsLoadingUpload(member.id)" fxFlex="auto" for="file-checkin-{{i}}" class="btn">{{ 'USER_CHECKIN.FORM.FIELDS.FILE' | translate | uppercase }}</label>
                    <input type="file" name="file-checkin-{{i}}" id="file-checkin-{{i}}" class="inputfile" (change)="readUrl($event, member)" accept=".pdf,.jpg,.jpeg,.png" />
                  </div>
                  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="member.checkin_file !== null">
                    <btn *ngIf="getIsLoadingUpload(member.id)" [btnLoading]="true" [loading]="true" type="grayscale" materialIcon="delete_outline" iconAlign="left" onlyIcon="true"></btn>
                    <a class="mr-8" *ngIf="!getIsLoadingUpload(member.id)" href="{{ member.checkin_file }}" download target="_blank">
                      <mat-icon matTooltip="{{ 'USER_CHECKIN.FILE' | translate}}" matTooltipPosition="below" matTooltipClass="below">attach_file</mat-icon>
                    </a>
                    <a (click)="removeFile(member)" *ngIf="competitor.checkin_status === competitorCheckin.REGISTERED && ! getIsLoadingUpload(member.id)">
                      <mat-icon matTooltip="{{ 'USER_CHECKIN.FILE' | translate}}" matTooltipPosition="below" matTooltipClass="below">delete_outline</mat-icon>
                    </a>
                    <a *ngIf="canCheckin && competitor.checkin_status === competitorCheckin.CHECKIN_ONLINE && !getIsLoadingUpload(member.id)">
                      <input style="display: none;" type="file" name="file-checkin-{{i}}" id="file-checkin-{{i}}" class="inputfile" (change)="readUrl($event, member, true)" accept=".pdf,.jpg,.jpeg,.png" />
                      <label style="cursor: pointer;" *ngIf="! getIsLoadingUpload(member.id)" fxFlex="auto" for="file-checkin-{{i}}">
                        <mat-icon matTooltip="{{ 'USER_CHECKIN.FORM.FIELDS.UPDATE' | translate}}" matTooltipPosition="below" matTooltipClass="below">create</mat-icon>
                      </label>
                    </a>
                  </div>
                </div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>

          <div 
            *ngIf="competitor.checkin_status === competitorCheckin.REGISTERED"
            fxLayout="row wrap"
            fxLayoutAlign="space-between start"
            fxLayoutAlign.sm="center center"
            fxLayoutAlign.xs="center center"
            fxLayout.xs="column"
            fxLayout.sm="column"
          >
            <btn [buttonHeight]="colsGrid === 1 ? 'auto' : '35px'" [btnLoading]="false" [loading]="isLoadingBtn" (click)="checkin()" [buttonDisabled]="!canCheckin || !canCheckinFile">
              {{ 'USER_CHECKIN.CHECKIN_ONLINE' | translate | uppercase }}
            </btn>
            <btn [buttonHeight]="colsGrid === 1 ? 'auto' : '35px'" [ngClass]="{'ml-8': colsGrid === 4, 'mt-8': colsGrid === 1}" ngClass.xs="ml-0 mt-8" ngClass.sm="ml-0 mt-8" backgroundColor="#ff0000" (click)="canCheckin ? checkinWithdraw() : ''" [loading]="false" [buttonDisabled]="!canCheckin">
              {{ 'USER_CHECKIN.WITHDRAW' | translate | uppercase }}
            </btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
