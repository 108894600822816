<div class="page-layout blank pb-64">
  <div class="p-32">
    <div class="content">
      <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
        <div class="p-32 pt-16">
          <div fxLayout="row" fxLayoutAlign="start center">
            <h3>{{ eventName }}</h3>
            <label-x class="ml-8" type="{{ (event.published) ? 'success' : 'warning'}}">
              {{ (event.published) ? ('SETTINGS.EVENT_STATUS.PUBLISHED' | translate | uppercase) : ('SETTINGS.EVENT_STATUS.NOT_PUBLISHED' | translate | uppercase) }}
            </label-x>
          </div>
          <form name="eventForm" [formGroup]="eventForm" autocomplete="off" novalidate>
            <div fxLayout="column" fxLayoutAlign="stretch stretch">
              <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start" fxLayoutAlign.xs="start stretch">
                <!-- FIRST COLUMN -->
                <div fxFlex="50" fxFlex.xs="auto" fxLayout="column" fxLayoutAlign="space-evenly stretch" fxLayoutAlign.xs="start stretch">
                  <mat-form-field>
                    <input #nameEventInput matInput placeholder="{{ 'SETTINGS.FIELDS.NAME' | translate }}" formControlName="name" [errorStateMatcher]="eventMatchers.name" uppercase required focus>
                    <mat-error *ngIf="eventForm.controls.name.hasError('required')">
                      {{ 'SETTINGS.VALIDATIONS.NAME_REQUIRED' | translate }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field>
                    <input
                      matInput
                      [matAutocomplete]="autoAddress" autocomplete="off"
                      placeholder="{{ 'SETTINGS.FIELDS.ADDRESS' | translate }}" formControlName="address" [errorStateMatcher]="eventMatchers.address" required>

                    <mat-autocomplete autoActiveFirstOption #autoAddress="matAutocomplete" xPosition="before">
                      <mat-option *ngFor="let city of cities" [value]="city.description" (onSelectionChange)="addressSelected(city)">
                        {{ city.description }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="eventForm.controls.address.hasError('required')">
                      {{ 'SETTINGS.VALIDATIONS.ADDRESS_REQUIRED' | translate}}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="mb-8">
                    <input matInput placeholder="{{ 'SETTINGS.FIELDS.LOCATION' | translate }}" formControlName="location" [errorStateMatcher]="eventMatchers.location" required>
                    <mat-error *ngIf="eventForm.controls.location.hasError('required')">
                      {{ 'SETTINGS.VALIDATIONS.LOCATION_REQUIRED' | translate}}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="mb-8">
                    <input [readonly]="!userMe.admin" matInput [matAutocomplete]="timezone" placeholder="{{ 'SETTINGS.FIELDS.TIMEZONE' | translate }}" formControlName="timezone" [errorStateMatcher]="eventMatchers.timezone" required>
                    <mat-autocomplete  #timezone="matAutocomplete" [displayWith]="displayFnTimezone">
                      <mat-option *ngFor="let timezone of filterdTimezoneData | async" [value]="timezone">
                        {{ timezone.text }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="eventForm.controls.timezone.hasError('required') || timezoneRequired">
                      {{ 'SETTINGS.VALIDATIONS.TIMEZONE_REQUIRED' | translate}}
                    </mat-error>
                  </mat-form-field>

                  <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" class="mb-8">
                    <mat-form-field fxFlex="65" class="inputDate">
                      <input [readonly]="!userMe.admin" matInput placeholder="{{'SETTINGS.FIELDS.DATE_START' | translate}}" formControlName="dateStart" [errorStateMatcher]="eventMatchers.dateStart" [dropSpecialCharacters]="false"
                             mask="00/00/0000" required>
                      <!-- (ngModelChange)="verifyDate($event)" -->
                      <mat-icon matSuffix>date_range</mat-icon>
                      <mat-error *ngIf="eventForm.controls.dateStart.hasError('required')">
                        {{'SETTINGS.VALIDATIONS.DATE_START_REQUIRED' | translate}}
                      </mat-error>
                      <mat-error *ngIf="!eventForm.controls.dateStart.hasError('required') && eventForm.controls.dateStart.hasError('invalid')">
                        {{'SETTINGS.VALIDATIONS.DATE_INVALID' | translate}}
                      </mat-error>
                    </mat-form-field>

                    <select-time
                      #pickerTimeStart
                      [readonly]="!userMe.admin"
                      fxFlex="35"
                      class="pl-12 hour"
                      label="{{'SETTINGS.FIELDS.TIME_START' | translate }}"
                      [value]="timeStart"
                      (inputValue)="startTimeChange($event)"
                      [required]="true"></select-time>
                  </div>

                  <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center">
                    <mat-form-field fxFlex="65" class="inputDate">
                      <input [readonly]="!userMe.admin" matInput placeholder="{{'SETTINGS.FIELDS.DATE_END' | translate}}" formControlName="dateEnd" [errorStateMatcher]="eventMatchers.dateEnd" [dropSpecialCharacters]="false"
                             mask="00/00/0000" required>
                      <!-- (ngModelChange)="verifyDate($event)" -->
                      <mat-icon matSuffix>date_range</mat-icon>
                      <mat-error *ngIf="eventForm.controls.dateEnd.hasError('required')">
                        {{'SETTINGS.VALIDATIONS.DATE_END_REQUIRED' | translate}}
                      </mat-error>
                      <mat-error *ngIf="!eventForm.controls.dateEnd.hasError('required') && eventForm.controls.dateEnd.hasError('invalid')">
                        {{'SETTINGS.VALIDATIONS.DATE_INVALID' | translate}}
                      </mat-error>
                    </mat-form-field>
                    <select-time
                      [readonly]="!userMe.admin"
                      #pickerTimeEnd
                      fxFlex="35"
                      class="pl-12 hour"
                      label="{{'SETTINGS.FIELDS.TIME_END' | translate }}"
                      [value]="timeEnd"
                      (inputValue)="endTimeChange($event)"
                      [required]="true"></select-time>
                  </div>
                </div>

                <!-- SECOND COLUMN -->
                <div fxFlex="50" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="space-around start">
                  <agm-map
                    fxFlex="100" ngClass="ml-16 gmaps" ngClass.xs="ml-0 gmaps"
                    [latitude]="lat" [longitude]="lng"
                    [zoom]="15" [streetViewControl]="false" [scrollwheel]="false">
                    <agm-marker [latitude]="lat" [longitude]="lng" *ngIf="isShowMarker"></agm-marker>
                  </agm-map>
                </div>
              </div>
              

              <h3>{{ 'SETTINGS.EVENT_PAGE' | translate }}</h3>
              <div fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.xs="start stretch">
                <mat-form-field fxFlex="50" fxFlex.xs="100">
                  <input matInput placeholder="{{ 'SETTINGS.FIELDS.EVENT_URL' | translate }}" formControlName="urlEvent" [errorStateMatcher]="eventMatchers.urlEvent" required>
                  <span matSuffix class="url-suffix">.crossx.com.br</span>
                  <mat-error *ngIf="eventForm.controls.urlEvent.hasError('required')">
                    {{ 'SETTINGS.VALIDATIONS.URL_EVENT_REQUIRED' | translate}}
                  </mat-error>
                  <mat-error *ngIf="!eventForm.controls.urlEvent.hasError('required') && eventForm.controls.urlEvent.hasError('minlength')">
                    {{ 'SETTINGS.VALIDATIONS.URL_EVENT_MINLENGTH' | translate:{ count: eventForm.controls.urlEvent.errors.minlength.requiredLength } }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="images-select">
                <div fxLayout="column" fxFlex="50">
                  <h4>{{ 'SETTINGS.IMAGES.BRAND' | translate }}</h4>
                  <div fxLayout="row" fxLayoutAlign="start start" class="wrap">
                    <div fxFlex="126px" class="mr-8 image-box" fxLayoutAlign="center center">
                      <img [src]="brandUrl" class="image" *ngIf="(brandUrl !== ''); else placeholderBrand">
                      <ng-template #placeholderBrand><img src="./assets/icons/placeholder/ic-picture.svg" class="mt-32 mb-32"></ng-template>
                    </div>
                    <div fxFlex="auto" class="pr-16">
                      <span innerHTML="{{ 'SETTINGS.IMAGES.DESCRIPTION_BRAND' | translate }}"></span><br>
                      <input type="file" name="file" id="file" class="inputfile" (change)="readUrl($event, 'brand')" accept=".jpg, .jpeg, .png" />
                      <label class="select-btn" for="file" class="btn mt-8">{{ 'SETTINGS.IMAGES.SELECT_BRAND' | translate }}</label>
                    </div>
                  </div>
                </div>

                <div fxLayout="column" fxFlex="50" >
                  <h4>{{ 'SETTINGS.IMAGES.HEADER' | translate }}</h4>
                  <div fxLayout="row" fxLayoutAlign="start start" class="wrap">
                    <div fxFlex="182px" class="mr-8 image-box" fxLayoutAlign="center center">
                      <img [src]="headerUrl" class="image" *ngIf="(headerUrl !== ''); else placeholderHeader">
                      <ng-template #placeholderHeader>
                        <img src="./assets/icons/placeholder/ic-picture.svg" class="mt-8 mb-8">
                      </ng-template>
                    </div>
                    <div fxFlex="auto" fxLayout="column">
                      <span innerHTML="{{ 'SETTINGS.IMAGES.DESCRIPTION_HEADER' | translate }}"></span>
                      <input type="file" name="file-header" id="file-header" class="inputfile" (change)="readUrl($event, 'header')" accept=".jpg, .jpeg, .png"  />
                      <label class="select-btn" for="file-header" class="btn mt-8">{{ 'SETTINGS.IMAGES.SELECT_IMAGE' | translate }}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div fxLayout="column" fxLayoutAlign="start start" class="mt-32 mb-32">
                <h4>{{ 'SETTINGS.FIELDS.DESCRIPTION' | translate }}</h4>
                <quill #quill
                  [config]="configEditor"
                  (valueChange)="onValueChange($event)">
                  <div quillToolbar></div><!-- Optional custom toolbar -->
                  <div quillContent></div><!-- Optional pre-filled content -->
                </quill>
              </div>

              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
                <div fxFlex="50" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center" class="pr-16">
                  <mat-form-field fxFlex="auto" class="pr-16" *ngIf="event.doc_rule === null">
                    <input matInput placeholder="{{ 'SETTINGS.FIELDS.REGULATION' | translate }}" formControlName="regulation" [errorStateMatcher]="eventMatchers.regulation" [value]="regulationFileName">
                    <mat-error *ngIf="eventForm.controls.regulation.hasError('required')">
                      {{ 'SETTINGS.VALIDATIONS.REGULATION_REQUIRED' | translate}}
                    </mat-error>
                  </mat-form-field>
                  <a href="{{ event.doc_rule }}" target="_blank" fxFlex="60" *ngIf="event.doc_rule">{{ 'SETTINGS.FIELDS.SEE_REGULATION' | translate }}</a>
                  <input type="file" name="file-regulation" id="file-regulation" class="inputfile" (change)="readUrl($event, 'regulation')" accept=".pdf" />
                  <label *ngIf="!event.doc_rule" fxFlex="auto" for="file-regulation" class="btn mt-8">{{ 'SETTINGS.SELECT' | translate }}</label>
                  <label *ngIf="event.doc_rule" fxFlex="auto" class="btn mt-8" (click)="removeDoc('regulation', event)">{{ 'SETTINGS.REMOVE' | translate }}</label>
                </div>

                <div fxFlex="50" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around">
                  <mat-form-field fxFlex="auto" class="pr-16" *ngIf="event.doc_term === null">
                    <input matInput placeholder="{{ 'SETTINGS.FIELDS.TERMS' | translate }}" formControlName="terms" [errorStateMatcher]="eventMatchers.terms" [value]="termsFileName" disabled>
                    <mat-error *ngIf="eventForm.controls.terms.hasError('required')">
                      {{ 'SETTINGS.VALIDATIONS.TERMS_REQUIRED' | translate}}
                    </mat-error>
                  </mat-form-field>

                  <a href="{{ event.doc_term }}" target="_blank" fxFlex="60" *ngIf="event.doc_term">{{ 'SETTINGS.FIELDS.SEE_TERMS' |
                    translate }}</a>
                  <input type="file" name="file-terms" id="file-terms" class="inputfile" (change)="readUrl($event, 'terms')" accept=".pdf" />
                  <label *ngIf="!event.doc_term" fxFlex="auto" for="file-terms" class="btn mt-8">{{ 'SETTINGS.SELECT' | translate }}</label>
                  <label *ngIf="event.doc_term" fxFlex="auto" class="btn mt-8" (click)="removeDoc('terms', event)">{{ 'SETTINGS.REMOVE' | translate }}</label>
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="end center" class="mt-32">
                <btn [btnLoading]="true" [loading]="isPublishLoading" type="grayscale" [buttonDisabled]="eventForm.invalid && !event.published" (click)="publishOrUnpublishEvent()">
                  {{ (event.published ? 'SETTINGS.UNPUBLISH_EVENT' : 'SETTINGS.PUBLISH_EVENT') | translate | uppercase }}
                </btn>
                <btn [btnLoading]="true" [loading]="isLoading" [buttonDisabled]="eventForm.invalid" (click)="updateEventDetail()" class="ml-16">{{ 'SETTINGS.SAVE' | translate }}</btn>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>
