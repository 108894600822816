export const locale = {
  lang: 'pt-BR',
  data: {
    'MODAL_COMPETITOR': {
      'HEAT' : {
        'HEAT': 'Bateria',
        'EMPTY': 'Nenhuma bateria postada'
      },
      'LANE': 'Lane',
      'TABS': {
        'SCHEDULE': 'Baterias',
        'RANK': 'Rank',
        'TEAM_MATES': 'Membros'
      },
      'RANK': {
        'POSITION': 'Posição',
        'RANK': 'Rank',
        'POINTS': 'Pontos',
        'TOTAL_POINTS': 'Total de pontos',
        'EMPTY': 'Nenhum resultado postado',
      },
      'DATE_FORMAT': 'dd/MM',
    }
  }
};
