import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BanksService {

  constructor(
    private http: HttpClient
  ) { }

  search(term: string): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.get(`admin/banks?search=${term}`, { responseType })
      .map((response: Response) => {
        return response
      })
  }
}
