export class CustomFieldType {
  id: number;
  type: string;
  name: string;
  hasOptions?: boolean;
  showType?: boolean = false;
}

export enum CustomFieldTypes {
  EMAIL = 'email',
  BOX = 'box',
  PHONE = 'phone',
  CPF = 'cpf',
  SINGLE_CHECKBOX = 'single_checkbox',
  MULTIPLE_CHECKBOX = 'multiple_checkbox',
  SELECT = 'select',
  SINGLE_LINE_TEXT = 'single_line_text',
  MULTIPLE_LINE_TEXT = 'multiple_line_text',
  NUMBER = 'number_text',
  DATE_PICKER = 'date_picker',
  DATETIME_PICKER = 'datetime_picker',
  AUTOCOMPLETE = 'autocomplete',
  ADDRESS = 'address',
  COUNTRY = 'country',
}
