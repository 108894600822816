export const locale = {
  lang: 'en-US',
  data: {
    'RESULTS': {
      'TITLE': 'Resultados',
      'DESCRIPTION': 'Adicione, edite ou delete o resultado dos atletas',
      'CATEGORY': 'Categoria',
      'COMMENT': 'Inserir comentário',
      'FORM': {
        'ALL': 'Todas',
        'SUB_DIVISION': 'Sub-categoria'
      },
      'PUBLISH': {
        'TITLE': 'PUBLICAR',
        'TITLE_DIALOG': 'Publicar resultados',
        'DESCRIPTION_DIALOG': 'Tem certeza que deseja publicar os resultados?',
        'DIALOG_CANCEL': 'CANCELAR',
        'TOASTR_TITLE': 'Resultados publicados',
        'TOASTR_DESCRIPTION': 'Resultados publicados',
        'TOASTR_TITLE_FAIL': 'Failed',
        'TOASTR_DESCRIPTION_FAIL': 'Results werent published, try again later.',
      },
      'SEARCH': 'Procure pelo nome do atleta',
      'EXPORT': 'Exportar',
      'TABLE': {
        'NUMBER': 'Nº',
        'NAME': 'Nome',
        'RESULT': 'Resultado',
        'TIEBREAK': 'Tiebreak',
        'VIDEO': 'Vídeo',
        'STATUS': 'Status'
      },
      'TIMECAP': 'TimeCap + Reps',
      'TIMECAP_ERROR': 'O número de Reps não pode ser maior que o configurado no Wod',
      'UNITS': {
        'TIME': 'Tempo',
        'REPS': 'Repetições',
        'time': 'Tempo',
        'Repetitions': 'Repetições',
        'Calories': 'Calorias',
        'Pounds': 'Libras',
        'Kiligrams': 'Kilograma',
        'Meters': 'Metros'
      },
      'STATUS': {
        'APPROVED': 'Aprovado',
        'REPROVE': 'Reprovado',
        'WAITING': 'AGUARDANDO',
      },
      'SAVE': 'SALVAR',
      'EMPTY': 'Não existe usuários cadastrados para essa categoria.',
      'EMPTY_WOD': 'Não existe wod cadastrado para essa categoria.',
      'TOASTR': {
        'ERROR': {
          'TITLE': 'Erro ao salvar resultado'
        },
        'ERROR_WITHOUT_RESULT': {
          'TITLE': 'Para acessar o vídeo deve existir um resultado postado.',
          'DESCRIPTION': ''
        }
      },
      'DISQUALIFIED' : {
        'CUT': 'cut',
        'WD': 'disqualify',
        'TYPE_CUT': 'CUT',
        'TYPE_WD': 'WD',
        'REMOVE': 'cancel',
        'SUCCESS': 'Atualizado com sucesso! Para postar acesse resultados e publique.',
        'MESSAGE': 'Tem certeza que deseja {{ custom_field_name }} {{ custom_field_type }} este competidor?',
        'TITLE': 'Tem certeza?',
        'CANCEL': 'Cancel',
        'CONFIRM': 'Confirm'
      },
      'TABS': {
        'ALL': 'Todos',
        'NO_RESULTS': 'Faltantes',
        'PUBLISHED': 'Publicados',
        'NOT_PUBLISHED': 'Não publicados',
      },
    }
  }
};
