export const locale = {
  lang: 'pt-BR',
  data: {
    'WORKOUT': {
      'CHAMPIONSHIP': 'Etapas',
      'RESULT_TYPE': 'Resultado',
      'TIEBREAK_TYPE': 'Tiebreak',
      'TIME_CAP': 'Time Cap',
      'WOD': {
        'EMPTY': 'Não existe WOD publicado'
      }
    }
  }
};
