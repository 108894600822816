import { EventGuard } from './../../../../../core/guards/event.guard';
import { EventsService } from './../../../../../core/services/event/events.service';
import { UserService } from './../../../../../core/services/user/user.service';
import { Observable } from 'rxjs/Observable';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';


import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { FormGroup } from '../../../../../../../node_modules/@angular/forms';
import { GooglePlacesService } from '../../../../../core/services/google-places.service';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { startWith, map } from 'rxjs/operators';
import { Utils } from '../../../../../core/utils';
import * as momentTimezone from 'moment-timezone';
import { NgxMaskModule } from 'ngx-mask';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit {

  private subscriptions: Subscription = new Subscription();
  
  translate: any

  //Event Form
  eventForm: FormGroup
  eventFormErrors: any
  users: any[] = []
  userId: number
  cities = []
  markers = []
  placeId: string = ''
  timeStart: string = "10:00"
  timeEnd: string = "10:00"
  timezoneRequired:boolean = false
  isShowGatewayOptions: boolean = true;
  isShowGatewayId: boolean = false;
  
  lat = -23.549063
  lng = -46.633460
  isShowMarker: boolean = false

  timezoneData: any[] = []
  timezoneSelected: string
  filterdTimezoneData: Observable<any[]>;

  isLoading: boolean = false
  timeOut
  
  constructor(
    public dialogRef: MatDialogRef<CreateEventComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private googlePlacesService: GooglePlacesService,
    private userService: UserService,
    private eventService: EventsService,
    private eventGuard: EventGuard
  ) {
    this.translationLoader.loadTranslations(english, portugues);

    //Event Form
    this.eventFormErrors = {
      name: {},
      user: {},
      address: {},
      location: {},
      timezone: {},
      dateStart: {},
      dateEnd: {},
      urlEvent: {},
      regulation: {},
      terms: {},
      default_gateway_id: {}
    };

  }

  ngOnInit() {
    this.translateService.get("CREATE_EVENT").subscribe((res: any) => {
      this.translate = res
    });

    // Event Form
    this.eventForm = this.formBuilder.group({
      name: ['', Validators.required],
      user: ['', Validators.required],
      address: [''],
      location: [''],
      timezone: [''],
      dateStart: [''],
      dateEnd: [''],
      leaderboard: [true],
      subscription: [true],
      defaultGatewayId: [1],
      // eventGatewayId: ['']
    });

    this.filterdTimezoneData = this.eventForm.get('timezone').valueChanges
      .pipe(
        startWith(''),
        map(timezone => timezone ? this._filterStates(timezone) : this.timezoneData.slice())
      );


    this.eventForm.valueChanges.subscribe(() => {
      this.onEventFormValuesChanged();
    });

    this.onEventFormValuesChanged()
    
    this.formValuesChanged();

    //Timezones
    this.getTimezones()
  }

  // Create Event
  createEvent() {
    if (this.eventForm.value.name.length === 0 && !this.userId) {
      return;
    }

    if (this.eventForm.value.dateStart && !this.timezoneSelected) {
      this.timezoneRequired = true
      return
    }

    if (this.eventForm.value.dateEnd && !this.timezoneSelected) {
      this.timezoneRequired = true
      return
    }
    
    let event = {
      name: this.eventForm.value.name.toUpperCase(),
      user_id: (this.eventForm.value.user.id) ? this.eventForm.value.user.id : null,
      address: (this.eventForm.value.address.description) ? this.eventForm.value.address.description : null,
      place_id: (this.eventForm.value.address.place_id) ? this.eventForm.value.address.place_id : null,//this.placeId,
      latitude: this.eventForm.value.address.place_id ? this.lat : null,
      longitude: this.eventForm.value.address.place_id ? this.lng : null,
      location: this.eventForm.value.location.toUpperCase(),
      timezone: this.timezoneSelected,
      enabled_leaderboard: this.eventForm.value.leaderboard,
      enabled_subscription: this.eventForm.value.subscription,
      default_gateway_id: 1,
      // event_gateway_id: this.eventForm.value.defaultGatewayId === 2 || this.eventForm.value.defaultGatewayId === '2' ? this.eventForm.value.eventGatewayId : null
    }

    if (this.eventForm.value.dateStart) {
      const dateTimeStart = moment(`${this.eventForm.value.dateStart} ${this.timeStart}:00`, `${this.translate.DATE_FORMAT} HH:mm:ss`).format(`YYYY-MM-DD HH:mm:ss`)
      event['datetime_start'] = dateTimeStart
    }

    if (this.eventForm.value.dateEnd) {
      const dateTimeEnd = moment(`${this.eventForm.value.dateEnd} ${this.timeEnd}:00`, `${this.translate.DATE_FORMAT} HH:mm:ss`).format('YYYY-MM-DD HH:mm:ss')
      event['datetime_end'] = dateTimeEnd
    }


    this.isLoading = true
    this.eventService.createEvent(event)
      .subscribe(
        result => {
          console.log("result", result);
          this.isLoading = false
          this.eventGuard.setEvent(result)
          this.dialogRef.close({ created: true })

        }, err => {
          console.log("err", err);
          this.isLoading = false
        }
      )

  }


  private _filterStates(value: string): any[] {
    const filterValue = Utils.removeAccents(value.toLowerCase())
    return this.timezoneData.filter(timezone => timezone.text.toLowerCase().indexOf(filterValue) !== -1)
  }

  onEventFormValuesChanged() {
    for (const field in this.eventFormErrors) {
      if (!this.eventFormErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.eventFormErrors[field] = {};

      // Get the control
      const control = this.eventForm.get(field);
      if (control && control.dirty && !control.valid) {

        control.errors["invalid"] = (!control.valid) ? true : null;
        this.eventFormErrors[field] = control.errors;
      }
    }
  }

  private formValuesChanged(): void {
    const subscription = this.eventForm
      .get('subscription')
      .valueChanges
      .subscribe((value) => {
        console.log(value)
        this.isShowGatewayOptions = value;
        if(this.isShowGatewayOptions === false) {
          this.isShowGatewayId = false;
        }
      });
    this.subscriptions.add(subscription);
    
    const subscriptionGateway = this.eventForm
      .get('defaultGatewayId')
      .valueChanges
      .subscribe((value) => {
        console.log(value)
        this.isShowGatewayId = value === 2 || value === '2'
      });
    this.subscriptions.add(subscriptionGateway);
  }

  startTimeChange(time) {
    this.timeStart = time
  }

  endTimeChange(time) {
    this.timeEnd = time
  }

  searchAddress(city: string) {
    if (city.length > 2) {
      this.googlePlacesService.search(city)
        .subscribe(
          result => {
            // console.log("result", result);
            this.cities = result
          }, err => {
            // console.log("err", err);
            this.cities = []

          }
        )
    }
  }

  addressSelected(event) {
    // console.log("event: ", event)
    this.placeId = event.place_id
    this.googlePlacesService.placeIdDetail(event.place_id)
      .subscribe(
        result => {
          // console.log(result)
          this.lat = result.geometry.location.lat()
          this.lng = result.geometry.location.lng()
          this.isShowMarker = true

        }, err => {

        }
      )
  }

  // search user
  searchUser(term: string) {
    clearTimeout(this.timeOut)

    if (term.length > 2) {
      this.timeOut = setTimeout(() => {
        this.searchUserWithTerm(term)
      }, 1000);
      
    }
    else if (term.length === 0) {
      this.users = []
      this.userId = null
    }
  }

  searchUserWithTerm(term: string) {
    clearTimeout(this.timeOut)
    this.userService.searchUserByTerm(term)
      .subscribe(
        result => {
          // console.log("result", result);
          this.users = result
        }, err => {
          // console.log("err", err);
          this.users = []

        }
      )
  }

  displayFnUser(user: any): string {
    console.log(user)
    return user ? `${user.firstname} ${user.lastname}`.toUpperCase() : user;
  }

  displayFnAddress(address: any): string {
    console.log(address)
    return address ? address.description : address;
  }

  userSelected(event) {
    // console.log("event: ", event)
    this.userId = event.id
  }

  setTimezoneSelected(event) {
    this.timezoneSelected = event.value
  }

  // Get Timezone
  getTimezones() {
    const momentNames = momentTimezone.tz.names();
    const now = momentTimezone();

    this.timezoneData = momentNames.map((name) => {
      return {
        text: `(GMT${now.tz(name).format('Z')}) ${name}`,
        value: name,
      };
    });

    // console.log(this.timezoneData)
  }

}
