export const locale = {
  lang: 'en-US',
  data: {
    'CROP': {
      'TITLE': 'Editar imagem',
      'SAVE': 'SALVAR',
      'CANCEL': 'CANCELAR'
    }
  }
};
