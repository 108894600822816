<div class="content">
  <div class="auto-width">
    <tab-bar
      [padding]="true"
      [items]="tabsFilter"
      [tabActive]="tabIndexActive"
      (tabEvent)="tabAction($event)"
    >
    </tab-bar>
  </div>
</div>
