export const locale = {
  lang: 'en-US',
  data: {
    'SUB_DIVISION': {
      'TITLE': 'Sub-categorias',
      'DESCRIPTION': 'Veja o leaderboard dessa etapa.',
      'TABLE': {
        'NAME': 'Nome',
        'DELETE': 'Deletar',
        'ADD_NEW': 'Adicionar sub-categoria'
      },
      'FORM': {
        'NAME': 'Nome',
      },
      'VALIDATIONS': {
        'NAME_REQUIRED': 'O nome é obrigatório'
      },
      'TOASTR': {
        'CREATE': {
          'SUCCESS': {
            'TITLE': 'Sub-categoria cadastrada com sucesso!',
          }
        },
        'UPDATE': {
          'SUCCESS': {
            'TITLE': 'Sub-categoria alterada com sucesso!',
          }
        },
        'CONFIGURATION': {
          'SUCCESS': {
            'TITLE': 'Configuração da sub-categoria alterada com sucesso!'
          }
        },
        'UPDATE_ORDER': {
          'ERROR': {
            'TITLE': 'Falha ao ordernar sub-categoria!',
          }
        },
        'DELETE': {
          'SUCCESS': {
            'TITLE': 'Sub-categoria deletada com sucesso!',
          },
          'ERROR': {
            'TITLE': 'Falha ao deletar sub-categoria!',
            'DESCRIPTION': 'Sub-categoria não pode ser deletada, já existe um competidor cadastrado.'
          }
        }
      },
      'DIALOG': {
        'CANCEL': 'Cancelar',
        'EXCLUIR': 'Excluir',
        'DESCRIPTION': 'Tem certeza que deseja excluir a sub-categoria <strong>{{ sub_division_name }}</strong>?<br><br>Após executada essa ação não poderá ser desfeita.',
      },
      'EMPTY': 'Nenhuma sub-categoria cadastrada.',
      'ADD': 'Adicionar',
      'CONFIGURATION': 'Configurações',
    }
  }
};
