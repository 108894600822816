import { WodService } from './../../../../../../core/services/wod/wod.service';
import { Component, OnInit } from '@angular/core';
import { Competition } from '../../../../../../core/model/competition';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { MatDialogRef, MatIconRegistry } from '@angular/material';
import { ModalCopyLotComponent } from '../modal-copy-lot/modal-copy-lot.component';
import { FuseTranslationLoaderService } from '../../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CompetitionService } from '../../../../../../core/services/competition/competition.service';
import { EventGuard } from '../../../../../../core/guards/event.guard';
import { LotService } from '../../../../../../core/services/lot/lot.service';
import { Utils } from '../../../../../../core/utils';
import { Errors } from '../../../../../../core/model/errors';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';

@Component({
  selector: 'app-modal-wod-copy',
  templateUrl: './modal-wod-copy.component.html',
  styleUrls: ['./modal-wod-copy.component.scss']
})
export class ModalWodCopyComponent implements OnInit {

  public competition: Competition
  public allCompetitions: Competition[]

  // Translate
  translate: any

  // Form
  wodForm: FormGroup;
  matchers: any
  competitionsCheckbox: any[] = []

  requestErrors: string

  isLoading: boolean = false

  constructor(
    public dialogRef: MatDialogRef<ModalWodCopyComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private formBuilder: FormBuilder,
    private competitionService: CompetitionService,
    private eventGuard: EventGuard,
    private wodService: WodService
  ) {
    this.translationLoader.loadTranslations(english, portugues);

    this.translateService.get("MODAL_COPY_WODS").subscribe((res: any) => {
      this.translate = res
    });

    // this.event = this.eventGuard.getActiveEvent().event

    //Icon Info
    iconRegistry.addSvgIcon(
      'ic-cat-info',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/category/ic-cat-info.svg')
    );
  }

  ngOnInit() {

    this.allCompetitions.forEach(competition => {
      if (competition.id !== this.competition.id) {
        this.competitionsCheckbox.push({
          id: competition.id,
          name: competition.name
        })
      }
    });

    const controlsCompetitions = this.competitionsCheckbox.map(c => new FormControl(false));
    const controlsWods = this.competition.wods.map(c => new FormControl(false));
    this.wodForm = this.formBuilder.group({
      competitions: new FormArray(controlsCompetitions, Utils.minSelectedCheckboxes(1)),
      wods: new FormArray(controlsWods, Utils.minSelectedCheckboxes(1))
    })
  }

  copyWods() {
    // validate all form fields
    if (!this.wodForm.valid) {
      Utils.formValid(this.wodForm)
      return
    }

    const selectedCompetitions = this.wodForm.value.competitions
      .map((v, i) => v ? this.competitionsCheckbox[i].id : null)
      .filter(v => v !== null);

    const selectedWods = this.wodForm.value.wods
      .map((v, i) => v ? this.competition.wods[i].id : null)
      .filter(v => v !== null);

    console.log(selectedCompetitions, selectedWods);

    const parameters = {
      competitions_ids: selectedCompetitions,
      wods_ids: selectedWods
    }

    this.isLoading = true
    this.wodService.duplicate(parameters)
      .subscribe(
        result => {
          console.log("result", result);
          this.isLoading = false
          this.dialogRef.close({ competitions: result, isEdit: false })
        }, err => {
          console.log("err", err);
          const errors: Errors = new Errors().deserialize((err.error as any))
          this.requestErrors = errors.getFullMessages()

          this.isLoading = false
        }
      )
  }

  formWods() { return <FormArray>this.wodForm.get('wods'); }
  formCompetition() { return <FormArray>this.wodForm.get('competitions'); }
}



