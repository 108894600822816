export const locale = {
  lang: 'pt-BR',
  data: {
    'CREATE_EVENT': {
      'TITLE': 'NOVO EVENTO',
      'SAVE': 'Salvar',
      'CANCEL': 'Cancelar',
      'DATE_FORMAT': 'DD/MM/YYYY',
      'TITLE_DESCRIPTION': 'Informações do evento',
      'DESCRIPTION': 'Crie o eventos com as informações iniciais dele.',
      'FIELDS': {
        'NAME': 'Nome do evento',
        'USER': 'Usuário responsável',
        'ADDRESS': 'Endereço',
        'LOCATION': 'Local',
        'TIMEZONE': 'Fuso horário do local',
        'DATE_START': 'Data inicial',
        'DATE_END': 'Data final',
        'TIME_START': 'Horário de início',
        'TIME_END': 'Horário de término',
        'EVENT_URL': 'URL do evento',
        'DESCRIPTION': 'Descrição',
        'REGULATION': 'Regulamento',
        'TERMS': 'Termos',
        'LEADERBOARD': 'Leaderboard',
        'SUBSCRIPTION': 'Inscrições',
        'GATEWAY_ID': 'ID da Zoop'
      },
      'VALIDATIONS': {
        'NAME_REQUIRED': 'Nome do evento é obrigatório',
        'USER_REQUIRED': 'Usuário é obrigatório',
        'ADDRESS_REQUIRED': 'Endereço do evento é obrigatório',
        'LOCATION_REQUIRED': 'Local do evento é obrigatório',
        'TIMEZONE_REQUIRED': 'O fuso horário do evento é obrigatório',
        'DATE_INVALID': 'O fuso horário do evento é obrigatório',
        'DATE_START_REQUIRED': 'Obrigatório',
        'URL_EVENT_REQUIRED': 'A url do evento é obrigatório',
        'DESCRIPTION_REQUIRED': 'A descrição é obrigatório',
        'REGULATION_REQUIRED': 'O regulamento é obrigatório'
      }
    }
  }
};
