import {Ranking} from './../../model/ranking';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RankingService {

  constructor(
    private http: HttpClient
  ) {
  }

  getRanking(championshipId: number, competitionId: number, subDivisionId: number = 0, page = 1, per_page = 70): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    let url = `admin/rankings?championship_id=${championshipId}&competition_id=${competitionId}&page=${page}&per_page=${per_page}`;
    
    if (subDivisionId != 0 && subDivisionId) {
      url = `${url}&sub_division_id=${subDivisionId}`;
    }
    // console.log(url)

    return this.http.get(url, {responseType})
      .map((response: Response) => {
        // console.log(response)
        // let results = response as any
        // const wods = results['wods']
        const athletes = response['athletes'].map((ranking: Ranking) => new Ranking().deserialize(ranking));
        // console.log(athletes)
        return {wods: response['wods'], athletes: athletes, isPublished: response['is_publised'], paginate: response['paginate']};
      });
  }

  publishRanking(publishChampionship: string, publishCompetition: string): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/rankings?${publishChampionship}&${publishCompetition}`;
    console.log(url);

    return this.http.post(url, null, {responseType})
      .map((response: Response) => {
        console.log(response);
        let published = response['success'];
        return published;
      });
  }

  // export
  export(championshipId: number, competitionId: number, subdivisionId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/rankings/export?championship_id=${championshipId}&competition_id=${competitionId}&subdivision_id=${subdivisionId}`;

    return this.http.get(url, {responseType})
      .map((response: Response) => {
        // console.log(response)
        return response;
      });
  }
  
  exportAll(championshipId: number, subdivisionId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/rankings/export?championship_id=${championshipId}&subdivision_id=${subdivisionId}`;

    return this.http.get(url, {responseType})
      .map((response: Response) => {
        // console.log(response)
        return response;
      });
  }


}
