import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CompetitorService } from '../../../../../core/services/competitor/competitor.service';
import {
  Competitor,
  CompetitorCheckin
} from '../../../../../core/model/competitor';
import { ActivatedRoute } from '@angular/router';
import { EventGuard } from '../../../../../core/guards/event.guard';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import { EventConfigation } from '../../../../../core/model/event';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from '../../../../../core/components/toastr_/public_api';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { BreadcrumbsService } from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { requiredFileType } from '../../../../../core/utils/custom-validators';
import { TeamMember } from '../../../../../core/model/team-member';
import { TeamMembersHttpService } from '../../../../../core/services/team-members/team-members-http.service';
import { MatDialog } from '@angular/material';
import { CrossXDialogComponent } from '../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import { Errors } from '../../../../../core/model/errors';

@Component({
  selector: 'fuse-app-user-checkin',
  templateUrl: './user-checkin.component.html',
  styleUrls: ['./user-checkin.component.scss']
})
export class UserCheckinComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  competitor: Competitor;
  competitorId: number;
  competitorCheckin = CompetitorCheckin;

  canCheckin = false;
  canCheckinFile = true;
  isLoading = true;
  isLoadingBtn = true;
  submitCheckin = false;
  dateStartCheckin: string;
  timeStartCheckin: string;
  dateEndCheckin: string;
  timeEndCheckin: string;
  endCheckin: any;
  

  timezone: any;
  eventConfiguration: any[];
  translate: any;

  colsGrid: number;

  enableCheckinFile = false;
  uploadIsLoading: {
    id: number;
    isLoading: boolean;
  }[];

  submitWithdraw = false;
  dialogRef = null;

  constructor(
    private readonly competitorService: CompetitorService,
    private readonly formBuilder: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly eventGuard: EventGuard,
    private readonly toastr: ToastrService,
    private readonly translationLoader: FuseTranslationLoaderService,
    private readonly translateService: TranslateService,
    private readonly breadcrumbsService: BreadcrumbsService,
    private readonly teamMembersHttpService: TeamMembersHttpService,
    private readonly dialog: MatDialog,
  ) {
    this.registerOnTranslate();
    this.registerCompetitor();
    this.registerActiveRoute();
  }

  ngOnInit(): void {
    this.colsGrid = window.innerWidth <= 700 ? 1 : 4;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onResize(event: any): void {
    this.colsGrid = event.target.innerWidth <= 700 ? 1 : 4;
  }

  private registerOnTranslate(): void {
    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService
      .get('USER_CHECKIN')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);
  }

  private fetchCompetitor(): void {
    this.subscriptions.add(
      this.competitorService.getupdateCompetitorByUser(this.competitorId)
    );
  }

  private registerActiveRoute(): void {
    this.subscriptions.add(
      this.activatedRoute.params.subscribe(({ championship_id }) => {
        const { competitor_id } = this.eventGuard.getChampionshipPerId(
          championship_id
        );
        this.competitorId = competitor_id;
        const {
          event_configurations,
          timezone
        } = this.eventGuard.getActiveEvent().event;
        this.eventConfiguration = event_configurations;
        this.timezone = timezone;
        this.checkCanCheckin();
        this.fetchCompetitor();
        this.breadcrumbsService.replaceAll([
          {
            text: this.translate.TITLE,
            route: `/user-dashboard/championship/${championship_id}/checkin`
          }
        ]);
      })
    );
  }

  public readUrl(event: any, teamMember: TeamMember, isUpdate = false): void {
    if (event.target.files.length > 0) {
      const params = {
        checkin_file: event.target.files[0]
      };
      if (event.target.files[0].size / 1024 / 1024 > 10) {
        this.toastr.show(
          this.translate.TOASTR.UPLOAD.ERRO_FILE_SIZE.DESCRIPTION,
          this.translate.TOASTR.UPLOAD.ERROR.TITLE,
          null,
          'error'
        );
        return;
      }

      if (isUpdate) {
        this.dialog.openDialogs.pop();
        this.dialogRef = this.dialog.open(CrossXDialogComponent, {
          width: '440px',
          closeOnNavigation: true
        });
        this.dialogRef.componentInstance.type = 'warning';
        this.dialogRef.componentInstance.titleDialog = this.translate.DIALOG.UPDATE_FILE.TITLE.toUpperCase();
        this.dialogRef.componentInstance.dialogContent = this.translate.DIALOG.UPDATE_FILE.DESCRIPTION;
        this.dialogRef.componentInstance.dialogConfirm = true;
        this.dialogRef.componentInstance.confirmActionRequest = true;
        this.dialogRef.componentInstance.actionButton = this.translate.DIALOG.CONFIRM.toUpperCase();
        this.dialogRef.componentInstance.cancelButton = this.translate.DIALOG.CANCEL.toUpperCase();
        this.subscriptions.add(
          this.dialogRef
          .componentInstance
          .dialogEvent
          .subscribe((result: any) => {
            this.dialogRef.close();
            this.upload(teamMember.id, params);
          })
        );
        return;
      }

      this.upload(teamMember.id, params);
    }
  }

  public removeFile(teamMember: TeamMember): void {
    const params = {
      checkin_file: null
    };
    this.upload(teamMember.id, params);
  }

  private verifyUploadIsLoading(id: number, isLoading: boolean): void {
    this.uploadIsLoading.forEach((element, index) => {
      if (element.id === id) {
        this.uploadIsLoading[index].isLoading = isLoading;
      }
    });
  }

  public getIsLoadingUpload(id: number): boolean {
    const data = this.uploadIsLoading.filter(element => element.id === id);
    if (data) {
      if (data[0]) {
        return data[0].isLoading;
      }
    }

    return false;
  }

  private upload(id: number, params: any): void {
    this.verifyUploadIsLoading(id, true);
    this.subscriptions.add(
      this.teamMembersHttpService.uploadFileCheckin(id, params).subscribe(
        (result: TeamMember) => {
          this.competitor.team.members.forEach((member: TeamMember, index) => {
            if (member.id === result.id) {
              member.checkin_file = result.checkin_file;
              this.competitor.team.members[index] = result;
            }
          });
          if (result.checkin_file) {
            this.toastr.show(
              '',
              this.translate.TOASTR.UPLOAD.SUCCESS_UPLOAD.TITLE,
              null,
              'success'
            );
          } else {
            this.toastr.show(
              '',
              this.translate.TOASTR.UPLOAD.SUCCESS_DELETE.TITLE,
              null,
              'success'
            );
          }
          this.verifyUploadIsLoading(id, false);
          this.verifyCanCheckin(true);
        },
        err => {
          const errData = err.error || err;
          const errors: Errors = new Errors().deserialize(errData as any);
          this.toastr.show(
            errors.getFullMessages(),
            this.translate.TOASTR.UPLOAD.ERROR.TITLE,
            null,
            'error'
          );
          this.submitCheckin = false;
          this.verifyUploadIsLoading(id, false);
          this.verifyCanCheckin(true);
        }
      )
    );
  }

  private registerCompetitor(): void {
    this.subscriptions.add(
      this.competitorService
        .competitorObservable()
        .subscribe((result: Competitor | HttpErrorResponse) => {
          this.isLoading = false;
          this.isLoadingBtn = false;
          if (this.dialogRef) {
            this.dialogRef.componentInstance.confirmLoading = false;
            this.dialogRef.close();
          }

          if (result instanceof HttpErrorResponse) {
            if (this.submitCheckin || this.submitWithdraw) {
              const errors: Errors = new Errors().deserialize(
                result.error as any
              );

              this.toastr.show(
                errors.getFullMessages(),
                this.translate.TOASTR.ERROR.TITLE,
                null,
                'error'
              );
              this.submitCheckin = false;
              this.submitWithdraw = false;
            }
            return;
          }
          if (this.submitWithdraw) {
            this.toastr.show(
              '',
              this.translate.TOASTR.SUCCESS_WITHDRAW.TITLE,
              null,
              'success'
            );
          }
          if (this.submitCheckin) {
            this.toastr.show(
              '',
              this.translate.TOASTR.SUCCESS.TITLE,
              null,
              'success'
            );
          }

          this.competitor = result;
          this.submitCheckin = false;
          this.verifyCanCheckin();
          if (this.competitor.checkin_status === CompetitorCheckin.REGISTERED) {
            const now = momentTimezone.tz(this.timezone);
            if (now.diff(this.endCheckin) > 0) {
              this.competitor.checkin_status = CompetitorCheckin.NO_SHOW;
            }
          }
        })
    );
  }

  private verifyCanCheckin(isUpdateFile = false): void {
    if (this.competitor) {
      this.canCheckinFile = true;
      if (!this.enableCheckinFile) {
        return;
      }
      if (!isUpdateFile) {
        this.uploadIsLoading = [];
      }
      this.competitor.team.members.forEach((member, index) => {
        if (!isUpdateFile) {
          this.uploadIsLoading.push({
            id: member.id,
            isLoading: false
          });
        }
        if (!member.checkin_file) {
          this.canCheckinFile = false;
        }
      });
    }
  }

  private checkCanCheckin(): void {
    let startCheckin = null;
    const now = momentTimezone.tz(this.timezone);
    this.eventConfiguration.forEach(eventConfiguration => {
      if (eventConfiguration.name === EventConfigation.START_CHECKIN_ONLINE) {
        startCheckin = momentTimezone
          .tz(eventConfiguration.value, 'GMT')
          .tz(this.timezone);
      }
      if (eventConfiguration.name === EventConfigation.END_CHECKIN_ONLINE) {
        this.endCheckin = momentTimezone
          .tz(eventConfiguration.value, 'GMT')
          .tz(this.timezone);
      }
      if (eventConfiguration.name === EventConfigation.ENABLED_CHECKIN_FILE) {
        this.enableCheckinFile = !!Number(eventConfiguration.value);
      }
    });
    this.dateStartCheckin = startCheckin.format('DD/MM/YYYY');
    this.timeStartCheckin = startCheckin.format('HH:mm');
    this.dateEndCheckin = this.endCheckin.format('DD/MM/YYYY');
    this.timeEndCheckin = this.endCheckin.format('HH:mm');
    if (!startCheckin || !this.endCheckin) {
      this.canCheckin = false;
      return;
    }
    this.canCheckin = now.isBetween(startCheckin, this.endCheckin);
  }

  public checkin(): void {
    if (!this.canCheckin) {
      return;
    }
    if (!this.canCheckinFile) {
      this.toastr.show(
        '',
        this.translate.TOASTR.UPLOAD.ERROR_FILE_REQUIRED.TITLE,
        null,
        'error'
      );
      return;
    }
    this.isLoadingBtn = true;
    const params = {
      checkin_status: CompetitorCheckin.CHECKIN_ONLINE
    };
    this.submitCheckin = true;
    this.subscriptions.add(
      this.competitorService.updateCompetitorByUser(this.competitorId, params)
    );
  }

  public checkinWithdraw(): void {
    this.dialog.openDialogs.pop();
    this.dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });
    
    const competition = this.competitor.competition;
    const subDivision = this.competitor.sub_division;
    const event = this.eventGuard.getActiveEvent().event;
    const team = this.competitor.team;
    const championship = this.competitor.championship;

    this.subscriptions.add(
      this.translateService
        .get('USER_CHECKIN.DIALOG.WITHDRAW.DESCRIPTION', {
          user_name: team.name,
          event_name: event.name,
          category_name: competition.name
        })
        .subscribe((res: string) => {
          this.dialogRef.componentInstance.dialogContent = res;
        })
    );
    if (championship.enable_sub_division && subDivision) {
      this.subscriptions.add(
        this.translateService
          .get('USER_CHECKIN.DIALOG.WITHDRAW.DESCRIPTION_WITH_SUB_DIVISION', {
            user_name: team.name,
            event_name: event.name,
            category_name: competition.name,
            sub_division: championship.sub_division_group_name || 'Sub-categoria',
            sub_division_name: subDivision.name
          })
          .subscribe((res: string) => {
            this.dialogRef.componentInstance.dialogContent = res;
          })
      );
    }

    this.dialogRef.componentInstance.withdraw = true;
    this.dialogRef.componentInstance.type = 'warning';
    this.dialogRef.componentInstance.titleDialog = this.translate.DIALOG.WITHDRAW.TITLE.toUpperCase();
    this.dialogRef.componentInstance.dialogConfirm = true;
    this.dialogRef.componentInstance.confirmActionRequest = true;
    this.dialogRef.componentInstance.actionButton = this.translate.DIALOG.CONFIRM.toUpperCase();
    this.dialogRef.componentInstance.cancelButton = this.translate.DIALOG.CANCEL.toUpperCase();
    this.subscriptions.add(
      this.dialogRef.componentInstance.dialogEvent
      .subscribe((result: any) => {
        this.dialogRef.componentInstance.confirmLoading = true;
        this.submitWithdraw = true;
        const params = {
          checkin_status: CompetitorCheckin.WITHDRAW,
          who_will_get_package: result.who_will_get_package
        };
        this.subscriptions.add(
          this.competitorService.updateCompetitorByUser(this.competitorId, params)
        );
      })
    );
  }
}
