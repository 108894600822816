<mat-toolbar class="p-0">
    <mat-progress-bar *ngIf="showLoadingBar" class="loading-bar" color="accent" mode="indeterminate"></mat-progress-bar>
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
          <div class="logo" *ngIf="showBrand">
            <img class="logo-icon" src="assets/images/logos/crossx.svg">
          </div>
          <button mat-button class="toggle-button-navbar mat-icon-button icon-menu"
                  fuseNavbarVertical="toggleBar" fxHide.gt-md *ngIf="!showBrand">
              <mat-icon>menu</mat-icon>
          </button>
          <breadcrumbs [breadcrumbs]="breadcrumbs" class="ml-32" fxHide fxShow.gt-sm></breadcrumbs>
        </div>
        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center" *ngIf="user !== null">
            <div *ngIf="user.admin || user.events_count" class="toolbar-separator"></div>
            <a *ngIf="user.admin || user.events_count" href="https://championship-nvi.s3.amazonaws.com/faq.pdf" class="redirect">
              <button mat-icon-button class="mat-icon-button quick-panel-toggle-button" aria-label="FAQ">
                <mat-icon class="icon">help_outline_outlined</mat-icon>
                <span>FAQ</span>
              </button>
            </a>
            <div class="toolbar-separator"></div>
            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar" src="{{ userPicture }}">
                    <div fxLayout="column">
                        <span class="username mr-12" fxHide fxShow.gt-sm>{{ userFullName }}</span>
                        <span class="gym mr-12" fxHide fxShow.gt-sm>{{ userGym }}</span>
                    </div>
                    <mat-icon class="s-16 arrow_user" >keyboard_arrow_down</mat-icon>
                </div>
            </button>
            <mat-menu class="submenu user-menu bottom arrow-right" #userMenu="matMenu" [overlapTrigger]="false" xPosition="before">
              <button mat-menu-item class="access" [routerLink]="['myprofile']">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon class="S-16" class="icon" svgIcon="ic-profile"></mat-icon>
                  <span>{{'TOOLBAR.PROFILE' | translate}}</span>
                </div>
              </button>
              <button class="logOut" mat-menu-item (click)="logOut()">
                <mat-icon class="logOut" svgIcon="ic-logout"></mat-icon>
                <span>{{'TOOLBAR.LOGOUT' | translate}}</span>
              </button>
            </mat-menu>
        </div>
    </div>
</mat-toolbar>
