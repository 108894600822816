import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthGuard} from '../../guards/auth.guard';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private authGuard: AuthGuard
  ) { }

  searchUserByTerm(term: string): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.get(`admin/users/search/${term}`, { responseType })
      .map((response: Response) => {
        // console.log(response)
        let events = response as any
        return events
      })
  }

  searchUserByEmail(email: string): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.get(`public/registration/user/${email}`, { responseType })
      .map((response: Response) => {
        // console.log(response)
        let user = response as any
        return user
      })
  }

  enabledRegistration(email: string, event_id: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.get(`public/heat-competitors/blocked-register/${event_id}/${email}`, { responseType })
      .map((response: Response) => {
        return response;
      });
  }

  getMyEventsCompetitor(search = ''): Observable<any> 
  {
    return this.http.get(`users/me/events?search=${search}`, { observe: 'response' })
      .map(({ body, headers }) => {
        return {
          paginate: JSON.parse(headers.get('pagination')),
          myEvents: body
        };
      })
  }
  
  // Update profile
  update(parameters: any): Observable<any>
  {
    return this.http.put(`users`, parameters)
      .map((response: Response) => {
        // console.log(response)
        if (response) {
          this.authGuard.updateMe(response)
        }
        return response as any
      })
  }
  
  updatePicture(picture: File) : Observable<any>
  {
    const formData: FormData = new FormData();
    formData.append('avatar', picture, picture.name);
    formData.append('patch', 'POST');
    
    return this.http.post(`users/picture`, formData)
      .map((response: Response) => {
        // console.log(response)
        if (response) {
          this.authGuard.updateMe(response)
        }
        return response as any
      })
  }
}
