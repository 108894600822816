<div class="page-layout blank pb-64" id="scrollBar">
  <div class="p-32">
    <div class="content">

      <div *ngIf="isLoading" class="card auto-width mb-32 p-64" fxLayoutAlign="center center">
        <mat-spinner color='accent' diameter='50' strokeWidth='5'></mat-spinner>
      </div>

      <div *ngIf="!isLoading && wods.length === 0" [ngClass.gt-sm]="'mb-0'" class="card auto-width mb-32 pt-8">
        <div class="p-32 emtpy-content" fxLayout="column" fxLayoutAlign="center center">
          <img src="./assets/icons/placeholder/ic-empty.svg"/>
          <span class="mt-8">{{ 'RESULT.EMPTY' | translate }}</span>
        </div>
      </div>

      <div *ngIf="wods && wods.length > 0" [ngClass.gt-sm]="'mb-0'" class="card auto-width mb-32 pt-8">
        <mat-tab-group [disableRipple]="true" backgroundColor="" class="padding tabs-left">
          <mat-tab *ngFor="let wod of wods" label="{{ wod.name }}">
            <div class="p-32" fxLayout="column">
              <span [innerHTML]="wod.description" class="wod-description"></span>
              <div *ngIf="wod.video_url !== null && wod.video_url.length > 0" class="mt-8" fxLayout="column">
                <div class="video-container">
                  <iframe [src]="getVideoUrl(wod)" allow="autoplay; encrypted-media" allowfullscreen frameborder="0"
                          height="315" width="560"></iframe>
                </div>
              </div>
              <div
                [fxShow]="(championship.online && wod.status === 'open') && (wod.resultEdit && wod.resultEdit.result === null || wod.editResult === true)"
                class="mt-32"
                fxLayout="column">
                <mat-radio-group
                  (ngModelChange)="onTimeCapChange(wod, wod.resultEdit.has_cap)"
                  *ngIf="wod.wod_type_id === 2 && wod.goal !== null"
                  [(ngModel)]="wod.resultEdit.has_cap"
                >
                  <mat-radio-button [value]="false" class="mr-16">{{ 'RESULT.NOT_TIMECAP' | translate }}</mat-radio-button>
                  <mat-radio-button [value]="true">{{ 'RESULT.TIMECAP' | translate }}</mat-radio-button>
                </mat-radio-group>

                <div class="form mt-32" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
                  <mat-form-field
                    *ngIf="(wod.resultEdit.has_cap === false || wod.goal === null) && wod.wod_type_id === 2"
                    class="pr-8"
                    floatLabel="never"
                    fxFlex="50"
                    fxFlex.xs="100"
                  >
                    <input
                      [(ngModel)]="wod.resultEdit.result"
                      [mask]="'00:00'"
                      dropSpecialCharacters="false"
                      matInput
                      placeholder="{{ 'RESULT.RESULT' | translate }} ({{ getUnitName(wod, wod.wod_type_id, true) }})"
                      required>
                  </mat-form-field>
                  <mat-form-field
                    *ngIf="wod.resultEdit.has_cap === true || wod.wod_type_id !== 2"
                    class="pr-8"
                    floatLabel="never"
                    fxFlex="50"
                    fxFlex.xs="100"
                  >
                    <input
                      [(ngModel)]="wod.resultEdit.result"
                      matInput
                      placeholder="{{ 'RESULT.RESULT' | translate }} ({{ getUnitName(wod, wod.wod_type_id, true) }})"
                      required
                      type="number">
                  </mat-form-field>
                <!-- tiebreak -->
                  <mat-form-field
                    *ngIf="wod.tiebreak_type_id !== null && wod.tiebreak_type_id === 2"
                    class="pl-8"
                    floatLabel="never" fxFlex="50"
                    fxFlex.xs="100"
                  >
                    <input
                      [(ngModel)]="wod.resultEdit.tiebreakresult"
                      [mask]="'00:00'"
                      dropSpecialCharacters="false"
                      matInput
                      placeholder="{{ 'RESULT.TIEBREAK' | translate}} ({{ getUnitName(wod, wod.tiebreak_type_id, true, true) }})">
                  </mat-form-field>
                  <mat-form-field
                    *ngIf="wod.tiebreak_type_id !== null && wod.tiebreak_type_id !== 2"
                    class="pl-8"
                    floatLabel="never"
                    fxFlex="50"
                    fxFlex.xs="100"
                  >
                    <input
                      [(ngModel)]="wod.resultEdit.tiebreakresult"
                      dropSpecialCharacters="false"
                      matInput
                      placeholder="{{ 'RESULT.TIEBREAK' | translate}} ({{ getUnitName(wod, wod.tiebreak_type_id, true, true) }})"
                      type="number">
                  </mat-form-field>
                </div>

                <div *ngIf="championship.online" class="form" fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign="start start">
                  <mat-form-field fxFlex="100">
                    <input [(ngModel)]="wod.resultEdit.video" matInput
                           placeholder="{{ 'RESULT.URL_VIDEO' | translate}}">
                  </mat-form-field>
                </div>

                <div *ngIf="wod.resultEdit !== null && wod.resultEdit.error !== null" class="textRed">
                  {{ wod.resultEdit.error }}
                </div>

                <div class="mt-8" fxLayout="row" fxLayoutAlign="end center">
                  <btn (click)="cancelUserResult(wod)" *ngIf="wod && wod.result.id" class="ml-16"
                       type="grayscale">{{'RESULT.CANCEL' | translate | uppercase}}</btn>
                  <btn (click)="saveUserResult(wod)" [btnLoading]="true" [loading]="wod.isLoading"
                       class="ml-16">{{'RESULT.SAVE' | translate | uppercase}}</btn>
                </div>
              </div>
            </div>

            <!-- See Result -->
            <table-x *ngIf="wod.result.result !== null && !wod.editResult" [config]="tableConfig"
                     [isLoading]="false" [items]="(wod && wod.result.result !== null) ? [wod.result] : []">

              <column [sortable]="true" align="left" header="{{ 'RESULT.TABLE.NAME' | translate }}" property="name">
                <ng-template #dataTableCell let-item="item">
                  <a class="small-font" fxLayout="row" fxLayoutAlign="start center">
                    <img class="avatar" src="{{ item.avatar | placeholderImage:'user' }}" width="80"/>
                    {{ item.name }}
                  </a>
                </ng-template>
              </column>

              <column [sortable]="false" align="center" header="{{ 'RESULT.TABLE.RESULT' | translate }}"
                      property="result">
                <ng-template #dataTableCell let-item="item">
                  {{ getValueResult(item, wod) }}
                </ng-template>
              </column>

              <column *ngIf="wod && wod.tiebreak_type_id !== null" [sortable]="false" align="center"
                      header="{{ 'RESULT.TABLE.TIEBREAK' | translate }}" property="tiebreak">
                <ng-template #dataTableCell let-item="item">
                  {{ item.tiebreakresult }}
                </ng-template>
              </column>

              <column *ngIf="championship.online" [sortable]="false" align="center"
                      header="{{ 'RESULT.TABLE.VIDEO' | translate }}"
                      property="video">
                <ng-template #dataTableCell let-item="item">
                  <btn-transparent (click)="openVideo(item.video)"
                                   *ngIf="item && item.result !== null && item.video !== null" buttonWidth="35px"
                                   icon="results/ic-video.svg"
                                   iconAlign="right"></btn-transparent>
                </ng-template>
              </column>

              <column *ngIf="championship.online" [sortable]="false" align="center"
                      header="{{ 'RESULT.TABLE.STATUS' | translate }}" property="status">
                <ng-template #dataTableCell let-item="item">
                  <label-x *ngIf="item && item.result && item.status !== null" type="{{ getResultStatus(item, true) }}">
                    {{ getResultStatus(item, false) }}
                  </label-x>
                </ng-template>
              </column>

              <column
                [sortable]="false" 
                align="center"
                header="" 
                property="description"
                width="30px"
              >
                <ng-template #dataTableCell let-item="item">
                  <button #tooltip="matTooltip"
                    (click)="tooltip.toggle()"
                    class="ic-info-no-count ml-8" 
                    *ngIf="item.description"
                    matTooltip="{{ item.description }}" 
                    matTooltipPosition="left" 
                    matTooltipClass="left"
                  >
                  <mat-icon svgIcon="ic-info"></mat-icon>
                  </button>
                </ng-template>
              </column>

              <column *ngIf="championship.online && wod.status === 'open'" [sortable]="false" align="center" header=""
                      property="actions">
                <ng-template #dataTableCell let-item="item">
                  <btn-transparent
                    (click)="wod.editResult = !wod.editResult"
                    *ngIf="item.status === resultStatus.WAITING || item.status === resultStatus.REFUSED"
                    buttonWidth="35px"
                    iconAlign="left" materialIcon="create"
                  ></btn-transparent>
                </ng-template>
              </column>
            </table-x>
          </mat-tab>
        </mat-tab-group>

      </div>
    </div>
  </div>
</div>
