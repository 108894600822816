import {TableXComponent} from './../../../../../core/components/table-x/table-x.component';
import {Utils} from './../../../../../core/utils';
import {RankingService} from './../../../../../core/services/ranking/ranking.service';
import {ToastrService} from './../../../../../core/components/toastr_/toastr/toastr.service';
import {TabBarComponent} from './../../../../../core/components/tab-bar/tab-bar.component';
import {WodService} from './../../../../../core/services/wod/wod.service';
import {EventGuard} from './../../../../../core/guards/event.guard';
import {config} from './../../../../../core/config/config';
import {Component, NgZone, OnDestroy, OnInit, SecurityContext, ViewChild} from '@angular/core';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {BreadcrumbsService} from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import {FuseTranslationLoaderService} from '../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthGuard} from '../../../../../core/guards/auth.guard';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

import {locale as english} from './i18n/en-US';
import {locale as portugues} from './i18n/pt-BR';
import {MatDialog, MatIconRegistry, MatMenuTrigger} from '@angular/material';
import {TableConfig} from '../../../../../core/components/table-x/table-x.component';
import * as moment from 'moment';
import {FusePerfectScrollbarDirective} from '../../../../../core/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {environment} from '../../../../../../environments/environment';
import {Event} from '../../../../../core/model/event';
import {Subscription} from 'rxjs';
import {Ranking} from '../../../../../core/model/ranking';
import {ModalReorderAthletesComponent} from './modal-reorder-athletes/modal-reorder-athletes.component';
import {SubDivision} from "../../../../../core/model/sub-division";
import {SubDivisionHttpService} from "../../../../../core/services/sub-division/sub-division-http.service";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit, OnDestroy {

  @ViewChild('tabbar', {read: TabBarComponent}) tabBar: TabBarComponent;
  @ViewChild('tableList', {read: TableXComponent}) tableList: TableXComponent;
  @ViewChild(FusePerfectScrollbarDirective) fusePerfectScrollbarDirective;
  @ViewChild(MatMenuTrigger) optionsButton: MatMenuTrigger;
  // Translate 
  translate: any;
  event: any = null;
  domain: string = environment.domain;
  championshipId: number;
  championship: any;
  competition: any;
  competitionId: number;
  wod: any;
  wodId: number;
  wods: any[];
  results: Ranking[] = [];
  tabsFilter: string[] = [];
  tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: false,
    massActions: null,
    massActionCountTitle: null
  };
  rankingFilter: any = {
    competitor: {
      fullnameSort: ''
    }
  };
  isResultPublised = true;
  isLoadingPublish = false;
  isLoading = false;
  isEmptyResult = true;
  isShowScrollToTop = false;
  isLoadingExport = false;
  timeOut;
  enableReorderRank = false;
  rankingReorder: Array<Ranking[]> = [];
  private subscriptions: Subscription = new Subscription();
  subDivisionId: number = 0;
  subDivisions: SubDivision[] = [];
  private metaData = {
    per_page: 70,
    page: 1
  };

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private wodService: WodService,
    private rankingService: RankingService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private subDivisionHttpService: SubDivisionHttpService,
    private zone: NgZone
  ) {
    this.registerSubDivision();

    this.translationLoader.loadTranslations(english, portugues);

    this.subscriptions.add(this.translateService.get('LEADERBOARD').subscribe((res: any) => {
      this.translate = res;
    }));

    this.subscriptions.add(this.activatedRoute.params.subscribe(
      params => {
        // console.log(params)
        this.championshipId = params.championship_id;
        // this.event = this.eventGuard.getActiveEvent().event
        this.event = new Event().deserialize(this.eventGuard.getActiveEvent().event);
        this.championship = this.eventGuard.getChampionshipPerId(this.championshipId);
        if (this.championship.enable_sub_division) {
          this.fetchSubDivision();
        }

        if (this.translate) {
          this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE}`);

          this.breadcrumbsService.replaceAll([{
            text: this.translate.TITLE,
            route: `/championship/leaderboard/${this.championshipId}`
          }]);
        }
      }
    )); // end router

    // Icon Info
    iconRegistry.addSvgIcon(
      'ic-info',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/myprofile/ic-profile.svg')
    );
  }

  ngOnInit(): void {

    this.getCompetitions();
    this.subscriptions.add(this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.getCompetitions();
        }
      }));
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeOut);
    this.results = [];
    this.subscriptions.unsubscribe();
  }

  // Get Competitions
  getCompetitions() {
    this.tabBar.resetIndex();
    this.tabsFilter = [];
    this.competition = this.championship.competitions[0];
    this.competitionId = this.championship.competitions[0].id;

    this.championship.competitions.forEach(competition => {
      this.tabsFilter.push(competition.name);
    });

    this.getRanking();
    // this.getWods()
  }

  // getWods()
  // {
  //   this.wodService.getWods(this.championship.id, this.competition.id)
  //     .subscribe(
  //       result => {
  //         console.log("wods:", result);
  //         this.wods = result
  //         this.wod = this.wods[0]

  //         this.getRanking()
  //       }, err => {
  //         console.error("err", err);
  //       }
  //     ) 
  // }

  getRanking() {
    this.isLoading = true;
    this.isResultPublised = true;
    clearTimeout(this.timeOut);
    this.results = [];
    this.metaData.per_page = 70;
    this.metaData.page = 1;
    this.fetchRanking();
  }

  private registerSubDivision(): void {
    const subscription = this.subDivisionHttpService
      .subDivisionObservable()
      .subscribe((results: SubDivision[]) => {
        this.subDivisions = results || [];
        console.log('--------INICIO--------');
        console.log(this.subDivisions);
        console.log('----------FIM---------');
      });

    this.subscriptions.add(subscription);
  }

  private fetchSubDivision(): void {
    this.subDivisionHttpService.fetchSubDivision(this.championshipId);
  }
  
  public subDivisionSelect(event: MatSelectChange): void {
    this.subDivisionId = event.value;
    this.metaData.page = 1;
    this.results = [];
    this.fetchRanking();
  }
  
  private fetchRanking(): void {
    this.isLoading = true;
    const subscription = this.rankingService.getRanking(this.championship.id, this.competition.id, this.subDivisionId, this.metaData.page, this.metaData.per_page)
      .subscribe(
        result => {
          this.wods = result.wods;
          this.wod = this.wods[0];
          this.isResultPublised = result.isPublished;
          if (result.paginate.current_page == 1) {
            this.results = [];
          }
          for (const newResult of result.athletes) {
            this.results.push(newResult);
          }
          this.reloadTable();
          this.isLoading = false;
          this.isEmptyResult = this.results.length === 0;
          if (result.paginate.current_page >= result.paginate.last_page) {
            return;
          }
          this.metaData.page++;
          this.fetchRanking();
        }, err => {
          this.isLoading = false;
        }
      );

    this.subscriptions.add(subscription);
  }

  getResultPerWod(wodId: number, results: any[], isTiebreak: boolean = false): string {
    const obj = results.filter(function (result) {
      // console.log(result)
      // console.log(result['wod_id'], wodId)
      if (result['wod_id'] === wodId) {
        return result;
      }
    });

    console.log(obj);

    return (obj.length > 0) ? obj[0].result : 'não tem nada';
  }

  getResultObj(wodId: number, results: any[]): any {
    const obj = results.filter(function (result) {
      // console.log(result)
      // console.log(result['wod_id'], wodId)
      if (result['wod_id'] === wodId) {
        return result;
      }
    });

    return (obj.length > 0) ? obj[0] : null;
  }

  formatResult(item?: any, wod?: any, tiebreak: boolean = false, type: string = 'result'): string {
    if (item === null || item.result === null) {
      return '';
    }

    const result = (!tiebreak) ? item.result : (wod.tiebreak_type_id) ? item.tiebreakresult : '';
    const column = ((!tiebreak) ? this.subDivisionId ? item.sub_division_rank : item.rank : ((item.points) ? `${item.points}pts` : ''));

    if (wod.wod_type_id === 2 && !tiebreak) {
      // format time in result and verify is CAP
      if (item.has_cap) {
        return (type === 'result') ? (((wod.goal - result) > 0) ? `CAP+${(wod.goal - result)}` : 'CAP') : column;
      } else {
        const d = moment.duration((result * -1));
        // @ts-ignore
        const seconds = `${d.seconds()}`.padStart(2, '0');
        // @ts-ignore
        const minutes = `${d.minutes()}`.padStart(2, '0');

        if (this.event.isResultTimeInMilliseconds) {
          // @ts-ignore
          const milliseconds = `${d.milliseconds()}`.padStart(3, '0');
          return (type === 'result') ? `${minutes}:${seconds}.${milliseconds}` : column;
          // let time = moment.utc(moment.duration((result * -1), "s").asMilliseconds()).format("mm:ss")
          // return (type === "result") ? time : column
        } else {
          return (type === 'result') ? `${minutes}:${seconds}` : column;
        }
      }
    } else if (wod.tiebreak_type_id === 2 && tiebreak) {
      // Format time in tiebreak
      const d = moment.duration((result * -1));
      // @ts-ignore
      const seconds = `${d.seconds()}`.padStart(2, '0');
      // @ts-ignore
      const minutes = `${d.minutes()}`.padStart(2, '0');

      if (this.event.isResultTimeInMilliseconds) {
        // @ts-ignore
        const milliseconds = `${d.milliseconds()}`.padStart(3, '0');
        return (type === 'result') ? ((result) ? `${minutes}:${seconds}.${milliseconds}` : '-') : column;
        // let time = moment.utc(moment.duration((result * -1), "s").asMilliseconds()).format("mm:ss")
        // return (type === "result") ? time : column
      } else {
        return (type === 'result') ? ((result) ? `${minutes}:${seconds}` : '-') : column;
      }
      // let time = moment.utc(moment.duration((result * -1), "s").asMilliseconds()).format("mm:ss")
      // return (type === 'result') ? ((result) ? time : '-') : column
    } else {
      if (!tiebreak) {
        return (type === 'result') ? `${result}${Utils.getUnitName(wod.wod_type_id, true)}` : column;
      }

      return (type === 'result') ? `${result}${Utils.getUnitName(wod.tiebreak_type_id, true)}` : column;
    }
  }

  // Publish Ranking
  publishRanking() {
    this.isLoadingPublish = true;

    const publishChampionship = `championship_id=${this.championshipId}`;
    const publishCompetition = `competition_id=${this.competitionId}`;

    const subscription = this.rankingService.publishRanking(publishChampionship, publishCompetition)
      .subscribe(
        response => {
          console.log('result', response);

          this.toastr.show(
            this.translate.PUBLISH.TOASTR_DESCRIPTION,
            this.translate.PUBLISH.TOASTR_TITLE,
            null,
            'success',
          );

          this.isLoadingPublish = false;
          this.isResultPublised = true;
        }, err => {
          // console.log("err", err);
          this.isLoadingPublish = false;
        }
      );

    this.subscriptions.add(subscription);
  }

  // Export Leaderboard
  exportRanking() {
    this.isLoadingExport = true;
    const subscription = this.rankingService.export(this.championship.id, this.competitionId, this.subDivisionId)
      .subscribe(
        result => {
          Utils.forceDownloadWithValidationURL(result.file);

          this.isLoadingExport = false;
        }, err => {
          console.log('err', err);
          this.isLoadingExport = false;
        }
      );

    this.subscriptions.add(subscription);
  }
  
  exportAllRankings() {
    this.isLoadingExport = true;
    const subscription = this.rankingService.exportAll(this.championship.id, this.subDivisionId)
      .subscribe(
        result => {
          Utils.forceDownloadWithValidationURL(result.file);

          this.isLoadingExport = false;
        }, err => {
          console.log('err', err);
          this.isLoadingExport = false;
        }
      );

    this.subscriptions.add(subscription);
  }

  // Tabbar action
  tabAction(event) {
    // console.log('tab action', event)
    this.competition = this.championship.competitions[event.index];
    this.competitionId = this.championship.competitions[event.index].id;
    this.subDivisionId = 0;

    this.getRanking();
  }

  // Sort Results
  sortAction(sort) {
    console.log('sort', sort);

    if (sort.sort === null) {
      this.sortResult('rank', 'ASC');
    } else {
      this.sortResult(sort.sort, sort.order);
    }
  }

  sortResult(column: string, order: string) {
    Utils.sortArray(this.results, column, order);
  }

  scrollToTop(event) {
    console.log('scroll', event);
    if (event.type === 'scrollToTop') {
      this.fusePerfectScrollbarDirective.scrollToTop(0, 2);
    }
  }

  public reorderAthletesTie(): void {
    const modalRef = this.dialog.open(ModalReorderAthletesComponent, {
      width: '816px',
      height: '100%',
      panelClass: 'modal'
    });
    modalRef.componentInstance.rankingReorder = this.rankingReorder;
    modalRef.componentInstance.competitionId = this.competition.id;
    modalRef.componentInstance.championshipId = this.championship.id;
    this.subscriptions.add(modalRef.afterClosed()
      .subscribe(result => {
        if (!result) {
          return;
        }
        if (result.isUpdate) {
          this.results = result.data;
          this.reloadTable();
        }
        modalRef.close();
      }));
  }

  private reloadTable(): void {
    this.rankingReorder = [];
    this.results.forEach((resultData) => {
      const tie = this.results
        .filter((elementSearch) => elementSearch.competitor.rank === resultData.competitor.rank);

      if (tie.length > 1) {
        const exists = this.rankingReorder
          .filter(rankingFilter => rankingFilter.filter((data) => data.competitor.rank === tie[0].competitor.rank).length > 0);
        if (exists.length === 0) {
          this.rankingReorder
            .push(tie);
        }
      }
    });
    if (this.tableList) {
      this.tableList.reloadItems();
    }
  }

  private populateArray(newResults: any[], index: number = 0) {
    const items = 70;
    const maxCount = ((index + items) <= newResults.length) ? (index + items) : index + (newResults.length - index);
    let newIndex = index;
    for (let i = index; i <= maxCount; i++) {
      const result = newResults[i];
      if (result) {

        this.zone.runOutsideAngular(() => {
          this.results.push(result);
        });
      }
      newIndex = i;
    }

    if (this.tableList) {
      this.tableList.reloadItems();
    }

    if (maxCount < newResults.length) {
      this.timeOut = setTimeout(() => {
        this.populateArray(newResults, newIndex);
      }, 0.1);
    }
  }
}
