import { NgModule } from '@angular/core';
import { EventListComponent } from './event-list.component';
import { AuthGuard } from '../../../../../core/guards/auth.guard';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../../../core/modules/shared.module';
import { NgxMaskModule } from 'ngx-mask';

const routes = [
  {
    path: 'list-event/:type',
    component: EventListComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    EventListComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot()
  ],
  exports: [
    EventListComponent
  ]
})
export class EventListModule { }
