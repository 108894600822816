import { Lot } from './lot';
import { Wod } from './wod';
import { FormGroup, FormBuilder, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';
import { FormErrorStateMatcher } from '../utils/form-error-state-matcher';
import { SubDivisionLimit } from './sub-division-limit';
import { SubDivision } from './sub-division';

export class Competition {
  id?: number = null;
  name = '';
  limit?: number = null;
  start_number: number;
  cut_line: number | null = null;
  team = false;
  team_size?: number = null;
  gender?: number = null;
  order = 0;
  wods?: Wod[] | null = [];
  sub_divisions?: SubDivision[] | null;
  lots?: Lot[] = [];
  enable_sub_division = false;
  sub_division_limits?: SubDivisionLimit[];

  created_at?: Date;
  updated_at?: Date;

  // Settings to create or update
  sumSingle = false;
  canBeDeleted = false;
  isOpen = false;
  championshipId: number = null;
  eventId: number = null;

  // FORMS
  nameRequired = false;
  teamSizeRequired = false;
  startNumberRequired = false;

  nameFormControl = new FormControl('', [
    Validators.required
  ]);

  startNumberFormControl = new FormControl(null, [
    Validators.required,
    Validators.min(1)
  ]);

  cutLineFormControl = new FormControl(null, [
    Validators.min(1)
  ]);

  matcher = new FormErrorStateMatcher();

  deserialize(object: any) {
    (<any>Object).assign(this, object);
    
    if (object) {
      if (object.wods) {
        this.wods = object.wods.map((wod: Wod) => new Wod().deserialize(wod));
      }

      if (object.lots) {
        this.lots = object.lots.map((lot: Lot) => new Lot().deserialize(lot));
      }

      if (object.team_size !== null && object.team_size > 1) { this.team = true; }
    } 

    return this;
  }

  sendToAPI(excludeChamptionship: boolean = false): any 
  {
    this.nameRequired = (this.name === null || this.name.length === 0);
    this.teamSizeRequired = (this.team && (this.team_size == null || this.team_size === 0));
    this.startNumberRequired = !(this.start_number >= 0);

    if (
        this.teamSizeRequired ||
        this.nameRequired ||
        this.startNumberRequired ||
        this.cutLineFormControl.invalid
    ) { 
      return { 
        competition: null, 
        error: true
      };
    }

    const object: any = {
      name: this.name.toUpperCase()
    };
    if (!excludeChamptionship) { 
      object['championship_id'] = this.championshipId; 
    }
    if (!excludeChamptionship) { 
      object['event_id'] = this.eventId; 
    }
    object['team_size'] = (this.team) ? this.team_size : null;
    object['limit'] = (this.limit && this.limit > 0) ? this.limit : null;
    object['start_number'] = this.start_number;
    object['cut_line'] = this.cut_line;
    let errorSubDivision = false;
    const subDivisionLimits: SubDivisionLimit[] = [];
    this.sub_division_limits.forEach((subDivisionLimit) => {
      if (subDivisionLimit.limitFormControl.invalid || subDivisionLimit.cutLineFormControl.invalid) {
        errorSubDivision = true;
      }
      let objectSubDivisionLimit: any = {
        cut_line: subDivisionLimit.cut_line,
        limit: subDivisionLimit.limit,
        sub_division_id: subDivisionLimit.sub_division_id,
        competition_id: subDivisionLimit.competition_id
      };
      if (subDivisionLimit.id) {
        objectSubDivisionLimit = {
          ...objectSubDivisionLimit,
          id: subDivisionLimit.id
        };
      }
      subDivisionLimits.push(objectSubDivisionLimit);
    });
    if (errorSubDivision) {
      return { 
        competition: null, 
        error: true
      };
    }
    object['enable_sub_division'] = this.enable_sub_division;
    if (this.enable_sub_division) {
      object['sub_division_limits'] = subDivisionLimits;
    }

    return { competition: object, error: false};
  }

  convertToJSON(): any
  {
    return {
      id: this.id,
      name: this.name,
      gender: this.gender,
      limit: this.limit,
      order: this.order,
      team_size: this.team_size
    };
  }
}


