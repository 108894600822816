import 'rxjs/add/operator/map'
import { config } from './../config/config';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';

//https://github.com/psykolm22/angular-google-place
// https://angular-maps.com/

@Injectable()
export class GooglePlacesService {
  // private apiAutocompleteUrl = 'https://maps.googleapis.com/maps/api/place/autocomplete/json'
  private apiAutocompleteUrl = 'https://maps.googleapis.com/maps/api/geocode/json'
  private apiDetailUrl =  'https://maps.googleapis.com/maps/api/place/details/json'
  private autocompleteService;
  private placeService;

  constructor(
    private http: HttpClient,
    private mapsAPILoader: MapsAPILoader
  ) { 
    this.mapsAPILoader.load().then(() => {
      this.autocompleteService = new google.maps.places.AutocompleteService();
      this.placeService = new google.maps.places.PlacesService(<HTMLDivElement>document.getElementById('gmap'))
    });
  }

  search(term: string): Observable<any[]> {
    return Observable.create(observer => {
      // API Call

      this.autocompleteService.getPlacePredictions({ input: term }, data => {
        let previousData: Array<any[]>;

        // Data validation

        if (data) {
          // console.log(data);
          previousData = data;
          observer.next(data);
          observer.complete();
        }

        // If no data, emit previous data

        if (!data) {
          // console.log("PreviousData: ");
          observer.next([]);
          observer.complete();

          // Error Handling

        } else {
          observer.error(status);
        }

      });

    });

  }

  

  placeIdDetail(placeId: string) : Observable<any>
  {
    // console.log('porra')
    return Observable.create(observer => {
      // API Call
      this.placeService.getDetails({
        placeId: placeId
      }, data => {
        // console.log('data porra', data)
        let previousData: Array<any[]>;

        // Data validation

        if (data) {
          // console.log(data);
          previousData = data;
          observer.next(data);
          observer.complete();
        }

        // If no data, emit previous data

        if (!data) {
          // console.log("PreviousData: ");
          observer.next([]);
          observer.complete();

          // Error Handling

        } else {
          observer.error(status);
        }
      })
    });

  }
}
