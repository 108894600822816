import { MaterialModule } from './../../modules/material.module';
import { SelectTimeComponent } from './select-time.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule
  ],
  declarations: [
    SelectTimeComponent
  ],
  exports: [
    SelectTimeComponent,
    MaterialModule,
    FlexLayoutModule
  ],
  providers: [
  ]
})
export class SelectTimeModule { }
