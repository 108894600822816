import { InformationComponent } from './information.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '../../../../../core/guards/auth.guard';
import { EventGuard } from '../../../../../core/guards/event.guard';
import { SharedModule } from '../../../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { AgmCoreModule } from '@agm/core';

const routes = [
  {
    path: 'user-dashboard/championship/:championship_id/information',
    component: InformationComponent,
    canActivate: [AuthGuard, EventGuard]
  },
  {
    path: 'user-dashboard/information',
    component: InformationComponent,
    canActivate: [AuthGuard, EventGuard]
  }
];

@NgModule({
  declarations: [
    InformationComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot()
  ],
  exports: [
    InformationComponent
  ]
})
export class InformationModule { }
