import { MaterialModule } from './../../modules/material.module';
import { TableXRowModule } from './table-x-row/table-x-row.module';
import { SharedModule } from './../../modules/shared.module';
import { TableXComponent } from './table-x.component';
import { NgModule } from '@angular/core';
import { CommonModule, AsyncPipe } from '@angular/common';
import { TableXColumnComponent } from './table-x-column/table-x-column.component';
import { MatCheckboxModule } from '@angular/material';
import { DragulaModule } from 'ng2-dragula';

@NgModule({
  imports: [
    CommonModule,
    TableXRowModule,
    MaterialModule,
    DragulaModule.forRoot()
  ],
  declarations: [
    TableXComponent
  ],
  exports: [
    TableXComponent,
    MaterialModule
  ],
  providers: [
  ]
})
export class TableXModule { }
