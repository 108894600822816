export const locale = {
  lang: 'pt-BR',
  data: {
    'HEAT_COMPETITORS': {
      'TITLE': 'Baterias',
      'HEAT': 'Bateria',
      'DATE_FORMAT': 'DD/MM/YYYY',
      'TABS': {
        'SINGLE': 'Individual',
        'TEAM': 'Time',
      },
      'SCREEN': {
        'HEATS': 'Baterias',
        'SCHEDULE': 'Horários'
      },
      'HEATS_GENERATOR': {
        'FIELDS': {
          'NUMBER_HEATS': 'Número de baterias',
          'NUMBER_LANES': 'Competidores por baterias',
        },
        'VALIDATIONS': {
          'NUMBER_HEATS_REQUIRED': 'Número de baterias é obrigatório',
          'NUMBER_LANES_REQUIRED': 'Competidores por baterias é obrigatório',
        },
        'SUBMIT': 'Gerar baterias',
        'HEATS_ITEMS' : {
          'SUBMIT' : 'Salvar baterias',
          'CLEAR': 'Limpar todas as lanes',
          'DELETE': 'Deletar bateria',
          'EDIT_HEAT': 'Editar número e nome',
          'ADD_HEAT': 'Nova bateria',
          'PUBLISH_COMPETITOR': 'Publicar competidores',
          'PUBLISH_SCHEDULE': 'Publicar agenda',
          'UNPUBLISH_COMPETITOR': 'Despublicar competidores',
          'UNPUBLISH_SCHEDULE': 'Despublicar agenda',
          'PUBLISH_COMPETITOR_ALL': 'Publicar todos os competidores',
          'PUBLISH_SCHEDULE_ALL': 'Publicar todos os cronogramas',
          'UNPUBLISH_COMPETITOR_ALL': 'Despublicar todos os competidores',
          'UNPUBLISH_SCHEDULE_ALL': 'Despublicar todos os cronogramas',
          'INFO': {
            'PUBLISH': {
              'COMPETITOR': 'Competidores publicados',
              'SCHEDULE': 'Agenda publicada'
            },
            'UNPUBLISH': {
              'COMPETITOR': 'Competidores despublicados',
              'SCHEDULE': 'Agenda despublicada'
            }
          }
        },
        'FILTER_COMPETITORS': {
          'COMPETITORS': 'Competidores',
          'FIELDS': {
            'WODS': 'Wod',
            'COMPETITIONS': 'Categoria',
            'SUB_DIVISION': 'Sub-categoria',
            'SEARCH': 'Procure por nome',
          },
          'VALIDATIONS': {
            'WODS_REQUIRED': 'Wods é obrigatório',
            'COMPETITIONS_REQUIRED': 'Categorias é obrigatório',
            'SUB_DIVISION_REQUIRED': 'Sub-categoria é obrigatório',
          },
          'EMPTY' : 'Não existem categorias cadastradas.',
          'TOASTR': {
            'WODS': {
              'TITLE': 'WODS',
              'DESCRIPTION': 'Não existe nenhum wod cadastrado para a categoria selecionada.',
            }
          },
        },
        'COMPETITOR_DATAGRID': {
          'BUSY_LANES': 'Ocupadas: ',
          'OF': 'de',
        },
        'TABLE' : {
          'NAME': 'Nome',
          'LANE': 'Lane',
          'COMPETITOR': 'Competitor',
          'COMPETITION': 'Categoria',
          'WOD': 'Wod',
          'DRAG_AREA_COMPETITOR': 'Arraste o competitor aqui',
        },
        'DIALOG': {
          'RESET_HEATS': {
            'TITLE': 'Você está prestes a resetar as baterias, Tem certeza que deseja continuar?',
            'MESSAGE': 'A quantidade de baterias e/ou lanes é menor do que a atual, com isso iremos re-gerar todo o conteúdo e você perderá a configuração atual.'
          },
          'PUBLISH_ALL_SCHEDULE': {
            'TITLE': 'Você tem certeza que deseja publicar todos os horários das baterias?',
            'MESSAGE': ''
          },
          'PUBLISH_ALL_COMPETITOR': {
            'TITLE': 'Você tem certeza que deseja publicar todos os competidores?',
            'MESSAGE': 'Ao publicar os competidores os horários das baterias também serão publicados.'
          },
          'UNPUBLISH_ALL_SCHEDULE': {
            'TITLE': 'Você tem certeza que deseja despublicar todos os horários das baterias?',
            'MESSAGE': 'Ao despublicar os horários os competidores da bateria também serão despublicados.'
          },
          'UNPUBLISH_ALL_COMPETITOR': {
            'TITLE': 'Você tem certeza que deseja despublicar todos os competidores?',
            'MESSAGE': ''
          },
          'PUBLISH_COMPETITOR': {
            'TITLE': 'Você tem certeza que deseja publicar os competidores desta bateria?',
            'MESSAGE': 'Ao publicar os competidores o horário da bateria também será publicado.'
          },
          'PUBLISH_SCHEDULE': {
            'TITLE': 'Você tem certeza que deseja publicar os horários desta bateria?',
            'MESSAGE': ''
          },
          'UNPUBLISH_COMPETITOR': {
            'TITLE': 'Você tem certeza que deseja despublicar os competidores desta bateria?',
            'MESSAGE': ''
          },
          'UNPUBLISH_SCHEDULE': {
            'TITLE': 'Você tem certeza que deseja despublicar os horários desta bateria?',
            'MESSAGE': 'Ao despublicar o horário os competidores da bateria também serão despublicados.'
          },
          'DELETE_HEAT': {
            'TITLE': 'Você tem certeza que deseja deletar a bateria?',
            'MESSAGE': 'Essa ação não poderá ser revertida',
          },
          'CANCEL': 'Cancelar',
          'CONTINUE': 'Continuar'
        },
        'TOASTR': {
          'ADD_CLASS_ERROR': {
            'TITLE': 'Erro ao adicionar bateria',
            'DESCRIPTION': 'Os campos hora de início e transição são obrigatórios.'
          },
          'LIMIT_LANE': {
            'TITLE': 'Ação inválida',
            'MESSAGE': 'Você selecionou mais competidores do que tem disponível de lanes.'
          },
          'TIME_CAP': {
            'TITLE': 'Ação inválida',
            'MESSAGE': 'O TimeCap do wod é diferente do TimeCap da bateria.',
          },
          'COUNT_LANES_EMPTY': {
            'TITLE': 'Ação inválida',
            'MESSAGE': 'Não tem a quatidade de lanes vazias para a quantidade selecionada.'
          },
          'SAME_WOD_COMPETITION': {
            'TITLE': 'Ação inválida',
            'MESSAGE': 'Você não pode adicionar competidores da mesma categoria mas em wod diferente na mesma bateria'
          },
          'SAVE': {
            'SUCCESS': {
              'TITLE': 'Baterias salvas com sucesso!',
              'MESSAGE': 'As alterações foram salvas com sucesso'
            },
            'ERROR': {
              'TITLE': 'Erro ao salvar as baterias!',
              'MESSAGE': 'Erro ao salvar as baterias'
            }
          },
          'DELETE_HEAT': {
            'SUCCESS': {
              'TITLE': 'Bateria deletada com sucesso!',
              'MESSAGE': 'A bateria selecionada foi deletada com sucesso.'
            },
            'ERROR': {
              'TITLE': 'Erro ao deletar a bateria'
            }
          }
        },
        'SORT_OPTIONS': {
          'BY_RANK_IN_MIDDLE': 'Ordenação dos melhores rankeados ao meio',
          'ALPHA_A_Z': 'Ordem alfabética A-Z',
          'ALPHA_Z_A': 'Ordem alfabética Z-A',
          'COMPETITOR_NUMBER_ASC': 'Ordenar por número do competidor crescente',
          'COMPETITOR_NUMBER_DESC': 'Ordenar por número do competidor decrescente',
          'RANK_ASC': 'Ordenar por ranking crescente',
          'RANK_DESC': 'Ordenar por ranking decrescente',
          'COMPETITOR_IN_MIDDLE': 'Ordenação dos participantes ao meio'
        }
      },
      'HEAT_EDIT': {
        'TITLE': 'Editar bateria {{heat_number}}',
        'FORM': {
          'NAME': 'Nome da bateria',
          'NUMBER': 'Número da bateria',
          'LANES': 'Lanes',
          'ERROR': {
            'NUMBER_REQUIRED': 'Número da bateria é obrigatório',
            'NAME_REQUIRED': 'Nome da bateria é obrigatório',
            'LANES_REQUIRED': 'Campo é obrigatório',
            'LANES_MIN': 'Valor mínimo deve ser a 1',
            'LANES_PATTERN': 'Campo só permite número inteiro.'
          }
        },
        'TOASTR': {
          'SUCCESS': {
            'TITLE': 'Bateria salva com sucesso!',
            'DESCRIPTION': 'Informações alteradas.'
          },
          'ERROR': {
            'TITLE': 'Erro ao alterar baterias',
          },
          'ERROR_DATE': {
            'TITLE': 'Erro ao gerar horários',
            'DESCRIPTION': 'Não é possivel iniciar um bateria que não corresponde ao dia do WOD.'
          }
        }
      },
      'HEATS_SCHEDULE': {
        'SCHEDULE': 'Horários',
        'TITLE_CONTENT': 'Programar baterias',
        'DATE_FORMAT': 'dd/MM/yyyy',
        'FIELDS': {
          'DUE_DATE': 'Data de início',
          'START_TIME': 'Hora de início',
          'DURATION': 'Duração (min)',
          'TRANSITION': 'Transição (min)',
        },
        'VALIDATIONS': {
          'DUE_DATE_REQUIRED': 'Data de início é obrigatório',
          'START_TIME_REQUIRED': 'Hora de início é obrigatório',
          'DURATION_REQUIRED': 'Duração é obrigatório',
          'TRANSITION_REQUIRED': 'Transição é obrigatório',
          'TRANSITION_MIN': 'Transição não pode ser inferior a 0'
        },
        'SUBMIT_GENERATE_SCHEDULE': 'Aplicar',
        'SUBMIT_SCHEDULE': 'Salvar',
        'TABLE' : {
          'HEAT': 'Bateria',
          'NAME': 'Nome',
          'DESCRIPTION': 'Descrição',
          'DATE': 'Data',
          'START_TIME': 'Início',
          'END_TIME': 'Término',
          'TIME_CAP': 'TimeCap',
          'TRANSITION': 'Transição',
          'EDIT_START_TIME': 'Editar hora de início',
          'DISABLE_EDIT_START_TIME': 'Voltar hora de início'
        },
        'EMPTY' : 'Não existem baterias cadastradas para programar.',
        'TOASTR': {
          'GENERATE': {
            'TITLE': 'Falha ao programar baterias!',
            'DESCRIPTION': 'Nenhuma bateria foi selecionada.',
          },
          'UPDATE': {
            'SUCCESS': {
              'TITLE': 'Baterias alteradas com sucesso!',
              'DESCRIPTION': 'Baterias alteradas.',
            },
            'ERROR': {
              'TITLE': 'Falha ao salvar!',
            }
          },
          'INPUT_ERROR': {
            'TITLE': 'Erro no valor inserido!',
            'DESCRIPTION': 'O valor inserido é inválido, por favor, verifique'
          }
        },
      },
      'TABLE': {
        'ID' : 'ID',
        'NAME': 'Nome',
        'LANE': 'Lane',
        'COMPETITOR': 'Competidor',
        'RANK': 'Rank',
        'COMPETITION': 'Categoria',
        'WOD': 'WOD'
      },
      'EMPTY' : 'Os WODs não estão configurados para bateria.',
      'EMPTY_DESCRIPTION' : 'Para configurar as baterias é necessário o Time Cap e a data do WOD.',
      'CANCEL': 'Cancelar',
      'OPTIONS': 'Opções',
      'EXPORT': 'Exportar',
      'SAVE': 'Salvar'
    }
  }
};
