import { Component, OnDestroy, OnInit } from '@angular/core';
import { locale as portugues } from '../i18n/pt-BR';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../../core/services/translation-loader.service';
import { HeatQueryParams } from '../enums/heat-query.params';
import { Subscription } from 'rxjs';
import {ActivatedRoute, Params} from '@angular/router';
import { HeatsService } from '../services/heats.service';
import { HeatsUrlService } from '../services/heats-url.service';
import { KeyValue } from '../model/key-value.model';
import {Utils} from '../../../../../../core/utils';
import {Event} from '../../../../../../core/model/event';
import {EventGuard} from '../../../../../../core/guards/event.guard';

@Component({
  selector: 'app-nav-team-type',
  templateUrl: './nav-team-type.component.html',
  styleUrls: ['./nav-team-type.component.scss']
})
export class NavTeamTypeComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();

  // translate
  translate: any;

  // tabs
  tabsFilter: string[] = [];

  tabIndexActive = 0;
  
  private queryParams = new Array<KeyValue>();
  
  private readonly tabs = [
    HeatQueryParams.TYPE_SINGLE,
    HeatQueryParams.TYPE_TEAM,
  ];


  private params: Params;

  constructor(
    private translateService: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private heatsService: HeatsService,
    private heatsUrlService: HeatsUrlService,
    private eventGuard: EventGuard,
    private activatedRoute: ActivatedRoute,
  ) {
    // translate
    this.registerOnTranslateHeatCompetitors();

    // params url
    this.registerOnPathParamsUrl();

    // query params
    this.registerOnUrlQueryParams();

    // tabs
    this.initTabsFilter();

    this.registerOnChampionshipIdChangedObservable();
  }

  tabAction(tabEvent: { index: number, type: string, title: string }) {
    this.tabIndexActive = tabEvent.index;
    this.heatsService.addFilter(HeatQueryParams.TYPE, this.getTypeValueFromIndex(tabEvent.index));
  }

  ngOnInit() {
    this.initQueryParams();
  }

  private initQueryParams(): void {
    if (this.params.length === 0) {
      this.heatsService.addFilter(HeatQueryParams.TYPE, HeatQueryParams.TYPE_SINGLE);
    }
  }

  private registerOnChampionshipIdChangedObservable(): void {
    this.heatsUrlService
      .championshipIdChangedObservable()
      .subscribe((data: boolean) => {
        if (data) {
          this.heatsService.addFilter(HeatQueryParams.TYPE, HeatQueryParams.TYPE_SINGLE);
        }
      });
  }
  
  private getTypeValueFromIndex(index: number) {
    return index === 1 ? HeatQueryParams.TYPE_TEAM : HeatQueryParams.TYPE_SINGLE;
  }

  private initTabsFilter() {
    this.tabsFilter = [
      this.translate.TABS.SINGLE,
      this.translate.TABS.TEAM
    ];
    /*this.activatedRoute.params.subscribe(
      params => {
        console.log(params)
        const championships = this.eventGuard.getChampionshipPerId(params.championship_id)

        // Verify competitions has single and team
        const hasTypeCompetitions = Utils.validateChampionshipHasTeamAndSingle(championships.competitions)

        this.tabsFilter = [];
        if (hasTypeCompetitions.hasSingle) {
          this.tabsFilter.push(this.translate.TABS.SINGLE)
        }
        else {
          
        }
        if (hasTypeCompetitions.hasTeam) {
          this.tabsFilter.push(this.translate.TABS.TEAM)
        }
      }
    );*/
  }

  private registerOnUrlQueryParams() {
    const subscription = this.heatsUrlService
      .queryParamsObservable()
      .subscribe((params: KeyValue[]) => {
        const paramType = params.find((param) => param.key === HeatQueryParams.TYPE);
        if (!paramType) {
          this.params = [];
          return;
        }
        this.tabIndexActive = this.tabs.findIndex((item) => {
          return item === paramType.value;
        });
        this.params = params;
      });

    this.subscriptions.add(subscription);
  }

  private registerOnPathParamsUrl() {
    const subscription = this.heatsUrlService
      .paramsObservable()
      .subscribe((params: Params) => {
        this.params = params;
      });

    this.subscriptions.add(subscription);
  }

  private registerOnTranslateHeatCompetitors(): void {
    
    this.translationLoader.loadTranslations(portugues);
    const subscription = this.translateService
      .get('HEAT_HEATCOMPETITORS')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);
  }

  ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
  }
}
