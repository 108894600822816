export const locale = {
  lang: 'pt-BR',
  data: {
    'CAT_LOT_WOD': {
      'TABS': {
        'CATEGORY': 'Categorias',
        'LOTS': 'Ingressos',
        'WODS': 'Wods'
      },
      'SUB_DIVISION': {
        'ENABLE': 'Habilitar sub-categoria',
        'ENABLE_CUSTOM': 'Habilitar {{ sub_division }}',
        'LIMIT': 'Limite por sub-categoria',
        'LIMIT_CUSTOM': 'Limite por {{ sub_division }}',
        'TITLE': 'Sub-categoria',
      },
      'TITLE': 'Categorias',
      'DESCRIPTION': 'Lorem ipsum dolor sit amet, vis suas mutat graece cu. In mea mazim periculis, sed porro pertinacia deterruisset ut, sea omnium facilisi necessitatibus at. Detracto.',
      'CATEGORY': {
        'TABLE': {
          'NAME': 'Nome',
          'LIMIT': 'Limite',
          'TYPE': 'Tipo',
          'SINGLE': 'Individual',
          'TEAM': 'Time'
        },
        'EMPTY': 'Não existe categoria cadastrada',
        'SUCCESS_CREATE_TITLE': 'Categoria criada com sucesso!',
        'SUCCESS_CREATE_DESCRIPTION': '',
        'SUCCESS_EDIT_TITLE': 'Categoria editada com sucesso!',
        'SUCCESS_EDIT_DESCRIPTION': '',
        'SUCCESS_DELETE_TITLE': 'Categoria deletada com sucesso!',
        'SUCCESS_DELETE_DESCRIPTION': '',
        'ERROR_DELETE_TITLE': 'Erro ao deletar a categoria!',
        'DIALOG': {
          'TITLE': 'Excluir categoria',
          'DESCRIPTION': 'Tem certeza que deseja excluir a categoria <strong>{{ category_name }}</strong>?<br><br>Após executada essa ação não poderá ser desfeita.',
          'EXCLUIR': 'Excluir',
          'CANCEL': 'Cancelar',
        },
        'TOASTR': {
          'DELETE': {
            'ERROR_DELETE_TITLE': 'Erro ao deletar a categoria!',
            'ERROR_DELETE_DESCRIPTION': 'Já existe competidor e/ou pagamento cadastrado nessa categoria.',
          }
        },
      },
      'LOT': {
        'COPY': 'Copiar ingressos',
        'TABLE': {
          'NAME': 'Nome',
          'PRICE': 'Valor',
          'DATE_START': 'Data de início',
          'DATE_END': 'Data final',
          'COUNT': 'Quantidade'
        },
        'CONTENT_EMPTY': 'Nenhum ingresso cadastrado',
        'CURRENCY': 'R$',
        'CURRENCY_CODE': 'BRL',
        'ADD': 'Adicionar',
        'ADD_NEW': 'Adicionar novo ingresso',
        'TOASTR': {
          'CREATE': {
            'TITLE': 'Ingresso criado com sucesso!',
            'DESCRIPTION': '',
          },
          'EDIT': {
            'TITLE': 'Ingresso editado com sucesso!',
            'DESCRIPTION': '',
          },
          'DELETE': {
            'TITLE': 'Ingresso deletado com sucesso!',
            'DESCRIPTION': '',
            'ERROR_DELETE_TITLE': 'Erro ao deletar o ingresso!',
            'ERROR_DELETE_DESCRIPTION': 'Já existe venda para esse Lot',
          },
          'COPY': {
            'TITLE': 'Ingresso copiados com sucesso!',
            'DESCRIPTION': '',
          }
        },
        'DIALOG': {
          'TITLE': 'Excluir ingresso',
          'DESCRIPTION': 'Tem certeza que deseja excluir o ingresso <strong>{{ lot_name }}</strong>?<br><br>Após esta ação não poderá ser revertido.',
          'EXCLUIR': 'Excluir',
          'CANCEL': 'Cancelar',
        }
      },
      'WODS': {
        'COPY': 'Copiar WOD\'s',
        'CONTENT_EMPTY': 'Nenhum WOD cadastrado',
        'ADD': 'Adicionar',
        'ADD_NEW': 'Adicionar novo WOD',
        'TABLE': {
          'NAME': 'Nome',
          'RESULT': 'Resultado',
          'TIEBREAK': 'Tiebreak'
        },
        'TOASTR': {
          'CREATE': {
            'TITLE': 'WOD criado com sucesso!',
            'DESCRIPTION': '',
          },
          'EDIT': {
            'TITLE': 'WOD editado com sucesso!',
            'DESCRIPTION': '',
          },
          'DELETE': {
            'TITLE': 'WOD deletado com sucesso!',
            'DESCRIPTION': '',
            'ERROR_DELETE_TITLE': 'Erro ao deletar o ingresso!',
            'ERROR_DELETE_DESCRIPTION': 'Já existe resultado postado nesse WOD.',
          },
          'COPY': {
            'TITLE': 'WOD copiados com sucesso!',
            'DESCRIPTION': '',
          }
        },
      },
      'DELETE': 'Deletar',
      'DATE_FORMAT': 'dd/MM/yyyy',
      'DATETIME_FORMAT': 'dd/MM/yyyy HH:mm:ss',
    }
  }
};
