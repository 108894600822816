import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserCheckinComponent } from './user-checkin.component';
import { AuthGuard } from '../../../../../core/guards/auth.guard';
import { EventGuard } from '../../../../../core/guards/event.guard';
import { SharedModule } from '../../../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';

const routes = [
  {
    path: 'user-dashboard/championship/:championship_id/checkin',
    component: UserCheckinComponent,
    canActivate: [AuthGuard, EventGuard]
  }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot()
  ],
  declarations: [UserCheckinComponent],
  exports: [UserCheckinComponent]
})
export class UserCheckinModule { }
