export const locale = {
  lang: 'en-US',
  data: {
    'REGISTRATION': {
      'DATE_FORMAT': 'DD/MM/YYYY',
      'DATE_FORMAT_COMPLETE': 'DD [de] MMMM [de] YYYY',
      'DATETIME_FORMAT': 'DD/MM/YYYY [às] HH:mm',
      'DATE_EXPIRATION_FORMAT': 'MMYY',
      'TABLE_HEADER': {
        'COMPETITION': 'Categoria',
        'LOT': 'Ingresso',
        'PRICE': 'Valor',
        'TAX': 'Taxa',
        'DISCOUNT': 'Desconto',
        'TOTAL_PRICE': 'Valor total'
      },
      'PERSONAL_INFORMATION': 'Informações pessoais',
      'SUB_DIVISION': 'Selecione uma {{ sub_division_name }}',
      'CAPTAIN': 'Capitão',
      'TEAM_INFORMATION': 'Informações do time',
      'TEAM_MEMBERS': 'Membros do time ({{ count }} / {{ total }})',
      'ADD_MEMBER': 'Adicionar membro',
      'REGULATION': 'Eu li e aceito o regulamento do evento',
      'PAYMENT_INFORMATION': 'Informações do pagamento',
      'CREDIT_CARD': 'Cartão de crédito',
      'BOLETO': 'Boleto bancário',
      'CURRENCY': 'R$',
      'CURRENCY_CODE': 'BRL',
      'GENDER_MALE': 'Masculino',
      'GENDER_FEMALE': 'Feminino',
      'FORM': {
        'TEAM_NAME': 'Nome do time',
        'TEAM_BOX': 'Box',
        'EMAIL': 'E-mail',
        'PASSWORD': 'Senha',
        'CONFIRM_PASSWORD': 'Confirmar senha',
        'FIRSTNAME': 'Nome',
        'LASTNAME': 'Sobrenome',
        'BOX': 'Box',
        'CPF': 'CPF',
        'PHONE': 'Telefone',
        'BIRTHDATE': 'Data de nascimento',
        'GENDER': 'Sexo',
        'TSHIRT': 'Tamanho da camiseta',
        'CITY': 'Cidade',
        'INSTALLMENTS': 'Parcelas',
        'CARD_NUMBER': 'Número do cartão',
        'EXPIRATION_DATE': 'Data de vencimento',
        'CVV': 'Código de segurança',
        'NAME_COMPLETED': 'Nome completo',
        'ADDRESS': 'Endereço',
        'ADDRESS_NUMBER': 'Número',
        'DISTRICT': 'Bairro',
        'STATE': 'Estado',
        'ZIPCODE': 'CEP',
        'CEP': 'CEP',
        'NEIGHBORHOOD': 'Bairro',
        'HOLD_NAME': 'Nome impresso no cartão',
        'HOLD_NAME_BANK_SLIP': 'Nome',
      },
      'VALIDATIONS': {
        'TEAM_NAME_REQUIRED': 'O nome do time é obrigatório',
        'TEAM_BOX_REQUIRED': 'O box do time é obrigatório',
        'EMAIL_REQUIRED': 'O campo e-mail é obrigatório',
        'EMAIL_INVALID': 'O formato de e-mail é inválido',
        'PASSWORD_REQUIRED': 'O campo senha é obrigatório',
        'PASSWORD_MINLENGHT': 'A senha deve conter pelo menos 6 caracters',
        'CONFIRM_PASSWORD_REQUIRED': 'Confirmação de senha obrigatória',
        'CONFIRM_PASSWORD_INVALID': 'As senhas não são iguais',
        'FIRSTNAME_REQUIRED': 'O campo nome é obrigatório',
        'LASTNAME_REQUIRED': 'O campo sobrenome é obrigatório',
        'PHONE_REQUIRED': 'O campo telefone é obrigatório',
        'BIRTHDATE_REQUIRED': 'O campo data de nascimento é obrigatório',
        'CPF_REQUIRED': 'O CPF é obrigatório',
        'BOX_REQUIRED': 'O box é obrigatório',
        'CITY_REQUIRED': 'O campo cidade é obrigatório',
        'GENDER_REQUIRED': 'O campo sexo é obrigatório',
        'TSHIRT_REQUIRED': 'O tamanho da camiseta é obrigatório',
        'DATE_INVALID': 'O formato da data é inválido',
        'CREDIT_CARD_REQUIRED': 'Número do cartão obrigatório',
        'CREDIT_CARD_INVALID': 'Número do cartão inválido',
        'EXPIRATION_REQUIRED': 'Validade obrigatória',
        'CVV_REQUIRED': 'Campo obrigatório',
        'CEP_REQUIRED': 'CEP é obrigatório',
        'ADDRESS_REQUIRED': 'Endereço obrigatório',
        'ADDRESS_NUMBER_REQUIRED': 'Número obrigatório',
        'NEIGHBORHOOD_REQUIRED': 'Bairro é obrigatório',
        'STATE_REQUIRED': 'UF é obrigatório',
        'HOLD_NAME_REQUIRED': 'Nome impresso no cartão obrigatório',
        'HOLD_NAME_BANK_SLIP_REQUIRED': 'Nome obrigatório',
        'HOLD_CPF_REQUIRED': 'CPF do dono do cartão obrigatório',
        'MIN_MEMBERS_REQUIRED': 'Está faltando membro no time',
        'REGULATION_REQUIRED': 'É obrigatório aceitar os termos do regulamento',
        'FULLNAME_NFE_REQUIRED': 'O nome para a nota fiscal é obrigatório.',
        'CPF_NFE_REQUIRED': 'O cpf para a nota fiscal é obrigatório.',
        'EMAIL_NFE_REQUIRED': 'O e-mail para a nota fiscal é obrigatório.',
        'EMAIL_NFE_INVALID': 'Formato de e-mail inválido',
        'ADDRES_NFE_REQUIRED': 'O endereço para a nota fiscal é obrigatório.',
        'ADDRESNUMBER_NFE_REQUIRED': 'O número para a nota fiscal é obrigatório.',
        'CITY_NFE_REQUIRED': 'A cidade para a nota fiscal é obrigatório.',
        'DISTRICT_NFE_REQUIRED': 'O bairro para a nota fiscal é obrigatório.',
        'ZIPCODE_NFE_REQUIRED': 'O CEP para a nota fiscal é obrigatório.',
        'STATE_NFE_REQUIRED': 'O estado para a nota fiscal é obrigatório.',
        'SUB_DIVISION_REQUIRED': '{{ sub_division_name }} é obrigatório.',
      },
      'TOASTR': {
        'CEP_NOT_FOUND': {
          'TITLE': 'CEP não encontrado',
          'DESCRIPTION': 'O CEP não foi encontrado em nossa base de dados, por favor, verificar se está correto.'
        },
        'CEP_NOT_ADDRESS': {
          'TITLE': 'Não encontrado endereço válido para esse CEP',
          'DESCRIPTION': 'Esse CEP não encontrou um endereço válido, por favor, verifique se está correto.'
        },
      },
      'NFE': {
        'TITLE': 'Dados pessoais',
      },
      'ERRORS': {
        'PAYMENT_TITLE_FAILED': 'Pagamento recusado',
        'SUBSCRIBE_USER_TITLE_FAILED': 'Usuário já inscrito.',
        'SUBSCRIBE_USER_TITLE_NOT_PERMITTED': 'Operação não permitida',
        'SUBSCRIBE_NOT_PERMITTED': 'Esse e-mail não está na lista de selecionados do TCB 2023, por favor, verifique se o e-mail está correto.',
        'SUBSCRIBE_TITLE_FAILED': 'Erro na inscrição'
      }
    }
  }
};
