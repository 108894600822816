<div class="page-layout blank pb-64">
  <div class="p-32">
    <div class="content">
      <div class="card auto-width mb-32" [ngClass.gt-sm]="'mb-0'">
        <div class="pt-16">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <h2 class="pl-32 pr-32">{{ 'CROSSX_APP.TITLE' | translate | uppercase }}</h2>
            <btn [btnLoading]="true" [loading]="isCrossXAppLoading" type="grayscale"
                 (click)="publishOrUnpublishCrossXApp((crossxApp.published ? 'unpublish' : 'publish'))"
                 *ngIf="crossxApp" class="mr-32">
              {{ (!crossxApp.published ? 'CROSSX_APP.PUBLISH' : 'CROSSX_APP.UNPUBLISH') | translate | uppercase }}
            </btn>
          </div>

          <div class="mb-32" *ngIf="isLoading">
            <div class="pt-16" fxLayout="column">
              <div fxLayoutAlign="center center" class="p-64" >
                <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
              </div>
            </div>
          </div>
          <!-- PLACEHOLDER NOT CATEGORIES -->
          <div class="mb-32" *ngIf="crossxAppUrls.length === 0 && !isLoading">
            <div class="pt-16" fxLayout="column">
              <div fxLayout="column" fxLayoutAlign="center center" class="p-32 emtpy-content">
                <img src="./assets/icons/placeholder/ic-empty.svg" />
                <span class="mt-8">{{ 'CROSSX_APP.EMPTY' | translate }}</span>
              </div>
            </div>
          </div>

          <table-x #table [items]="crossxAppUrls" [config]="tableConfig" [isLoading]="isLoading" tableName="urls" [isDraggable]="true" (reorderEvent)="reorderAction($event)" *ngIf="!isLoading && crossxAppUrls && crossxAppUrls.length > 0">
            <column property="title" header="{{ 'CROSSX_APP.TABLE.TITLE' | translate }}" [sortable]="false" align="left">
              <ng-template #dataTableCell let-item="item" let-index="index">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon class="mr-8 menu-reorder">menu</mat-icon>
                  <a href="{{ item.url }}" target="_blank" class="small-font">
                    {{ item.title | uppercase }}
                  </a>
                </div>
              </ng-template>
            </column>

            <column property="url" header="{{ 'CROSSX_APP.TABLE.URL' | translate }}" [sortable]="false" align="left">
              <ng-template #dataTableCell let-item="item">
                <a href="{{ item.url }}" target="_blank">
                  {{ item.url }}
                </a>
              </ng-template>
            </column>

            <column property="actions" header="" [sortable]="false" align="center" width="100px">
              <ng-template #dataTableCell let-item="item" let-index="index">
                <btn-transparent icon="common/ic-settings.svg" buttonWidth="35px" iconAlign="left" [matMenuTriggerFor]="urlActionMenu" class="hide-in-drag"
                                 [matMenuTriggerData]="{ item: item, index: index }"></btn-transparent>
              </ng-template>
            </column>
          </table-x>

          <!-- MAT-MENU ACTIONS -->
          <mat-menu class="submenu bottom arrow-right" #urlActionMenu="matMenu" [overlapTrigger]="false" xPosition="before">
            <ng-template matMenuContent let-item="item" let-index="index">
              <button mat-menu-item class="line-bottom" (click)="deleteUrl(item, index)">
                <span class="text-red">{{ 'CROSSX_APP.DELETE' | translate }}</span>
              </button>
            </ng-template>
          </mat-menu>
          
        </div>
      </div>
    </div>
  </div>
</div>

<button mat-fab (click)="createUrl()">
  <mat-icon class="mat-30">add</mat-icon>
</button>