export const locale = {
    lang: 'pt-BR',
    data: {
      'LOGIN': {
        'TITLE': 'Entrar',
        'PASSWORD': 'Senha',
        'LOGIN': 'ENTRAR',
        'FORGOT_PASSWORD': 'Esqueceu a senha?',
        'FACEBOOK_BUTTON': 'ENTRAR COM O FACEBOOK',
        'REGISTER': 'CRIAR CONTA',
        'EMAIL_REQUIRED': 'E-mail obrigatório',
        'EMAIL_INVALID': 'Formato de e-mail inválido',
        'PASSWORD_REQUIRED': 'Senha obrigatória',
        'LOGIN_ERROR': 'Usuário ou senha inválidos',
      }
    }
};
