import { Subscription } from './../../model/subscription';
import { Lot } from './../../model/lot';
import { Event } from './../../model/event';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SubDivision } from '../../model/sub-division';

@Injectable({
  providedIn: 'root'
})
export class EventPageService {

  public event: Event = null;
  private eventSlug: string;

  private lot: Lot = null;
  private coupon: any;
  private subscription: Subscription = null;
  private subDivisions: SubDivision[];
  public email: string = null;

  constructor(
    private http: HttpClient
  ) { }

  
  getEventDetail(slug: string): Observable<any> {
    this.lot = this.coupon = null;
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.get(`public/registration/events/${slug}`, { responseType })
      .map((response: Response) => {
        console.log(response);
        this.event = new Event().deserialize(response as any);
        return this.event;
      });
  }

  // Getters
  getCoupon(): string
  {
    return this.coupon;
  }

  getLot(): Lot
  {
    return this.lot;
  }

  getEmail(): string
  {
    return this.email;
  }

  getSlug() {
    return this.eventSlug;
  }

  getSubscription() {
    return this.subscription;
  }

  // Setters
  setCoupon(coupon: any) {
    this.coupon = coupon;
  }

  setLot(lot: Lot) {
    this.lot = lot;
  }

  setEmail(email: string) {
    this.email = email;
  }

  setEvent(slug: string) {
    this.eventSlug = slug;
  }

  setSubscription(subscription: Subscription) {
    this.subscription = subscription;
  }

}
