import { NgModule } from '@angular/core';
import {AuthGuard} from '../../../../../core/guards/auth.guard';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {SharedModule} from '../../../../../core/modules/shared.module';
import {RouterModule} from '@angular/router';
import {NgxMaskModule} from 'ngx-mask';
import {PaymentsComponent} from './payments.component';

const routes = [
  {
    path: 'user-dashboard/championship/:championship_id/payment',
    component: PaymentsComponent,
    canActivate: [AuthGuard, EventGuard]
  }
];

@NgModule({
  declarations: [
    PaymentsComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot()
  ],
  exports: [
    PaymentsComponent
  ]
})
export class PaymentsModule { }
