<div class="page-layout blank pb-64" id="scrollBar">
  <div class="pt-32">
    <div class="content">
      <div class="auto-width">
        <tab-bar #tabbar [padding]="true" (tabEvent)="tabAction($event)" [items]="tabsFilter" [tabActive]="0"></tab-bar>
      </div>
    </div>

    <div class="p-32">
      <!-- CARD LOTS WITH COMPETITION -->
      <app-lots-with-competition
        [championshipId]="championshipId"
        [championship]="championship"
        [event]="event"
        [fxShow]="tabIndexActive === 0 && event.enabled_leaderboard" *ngIf="event.enabled_leaderboard"></app-lots-with-competition>
      <!-- END CARD LOTS WITH COMPETITION -->
      
      <!-- CARD LOTS WITHOUT COMPETITION -->
      <app-lots-without-competition
        [championshipId]="championshipId"
        [championship]="championship"
        [event]="event"
        [fxShow]="tabIndexActive === 1 || (tabIndexActive === 0 && !event.enabled_leaderboard)"></app-lots-without-competition>
      <!-- END CARD LOTS WITHOUT COMPETITION -->
    </div>
  </div>
</div>