import { Coupon } from './../../model/coupon';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CouponsService {

  constructor(
    private http: HttpClient
  ) { }

  validateCode(cuponCode: string, eventId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.get(`public/registration/coupons/${cuponCode}/${eventId}`, { responseType })
      .map((response: Response) => {
        console.log(response)
        return response
      })
  }

  getList(eventId: number, page: number, type: string = 'all', sort: any = null, searchTerm: string = ''): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    var url = `admin/coupons/?eventId=${eventId}&type=${type}&name=${searchTerm}&page=${page}`

    if (sort) url = url + `&order_by[${sort.column}]=${sort.order}`
    console.log(url)

    return this.http.get(url, { responseType })
      .map((response: Response) => {
        // console.log(response)

        let coupons = (response as any).map((coupon: Coupon) => new Coupon().deserialize(coupon))
        return coupons
      })
  }
  
//  Create Coupon
  create(parameters: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.post(`admin/coupons/`, parameters, { responseType })
      .map((response: Response) => {
        // console.log(response)
        let coupon = new Coupon().deserialize(response)
          // (response as any).map((coupon: Coupon) => new Coupon().deserialize(coupon))
        return coupon
      })
  }
  
  update(parameters: any, couponId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.put(`admin/coupons/${couponId}`, parameters, { responseType })
      .map((response: Response) => {
        // console.log(response)
        let coupon = new Coupon().deserialize(response)
          // (response as any).map((coupon: Coupon) => new Coupon().deserialize(coupon))
        return coupon
      })
  }

  delete(couponId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.delete(`admin/coupons/${couponId}`, { responseType })
      .map((response: Response) => {
        // console.log(response)
        return response
      })
  }
}
