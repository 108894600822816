import {Draggable} from '../../../../../../core/model/draggable.model';
import {HeatCompetitor} from '../../heats/model/heat-competitor.model';
import {Heat} from '../../../../../../core/model/heat.model';

export class HeatLanesOrder
{
  private static rankNullNumber = 3000000;
  
  public static byLanesToBestRankInCenter(heat: Heat, enableSubDivision: boolean) {
    const heatCompetitors = [...heat.heat_competitors];
    
    const hasCompetitorRank =
      enableSubDivision 
        ? heatCompetitors.filter(item => {
          return item.type === 'item' && item.competitor.sub_division_rank !== null;
        })
      : heatCompetitors.filter(item => {
      return item.type === 'item' && item.competitor.rank !== null;
    });
    
    if (hasCompetitorRank.length === 0) {
      return;
    } 
    
    const laneOrderRank = heatCompetitors.filter(item => {
      if (enableSubDivision) {
        if (item.type === 'item' && item.competitor.sub_division_rank === null) {
          item.competitor.sub_division_rank = this.rankNullNumber;
        }
      }
      else {
        if (item.type === 'item' && item.competitor.rank === null) {
          item.competitor.rank = this.rankNullNumber;
        }
      }
      
      return item.type === 'item';
      
    }).sort((a, b) => {
      if (enableSubDivision) {
        if (a.competitor.sub_division_rank > b.competitor.sub_division_rank ) { return 1; }
        else if (a.competitor.sub_division_rank < b.competitor.sub_division_rank) { return -1; }
        else { return 0; } 
      }
      else{
        if (a.competitor.rank > b.competitor.rank ) { return 1; }
        else if (a.competitor.rank < b.competitor.rank) { return -1; }
        else { return 0; }
      }
  
    });
    
    if (laneOrderRank === null || laneOrderRank.length === 0){
      return;
    }

    const tableName = laneOrderRank[0].tableName;
    
    const totalPlaceholder = heat.total_lanes - laneOrderRank.length;
    const countPlaceholderFirst = Math.floor(totalPlaceholder / 2);
    const countPlaceholderLast = totalPlaceholder - countPlaceholderFirst;
    
    const newArray: Draggable[] = [];
    let indexNewArray = 1;
    
    // Add Placeholder in init array
    for (let i = 0; i < countPlaceholderFirst; i++) {
      const lane = {
        id: null,
        type: 'placeholder',
        tableName: tableName,
        lane: indexNewArray,
        competitor_id: null,
        competitor: null,
        wod_id: null,
        // children: null,
        selected: false,
        object: null
      };
      newArray.push(lane);
      indexNewArray++;
    }
    
    const firstArray: any[] = []; 
    const secondArray: any[] = []; 
    
    laneOrderRank.forEach((item, index) => {
      if (enableSubDivision) {
        if (item.competitor.sub_division_rank % 2 === 1) {
          const exists = secondArray.filter(value => {
            return value.competitor.sub_division_rank === item.competitor.sub_division_rank;
          });
          if (exists.length > 0) {
            if (exists.length % 2 === 1) {
              firstArray.push(item);
            } else {
              secondArray.push(item);
            }
            
          } else {
            secondArray.push(item);
          }
      
        } else if (item.competitor.sub_division_rank % 2 === 0) {
          const exists = firstArray.filter(value => {
            return value.competitor.sub_division_rank === item.competitor.sub_division_rank;
          });
          if (exists.length > 0) {
              if (exists.length % 2 === 1) {
                secondArray.push(item);
              } else {
                firstArray.push(item);
              }
          } else {
            firstArray.push(item);
          }
       
        }
      } else {
        if (item.competitor.rank % 2 === 1) {
          const exists = secondArray.filter(value => {
            return value.competitor.rank === item.competitor.rank;
          });
          if (exists.length > 0) {
            firstArray.push(item);
          } else {
            secondArray.push(item);
          }
        } else if (item.competitor.rank % 2 === 0) {
          const exists = firstArray.filter(value => {
            return value.competitor.rank === item.competitor.rank;
          });
          if (exists.length > 0) {
            secondArray.push(item);
          } else {
            firstArray.push(item);
          }
        }
      }
 
  
    });
    

    secondArray.sort((a, b) => {
      if (enableSubDivision) {
        if (a.competitor.sub_division_rank > b.competitor.sub_division_rank ) { return -1; }
        else if (a.competitor.sub_division_rank < b.competitor.sub_division_rank) { return 1; }
        else { return 0; } 
      } else {
        if (a.competitor.rank > b.competitor.rank ) { return -1; }
        else if (a.competitor.rank < b.competitor.rank) { return 1; }
        else { return 0; }
      }
   
    }).forEach(item => {
      item.lane = indexNewArray;
      newArray.push(item);
      indexNewArray++;
    });
    
    firstArray.forEach(item => {
      item.lane = indexNewArray;
      newArray.push(item);
      indexNewArray++;
    });
    
    for (let i = 0; i < countPlaceholderLast; i++ ) {
      const lane = {
        id: null,
        type: 'placeholder',
        tableName: tableName,
        lane: indexNewArray,
        competitor_id: null,
        competitor: null,
        wod_id: null,
        // children: null,
        selected: false,
        object: null
      };
      newArray.push(lane);
      indexNewArray++;
    }

    heat.heat_competitors = [];
    newArray.forEach(item => {
      if (enableSubDivision) {
        if (item.type === 'item' && item.competitor.sub_division_rank === this.rankNullNumber) {
          item.competitor.sub_division_rank = null;
        }
      } else {
        if (item.type === 'item' && item.competitor.rank === this.rankNullNumber) {
          item.competitor.rank = null;
        }
      }
      
      heat.heat_competitors.push(item);
    });
    
    heat.heat_competitors.sort((a, b) => {
      if (a.lane > b.lane) { return 1; }
      else if (a.lane < b.lane) { return -1; }
      else { return 0; }
    });
    heat.isUpdated = true;
    // heat.heat_competitors = newArray.filter(item => {
    //   if (item.type === 'item' && item.competitor.rank === this.rankNullNumber) {
    //     item.competitor.rank = null;
    //   }
    //   return true;
    // }).sort((a, b) => {
    //   if (a.lane > b.lane) return 1;
    //   else if (a.lane < b.lane) return -1;
    //   else return 0;
    // });

    // heat.heat_competitors = newArray;
  }

  public static byCompetitorInMiddle(heat: Heat) {
    const heatCompetitors = [...heat.heat_competitors];
    const laneOrderRank = heatCompetitors.filter(item => {
      return item.type === 'item';
    });

    if (laneOrderRank === null || laneOrderRank.length === 0){
      return;
    }

    const tableName = laneOrderRank[0].tableName;

    const totalPlaceholder = heat.total_lanes - laneOrderRank.length;
    const countPlaceholderFirst = Math.floor(totalPlaceholder / 2);
    const countPlaceholderLast = totalPlaceholder - countPlaceholderFirst;

    const newArray: Draggable[] = [];
    let indexNewArray = 1;

    // Add Placeholder in init array
    for (let i = 0; i < countPlaceholderFirst; i++) {
      const lane = {
        id: null,
        type: 'placeholder',
        tableName: tableName,
        lane: indexNewArray,
        competitor_id: null,
        competitor: null,
        wod_id: null,
        // children: null,
        selected: false,
        object: null
      };
      newArray.push(lane);
      indexNewArray++;
    }
    
    laneOrderRank.forEach(item => {
      item.lane = indexNewArray;
      newArray.push(item);
      indexNewArray++;
    });

    for (let i = 0; i < countPlaceholderLast; i++ ) {
      const lane = {
        id: null,
        type: 'placeholder',
        tableName: tableName,
        lane: indexNewArray,
        competitor_id: null,
        competitor: null,
        wod_id: null,
        // children: null,
        selected: false,
        object: null
      };
      newArray.push(lane);
      indexNewArray++;
    }
    
    heat.heat_competitors = newArray.sort((a, b) => {
      if (a.lane > b.lane) { return 1; }
      else if (a.lane < b.lane) { return -1; }
      else { return 0; }
    });
    heat.isUpdated = true;
    
  }
  
}
